import { BreakingPoints } from "./types";

export const BREAKING_POINT_QUERIES: { [key in BreakingPoints]: string } = {
	sm: "(max-width: 640px)",
	md: "(min-width: 641px) and (max-width: 800px)",
	lg: "(min-width: 801px) and (max-width: 920px)",
	xl: "(min-width: 921px) and (max-width: 1440px)",
	"2xl": "(min-width: 1441px) and (max-width: 1919px)",
	"3xl": "(min-width: 1920px)",
};
