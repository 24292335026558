export const zigTags = {
	title: "Manage Zig Tags",
	table: {
		columns: {
			zigCode: "Zig Code",
			userName: "User",
			userDocument: "Documento do usuário",
			isBlocked: "Is Blocked?",
			actions: "Actions",
		},
		actions: {
			blockTag: "Block Tag",
		},
	},
	form: {
		label: "Buscar Zig Tag",

		placeholder: "Type the tag number",
		searchBtn: "Search",
		errors: {
			zigTagRequired: "Type the tag number to search",
		},
	},
	empty: {
		title: "Search a zig tag to manage it",
	},
	block: {
		success: "Tag bloqueada com sucesso",
	},
};
