export const contentProportionalValue = {
	quantity: "Quantidade",
	unityMeasurement: "Unidade de medida",
	requiredField: "Campo obrigatório",
	infoMessageMyTapp:
		"Não é possível desativar essa função enquanto “Sincronizar com My Tapp” estiver ativa.",
	placeholder: {
		quantity: "Insira uma quantidade",
		unitMeasurement: "Selecione",
	},
	optionsUnitMeasurement: {
		ml: "Mililitros",
		gm: "Gramas",
		un: "Unidade",
	},
};
