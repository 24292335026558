import { fromPromise, IPromiseBasedObservable, PromiseState } from "mobx-utils";
import { action, computed, observable } from "mobx";

/**  @deprecated models should not ne used anymore */
export class ReportModel<DataType, ArgsType> {
	@observable
	public loaderPromise: IPromiseBasedObservable<DataType> | null = null;
	@observable
	public updatedAt: Date | null = null;

	constructor(private fetcherFn: (args: ArgsType) => Promise<DataType>) {}

	@computed
	get value(): DataType | null {
		return (
			(this.loaderPromise &&
				this.loaderPromise.state === "fulfilled" &&
				this.loaderPromise.value) ||
			null
		);
	}

	@action
	private updateDate = () => {
		this.updatedAt = new Date();
	};

	@computed
	get state(): PromiseState | null {
		return this.loaderPromise && this.loaderPromise.state;
	}

	@computed
	get lastError() {
		return (
			this.loaderPromise &&
			this.loaderPromise.state === "rejected" &&
			this.loaderPromise.value
		);
	}

	@action
	public clear() {
		this.loaderPromise = null;
	}

	@action
	public fetch({ args, forceReload = true }: { args: ArgsType; forceReload?: boolean }) {
		if (forceReload || !this.loaderPromise || this.loaderPromise.state === "rejected") {
			return (this.loaderPromise = fromPromise(
				(async () => {
					try {
						const data = await this.fetcherFn(args);
						this.updateDate();
						return data;
					} catch (error) {
						console.error("ReportModel: ", error);
						throw error;
					}
				})(),
			));
		}
	}
}
