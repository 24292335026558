export const productionControl = {
	label: "Receitas e produção",
	at: "às",
	attachedToModal: {
		title: "Vínculos do item",
		description: "Relação de cadastro com os quais esse item está vinculado",
		seeDetails: "Ver detalhes",
	},
	tabs: {
		production: "Receitas e Produção",
		productionHistory: "Histórico de Produção",
	},
	list: {
		emptyList: "Nenhuma receita encontrada",
		createdAt: "Criado em {{date}}",
		title: "Receitas",
		subtitle: "Selecione a receita que deseja produzir",
		searchPlaceholder: "Buscar por receita",
		links: "Vínculos",
		linksTotal: "{{count}} produtos",
	},
	history: {
		table: {
			name: "Nome",
			storage: "Estoque",
			fiscalCode: "SKU",
			createdAt: "Data de Criação",
			count: "Quantidade Produzida",
			cost: "Valor",
			author: "Produtor",
		},
		filters: {
			search: {
				label: "Buscar por",
				placeholder: "Nome, produtor ou SKU",
			},
			interval: {
				label: "Filtrar por período",
			},
		},
	},
	producer: "Produtor",
	expectedYield: "Rendimento esperado",
	yield: "Rendimento",
	storage: "Estoque",
	production: {
		title: "Produzir insumo",
		steps: {
			CONFIGURATION: {
				title: "Configuração",
				description: "Selecione o estoque para começar",
			},
			PREPARE: {
				title: "Preparação",
				description:
					"Preencha a quantidade de cada ingrediente e clique em próximo para prosseguir",
			},
			RESULT: {
				title: "Rendimento",
				description: "Revise sua produção e preencha o rendimento da sua para finalizar",
			},
		},
		fields: {
			storage: {
				label: "Estoque",
				placeholder: "Selecione um estoque",
			},
			producer: {
				label: "Produtor",
			},
			inputCount: {
				label: "Quantidade utilizada",
			},
			inputLoss: {
				label: "Perda",
			},
			output: {
				label: "Quantidade produzida",
			},
		},
		statusDialog: {
			loading: {
				dialogTitle: "Produção",
				title: "Produzindo...",
				subtitle: "Estamos produzindo o seu produto, aguarde um momento.",
			},
			success: {
				dialogTitle: "Produção - Sucesso",
				title: "Produção concluída!",
				subtitle: "Entrada de {{count}} de {{recipe}} no estoque {{storage}}",
			},
			error: {
				dialogTitle: "Produção - Erro",
				title: "Ops, algo deu errado!",
				subtitle: "Não conseguimos realizar a produção. Tente novamente mais tarde.",
			},
		},
		backToHome: "Voltar para página inicial",
		backToYield: "Volta para rendimento",
		ingredientsTable: {
			name: "Ingrediente",
			count: "Quantidade",
			loss: "Perda",
			totalLoss: "Saída de Estoque",
		},
		outOfExpectedYield: {
			cancelText: "Editar rendimento",
			description:
				"Sua saída está muito fora da regra de produção, quer continuar mesmo assim?",
		},
	},
	validation: {
		numberMustBeGreaterThanZero: "O valor deve ser maior que 0",
		numberMustBePositive: "O valor deve ser positivo",
		nameMustHaveMaxCharacters: "O nome deve ter no máximo 30 caracteres",
		skuMustHaveMaxCharacters: "O SKU deve ter no máximo 60 caracteres",
	},
	save: {
		newTitle: "Nova regra",
		editTitle: "Editar regra",
		createAction: "Criar regra",
		addSupplyAction: "Adicionar insumo",
		detailsTitle: "Detalhes da regra",
		productionRule: "Regra de produção",
		inputsTable: {
			name: "Insumo",
			count: "Quantidade",
			loss: "Perda",
			actions: "Ações",
		},
		form: {
			name: {
				label: "Nome",
				placeholder: "Insira o nome",
			},
			yield: {
				label: "Rendimento da regra",
			},
			image: {
				label: "Foto",
				caption: "Imagem quadrada ou 500x500 pixels",
			},
			inputName: {
				label: "Insumo ou produto",
			},
			inputCount: {
				label: "Quantidade",
			},
			inputLoss: {
				label: "Perda",
			},
			output: {
				label: "Quantidade produzida",
			},
		},
	},
	deleteDialog: {
		title: "Excluir regra",
		content:
			"Ao confirmar a ação você exclui permanentemente esta regra de produção. Deseja continuar?",
	},
	recipe: {
		title: "Detalhes da receita",
		ingredientsTitle: "Receita e produção",
		totalCost: "Custo total",
		yield: "Rendimento esperado",
		deleteInputDialog: {
			content: "Ao confirmar a ação você remove o item da receita. Deseja continuar?",
			title: "Excluir Item",
		},
		multipleItems: "Itens",
		singleItem: "Item",
		links: "Vínculos",
	},
	actions: {
		produce: "Produzir",
		delete: "Excluir",
		edit: "Editar",
	},
};
