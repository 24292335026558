import { action, observable, toJS } from "mobx";
import * as ReactGA from "react-ga";
import { RootStore } from ".";

import { fromPromise, IPromiseBasedObservable } from "#helpers/mobx-utils";
import { showErrorNotification } from "#helpers/notifications";
import { AdminResponse, Employee } from "#resources/api/enterprise-generated";
import enterprise from "#resources/api/enterprise-client";
import { EnterprisePermissions } from "#resources/helpers/permissions/utils";
import { fetchModel } from "#resources/helpers/fetch-model";
import { IError } from "#resources/helpers/mobx/utils";
import { clearQueryClient } from "#structure/providers/react-query";

export class AuthStore {
	@observable
	public currentEmployee: null | Employee = null;
	@observable
	public loginPromise: IPromiseBasedObservable<void> | null = null;
	@observable
	public logoutPromise: IPromiseBasedObservable<void> | null = null;
	@observable
	public fetchCurrentEmployeePromisse: IPromiseBasedObservable<void> | null = null;
	@observable
	public isZigpayEmployee: boolean = false;

	public rootStore: RootStore;

	public constructor(rootStore: RootStore) {
		this.rootStore = rootStore;
		this.syncWithLocalStorage(false);

		enterprise.errorHook = (result: any, _name: string, _args: any) => {
			if (result && result.type && result.type === "NotLoggedIn") {
				this.logout();
			}
		};
	}

	public syncWithLocalStorage(override: boolean) {
		if (!override) {
			this.currentEmployee = JSON.parse(
				localStorage.getItem("currentEmployee") || "null",
			);
		} else {
			localStorage.setItem("currentEmployee", JSON.stringify(toJS(this.currentEmployee)));
		}
		this.updateZigpayEmployeeStatus();
	}

	@action
	public login = async (username: string, password: string, organization: string) => {
		try {
			this.currentEmployee = await enterprise.logIn({
				username: username.trim(),
				password: password.trim(),
				organizationUsername: organization.trim(),
			});
			this.syncWithLocalStorage(true);
			ReactGA.event({
				category: "User",
				action: "login",
			});
			this.rootStore.routerStore.push("/");
		} catch (err) {
			if (err instanceof Error) {
				showErrorNotification(err.message);
			}
		}
	};

	@action
	public async logout() {
		try {
			await enterprise.logOut();
			this.clearUser();
			ReactGA.event({
				category: "User",
				action: "logout",
			});
			this.rootStore.routerStore.push("/login");
			this.cleanAll();
			clearQueryClient();
		} catch {}
	}

	@action
	public async clearUser() {
		this.currentEmployee = null;
		this.syncWithLocalStorage(true);
		this.getCurrentAdmin.reset();
	}

	@action
	public cleanAll() {
		this.rootStore.authStore.clean();
		this.rootStore.placeStore.clean();
		this.rootStore.barStore.clean();
		this.rootStore.itemStore.clean();
		this.rootStore.comboStore.clean();
		this.rootStore.employeeStore.clean();
		this.rootStore.eventStore.clean();
		this.rootStore.organizationStore.clean();
		this.rootStore.clientStore.clean();
		this.rootStore.fiscalStore.clean();
		this.rootStore.deliveryStore.clearAll();
		this.rootStore.invoiceStore.clean();
		this.rootStore.CMVStore.clean();
		this.rootStore.whatsUpStore.clean();
		this.rootStore.loyaltyStore.clean();
		this.rootStore.storageStore.clean();
		this.rootStore.clientAreaStore.clean();
	}

	@action
	public clean() {
		this.currentEmployee = null;
		this.loginPromise = null;
		this.logoutPromise = null;
		this.fetchCurrentEmployeePromisse = null;
	}

	@action
	public async fetchCurrentEmployee() {
		this.fetchCurrentEmployeePromisse = fromPromise(
			enterprise
				.getCurrentEmployee()
				.then(res => {
					this.currentEmployee = res;
					this.syncWithLocalStorage(true);
				})
				.catch(err => {
					this.logout();
					if (err instanceof Error) {
						showErrorNotification(err.message);
					}
				}),
		);
		this.getCurrentAdmin.fetch({});
	}

	@action
	public async updateZigpayEmployeeStatus() {
		if (this.currentEmployee) {
			const name = this.currentEmployee.name;
			const username = this.currentEmployee.username;
			const isEmployee = `${name}-${username}`.toLowerCase().includes("zigemployee");
			this.isZigpayEmployee = isEmployee;
		} else this.isZigpayEmployee = false;
	}

	public hasPermission = (permissionName: EnterprisePermissions): boolean => {
		if (!this.currentEmployee) return false;

		const foundPermission = this.currentEmployee.permissions.find(
			permission => permission.slug === permissionName,
		);
		return !!foundPermission;
	};

	public getCurrentAdmin = new fetchModel<{}, AdminResponse | null>({
		fnPromise: args => enterprise.getCurrentAdmin(args),
		onError: (err: IError) => showErrorNotification(err.message),
	});
}
