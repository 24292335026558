export const profitMarginReport = {
	seeMore: "Mostrar Mais",
	pageTitle: "Relatório de Custos e Margens de Lucro",
	empty: "Não há relatórios disponíveis",
	label: "Custos e margem de lucro",
	notAssociatedReport: "(Relatório não associado)",
	export: "Download em Excel",
	print: "Imprimir",
	selectPlaceholder: "Selecione aqui",
	excelFileName: "relatorio_custo_e_margens_de_lucro",
	successfullExportMsg: "Relatório exportado com sucesso",
	period: "Período",
	header: {
		description:
			"Este relatório oferece uma visão da performance financeira dos produtos mostrando seu faturamento, quantidade vendida, preço de venda, custos e margem de lucro.",
	},
	columns: {
		productName: "Produto",
		category: "Categoria",
		quantity: "QTD.",
		value: "Preço médio",
		soldAmount: "Faturamento",
		invoicing: "Participação",
		invoicingExplanation: "Percentual do produto no faturamento",
		averageSellingPrice: "Custo Unitário",
		totalCost: "Custo total",
		costPercentage: "% Custo",
		costPercentageExplanation: "Percentual do produto no custo",
		profitMarginValue: "R$ Lucro",
		profitMarginValueExplanation: "Margem de lucro em dinheiro",
		profitMarginPercentage: "% Lucro",
		profitMarginPercentageExplanation: "Margem de lucro em porcentagem",
	},
	indicators: {
		updatedAt: "Atualizado {{ date }}",
		profitMargin: {
			title: "Margem de Lucro",
		},
		totalBilling: {
			title: "Faturamento Total",
		},
		totalCost: {
			title: "Custo Total",
		},
		mostProfitMargin: {
			title: "Maior Margem de Lucro",
			seeMore: "Produtos com maior margem de lucro",
		},
		lessProfitMargin: {
			title: "Menor Margem de Lucro",
			seeMore: "Produtos com menor margem de lucro",
		},
	},
	flags: {
		red: "Entre os 5 produtos com menor margem de lucro",
		green: "Entre os 5 produtos com maior margem de lucro",
	},
	filters: {
		period: "Período",
		product: "Produto",
		category: "Categoria",
		allProducts: "Todos os produtos",
		allCategories: "Todas as categorias",
	},
};
