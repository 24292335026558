export const promoter = {
	title: "Promoters",
	promoter: {
		menu: {
			deactivate: "Desativar",
			activate: "Ativar",
			edit: "Editar",
		},
		table: {
			name: "Nome",
			email: "E-mail",
			phone: "Telefone",
			accessLevel: "Nível de acesso",
			active: "Ativo",
			pending: "Pendente",
			inactive: "Inativo",
		},
		content: {
			titleAddPromoter: "Adicionar promoter",
			titleEditPromoter: "Editar promoter",
		},
		header: {
			title: "Promoters",
			text: "Área do promoter:",
			promoter: "promoter",
			promoters: "promoters",
			buttonAddPromoter: "Adicionar promoter",
			buttonCopyPromoter: "Copiar",
			buttonCopyPromoterSuccess: "Link copiado",
			buttonSearch: "Buscar",
			active: "Ativo",
			inactive: "Inativo",
			pending: "Pendente",
		},
		addPromoter: {
			subtitle: "Enviar convite para:",
			textAddPromoter:
				"Digite o e-mail do promoter que deseja permitir acesso às listas de convidados.",
			messageAddPromoter:
				"Para adicionar mais de um promoter, basta separar os e-mails por vírgula (,)",
			buttonInvite: "Enviar convite",
			successTittle: "Convite enviado com sucesso!",
			successText: "O convite foi enviado para os e-mails cadastrados",
			successButton: "Adicionar promoter",
		},
		editPromoter: {
			textStatusPromoter: "Status",
			textLevelPromoter: "Nível de acesso",
			name: "Nome",
			email: "Email",
			phone: "Telefone",
			messageLevelPromoter:
				"Pode visualizar os eventos, adicionar e editar convidados nas listas",
			messageToolTipPromoter: "O status define se o promoter tem acesso as suas listas",
			pendingStatus: "Pendente",
			buttonInvite: "Reenviar convite",
			buttonInviteSave: "Salvar edição",
			successTittle: "Convite enviado com sucesso!",
			successText: "O convite foi enviado para os e-mails cadastrados",
			successButton: "Adicionar promoter",
			active: "Ativo",
			inactive: "Inativo",
		},
		store: {
			messageActive: "Usuário ativado com sucesso",
			messageDeactive: "Usuário desativado com sucesso",
			messageSuccess: "Convite reenviado com sucesso",
			messageEdit: "Dados do promoter alterados com sucesso",
		},
	},
};
