export const cmvCost = {
	label: "Custos",
	export: "Exportar para Excel",
	selectTotalCost: "Custo total da seleção",
	storageTotalCost: "Custo total em estoque",
	averageCostSelection: "Custo médio x preço de venda da seleção",
	averageCost: "Custo médio x preço de venda",
	alert: "Atenção! Existem produtos com quantidade negativa que devem ser revisados.",
	products: "Produtos",
	showAll: "Mostrar todos {{count}} produto(s)",
	showLess: "Mostrar menos",
	filter: {
		label: "Filtrar por",
		storage: {
			title: "Estoque",
			label: "Selecionar estoque",
			all: "Todos estoques",
		},
		category: {
			title: "Categoria",
			label: "Selecionar categoria",
			all: "Todas categorias",
		},
		product: {
			title: "Produto",
			label: "Selecionar produto",
			all: "Todos produtos",
		},
		clear: "Limpar filtro",
		apply: "Filtrar",
	},
	table: {
		checkbox: "Não mostrar itens com ficha técnica",
		columns: {
			name: "Produto",
			code: "SKU",
			count: "Quantidade",
			averageCost: "Custo médio",
			stockValue: "Valor em estoque",
			realCost: "Preço de venda",
			margin: "Margem",
		},
	},
	sheet: {
		title: "Relatório de Custos",
		fileName: "relatorio-de-custos",
		name: "Produto",
		fiscalCode: "SKU",
		count: "Quantidade",
		unityCount: "Quantidade (Unidade)",
		averageCost: "Custo médio",
		stockValue: "Valor em estoque",
		realCost: "Preço de venda",
		margin: "Margem",
	},
};
