export const newList = {
	title: "Nova lista de convidados",
	steps: {
		first: {
			title: "Dados da lista",
			description: "Que lista é essa?",
		},
		second: {
			title: "Clientes",
			description: "Quem são seus clientes?",
		},
		last: {
			title: "Lista pronta",
			description: "Download e impressão",
		},
	},
	create: "Criar lista",
	view: "Visualizar lista criada",
	print: "Imprimir",
	list: "Lista",
	downloadList: "Baixar lista",
	forms: {
		firstStep: {
			title: "Nova lista",
			name: {
				label: "Nome da lista",
				placeholder: "Digite aqui o nome da sua lista",
				errorName: "Informe o nome da lista",
				errorLength: "O nome da lista deve ter menos de 100 caracteres",
			},
			expiresAtDate: {
				label: "Expiração da lista",
				at: "às",
				error: "Data e hora expirada",
			},
			addGuestTimeLimit: "Prazo limite de inserção de convidados",
			colorSelect: "Atribuir uma cor",
			couvertPercentage: "Desconto no couvert",
			entranceIds: {
				label: "Entrada associada",
				placeholder: "Selecione o tipo de entrada associada",
			},
			promoterIds: {
				label: "Promoters responsáveis por adicionar convidados nesta lista",
				placeholder: "Para adicionar digite ou selecione",
				all: "Todos",
			},
			description: {
				label: "Descrição",
				placeholder: "Adicione uma observação para sua lista caso queira",
				error: "A descrição da lista deve possuir menos de 280 caracteres",
				leftCharacters: "Restam {{count}} caracteres",
			},
			canBeInvitedByLink: {
				label: "Compartilhamento de lista via link",
				error: "Para uma lista ser compartilhavel é necessario um limite de convidados",
			},
			isDocumentRequired: {
				label: "Documento obrigatório",
			},
			hasAutomaticEntranceCharge: {
				label: "Cobrar entrada automaticamente",
			},
			guestsLimitByPromoter: {
				label: "Limite de convidados por promoter",
				placeholder: "Insira o número limite",
			},
			guestsLimit: {
				label: "Limite de convidados",
				placeholder: "Insira o número limite",
			},
		},
		secondStep: {
			successImport: "Planilha importada com sucesso",
			label:
				"Você pode adicionar convidados manualmente ou importar uma planilha. Caso deseje, você pode prosseguir e adicionar ou importar convidados em outro momento.",
			title: "Cliente {{count}}",
			cpf: {
				label: "CPF",
				error: "CPF Inválido",
				duplicate: "CPF já foi adicionado",
			},
			name: {
				label: "Nome e sobrenome",
				placeholder: "Digite o nome do convidado",
				error: "Nome inválido",
			},
			phone: "Telefone",
			promoter: {
				label: "Nome do(a) promoter",
				placeholder: "Nome do responsável da lista",
			},
			addNewClient: "Adicionar convidado",
		},
		lastStep: {
			title: "Lista criada com sucesso",
			cardTitle: "Download/impressão",
		},
	},
	goBackToLists: "Voltar para listas",
};
