export const productReport = {
	title: "Delivery report by product",
	columns: {
		name: "Product",
		sku: "SKU",
		count: "Quantity",
		totalValue: "Total price",
		baseValue: "Base value",
		integration: "Integration",
	},
	vendors: {
		coala: "Whitelabel",
		ifood: "Ifood",
		rappi: "Rappi",
		ubereats: "Uber eats",
	},
	sheet: {
		filename: "report-delivery",
		generalProductReport: {
			title: "Delivery Products Report",
			columns: {
				name: "Product",
				sku: "SKU",
				count: "Quantity",
				totalValue: "Total price",
				baseValue: "Base value",
				integration: "Integration",
			},
		},
		allOrdersProductsReport: {
			title: "Product Order Report",
			columns: {
				name: "Product",
				sku: "SKU",
				count: "Quantity",
				baseUnitPrice: "Base Price",
				totalUnitPrice: "Total Price",
				obs: "Note",
				deliveryOrderId: "Order Identifier",
				productId: "Product Identifier",
			},
		},
	},
};
