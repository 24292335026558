export const panel = {
	closeEvent: {
		message:
			"Are you sure you want to close the event? This will checkout all users and close the report, inventory, and cashiers.",
		finishedActivityDevices:
			"There are {{finishedActivityDevices}} PDV's with open operations!",
		eventNameMessage: "Enter event name to confirm closing",
		emptyName: "Event name is empty",
		incorectName: "The event name is incorrect",
	},
	deleteEvent: {
		confirm: "Are you sure you want to remove the event? This will be a permanent action",
	},
	eventNotOpen: "This event has not yet opened",
	openEvent: "Open event",
	eventStatus: "Event status",
	open: "Open",
	finished: "Closed",
	canceled: "Canceled",
	notOpened: "Not open",
	openedBy: "Opened by",
	finishedActivityText: "{{activeDevices}} PDV with open operation",
	closeEventButton: "Close event",
	removeEventButton: "Remove event",
	goTo: "go to",
	importConsumption: {
		title: "Import Consumption",
		consumptionType: "Type of consummation",
		normal: "Normal",
		consumption: "Consumption",
		confirm: "Confirm",
		consumptionErrors: "Consummations that failed",
		document: "CPF",
		reason: "Reason",
		import: "Import consummation",
		balanceType: "Balance type",
		columns: {
			reason: "Reason",
			value: "Value",
			document: "CPF",
			name: "Name",
			email: "E-mail",
			phone: "Phone",
			isForeign: "Is it foreign?",
			gender: "Gender",
		},
		successNotification: "Imported consumption!",
		warningNotification: "Data has not been sent. Some consummations failed",
		recharge: "Recharge",
		minimumConsumption: "Anticipated consumption",
		bonus: "Bonus",
		spreadsheet: "Spreadsheet",
		user: "User",
	},
	importConsumptionCardsGroup: {
		title: "Group of cards",
		fields: {
			group: {
				label: "Name of the card group",
				required: "Select a card group",
			},
			balanceType: {
				label: "Balance Type",
				required: "The balance amount must be greater than zero",
			},
			balance: { label: "Balance Value" },
		},
	},
	importConsumptionZigCode: {
		title: "ZigCode",
		fileName: "Consummation Worksheet",
		rechargeSuccess: "Successfully recharged!",
		balanceType: "Balance Type",
		columns: {
			reason: "Reason",
			value: "Value",
			zigCode: "ZigCode",
		},
	},
	importConsumptionUser: {
		reason: "Reason",
		document: "Document",
		paymentMethod: "Payment method",
		value: "Recharge value",
		submitRecharge: "Recharge ",
		documentError: "Document is required",
		paymentMethodError: "Payment method is required",
		valueError: "Recharge value is required",
		recharge: "Recharge",
		minimumConsumption: "Minimum Consumption",
		bonus: "Bonus",
		documentType: "Document type",
	},
	publicCard: {
		title: "Public",
		totalPublic: "{{totalPublic}} were at the event",
		allInsideText: "are still enjoying",
		allOutsideText: "already left",
		newPublicText: "new clients",
		oldPublicText: "returned",
		capacity: "Capacity",
		people: "people",
		genderCard: {
			invoice: "Billed",
			averageTicket: "Average ticket",
			zeroTicket: "Zero Ticket",
			realTicket: "Real Ticket",
			male: "Men",
			female: "Women",
			other: "Others",
		},
	},
	totalReceiptCard: {
		title: "Financial",
		notIdealStateMessage: "This information is only available for events from 08/01/2017",
		averageTicket: "Average ticket",
		zeroTicket: "Zero Ticket",
		realTicket: "Real Ticket",
		receipt: "Receipt",
		operationTable: {
			title: "Operation",
			operationMode: "Operation mode",
			zigTable: "Zig Table",
			zigCard: "Zig Card",
			total: "Total",
			invoicing: "Invoicing",
		},
		column: {
			title: "Payment Mode",
			bar: "Bar",
			value: "Value",
			entrance: "Entry",
			opened: "Open accounts",
			total: "Total",
			tableRow: {
				app: "App",
				rappi: "Rappi Pay",
				ame: "Ame",
				uber: "Uber",
				ifood: "Ifood",
				deliveryOnline: "Delivery Online",
				cash: "Cash",
				credit: "Credit",
				debit: "Debit",
				voucher: "Voucher",
				anticipated: "Anticipated",
				postEvent: "Paid after event",
				pix: "Pix",
				integratedFoodVoucher: "Food Voucher",
				mbway: "Mbway",
			},
			entranceConsumptions: "Entrance consumption",
			extraConsumptions: "Extra consumption",
			adjustedReceipt: "Adjusted revenue",
			rechargeReturned: "Recharges returned",
			invoicing: "Invoicing",
		},
		errorContent: "Error!",
		emptyContent: "There's nothing to show here",
		timelineCard: {
			consumption: "Consumption",
			period: "Period",
			recharge: "Recharges",
			zigCardConsumption: "Zig Card consumption",
			tableConsumption: "Table consumption",
			tooltipMessage: "{{titleMap}} in the period from {{start}} to {{end}}",
			value: "Value consumed",
		},
	},
	topClientsCard: {
		title: "Customers who consume the most",
		clientInfo: "Customer information",
		clients: "Customers",
		count: "Consumption",
	},
	vendorsResumeCard: {
		title: "Sales by vendors",
		name: "Vendors",
		totalSold: "Total sold",
	},
	topProductsPriceCard: {
		title: "Products by sales representation",
		allProductsSold: "All products sold",
		altNavlink: "Navigation link to report of all products sold",
		noData: "No data",
		other: "Others",
		products: "Products",
		quantity: "Quantity",
		consumption: "Consumption",
		unavailable: "Unavailable",
	},
	totalOpenedTable: "Total opened table",
	editEvent: {
		title: "Edit event",
		confirm: "Confirm",
		passTemplate: "Pass template ID",
	},
	totalOpenedTables: "Total opened tables",
	totalTables: "Total tables",
	zigMesaOperationMode: {
		message: "POS server status",
		online: "Online",
		local: "Local",
	},
	operationCard: {
		title: "Zig Table Operation",
		peopleSingle: "People",
		peoplePlural: "People",
		tableSingle: "Table",
		tablePlural: "Tables",
		time: "Time",
	},
};
