export const storageAlerts = {
	label: "Stock alerts",
	search: "Search products",
	btnAddEmail: "Configure alert reception",
	btnAddProductAlert: "Add alert for a product",
	addEmailModal: {
		title: "Configure receiving email alerts",
		name: "Name",
		email: "Email",
		emptyText: "No registered emails",
	},
	addAlertModal: {
		title: "Add new alert",
		storageAlert: {
			label: "Alert",
			storageIn: "Stock in",
			below: "Below",
		},
		storageCritic: {
			label: "Critical",
			storage: "Inventory",
			below: "Below",
		},
		add: "Create alert",
		invalidText:
			"The alert stock value cannot be less than or equal to the critical stock value",
	},
	columns: {
		product: "Product",
		storageQuantity: "Current inventory",
		storageAlert: {
			label: "Alert",
			storageIn: "Stock in",
			from: "from",
		},
		storageCritic: {
			label: "Critical",
			storage: "Inventory",
			from: "from",
		},
		actions: "Actions",
	},
	filters: {
		ok: "OK",
		storageAlert: "Alert state",
		storageCritic: "Critical condition",
	},
};
