export const withdrawConfirmation = {
	modal: {
		title: "Confirmação de solicitação de saque",
		message:
			"Para confirmar o saque, digite o código de aprovação enviado para o meio de confirmação configurado do place.",
		codeInput: { label: "Código", placeholder: "Digite aqui o código de confirmação" },
		resendCodeBtnLabel: "Não recebi meu código",
	},
	form: {
		errors: {
			onlyNumbers: "O código deve conter apenas números",
			minLength: "O código deve conter 6 dígitos",
		},
		submit: {
			success: "Solicitação aprovada com sucesso",
			error: "Código inválido ou expirado",
		},
	},
	resendConfirmationSuccessfully: "Código reenviado com sucesso",
	notification:
		"Sua solicitação foi processada com sucesso, mas precisa ser confirmada com o código enviado ao meio de confirmação configurado para o place.",
};
