import React from "react";
import { Tabs as TabsAntd } from "antd";
import { useTranslation } from "react-i18next";

import { NotificationsTabs } from "#components/notifications/types";
import { useNotifications } from "#components/notifications/notifications.context";
import {
	NotificationsContentEmptyState,
	NotificationsContentFooter,
	NotificationsContentInfo,
} from "..";

import s from "../../notifications-content.scss";
import {
	useListReadNotifications,
	useListUnreadNotifications,
} from "#resources/hooks/integrations/notifications";

export function NotificationsContentTabs() {
	const { t } = useTranslation("components", {
		keyPrefix: "notifications",
	});

	const {
		currentTab,
		onChangeTabs,
		unreadNotifications,
		readNotifications,
	} = useNotifications();

	const { hasNextPage: hasNextPageUnreadNotifications } = useListUnreadNotifications();
	const { hasNextPage: hasNextPageReadNotifications } = useListReadNotifications();

	const items = [
		{
			label: t("unread"),
			key: "unread",
			children: unreadNotifications?.pages?.some(p => p.data.length) ? (
				<>
					{unreadNotifications.pages.map((page, i) => (
						<React.Fragment key={i}>
							{page.data.map(notification => (
								<div key={notification.id}>
									<NotificationsContentInfo notification={notification} />
								</div>
							))}
						</React.Fragment>
					))}
					<NotificationsContentFooter showMore={hasNextPageUnreadNotifications} />
				</>
			) : (
				<NotificationsContentEmptyState />
			),
		},
		{
			label: t("read"),
			key: "read",
			children: readNotifications?.pages?.some(p => p.data.length) ? (
				<>
					{readNotifications.pages.map((page, i) => (
						<React.Fragment key={i}>
							{page.data.map(notification => (
								<div key={notification.id}>
									<NotificationsContentInfo notification={notification} />
								</div>
							))}
						</React.Fragment>
					))}
					<NotificationsContentFooter showMore={hasNextPageReadNotifications} />
				</>
			) : (
				<NotificationsContentEmptyState />
			),
		},
	];

	return (
		<TabsAntd
			className={s.tabsContainer}
			defaultActiveKey={currentTab}
			animated={false}
			items={items}
			onChange={(activeKey: NotificationsTabs) => {
				onChangeTabs(activeKey);
			}}
		/>
	);
}
