export const bills = {
	addBillSuccessNotification: "Conta criada com sucesso.",
	printTitle: {
		any: "Todas as contas",
		toPay: "Contas a pagar",
		toReceive: "Contas a receber",
		transfer: "Transferência",
		default: "Contas",
	},
	printTitleCaps: {
		any: "TODAS AS CONTAS",
		toPay: "CONTAS A PAGAR",
		toReceive: "CONTAS A RECEBER",
		transfer: "TRANSFERÊNCIAS",
	},
	competence: "Competência",
	dueDate: "Vencimento",
	addBill: "Adicionar conta",
	importSpreadsheet: "Importar planilha",
	exportSpreadsheet: "Exportar planilha",
	addTransfer: "Adicionar transferência",
	modalTransfer: {
		add: "Adicionar transferência",
		edit: "Editar transferência",
		description: "Descrição",
		date: "Data",
		value: "Valor",
		paid: "Pago?",
		sourceAccount: "Conta origem",
		destinationAccount: "Conta destino",
	},
	tableBill: {
		description: "Descrição",
		performDate: "Data de competência",
		dueDate: "Data de vencimento",
		account: "Conta de recebimento",
		value: "Valor",
		paid: "Pago?",
		expireToday: "Vence hoje",
		expired: "Vencido",
		notInformed: "Não informado",
	},
	tableTransfer: {
		description: "Descrição",
		date: "Data",
		value: "Valor",
		sourceAccount: "Conta de origem",
		destinationAccount: "Conta de destino",
		paid: "Pago?",
		actions: "Ações",
		popConfirm: "Tem certeza que deseja excluir esta transferência?",
	},
	billFields: {
		description: "Descrição",
		value: "Valor",
		dueDate: "Data de Competência",
		payDate: "Data de Pagamento",
		performDate: "Data de Vencimento",
		supplier: "Fornecedor",
		account: "Conta",
		category: "Categoria",
		accountType: "Tipo de Conta",
		coustCenter: "Centro de Custo",
		paymentMethod: "Método de Pagamento",
		observation: "Observação",
		interest: "Juros",
		fine: "Multa",
		discount: "Desconto",
		reconciled: "Reconciliado",
		payed: "Pago?",
		filename: "exemploDeContas",
	},
};
