import { autobind } from "core-decorators";
import { fetchModel } from "#helpers/fetch-model";
import { IError } from "#helpers/mobx/utils";
import { RootStore } from ".";
import { showErrorNotification, showSuccessNotification } from "#helpers/notifications";

import {
	BackOfficeChartAccountCreate,
	BackOfficeChartAccountResponse,
	BackOfficeChartAccountResponseTree,
	BackOfficeChartAccountUpdate,
	BackOfficeOperationChartAccount,
} from "#resources/api/enterprise-generated";

import enterprise from "#resources/api/enterprise-client";

import i18n from "#i18n/index";

const t = i18n.t;

@autobind
export class ChartOfAccountsStore {
	public rootStore: RootStore;

	public constructor(rootStore: RootStore) {
		this.rootStore = rootStore;
	}

	public clean() {
		this.getBackOfficeChartAccountTree.reset();
		this.getBackOfficeChartAccount.reset();
		this.getBackOfficeChartAccountTreeDefault.reset();
		this.getBackofficeChartAccounts.reset();
		this.createBackOfficeChartAccount.reset();
		this.updateBackOfficeChartAccount.reset();
		this.deleteBackOfficeChartAccount.reset();
		this.exportBackofficeDefaultChartAccountXlsx.reset();
		this.importBackofficeChartAccountXlsx.reset();
		this.getBackofficeNewChartAccountCode.reset();
	}

	//CHART OF ACCOUNTS
	public getBackOfficeChartAccountTree = new fetchModel<
		{},
		BackOfficeChartAccountResponseTree[]
	>({
		fnPromise: args => enterprise.getBackOfficeChartAccountTree(args),
		onError: (err: IError) => showErrorNotification(err.message),
	});

	public getBackOfficeChartAccountTreeDefault = new fetchModel<{}, any>({
		fnPromise: args => enterprise.getBackOfficeChartAccountTreeDefault(args),
		onError: (err: IError) => showErrorNotification(err.message),
	});

	public getBackOfficeChartAccount = new fetchModel<
		{ id: string },
		BackOfficeChartAccountResponse
	>({
		fnPromise: args => enterprise.getBackOfficeChartAccount(args),
		onError: (err: IError) => showErrorNotification(err.message),
	});

	public createBackOfficeChartAccount = new fetchModel<
		{
			chartAccount: BackOfficeChartAccountCreate;
		},
		BackOfficeChartAccountResponse
	>({
		fnPromise: args => enterprise.createBackOfficeChartAccount(args),
		onSuccess: () =>
			showSuccessNotification(t("org:chartOfAccounts.modalForm.status.successCreate")),
		onError: (err: IError) => showErrorNotification(err.message),
	});

	public setDefaultChartAccountToOrganizationBackOffice = new fetchModel<{}, any>({
		fnPromise: args => enterprise.setBackOfficeDefaultChartAccountToOrganization(args),
		onError: (err: IError) => showErrorNotification(err.message),
	});

	public getBackofficeChartAccounts = new fetchModel<
		{
			operation?: BackOfficeOperationChartAccount | null;
			acceptTransactions?: boolean | null;
			isDre?: boolean | null;
			isCashflow?: boolean | null;
		},
		BackOfficeChartAccountResponse[]
	>({
		fnPromise: args => enterprise.getBackofficeChartAccounts(args),
		onError: (err: IError) => showErrorNotification(err.message),
	});

	public getBackofficeNewChartAccountCode = new fetchModel<
		{ chartAccountParentId?: string | null },
		string
	>({
		fnPromise: args => enterprise.getBackofficeNewChartAccountCode(args),
		onError: (err: IError) => showErrorNotification(err.message),
	});

	public updateBackOfficeChartAccount = new fetchModel<
		{ chartAccountId: string; chartAccount: BackOfficeChartAccountUpdate },
		BackOfficeChartAccountResponse
	>({
		fnPromise: args => enterprise.updateBackOfficeChartAccount(args),
		onSuccess: () =>
			showSuccessNotification(t("org:chartOfAccounts.modalForm.status.successEdit")),
		onError: (err: IError) => showErrorNotification(err.message),
	});

	public deleteBackOfficeChartAccount = new fetchModel<{ chartAccountId: string }, void>({
		fnPromise: args => enterprise.deleteBackOfficeChartAccount(args),
		onSuccess: () =>
			showSuccessNotification(t("org:chartOfAccounts.modalForm.status.successExclude")),
		onError: (err: IError) => {
			if (err instanceof Error) {
				if (!err.message.includes("ChartAccountHasAssociationsError")) {
					return showErrorNotification(err.message);
				}
			}
		},
	});

	public importBackofficeChartAccountXlsx = new fetchModel<
		{ file: ArrayBuffer },
		BackOfficeChartAccountResponse[]
	>({
		fnPromise: args => enterprise.importBackofficeChartAccountXlsx(args),
		onError: (err: IError) => showErrorNotification(err.message),
	});

	public exportBackofficeDefaultChartAccountXlsx = new fetchModel<{}, string>({
		fnPromise: args => enterprise.exportBackofficeDefaultChartAccountXlsx(args),
		onError: (err: IError) => showErrorNotification(err.message),
	});
}
