import React from "react";

import s from "./button-select.module.scss";
import classNames from "classnames";

type ButtonSelectStates = "default" | "selected" | "disabled";

interface ButtonSelectProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
	state?: ButtonSelectStates;
}

export const ButtonSelect = ({
	state = "default",
	className,
	onClick,
	children,
	...props
}: ButtonSelectProps) => {
	return (
		<button
			{...props}
			disabled={state === "disabled"}
			aria-checked={(state === "selected").valueOf()}
			className={classNames(
				s.container,
				{
					[s.selected]: state === "selected",
				},
				className,
			)}
			onClick={e => {
				if (state === "disabled") return;

				onClick && onClick(e);
			}}
		>
			{children}
		</button>
	);
};
