import { browserHistory, RedirectFunction, RouterState } from "react-router";
import { syncHistoryWithStore as syncHistoryWithStoreMOBX } from "mobx-react-router";
import { rootStore } from "#stores/index";
import { clearQueryClient } from "#structure/providers/react-query";

export const history = syncHistoryWithStoreMOBX(browserHistory, rootStore.routerStore);

export async function requireAuth(
	nextState: RouterState,
	_replace: RedirectFunction,
	callback: () => void | undefined,
) {
	if (!rootStore.authStore.currentEmployee) {
		clearQueryClient();
		rootStore.routerStore?.push("/login");
	} else {
		await rootStore.organizationStore.fetchCurrentOrganization();
		if (nextState.params.placeId) {
			await rootStore.placeStore.setCurrentPlace(nextState.params.placeId);
		} else {
			await rootStore.placeStore.unsetCurrentPlace();
		}

		if (nextState.params.eventId) {
			await rootStore.eventStore.setCurrentEvent(nextState.params.eventId);
		} else {
			await rootStore.eventStore.unsetCurrentEvent();
		}
	}
	callback();
}

export function requireNotAuth() {
	if (rootStore.authStore.currentEmployee) {
		rootStore.routerStore.push("/");
	}
}

export async function updatePlaceAndEvent(
	_prevState: RouterState,
	nextState: RouterState,
	_replace: RedirectFunction,
	callback: () => void | undefined,
) {
	if (nextState.params.placeId) {
		await rootStore.placeStore.setCurrentPlace(nextState.params.placeId);
	} else {
		await rootStore.placeStore.unsetCurrentPlace();
	}

	if (nextState.params.eventId) {
		await rootStore.eventStore.setCurrentEvent(nextState.params.eventId);
	} else {
		await rootStore.eventStore.unsetCurrentEvent();
	}

	callback();
}
