export const tagGroups = {
	title: "Tag Groups",
	total: "Total",
	exportToExcel: "Export to excel",
	importConsumption: "Import Consumption",
	blockCards: {
		label: "Block Selected Tags",
		confirmationMessage:
			"You are about to block the selected tags. Are you sure you want to proceed?",
		confirmationButton: "Yes, block tags",
	},
	unblockCards: {
		label: "Unblock Selected Tags",
		confirmationMessage:
			"You are about to activate the selected tags. Are you sure you want to proceed?",
		confirmationButton: "Yes, activate tags",
	},
	filter: {
		zigCode: {
			label: "Filter by Zig Code",
			placeholder: "Zig Code",
		},
		group: {
			label: "Filter by Group",
			placeholder: "Group's name",
		},
		serial: {
			label: "Filter by Serial",
			from: "From:",
			to: "To:",
			placeholderFrom: "Ex: 1001",
			placeholderTo: "Ex: 2001",
		},
		status: {
			label: "Filter by Status",
			options: {
				all: "All",
				blocked: "Blocked",
				inUse: "In Use",
				used: "Used",
				inactive: "Inactive",
			},
		},
		userType: {
			label: "By User Type",
			options: {
				all: "All",
				anonymous: "Anonymous",
				nonAnonymous: "Non Anonymous",
			},
		},
	},
	table: {
		export: {
			fileName: "cards-group-event-{{eventName}}_{{eventDate}}",
			titleName: "Card's group from event {{eventName}}",
		},
		selectedItems: "Selected items:",
		columns: {
			zigCode: "Zig Code",
			serialNumber: "Serial Number",
			status: {
				label: "Status",
				blocked: "Blocked",
				inUse: "In Use",
				used: "Used",
				inactive: "Inactive",
			},
			user: {
				label: "User",
				anonymous: "Anonymous",
			},
			zigDeviceGroup: "Card Group",
			totalValue: "Balance",
			actions: {
				label: "Actions",
				block: "Block",
				blockCard: "Block Card",
				unblock: "Unblock",
				unblockCard: "Unblock Card",
				addConsumption: "Add consumption",
			},
		},
	},
	addConsumption: {
		title: "Top Up",
		confirmButton: "Confirm",
		fields: {
			rechargeType: {
				label: "Recharge Type",
			},
			balanceValue: {
				label: "Recharge Value",
				required: "The recharge value must be greater than zero",
			},
			reason: {
				label: "Reason",
				required: "Enter the reason",
			},
		},
	},
	topUpCancelation: {
		confirmation: {
			title: "Confirm Canceling Pending Top-Ups",
			description:
				"You are about to cancel all pending top-ups associated with this card. Confirm cancellation?",
			confirmButton: "Confirm Cancellation",
			cancelButton: "Go Back",
		},
		confirmationByBatch: {
			title: "Confirm Batch Top-Ups Cancellation",
			description:
				"You're about to cancel all pending top-ups for the selected cards. Proceed with the cancellation?",
			confirmButton: "Confirm Cancellation",
			cancelButton: "Go Back",
		},
		cancelationButton: "Cancel pending top-ups",
	},
};
