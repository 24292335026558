import { Collapse as CollapseUI, CollapseProps as CollapseUiProps } from "react-collapse";
import * as React from "react";

export type CollapseProps = CollapseUiProps;

export function Collapse({ initialStyle, ...props }: CollapseProps) {
	return (
		<CollapseUI
			initialStyle={{ height: 0, overflow: "hidden", ...initialStyle }}
			{...props}
		/>
	);
}
