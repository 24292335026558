import {
	ModalOnboarding,
	ModalOnboardingProps,
} from "#components/modal-onboarding/modal-onboarding";
import React from "react";
import { useTranslation } from "react-i18next";

import bannerImg from "./banner.png";

import s from "./modularization.module.scss";

type ModularizationOnboardingProps = Pick<ModalOnboardingProps, "onClose">;

const MODULARIZATION_URL = "https://bit.ly/Novo-Dash-Zig";

export const ModularizationOnboarding = ({ onClose }: ModularizationOnboardingProps) => {
	const { t } = useTranslation("components", {
		keyPrefix: "notifications.onboarding",
	});

	return (
		<ModalOnboarding
			bannerSrc={bannerImg}
			className={s.modal}
			title={<span dangerouslySetInnerHTML={{ __html: t("modularization.title") }} />}
			content={<span dangerouslySetInnerHTML={{ __html: t("modularization.content") }} />}
			onClose={onClose}
			actions={{
				primary: {
					label: t("modularization.primaryActionLabel"),
					onClick: () => {
						window.open(MODULARIZATION_URL, "_blank");
					},
				},
				secondary: {
					label: t("modularization.secondaryActionLabel"),
				},
			}}
		/>
	);
};
