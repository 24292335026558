export const vendorReport = {
	title: "Relatório delivery de vendors",
	sheet: {
		filename: "vendor-delivery-report",
		title: "Vendas delivery por vendor",
		columns: {
			vendor: "Vendor",
			value: "Valor",
			tip: "Gorjeta",
			deliveryFee: "Taxa de entrega",
		},
	},
	columns: {
		vendor: "Vendor",
		deliveryFee: "Taxa de entrega",
		tip: "Gorjeta",
		value: "Valor",
	},
};
