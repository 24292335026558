export const irregularCmvProducts = {
	label: "Custo unitário zerado",
	layout: {
		goBack: "Voltar",
	},
	table: {
		columns: {
			productId: "ID do Produto",
			name: "Produto",
			storage: "Estoque",
			storageId: "ID do Estoque",
			fiscalCode: "SKU",
			lastCost: "Último Custo",
			unitCost: "Custo Unitário",
			edit: "Editar",
		},
		actions: {
			edit: "Editar custo unitário",
		},
	},
	batchActions: {
		title: "Ações em lote",
		actions: {
			useLastCost: "Utilizar último custo",
			editCost: "Editar custo",
			editViaSpreadsheet: "Editar via planilha",
		},
		caption: "Selecione produtos para ações em lote",
		useLastCost: {
			title: "Utilizar último custo",
			description: "Os seguintes produtos serão afetados:",
			selectedProductsWithoutCost: "Há produtos sem último custo selecionados",
		},
	},
	updating: {
		title: "Atualizando custos",
		subtitle: "Aguarde, estamos atualizando os custos unitários",
	},
	editForm: {
		title: "Editar custo",
		inputs: {
			product: "Produto",
			storage: "Estoque",
			lastCost: "Último Custo",
			unitCost: "Custo Unitário",
			save: "Concluir",
			cancel: "Cancelar",
		},
		errors: {
			unitCostValueBiggerThanZero: "O custo unitário deve ser maior que 0",
		},
	},
	spreadsheetExportModal: {
		title: "Editar custo",
		fileName: "produtos-cmv-irregular-{{timestamp}}",
		exportingSuccess: "Planilha exportada com sucesso!",
		exportButton: "Clique aqui para baixar a planilha",
		currencyWarning:
			"Atenção! o valor dos produtos é informado em centavos, ex: 123 equivale a R$ 1,23",
		importButton: "Importar planilha",
		closeButton: "Fechar",
		successTitle: "Atualização realizada com sucesso",
		successSubtitle: "Produtos foram atualizados",
		errorTitle: "Ops... Algo não saiu como planejado.",
		errorSubtitle:
			"Não conseguimos realizar a atulização dos produtos. Tente novamente mais tarde.",
	},
};
