import * as React from "react";
import s from "./checkbox.scss";
import classnames from "classnames";

interface IInputProps extends React.InputHTMLAttributes<HTMLInputElement> {
	label?: string | { value: string; info?: string };
	indeterminate?: boolean;
}

export const Checkbox = React.forwardRef<HTMLInputElement, IInputProps>(
	({ label, checked, indeterminate, className, onChange: handleChange, ...props }, _) => {
		const [isChecked, setChecked] = React.useState(checked);
		const checkboxRef = React.createRef<HTMLInputElement>();

		React.useEffect(() => {
			if (checkboxRef && checkboxRef.current) {
				checkboxRef.current.indeterminate = Boolean(indeterminate);
			}
		}, [indeterminate]);

		React.useEffect(() => {
			setChecked(checked);
		}, [checked]);

		return (
			<div className={classnames(s.checkbox, className)}>
				<input
					ref={checkboxRef}
					type="checkbox"
					checked={isChecked}
					onChange={ev => {
						handleChange?.(ev);
						setChecked(ev.target.checked);
					}}
					{...props}
				/>
				<span className={s.customMark} />
				<label htmlFor={props.id}>{label}</label>
			</div>
		);
	},
);
