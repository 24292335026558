export const abcPurchaseReport = {
	title: "Relatório ABC de Compras",
	label: "ABC de Compras",
	description:
		"O método da curva ABC na gestão do estoque vai revelar principalmente a importância de cada item para a empresa - ou seja, quais são as mercadorias mais caras que precisam de atenção no controle e negócios do custo durante o processo de compras.",
	export: "Download em Excel",
	filterByPlaceholder: "Todas as curvas",
	contentTable: {
		emptyReports: "Não há relatórios disponíveis",
		totalizers: {
			totalValues: "Valores totais dos produtos",
			curveA: "Curva A",
			curveB: "Curva B",
			curveC: "Curva C",
			tooltips: {
				curveA:
					"Produtos com valor alto valor que correspondem à até até 80% do valor das compras",
				curveB:
					"Produtos com valor intermediário que correspondem à até 15% do valor das compras",
				curveC: "Produtos com baixo valor que correspondem à até 5% do valor das compras",
			},
		},
		columns: {
			name: "Produto ou Insumo",
			quantity: "Qtd. Comprada",
			unitMeasurement: "Unidade de Medida",
			unitaryValue: "Custo Unitário",
			total: "Custo Total",
			cumulateTotal: "Total Acumulado",
			percentage: "Representação Individual",
			cumulativePercentage: "Representação Acumulada",
			tier: "Classificação",
		},
	},
};
