import { AsyncStore } from "#helpers/mobx/utils";
import { action, observable } from "mobx";
import { showErrorNotification, showSuccessNotification } from "#helpers/notifications";
import { autobind } from "core-decorators";
import { IPromiseBasedObservable } from "mobx-utils";
import { fetchModel } from "#helpers/fetch-model";
import {
	DeviceConfig,
	DeviceEventStatus,
	EmployeeDeviceConfigsUpdate,
} from "#resources/api/enterprise-generated";
import enterprise from "#api/enterprise-client";
import i18n from "#i18n/index";

type TDeviceHooks =
	| "get"
	| "create"
	| "delete"
	| "removeAll"
	| "removeFromEvent"
	| "getStatus";

const t = i18n.t;

@autobind
export class DeviceStore extends AsyncStore<TDeviceHooks> {
	@observable
	public addDevicePromise: IPromiseBasedObservable<void> | null = null;

	@observable
	public allDevicesStatus: DeviceEventStatus[] = [];

	@action
	public async removeDeviceFromEvent(deviceId: string, eventId: string) {
		return await this.doAsyncAction("removeFromEvent", async () => {
			try {
				await enterprise.removeDeviceFromEvent({ deviceId, eventId });
				await this.getDeviceStatusAtEvent(eventId);
				showSuccessNotification(t("store:deviceStore.removeDeviceFromEvent"));
			} catch (err) {
				if (err instanceof Error) {
					showErrorNotification(err.message);
				}
			}
		});
	}

	@action
	public getDeviceStatusAtEvent = async (eventId: string) => {
		try {
			this.allDevicesStatus = await enterprise.getDeviceEventStatus({ eventId });
		} catch (err) {
			if (err instanceof Error) {
				console.error(err.message);
				showErrorNotification(err.message);
			}
		}
	};

	@action
	public updateEmployeeDeviceConfigs = async (
		employeeIds: string[],
		configs: EmployeeDeviceConfigsUpdate,
	) => {
		try {
			await enterprise.updateMultipleEmployeeDeviceConfigs({
				employeeIds: Array.from(new Set(employeeIds)),
				config: configs,
			});
			showSuccessNotification(t("store:deviceStore.updateEmployeeDeviceConfigs"));
		} catch (err) {
			if (err instanceof Error) {
				showErrorNotification(err.message);
			}
		}
	};
	public devices = new fetchModel<string, DeviceConfig[]>({
		fnPromise: placeId => enterprise.getPlaceDevices({ placeId }),
		onError: err => showErrorNotification(err.message),
	});

	public addDevice = new fetchModel<
		{ fingerPrint: string[]; placeId: string },
		DeviceConfig[]
	>({
		fnPromise: arg =>
			enterprise.linkDevicesToPlace({ placeId: arg.placeId, smallIds: arg.fingerPrint }),
		onSuccess: res => {
			const newArray = [...(this.devices.value || []), ...res];
			const removeRepeateds = newArray.reduce<{ [key: string]: DeviceConfig }>(
				(acc, cur) => {
					acc[cur.id] = cur;
					return acc;
				},
				{},
			);
			this.devices.value = Object.values(removeRepeateds);
			showSuccessNotification(t("store:deviceStore.addDevice"));
		},
		onError: err => showErrorNotification(err.message),
	});
}
