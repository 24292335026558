export const employees = {
	tabs: {
		employees: {
			label: "Team",
			search: "Search",
			filter: "Filter by places",
			addModal: "Add employee",
			importModal: {
				label: "Import employee",
				sheetFileName: "Employee",
				buttonText: "Choose a worksheet",
				role: "Office",
				places: "Places and Bars",
				permissions: "Permissions",
				columns: {
					name: "Name",
					password: "Password",
					username: "Username",
					fichasAmount: "Number of ways in tokens",
					zigTag: "ZigTag",
				},
				selectPlace: "At least one location must be selected",
				requiredField: "This field is mandatory",
				custom: "Custom",
				errorNotification: "No worksheet selected",
				passwordRequirements: `Employees with the permission "Access to Dashboard" must follow the following rules for password: minimum of 6 characters, at least one letter.`,
			},
			multipleEmployeeModalError: {
				success: "Successfully created",
				successMessage: "Login employees {{logins}} have been created",
				error: "Failed",
				errorMessage: "Unable to create login employees {{logins}}. Login already exists",
			},
			editButton: "Edit employee",
			exportButton: "Export employees",
			deleteButton: "Remove employees",
			edit: {
				label: "Edit employee",
				data: {
					label: "Employee data",
					name: "Employee name",
					login: "Login",
					password: "Password",
					zigCard: "Zig Card",
					employeeNumber: {
						label: "Add more than one employee",
						tooltip: "Only for more than one employee within the same position",
						start: "In",
						end: "Up until",
					},
					photo: "Photo",
				},
				extra: {
					label: "Extra information",
					fichasAmount: {
						label: "Number of ways on record",
						placeholder: "Amount of ways",
					},
					type: "Employee type",
				},
				places: {
					label: "Places",
					selectedPlace: "Places",
					selectedBar: {
						label: "Bars",
						placeholder: "None",
					},
					mainBar: "Main bar / point of sale",
				},
				role: {
					label: "Job roles and Permissions",
					employeeRole: {
						label: "Office",
						placeholder: "Choose a job role",
						none: "None",
						custom: "Custom",
					},
					permissions: "Permissions",
				},
				seeProducts: {
					label: "See bar products",
					blockedBar: {
						label: "Block bars",
						placeholder: "None",
					},
				},
			},
			editMultiple: {
				label: "Edit employee ({{count}})",
				rename: "Rename",
				number: "Number from",
				role: "Change job role",
				places: "Places",
				bar: "Bars",
				fichasAmount: "Number of ways on record",
				info: "Unfilled fields will have their initial values kept",
				confirm: "Do you want to modify the following collaborators?",
				none: "None",
				mainBar: "Main bar",
			},
			employeeDelete: {
				text:
					"Do you really want to delete {{name}} ({{role}})'? This action cannot be undone",
				customized: "Custom position",
			},
			deleteModal: {
				title: "Do you want to remove {{count}} employee(s)?",
				content: "Heads up! Employees will be permanently removed.",
			},
			checkAll: "Select employee ({{counter}})",
			uncheckAll: "Deselect all employee ({{counter}})",
			columns: {
				name: "Employee",
				username: "Login",
				role: "Job role",
				places: {
					label: "Places",
					none: "No place selected",
				},
				bar: {
					label: "Bar",
					none: "No bar selected",
				},
				zigtag: "ZigTag",
				custom: "Costum",
			},
			createEmployee: {
				fakeBarBlocklistPermission: "Restrict view of menu products",
				noSelectedPlace: "Select a place to enable this permission",
				onlyOneSelect: "To enable this permission, select only one location",
				validation: {
					name: "Please enter a valid name",
					login: "Please enter a valid login",
					role: "Select a role",
					password: "Please enter a valid password",
					same: "The values filled in are the same",
					twoValues: "Both values must be filled",
					negative: "Values cannot be negative",
					initialValueSmaller: "Initial value must be less than final",
					selectedPlaces: "At least one location is required",
				},
				menuVisualization: {
					title: "Menu visualization",
					label: "Types",
					placeholder: "Select menu visualization type",
					type: {
						grid: "Default",
						smallGrid: "Small photo",
					},
				},
			},
			importSuccess: "Employees imported successfully",
			deleteWarning: "Oops! A problem occurred, try again",
			addEmployeeZigtagError: "Error adding collaborator with Zigtag",
		},
		roles: {
			label: "Job roles",
			search: "Search by job role",
			create: "Create job role",
			edit: "Edit job role",
			removeRole: "Are you sure you want to remove the job role?",
			roleError: "Job role not found, please try again",
			isNameInvalid: "The fields were not filled in correctly",
			columns: {
				role: "Job roles",
				employees: "Number of employees",
				options: "Options",
				none: "None",
			},
			modal: {
				roleName: {
					label: "Name of the job role",
					error: "Invalid name",
					placeholder: "Ex: Waiter",
				},
				importPermissions: {
					label: "Import permissions",
					placeholder: "Ex: box",
				},
				permissions: "Permissions",
				placesBar: {
					place: "Places",
					chooseLocal: "Choose a place",
					bar: "Bar",
					chooseBar: "Choose a bar",
					none: "None",
					blocked: "Block bars",
					chooseMinimum: "Choose at least one bar",
				},
			},
		},
	},
};
