import { action, observable } from "mobx";
import * as ReactGA from "react-ga";
import { RootStore } from ".";
import { fromPromise, IPromiseBasedObservable } from "#helpers/mobx-utils";
import { autobind } from "core-decorators";
import { showErrorNotification, showSuccessNotification } from "#helpers/notifications";
import { errorTap } from "#helpers/errors";
import { PlaceCombo } from "#resources/api/enterprise-generated";
import enterprise from "#resources/api/enterprise-client";
import i18n from "#i18n/index";

const t = i18n.t;

@autobind
export class ComboStore {
	@observable
	public combos: PlaceCombo[] = [];
	@observable
	public createComboPromise: IPromiseBasedObservable<void> | null = null;
	@observable
	public fetchCombosPromise: IPromiseBasedObservable<PlaceCombo> | null = null;
	@observable
	public deleteComboPromise: IPromiseBasedObservable<void> | null = null;
	@observable
	public editComboPromise: IPromiseBasedObservable<void> | null = null;

	public rootStore: RootStore;

	constructor(rootStore: RootStore) {
		this.rootStore = rootStore;
	}

	public clean() {
		this.combos = [];
		this.createComboPromise = null;
		this.fetchCombosPromise = null;
		this.deleteComboPromise = null;
		this.editComboPromise = null;
	}

	@action
	public createCombo = (combo: PlaceCombo, data: Buffer, placeId: string) => {
		const { ...restCombo } = combo;

		this.createComboPromise = fromPromise(
			enterprise
				.addNewCombo({ placeId, combo: { ...restCombo }, data })
				.then(() => {
					ReactGA.event({
						category: "User",
						action: "create combo",
					});

					this.rootStore.placeStore!.fetchPlace(placeId);
					showSuccessNotification(t("store:comboStore.createCombo"));
				})
				.catch(errorTap((err: Error) => showErrorNotification(err.message))),
		);
	};

	@action
	public deleteCombo = (comboId: string, placeId: string) => {
		this.deleteComboPromise = fromPromise(
			enterprise
				.deleteCombo({ id: comboId })
				.then(_ => {
					ReactGA.event({
						category: "User",
						action: "delete combo",
					});

					this.rootStore.placeStore.fetchPlace(placeId);
					showSuccessNotification(t("store:comboStore.deleteCombo"));
					this.combos = this.combos.filter(comb => comb.id !== comboId);
				})
				.catch(errorTap((err: Error) => showErrorNotification(err.message))),
		);
	};

	@action
	public editCombo = async (
		combo: PlaceCombo,
		placeId: string,
		data?: Buffer,
		HideSuccessMessage?: boolean,
	) => {
		const { ...restCombo } = combo;
		this.editComboPromise = fromPromise(
			Promise.all([
				enterprise.editCombo({ comboId: combo.id, placeId, combo: { ...restCombo } }),
				data && enterprise.editComboImage({ comboId: combo.id, data }),
			])
				.then(async () => {
					ReactGA.event({
						category: "User",
						action: "edit combo",
					});
					this.rootStore.placeStore.fetchPlace(placeId);
					if (HideSuccessMessage === false)
						showSuccessNotification(t("store:comboStore.editCombo"));
				})
				.catch(errorTap((err: Error) => showErrorNotification(err.message))),
		);

		await this.editComboPromise;
	};
}
