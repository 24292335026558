export const tabTaxCouvert = {
	emptyTaxCouvertMessage: "Não existem dados fiscais de couvert a serem mostrados",
	infosTaxCouvert: {
		cest: "CEST",
		cfop: "CFOP",
		code: "SKU",
		group: "Grupo",
		ncm: "NCM",
		profile: "Perfil",
	},
	searchPlaceholder: "Pesquisar dados fiscais de couvert",
};
