import React from "react";
import { useTranslation } from "react-i18next";

import { Typography } from "#components/primitive";
import CloseIcon from "#assets/svg/close.svg";
import { useNotifications } from "#components/notifications/notifications.context";

import s from "../../notifications-content.scss";

export function NotificationsContentHeader() {
	const { t } = useTranslation("components", {
		keyPrefix: "notifications",
	});

	const { onCloseNotifications } = useNotifications();

	return (
		<div className={s.headerContainer}>
			<Typography type="paragraph" variant="v2" className={s.headerTitle}>
				{t("title").toUpperCase()}
			</Typography>
			<div className={s.closeIcon} onClick={onCloseNotifications}>
				<CloseIcon />
			</div>
		</div>
	);
}
