export const soldProducts = {
	tabTitle: "Total de produtos vendidos",
	title: "Produtos Vendidos",
	filter: "Filtrar por tipo de transação",
	fileName: "total-produtos-vendidos",
	printTitle: "Produtos vendidos",
	table: {
		fiscalCode: "SKU",
		name: "Nome",
		category: "Categoria",
		mountableProduct: "Montável",
		mountableProducts: "Montáveis",
		count: "Quantidade",
		unitValue: "Valor unitário",
		subtotal: "Subtotal",
		totalDiscount: "Descontos",
		totalValue: "Valor total",
		product: "Produto",
		productCategory: "Categoria Produto",
		mountableStage: "Etapa Montável",
		mountableItem: "Item Montável",
		unitCount: "Quantidade Unit.",
		fracionalCount: "Quantidade Frac.",
		unitaryValue: "Valor unitário",
		mountableSection: "Sessão Montável",
	},
	filterByProducts: "Filtrar por produtos",
	filterByProductsPlaceholder: "Todos",
	filterByOperationType: "Filtrar por tipo de operação",
	filterByOperationTypePlaceholder: "Todas",
	filterByProductKind: "Filtrar por tipo de produto",
	productKindOptions: {
		food: "Alimentos",
		drinks: "Bebidas",
		tickets: "Bilheterias",
		other: "Outros",
	},
};
