export const tabTaxGroups = {
	addNewTaxGroup: "Adicionar novo grupo",
	emptyTaxGroupsMessage: "Não existem grupos fiscais de produto a serem mostrados",
	infosTaxGroup: {
		aliquot: "Aliquota",
		cfop: "CFOP",
		tax: "Tax",
		type: "Tipo",
	},
	searchPlaceholder: "Pesquisar grupos fiscais de produto",
	typeLabel: {
		invoice: "Fatura",
		service: "Serviço",
		vat: "Value Added Tax",
	},
};
