export const orangeScreenReport = {
	title: "Relatório de Tela Laranja",
	subtitle: "Lista com todas as ocorrências no período do evento.",
	filters: {
		employee: {
			label: "Nome de operador",
			placeholder: "Digite o nome do operador",
		},
		deviceFingerprint: {
			label: "PDV ID",
			placeholder: "Digite o ID do PDV",
		},
	},
	columns: {
		date: "Data/hora da ocorrência",
		employee: "Operador",
		deviceId: "PDV ID",
		zigDeviceUid: "ID do Cartão",
	},
	filename: "relatório-de-tela-laranja",
};
