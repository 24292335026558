export const productReport = {
	title: "Relatório delivery por produto",
	columns: {
		name: "Produto",
		sku: "SKU",
		count: "Quantidade",
		totalValue: "Preço total",
		baseValue: "Preço base",
		integration: "Integração",
	},
	vendors: {
		coala: "Whitelabel",
		ifood: "Ifood",
		rappi: "Rappi",
		ubereats: "Uber eats",
	},
	sheet: {
		filename: "relatório-delivery",
		generalProductReport: {
			title: "Relatório de produtos delivery",
			columns: {
				name: "Produto",
				sku: "SKU",
				count: "Quantidade",
				totalValue: "Preço total",
				baseValue: "Preço base",
				integration: "Integração",
			},
		},
		allOrdersProductsReport: {
			title: "Relatório de pedidos de produtos",
			columns: {
				name: "Produto",
				sku: "SKU",
				count: "Quantidade",
				baseUnitPrice: "Preço base",
				totalUnitPrice: "Preço total",
				obs: "Observação",
				deliveryOrderId: "Identificador da ordem",
				productId: "Identificador do produto",
			},
		},
	},
};
