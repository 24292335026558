import { abcPurchaseReport } from "./abc-purchase-report";
import { abcSupplierReport } from "./abc-supplier-report";

export const abcReports = {
	abcPurchaseReport,
	abcSupplierReport,
	header: {
		filter: {
			period: "Período",
			curves: {
				label: "Filtrar por",
				placeholder: "Todas as curvas",
			},
		},
		searchPlaceholderProducts: "Buscar por produto ou insumo",
		searchPlaceholderSuppliers: "Buscar por fornecedor",
	},
};
