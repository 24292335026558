import { RootStore } from ".";
import { fetchModel } from "#helpers/fetch-model";
import enterprise from "#resources/api/enterprise-client";
import { showErrorNotification } from "#resources/helpers/notifications";
import {
	CardHistoryPagination,
	EmployeeCardsPagination,
	GetPasswordCardsFromPlaceFilters,
	Pagination,
} from "#resources/api/enterprise-generated";

export class PasswordCardStore {
	public rootStore: RootStore;

	constructor(rootSore: RootStore) {
		this.rootStore = rootSore;
	}

	public getPasswordCardsFromPlace = new fetchModel<
		{
			placeId: string;
			pagination: Pagination;
			filters: GetPasswordCardsFromPlaceFilters;
		},
		EmployeeCardsPagination
	>({
		fnPromise: args => enterprise.getPasswordCardsFromPlace(args),
		onError: err => showErrorNotification(err.message),
	});

	public getPasswordCardHistory = new fetchModel<
		{
			passwordCardId: string;
			pagination: Pagination;
		},
		CardHistoryPagination
	>({
		fnPromise: args => enterprise.getPasswordCardHistory(args),
		onError: err => showErrorNotification(err.message),
	});

	public blockPasswordCards = new fetchModel<
		{
			passwordCardIds: string[];
		},
		void
	>({
		fnPromise: args => enterprise.blockPasswordCards(args),
		onError: err => showErrorNotification(err.message),
	});

	public deleteEmployeePasswordCard = new fetchModel<
		{
			passwordCardIds: string[];
		},
		void
	>({
		fnPromise: args => enterprise.deleteEmployeePasswordCard(args),
		onError: err => showErrorNotification(err.message),
	});
}
