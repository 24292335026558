export const awards = {
	title: "Prêmios",
	description: "Selecione os produtos e os descontos que o cliente terá direito.",
	maxUses: {
		label: "Quantidade máxima de usos por ganho",
		withoutLimits: "Sem limites",
	},
	discountType: {
		label: "Tipo do desconto",
		percentage: "Porcentagem",
		value: "Valor",
		error: "Os seguintes produtos possuem valor menor que o desconto dado",
		table: {
			product: "Produto",
			price: "Preço de venda",
			promotionalPrice: "Preço promocional",
			error: "Valor máximo e mínimo não corresponde",
			empty: {
				label: "Nenhum produto selecionado",
				description:
					"Caso não selecione produtos, a promoção funcionará em todos os produtos.",
			},
			total: "Total de {{count}} produto(s)",
			add: "Adicionar produtos",
		},
	},
};
