export const productArea = {
	titles: {
		productArea: "Área do produto",
		editProduct: "Editar produto",
		addInput: "Adicionar insumo",
		editTechnicalSheet: "Editar ficha técnica",
	},
	tabs: {
		productDetail: "Detalhes do produto",
		technicalSheet: "Ficha técnica",
	},
};
