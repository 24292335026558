import { isValid } from "cpf";
import { IWrapperValidate } from "#components/primitive";
import { validate } from "uuid";

import { StepperErros, StepperTouched, StepperWarnings } from "#components/stepper";
import { get } from "lodash-es";
import { phoneWithDDDMask } from "./use-masks";

// eslint-disable-next-line max-len
export const cpfRegex = /\d{3}[\.]?\d{3}[\.]?\d{3}[-]?\d{2}/;
export const cnpjRegex = /\d{2}[\.]?\d{3}[\.]?\d{3}[\/]?\d{4}[-]?\d{2}/;
export const emailRegex = /\S+@\S+\.\S+/;
export const phoneRegex = /\+? ?[1-9]{2}[\(]?[1-9]{2}[\)]? ?9\d{4}[\-]?\d{4}/;

export const bankAccountAgencyRegex = /\d{4}/;

export type Error<T> = {
	[field in keyof T]?: any;
};

export type Touched<T> = {
	[field in keyof T]?: any;
};

export type ValidateStepperField<T> = (
	errors: StepperErros<T>,
	warnings: StepperWarnings<T>,
	touched: StepperTouched<T>,
	field: keyof T,
) => IWrapperValidate | undefined;

export function useValidations<Values>() {
	function validateFormikField(
		errors: Error<Values>,
		touched: Touched<Values>,
		field: keyof Values,
	): IWrapperValidate | undefined {
		if (errors[field] && touched[field]) {
			return { type: "error", value: errors[field] };
		}
	}

	function validateStepperField(
		errors: StepperErros<Values>,
		warnings: StepperWarnings<Values>,
		touched: StepperTouched<Values>,
		field: GlobalTypes.ExtractKeys<Values>,
	): IWrapperValidate | undefined {
		const error = get(errors, field);
		const isTouched = !!get(touched, field);
		const warning = get(warnings, field);

		if (!!error && isTouched && typeof error === "string") {
			return { type: "error", value: error };
		}

		if (!!warning && isTouched && typeof warning === "string") {
			return { type: "warning", value: warning };
		}
	}

	function validateCpf(cpf: string) {
		cpf = cpf.replace(/\D+/g, "");
		return isValid(cpf) && cpfRegex.test(cpf);
	}

	function validateCnpj(cnpj: string) {
		cnpj = cnpj.replace(/\D+/g, "");
		return cnpjRegex.test(cnpj);
	}

	function validateEmail(email: string) {
		return emailRegex.test(email);
	}

	function validatePhone(phone: string) {
		return phoneWithDDDMask.test(phone);
	}

	function validateUuid(uuid: string) {
		return validate(uuid);
	}

	function validateBankAccountAgency(agency: string) {
		return bankAccountAgencyRegex.test(agency);
	}

	return {
		validateFormikField,
		validateStepperField,
		validateCpf,
		validateCnpj,
		validateEmail,
		validatePhone,
		validateUuid,
		validateBankAccountAgency,
	};
}
