export const debtorsReport = {
	title: "Contas em Aberto",
	filters: {
		vendor: "Vendor",
		period: "Período",
	},
	table: {
		title: "Relatório de Contas em Aberto",
		filename: "relatorio_de_contas_em_aberto__{{vendor}}__{{date}}",
		totalizer: {
			totalOpen: "Total em Aberto",
			paidAfterEvent: "Pago Após Evento",
			clients: "Clientes",
			stillOpen: "Ainda em Aberto",
		},
		columns: {
			userName: "Nome",
			userDocument: "CPF",
			phone: "Telefone",
			totalOpen: "Total em Aberto",
			paidAfterEvent: "Pago Após Evento",
			stillOpen: "Ainda em Aberto",
		},
	},
};
