export const totalByClient = {
	label: "Total por cliente",
	title: "Total de vendas por cliente",
	table: {
		title: "Consumo de mesas",
		columns: {
			name: "NOME",
			document: "CPF",
			openedAt: "ABERTURA",
			closedAt: "FECHAMENTO",
			payment: "PAGAMENTO",
			serviceValue: "VALOR DO SERVIÇO",
			discount: "DESCONTOS",
			refundValue: "ESTORNOS",
			totalValue: "TOTAL",
			products: {
				imageUrl: "FOTO",
				sku: "SKU",
				name: "NOME DO PRODUTO",
				tableName: "MESA",
				payment: "PAGAMENTO",
				quantity: "QUANTIDADE",
				unitValue: "VALOR UNITÁRIO",
				totalValue: "VALOR TOTAL",
			},
		},
	},
};

export type TotalByClientLocale = typeof totalByClient;
