export const general = {
	label: "Relatório geral",
	eventDate: "Data do evento",
	filters: {
		table: "Mesa",
		employeeName: "Garçom",
		payment: "Pagamento",
		tablePlaceholder: "Selecione uma mesa",
		employeeNamePlaceholder: "Todos",
		status: "Status",
		paymentPlaceholder: "Todos",
		dateOrPeriod: "Data ou período",
	},
	resume: {
		people: "Pessoas",
		totalRevenue: "Faturamento total",
		openTables: "Mesas em uso",
		tablesUsed: "Mesas Utilizadas",
		chargeback: "Estornados",
		averageTicket: "Ticket médio",
		averageLengthStay: "Tempo Médio de Permanência",
		hours: "horas e",
		minutes: "minutos",
		info: "Este valor já inclui a taxa de serviço.",
	},
	table: {
		title: "Consumo de mesas",
		allPayments: "Ver todos os pagamentos",
		columns: {
			tableName: "MESA",
			employeeName: "GARÇOM",
			numberOfSeats: "PESSOAS",
			permanence: "PERMANÊNCIA",
			tip: "SERVIÇO",
			productsValue: "PRODUTOS",
			openedAt: "ABERTURA",
			closedAt: "FECHAMENTO",
			discount: "DESCONTOS",
			refundValue: "ESTORNOS",
			totalValue: "TOTAL",
			icon: "CONTA",
			buyers: {
				name: "NOME",
				document: "CPF",
				paymentType: "PAGAMENTO",
				total: "TOTAL",
			},
		},
	},
	paymentTypes: {
		CreditCard: "Crédito",
		DebitCard: "Débito",
		Pix: "Pix",
		Cash: "Dinheiro",
	},
};
