export const tabTaxProfiles = {
	addNewTaxProfile: "Add new profile",
	emptyTaxProfilesMessage: "There are no tax profiles to show",
	infosTaxProfile: {
		cnpj: "CNPJ",
		corporateName: "Corporate name",
		fantasyName: "Fantasy name",
		stateRegistration: "State registration",
		taxRegime: "Tax regime",
		phone: "Phone",
		email: "E-mail",
	},
	optionsTaxation: {
		normalRegime: "Normal regime",
		simpleNational: "Simple national",
		simpleNationalExcess: "Simple national excess",
	},
	searchPlaceholder: "Search tax profile",
};
