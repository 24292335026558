import React, { ComponentType } from "react";
import { useGTMEvent, UseGTMEventProps } from "./use-gtm-event";

export const withGTMEvents = <P extends UseGTMEventProps>(
	WrappedComponent: ComponentType<P>,
): React.FC<Omit<P, keyof UseGTMEventProps>> => props => {
	const gtmProps = useGTMEvent();

	return <WrappedComponent {...(props as P)} {...gtmProps} />;
};
