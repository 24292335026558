export const debtorsReport = {
	title: "Open Invoices",
	filters: {
		vendor: "Vendor",
		period: "Period",
	},
	table: {
		title: "Open Invoices Report",
		filename: "open_invoices_report__{{vendor}}__{{date}}",
		totalizer: {
			totalOpen: "Total Open",
			paidAfterEvent: "Paid After Event",
			clients: "Customers",
			stillOpen: "Still Open",
		},
		columns: {
			userName: "Name",
			userDocument: "Document",
			phone: "Phone",
			totalOpen: "Total Open",
			paidAfterEvent: "Paid After Event",
			stillOpen: "Still Open",
		},
	},
};
