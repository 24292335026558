export const soldBaseProductReport = {
	tabTitle: "General output of products",
	filename: "product_general_output_report",
	emptyText: "No data to display.",
	table: {
		code: "SKU",
		product: "Product",
		count: "Quantity",
	},
};
