export const prechargeReports = {
	title: "Top-up Reports",
	filterByDate: "Filter by date",
	cards: {
		retrievedRecharge: "Redeemed Top-Ups",
		consumedValue: "Utilized Top-Ups",
		avgRechargedValue: "Avg. Top-up Amount",
		avgRetrievedValueByDay: "Avg. Redeemed/Day",
		gender: {
			label: "Gender Division Top-Ups",
			male: {
				title: "{{percent}}% of Men",
				acronym: "M",
			},
			female: {
				title: "{{percent}}% of Women",
				acronym: "W",
			},
			other: {
				title: "{{percent}}% Other",
				acronym: "O",
			},
		},
	},
	table: {
		exportToExcel: {
			title: "{{placeName}}_{{date}}",
			filename: "{{placeName}}_{{date}}",
		},
		columns: {
			date: "Date",
			maleQuantity: "Total Top-Ups - Men",
			femaleQuantity: "Total Top-Ups - Women",
			otherQuantity: "Total Top-Ups - Others",
			topUpQuantity: "Total Quantity Topped-Up",
			redeemedQuantity: "Total Quantity Redeemed",
			accumulatedValue: "Total Accumulated",
			bonusValue: "Bonus Amount",
			redeemedValue: "Total Redeemed",
			usedValue: "Total Utilized",
		},
	},
};
