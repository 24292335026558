import React from "react";

import { useOnClickOutside } from "#resources/hooks";
import { NotificationsContentHeader, NotificationsContentTabs } from "./components";

import { useNotifications } from "../notifications.context";
import s from "./notifications-content.scss";

export function NotificationsContent() {
	const { onCloseNotifications } = useNotifications();
	const containerRef = React.useRef<HTMLDivElement>(null);

	useOnClickOutside(containerRef, onCloseNotifications, "mousedown");

	return (
		<div className={s.container} ref={containerRef}>
			<NotificationsContentHeader />
			<NotificationsContentTabs />
		</div>
	);
}
