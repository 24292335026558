export const chipsBurned = {
	byEmployee: {
		tabTitle: "Fichas queimadas por funcionário",
		filename: "fichas-queimadas-funcionario",
		printTitle: "Fichas queimadas por funcionário",
	},
	tabTitle: "Total de fichas queimadas",
	title: "Total de fichas queimadas",
	fileName: "total-fichas-queimadas",
	printTitle: "Total de fichas queimadas",
	table: {
		name: "Nome",
		count: "Quantidade",
		unitValue: "Valor unitário",
		totalValue: "Total",
		product: "Produto",
		unitCount: "Quantidade Unit.",
		fracionalCount: "Quantidade Frac.",
		unitaryValue: "Valor unitário",
	},
};
