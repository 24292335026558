import { createQueryFactory } from "../query-factory";
import { PRODUCTS_KEYS } from "./keys";

export const { useApiHook: usePlaceProducts } = createQueryFactory(
	"getPlaceProducts",
	{},
	PRODUCTS_KEYS.getPlaceProducts,
);

export const { useApiHook: useAllProducts } = createQueryFactory("getAllProducts");

export const { useApiHook: usePlaceProductsByType } = createQueryFactory(
	"getPlaceProductsByType",
	{},
	PRODUCTS_KEYS.getPlaceProductsByType,
);

export const { useApiHook: useProductsSoldByBarReport } = createQueryFactory(
	"productsSoldByBarReport",
	{
		retry: false,
		throwOnError() {
			return false;
		},
	},
);
