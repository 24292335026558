export const soldProducts = {
	tabTitle: "Total products sold",
	title: "Products Sold",
	filter: "Filter by transaction type",
	fileName: "total-products-sold",
	printTitle: "Products sold",
	table: {
		fiscalCode: "SKU",
		name: "Name",
		category: "Category",
		mountableProduct: "Mountable",
		mountableProducts: "Mountable",
		count: "Quantity",
		unitValue: "Unit value",
		subtotal: "Subtotal",
		totalDiscount: "Discounts",
		totalValue: "Total value",
		product: "Product",
		productCategory: "Product Category",
		mountableStage: "Mountable Step",
		mountableItem: "Item Mountable",
		unitCount: "Unit Quantity",
		fracionalCount: "Fractional Quantity.",
		unitaryValue: "Unitary value",
		mountableSection: "Mountable Session",
	},
	filterByProducts: "Filter by products",
	filterByProductsPlaceholder: "All",
	filterByOperationType: "Filter by operation type",
	filterByOperationTypePlaceholder: "All",
	filterByProductKind: "Filter by product type",
	productKindOptions: {
		food: "Food",
		drinks: "Drinks",
		tickets: "Ticket offices",
		other: "Other",
	},
};
