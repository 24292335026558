import * as React from "react";
import { saveAs } from "file-saver";
import QRCode from "qrcode";
import JSZip from "jszip";
import { normalize } from "#resources/helpers/formatters";

interface IQRCodeProductData {
	image: string;
	base64: string;
}

export interface IMultiplesQrCodes {
	url: string;
	filename: string;
}

interface IQrCodeHook {
	options?: QRCode.QRCodeToDataURLOptions;
}

export function useQrCode({ options }: IQrCodeHook) {
	const [qrCodeData, setQrCodeData] = React.useState<IQRCodeProductData | null>(null);
	const [loading, setLoading] = React.useState(false);

	async function generateQrCode(url: string) {
		try {
			setLoading(true);
			const qrCode: string = await QRCode.toDataURL(url, {
				...options,
			});

			const [, base64] = qrCode.split(",");

			setQrCodeData({ image: qrCode, base64 });

			return { image: qrCode, base64 };
		} catch (err) {
			console.error(err);

			if (err instanceof Error) {
				throw new Error(err.message);
			}

			return null;
		} finally {
			setLoading(false);
		}
	}

	async function downloadQRCode(filename?: string) {
		try {
			if (!qrCodeData) {
				return;
			}

			setLoading(true);

			const responseImage = await fetch(qrCodeData.image);

			const blob = await responseImage.blob();

			const filenameToDownload = filename
				? `qrCode-${normalize(filename).replaceAll(" ", "")}.png`
				: "qrCode.png";

			saveAs(blob, filenameToDownload);
		} catch (err) {
			console.error(err);

			if (err instanceof Error) {
				throw new Error(err.message);
			}
		} finally {
			setLoading(false);
		}
	}

	async function downloadMultiplesQrCodes(qrCodeData: IMultiplesQrCodes[]) {
		try {
			setLoading(true);
			const qrCodesZip = new JSZip();

			for (const data of qrCodeData) {
				const qrCode = await generateQrCode(data.url);

				if (!qrCode) {
					throw new Error("Error on generating qr code!");
				}

				qrCodesZip.file(
					`qrCode-${normalize(data.filename).replaceAll(" ", "")}.png`,
					qrCode.base64,
					{
						base64: true,
					},
				);
			}

			const content = await qrCodesZip.generateAsync({ type: "blob" });

			saveAs(content, "qrcodes.zip");
		} catch (err) {
			console.error(err);

			if (err instanceof Error) {
				throw new Error(err.message);
			}
		} finally {
			setLoading(false);
		}
	}

	return {
		generateQrCode,
		qrCodeData,
		downloadQRCode,
		downloadMultiplesQrCodes,
		loading,
	};
}
