import { Invoice, InvoiceMode, User } from "#api/enterprise-generated";
import { InvoiceStore } from "#stores/invoice";
import { extendModel } from "#helpers/mobx/utils";

/**  @deprecated models should not ne used anymore */
export class InvoiceModel implements Invoice {
	public id!: string;
	public mode!: InvoiceMode;
	public issuedAt!: Date;
	public isCanceled!: boolean;
	public isIssued!: boolean;
	public isIssuing!: boolean;
	public error!: string | null;
	public users!: User[];
	public pdfUrl!: string | null;
	public imgUrl!: string | null;

	public invoiceStore!: InvoiceStore;

	constructor(invoiceStore: InvoiceStore, invoice: Invoice) {
		this.invoiceStore = invoiceStore;
		this.sync(invoice);
	}

	public sync(invoice: Invoice) {
		extendModel(this, invoice);
	}
}
