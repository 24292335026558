export const infos = {
	goBack: "Voltar para listas",
	import: "Importar planilha",
	addGuest: "Adicionar convidado",
	edit: "Editar informações",
	download: "Baixar lista",
	print: "Imprimir",
	search: "Buscar convidado ou promoter",
	entrances: {
		label: "Entrada associada",
		empty: "Sem entrada associada",
		error: "Sem entrada associada a essa lista",
	},
	couvert: "Desconto Couvert",
	expires: "Expiração",
	description: {
		label: "Descrição",
		empty: "Sem descrição adicionada",
	},
	guest: "Convidado",
	guests: "Convidados",
	editInfos: "Editar informações da lista",
	editGuest: "Editar convidado",
	emptyList: {
		defaultMessage: "Parece que você não cadastrou nenhum convidado",
		text:
			"A lista com os convidados aparecerá aqui quando você tiver convidados cadastrados.",
		importList: "Importar lista",
	},
	editModal: {
		title: "Editar lista",
		new: "Nova lista",
		name: {
			label: "Nome da lista",
			placeholder:
				"Especifique sua lista de modo que identifique rapidamente (nome da lista - evento - data - local)",
		},
		expiresAt: "Expiração da lista",
		color: "Atribuir uma cor",
		couvert: "Desconto no couvert",
		entrance: {
			label: "Entrada associada",
			placeholder: "Digite ou selecione o tipo de entrada",
		},
		description: {
			label: "Descrição",
			placeholder: "Adicione uma observação para sua lista caso queira",
			limit: "Restam {{limit}} caracteres",
		},
		errors: {
			emptyName: "Informe o nome da lista",
			length: "O nome da lista deve ter menos de 100 caracteres",
			expires: "Data e hora expirada",
			description: "A descrição da lista deve possuir menos de 280 caracteres",
		},
		save: "Salvar edição",
	},
	addGuestModal: {
		name: {
			label: "Nome e sobrenome",
			placeholder: "Nome completo do cliente",
			error: "Nome inválido",
		},
		cpf: {
			label: "CPF",
			error: "CPF inválido",
		},
		phone: "Telefone",
		promoter: {
			label: "Promoter",
			placeholder: "Digite o nome do promoter",
		},
	},
	columns: {
		name: "Nome",
		cpf: "CPF",
		phone: "Telefone",
		promoter: "Promoter",
		actions: "Ações",
		emptyText: "Sem dados a serem exibidos.",
	},
};
