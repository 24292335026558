import { fetchModel } from "#resources/helpers/fetch-model";
import { RootStore } from ".";
import enterprise from "#api/enterprise-client";
import { showErrorNotification } from "#helpers/notifications";
import {
	BaseLoyaltyProgram,
	LoyaltyCompleteReport,
	LoyaltyProgram,
	LoyaltyProgramReport,
	PaginationAndFilters,
	ProgramParticipantReportResponse,
	SmsCostInfo,
} from "#resources/api/enterprise-generated";

interface ICreateProgramArgs {
	loyaltyProgram: BaseLoyaltyProgram;
}

interface IGetLoyaltyById {
	programId: string;
}

interface IActivateProgram {
	id: string;
}

interface IEditLoyaltyProgram {
	id: string;
	loyaltyProgram: BaseLoyaltyProgram;
}

interface ILoyaltyIdAndPagination {
	loyaltyProgramId: string;
	paginationAndFilters: PaginationAndFilters;
}

export class LoyaltyStore {
	public rootStore: RootStore;

	constructor(rootSore: RootStore) {
		this.rootStore = rootSore;
	}

	public clean() {
		this.createProgram.reset();
		this.getPrograms.reset();
		this.getLoyaltyCompleteReportById.reset();
		this.getCompleteReport.reset();
		this.getLoyaltyProgramById.reset();
		this.activateProgram.reset();
		this.deactivateProgram.reset();
		this.editProgram.reset();
		this.getLoyaltyProgramParticipantsReport.reset();
	}

	public createProgram = new fetchModel<ICreateProgramArgs, string>({
		fnPromise: args => enterprise.createLoyaltyProgram(args),
		onError: err => showErrorNotification(err.message),
	});

	public getPrograms = new fetchModel<{}, LoyaltyProgram[]>({
		fnPromise: () => enterprise.getLoyaltyPrograms(),
		onError: err => showErrorNotification(err.message),
	});

	public editProgram = new fetchModel<IEditLoyaltyProgram, void>({
		fnPromise: args => enterprise.editLoyaltyProgram(args),
		onError: err => showErrorNotification(err.message),
	});

	public activateProgram = new fetchModel<IActivateProgram, void>({
		fnPromise: args => enterprise.activateLoyaltyProgram(args),
		onError: err => showErrorNotification(err.message),
	});

	public deactivateProgram = new fetchModel<IActivateProgram, void>({
		fnPromise: args => enterprise.deactivateLoyaltyProgram(args),
		onError: err => showErrorNotification(err.message),
	});

	public getLoyaltyCompleteReportById = new fetchModel<
		IGetLoyaltyById,
		LoyaltyProgramReport
	>({
		fnPromise: args => enterprise.getLoyaltyCompleteReportById(args),
		onError: err => showErrorNotification(err.message),
	});

	public getLoyaltyProgramById = new fetchModel<IGetLoyaltyById, LoyaltyProgram>({
		fnPromise: args => enterprise.getLoyaltyProgramById(args),
		onError: err => showErrorNotification(err.message),
	});

	public getCompleteReport = new fetchModel<{}, LoyaltyCompleteReport>({
		fnPromise: () =>
			enterprise.getLoyaltyCompleteReport({
				organizationId: this.rootStore.authStore.currentEmployee?.organization?.id ?? "",
				paginationAndFilters: {
					pagination: { currentPage: 0, perPage: 10 },
					filters: null,
				},
			}),
		onError: err => showErrorNotification(err.message),
	});

	public getLoyaltyProgramParticipantsReport = new fetchModel<
		ILoyaltyIdAndPagination,
		ProgramParticipantReportResponse
	>({
		fnPromise: args => enterprise.getLoyaltyProgramParticipantsReport(args),
		onError: err => showErrorNotification(err.message),
	});

	public getLoyaltyProgramSmsCost = new fetchModel<{ id: string }, SmsCostInfo>({
		fnPromise: args => enterprise.getLoyaltyProgramSmsCost(args),
		onError: error => showErrorNotification(error.message),
	});

	public sendSmsToLoyaltyProgramRelatives = new fetchModel<
		{ id: string; message: string },
		void
	>({
		fnPromise: args => enterprise.sendSmsToLoyaltyProgramRelatives(args),
		onError: error => showErrorNotification(error.message),
	});
}
