import { message } from "./message";
import { packages } from "./packages";
import { whatsapp } from "./whatsapp";
export const communication = {
	title: "Messages",
	label: "Messages Sent",
	export: "Exportar para Excel",
	new: "New message",
	status: {
		sent: "sent",
		canceled: "canceled",
		notSent: "not sent",
		scheduled: "scheduled",
		error: "error",
		delivered: "delivered",
		optout: "optout",
		sending: "sending",
	},
	columns: {
		message: "Message",
		place: "Place",
		campaign: "Campaign",
		channel: "Channel",
		date: "Date and time",
		status: "Status",
		impactedCustomers: "Impacted customers",
	},
	at: "at",
	actions: {
		list: {
			sent: "View shipping report",
			schedule: {
				editAddressees: "Edit Recipients",
				editMessage: "Edit message content",
				cancelShipping: "Cancel shipping",
				hitchCampaign: "Link sending to campaign",
			},
			canceled: "View message report",
		},
		cancelModal: {
			close: "Close",
			cancel: "Cancel shipping",
			text: "Do you want to cancel sending the scheduled message for ",
			clients: "customers in the day",
			at: "at",
			confirm:
				"When you cancel the shipment, your balance will return to your account within a few hours.",
		},
		campaignCategoryModal: {
			title: "Link sending to campaign",
			tooltipMessage:
				"OPTIONAL: Link this send to a campaign to make it easier to analyze the performance of multiple sends together.",
			placeholder: "Select a campaign",
			text: "Select campaign",
			textButtonConfirm: "Confirm selection",
			successMessage: "Campaign linked to message",
		},
	},
	message,
	packages,
	whatsapp,
	warningCard: {
		title: "Payment",
		label: "The value of {{value}} will be added to Zig's monthly charge payment.",
	},
	modalChannel: {
		title: "Channel",
		channel: {
			sms: {
				title: "SMS",
				description: "Select a home, filter clients and send standard text messages",
			},
			whatsapp: {
				title: "Whatsapp",
				description: "Select a house, filter customers and send WhatsApp messages",
			},
			email: {
				title: "Email",
				description: "Select a house, filter clients and send personalized emails",
			},
		},
		tags: {
			new: "New",
			comingSoon: "Coming soon",
		},
	},
	permissionDenied: "No location allowed to manage WhatsApp messages",
};
