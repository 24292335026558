import enterprise from "#resources/api/enterprise-client";
import { fetchModel } from "#resources/helpers/fetch-model";

import { action, observable } from "mobx";
import {
	BaseProductFeature,
	BaseProductFeatureTemplate,
	ProductFeature,
	ProductFeatureTemplate,
} from "#resources/api/enterprise-generated";
import {
	showErrorNotification,
	showSuccessNotification,
} from "#resources/helpers/notifications";
import i18n from "#i18n/index";

const t = i18n.t;

export class ProductFeatureStore {
	@action
	public generateTemplatesOptions(templates: ProductFeatureTemplate[] | undefined) {
		return (
			templates?.map(template => ({
				label: `${template.name} (${
					template.organizationId
						? t("store:productFeatureStore.org")
						: t("store:productFeatureStore.zig")
				})`,
				value: template.id,
			})) || []
		);
	}

	@observable
	public isAddProductFeatureTemplateModalOpen = false;

	@action
	public handleToggleIsAddProductFeatureTemplateModalOpen(status: boolean) {
		this.isAddProductFeatureTemplateModalOpen = status;

		if (!status) {
			this.currentTemplate = undefined;
		}
	}

	@observable
	public currentTemplate: ProductFeatureTemplate | undefined = undefined;

	@action
	public handleChangeCurrentTemplate(template: ProductFeatureTemplate) {
		this.currentTemplate = template;
		this.isAddProductFeatureTemplateModalOpen = true;
	}

	public createProductFeatureTemplate = new fetchModel<
		{ productFeatureTemplate: BaseProductFeatureTemplate },
		ProductFeatureTemplate
	>({
		fnPromise: args => enterprise.createProductFeatureTemplate(args),
		onError: err => showErrorNotification(err.message),
		onSuccess: ({ organizationId }) => {
			showSuccessNotification(
				t("store:productFeatureStore.createProductFeatureTemplate"),
			);
			this.getProductFeatureTemplates.fetch({ organizationId: organizationId! });
			this.currentTemplate = undefined;
		},
	});

	public getProductFeatureTemplates = new fetchModel<
		{ organizationId: string },
		ProductFeatureTemplate[]
	>({
		fnPromise: async args => enterprise.getProductFeatureTemplates(args),
		onError: err => showErrorNotification(err.message),
	});

	public getUsableProductFeatureTemplates = new fetchModel<
		{ organizationId: string },
		ProductFeatureTemplate[]
	>({
		fnPromise: args => enterprise.getUsableProductFeatureTemplates(args),
		onError: err => showErrorNotification(err.message),
	});

	public getZigProductFeatureTemplates = new fetchModel<{}, ProductFeatureTemplate[]>({
		fnPromise: args => enterprise.getZigProductFeatureTemplates(args),
		onError: err => showErrorNotification(err.message),
	});

	public editProductFeatureTemplate = new fetchModel<
		{ productFeatureTemplate: ProductFeatureTemplate },
		ProductFeatureTemplate
	>({
		fnPromise: args => enterprise.editProductFeatureTemplate(args),
		onError: err => showErrorNotification(err.message),
		onSuccess: ({ organizationId }) => {
			showSuccessNotification(t("store:productFeatureStore.editProductFeatureTemplate"));
			this.getProductFeatureTemplates.fetch({ organizationId: organizationId! });
		},
	});

	public removeProductFeatureTemplate = new fetchModel<{ id: string }, void>({
		fnPromise: args => enterprise.removeProductFeatureTemplate(args),
		onError: err => showErrorNotification(err.message),
		onSuccess: () =>
			showSuccessNotification(
				t("store:productFeatureStore.removeProductFeatureTemplate"),
			),
	});

	public changeProductFeatureTemplateStatus = new fetchModel<
		{ id: string; status: boolean },
		ProductFeatureTemplate
	>({
		fnPromise: args => {
			enterprise.changeProductFeatureStatusByTemplateId(args);
			return enterprise.changeProductFeatureTemplateStatus(args);
		},
		onError: err => showErrorNotification(err.message),
	});

	public createProductFeature = new fetchModel<
		{ productFeature: BaseProductFeature },
		ProductFeature
	>({
		fnPromise: args => enterprise.createProductFeature(args),
		onError: err => showErrorNotification(err.message),
		onSuccess: () =>
			showSuccessNotification(t("store:productFeatureStore.createProductFeature")),
	});

	public getProductFeatures = new fetchModel<{ productId: string }, ProductFeature[]>({
		fnPromise: args => enterprise.getUsableProductFeatures(args),
		onError: err => showErrorNotification(err.message),
		onSuccess: v => console.log(v),
	});

	public editProductFeature = new fetchModel<
		{ productFeature: ProductFeature },
		ProductFeature
	>({
		fnPromise: args => enterprise.editProductFeature(args),
		onError: err => showErrorNotification(err.message),
		onSuccess: () =>
			showSuccessNotification(t("store:productFeatureStore.editProductFeature")),
	});

	public removeProductFeature = new fetchModel<{ id: string }, void>({
		fnPromise: args => enterprise.removeProductFeature(args),
		onError: err => showErrorNotification(err.message),
		onSuccess: () =>
			showSuccessNotification(t("store:productFeatureStore.removeProductFeature")),
	});

	public removeProductFeaturesByTemplateId = new fetchModel<{ id: string }, void>({
		fnPromise: args => enterprise.removeProductFeaturesByTemplateId(args),
		onError: err => showErrorNotification(err.message),
		onSuccess: () =>
			showSuccessNotification(
				t("store:productFeatureStore.removeProductFeaturesByTemplateId"),
			),
	});
}
