import * as React from "react";
import { NavBarRoute } from "#components/nav-bars-shared/nav-bar";
import RootStore from "../../stores";
import { usePermission } from "#resources/hooks";
import { EnterprisePermissions } from "#resources/helpers/permissions/utils";
import { placeFeatures } from "#stores/place";
import { MenuIconName } from "#components/nav-bars-shared/nav-bar/menu-icon";
import { orgFeatures } from "#stores/organization";
import { TagTypeStyle } from "#components/primitive";

export interface RouteItemProps extends NavBarRoute {
	hide?: boolean;
	permissions?: EnterprisePermissions[];
	placeFeature?: placeFeatures;
	orgFeaturesIds?: orgFeatures;
}

export type Submenu = {
	label: string;
	url: string;
	alternativeUrls?: string[];
	tagType?: TagTypeStyle;
};

export type NavBarRouteV2 = Omit<NavBarRoute, "icon" | "url"> & {
	icon: MenuIconName;
	url?: string;
	submenus?: Submenu[];
	alternativeUrls?: string[];
};

export type RouteItemPropsV2 = Omit<RouteItemProps, "icon" | "url"> &
	Pick<NavBarRouteV2, "icon" | "alternativeUrls"> & {
		submenus?: Array<Submenu & Omit<RouteItemProps, "icon">>;
	} & {
		url?: string;
	};

export function useNavigationWithRestriction<T = NavBarRoute>(
	routes: Array<RouteItemProps | RouteItemPropsV2>,
): {
	restrictedRoutes: T[];
} {
	const { hasPermission } = usePermission();
	const { placeStore, organizationStore } = React.useContext(RootStore);

	const memoizedRoutes = React.useMemo(() => routes, [routes]);

	// console.log({ memoizedRoutes });

	const checkPermissions = (
		navOption: Omit<RouteItemProps, "icon"> | RouteItemPropsV2,
	) => {
		if (
			navOption.orgFeaturesIds &&
			!organizationStore.hasFeature(navOption.orgFeaturesIds)
		)
			return false;

		if (navOption.permissions && !navOption.permissions.every(hasPermission))
			return false;
		if (navOption.placeFeature && !placeStore.hasFeature(navOption.placeFeature))
			return false;

		if ("submenus" in navOption && navOption.submenus?.length) {
			navOption.submenus = navOption.submenus.filter(checkPermissions);
		}

		return !navOption.hide;
	};

	const restrictedRoutes = memoizedRoutes.filter(checkPermissions).map(navOption => {
		const { hide, permissions, placeFeature, ...others } = navOption;
		return others;
	}) as T[];

	return {
		restrictedRoutes,
	};
}
