export const sellChip = {
	title: "Fichas vendidas no evento",
	addCollaborator: "Adicionar colaborador",
	placeholder_one: "Selecione o colaborador",
	placeholder_other: "Selecione os colaboradores",
	noChipsFound: "Nenhuma ficha encontrada",
	oneChipFound: "Uma ficha encontrada",
	chipsFound: "{{num}} fichas encontradas",
	searchPlaceholder: "Procurar por cliente ou produto",
	columns: {
		date: "Data",
		employee: "Funcionário",
		clients: "Clientes",
		products: "Produtos",
		value: "Valor",
		refunded: "Estornada",
		actionButton: "Estornar ficha",
	},
	modal: {
		title: "Estornar ficha de {{client}}",
		okText: "Estornar",
		product: "Produto",
		products: "Produtos",
		reason: "Motivo",
		reasonRequired: "Motivo obrigatório.",
		reasonPlaceholder: "Digite o motivo do estorno",
		shouldReturnToStorage: "Deve voltar ao estoque?",
		reasonError: "Esse campo não pode ser vazio",
		returnToStorage: "Retornar produto para o estoque",
	},
};
