export const clients = {
	titleWithPlace: "Customers list",
	searchTitle: "Search type",
	place: "Place",
	searchPlaceholder: "Insert customer document",
	searchClientByName: "Insert customer name",
	documentTypePlaceHolder: "Select costumer document type",
	filterTitle: "Advanced filters",
	filterByProductTitle: "Filter by product",
	clearFilter: "Clear all fields",
	applyFilter: "Apply filters",
	productError: "Amount of {{product}} must be greater than zero",
	filter: {
		period: "Period",
		gender: "Gender",
		genderPlaceholder: "Select the gender",
		periodFrequency: "Frequency",
		firstTime: "1st time",
		frequencyPlaceholder: "Choose customer frequency",
		daysOfTheWeek: "Week days",
		daysOfTheWeekPlaceholder: "Select the days of the week",
		productsConsumed: "Products consumed",
		product: "Product",
		selectProduct: "Select a product",
		noProducts: "No products found",
		quantity: "The amount",
		removeProduct: "Remove product",
		addProduct: "Add another product",
		consumptionVolume: "Minimum Consumption",
		notUsedRecharges: "Minimum Recharge Residue",
		yearPlaceholder: "yyyy",
		birthdateRange: "Birthday Period",
		birthdateYear: "Birthday Year",
		products: "Products",
		productsPlaceholder: "Select the products",
		errors: {
			type: "Invalid data",
			birthdate: {
				year: "Insert a year between 1900 and the current year",
			},
		},
	},
	table: {
		name: "Client",
		cpf: "CPF",
		phone: "Telephone",
		birthDate: "Birthday",
		uninformed: "Uninformed",
		email: "Email",
		presences: "Presence",
		consumption: "Consumed",
		remaningValueOnPlace: "Balance available",
		hasApp: "Active application",
		moreThan: "more of",
		countClients_one: "Customer found",
		countClients_other: "Found customers",
		countTicketClients: "Average ticket per customer",
		infoLabel: "Select a customer to see details.",
		infoLabelTable: "Select a Table to view details.",
		emptyText: "No data to display",
		moreResults: "Load more results",
		firstTimeAtPlace: "First time",
	},
	cancel: "Cancel",
	search: "Search",
	gender: {
		male: "Man",
		female: "Women",
		others: "Others",
	},
	daysOfTheWeek: {
		monday: "Second",
		tuesday: "Tuesday",
		wednesday: "Fourth",
		thursday: "Fifth",
		friday: "Friday",
		saturday: "Saturday",
		sunday: "Sunday",
	},
};
