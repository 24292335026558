import { reports } from "./reports";

import { addBillModalForm } from "./add-bill-modal-form";
import { coalaAddMenuModal } from "./coala-add-menu-modal";
import { coalaAddProductModal } from "./coala-add-product-modal";
import { forms } from "./forms";
import { withdrawsCards } from "#i18n/translations/en-US/components/withdraws-cards";
import { pixTypeInput } from "./pix-type-input";
import { onboarding } from "./onboarding";

export const componentsEN = {
	pixTypeInput,
	confirmModal: {
		title: "Confirmation required",
		cancel: "Cancel",
		ok: "Ok",
	},
	eventTag: {
		sent: {
			title: "Sent Conference",
			tooltip: "Cash conference sent to the financial back office.",
		},
		pending: {
			title: "Pending Conference",
			tooltip: "Cash conference pending to be sent to the financial back office.",
		},
		openPdv: "Open PDVs",
		openTable: "Outdoor tables",
	},
	paginator: {
		showMore: "Show more ({{remainingElements}})",
		showLess: "Show less",
	},
	dataTable: {
		empty: "No data to display",
		table: "Table",
	},
	monthPicker: {
		dateInfoNotAvailable: "View not available",
		monthDays: "Total {{monthDays}} days",
	},
	notification: {
		success: "Success!",
		error: "Error!",
		warning: "Attention!",
	},
	notAvailable: {
		isGrouped: "There are products with no registered cost",
		isNotGrouped: "Cost not registered",
		na: "N/A",
	},
	logo: "Logo",
	openSalesReport: {
		hooks: {
			exportSalesByEmployee: {
				employee: "Employee",
				filename: "single-sales-per-employee-",
				filenameEvent: "at-event-{{eventName}}",
				filenamePeriod: "-from-{{since}}-to-{{until}}",
				title: "Single Sales by Employee ",
				titleEvent: "at event {{eventName}} ",
				titlePeriod: "from {{since}} to {{until}}",
			},
			exportAllSalesFromEmployee: {
				date: "Date",
				isRefunded: "Is refunded",
				refundedBy: "Refunded by",
				refundedAt: "Refunded at",
				value: "Value",
				method: "Method",
				filename: "{{employeeName}}-single-sales",
				filenameEvent: "-at-event-{{eventName}}",
				filenamePeriod: "-from-{{since}}-to-{{until}}",
				title: "Single sales from {{employeeName}} ",
				titleEvent: "at event {{eventName}} ",
				titlePeriod: "from {{since}} to {{until}}",
			},
		},
		parseOpenSaleMethod: {
			credit: "Credit",
			debit: "Debit",
			pix: "Pix",
			creditInInstallments: "Installment",
			integratedFoodVoucher: "Food Voucher",
		},
	},
	fileUpload: "Click or drag here to attach",
	formStatus: {
		error: {
			title: "Oops... Something didn't go as planned.",
			description: "Could not complete.",
		},
		success: "Successfully concluded!",
	},
	reportNavbar: {
		cashflow: {
			title: "CASH FLOW",
			extract: "Extract",
			expensses: "Expenses/Revenues",
			history: "Historic",
			demonstrative: "Statement",
		},
		expenses: {
			title: "EXPENSES",
			perDescripion: "Per Description",
			perDay: "Per day",
			perType: "Per Type",
			perCategory: "Per Category",
			perCostCenter: "Per Cost Center",
			perHistory: "Historic",
			perAnything: "Paid to...",
		},
		receipts: {
			title: "RECEIPTS",
			perDescripion: "Per Description",
			perDay: "Per day",
			perType: "Per Type",
			perCategory: "Per Category",
			perCostCenter: "Per Cost Center",
			perHistory: "Historic",
			perAnything: "Received from...",
		},
		competence: "Competence",
		dueDate: "Due date",
	},
	reportTab: {
		letterhead: "Event '{{name}} ({{begin}})'",
		notAuthorized: "You are not authorized to view any reports from this section",
	},
	selectImage: {
		productImage: "Product photo",
		select: "Select a photo",
		setTagInput: "search for the product using keywords",
		notInformed: "Name not informed",
		searchMoreOptions: "Browse more database options",
		selectFrom: "Select from ",
		computer: "computer",
	},
	noInformation: "No informations",
	table: {
		deleteGuest: "Delete guest",
		excludeGuest: "Delete guest",
		deleteDescription:
			"The guest will be permanently deleted. Do you want to continue anyway?",
		emptyText: "No data to display.",
		reports,
	},
	tabsBackoffice: {
		accountsPayable: "Accounts payable",
		accountsReceivable: "Accounts receivable",
		providers: "Customers and suppliers",
		costCenter: "Cost center",
		planAccounts: "Plan of accounts",
		checkingAccounts: "Checking Accounts",
		reports: "Reports",
		cashflow: "Cash flow",
		finExManagerialReport: "DRE",
		bankAccounts: "Bank accounts",
		noticeIsFreeModule: "Free module up to",
		conciliation: "Conciliation via OFX",
	},
	toggleProducts: "No, just this product",
	addBillModalForm,
	ballStatusIndicator: {
		online: "online",
		offline: "offline",
		invalid: "Invalid status",
	},
	comingSoon: "Coming soon",
	coalaAddMenuModal,
	coalaAddMenuProductModal: {
		title: "Add product to menu",
		productInformation: "Product information",
		product: "Product",
		price: "Sale price",
		add: "Add product",
		validateField: "Required field",
	},
	coalaAddProductModal,
	coalaCategorizedProductsTable: {
		all: "All",
		emptyMessage: "No products found",
		product: "Product",
		sku: "SKU",
		price: "Price",
		mountable: "Mountable",
	},
	coalaMassEditModal: {
		products: "Products",
		selectDisclaimer:
			"Download selected products in excel and edit them. Then upload the file to update your menu",
		xlsxDisclaimer:
			"Now that you have edited the products in excel, upload the file and we will update your list of products in this menu",
		label: "File name",
		download: "Download",
		editMassProduct: "Mass product editing",
		sheet: {
			id: "IDENTIFIER (DO NOT EDIT)",
			sku: "SKU",
			name: "Name",
			category: "Category",
			description: "Description",
			price: "Price",
			filename: "edit-products-in-mass-delivery",
			invalidFormat: "Invalid format",
		},
	},
	containers: {
		noMatch: "Page not found",
	},
	dialogs: {
		clientInvoices: {
			title: "Invoices from {{clientName}}",
			submitButton: "Issue notes",
			name: "Consumers",
			issuedAt: "Date and time",
			links: "Links",
			loading: "Loading...",
			noInvoices: "There are no issued notes to display",
			somethingWrong: "Something went wrong",
			updatePage: "Please refresh the page and try again",
			openPdf: "Open PDF",
			openCoupon: "Open Coupon",
			onSubmitEmailForm: "Send invoices by email",
			email: "Email",
			send: "Send",
		},
		clientTransactions: {
			title: "Extract from client'{{clientName}}'",
			date: "Hour",
			product: "Products",
			sellerName: "Seller",
			buyers: "Buyers",
			refunded: "Refunded?",
		},
	},
	errorBoundary: {
		title: "Something went wrong",
		subtitle: "Error code",
		description:
			"Our team has already been notified of the error. Please reload the page and try again.",
		descriptionWithError:
			"If the problem is recurring, let our team know the code above.",
	},
	forms,
	globalWarnings: {
		requests_one: "Attention! You have {{value}} pending vendor withdrawal requests",
		requests_other: "Attention! You have {{value}} pending vendor withdrawal requests",
		isApproved:
			"Attention! This location is in pre-approval status, it will not be possible to open events until approval is made.",
		certificatesAboutToExpire:
			"Digital certificates for {{value}} tax profiles will expire in less than 30 days!",
		expiredCertificates:
			"The digital certificates for the {{value}} fiscal profiles have expired!",
		blockedPlace: "The place {{placeName}} is blocked",
		blockedPlaces: "The places {{placeName}} are blocked",
	},
	consolidatedHistory: {
		translatePaymentMethod: {
			app: "App",
			cash: "Cash",
			creditCard: "Credit card",
			debitCard: "Debit card",
			zigPosCredit: "Zig post credit",
			zigPosDebit: "Zig post debit",
			voucher: "Voucher",
			dashboard: "Dashboard",
			anticipated: "Anticipated",
			postEvent: "Post event",
			rappi: "Rappi",
			appPrePayment: "Payment via app",
			pix: "Pix",
			bonus: "Bonus",
			zigPosCreditInInstallments: "Installment",
			integratedFoodVoucher: "Food Voucher",
		},
		translateEventStatus: {
			created: "Closed",
			finished: "Closed",
			running: "Open",
			canceled: "Canceled",
		},
	},
	addProduct: {
		emptyProductFeatureForm: {
			title: "No functionality model selected",
			description:
				"Select a custom product information template. To create a new model, access the",
			link: "product functionality models.",
		},
	},
	pendingContracts: {
		approveContract: "Approve contract",
		description: "You have pending agreements.",
		deselectAllContracts: "Deselect all contracts",
		modalApproveConfirmation: {
			description:
				"To finalize the subscription and confirm the approval, we will send a code to the registered email. At the end of the subscription, you will only be able to cancel by contacting the support channel.",
			header: "Approval confirmation",
			reminder:
				"Reminder: check your spam folder, the email with the code may have been directed to it.",
			sendCode: "Send code",
		},
		modalCodeConfirmation: {
			codePlaceholder: "Enter the code sent",
			confirmSign: "Confirm subscription",
			description: "Enter confirmation code to complete request signing.",
			verifyCode: "Confirmation code",
		},
		request: "Request #{{request}}",
		selectAllContracts: "Select all contracts",
		title: "Pending terms and contracts",
	},
	reportTabs: {
		notAuthorized: "You are not authorized to view any reports from this section",
	},
	transactionalHistory: {
		translateAcquirerTansactionType: {
			credit: "Credit",
			debit: "Debit",
		},
	},
	helpers: {
		tabs: {
			discount: "Discounts",
			menus: "Menu",
			products: "Products",
			vendorReport: "Vendor report",
			productReport: "Report by product",
			orderReport: "Order Report",
			config: "Settings",
			extract: "Vendor extract",
			closings: "Closing by vendor",
			productSoldByVendor: "Products sold by vendor",
			vendorsRevenue: "Vendor billing",
			vendors: "Vendors",
			invoices: "Invoices",
			missingInvoices: "Missing notes",
			missingInvoicesForeign: "Pending notes",
			trasactions: "Transactions",
			recharges: "Recharges",
			notEmitedProducts: "Not issued products",
			search: "Search",
			emited: "Emissions",
			emissionErrors: "Emission Errors",
			fiscalReport: "Fiscal Report",
			debtorsReport: "Open Invoices",
		},
		tooltips: {
			soldProducts: "Total products sold",
			waiterReport: "Sales per waiter",
			entranceReport: "Entrance",
			bonusByProductReport: "Bonus per product",
			closeCashier: "Closing Cashier",
			soldEntrances: "Total entrances sold",
			minimumConsumptionReport: "Early Consumption",
			cashierBalance: "Revenue per cashier",
			financialReport: "Closing",
			vat: "VAT is included in the registered product price.",
			tipReport: "Tip Report",
			netValue:
				"The Net Value is calculated by subtracting the discount and the VAT percentage from the product's Gross Value.",
			invoiceValue:
				"Corresponds to the total value of the invoice, which may include more than 1 product, hence more than one line in this table.",
		},
		humanizedWithdrawType: {
			anticipation: "Anticipation",
			withdraw: "Withdraw",
			withdrawAndAnticipation: "Withdraw in anticipation",
		},
		humanizedWithdrawStatus: {
			pending: "Pending",
			paid: "Paid",
			refused: "Denied",
			processing: "In processing",
		},
		parsePaymentMethodV2: {
			bonus: "Bonus",
			cash: "Cash",
			creditCard: "Credit Card",
			debitCard: "Debit Card",
			postEvent: "Post event",
			voucher: "Voucher",
			zigPosCredit: "Integrated Credit",
			zigPosDebit: "Integrated Debit",
			pix: "Pix",
			foodVoucher: "Food Voucher",
			ame: "Ame",
			anticipated: "Anticipated",
			zigPosCreditInInstallments: "Installment",
			rappi: "Rappi",
			ifood: "Ifood",
			uber: "Uber",
			deliveryOnline: "Delivery Online",
			dashboard: "Dashboard",
			Online: "Online",
			Cashless: "Cashless",
			mbway: "MBWay",
			total: "Total",
		},
		defaultErrorMessage: "There was an error with your request",
		xlsx: {
			day: "{{title}} on day {{date}}",
			sinceUntil: "{{title}} between {{since}} and {{until}}",
			emissionInfo: "Document issued by {{currentEmployee}}, {{date}}",
			data: "Data",
			zigEmissionDate: "ZIGPAY - Issue Date {{date}}",
			selectedPeriod: "selected period {{since}} to {{until}}",
		},
		displayFiltersInfo: {
			none: "Filters: No filters applied",
			hasFilters: "Filters: {{filters}}",
		},
	},
	withdrawsCards,
	reportPrintTemplate: {
		printDate: "Issued on",
		reportPeriod: "Report period",
		emissionInfo: "Issued by",
		eventDate: "Event date",
	},
	inputFile: {
		invalidFileFormat: "Invalid file format, valid formats: {{formats}}",
		multipleFilesUploaded: "Multiple files uploaded",
		remove: "Remove",
	},

	notifications: {
		title: "Notifications",
		read: "All",
		unread: "Unread",
		emptyNotifications: "You've reached the end of the list!",
		emptyUnreadNotifications: "You are up to date with all the news!",
		noNews: "No news!",
		levelTag: "Level {{tag}}",
		classification: {
			alert: "Instability",
			release: "Release",
			warning: "Improvement",
		},
		level: {
			app: "User App",
			organization: "Organization",
			place: "Location",
			event: "Event",
			pdv: "Pdv",
		},
		onboarding,
	},
	placeCheckbox: {
		selectAll: "Select all",
	},
	placeFilter: {
		placeholder: "Filter by place",
	},
	header: {
		filterError: "The fields array cannot have more than 4 items.",
		perDay: "Per day",
		byPeriod: "By period",
	},
	tableEmptyState: {
		noData: {
			title: "We couldn't find what you're looking for",
			description: "Try selecting a new location or period, or try again later.",
		},
		noFilters: {
			title: "There's nothing here yet",
			description: "Select the search location or check again later.",
		},
	},
};
