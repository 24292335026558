export const company = {
	fields: {
		CNPJ: {
			label: "CNPJ",
			placeholder: "Insert CNPJ",
		},
		cityCode: {
			label: "City code",
			placeholder: "Insert city code",
		},
		corporateReason: {
			label: "Corporate reason",
			placeholder: "Insert corporate reason",
		},
		fantasyName: {
			label: "Fantasy name",
			placeholder: "Insert fantasy name",
		},
		municipalRegistration: {
			label: "Municipal registration",
			placeholder: "Insert municipal registration",
		},
		stateRegistration: {
			label: "State registration",
			placeholder: "Insert state registration",
		},
		taxRegime: {
			label: "Tax regime",
			placeholder: "Select tax regime",
		},
	},
	optionsTaxation: {
		normalRegime: "Normal regime",
		simpleNational: "Simple national",
		simpleNationalExcess: "Simple national excess",
	},
};
