export const cmvCost = {
	label: "Costs",
	export: "Export to Excel",
	selectTotalCost: "Total cost of selection",
	storageTotalCost: "Total cost in stock",
	averageCostSelection: "Average cost x selection selling price",
	averageCost: "Average cost x selling price",
	alert: "Heads up! There are products with negative quantity that should be reviewed",
	products: "Products",
	showAll: "Show all {{count}} product(s)",
	showLess: "Show less",
	filter: {
		label: "Filter by",
		storage: {
			title: "Inventory",
			label: "Select stock",
			all: "All stocks",
		},
		category: {
			title: "Category",
			label: "Select category",
			all: "All categorys",
		},
		product: {
			title: "Product",
			label: "Select product",
			all: "All products",
		},
		clear: "Clean filter",
		apply: "Filter",
	},
	table: {
		checkbox: "Do not show items with datasheet",
		columns: {
			name: "Product",
			code: "SKU",
			count: "Quantity",
			averageCost: "Average Cost",
			stockValue: "Value in stock",
			realCost: "Sale price",
			margin: "Margin",
		},
	},
	sheet: {
		title: "CMV Costs Report",
		fileName: "cmv-cost-report",
		name: "Product",
		fiscalCode: "SKU",
		count: "Quantity",
		unityCount: "Quantity (Unit)",
		averageCost: "Average cost",
		stockValue: "Value in stock",
		realCost: "Sale price",
		margin: "Margin",
	},
};
