import { showErrorNotification } from "#helpers/notifications";
import enterprise from "#resources/api/enterprise-client";
import { BIWorkspace } from "#resources/api/enterprise-generated";
import { fetchModel } from "#resources/helpers/fetch-model";
import { autobind } from "core-decorators";
import { action, observable } from "mobx";

@autobind
export class ClientAreaStore {
	public clean() {
		this.workspaces = [];
		this.selectedPlaces = [];
	}

	@observable
	public workspaces: BIWorkspace[] = [];

	@observable
	public selectedPlaces: string[] = [];

	public getBiPanelsGroupedByWorkspace = new fetchModel<{}, BIWorkspace[]>({
		fnPromise: () => enterprise.getBiPanelsGroupedByWorkspace(),
		onSuccess: workspaces => {
			this.workspaces = workspaces;
		},
		onError: err => showErrorNotification(err.message),
	});

	@action
	public updateSelectedPlaces(selectedPlaces: string[]) {
		this.selectedPlaces = selectedPlaces;
	}
}
