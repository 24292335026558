var escape = require("../../../../node_modules/.pnpm/css-loader@0.28.11/node_modules/css-loader/lib/url/escape.js");
exports = module.exports = require("../../../../node_modules/.pnpm/css-loader@0.28.11/node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._2M4yAdDxXzEnMalKt8FkVH{background:url(" + escape(require("./logo.png")) + ") no-repeat;background-size:contain;text-indent:-9999px;height:200px;width:200px;background-position:center}", ""]);

// exports
exports.locals = {
	"mainLogo": "_2M4yAdDxXzEnMalKt8FkVH"
};