import React from "react";

type CellProps = {
	children: React.ReactNode;
	area: string;
	className?: string;
};

export const Cell = ({ children, area, className }: CellProps) => {
	return (
		<div className={className} style={{ gridArea: area }}>
			{children}
		</div>
	);
};
