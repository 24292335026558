import * as ReactGA from "react-ga";

import enterprise from "#resources/api/enterprise-client";
import { errorTap } from "#helpers/errors";
import { EventModel } from "#models/event";
import { fetchModel } from "#helpers/fetch-model";
import dayjs from "dayjs";
import { ReportModel } from "#models/report";
import { RootStore } from ".";

import { action, computed, observable } from "mobx";
import {
	ActivationReturnReport,
	AllOfflineActionTypes,
	AppTransactionDetailsPaginationResponse,
	AppTransactionsTotalizer,
	BaseProduct,
	BillPayment,
	CardReturnEmployee,
	Cashier,
	Debtor,
	DiscountEmployee,
	EmployeeTipReport,
	EmployeeTipReportFilters,
	EntranceSold,
	EntranceUser,
	Event,
	EventResumeSection,
	FilterReportAfterEventClosing,
	FiltersActionsFromEvent,
	GetZigDeviceSyncForcesAtEvent,
	MoneyOperation,
	NewUserRecharge,
	OpenedBillPayment,
	OpenedBillPaymentV2,
	OpenSale,
	OpenSaleReport,
	Pagination,
	PaymentMethod,
	PostDetails,
	PostLimitChange,
	ProductBurnedTicketSold,
	ProductSold,
	ProductSoldV2,
	RappiDiscount,
	RechargeConsumption,
	RechargeDetails,
	RefundedProduct,
	RefundedRecharge,
	ReportActionsFromEventResponse,
	ReportAfterEventClosing,
	ReportPdvZigCardFilters,
	ReportPdvZigCardReport,
	SellerReport,
	SellerTransaction,
	SimplifiedSeller,
	SoldEntranceWithEmployee,
	StockTransfer,
	TipRemovedList,
	TransactionSource,
	TransactionTransfer,
	UsedPromotion,
	UserDocumentType,
	UsersEntrancesConsumedOverview,
	UsersEntrancesConsumedReportFilters,
	UsersEntrancesConsumedReportResponse,
	UserUsedPromotion,
} from "#resources/api/enterprise-generated";
import { fromPromise, IPromiseBasedObservable } from "#helpers/mobx-utils";
import {
	showErrorNotification,
	showMessageOnTop,
	showSuccessNotification,
} from "#helpers/notifications";
import i18n from "#i18n/index";
import { add, datetime, startOf, subtract } from "#resources/helpers/date-format";

interface EventArg {
	eventId: string;
}

interface ISellerTransactionsAtEventReportData {
	eventId: string;
	waiterId: string;
}

interface ICashierRechargeDetailsData {
	eventId: string;
	cashierId: string;
}

interface ISellerProductsData {
	eventId: string;
	sellerId: string;
}

interface IPostCashierDetailsData {
	eventId: string;
	cashierId: string;
}

interface IScroll {
	top: number;
	left: number;
}

interface IGetAllEventActions {
	eventId: string;
	pagination: Pagination;
	filters: FiltersActionsFromEvent;
}

const t = i18n.t;

export class EventStore {
	@observable
	public debtorsAtEventReport: ReportModel<
		Debtor[],
		{ eventId: string }
	> = new ReportModel(args => enterprise.debtorsInEvent(args));
	@observable
	public sellerTransactionsReport: ReportModel<
		SellerTransaction[],
		ISellerTransactionsAtEventReportData
	> = new ReportModel(({ eventId, waiterId }) =>
		enterprise.getSellerDetails({ eventId, sellerId: waiterId }),
	);
	@observable
	public cashierBalanceAtEventReport: ReportModel<
		Cashier[],
		{ eventId: string }
	> = new ReportModel(args => enterprise.cashierDetailsAtEvent(args));

	@observable
	public postCashierDetailsReport: ReportModel<
		PostDetails[],
		IPostCashierDetailsData
	> = new ReportModel(({ eventId, cashierId }) =>
		enterprise.getCashierPostDetails({ eventId, cashierId }),
	);
	@observable
	public waiterReport: ReportModel<
		SellerReport[],
		{ eventId: string }
	> = new ReportModel(args => enterprise.getSellerReports(args));
	@observable
	public stockReport: ReportModel<
		StockTransfer[],
		{ eventId: string }
	> = new ReportModel(args => enterprise.getStockTransferReport(args));
	@observable
	public sellerProductsReport: ReportModel<
		ProductSold[],
		ISellerProductsData
	> = new ReportModel(({ eventId, sellerId }) =>
		enterprise.getSellerProductDetails({ eventId, sellerId }),
	);
	@observable
	public entrancesSoldReport: ReportModel<
		EntranceSold[],
		{ eventId: string }
	> = new ReportModel(args => enterprise.entrancesSoldAtEvent(args));

	@observable
	public entranceSoldByEmployeeAtEvent: ReportModel<
		SoldEntranceWithEmployee[],
		{ eventId: string }
	> = new ReportModel(args => enterprise.getEntrancesSoldByEmployeeAtEvent(args));

	@observable
	public rechargeConsumptionReport: ReportModel<
		RechargeConsumption[],
		{ eventId: string }
	> = new ReportModel(args => enterprise.getRechargeConsumptions(args));

	public usedPromotionReport: fetchModel<
		{ eventId: string },
		UsedPromotion[]
	> = new fetchModel({
		fnPromise: args => enterprise.getPromotionsUsedAtEvent(args),
		onError: err => showErrorNotification(err.message),
	});

	@observable
	public refundedRechargesReport: ReportModel<
		RefundedRecharge[],
		{ eventId: string }
	> = new ReportModel(args => enterprise.getRefundedRechargesAtEvent(args));

	@observable
	public cashierRechargeDetailsReport: ReportModel<
		RechargeDetails[],
		ICashierRechargeDetailsData
	> = new ReportModel(({ eventId, cashierId }) =>
		enterprise.getCashierRechargeDetails({ eventId, cashierId }),
	);

	@observable
	public refundedProductsAtEvent: ReportModel<
		RefundedProduct[],
		{ eventId: string }
	> = new ReportModel(args => enterprise.getRefundedProductsAtEvent(args));

	@observable
	public entranceSoldInPeriod: EntranceSold[] | null = null;

	@observable
	public cardActivationReturnReport: ReportModel<
		ActivationReturnReport,
		{ eventId: string }
	> = new ReportModel(args => enterprise.getCardActivationReturnReportAtEvent(args));

	@observable
	public tipRemovedList: TipRemovedList[] = [];

	@observable
	public fetchAllEventsPromise: IPromiseBasedObservable<void> | null = null;
	@observable
	public openEventPromise: IPromiseBasedObservable<void> | null = null;
	@observable
	public fetchEventPromise: IPromiseBasedObservable<void> | null = null;
	@observable
	public closeEventPromise: IPromiseBasedObservable<void> | null = null;
	@observable
	public deleteEventPromise: IPromiseBasedObservable<void> | null = null;
	@observable
	public bleedCashierPromise: IPromiseBasedObservable<void> | null = null;
	@observable
	public cashierClosingDetailsPromise: IPromiseBasedObservable<void> | null = null;
	@observable
	public updateCashierClosingPromise: IPromiseBasedObservable<void> | null = null;
	@observable
	public tipRemovedListPromise: IPromiseBasedObservable<void> | null = null;
	@observable
	public events: EventModel[] = [];
	@observable
	public event: EventModel | null = null;
	@observable
	public entrancesSoldToUserAtEvent: EntranceUser[] = [];
	@observable
	public productsSoldByBarAtEvent: ProductSold[] = [];
	@observable
	public loading: boolean = false;

	public rootStore: RootStore;

	@observable
	public until = datetime().compose(startOf("day"), add(1, "month"));
	@observable
	public since = datetime().compose(startOf("day"), subtract(1, "month"));

	@observable
	public lastEventDateWithTransaction: Date | null = null;

	constructor(rootStore: RootStore) {
		this.rootStore = rootStore;
	}

	public clean() {
		this.fetchAllEventsPromise = null;
		this.openEventPromise = null;
		this.fetchEventPromise = null;
		this.closeEventPromise = null;
		this.productsSoldByBarAtEvent = [];

		this.events = [];
		this.event = null;

		this.postLimitChange.reset();
		this.rappiDiscount.reset();
		this.openedBillPayments.reset();
		this.soldBaseProduct.reset();
		this.getAllOpenSalesByEmployee.reset();
		this.getOpenSaleReport.reset();
		this.getEventEntranceList.reset();
	}

	@computed
	get eventsUnderInterval() {
		return this.events.filter(e => dayjs(e.begin).isBetween(this.since, this.until));
	}

	public updateNoteFromServer = (incomeEvent: Event, placeId: string) => {
		let event = this.events.find(t => t.id === incomeEvent.id);
		if (!event) {
			event = new EventModel(this, this.rootStore.placeStore, incomeEvent, placeId);
			this.events.push(event);
		} else {
			event.sync(incomeEvent, placeId);
		}
		return event;
	};

	public resolve = (incomeEvent: Event, placeId: string) =>
		this.updateNoteFromServer(incomeEvent, placeId);

	public resolveMany = (incomingEvents: Event[], placeId?: string) => {
		this.events = [];
		incomingEvents.forEach(e =>
			this.resolve(e, placeId || this.rootStore!.placeStore!.place!.id!),
		);
	};

	public fetchProductsSoldInPeriodV1 = new fetchModel<
		{
			eventId: string;
			since: Date | null | undefined;
			until: Date | null | undefined;
			sources?: TransactionSource[] | null;
		},
		ProductSold[]
	>({
		fnPromise: ({ eventId, since, until, sources }) =>
			enterprise.getProductsSoldAtEventInPeriod({ eventId, since, until, sources }),
		onError: err => showErrorNotification(err.message),
	});

	public fetchProductsSoldInPeriod = new fetchModel<
		{
			eventId: string;
			since: Date | null | undefined;
			until: Date | null | undefined;
			sources?: TransactionSource[] | null;
			placeId?: string;
		},
		ProductSoldV2[]
	>({
		fnPromise: ({ eventId, placeId, since, until, sources }) =>
			enterprise.getProductsSoldAtEventInPeriodV2({
				eventId,
				since,
				until,
				sources,
				placeId,
			}),
		onError: err => showErrorNotification(err.message),
	});

	@action
	public fetchEntrancesSoldInPeriod = async (
		eventId: string,
		since: Date | undefined | null,
		until: Date | undefined | null,
	) => {
		try {
			this.entranceSoldInPeriod = await enterprise.getEntrancesSoldAtEventInPeriod({
				eventId,
				since,
				until,
			});
		} catch (err) {
			if (err instanceof Error) {
				showErrorNotification(err.message);
			}
		}
	};

	@action
	public entrancesSoldByEmployeeAtEvent = async () => {
		await enterprise.getEntrancesSoldByEmployeeAtEvent({
			eventId: this.event!.id,
		});
	};

	@action
	public fetchEntrancesSoldToUserAtEvent = async () => {
		const resp = await enterprise.getEntrancesSoldToUserAtEvent({
			eventId: this.event!.id,
		});
		this.entrancesSoldToUserAtEvent = resp;
	};

	@action
	public fetchEvent = (eventId: string) =>
		(this.fetchEventPromise = fromPromise(
			enterprise.getEvent({ id: eventId }).then(event => {
				this.resolve(event, this.rootStore.placeStore.place!.id!);
			}),
		));

	public openEvent(eventId: string, updateDate?: boolean) {
		this.openEventPromise = fromPromise(
			enterprise
				.openEvent({ id: eventId, actualizingdatetime: !!updateDate })
				.then(() => {
					this.fetchEvent(eventId);
					ReactGA.event({
						category: "User",
						action: "open event",
					});
					showSuccessNotification(t("store:eventStore.openEvent"));
				})
				.catch(errorTap((err: Error) => showErrorNotification(err.message))),
		);
	}

	public async payBillAtEventWithMultiplePayments(
		userId: string,
		eventId: string,
		payments: BillPayment[],
		reason: string | null,
	) {
		try {
			await enterprise.payBillAtEventWithMultiplePayments({
				userId,
				eventId,
				payments,
				reason,
			});
			showSuccessNotification(t("store:eventStore.payBillAtEventWithMultiplePayments"));
		} catch (err) {
			if (err instanceof Error) {
				showErrorNotification(err.message);
				console.error(err.message);
			}
		}
	}

	public async payAllBillsAtEvent(
		userIds: string[],
		eventId: string,
		method: PaymentMethod,
	) {
		try {
			await enterprise.payBillsAtEventWithMultiplePayments({
				userIds,
				eventId,
				method,
			});
			showSuccessNotification(t("store:eventStore.payAllBillsAtEvent"));
		} catch (err) {
			if (err instanceof Error) {
				showErrorNotification(err.message);
			}
		}
	}

	@action
	public closeEvent(eventId: string) {
		const endLoading = showMessageOnTop({
			description: t("store:eventStore.closeEventLoading"),
			time: 0,
			type: "loading",
		});

		this.closeEventPromise = fromPromise(
			enterprise
				.closeEvent({ id: eventId })
				.then(() => {
					ReactGA.event({
						category: "User",
						action: "close event",
					});
					endLoading();
					showSuccessNotification(t("store:eventStore.closeEvent"));
					this.fetchEvent(eventId);
				})
				.catch(err => {
					endLoading();
					if (err instanceof Error) {
						showErrorNotification(err.message);
					}
				}),
		);
	}

	@action
	public deleteEvent(eventId: string) {
		this.deleteEventPromise = fromPromise(
			enterprise
				.deleteEvent({ id: eventId })
				.then(() => {
					ReactGA.event({
						category: "User",
						action: "delete event",
					});

					this.events = this.events.filter(e => e.id !== eventId);
					showSuccessNotification(t("store:eventStore.deleteEvent"));
				})
				.catch(err => {
					if (err instanceof Error) {
						showErrorNotification(err.message);
					}
				}),
		);
	}

	public async setCurrentEvent(eventId: string) {
		try {
			let event = this.events.find(e => e.id === eventId);
			if (event === undefined) {
				await this.fetchEvent(eventId);
				event = this.events.find(e => e.id === eventId);
			}
			this.event = event || null;
			this.rootStore.itemStore.clean();
			this.rootStore.comboStore.clean();
		} catch (err) {
			if (err instanceof Error) {
				showErrorNotification(err.message);
			}
		}
	}

	@action
	public unsetCurrentEvent() {
		this.event = null;
	}

	@action
	public fetchDebtorsAtEventReport = () => {
		this.debtorsAtEventReport.fetch({ args: { eventId: this.event!.id } });
	};

	@action
	public fetchSellerTransactionsReport = (waiterId: string) => {
		this.sellerTransactionsReport.fetch({
			args: { eventId: this.event!.id, waiterId },
		});
	};

	@action
	public fetchCashierBalanceAtEventReport = () => {
		this.cashierBalanceAtEventReport.fetch({ args: { eventId: this.event!.id } });
	};

	@action
	public fetchPostCashierDetailsReport = (cashierId: string) => {
		this.postCashierDetailsReport.fetch({
			args: { eventId: this.event!.id, cashierId },
		});
	};

	@action
	public getDeliveredProductsByBarReport = async (
		barIds: string[],
		sources?: TransactionSource[] | null,
	) => {
		this.loading = true;
		this.productsSoldByBarAtEvent = await enterprise.deliveredProductsByBarsAtEvent({
			eventId: this.event!.id,
			barIds,
			sources,
		});
		this.loading = false;
	};

	@action
	public fetchWaiterReport = () => {
		this.waiterReport.fetch({ args: { eventId: this.event!.id } });
	};

	public getSellerReports = new fetchModel<
		{
			eventId: string;
			sources?: TransactionSource[] | null;
		},
		SellerReport[]
	>({
		fnPromise: args => enterprise.getSellerReports(args),
		onError: err => showErrorNotification(err.message),
	});

	public getAllEventsFromPeriod = new fetchModel<
		{
			placeId: string;
			since: Date;
			until: Date;
		},
		Event[]
	>({
		fnPromise: args => enterprise.getAllEvents(args),
		onError: err => showErrorNotification(err.message),
	});

	@action
	public fetchStockReport = () => {
		this.stockReport.fetch({ args: { eventId: this.event!.id } });
	};

	@action
	public fetchSellerProductsReport = async (sellerId: string) => {
		return await this.sellerProductsReport.fetch({
			args: { eventId: this.event!.id, sellerId },
		});
	};

	@action
	public fetchEntrancesSoldReport = () => {
		this.entrancesSoldReport.fetch({ args: { eventId: this.event!.id } });
	};

	@action
	public fetchEntranceSoldByEmployeeAtEvent = () => {
		this.entranceSoldByEmployeeAtEvent.fetch({ args: { eventId: this.event!.id }! });
	};

	@action
	public fetchRechargeConsumptionReport = () => {
		this.rechargeConsumptionReport.fetch({ args: { eventId: this.event!.id }! });
	};

	@action
	public fetchRefundedRechargesReport = () => {
		this.refundedRechargesReport.fetch({ args: { eventId: this.event!.id! } });
	};

	@action
	public fetchCashierRechargeDetailsReport = (cashierId: string) =>
		this.cashierRechargeDetailsReport.fetch({
			args: { eventId: this.event!.id!, cashierId },
		});

	@action
	public fetchRefundedProductsAtEventReport = () => {
		this.refundedProductsAtEvent.fetch({ args: { eventId: this.event!.id! } });
	};

	@action
	public fetchCardActivationReturnReport = async () => {
		await this.cardActivationReturnReport.fetch({ args: { eventId: this.event!.id! } });
	};

	@action
	public sendConsumption = (eventId: string, recharges: NewUserRecharge[]) =>
		enterprise.giveRechargeToUsers({ eventId, recharges });

	@observable
	public cashierBalanceScroll: IScroll = { top: 0, left: 0 };

	@action
	public setCashierBalanceScroll(scrollTopValue: number, scrollLeftValue: number) {
		this.cashierBalanceScroll.top = scrollTopValue;
		this.cashierBalanceScroll.left = scrollLeftValue;
	}

	public postLimitChange = new fetchModel<
		{
			eventId: string;
		},
		PostLimitChange[]
	>({
		fnPromise: args => enterprise.getPostLimitChangesAtEvent(args),
		onError: err => showErrorNotification(err.message),
	});

	public rappiDiscount = new fetchModel<
		{
			eventId: string;
		},
		RappiDiscount[]
	>({
		fnPromise: args => enterprise.getRappiDiscountsAtEvent(args),
		onError: err => showErrorNotification(err.message),
	});

	public openedBillPayments = new fetchModel<
		{
			eventId: string;
		},
		OpenedBillPayment[]
	>({
		fnPromise: args => enterprise.getOpenedBillPayments(args),
		onError: err => showErrorNotification(err.message),
	});

	public soldBaseProduct = new fetchModel<
		{
			eventId: string;
			sources?: TransactionSource[] | null;
		},
		BaseProduct[]
	>({
		fnPromise: args => enterprise.getSoldBaseProductsAtEvent(args),
		onError: err => showErrorNotification(err.message),
	});

	public userPromotions = new fetchModel<{ eventId: string }, UserUsedPromotion[]>({
		fnPromise: args => enterprise.getUserPromotionsUsedAtEvent(args),
		onError: err => showErrorNotification(err.message),
	});

	@action
	public emitInnovaroGeneralInvoices = async (eventId: string) => {
		try {
			await enterprise.emitInnovaroGeneralInvoices({ eventId });
			showSuccessNotification(t("store:eventStore.emitInnovaroGeneralInvoices"));
		} catch (err) {
			if (err instanceof Error) {
				showErrorNotification(err.message);
			}
		}
	};

	public tipRemoved = new fetchModel<EventArg, TipRemovedList[], TipRemovedList[]>({
		fnPromise: args => enterprise.getTipsRemovedAtEvent(args),
		onError: err => showErrorNotification(err.message),
	});

	public devolutionCardReport = new fetchModel<EventArg, CardReturnEmployee[]>({
		fnPromise: args => enterprise.getCardActivationReturnReportByEmployeeAtEvent(args),
		onError: err => showErrorNotification(err.message),
	});

	public discountsAtEvent = new fetchModel<
		EventArg,
		DiscountEmployee[],
		DiscountEmployee[]
	>({
		fnPromise: args => enterprise.getDiscountsAtEventByEmployee(args),
		onError: err => showErrorNotification(err.message),
	});

	public downloadAllProductsSalesFromEvent = new fetchModel<{ eventId: string }, string>({
		fnPromise: args => enterprise.exportAllTransactionProductsXlsx(args),
		onSuccess: url => window.open(url, "_blank"),
		onError: err => showErrorNotification(err.message),
	});

	public simplifiedSellers = new fetchModel<{ eventId: string }, SimplifiedSeller[]>({
		fnPromise: args => enterprise.getSimplifiedSellers(args),
		onError: err => showErrorNotification(err.message),
	});

	public getSellerDetails(sellerId: string): Promise<SellerTransaction[]> | undefined {
		try {
			return enterprise.getSellerDetails({
				eventId: this.event!.id,
				sellerId,
				sources: ["ficha", "balcony", "delivery", "onlineFicha"],
			});
		} catch (error) {
			if (error instanceof Error) {
				showErrorNotification(error.message);
			}
		}
	}

	public refundFichaSell = async (
		transactionId: string,
		reason: string,
		shouldReturnToStorage: boolean,
	): Promise<boolean> => {
		try {
			await enterprise.refundFichaSell({ transactionId, reason, shouldReturnToStorage });
			showSuccessNotification(t("store:eventStore.refundFichaSell"));
			return true;
		} catch (error) {
			if (error instanceof Error) {
				showErrorNotification(error.message);
			}
			return false;
		}
	};

	public getAllMoneyOperationsAtEvent = new fetchModel<
		{
			eventId: string;
		},
		MoneyOperation[]
	>({
		fnPromise: args => enterprise.getAllMoneyOperationsAtEvent(args),
		onError: err => showErrorNotification(err.message),
	});

	public getOpenedBillPayments = new fetchModel<
		{
			eventId: string;
		},
		OpenedBillPaymentV2[]
	>({
		fnPromise: args => enterprise.getOpenedBillPaymentsV2(args),
		onError: err => showErrorNotification(err.message),
	});

	public getResumeForEventInSections = new fetchModel<
		{ eventId: string },
		EventResumeSection[]
	>({
		fnPromise: args => enterprise.getResumeForEventInSections(args),
		onError: err => showErrorNotification(err.message),
	});

	public getEntranceResumeForEventInSections = new fetchModel<
		{ eventId: string },
		EventResumeSection[]
	>({
		fnPromise: args => enterprise.getEntranceResumeForEventInSections(args),
		onError: err => showErrorNotification(err.message),
	});

	public getClosingResumeReportForEventInSections = new fetchModel<
		{ eventId: string },
		EventResumeSection[]
	>({
		fnPromise: args => enterprise.getClosingResumeReportForEventInSections(args),
		onError: err => showErrorNotification(err.message),
	});

	// open sales
	public getAllOpenSalesByEmployee = new fetchModel<
		{ eventId: string; employeeId: string },
		OpenSale[]
	>({
		fnPromise: args => enterprise.getAllOpenSalesByEmployee(args),
		onError: err => showErrorNotification(err.message),
	});

	public getOpenSaleReport = new fetchModel<{ eventId: string }, OpenSaleReport[]>({
		fnPromise: args => enterprise.getOpenSaleReport(args),
		onError: err => showErrorNotification(err.message),
	});

	public giveRechargeToUser = new fetchModel<
		{ placeId: string; recharge: NewUserRecharge; documentType: UserDocumentType },
		void
	>({
		fnPromise: args => enterprise.giveRechargeToUser(args),
		onError: err => showErrorNotification(err.message),
		onSuccess: () => showSuccessNotification(t("store:eventStore.giveRechargeToUser")),
	});

	public getProductsBurnedPaperTicketsForEvent = new fetchModel<
		{ eventId: string; since?: Date | null | undefined; until?: Date | null | undefined },
		ProductBurnedTicketSold[]
	>({
		fnPromise: args => enterprise.getProductsBurnedPaperTicketsForEvent(args),
		onError: err => showErrorNotification(err.message),
	});

	public getAllTransactionsAfterEventClosing = new fetchModel<
		{ eventId: string; filters: FilterReportAfterEventClosing[] },
		ReportAfterEventClosing[]
	>({
		fnPromise: args => enterprise.getAllTransactionsAfterEventClosing(args),
		onError: err => showErrorNotification(err.message),
	});

	public getZigDeviceSyncForcesAtEvent = new fetchModel<
		{ eventId: string; pagination?: Pagination | null },
		GetZigDeviceSyncForcesAtEvent
	>({
		fnPromise: args => enterprise.getZigDeviceSyncForcesAtEvent(args),
		onError: err => showErrorNotification(err.message),
	});

	public getSellerTipReportForEvent = new fetchModel<
		{
			eventId: string;
			filters: EmployeeTipReportFilters;
		},
		EmployeeTipReport[]
	>({
		fnPromise: args => enterprise.getSellerTipReportForEvent(args),
		onError: err => showErrorNotification(err.message),
	});

	public getAppTransactionsAtEvent = new fetchModel<
		{
			eventId: string;
			pagination: Pagination;
		},
		AppTransactionDetailsPaginationResponse
	>({
		fnPromise: args => enterprise.getAppTransactionsAtEvent(args),
	});

	public getAppTotalizerByEvent = new fetchModel<
		{
			eventId: string;
		},
		AppTransactionsTotalizer
	>({
		fnPromise: args => enterprise.getAppTotalizerByEvent(args),
	});

	public getEventClientsByEntrance = new fetchModel<
		{
			eventId: string;
			filters: UsersEntrancesConsumedReportFilters;
			pagination: Pagination;
		},
		UsersEntrancesConsumedReportResponse
	>({
		fnPromise: args => enterprise.usersEntrancesConsumed(args),
		onError: err => showErrorNotification(err.message),
	});

	public getEventClientByEntranceOverview = new fetchModel<
		{ eventId: string; filters: UsersEntrancesConsumedReportFilters },
		UsersEntrancesConsumedOverview
	>({
		fnPromise: args => enterprise.usersEntrancesConsumedOverview(args),
		onError: err => showErrorNotification(err.message),
	});

	public getEventEntranceList = new fetchModel<{ eventId: string }, EntranceSold[]>({
		fnPromise: args => enterprise.entrancesSoldAtEvent(args),
		onError: err => showErrorNotification(err.message),
	});

	public transactionTransferAtEvent = new fetchModel<
		{ eventId: string },
		TransactionTransfer[]
	>({
		fnPromise: args => enterprise.getTransactionTransfersAtEvent(args),
		onError: err => showErrorNotification(err.message),
	});

	public getReportPdvZigCardErrors = new fetchModel<
		{
			eventId: string;
			pagination: Pagination;
			filters: ReportPdvZigCardFilters;
		},
		ReportPdvZigCardReport
	>({
		fnPromise: args => enterprise.getReportPdvZigCardErrors(args),
		onError: err => showErrorNotification(err.message),
	});

	public getAllOfflineActionTypes = new fetchModel<void, AllOfflineActionTypes[]>({
		fnPromise: () => enterprise.getAllOfflineActionTypes(),
		onError: err => showErrorNotification(err.message),
	});

	public getAllEventActions = new fetchModel<
		IGetAllEventActions,
		ReportActionsFromEventResponse
	>({
		fnPromise: args => enterprise.getAllEventActions(args),
		onError: error => showErrorNotification(error.message),
	});

	public getAllEventActionsFromTotem = new fetchModel<
		IGetAllEventActions,
		ReportActionsFromEventResponse
	>({
		fnPromise: args => enterprise.getAllEventActionsFromTotem(args),
		onError: error => showErrorNotification(error.message),
	});

	public updateEvent = new fetchModel<
		{
			id: string;
			passTemplateId: string | null;
		},
		void
	>({
		fnPromise: args => enterprise.updateEvent(args),
		onError: err => showErrorNotification(err.message),
		onSuccess: () =>
			showSuccessNotification(t("store:eventStore.eventEditedSuccessfully")),
	});

	public getEvent = new fetchModel<
		{
			id: string;
		},
		Event
	>({
		fnPromise: args => enterprise.getEvent(args),
		onError: err => showErrorNotification(err.message),
	});

	public getAllEventActionsToXlsx = new fetchModel<
		IGetAllEventActions,
		ReportActionsFromEventResponse
	>({
		fnPromise: args => enterprise.getAllEventActions(args),
		onError: error => showErrorNotification(error.message),
	});

	public getAllEventActionsFromTotemToXlsx = new fetchModel<
		IGetAllEventActions,
		ReportActionsFromEventResponse
	>({
		fnPromise: args => enterprise.getAllEventActionsFromTotem(args),
		onError: error => showErrorNotification(error.message),
	});
}
