export const clients = {
	headerChart: {
		total: "Total customers",
		label: "During the period",
		averagePublic: "Average audience",
		filters: {
			label: "Filters",
			men: "Men",
			women: "Women",
			others: "Others",
		},
	},
	averagePublicPerWeekDay: {
		title: "Average audience by day of the week",
		label: "Average audience value per day of the week during the period",
		averageTicket: "Average Ticket",
	},
	clientRecordPerDay: {
		title: "Audience record",
		label: "Larger amounts of audience during the period",
		none: "None",
		person: "{{num}} person",
		people: "{{num}} people",
	},
	topClients: {
		title: "TOP Clients",
		label: "Value during the period",
		infoLabel: "Select a customer to see details.",
	},
	topTables: {
		title: "TOP Tables",
		label: "Value during the period",
		infoLabel: "Select a table to see details.",
	},
};
