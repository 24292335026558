import React from "react";
import { useTranslation } from "react-i18next";
import { observer } from "mobx-react-lite";
import { Checkbox, Divider } from "antd";
import { Button, Typography } from "#components/primitive";
import DownloadIcon from "#components/icon/imgs/download.svg";
import { useDisclosure } from "#resources/hooks";
import WarningIcon from "#assets/svg/warning-triangule.svg";
import RootStore from "#stores/index";
import { ModalApproveConfirmation } from "./modal-approve-confirmation";
import s from "./pending-contracts.scss";
import { ModalCodeConfirmation } from "./modal-code-confirmation";
import { PendingPlaceContractsContractFiles } from "#resources/api/enterprise-generated";

interface IPendingContractsProps {
	placeId: string;
}

export const PendingContracts = observer(PendingContractsComponent);

function PendingContractsComponent({ placeId }: IPendingContractsProps) {
	const { t } = useTranslation("components", {
		keyPrefix: "pendingContracts",
	});
	const { t: tGlobalKeys } = useTranslation("globalKeys");

	const { authStore, placeStore } = React.useContext(RootStore);
	const [selectedContractIds, setSelectedContractIds] = React.useState<string[]>([]);
	const [code, setCode] = React.useState<string>("");

	const showPendingContracts = useDisclosure();
	const modalApproveConfirmation = useDisclosure();
	const modalCodeConfirmation = useDisclosure({
		onCloseCallback: () => {
			setSelectedContractIds([]);
			setCode("");
		},
	});

	const usernameIsZigEmployee = (username: string) =>
		/^.+(\$zigemployee\$).+$/.test(username);

	React.useEffect(() => {
		if (placeId) {
			if (
				authStore &&
				authStore.currentEmployee &&
				authStore.currentEmployee &&
				authStore.currentEmployee.role &&
				authStore.currentEmployee.role.slug === "master" &&
				!usernameIsZigEmployee(authStore.currentEmployee.username)
			) {
				getPendingPlaceContracts(placeId);
				showPendingContracts.onOpen();
			}
		}
	}, [placeId]);

	async function getPendingPlaceContracts(placeId: string) {
		await placeStore.getPendingPlaceContracts.fetch({
			placeId,
		});
	}

	async function signPlaceContracts() {
		await placeStore.signPlaceContracts.fetch({
			contractIds: selectedContractIds,
		});
		modalApproveConfirmation.onClose();
		modalCodeConfirmation.onOpen();
	}

	async function confirmSignPlaceContracts() {
		await placeStore.confirmSignPlaceContracts.fetch({
			code,
			placeId,
		});
		modalCodeConfirmation.onClose();
		await getPendingPlaceContracts(placeId);
	}

	function onChangeCode(code: string) {
		const codeUpperCase = code.toUpperCase();
		setCode(codeUpperCase);
	}

	const pendingContracts = placeStore.getPendingPlaceContracts.value?.contracts;
	const loadingSignPlaceContracts = placeStore.signPlaceContracts.loading;
	const loadingConfirmSignPlaceContracts = placeStore.confirmSignPlaceContracts.loading;

	function downloadFiles(fileUrls: PendingPlaceContractsContractFiles[]) {
		fileUrls.forEach(file => {
			window.open(file.url);
		});
	}

	if (!pendingContracts?.length || !showPendingContracts.isOpen) return <></>;

	const allContractIds = pendingContracts.map(pendingContract => pendingContract.id);
	const allContractsSelected = allContractIds.length === selectedContractIds.length;

	return (
		<div className={s.container}>
			<div className={s.content}>
				<Typography type="heading" variant="v3">
					{t("title")}
				</Typography>
				<div className={s.description}>
					<div>
						<WarningIcon />
					</div>
					<Typography type="paragraph" variant="v1">
						{t("description")}
					</Typography>
				</div>
				<div className={s.contractSelectContainer}>
					<Checkbox
						value={allContractsSelected ? [] : allContractIds}
						onChange={e => setSelectedContractIds(e.target.value)}
					>
						{allContractsSelected ? t("deselectAllContracts") : t("selectAllContracts")}
					</Checkbox>
					<Divider />
					<Checkbox.Group
						onChange={selected => {
							setSelectedContractIds(selected as string[]);
						}}
						value={selectedContractIds}
					>
						{pendingContracts.map(pendingContract => (
							<div key={pendingContract.id}>
								<div className={s.header}>
									<Checkbox value={pendingContract.id}>
										{`${pendingContract.requestType} #${
											pendingContract.id.split("-")[0]
										}`}
									</Checkbox>
									{pendingContract.contractFiles.length ? (
										<div
											onClick={() => downloadFiles(pendingContract.contractFiles)}
											className={s.icon}
										>
											<DownloadIcon />
										</div>
									) : null}
								</div>
								<div>
									{pendingContract.equipments.map((equipament, index) => (
										<div key={index}>
											<Typography
												type="paragraph"
												variant="v4"
											>{`${equipament.quantity}x ${equipament.type}`}</Typography>
										</div>
									))}
								</div>
								<Divider />
							</div>
						))}
					</Checkbox.Group>
				</div>
				<div className={s.actions}>
					<Button variant="secondary" onClick={showPendingContracts.onClose}>
						{tGlobalKeys("close")}
					</Button>
					<Button
						variant="primary"
						disabled={!selectedContractIds.length}
						onClick={modalApproveConfirmation.onOpen}
					>
						{t("approveContract")}
					</Button>
				</div>
			</div>
			<ModalApproveConfirmation
				visible={modalApproveConfirmation.isOpen}
				loading={loadingSignPlaceContracts}
				onClose={modalApproveConfirmation.onClose}
				onOk={signPlaceContracts}
			/>
			<ModalCodeConfirmation
				visible={modalCodeConfirmation.isOpen}
				loading={loadingConfirmSignPlaceContracts}
				onClose={modalCodeConfirmation.onClose}
				onOk={confirmSignPlaceContracts}
				code={code}
				onChangeCode={onChangeCode}
			/>
		</div>
	);
}
