exports = module.exports = require("../../../../../node_modules/.pnpm/css-loader@0.28.11/node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._1hV13rcimtBygFJIvz53Xj [class^=ant-modal-content]{border-top-right-radius:0}", ""]);

// exports
exports.locals = {
	"modal": "_1hV13rcimtBygFJIvz53Xj"
};