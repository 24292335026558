export const technicalSheet = {
	title: "Ficha técnica",
	modalDelete: {
		title: "Excluir ficha técnica",
		text:
			"Ao confirmar a ação você exclui permanentemente a ficha técnica. Deseja continuar?",
		okText: "Sim, desejo excluir a ficha técnica",
	},
	addInput: {
		columns: {
			inputName: "Insumo",
			count: "Quantidade",
			unitCostValue: "Custo unitário",
			totalCost: "Custo total",
			actions: "Ações",
		},
		warnings: {
			withoutInput: "Selecione um insumo",
			withoutQuantity: "Adicione uma quantidade",
			alreadyExist:
				"O insumo já existe na lista de insumos, selecione um insumo diferente.",
		},
		inputName: "Insumo",
		count: "Quantidade",
		loss: "Perda",
		value: "Preço de custo",
		price: "Preço",
		add: "Adicionar",
	},
	editInput: {
		inputName: "Insumo",
		count: "Quantidade",
		loss: "Perda",
		value: "Preço de custo",
		price: "Preço",
		delete: "Excluir",
	},
	header: {
		addButton: "Adicionar Insumo",
		editButton: "Editar ficha técnica",
	},
	infoCard: {
		value: "Preço de venda",
		totalCost: "Custo total",
		cmv: "CMV %",
		profitMargin: "Margem de lucro",
	},
	table: {
		columns: {
			inputName: "Insumo",
			count: "Quantidade",
			loss: "Perda",
			unitCostValue: "Custo unitário",
			yieldPercentage: "Rendimento",
			unitCostWithYield: "Custo com rendimento",
			totalCost: "Custo total",
			actions: "Ações",
		},
	},
};
