import * as React from "react";
import { NavBar as NavBarV2 } from "#components/nav-bars-shared/nav-bar-v2";
import { useTranslation } from "react-i18next";
import {
	NavBarRouteV2,
	RouteItemProps,
	RouteItemPropsV2,
	useNavigationWithRestriction,
} from "../navigation-with-permissions";
import rootStore from "#stores/index";
import { useSandwichMenu } from "#structure/providers/sandwich-menu";
import { useActiveEvents } from "../use-active-events";
import { observer } from "mobx-react-lite";
import NavBar, { NavBarRoute } from "#components/nav-bars-shared/nav-bar";
import { useModularization } from "../use-modularization";

function Nav() {
	const { organizationStore } = React.useContext(rootStore);
	const { t } = useTranslation("nav", { keyPrefix: "navbar.org" });
	const {
		isOpenSandwichMenu,
		onChangeIsOpenSandwichMenu,
		currentSelectedMenu,
	} = useSandwichMenu();

	const { activeEvents } = useActiveEvents();

	const hasLoyaltyOrgFeature = organizationStore.organization?.features.some(
		feature => feature.id === "loyalty",
	);
	const hasGiftbackOrgFeature = organizationStore.organization?.features.some(
		feature => feature.id === "giftbackFeature",
	);

	const { showMenuV2 } = useModularization();

	const routesV1: RouteItemProps[] = [
		{
			icon: "panel",
			label: t("panel"),
			url: "/",
		},
		{
			icon: "employee",
			label: t("team"),
			url: "/employees",
		},
		{
			icon: "stock-inventory",
			label: t("stockInventory"),
			url: `/products`,
			permissions: ["seeOrganizationProducts"],
		},
		{
			icon: "tax-data",
			label: t("taxData"),
			permissions: ["seeOrganizationFiscalData"],
			url: "/fiscal-data",
		},
		{
			icon: "financial-report",
			label: t("financialReport"),
			url: "/reports",
			permissions: ["seeOrganizationReportsPage"],
		},
		{
			icon: "chart-of-accounts",
			label: t("chartOfAccounts"),
			url: "/chart-of-accounts",
			permissions: ["accessFinancialBackoffice"],
		},
		{
			icon: "satisfaction-survey",
			label: t("satisfactionSurvey"),
			url: "/satisfaction-survey",
			permissions: ["manageNpsSurvey"],
		},
		{
			tagType: hasLoyaltyOrgFeature ? undefined : "comingSoon",
			icon: "loyalty",
			label: t("loyalty"),
			url: hasLoyaltyOrgFeature ? "/loyalty" : "/loyalty/comming-soon",
			orgFeaturesIds: "loyalty",
		},
		{
			icon: "giftback",
			label: "Giftback",
			url: "/giftback",
			hide: !hasGiftbackOrgFeature,
		},
		// {
		// 	icon: "gift-card",
		// 	label: t("giftCard"),
		// 	url: "/gift-card",
		// 	orgFeaturesIds: "giftCardFeature",
		// 	permissions: ["manageGiftCards"],
		// },
		{
			icon: "customers",
			label: t("customers"),
			url: "/client-area",
		},
		{
			icon: "communication",
			label: t("communication"),
			url: "/communication",
			permissions: ["manageMessage"],
		},
		{
			icon: "multivendor",
			label: t("vendors"),
			url: "/vendors",
			orgFeaturesIds: "multivendor",
		},
	];

	const routesV2: RouteItemPropsV2[] = [
		{
			icon: "place",
			label: t("panel"),
			url: "/",
		},
		{
			icon: "team",
			label: t("employee"),
			url: "/employees",
			alternativeUrls: ["/employees-roles"],
		},
		{
			icon: "cargo-cart",
			label: t("productRegistration"),
			url: "/products",
			alternativeUrls: ["/categories"],
		},
		{
			icon: "crm",
			label: t("crm"),
			submenus: [
				{
					label: t("customersList"),
					url: "/clients",
					permissions: ["seePlaceCustomersPage"],
				},
				{
					label: t("advancedBI"),
					url: "/client-area",
				},
				// {
				// 	label: t("giftCard"),
				// 	url: "/gift-card",
				// 	orgFeaturesIds: "giftCardFeature",
				// 	permissions: ["manageGiftCards"],
				// },
				{
					label: t("sendingMessages"),
					permissions: ["manageMessage"],
					url: "/communication",
				},
				{
					label: t("satisfactionSurvey"),
					url: "/satisfaction-survey#results",
					permissions: ["manageNpsSurvey"],
					alternativeUrls: [
						"/satisfaction-survey",
						"/satisfaction-survey#share-settings",
						"/satisfaction-survey#search-settings",
					],
				},
				{
					tagType: hasLoyaltyOrgFeature ? undefined : "comingSoon",
					label: t("loyalty"),
					url: hasLoyaltyOrgFeature ? "/loyalty" : "/loyalty/comming-soon",
					orgFeaturesIds: "loyalty",
				},
			],
		},
		{
			icon: "reports",
			label: t("reports"),
			permissions: ["seeOrganizationReportsPage"],
			url: "/reports#revenue",
			alternativeUrls: [
				"/reports",
				"/reports#service",
				"/reports#products",
				"/reports#people",
				"/reports#ticket",
				"/reports#entrance",
			],
		},
		{
			icon: "fiscal",
			label: t("taxRegistration"),
			permissions: ["seeOrganizationFiscalData"],
			url: "/fiscal-data#fiscal-profile",
			alternativeUrls: [
				"/fiscal-data",
				"/fiscal-data#fiscal-product-groups",
				"/fiscal-data#fiscal-couvert",
				"/fiscal-data#fiscal-minimum-consummation",
				"/fiscal-data#fiscal-recharge",
			],
		},
		{
			icon: "chart-of-accounts-v2",
			label: t("chartOfAccounts"),
			url: "/chart-of-accounts",
			permissions: ["accessFinancialBackoffice"],
		},
		{
			icon: "multivendor-v2",
			label: t("vendors"),
			url: "/vendors",
			orgFeaturesIds: "multivendor",
		},
		{
			icon: "giftback",
			label: t("giftback"),
			url: "/giftback",
			hide: !hasGiftbackOrgFeature,
		},
	];

	const items = showMenuV2
		? useNavigationWithRestriction<NavBarRouteV2>(routesV2)
		: useNavigationWithRestriction(routesV1);

	if (showMenuV2) {
		return (
			<NavBarV2
				runningEvents={activeEvents}
				items={items.restrictedRoutes as NavBarRouteV2[]}
				isOpenSandwichMenu={isOpenSandwichMenu}
				onChangeIsOpenSandwichMenu={onChangeIsOpenSandwichMenu}
				selectedMenu={currentSelectedMenu}
				showRunningEventsIdx={0}
			/>
		);
	}

	return (
		<NavBar
			items={items.restrictedRoutes as NavBarRoute[]}
			isOpenSandwichMenu={isOpenSandwichMenu}
			onChangeIsOpenSandwichMenu={onChangeIsOpenSandwichMenu}
		/>
	);
}

export default observer(Nav);
