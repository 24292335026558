export const pos = {
	title: "Dispositivos: PDVs e Totens do Evento",
	open: "{{active}} de {{total}} PDV´s em aberto",
	info:
		"É necessário que o PDV esteja conectado à internet para sincronização das configurações.",
	searchPlaceholder: "Buscar dispositivo ou funcionário",
	noName: "Sem nome",
	error: "Essa operação só é permitida quando não tem nenhum PDV selecionado",
	editConfig: {
		label: "Editar Configurações",
		title: "Modificar PDVs ({{count}})",
		configs: {
			label: "Configurações",
			checkbox: "Tentar parear maquininha bluetooth",
		},
		bluetooth: "Bluetooth",
		wifi: "Wi-fi",
		preferences: "Preferências",
		noName: "Preferência sem nome",
		confirmMessage:
			"Tem certeza que deseja salvar as alterações? Ambos o wifi e dados móveis do(s) dispositivo(s) estão desabilitados, desta forma não será possível visualizar as informações atualizadas do dispositivo.",
		errorMessage:
			"Uma dessas preferencias 'Habilitar venda' e 'Habilitar venda em grupo' precisa estar ativa",
	},
	columns: {
		fingerPrint: "CÓDIGO",
		product: "PRODUTO",
		version: "VERSÃO",
		employee: "COLABORADOR",
		activation: "ATIVAÇÃO",
		lastUpdate: "ÚLTIMA ATUALIZAÇÃO",
		cleanedDataStatus: "LIMPOU?",
		hasFinishedActivity: {
			label: "OPERAÇÃO",
			finished: "Encerrado",
			open: "Em aberto",
		},
		devicePlatform: "MODELO",
		deviceConfig: "STATUS",
		date: "DATA",
	},
	deviceConfigColumn: {
		noData: "sem dados",
		on: "ligado",
		off: "desligado",
		tryingToConnect: "Tentando conectar à maquininha bluetooth",
		connected: "conectada",
		disconnect: "desconectada",
		bluetooth: "Bluetooth",
		bluetoothMachine: "Maquininha bluetooth",
		wifi: "Wifi",
		mobileData: "Dados móveis",
		nfc: "NFC",
	},
	settings: "Configurações",
	humanizedPreferencies: {
		dettachOnCheckout: "Desanexar automaticamente no checkout",
		hidePaymentModeVoucher: "Esconder modo de pagamento voucher",
		isGroupedSellEnabled: "Habilitar venda em grupo",
		isKeyboardOpenEnabled: "Teclado já aberto na busca",
		isNormalSellEnabled: "Habilitar venda",
		isNumericKeyboardInObsEnabled: "Observação com teclado numérico",
		isSearchByCodeEnabled: "Busca numérica",
		isSellWithoutCardEnabled: "Habilitar venda sem cartão",
		isStockTransfEnabled: "Transferir estoque",
		isStorageTransferPrinterLanActive:
			"Ativar impressora de transferencia de estoque por LAN",
		printTwoTickets: "Imprimir duas vias de venda",
		printViaBtForWaiter: "Mostrar botão para imprimir pedidos pendentes de garçons",
		registerWithoutCard: "Registro sem cartão",
		removeCreditDebitPaymentOption: "Remover opção de Débito e Crédito",
		hideDettachOption: "Esconder desanexar",
	},
};
