export const awards = {
	title: "Awards",
	description: "Select the products and discounts that the customer will be entitled to.",
	maxUses: {
		label: "Maximum number of uses per gain",
		withoutLimits: "No Boundaries",
	},
	discountType: {
		label: "Discount type",
		percentage: "Percentage",
		value: "Value",
		error: "The following products are worth less than the discount given",
		table: {
			product: "Product",
			price: "Sale price",
			promotionalPrice: "Promotional price",
			error: "Maximum and minimum value does not match",
			empty: {
				label: "No products selected",
				description:
					"If you do not select products, the promotion will work on all products.",
			},
			total: "Total of {{count}} product(s)",
			add: "Add products",
		},
	},
};
