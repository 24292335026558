export const debitNotes = {
	title: "Notas de Débito",
	filename: "notas-de-debito",
	table: {
		serialNumber: "Número",
		issueDate: "Data de emissão",
		period: "Período",
		status: "Status",
		value: "Valor",
		generatedAs: "Gerado como",
		pdf: "PDF",
		noAttachments: "Sem anexos",
		attachments: "Anexos",
	},
	status: {
		created: "Criado",
		generated: "Gerado",
		cancelled: "Cancelado",
	},
	generated: {
		automatic: "Automático",
		manually: "Manualmente",
	},
	emptyText: "Não há nada a mostrar para o período selecionado",
};
