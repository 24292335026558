import enterprise from "#resources/api/enterprise-client";
import { EnterpriseApiSignature, EnterpriseEndpointKey } from "#resources/helpers/api";
import { showErrorNotification } from "#resources/helpers/notifications";
import { SdkgenError } from "@sdkgen/browser-runtime";
import {
	useMutation,
	UseMutationOptions,
	UseMutationResult,
} from "@tanstack/react-query";

type ICreateMutationFactory = <T extends EnterpriseEndpointKey>(
	methodName: T,
	defaultOptions?: UseMutationOptions<
		EnterpriseApiSignature<T>["result"],
		SdkgenError,
		EnterpriseApiSignature<T>["args"]
	>,
) => {
	useApiHook: <TData = EnterpriseApiSignature<T>["result"]>(
		options?: UseMutationOptions<TData, SdkgenError, EnterpriseApiSignature<T>["args"]>,
	) => UseMutationResult<TData, SdkgenError, EnterpriseApiSignature<T>["args"]>;
	MethodSignature: EnterpriseApiSignature<T>;
	MethodResult: EnterpriseApiSignature<T>["result"];
	MethodArgs: EnterpriseApiSignature<T>["args"];
	UseApiOptions: Omit<
		UseMutationOptions<
			EnterpriseApiSignature<T>["result"],
			SdkgenError,
			EnterpriseApiSignature<T>["args"]
		>,
		"mutationFn"
	>;
};

/**
 * Cria um hook de mutation utilizando React Query e SDKgen.
 *
 * @param methodName - O nome do método da API.
 * @param defaultOptions - Opções padrão para a mutation.
 * @returns Um objeto contendo o hook de consulta e os tipos relacionados.
 */
export const createMutationFactory: ICreateMutationFactory = (
	methodName,
	defaultOptions,
) => {
	type MethodSignature = EnterpriseApiSignature<typeof methodName>;
	type MethodResult = MethodSignature["result"];
	type MethodArgs = MethodSignature["args"];
	type UseApiOptions = Omit<
		UseMutationOptions<MethodResult, SdkgenError, MethodArgs>,
		"mutationFn"
	>;

	const useApiHook = <TData = MethodResult>(
		options?: UseApiOptions,
	): UseMutationResult<TData, SdkgenError, MethodArgs> => {
		return useMutation<TData, SdkgenError, MethodArgs>({
			mutationKey: [methodName],
			mutationFn: args => (enterprise as any)[methodName](args),
			onError(error) {
				showErrorNotification(error.message);
			},
			...defaultOptions,
			...options,
		});
	};

	return {
		useApiHook,
		MethodSignature: {} as MethodSignature,
		MethodResult: {} as MethodResult,
		MethodArgs: {} as MethodArgs,
		UseApiOptions: {} as UseApiOptions,
	};
};
