import { NavBarRouteV2 } from "#components/nav-bars/navigation-with-permissions";

export const extractUrlListFromNavItems = (itemsList: NavBarRouteV2[]) => {
	let items: { label: string; url: string }[] = [];

	items = itemsList
		.filter(item => item.url)
		.map(item => ({
			label: item.label,
			url: item.url!.split("#")[0],
		}));

	const subItems = itemsList
		.filter(item => item.submenus?.length)
		.reduce((prev, curr) => {
			const submenusList = (curr.submenus ?? []).map(submenu => ({
				label: submenu.label,
				url: submenu.url?.split("#")[0],
			}));

			return [...prev, ...submenusList];
		}, []);

	return [...items, ...subItems];
};
