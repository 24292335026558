export const stockTransferHistory = {
	titlePage: "Transferências de estoque",
	description:
		"Este relatório possibilita acompanhar as movimentações entre estoques fornecendo uma visibilidade adequada das transferências para estoquistas e gestores.",
	actionButtons: {
		print: "Imprimir",
		downloadExcel: "Download em Excel",
	},
	filterToPeriod: "Filtrar por período",
	column: {
		dateTransfer: "Data de transferência",
		employee: "Funcionário",
		outgoingStock: "Estoque de saída",
		incomingStock: "Estoque de entrada",
		inputAndProduct: "Insumo/Produto",
		amount: "Qtd de itens",
	},
	searchBy: "Buscar por",
	employeePlaceholder: "Funcionário",
	storagePlaceholder: "Estoque",
};
