import { useValidations } from "./use-validations";

export const cpfMask = /(\d{3})(\d{3})(\d{3})(\d{2})/g;
export const cepMask = /(\d{5})(\d{3})/g;
export const cnpjMask = /(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/g;
export const bankAccountAgencyMask = /(\d{4})(\d{1})?/g;
export const bankAccountNumberMask = /(\d)([\da-zA-Z]{1}$)/;
export const phoneWithDDDMask = /(\d{2})(9)(\d{4})(\d{4})/;
export const phoneWithDDIMask = /(\+?\d{2})(\d{2})(9)(\d{4})(\d{4})/;

const MOBILE_PHONE_NUMBER_LIMIT = 11;
const CPF_NUMBER_LIMIT = 11;
const CNPJ_NUMBER_LIMIT = 14;

export function useMasks() {
	const { validateCpf, validateCnpj, validatePhone } = useValidations();

	function maskCpf(cpf: string) {
		return cpf.replace(cpfMask, "$1.$2.$3-$4");
	}

	function maskBankAccountAgency(agency: string) {
		return agency.replace(bankAccountAgencyMask, "$1-$2");
	}

	function maskBankAccountNumber(number: string) {
		return number.replace(bankAccountNumberMask, "$1-$2");
	}

	function maskPhoneWithDDD(phone: string) {
		return phone.replace(phoneWithDDDMask, "($1) $2 $3-$4");
	}

	function maskPhoneWithDDI(phone: string) {
		return phone.replace(phoneWithDDIMask, "$1 ($2) $3 $4-$5");
	}

	function maskCnpj(cnpj: string) {
		return cnpj.replace(cnpjMask, "$1.$2.$3/$4-$5");
	}

	function maskCep(cnpj: string) {
		return cnpj.replace(cepMask, "$1-$2");
	}

	function maskPix(pixKey: string) {
		const newPixValue = pixKey.replace(/\W+/g, "");
		if (!isNaN(Number(newPixValue))) {
			if (validateCpf(newPixValue) && newPixValue.length === CPF_NUMBER_LIMIT) {
				return maskCpf(newPixValue);
			}

			if (validateCnpj(newPixValue) && newPixValue.length === CNPJ_NUMBER_LIMIT) {
				return maskCnpj(newPixValue);
			}

			if (
				validatePhone(newPixValue) &&
				newPixValue.length === MOBILE_PHONE_NUMBER_LIMIT
			) {
				return maskPhoneWithDDD(newPixValue);
			}

			return newPixValue;
		}
		return pixKey;
	}

	return {
		maskCep,
		maskCpf,
		maskCnpj,
		maskBankAccountAgency,
		maskBankAccountNumber,
		maskPhoneWithDDD,
		maskPhoneWithDDI,
		maskPix,
	};
}
