import * as React from "react";
import { ICurrencyOptions, ResultCurrency } from "./utils";
import { useCurrency } from "#resources/hooks";

export type WithCurrencyProps = ResultCurrency;

export const withCurrency = <P extends object>(
	Component: React.ComponentType<P>,
	options?: ICurrencyOptions,
): React.FC<Omit<P, keyof WithCurrencyProps>> => {
	// passei a props como any pois tava quebrando no componente e pelo tempo que temos
	// props: ResultCurrency<P>  <- como estava antes
	return (props: Omit<P, keyof WithCurrencyProps>) => {
		const hookProps = useCurrency(undefined, options);

		return <Component {...(props as P)} {...hookProps} />;
	};
};
