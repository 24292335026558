import * as React from "react";
import { DashLanguages } from "../../i18n/utils";
import i18n from "../../i18n";
import enterpriseAPI from "#resources/api/enterprise-client";

type Languages = DashLanguages;

interface ProviderProps {
	language: Languages;
	languageInfo: LanguageOption;
	changeLanguage: (language: Languages, forceReload?: boolean) => void;
}

type LanguageOption = { value: DashLanguages; label: string; icon: string };

const defaultLanguage: LanguageOption = {
	label: "Português (BR)",
	value: "pt-BR",
	icon: "brazil",
};

const languageOptions: Array<LanguageOption> = [
	defaultLanguage,
	{
		label: "Español (ES)",
		value: "es-ES",
		icon: "spain",
	},
	{
		label: "English (US)",
		value: "en-US",
		icon: "usa",
	},
	{
		label: "French (FR)",
		value: "fr-FR",
		icon: "french",
	},
];

const LanguageContext = React.createContext<ProviderProps | null>(null);

function LanguageProvider({ children }: { children: React.ReactNode }) {
	const initialLanguage = (localStorage.getItem("lng") || i18n.language) as Languages;
	const [language, setLanguage] = React.useState(initialLanguage);
	const [languageInfo, setLanguageInfo] = React.useState<LanguageOption>(defaultLanguage);

	React.useEffect(
		function updateLanguage() {
			const selectedLanguage = languageOptions.find(({ value }) => value === language);

			if (!selectedLanguage) {
				throw new Error("Language not found in options");
			}

			setLanguageInfo(selectedLanguage);
			changeLanguage(selectedLanguage.value);
		},
		[language],
	);

	function changeLanguage(lng: Languages, forceReload: boolean = false) {
		const hasLanguage = languageOptions
			.map(({ value }) => value)
			.some(lngOption => lngOption === lng);

		if (!hasLanguage) {
			throw new Error("Language not found");
		}

		setLanguage(lng);
		i18n.changeLanguage(lng);
		localStorage.setItem("lng", lng);
		enterpriseAPI.extra.set("lng", lng);

		if (forceReload) {
			window.location.reload();
		}
	}

	return (
		<LanguageContext.Provider value={{ language, changeLanguage, languageInfo }}>
			{children}
		</LanguageContext.Provider>
	);
}

function useLanguage() {
	const context = React.useContext(LanguageContext);
	if (!context) {
		throw new Error("useLanguage should be used inside LanguageProvider");
	}

	return context;
}

// HOC for class components
type LanguageHOCProps = ProviderProps;

function withLanguage<P extends object>(
	Component: React.ComponentType<P>,
): React.ComponentType<Omit<P, keyof LanguageHOCProps>> {
	return (props: Omit<P, keyof LanguageHOCProps>) => {
		const language = useLanguage();
		return <Component {...(props as P)} {...language} />;
	};
}

// Consumer for class components
function LanguageConsumer({
	children,
}: {
	children: (props: LanguageHOCProps) => React.ReactNode;
}) {
	return (
		<LanguageContext.Consumer>
			{context => {
				if (!context) {
					throw new Error("useLanguage should be used inside LanguageProvider");
				}
				return children(context);
			}}
		</LanguageContext.Consumer>
	);
}

export {
	LanguageProvider,
	LanguageConsumer,
	LanguageHOCProps,
	useLanguage,
	withLanguage,
	languageOptions,
};
