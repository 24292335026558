import { RootStore } from ".";
import { fetchModel } from "#helpers/fetch-model";
import enterprise from "#resources/api/enterprise-client";
import { observable } from "mobx";
import {
	showErrorNotification,
	showSuccessNotification,
} from "#resources/helpers/notifications";
import {
	AverageTicketFilter,
	EditMessage,
	Message,
	MessageCampaign,
	MessageClient,
	MessageClientDataForPlace,
	MessageClientInsertResponse,
	MessageSentResume,
	MessageTemplate,
	MessageTemplateResponse,
	NewMessage,
	SearchResult,
	SentMessageClient,
	SimplifiedPlaceWhatsapp,
	UsersWithAverageTicket,
} from "#resources/api/enterprise-generated";
import i18n from "#i18n/index";

const t = i18n.t;

export class MessageStore {
	public rootStore: RootStore;
	public messageClients: MessageClient[] | null = null;
	public messageClientsAmountBefore: number | null = null;
	public importedSheetData: MessageClient[] = [];

	constructor(rootSore: RootStore) {
		this.rootStore = rootSore;
	}

	@observable
	public clientFiltered: SearchResult[] = [];

	public getPlaceUsersByAverageTicket = new fetchModel<
		{
			placeIds: string[];
			since: Date;
			until: Date;
			filters?: AverageTicketFilter | null;
		},
		UsersWithAverageTicket[]
	>({
		fnPromise: args => enterprise.getPlaceUsersByAverageTicket(args),
		onError: err => showErrorNotification(err.message),
	});

	public getMessages = new fetchModel<
		{ page: number; limit: number; campaignIds?: string[] | null | undefined },
		Message[]
	>({
		fnPromise: args => enterprise.getMessages(args),
		onError: err => showErrorNotification(err.message),
	});

	public getMessage = new fetchModel<{ id: string }, Message>({
		fnPromise: args => enterprise.getMessage(args),
		onError: err => showErrorNotification(err.message),
	});

	public cancelMessage = new fetchModel<{ id: string }, void>({
		fnPromise: args => enterprise.cancelMessage(args),
		onSuccess: () => showSuccessNotification(t("store:smsStore.cancelSmsCampaign")),
		onError: err => showErrorNotification(err.message),
	});

	public editMessage = new fetchModel<{ id: string; campaign: EditMessage }, Message>({
		fnPromise: args => enterprise.editMessage(args),
		onError: err => showErrorNotification(err.message),
	});

	public editMessageClients = new fetchModel<
		{ id: string; clients: MessageClient[] },
		MessageClientInsertResponse[]
	>({
		fnPromise: args => enterprise.editMessageClients(args),
		onSuccess: () => showSuccessNotification(t("store:smsStore.editSmsCampaignClients")),
		onError: err => showErrorNotification(err.message),
	});

	public getMessageClients = new fetchModel<
		{ id: string; page: number; limit: number },
		MessageClient[]
	>({
		fnPromise: args => enterprise.getMessageClients(args),
		onError: err => showErrorNotification(err.message),
	});

	public getMessageClientData = new fetchModel<
		{ placeId: string; userIds: string[] },
		MessageClientDataForPlace[]
	>({
		fnPromise: args => enterprise.getMessageClientData(args),
		onError: err => showErrorNotification(err.message),
	});

	public createMessage = new fetchModel<{ message: NewMessage }, Message>({
		fnPromise: args => enterprise.createMessage(args),
		onError: err => showErrorNotification(err.message),
	});

	public getSentMessageClients = new fetchModel<
		{ id: string; page: number; limit: number },
		SentMessageClient[]
	>({
		fnPromise: args => enterprise.getSentMessageClients(args),
		onError: err => showErrorNotification(err.message),
	});

	public getMessageExamples = new fetchModel<{ placeId: string }, string[]>({
		fnPromise: args => enterprise.getMessageExamples(args),
		onError: err => showErrorNotification(err.message),
	});

	public getPlaceMessageCost = new fetchModel<
		{ placeId: string; channel: string },
		number
	>({
		fnPromise: args => enterprise.getPlaceMessageCost(args),
		onError: err => showErrorNotification(err.message),
	});

	public createMessageTemplate = new fetchModel<
		{ messageTemplate: MessageTemplate },
		MessageTemplateResponse
	>({
		fnPromise: args => enterprise.createMessageTemplate(args),
		onError: err => showErrorNotification(err.message),
	});

	public getMessageTemplate = new fetchModel<{ id: string }, MessageTemplateResponse>({
		fnPromise: args => enterprise.getMessageTemplate(args),
		onError: err => showErrorNotification(err.message),
	});

	public fetchMessageTemplate = new fetchModel<
		{ placeId: string },
		MessageTemplateResponse[]
	>({
		fnPromise: args => enterprise.fetchMessageTemplate(args),
		onError: err => showErrorNotification(err.message),
	});

	public getSentMessageClientsResume = new fetchModel<
		{ messageId: string; channel: string },
		MessageSentResume
	>({
		fnPromise: args => enterprise.getSentMessageClientsResume(args),
		onError: err => showErrorNotification(err.message),
	});

	public createMessageCampaign = new fetchModel<{ name: string }, MessageCampaign>({
		fnPromise: args => enterprise.createMessageCampaign(args),
		onError: err => showErrorNotification(err.message),
	});

	public fetchMessagesCampaign = new fetchModel<
		{ id?: string | null; name?: string | null },
		MessageCampaign[]
	>({
		fnPromise: args => enterprise.fetchMessagesCampaign(args),
		onError: err => showErrorNotification(err.message),
	});

	public getPlaces = new fetchModel<{}, SimplifiedPlaceWhatsapp[]>({
		fnPromise: args => enterprise.getSimplifiedPlacesWhatsapp(args),
		onError: err => showErrorNotification(err.message),
	});

	public markTemplateAsUsed = new fetchModel<{ id: string }, MessageTemplateResponse>({
		fnPromise: args => enterprise.setMessageTemplateTwilioIsUsed(args),
		onError: err => showErrorNotification(err.message),
	});
}
