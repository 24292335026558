export const nfReport = {
	label: "Purchase invoices",
	columns: {
		name: "Note number",
		date: "Import date",
		emission: "Issue date",
		value: "Value",
	},
	productColumns: {
		name: "Products",
		quantity: "The amount",
		unit: "Unit",
		unitValue: "Unitary value",
		total: "Amount",
	},
	notAssociatedProduct: "(Unassociated product)",
	empty: "No products",
};
