export const errorsValidations = {
	anticipation: {
		value: {
			noLiquidValue: "Enter the net amount for the anticipation",
			liquidValue:
				"Amount requested greater than net amount available ({{totalAvailable}})",
			noTotalValue: "Enter the total amount for the anticipation",
			totalValue:
				"Amount requested greater than total amount available ({{totalAvailable}})",
			reason: "Enter a reason for anticipation",
		},
		accountData: {
			noAccountType: "Select an account type for the advance",
			accountHolder: "Enter the name of the account holder",
			accountTypeExisting: "Select one of the existing accounts",
			accountName: "Enter the name to identify the account",
			accountBank: "Select the bank to which the account belongs",
			accountDocument: "Enter the account holder's document (CPF/CNPJ)",
			accountDocumentInvalid: "Invalid document",
			accountAgency: "Enter the agency number",
			accountAgencyInvalid: "Invalid agency",
			accountNumber: "Enter account number",
			accountDigit: "Enter the account digit",
			accountType: "Select account type",
			accountPixKey: "Invalid PIX key",
			accountTypeWrapper: "Fill in your bank details or PIX key",
		},
	},
	withdraw: {
		value: {
			noWithdrawValue: "Enter the amount for the withdrawal",
			withdrawValue:
				"Amount requested greater than total amount available ({{totalAvailable}})",
			anticipation: "Anticipation required",
			reason: "Enter a reason for the request",
			accountChoice: "Select an account type for the request",
			existingAccount: "Select one of the existing accounts",
			accountName: "Enter the name to identify the account",
			accountHolder: "Enter the name of the account holder",
			accountBank: "Select the bank to which the account belongs",
			accountNumber: "Enter account number",
			accountDigit: "Enter the account digit",
			agencyNumber: "Enter the agency number",
			agencyInvalid: "Invalid agency",
			documentNumber: "Enter the account holder's document (CPF/CNPJ)",
			documentInvalid: "Invalid document",
			accountType: "Select account type",
			pixKeyInvalid: "Invalid PIX key",
			receiverDataWrapper: "Fill in your bank details or PIX key",
		},
	},
	bankSlip: {
		basicInfoValue:
			"Amount requested greater than total amount available ({{totalAvailable}})",
		anticipation: "Anticipation required",
		beneficiary: "Enter the beneficiary of the bank slip",
		barCode: "Enter the bank slip barcode",
		invalidBarCode: "Invalid barcode",
		attachment: "Attach the bank slip file",
		dueDate:
			"ATTENTION: The bank slip will only be paid if the remaining balance covers interest and fine costs.",
	},
};
