export const contentProportionalValue = {
	quantity: "Quantity",
	unityMeasurement: "Unity Measurement",
	requiredField: "Required field",
	infoMessageMyTapp:
		"It is not possible to deactivate this function while “Sync with My Tapp” is active.",
	placeholder: {
		quantity: "Insert quantity",
		unitMeasurement: "Select",
	},
	optionsUnitMeasurement: {
		ml: "Milliliters",
		gm: "Grams",
		un: "Unit",
	},
};
