import { consolidated } from "./consolidated";
import { financial } from "./financial";
import { withdraws } from "./withdraws";
import { devolution } from "./devolution";
import { adjustments } from "./adjustments";
import { accounts } from "./accounts";
import { flow } from "./flow";
import { anticipationDrawer } from "./anticipation-drawer";
import { withdrawRequestDrawer } from "./withdraw-request-drawer";
import { errorsValidations } from "./errors-validations";
import { transferForm } from "./transfer-form";
import { withdrawConfirmation } from "./withdraw-confirmation";
import { withdrawRequestDetails } from "#i18n/translations/pt-BR/place/extract/withdraw-request-details";
import { locationNotes } from "./location-notes";
import { debitNotes } from "./debit-notes";

export const extract = {
	title: "Extrato",
	availableToWithdraw: "Disponível para saque",
	owedToZig: "Saldo devido ao ZIG",
	vendorOwned: "Devido a vendors",
	totalBalance: "Saldo total",
	totalBalanceAnticipation: "Saldo total para antecipação",
	valueAvailableToAnticipate: "Valor disponível para antecipação",
	availableToAnticipate: "Disponível para antecipação",
	paidToZig: "Valor a ser pago ao ZIG",
	actionLabelWithdraw: "Solicitar saque, pagamento de boleto ou transferência",
	actionLabelAnticipate: "Solicitar antecipação e pagamento de boleto",
	availableMoreThanOneMonth: "Valor disponível em mais de 30 dias",
	paidToZigMoreThanOneMonth: "Valor a ser pago ao ZIG em mais de 30 dias",
	actionLabelBlocked: "Realizar saque / Pagar boleto",
	bankslipAvailable: "Boleto disponível",
	dueDate_Abrev: "Venc",
	period: "Período",
	excelButton: "Exportar para Excel",
	tabs: {
		consolidated: "Consolidado",
		financial: "Extrato diário",
		withdraws: "Saques, antecipações e boletos",
		devolution: "Recargas expiradas",
		adjustments: "Ajustes",
		accounts: "Contas salvas",
		flow: "Extrato-Fluxo de caixa",
		locationNotes: "Notas de Locação",
		debitNotes: "Notas de débito",
	},
	consolidated,
	financial,
	withdraws,
	devolution,
	adjustments,
	accounts,
	flow,
	anticipationDrawer,
	withdrawRequestDrawer,
	errorsValidations,
	transferForm,
	withdrawConfirmation,
	bankSlipForm: {
		warningBanner: {
			title: "Atenção",
			description:
				"Solicitações realizadas após às 10h30 (horário de Brasília) só serão atendidas no dia seguinte.",
		},
		tryAgain: "Tente novamente",
		request: "Solicitar",
		error: "Não foi possível concluir a solicitação.",
		success: "Pagamento de boleto solicitado com sucesso!",
	},
	hooks: {
		value: "Valor da antecipação",
		accountData: "Informações da conta",
		summary: "Revisão dos dados",
	},
	availableInTwoDays: "Saldo a ser liberado nos próximos 2 dias",
	availableInMoreThanOneMonth: "Valor a ser liberado em mais de 32 dias",
	lastUpdated: "atualizado há {{lastUpdated}}",
	withdrawRequestDetails,
	locationNotes,
	debitNotes,
	relatedPartDataTooltip: "Dados especificados a partir do dia 21/05/2024",
};
