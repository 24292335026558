import * as React from "react";

import s from "./filled-button.scss";

type TButtonThemes = "danger" | "white";

export interface IFilledButtonProps
	extends Omit<React.HTMLProps<HTMLButtonElement>, "type"> {
	theme?: TButtonThemes;
	type?: "button" | "submit" | "reset";
}

import { autobind } from "core-decorators";

@autobind
class FilledButton extends React.Component<IFilledButtonProps, {}> {
	public render(): JSX.Element {
		const { className, theme, ...props } = this.props;

		return (
			<button
				{...props}
				className={s.button + " " + (className || "") + " " + ((theme && s[theme]) || "")}
			>
				{this.props.children}
			</button>
		);
	}
}

export default FilledButton;
