import { TabTagType } from "#resources/helpers/tabs";
import classNames from "classnames";
import * as React from "react";
import s from "./menu-icon.scss";

export type MenuIconName =
	| "account-statement"
	| "audits"
	| "bag-icon"
	| "bar-report"
	| "bookings"
	| "business-inteligence"
	| "business-intelligence-v2"
	| "cash-flow"
	| "change-language"
	| "chart-of-accounts"
	| "chart-of-accounts-v2"
	| "clients"
	| "communication"
	| "contract"
	| "customers"
	| "delivery"
	| "delivery-person-white"
	| "employee"
	| "events"
	| "file-check"
	| "financial-report"
	| "financial"
	| "list-multiple"
	| "logout"
	| "loyalty"
	| "multivendor"
	| "multivendor-v2"
	| "new-storage"
	| "panel"
	| "password-card"
	| "pdv-status"
	| "pos-machine"
	| "products"
	| "promotions"
	| "report"
	| "reserve"
	| "satisfaction-survey"
	| "settings"
	| "stock-inventory"
	| "table-config"
	| "table-report"
	| "tax-data"
	| "tax"
	| "tickets-report"
	| "tickets"
	| "tickets-v2"
	| "bars"
	| "packages"
	| "whats-up"
	| "zig-account"
	| "zig-tag"
	| "crm"
	| "fiscal"
	| "place"
	| "team"
	| "reports"
	| "storage-reports"
	| "receptive"
	| "operation"
	| "pre-charge"
	| "config-adm"
	| "sell-with-no-card"
	| "devices"
	| "cargo-cart"
	| "giftback"
	| "users"
	| "recharge-management";

interface Props extends React.SVGProps<SVGElement> {
	icon: MenuIconName;
	className?: string;
	style?: React.CSSProperties;
	tagType?: TabTagType;
}

export const MenuIcon = ({ className, icon, tagType, ...svgProps }: Props) => {
	const SvgComponent = React.lazy(() => import(`./icons/${icon}.svg`));

	return (
		<React.Suspense fallback="-">
			<div className={classNames(s.icon, className)}>
				<SvgComponent data-icon={icon} {...svgProps} />

				{tagType && (
					<div
						className={classNames({
							[s.notification]: true,
							[s.new]: tagType === "new",
							[s.comingSoon]: tagType === "comingSoon",
						})}
					/>
				)}
			</div>
		</React.Suspense>
	);
};
