import { passwordCardHeader } from "./password-card-header";
import { passwordCardDeleteModal } from "./password-card-delete-modal";

export const passwordCard = {
	columns: {
		cardOwner: "Card owner",
		permissions: "Actions that authorize",
		cardNumber: "Card number",
		timeCreation: "Date/Time Creation",
		cardCreator: "Card creator",
		cardStatus: "Status do cartão",
		authorizedTransactioneration: "Operation/Authorized Transaction",
		value: "Value",
		date: "Date/Hour",
		pdvId: "PDV Id",
		employeeName: "Employee",
		sourceId: "Transaction Id",
	},
	statusEmployeeCard: {
		active: "Active",
		inactive: "Inactive",
		blocked: "Blocked",
	},
	stockHistory: "Stock History",
	blockCard: "Block card",
	passwordCardHeader,
	passwordCardDeleteModal,
	filename: "stock_history",
};
