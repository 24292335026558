import { productCard } from "./product-card";
import { invoicecCardInfo } from "./invoice-card-info";
import { duplicatesCardInfo } from "./duplicates-card-info";
import { paymentCardInfo } from "./payment-card-info";

export const importXml = {
	title: "XML import",
	stockItens: "Stock items",
	productCard,
	invoicecCardInfo,
	duplicatesCardInfo,
	paymentCardInfo,
};
