import {
	ContextOptionsDivider,
	ContextOptionsLabel,
	ContextOptionsMenu,
	ContextOptionsOption,
} from "./context-options";

export const ContextOptions = {
	Menu: ContextOptionsMenu,
	Option: ContextOptionsOption,
	Divider: ContextOptionsDivider,
	Label: ContextOptionsLabel,
};
