import { JSX, SVGAttributes } from "react";

import { Disconnect, Search } from "./illustrations";

import { EmptyType } from "./types";

export const ILLUSTRATIONS: {
	[key in EmptyType]: (props: SVGAttributes<SVGSVGElement>) => JSX.Element;
} = {
	noData: Search,
	noFilters: Disconnect,
};
