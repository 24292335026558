import { action, extendObservable, observable } from "mobx";
import { CreateObservableOptions } from "mobx/lib/api/observable";

export interface IError {
	type: string;
	message: string;
}

/** @deprecated  use fetch model */
export function setObservableData<T extends { [key: string]: any }, O extends T>(
	target: O,
	newData: T,
) {
	if (!(typeof target === "object")) {
		throw new Error("Target should be an object.");
	}
	Object.keys(newData).forEach(newDataKey => {
		(target as any)[newDataKey] = newData[newDataKey];
	});
}

/** @deprecated  use fetch model */
export function extendModel<A extends B, B extends Object>(
	target: A,
	properties: B,
	decorators?: { [K in keyof B]?: Function },
	options?: CreateObservableOptions,
): void {
	const keys = Object.keys(properties) as Array<keyof B>;
	const keysPresent = keys.filter(key => key in target);
	const keysAbsent = keys.filter(key => !(key in target));

	for (const key of keysPresent) {
		(target as any)[key] = properties[key];
	}

	extendObservable(
		target,
		keysAbsent.reduce((acc, key) => {
			acc[key] = properties[key];
			return acc;
		}, {} as any),
		decorators,
		options,
	);
}

/** @deprecated no neeed to use asyncstore anymore, use fetch model */
export class AsyncStore<Hooks extends string> {
	@observable
	public errors: GlobalTypes.PartialRecord<Hooks, IError> = {};

	@observable
	public isLoading: GlobalTypes.PartialRecord<Hooks, boolean> = {};

	@action
	protected async doAsyncAction<T>(
		hook: Hooks,
		doAction: () => Promise<T>,
		onCatch?: (err: Error) => void,
	): Promise<T | undefined> {
		try {
			this.errors[hook] = undefined;
			this.isLoading[hook] = true;

			return await doAction();
		} catch (err) {
			if (err instanceof Error) {
				const thing: IError = err && {
					type: err.name,
					message: err.message,
				};
				this.errors[hook] = thing;

				console.error("doAsyncAction", err);

				if (onCatch) onCatch(err);
			}
		} finally {
			this.isLoading[hook] = false;
		}
	}

	public clearAsyncState(hook: Hooks) {
		this.errors[hook] = undefined;
		this.isLoading[hook] = false;
	}
}
