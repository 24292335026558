import * as React from "react";
import OptionsIcon from "#components/icon/imgs/options.svg";
import s from "./context-options.scss";
import { useOnClickOutside } from "#resources/hooks";
import classNames from "classnames";

export interface IOptionProps {
	label: string;
	action: () => void;
}

export interface IOptionsLabel {
	label: string;
	className?: string;
}

interface IOptionsProps {
	children: React.ReactNode;
	customTrigger?: React.ReactElement;
	classNameList?: string;
}

export function ContextOptionsOption({ label, action }: IOptionProps) {
	return (
		<li className={s.option}>
			<button className={s.optionButton} onClick={action}>
				{label}
			</button>
		</li>
	);
}

export function ContextOptionsLabel({ label, className }: IOptionsLabel) {
	return <li className={classNames(s.optionLabel, className)}>{label}</li>;
}

export function ContextOptionsDivider() {
	return <li className={s.divider} />;
}

export function ContextOptionsMenu({
	children,
	customTrigger,
	classNameList,
}: IOptionsProps) {
	const [isOpened, setIsOpened] = React.useState<boolean>(false);
	const [position, setPosition] = React.useState<string>(s.right);
	const popOver = React.useRef<HTMLDivElement>(null);
	const menuRef = React.useRef<HTMLUListElement>(null);
	useOnClickOutside(popOver, () => setIsOpened(false));

	function checkPosition() {
		const menuEl = menuRef.current;
		if (!menuEl) {
			return false;
		}

		const { x, width } = menuEl.getBoundingClientRect();

		const screenWidth = document.body.getBoundingClientRect().width;
		const menuPosition = x + width;

		setPosition(screenWidth <= menuPosition ? s.left : s.right);
	}

	function onToggle() {
		setPosition(s.right);

		setIsOpened(value => {
			const isOpened = !value;
			if (isOpened) {
				checkPosition();
			}

			return isOpened;
		});
	}

	function handleCustomTriggerClick(originalOnClick?: React.MouseEventHandler) {
		return (event: React.MouseEvent) => {
			if (originalOnClick) {
				originalOnClick(event);
			}
			onToggle();
		};
	}

	return (
		<div className={s.contextOptions} ref={popOver}>
			{customTrigger ? (
				React.cloneElement(customTrigger, {
					onClick: handleCustomTriggerClick(customTrigger.props.onClick),
				})
			) : (
				<button
					className={s.iconContainer}
					onClick={onToggle}
					aria-label="open-context-options"
				>
					<OptionsIcon />
				</button>
			)}

			<ul
				aria-label="context-options-menu"
				aria-expanded={isOpened}
				ref={menuRef}
				className={classNames(
					{
						[s.listContainer]: true,
						[position]: true,
						[s.isOpened]: isOpened,
					},
					classNameList,
				)}
			>
				{React.Children.map(children, child => {
					if (React.isValidElement(child)) {
						return React.cloneElement(child, {
							action: () => {
								child?.props?.action?.();
								onToggle();
							},
						} as any);
					}
					return child;
				})}
			</ul>
		</div>
	);
}
