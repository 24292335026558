import { employees } from "./employees";
import { financial } from "./financial";
import { clients } from "./clients";

export const businessIntelligence = {
	header: {
		financial: "Financeiro",
		employees: "Funcionários",
		clients: "Clientes",
		period: "Período",
	},
	chartCards: {
		filters: {
			consumption: "Consumo",
			ppu: "PPU",
		},
	},
	employees,
	financial,
	clients,
};
