export const combo = {
	placeholder: "Filter combos",
	addComboButton: "Add combo",
	activateMultipleCombosButton: "Activate multiple combos",
	disableMultipleCombosButton: "Disable multiple combos",
	activatedMultipleCombos: "Activated combos",
	activatedSingleCombo: "Activated combo",
	deactivatedMultipleCombos: "Deactivated combos",
	deactivatedSingleCombo: "Deactivated combo",
	table: {
		name: "Combo",
		warning:
			"Heads up! To make this combo available for sale, configure its price on the side",
		fiscalInfo:
			"This combo has no tax data. Therefore, the tax data of your products will be considered",
		category: "Category",
		description: "Description",
		products: "Products",
		price: "Price",
		active: "Active",
	},
	addComboModal: {
		errors: {
			obrigatoryField: "This field is mandatory",
			invalidValue: "Invalid value",
			products: "The combo must be associated with at least one product",
		},
		name: "Name",
		category: "Category",
		price: "Price",
		description: "Description",
		picture: "Photograph:",
		products: "Products",
		includeFiscal: "Include tax data?",
		include: "Include",
		fiscalData: "Tax data",
		fiscalFields: {
			code: "SKU",
			ncm: "NCM",
			cest: "CEST",
			fiscalGroup: "Tax group",
			fiscalProfile: "Tax profile",
		},
		edit: "To edit",
		editCombo: "Edit combo",
		add: " Add",
		addCombo: "Add combo",
		cancel: "Cancel",
	},
	deleteCombo: 'Do you want to delete the product "{{name}}"?',
	columns: {
		name: "Combo",
		category: "Category",
		description: "Description",
		products: "Products",
		price: "Price",
		active: "Active",
	},
};
