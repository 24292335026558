export const emited = {
	details: "Detalhes de emissões",
	fiscalGroup: "Emissões por grupo fiscal",
	fiscalProfile: "Emissões por perfil fiscal",
	byBars: "Emissões por Bar",
	byEmployee: "Emissões por funcionário",
	foreignDescription:
		"Este relatório apresenta um detalhamento agregado do valor bruto emitido e do valor líquido emitido. Exibe um agrupamento por perfil fiscal, grupo fiscal de produto, bar, funcionário e método de pagamento.",
	sheets: {
		details: {
			columns: {
				description: "Descrição",
				financialValue: "Valor financeiro",
				title: "Título",
				unitValue: "Valor unidade",
				name: "Perfil fiscal",
				value: "Valor Emitido",
				valueForeign: "Valor Emitido Bruto",
				netValueForeign: "Valor Emitido Líquido",
			},
			filename: "detalhes_de_emissoes",
			title: "Detalhes de Emissões",
		},
		fiscalGroup: {
			columns: {
				name: "Grupo de produtos",
				value: "Valor",
				valueForeign: "Valor Emitido Bruto",
				netValueForeign: "Valor Emitido Líquido",
			},
			filename: "emissoes_por_grupo_fiscal",
			title: "Emissões por grupo fiscal",
		},
		byBars: {
			title: "Emissões por bar",
			filename: "emissoes_por_bar",
			columns: {
				name: "Bar",
				value: "Valor",
				valueForeign: "Valor Emitido Bruto",
				netValueForeign: "Valor Emitido Líquido",
			},
		},
		byEmployee: {
			title: "Emissões por funcionário",
			filename: "Emissões_por_funcionário",
			columns: {
				name: "Funcionário",
				value: "Valor",
				valueForeign: "Valor Emitido Bruto",
				netValueForeign: "Valor Emitido Líquido",
			},
		},
		fiscalProfile: {
			columns: {
				name: "Perfil fiscal",
				value: "Valor Emitido",
				valueForeign: "Valor Emitido Bruto",
				netValueForeign: "Valor Emitido Líquido",
			},
			filename: "emissoes_por_perfil_fiscal",
			title: "Emissões por perfil fiscal",
		},
	},
	title: "Emissões",
	subtitle:
		"Esse relatório leva em consideração apenas as notas emitidas sobre o regime NFC-e",
	table: {
		title: "Título",
		value: "Valor",
		valueForeign: "Valor Emitido Bruto",
		netValueForeign: "Valor Emitido Líquido",
		fiscalGroups: {
			columns: {
				name: "Grupo fiscal",
				value: "Valor",
				valueForeign: "Valor Emitido Bruto",
				netValueForeign: "Valor Emitido Líquido",
			},
		},
		fiscalProfiles: {
			columns: {
				name: "Perfil fiscal",
				value: "Valor Emitido",
				valueForeign: "Valor Emitido Bruto",
				netValueForeign: "Valor Emitido Líquido",
			},
		},
		byBars: {
			columns: {
				name: "Bar",
				value: "Valor Emitido",
				valueForeign: "Valor Emitido Bruto",
				netValueForeign: "Valor Emitido Líquido",
			},
		},
		byEmployee: {
			columns: {
				name: "Funcionário",
				value: "Valor Emitido",
				valueForeign: "Valor Emitido Bruto",
				netValueForeign: "Valor Emitido Líquido",
			},
		},
	},
};
