import * as React from "react";
import { Modal } from "antd";
import { useTranslation } from "react-i18next";
import WarningIcon from "#assets/svg/warning-triangule.svg";
import { Button, Input, Typography, Wrapper } from "#components/primitive";
import s from "../pending-contracts.scss";

interface IModalCodeConfirmationProps {
	visible: boolean;
	loading: boolean;
	onClose: () => void;
	onOk: () => void;
	onChangeCode: (code: string) => void;
	code: string;
}

export function ModalCodeConfirmation({
	visible,
	loading,
	onClose,
	onOk,
	onChangeCode,
	code,
}: IModalCodeConfirmationProps) {
	const { t } = useTranslation("components", {
		keyPrefix: "pendingContracts.modalCodeConfirmation",
	});
	return (
		<Modal
			title={
				<div className={s.header}>
					<WarningIcon />
					<Typography type="heading" variant="v5" className={s.title}>
						{t("verifyCode")}
					</Typography>
				</div>
			}
			open={visible}
			onCancel={onClose}
			maskClosable={false}
			className={s.modal}
			footer={false}
			destroyOnClose={true}
		>
			<>
				<Typography type="paragraph" variant="v1">
					{t("description")}
				</Typography>
				<Wrapper label={t("verifyCode")}>
					<Input
						value={code}
						onChange={e => onChangeCode(e.target.value)}
						placeholder={t("codePlaceholder")}
					/>
				</Wrapper>
				<div className={s.actions}>
					<Button
						variant="primary"
						onClick={onOk}
						disabled={loading || !code}
						loading={loading}
					>
						{t("confirmSign")}
					</Button>
				</div>
			</>
		</Modal>
	);
}

export default ModalCodeConfirmation;
