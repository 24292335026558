import { useMemo } from "react";

import { SimplifiedPlace, SummaryEvent } from "#resources/api/enterprise-generated";
import { useModularization } from "./use-modularization";
import { useGetActiveEventsBySimplifiedPlaces } from "#resources/hooks/integrations/org/query";
import { useSimplifiedPlaces } from "#resources/hooks/integrations/place/query";

export type MinifiedPlaceEventType = {
	place: SimplifiedPlace;
	event: SummaryEvent;
};

export const useActiveEvents = () => {
	const { showMenuV2 } = useModularization();

	const { data: simplifiedPlaces } = useSimplifiedPlaces({
		enabled: showMenuV2,
	});

	const placesWithRunningEvents = (simplifiedPlaces ?? []).filter(
		item => item.hasOpenedEvent && item.lastEventBeginDate,
	);

	const queryResults = useGetActiveEventsBySimplifiedPlaces(
		{ simplifiedPlaces: placesWithRunningEvents },
		{ enabled: showMenuV2 },
		(event, place) => ({
			event,
			place,
		}),
	);

	const activeEvents = useMemo(() => {
		if (!queryResults?.length) {
			return [];
		}

		const runningEvents = queryResults
			.filter(
				result =>
					!result.isLoading &&
					!result.error &&
					result.data &&
					result.data.event.events.length,
			)
			.map(result => {
				const dataResult = result.data;
				const { place, event } = dataResult!;

				const parsedData = event?.events
					.filter(e => e.status === "Running")
					.map(e => {
						return {
							event: e,
							place: place,
						};
					});

				return parsedData!;
			});

		return runningEvents.flat();
	}, [queryResults]);

	return { activeEvents };
};
