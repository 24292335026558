export const fiscalSettings = {
	form: {
		title: "Dados fiscais de Couvert '{{name}}'",
		subtitle: "Atualizar dados fiscais de '{{name}}'",
		code: "SKU",
		ncm: "NCM",
		cest: "CEST",
		fiscalProfile: "Perfil fiscal",
		fiscalGroup: "Grupo fiscal",
		optionalFields:
			"Estes campos são opcionais, mas são necessários para a emissão de notas fiscais",
		onlyNumbers: "Apenas numeros",
		cst: "CST",
		quantitySold: "Quantidade vendida",
		aliquota: "Alíquota",
		calculo: "Base de Cálculo",
		invoice: "Fatura",
		service: "Serviço",
		vat: "Value-added tax",
		valueAdded: "Value-added",
		type: "Tipo",
		name: "Nome",
		cfop: "CFOP",
		csosn: "CSOSN",
		origin: "Origem",
		reduceCalculo: "Redução na base de cálculo",
		servideCode: "Código de serviço",
		tributacao: "Código de tributação do município",
		generalFields: "Campos gerais",
		icms: "ICMS",
		pis: "PIS",
		cofins: "COFINS",
		requiredField: "Esse campo é obrigatorio",
		createProfile: "Criar perfil fiscal",
		updateProfile: "Atualizar perfil fiscal",
		generalData: "Dados Gerais",
		companyData: "Dados da Empresa",
		cnpj: "CNPJ",
		inscricaoEstadual: "Inscrição Estadual",
		fantasyName: "Nome Fantasia",
		socialName: "Razão Social",
		regimeTributario: "Regime Tributário",
		cityCode: "Codigo do Municipio",
		municipalRegistration: "Inscrição Municipal",
		address: "Enredeço",
		zipCode: "CEP",
		street: "Logradouro",
		number: "Número",
		adjunct: "Complemento",
		neighborhood: "Bairro",
		city: "Município",
		state: "Estado",
		contact: "Contato",
		email: "Email",
		phone: "Telefone",
		fiscal: "Fiscal",
		fiscalMode: "Modo fiscal",
		sat: "SAT-e",
		satNumber: "Número de Série SAT",
		nfce: "NFC-e",
		certificate: "Certificado",
		certificatePassword: "Número do certificado",
		expirationDate: "Data de expiração",
		cscHomologation: "CSC NFC-e Homologação",
		idHomologation: "Id NFC-e Homologação",
		cscProduction: "CSC NFC-e Produção",
		idProduction: "Id NFC-e Produção",
		zipCodeValidation: "CEP precisa ter 8 números",
	},
	emptyData: "Não existem dados a serem mostrados.",
	fiscalDataPlace: "Dados fiscais de Couvert do Local",
	unavailable: "Indisponível",
	fiscalGroupProduct: "Grupos fiscais de produtos",
	addNewGroup: "Adicionar novo grupo",
	addNewProfile: "Adicionar novo perfil",
	delete: "Tem certeza que quer deletar?",
	expireIn: "Expira em",
	generalSettings: "Configurações Fiscais",
	searchFiscalProfile: "Pesquisar perfis fiscais",
	searchFiscalGroup: "Pesquisar grupos fiscais",
};
