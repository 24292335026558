export const vendorReport = {
	title: "Vendor delivery report",
	sheet: {
		filename: "vendor-delivery-report",
		title: "Sales delivery by vendor",
		columns: {
			vendor: "Vendor",
			value: "Value",
			tip: "Tip",
			deliveryFee: "Delivery Fee",
		},
	},
	columns: {
		vendor: "Vendor",
		deliveryFee: "Delivery Fee",
		tip: "Tip",
		value: "Value",
	},
};
