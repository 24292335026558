export const general = {
	fieldsAddress: {
		cep: {
			label: "CEP",
			placeholder: "00000-000",
		},
		complement: {
			label: "Complement",
			placeholder: "Insert complement",
		},
		county: {
			label: "County",
			placeholder: "Insert county",
		},
		neighborhood: {
			label: "Neighborhood",
			placeholder: "Insert neighborhood",
		},
		number: {
			label: "Number",
			placeholder: "Insert number",
		},
		publicplace: {
			label: "Public place",
			placeholder: "Insert public place",
		},
		uf: {
			label: "UF",
			placeholder: "Select state",
		},
	},
	fieldsGeneral: {
		email: {
			label: "Email",
			placeholder: "Insert email",
		},
		fiscalMode: {
			label: "Tax mode",
			placeholder: "Select tax mode",
		},
		enableManifestation: {
			label: "Enable search for NFs issued for this CNPJ to import on stock",
		},
		name: {
			label: "Name",
			placeholder: "Profile name",
		},
		phone: {
			label: "Phone",
			placeholder: "Insert Phone",
			error: "Invalid Telephone Number: must contain 10 to 11 digits with area code",
			errorIvaVendus: "Invalid Phone Number: must contain 9 digits",
			errorIvaFactura: "Invalid Phone Number: must contain 9 to 12 digits",
		},
	},
	optionsFiscalMode: {
		ivaFactura: "IVA - Factura",
		ivaVendus: "IVA - Vendus",
		nfce: "NFC-e",
		sat: "SAT",
	},
	optionsEnableManifestation: {
		allow: "Yes, allow searching for invoices issued for this CNPJ",
		notAllow: "do not allow searching for invoices",
	},
};
