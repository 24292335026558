export const config = {
	title: "Delivery store settings",
	updatingData: "Updating data",
	savedData: "Saved Data",
	newImageSaved: "New Image Saved",
	form: {
		initialInfo: "initial information",
		addressInfo: "address information",
		deliveryInfo: "delivery information",
		webpageInfo: "viewing information",
		paymentMethods: "payment methods",
		additionalFields: "additional fields",
		qrCodesGenerate: "Generate QR Codes",
	},
	subForms: {
		additionalFields: {
			empty: "There are no registered fields",
			add: "Add new field",
			label: "Caption",
			slug: "Slug",
			required: "Required",
			type: {
				label: "Type",
				text: "Alphanumeric",
				number: "Number",
				date: "Date",
				select: {
					label: "Multiple choice",
					a: "Option A",
					b: "Option B",
					c: "Option C",
				},
				remove: "Remove",
				options: {
					label: "Options",
					placeholder: "Add a new choice",
				},
			},
			save: "Save additional fields",
		},
		address: {
			formattedAddress: "Address",
			complement: "Add-on",
			postalCode: "ZIP",
			streetName: "Street Name",
			streetNumber: "Street number",
			neighborhood: "Neighborhood",
			city: "City",
			state: "State",
			country: "Country",
			save: "Save address information",
		},
		delivery: {
			attendanceRadius: {
				label: "Service radius",
				sufix: "km",
			},
			deliveryFeePerKm: "Delivery fee per Km",
			fixedDeliveryFee: "Minimum delivery fee",
			avgWaitTime: {
				label: "Average delivery time",
				sufix: "Minutes",
			},
			save: "Save delivery information",
		},
		initialInfo: {
			name: "Name",
			isManualMenu: "Manual menu activation",
			isOrganization: "Organization Mode",
			save: "Save initial information",
		},
		payments: {
			methods: {
				creditCard: "Credit Card",
				debitCard: "Debit Card",
				cash: "Money",
				creditCardOnline: "Credit Card Online",
				others: "Other payment methods",
				voucherInPlace: "Voucher",
				mastercard: "Master-card",
				visa: "Visa",
				amex: "Amex",
				elo: "Elo",
				hipercard: "Hiper-card",
				diners: "Diners",
				ticket: "Ticket",
				alelo: "Alelo",
				vr: "VR",
				ben: "Ben",
				sodexo: "Sodexo",
				pix: "Pix",
			},
			save: "Save payment methods",
		},
		qrCodeGenerator: {
			columns: {
				url: "URL",
				params: "Parameter",
				value: "Parameter value",
			},
			warningNotification: "Please fill in all fields.",
			urlRequired: "URL is required",
			paramsRequired: "Parameter is required",
			valueRequired: "Parameter value is required",
			new: "New QR code",
			download: "Download QR codes",
			import: "Import QR codes",
			chooseSheet: "Choose a sheet",
			sheetFilename: "QR codes",
		},
		webPage: {
			image: "Image",
			slug: "Slug",
			primaryColor: "Primary Color",
			secondaryColor: "Secondary Color",
			save: "Save View Information",
		},
	},
};
