import * as React from "react";
import s from "./menu.scss";
import classnames from "classnames";
import LogoMinified from "./logo-minified.svg";
import Logo from "./logo.svg";
import MenuPatternImage from "./menu-background-pattern.svg";
import { Menu as MenuV2 } from "./v2";

export type TagTypeStyle = "comingSoon" | "new" | "pending";

interface IMenuProps extends React.HTMLAttributes<HTMLDivElement> {
	LogoContainer?: React.ComponentType;
	isForceOpen?: boolean;
	// TODO: remove prop: v2 when migrate all of menus to v2
	v2?: boolean;
	onChangeIsOpenSandwichMenu?: (isOpen: boolean) => void;
}

interface IMenuItemProps {
	active?: boolean;
	children: React.ReactNode;
	tagType?: TagTypeStyle;
}

interface IMenuTagProps extends React.HTMLAttributes<HTMLDivElement> {
	type: TagTypeStyle;
}

interface IMenuIconContainerProps extends React.HTMLAttributes<HTMLDivElement> {
	children: React.ReactNode;
}

export function MenuItem({ active, tagType, children }: IMenuItemProps) {
	return (
		<li
			className={classnames({
				[s.menuItem]: true,
				[s.active]: active,
				[s[tagType ?? ""]]: tagType,
			})}
		>
			{children}
		</li>
	);
}

export function MenuTag({ type, children }: IMenuTagProps) {
	return <span className={classnames(s.menuTag, s[type])}>{children}</span>;
}

export function MenuDivider() {
	return <li className={s.divider}></li>;
}

export function MenuIconContainer({
	className,
	children,
	...props
}: IMenuIconContainerProps) {
	return (
		<div className={classnames("icon-container", className)} {...props}>
			{children}
		</div>
	);
}

export function Menu(props: IMenuProps) {
	const { LogoContainer = React.Fragment, isForceOpen, children, title, v2 } = props;

	// TODO: Remove this conditional and replace this Menu component for /menu-v2 content
	if (v2) {
		return <MenuV2 {...props} />;
	}

	return (
		<nav
			className={classnames({
				[s.navContainer]: true,
				[s.forceOpen]: isForceOpen,
				[s.v2]: v2,
			})}
		>
			<div className={s.patternContainer}>
				<MenuPatternImage className={s.pattern} />
			</div>
			<LogoContainer>
				<div
					className={classnames(s.logoContainer, {
						[s.v2]: v2,
					})}
				>
					<div className={s.logoExpanded}>
						<Logo />
					</div>
					<div className={s.logoMinified}>
						<LogoMinified />
					</div>
				</div>
			</LogoContainer>

			{title && <span className={s.title}>{title}</span>}

			<ul className={s.navMenu}>{children}</ul>
		</nav>
	);
}
