export const employees = {
	filters: {
		sells: {
			label: "Sales",
			shortName: "Sales",
			quantity: "Quantity of orders",
			total: "Total value of orders",
			average: "Average per employee",
			best: "Best performance",
			lower: "Lower performance",
		},
		financialOperations: {
			label: "Financial receipts",
			shortName: "Receipts",
			quantity: "Number of operations",
			total: "Products sold",
			average: "Average per employee",
			best: "Best performance",
			lower: "Lower performance",
		},
		tips: {
			label: "Tips",
			shortName: "Tips",
			total: "Total tip",
			percent: "% on sale",
			average: "Average per employee",
			higher: "Highest value",
			lower: "Lower value",
		},
		refunds: {
			label: "Chargebacks",
			shortName: "Chargebacks",
			quantity: "Number of chargebacks",
			total: "Total value of chargebacks",
		},
		sellRefunds: {
			label: "Who caused the chargeback",
			shortName: "Chargeback",
		},
		checkins: {
			label: "Check-ins",
			shortName: "Check-ins",
			quantity: "Total number of Check-ins",
			average: "Average per employee",
			higher: "Employee with the most Check-ins",
			lower: "Employee with fewer Check-ins",
		},
	},
	userCard: {
		employeeName: "employee name",
		role: "Role",
		ofSales: "of sales",
	},
};
