export const eventConference = {
	titlePage: "Event Conference",
	toThe: "at",
	infoPage:
		"When processing an event, it sends the billing information to accounts payable, accounts receivable, cash flow, and DRE.",
	viewDataConference: "View conference",
	viewDataConferenceDetails: "Conference details",
	dataActions: {
		viewDetails: "View details",
		eventProcess: "Process event",
	},
	tableColumns: {
		date: "Date",
		eventName: "Event name",
		totalIncome: "Gross income",
		status: "Status",
		actions: "Actions",
		dateAndTimeProccess: "Date and time of processing",
		checkedBy: "Processor",
	},
	textPerStatus: {
		loading: "Please wait, we are processing the conferences",
		success: "All conferences have been successfully processed",
		conclude: "Completed",
	},
	titleProcess: {
		processing: "Processing",
		processed: "Processed",
		processConferences: "Process conferences",
	},
	filter: {
		searchName: {
			title: "Search for events",
			label: "Enter the event name",
		},
		searchDate: {
			title: "Period",
		},
		searchStatus: {
			title: "Show status",
		},
	},
	buttonProcessMultiple: "Process events",
};
