export const categories = {
	label: "Categorias",
	search: {
		label: "Pesquisar",
		placeholder: "Categoria, subcategoria ou produto",
	},
	add: "Nova categoria",
	categoryModal: {
		create: "Criar categoria",
		createSubcategory: "Criar subcategoria",
		edit: "Editar categoria",
		inputs: {
			image: "Foto",
			name: {
				label: "Nome",
				placeholder: "Digite o nome",
			},
			description: {
				label: "Descrição",
				placeholder: "Descreva a categoria",
			},
			priorityLevel: {
				label: "Nível de prioridade",
				errorMessage: "Insira um nível de prioridade válido (ex: 1, 2, 3...)",
			},
			prefix: "Nível",
			chartAccount: {
				label: "Plano de Contas",
				placeholder: "Selecione o plano de contas",
			},
			errors: {
				requiredName: "Nome é obrigatório",
				requiredPriority: "Nível de prioridade é obrigatório",
				invalidPriority: "Nível de prioridade inválido",
			},
		},
	},
	columns: {
		product: "Produto",
		type: "Tipo",
		actions: "Ações",
		combo: "Combo",
	},
	priorityLevel: "Nível de prioridade {{level}}",
	addSubcategory: "Adicionar subcategoria em {{category}}",
	addProduct: "Adicionar Produto em {{category}}",
	deleteCategory: "Deseja deletar a categoria? Essa operação não pode ser desfeita.",
	deleteProduct: "Deseja deletar esse produto? Essa operação não pode ser desfeita.",
	productModal: {
		edit: "Editar produto",
		create: "Criar produto",
		inputs: {
			name: {
				label: "Nome",
				emptyError: "Nome do produto não preenchido",
				noStringError: "Use apenas caracteres válidos",
				noCategory: "Nome da categoria não preenchido",
				noPriority: "Nível de prioridade inválido",
			},
			type: {
				label: "Tipo do produto",
				placeholder: "Selecione um tipo de produto",
				error: "Selecione um tipo de produto",
			},
			image: "Imagem",
			hasAlcohol: "Contém Álcool",
		},
	},
	subcategory: "subcategoria",
	subcategories: "Subcategorias",
	description: "Descrição",
	chartAccount: "Plano de Contas",
};
