import { TotalByClientLocale } from "#i18n/translations/pt-BR/event/table-reports/total-by-client";

export const totalByClient: TotalByClientLocale = {
	label: "Total by client",
	title: "Total sales by client",
	table: {
		title: "Table consumption",
		columns: {
			name: "NAME",
			document: "DOCUMENT",
			openedAt: "OPENING",
			closedAt: "CLOSING",
			payment: "PAYMENT",
			serviceValue: "SERVICE VALUE",
			discount: "DISCOUNTS",
			refundValue: "REFUNDS",
			totalValue: "TOTAL",
			products: {
				imageUrl: "PHOTO",
				sku: "SKU",
				name: "PRODUCT NAME",
				tableName: "TABLE",
				payment: "PAYMENT",
				quantity: "AMOUNT",
				unitValue: "UNIT VALUE",
				totalValue: "TOTAL VALUE",
			},
		},
	},
};
