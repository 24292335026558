import { details } from "./details";

export const clients = {
	title: "Clientes",
	atEventNameTitle: "em {{eventName}}",
	thisEventTitle: "neste Evento",
	fileTitle: "Clientes no evento {{eventName}} {{eventDate}}",
	filename: "clientes_no_evento_{{eventName}}_{{eventDate}}",
	searchPlaceholder: "Busque por nome, cpf ou telefone",
	period: "Período",
	until: "Até",
	onlyClientsCheckbox: "Clientes com notas não emitidas",
	issueAllInvoicesButton: "Emitir todas as notas",
	table: {
		name: "Nome",
		document: "CPF",
		phone: "Telefone",
		checkInTime: "Horário de entrada",
		checkOutTime: "Horário de saída",
		housePrePaidTransactionsValue: "Pré-pago na casa",
		housePostPaidTransactionsValue: "Pós-pago na casa",
		appTransactionsValue: "Pago pelo app",
		bonusTransactionsValue: "Pago com bônus",
		total: "Total",
		noDataText: "Sem dados a serem exibidos.",
	},
	details,
	issueLoading: "Emitindo notas...",
	issuedInvoice: "nota foi emitida com sucesso",
	issuedInvoices: "notas foram emitidas com sucesso",
	errorCountIssuedInvoice: "tipo de erro foi encontrado ao emitir notas",
	errorCountIssuedInvoices: "tipos de erro foram encontrados ao emitir notas",
};
