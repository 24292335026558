export const tableProductsSold = {
	label: "Products sold by table",
	title: "Products sold by table",
	filters: {
		table: "Table",
		employeeName: "Employee",
		payment: "Payment",
		product: "Product",
		tablePlaceholder: "Select a table",
		employeeNamePlaceholder: "All",
		status: "Status",
		paymentPlaceholder: "All",
		productPlaceholder: "All",
	},
	table: {
		title: "Table consumption",
		columns: {
			tableName: "TABLE",
			employeeName: "EMPLOYEE",
			numberOfSeats: "PEOPLE",
			openedAt: "OPENING",
			closedAt: "CLOSING",
			discount: "DISCOUNTS",
			refundValue: "REFUNDS",
			totalValue: "TOTAL",
			permanence: "PERMANENCE",
			tip: "TIP",
			productsValue: "PRODUCTS VALUE",
			products: {
				imageUrl: "PHOTO",
				sku: "SKU",
				name: "PRODUCT NAME",
				quantity: "QUANTITY",
				unitPrice: "UNIT PRICE",
				subTotal: "SUBTOTAL",
				discount: "DISCOUNTS",
				total: "TOTAL VALUE",
			},
		},
	},
};
