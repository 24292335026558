export const promoter = {
	title: "Promoters",
	promoter: {
		menu: {
			deactivate: "Deactivate",
			activate: "Activate",
			edit: "Edit",
		},
		table: {
			name: "Name",
			email: "Email",
			phone: "Phone",
			accessLevel: "Access Level",
			active: "Active",
			pending: "Pending",
			inactive: "Inactive",
		},
		content: {
			titleAddPromoter: "Add promoter",
			titleEditPromoter: "Edit promoter",
		},
		header: {
			title: "Promoters",
			text: "Promoter's area:",
			promoter: "promoter",
			promoters: "promoters",
			buttonAddPromoter: "Add promoter",
			buttonCopyPromoter: "Copy",
			buttonCopyPromoterSuccess: "Link copied",
			buttonSearch: "Search",
			active: "Active",
			inactive: "Inactive",
			pending: "Pending",
		},
		addPromoter: {
			subtitle: "Send invitation to:",
			textAddPromoter:
				"Enter the email address of the promoter you wish to allow access to guest lists.",
			messageAddPromoter:
				"To add more than one promoter, just separate the emails with a comma (,)",
			buttonInvite: "Send invite",
			successTittle: "Invitation sent successfully!",
			successText: "The invitation has been sent to the registered e-mails",
			successButton: "Add promoter",
		},
		editPromoter: {
			textStatusPromoter: "Status",
			textLevelPromoter: "Access level",
			name: "Name",
			email: "Email",
			phone: "Phone",
			messageLevelPromoter: "Can view events, add and edit guests in lists",
			messageToolTipPromoter:
				"The status defines whether the promoter has access to your lists",
			pendingStatus: "Pending",
			buttonInvite: "resend invitation",
			buttonInviteSave: "Save edit",
			successTittle: "Invitation sent successfully!",
			successText: "The invitation has been sent to the registered e-mails",
			successButton: "Add promoter",
			active: "Active",
			inactive: "Inactive",
		},
		store: {
			messageActive: "User activated successfully",
			messageDeactive: "User deactivated successfully",
			messageSuccess: "Invitation resent successfully",
			messageEdit: "Promoter data changed successfully",
		},
	},
};
