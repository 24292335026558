import * as ReactGA from "react-ga";

export function sendPageview(modalPath: string = "") {
	const page =
		window.location.pathname.replace(/(\/(place|event))\/[^\/]+/g, "$1") +
		(modalPath ? `/${modalPath}` : "") +
		window.location.search;

	ReactGA.set({ page });
	ReactGA.pageview(page);
}
