import { steps } from "./steps";
import { components } from "./components";

export const fiscalProfileForm = {
	components,
	steps,
	titleSteps: {
		company: "Dados da empresa",
		fiscal: "Dados fiscais",
		general: "Dados gerais",
		tip: "Configuração de gorjeta",
	},
	navigateBackLabel: "Voltar para perfis fiscais",
};
