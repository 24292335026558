export const productsTab = {
	label: "Product Base Registration",
	description:
		"List of products registered in the organization and their basic information.",
	header: {
		counter: "{{amount}} Products",
		search: "Search",
		add: "Add product",
		inventory: "Change inventory",
		export: "Export products",
		addPlaces: "Add Places",
		editPlaces: "Edit Places",
		deleteProduct: "Delete (1) product",
		deleteMultipleProducts: "Delete ({{products}}) products",
	},
	addEditProductModal: {
		add: "Add product",
		update: "Update product",
		edit: "Edit product",
		undefined: "Undefined",
		unavailable: "Unavailable",
		form: {
			name: {
				label: "Product's name",
				placeholder: "Enter a name for the product",
				error: "Use only valid characters",
			},
			type: {
				label: "Type",
				placeholder: "Select a product type",
			},
			category: {
				label: "Category",
				info: "You will be creating a new category",
				placeholder: "Select or create a category",
				error: "Choose or create a category",
			},
			code: {
				label: "SKU",
				placeholder: "Enter a SKU",
				tooltip: "If not filled in, the code will be generated automatically",
			},
			ncm: {
				label: "NCM",
				placeholder: "Enter an NCM",
				error: "NCM must be 8 digits",
			},
			photo: "Image",
			hasAlcohol: "Contains alcohol?",
			error: "This field is required",
		},
	},
	inventoryUpdate: {
		label: "Change inventory",
		import: "Import spreadsheet",
		download: "Download spreadsheet",
		columns: {
			id: "Pproduct id",
			name: "Name",
			category: "Category",
			type: "Product type",
			hasAlcohol: "Does it contain alcohol?",
			fiscalCode: "SKU",
			fiscalNcm: "Fiscal - NCM",
		},
	},
	exportProducts: {
		products: "Products",
		warningNotification: "Unmodified Products",
		columns: {
			id: "Product id",
			title: "Name",
			category: "Category",
			places: "Places",
			fiscalNcm: "NCM",
			fiscalCode: "SKU",
			kind: "Product type",
			type: "Type",
			hasAlcohol: "Does it contain alcohol?",
			description: "Description",
		},
	},
	productForm: {
		alcoholLabel: "Contains Alcohol?",
		categoryLabel: "Category",
		categoryPlaceholder: "Enter the product category",
		createProduct: "Create Inventory Product",
		editProduct: "Edit Inventory Product",
		fiscalCodelabel: "SKU",
		fiscalCodePlacehodler: "Enter the SKU code of the product",
		ncmLabel: "NCM",
		ncmPlaceholder: "Enter the NCM code of the product",
		photolabel: "Photo",
		productNameLabel: "Product Name",
		productNamePlaceholder: "Enter the product name",
		productTypelabel: "Type",
		productTypePlaceholder: "Select the product type",
		placesLabel: "Locations",
		placesPlaceholder: "Select the product locations",
		price: "Price",
		priceTooltip:
			"Changing the price in this field alters the product price for all linked places",
		editPrice: "Change product",
		saveProduct: "Save Product",
		saveEditProduct: "Save Changes",
		confirmProductPrice: {
			goBack: "Back to editing",
			confirm: "Confirm change",
			title: "Confirm adjustment",
			description: {
				title: "Do you want to confirm the price change?",
				subtitle:
					"The linked places will have the new value of <b>{{productValue}}</b> for the product <b>{{productName}}</b>.",
			},
		},
	},
	types: {
		product: "Product",
		supply: "Supply",
	},
	rows: {
		products: "Products",
		type: "Type",
		places: "Places",
		noProducts: "No products",
		fiscalCode: "SKU",
	},
	selectPlaces: {
		label: "Select places",
		search: "Search places",
	},
	removeProduct: {
		label: "Remove product",
		title: "Do you really want to remove the product?",
	},
	modalDelete: {
		title: "Delete product",
		description:
			"By confirming the action, you permanently delete the product. Do you want to continue?",
	},
	modalDeleteMultipleProducts: {
		title: "Remove products",
		description:
			"When removing selected products they will be deleted from all places, and it will not be possible to reverse this action.",
		confirm: "Do you want to delete it anyway?",
	},
	link: "Link",
	links: "Links",
	productPlacesModal: {
		title: "Product Links",
		description: "List of locations where the product is present",
		seePlace: "See place",
	},
};
