export const giftCard = {
	pageTitle: "Gift Card",
	pageSubtitle: "Configure your Gift Card for campaigns.",
	modalTitle: "Gift Cards",
	modalDescription:
		"Campaign created successfully. Now you can export the file to redeem the coupons.",
	manageGiftCardModal: {
		title: {
			active: "Activate campaign",
			inactive: "Inactivate campaign",
		},
		description: {
			active: "Activating campaign ({{campaign}}), continue?",
			inactive: "Inactivating campaign ({{campaign}}), continue?",
		},
	},
	fields: {
		campaignName: "Campaign Name",
		campaignPlaces: "Place",
		campaignCreatedAt: "Date of creation",
		numberOfGifts: "Qty of coupons",
		giftCardValue: "Unit value",
		giftCardExpirationDate: "Expiration Date",
		giftCardRedeemed: "rescued",
		validation: {
			campaignNameAlreadyExists: "This campaign name already exists",
		},
	},
	form: {
		validations: {
			required: "Required field",
		},
		messages: {
			successInCreating: "Campaign successfully created",
			failedToCreate: "Failed to create campaign",
			successInCreatingSubtitle:
				"Access all campaign codes by downloading the spreadsheet below:",
			campaignEdited: "Campaign updated",
			campaignEditedFailure: "Failed to update campaign",
		},
	},
	actions: {
		label: "Actions",
		exportCupons: "Export Coupons",
		track: "Track",
		createCampaign: "Create Campaign",
		inactivate: "Deactivate",
		activate: "Activate",
	},
	status: {
		label: "Status",
		active: "Active",
		inactive: "Inactive",
		expired: "Expired",
	},
	excel: {
		title: "Gift Cards",
		filename: "gift-cards",
		columns: {
			giftCardcodes: "Codes",
			giftCardValue: "Unit value",
		},
	},
};
