import classNames from "classnames";
import React from "react";

import s from "./expand-icon.module.scss";
import Icon from "#components/icon";

export type ExpandIconProps = {
	expanded: boolean;
	onExpand: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
};

export const ExpandIcon = ({ expanded, onExpand }: ExpandIconProps) => {
	return (
		<button
			className={classNames(s.collapseButton, {
				[s.expandedRowIcon]: expanded,
			})}
			onClick={onExpand}
		>
			<Icon width={24} height={24} icon={"chevron-down"} />
		</button>
	);
};
