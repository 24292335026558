export const contracts = {
	solicitation: {
		columns: {
			id: "Ticket Id",
			requestBy: "Requester's Name",
			requestDate: "Request Date",
			requestReason: "Request Reason",
			requestType: "Request Type",
			returnDate: "Return Date",
			status: "Status",
		},
		filter: {
			periodTimeLabel: "Request Date",
			statusLabel: "Status",
			statusPlaceholder: "Select the request status",
		},
		incident: "Incident",
		status: {
			closed: "Finished",
			hold: "Hold",
			open: "Open",
			new: "New",
			pending: "Pending",
			solved: "Solved",
		},
		title: "Requests",
	},
	history: {
		title: "Contract History",
		filename: "contract_history",
		status: {
			approved: "Approved",
			pendingApproval: "Pending Approval",
			pendingSign: "Pending Signature",
		},
		columns: {
			requestDate: "Request Date",
			signatureDate: "Signature Date",
			contract: "Contract",
			managerName: "Sales Manager",
			signedBy: "Signed By",
			status: "Status",
		},
		filter: {
			periodTimeLabel: "Request Date",
			statusLabel: "Status",
			statusPlaceholder: "Select request status",
		},
		details: {
			title: "Request made in the template",
			temporary: "Temporary",
			permanent: "Permanent",
			contractNotFound: "Contract not found",
			equipments: {
				equipment: "Equipment",
				quantity: "Quantity",
				unitValue: "Unit Value",
				totalValue: "Total Value",
			},
			manager: {
				managerName: "ZIG Responsible",
				managerContact: "Contact Channel",
			},
			file: {
				type: "Request Type",
				requestModel: "Request Template",
				contractPeriod: "Contract Period",
			},
		},
		actionsTable: {
			details: "View request details",
			downloadDocuments: "Download Documents",
		},
	},
};
