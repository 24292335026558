export const cmvReport = {
	label: "CMV",
	description:
		"The Cost of Goods Sold (COGS), also known as the Cost of Sales, is the total cost incurred to acquire goods that were sold during a specific period of time. It is essential for determining a company's gross profit.",
	periodInfo: "Displaying report from {{since}} to {{until}}",
	export: "Download excel",
	columns: {
		product: "Product",
		category: "Category",
		quantity: "Quantity",
		fiscalCode: "SKU",
		ncm: "NCM",
		count: "Quantity",
		unitValue: "Unit value",
		totalValue: "Total value",
		productUnitCost: "Unit cost",
		totalCost: "Total cost",
		percentageCost: "Percentage cost",
		margin: "Margin",
		empty: "No products found!",
		total: "Total",
		totalProduct: "Total ({{product}})",
		supplie: "Supplie",
	},
	forms: {
		cmvType: "Displayed Products",
		cmvType1: "All products",
		cmvType2: "Place products only",
		cmvType3: "Vendor products only",
		search: "Search by category",
		period: "Period",
		product: "Buscar produto",
	},
	infoCards: {
		updated: "Updated",
		titleCard1: "Quantity of products",
		titleCard2: "Total Amount",
		titleCard3: "Total cost",
		titleCard4: "Percentage margin",
		titleCard5: "Margin",
		titleCard6: "Zero unit cost",
		titleCard6Action: "See all",
	},
	sheet: {
		title: "CMV report",
		fileName: "cmv_report",
		product: "Product",
		fiscalCode: "SKU",
		ncm: "NCM",
		count: "Quantity",
		unitValue: "Unit value",
		totalValue: "Total value",
		productUnitCost: "Unit cost",
		totalCost: "Total cost",
		margin: "Margin",
	},
	expandIcon: {
		arrowUp: "Show products",
		arrowDown: "Close products",
	},
	expandedTable: {
		loading: "Loading",
		columns: {
			product: "Product",
			codeProduct: "SKU",
			quantity: "Quantity",
			unitValue: "Unit Value",
			totalValue: "Total Value",
			unitCost: "unit Cost",
			totalCost: "Total Cost",
			percentMargin: "Percent Margin",
			margin: "Margin",
		},
	},
};
