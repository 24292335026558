import * as React from "react";
import s from "./radio-button.scss";

export interface IRadioButtonProps extends React.InputHTMLAttributes<HTMLInputElement> {
	label: React.ReactNode;
}

export const RadioButton = React.forwardRef<HTMLInputElement, IRadioButtonProps>(
	(props, ref) => (
		<div className={s.radio}>
			<input ref={ref} {...props} type="radio" />
			{Boolean(props.label) && <label htmlFor={props.id}>{props.label}</label>}
		</div>
	),
);
