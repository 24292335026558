export const duplicateMountableProduct = {
	duplicateMountable: "Duplicate Mountable",
	createNewProduct: "Create New Product",
	createNewProductDescription: "Duplicate the mountable product and edit a new product",
	importDuplicateInBatch: "Import Duplicate in Batch",
	importDuplicateInBatchDescription:
		"Import the mountable step for products already created",
	importDuplicate: "Import Duplicate",
	selectProductsDisclaimer:
		"Select the products you wish to import the mountable data for",
	linkToProducts: "Link to Products",
	addProductsToMountable: "Add products to add the mountable part",
	addProductsCount: "Add {{countProducts}} products",
	duplicateConfirmation: "Duplicate Confirmation",
	selectedProducts:
		"{{countProducts}} product(s) have been selected to duplicate the mountable steps.",
	finishStep: "Do you want to finish adding the steps?",
	finishStepNote:
		"NOTE: if mountable products were selected, their current steps will be replaced.",
	backStage: "Back Stage",
	confirmDuplicate: "Confirm Duplicate",
	successMessage: "Mountable steps have been added to products in the list.",
};
