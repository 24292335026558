export const withdrawRequestDetails = {
	drawerTitle: "Request of type {{type}}",
	successNotification: "Request canceled successfully",
	errorNotification: "Error canceling request",
	cancelWithdrawPlaceConfirmationButtonLabel: "Cancel confirmation",
	confirmWithdrawButtonLabel: "Confirm request",
	cancelWithdrawRequestButtonLabel: "Cancel request",
	sections: {
		summary: {
			title: "Request summary",
			fields: {
				place: "Place",
				timestamp: "Date/Hour",
				employee: "Employee",
			},
		},
		details: {
			title: "Request details",
			requestedValue: "Requested value",
			anticipatedValue: "Anticipated value",
			proRata: "Pro-Rata",
			totalValue: "Total value",
			bankAccount: "Bank account",
			obs: "Observation",
			paymentMethod: "Payment method",
			attachments: "Attachments",
		},
	},
	valueLabel: "Value:",
};
