export const transactions = {
	title: "Transações",
	titleForeign: "Produtos",
	activeTab: "transactions",
	valueTransactioned: "Valor Transacionado",
	value: "Valor Bruto Emitido",
	netValue: "Valor Líquido Emitido",
	period: "Período",
	export: "Exportar Excel",
	filterIssued: "Emitidos",
	filterIssuedLabel: "Filtrar por status",
	filterNotIssued: "Não emitidos",
	descriptionForeign:
		"Neste relatório, é possível ter uma visão do faturamento fiscal emitido considerando cada produto transacionado, com a opção de filtrar por período e status. O valor bruto emitido inclui o Imposto sobre o Valor Agregado (IVA), enquanto o valor líquido emitido é calculado após o desconto do IVA.",
	columns: {
		transactionId: "Id da Transação",
		productName: "Produto",
		count: "Quant.",
		value: "Valor unitário",
		total: "Valor total",
		netValue: "Valor Líquido",
		discountValue: "Desconto",
		date: "Data",
		isCombo: "Combo",
		eventName: "Evento",
		eventDate: "Data evento",
		cnpj: "CNPJ Emitente",
		cpf: "CPF Cliente",
		type: "Tipo",
		issuerNIF: "NIF Lojista",
		recipientNIF: "NIF Cliente",
		invoiceSeries: "Série nota",
		invoiceNumber: "Nº nota",
		invoiceValue: "Valor nota",
		invoiceNetValue: "Valor nota líquido",
		invoiceDate: "Data Emissão",
	},
	sheet: {
		title: "Transações",
		titleForeign: "Produtos",
		filename: "transacoes",
		filenameForeign: "produtos",
		columns: {
			transactionId: "Id da Transação",
			productName: "Produto",
			count: "Quantidade",
			value: "Valor unitário",
			total: "Valor total",
			netValue: "Valor Líquido",
			discountValue: "Desconto",
			date: "Data",
			isCombo: "É Combo",
			eventName: "Evento",
			eventDate: "Data evento",
			cnpj: "CNPJ Emitente",
			cpf: "CPF Cliente",
			type: "Tipo",
			issuerNIF: "NIF Lojista",
			recipientNIF: "NIF Cliente",
			invoiceSeries: "Série nota",
			invoiceNumber: "Nº nota",
			invoiceValue: "Valor nota",
			invoiceNetValue: "Valor nota líquido",
			invoiceDate: "Data Emissão",
		},
	},
};
