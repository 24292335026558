export const salesReport = {
	title: "Relatório de Vendas",
	tabTitle: "Vendas",
	exportButton: "Exportar",
	exportLabelWithFilters: "Relatório com filtros selecionados",
	exportAllSales: "Todas as vendas",
	exportOthersReportsLabel: "Outros relatórios",
	exportTotalSales: "Total em vendas",
	productsSoldLabel: "Qtd produtos vendidos",
	subtotalLabel: "Subtotal",
	subtotalTooltip: "Valor unit x Qtd vendida",
	discountsLabel: "Descontos",
	totalProductsSoldLabel: "Total em produtos vendidos",
	totalProductsSoldTooltip: "(Valor unit x Qtd vendida) - Descontos",
	allSalesTab: "Todas as vendas",
	totalSalesTab: "Total em vendas",
	paymentTypes: {
		bonus: "Bonus",
		fromApp: "Pago no App",
		fromEvent: "Pago no evento",
		fromOtherEvent: "Pago em outro evento",
		minimumConsumption: "Consumação mínima",
		post: "Pós",
		ppu: "Pré",
	},
	allSales: {
		table: {
			columns: {
				date: "Data",
				client: "Cliente",
				document: "Documento",
				employee: "Colaborador",
				products: "Produtos",
				paymentType: "Forma de pagamento",
				isRefunded: "Estornado",
				discount: "Desconto",
				transactionValue: "Valor da transação",
			},
			total: "Total",
			method: "método",
			methods: "métodos",
			product: "produto",
			products: "produtos",
		},
		filters: {
			clientPlaceholder: "Filtrar por cliente",
			employeePlaceholder: "Filtrar por colaborador",
			paymentTypePlaceholder: "Filtrar pagamento",
		},
	},
	totalSales: {
		groupPlaceholder: "Agrupar por categoria de produto",
		groupByBar: "Agrupar por bar",
		groupByCategory: "Agrupar por categoria de produto",
		noGroup: "Sem agrupamento",
		transactionPlaceholder: "Filtrar por tipo de transação",
		productPlaceholder: "Filtrar por produto",
		barPlaceholder: "Filtrar por bar",
		tableProductsSold: {
			sku: "SKU",
			name: "Nome",
			unitQuantity: "Qtd unit",
			unitValue: "Valor unit",
			subtotal: "Subtotal",
			discounts: "Descontos",
			totalValue: "Valor total",
			addCategory: "Adicionar categoria",
			totalInBar: "Total em {{barName}}",
			productImageAlt: "Imagem do produto",
			category: "Categoria",
			values: "{{length}} Valores",
		},
	},
};
