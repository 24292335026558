export const withdrawRequestDetails = {
	drawerTitle: "Solicitação do tipo {{type}}",
	successNotification: "solicitação cancelada com sucesso",
	errorNotification: "erro ao cancelar notificação",
	cancelWithdrawPlaceConfirmationButtonLabel: "Cancelar confirmação",
	confirmWithdrawButtonLabel: "Confirmar solicitação",
	cancelWithdrawRequestButtonLabel: "Cancelar solicitação",
	sections: {
		summary: {
			title: "Resumo da Solicitação",
			fields: {
				place: "Place",
				timestamp: "Data/Hora",
				employee: "Funcionário",
			},
		},
		details: {
			title: "Detalhes da Solicitação",
			requestedValue: "Valor solicitado",
			anticipatedValue: "Valor antecipado",
			proRata: "Pró-Rata",
			totalValue: "Valor bruto total",
			bankAccount: "Conta bancária",
			obs: "Observação",
			paymentMethod: "Método de pagamento",
			attachments: "Anexos",
		},
	},
	valueLabel: "Valor:",
};
