export const accountsPayableReceivable = {
	accounts: "Accounts",
	addAccount: "Add Account",
	exportExcel: "Export to excel",
	importExcel: "Import to excel",
	payable: "Payable",
	receivable: "Receivable",
	emptyText: "No account found",
	isPaidYes: "Yes",
	isPaidNo: "No",
	paymentStatus: {
		All: "All",
		Paid: "Paid",
		NotPaid: "Not Paid",
	},
	titleAccountPayable: "Accounts payable",
	excelFileNamePayable: "accounts_to_pay",
	titleAccountReceivable: "Accounts receivable",
	excelFileNameReceivable: "accounts_to_received",
	form: {
		newAccountPayable: "New account payable",
		editAccountPayable: "Edit account payable",
		newAccountReceivable: "New account receivable",
		editAccountReceivable: "Edit account receivable",
		informOfPayments: "Payment information",
		postingInformation: "Posting Information",
		lblSupplier: "Supplier",
		phSupplier: "Select Supplier",
		lblDocNumber: "Document number",
		phDocNumber: "Insert Document Number",
		lblInvoiceDate: "Accrual Date",
		phInvoiceDate: "Select Accrual Date",
		lblPaymentDate: "Payment date",
		phPaymentDate: "Select Payment Date",
		lblDueDate: "Due Date",
		phDueDate: "Select Due Date",
		lblDescription: "Description",
		phDescription: "Insert Description",
		phValue: "Insert Value",
		lblValue: "Value",
		lblPaymentMethod: "Payment Method",
		phPaymentMethod: "Select Payment Method",
		lblAccountId: "Bank account",
		phAccountId: "Select Bank Account",
		lblCostCenter: "Cost Center",
		phCostCenter: "Select Cost Center",
		lblChartAccounts: "Chart of Accounts",
		phChartAccounts: "Select Chart of Accounts",
		ttRequiredWhenFilled:
			"When filling in the value field, the chart of accounts becomes mandatory.",
		lblInterest: "Interest on arrears",
		phInterest: "Select Interest on arrears",
		lblFine: "Delay fine",
		phFine: "Select Late Fee",
		lblDiscount: "Discounts",
		phDiscount: "Select Discounts",
		lblPaidValue: "Amount paid",
		phPaidValue: "Select Paid Value",
		phZeroValue: "0.00",
		swtIsPaid: "Paid",
		swtConciliated: "Reconciled",
		swtTotalValue: "Total value:",
		recurringPayment: "Recurring payment",
		lblRecurringPaymentInterval: "Repeat every",
		lblRecurringPaymentTypeInterval: "Interval",
		lblRecurringPaymentTimes: "How many times?",
		totalRepetition: "Total in ",
		recurringEnd: "End of recurrence:",
		observation: "Observation",
		attachment: "Attachment",
		lblObservation: "Insert observation",
		lblAttachment: "Click or drag to attach files",
		lblFinancialFees: "Financial Fees",
		phFinancialFees: "Select Financial Fees",
		validations: {
			dueDate: "Enter Due Date to continue",
			value: "Enter Value to continue",
			chartAccounts: "Select Chart of Accounts to continue",
		},
		modalDelete: {
			title: "Delete account",
			cancelText: "Cancel",
			okText: "Delete account",
			message:
				"Account deletion is a final action. Do you want to proceed with deletion?",
		},
	},
	columns: {
		docNumber: "DOCUMENT NUMBER",
		supplier: "SUPPLIER",
		chartOfAccount: "CHART OF ACCOUNTS",
		account: "RECEIPT ACCOUNT",
		invoiceDate: "EXPIRATION DATE",
		dueDate: "COMPETENCY DATE",
		paymentDate: "PAYMENT DATE",
		value: "VALUE",
		isPaid: "PAID",
		actions: "ACTIONS",
	},
	attachModal: {
		title: "Account Attachments",
		noAttachments: "No attachments found at this time",
		table: {
			columns: {
				attachedFiles: "Attached files",
			},
			successfullyDeletedFile: "File deleted",
			errorDeleted: "Unable to delete file",
		},
	},
	labelFilter: "Click to filter",
	selectSupplier: "Filter by supplier",
	lblDocNumber: "Filter by document number",
	phDocNumber: "Enter the number to filter",
	lblPaymentDate: "Filter by payment date",
	lblPaymentStatus: "Filter by payment status",
	optPaymentStatus1: "All",
	optPaymentStatus2: "Paid",
	optPaymentStatus3: "Not Paid",
	lblDueDate: "Filter by due date",
	lblInvoiceDate: "Filter by accrual date",
	tzOverdue: "Overdue",
	tzmaturing: "Expires today",
	tzDue: "To win",
	tzPaid: "Paid",
	tzTotal: "Period total",
	attatchmentDescription:
		"Attach payment receipts or other documents related to this account so you don't lose track of them. Up to 4 documents are allowed in PDF, OFX, XML, XLS, JPG and PNG formats with up to 1.5MB.",
	attachFile: "Click to attach a file",
	attachImportError: {
		existingFile: "File already imported",
		exceededSize: "Size limit exceeded. The attachment must not be larger than 1.5MB",
	},
};
