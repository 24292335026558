export const storageTransfer = {
	warningNotifications: {
		productAlreadyOnList: "Esse produto já foi adicionado na lista!",
		originAndDestinyStorageAreTheSame:
			"O estoque de saida não podem ser o mesmo estoque de destino!",
		notPossibleToTransferToMultipleStorages:
			"Não é possivel adicionar na lista produtos com estoques de destino diferentes!",
	},
	title: "Transferir produtos do estoque  {{storageName}}",
	cancel: "Cancelar",
	finish: "Concluir",
	feedbackModal: {
		notFound: "Não encontrado",
		sheet: {
			filename: "transferencia-estoque",
			title: "Tranferencia do estoque {{fromStorageName}} para {{toStorageName}}",
			columns: {
				product: "Produto",
				quantity: "Quantidade",
				ube: "Unidade",
			},
		},
		close: "Fechar",
		cancel: "Cancelar",
		importOnlyAssociated: "Importar apenas associados",
		associateAndProducts: "Associar produtos e importar",
		downloadExcell: "Baixar em excel",
		messages: {
			loading: {
				title: "Transferindo Produtos",
				subtitle: "Aguarde, estamos transferindo os produtos",
			},
			error: {
				title: "Ops... Algo não saiu como planejado.",
				subtitle:
					"Não conseguimos realizar a transferência dos produtos.\nTente novamente mais tarde.",
			},
			needPlaceAtivation: {
				title: "Ops... Algo não saiu como planejado.",
				subtitle: "Deseja salvar o relatório de trasferência?",
			},
			success: {
				title: "Transferência realizada com sucesso",
				subtitle: "Deseja salvar o relatório de trasferência?",
			},
		},
		wasntPossibleToTransferDueToNotAssociatedProducts:
			"Ops... Não foi possível realizar a transferência. Alguns produtos não estão associados no local de destino. Associe os seguintes produtos para prosseguir:",
		title: "Relatório de trasferência",
		transferPrint: {
			printReport: "Imprimir relatório",
			pdf: {
				proofOfTransfer: "Comprovante de transferência",
				message:
					'Esse documento comprova a transferência de produtos do Estoque "{{fromStorage}}" para o estoque "{{toStorage}}" em {{transferDateString}} feita por ZigPay. Os produtos transferidos estão na seguinte lista:',
				storageResponsible: "Responsável pelo estoque destino",
			},
		},
	},
	transferProductForm: {
		form: {
			validation: {
				maxStorageCountIs: "Quantidade maxima em estoque é {{productMaxCountString}}",
				missingField: "Campo obrigatorio",
				invalidQuantity: "Quantidade invalida",
			},
			storageTypes: {
				local: "Estoque local",
				central: "Estoque geral",
			},
			placeholder: {
				product: "Busque pelo produto",
				storage: "Estoque de destino",
			},
			label: {
				storageType: "Transferir para:",
				storage: "Estoque de destino",
				product: "Busque pelo produto",
				quantity: "Quantidade",
			},
			submit: "Adicionar",
		},
	},
	table: {
		columns: {
			name: "produto",
			toStorage: "Estoque destino",
			count: "quantidade",
			unitcost: "custo unitário",
			totalcost: "custo total",
			action: "ação",
		},
	},
};
