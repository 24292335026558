import enterprise from "#resources/api/enterprise-client";
import { MetabaseQuestionEnum } from "#resources/api/enterprise-generated";
import { fetchModel } from "#resources/helpers/fetch-model";
import { showErrorNotification } from "#resources/helpers/notifications";
import { RootStore } from ".";

export class MetabaseStore {
	public rootStore: RootStore;

	constructor(rootSore: RootStore) {
		this.rootStore = rootSore;
	}

	public clean() {
		this.getMetabaseUrl.reset();
	}

	public getMetabaseUrl = new fetchModel<
		{ question: MetabaseQuestionEnum; params: any },
		string
	>({
		fnPromise: args => enterprise.getMetabaseUrl(args),
		onError: err => showErrorNotification(err.message),
	});
}
