export const emited = {
	details: "Emissions details",
	fiscalGroup: "Emissions by fiscal group",
	fiscalProfile: "Emissions by fiscal profile",
	byBars: "Emissions by bar",
	byEmployee: "Emissions by employee",
	foreignDescription:
		"This report presents an aggregated breakdown of the gross issued amount and net issued amount. It displays grouping by fiscal profile, product tax group, bar, employee, and payment method.",
	sheets: {
		details: {
			columns: {
				description: "Description",
				financialValue: "Financial value",
				title: "Title",
				unitValue: "Unit value",
				name: "Tax Profile",
				value: "Issued Value",
				valueForeign: "Issued Gross Value",
				netValueForeign: "Issued Net Value",
			},
			filename: "emissions_details",
			title: "Emissions Details",
		},
		fiscalGroup: {
			columns: {
				name: "Product Group",
				value: "Value",
				valueForeign: "Issued Gross Value",
				netValueForeign: "Issued Net Value",
			},
			filename: "emissions_by_fiscal_group",
			title: "Emissions by tax group",
		},
		byEmployee: {
			title: "Emissions by employee",
			filename: "employee_emissions",
			columns: {
				name: "Employee",
				valueForeign: "Issued Gross Value",
				netValueForeign: "Issued Net Value",
			},
		},
		byBars: {
			title: "Emission by bar",
			filename: "emission_by_bar",
			columns: {
				name: "Bar",
				valueForeign: "Issued Gross Value",
				netValueForeign: "Issued Net Value",
			},
		},
		fiscalProfile: {
			columns: {
				name: "Tax Profile",
				value: "Issued Value",
				valueForeign: "Issued Gross Value",
				netValueForeign: "Issued Net Value",
			},
			filename: "emissions_by_fiscal_profile",
			title: "Emissions by tax profile",
		},
	},
	title: "Emissions",
	subtitle: "This report only takes into account the notes issued under the NFC-e regime",
	table: {
		title: "Title",
		value: "Value",
		valueForeign: "Issued Gross Value",
		netValueForeign: "Issued Net Value",
		fiscalGroups: {
			columns: {
				name: "Fiscal Group",
				value: "Value",
				valueForeign: "Issued Gross Value",
				netValueForeign: "Issued Net Value",
			},
		},
		fiscalProfiles: {
			columns: {
				name: "Tax Profile",
				value: "Issued Value",
				valueForeign: "Issued Gross Value",
				netValueForeign: "Issued Net Value",
			},
		},
		byBars: {
			columns: {
				name: "Bar",
				valueForeign: "Issued Gross Value",
				netValueForeign: "Issued Net Value",
			},
		},
		byEmployee: {
			columns: {
				name: "Employee",
				valueForeign: "Issued Gross Value",
				netValueForeign: "Issued Net Value",
			},
		},
	},
};
