export const abcSupplierReport = {
	title: "Relatório ABC de Fornecedores",
	label: "ABC de Fornecedores",
	description:
		"Este relatório classifica os fornecedores em três categorias (A, B, C) com base no volume de compras. Os fornecedores da categoria A são os que concentram a maior parte dos gastos em compras de produto, enquanto os da categoria C são aqueles com menor gasto.",
	export: "Download em Excel",
	contentTable: {
		emptyReports: "Não há relatórios disponíveis",
		totalizers: {
			totalValues: "Valores totais dos produtos",
			curveA: "Curva A",
			curveB: "Curva B",
			curveC: "Curva C",
			tooltips: {
				curveA:
					"Fornecedores com valor alto valor que correspondem à até 80% do valor das compras",
				curveB:
					"Fornecedores com valor intermediário que correspondem à até 15% do valor das compras",
				curveC:
					"Fornecedores com baixo valor que correspondem à até 5% do valor das compras",
			},
		},
		columns: {
			name: "Fornecedor",
			total: "Custo Total",
			cumulateTotal: "Total Acumulado",
			percentage: "Representação Individual",
			cumulativePercentage: "Representação Acumulada",
			tier: "Classificação",
		},
	},
};
