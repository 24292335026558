export const closings = {
	title: "Fechamento por vendor",
	download: "Baixa planilha",
	choosePeriod: "Escolha período para exportar planilha",
	sheet: {
		title: "Fechamento de vendors",
		filename: "fechamento-de-vendors",
		columns: {
			vendorName: "vendor",
			vendorValueToReceive: "Valor a receber",
			otherProductsFeeValue: "Taxa variável sobre produtos especiais",
			generalFeeValue: "Taxa variável geral",
			eventDate: "Data do evento",
			payDate: "Data de repasse",
			generalPercentageFee: "Taxa variável sobre faturamento",
			fixedFee: "Taxa de aluguel fixa",
			totalSoldValue: "Faturamento total",
		},
	},
	calendar: {
		totalSoldValue: "Valor total",
		toReceive: "A receber",
	},
	modal: {
		details: "Detalhes do dia",
		vendorName: "Vendor",
		vendorValueToReceive: "Valor a receber",
		otherProductsFeeValue: "Taxa variável sobre produtos especiais",
		generalFeeValue: "Taxa variável geral",
		eventDate: "Data do evento",
		payDate: "Data de repasse",
		generalPercentageFee: "Taxa variável sobre faturamento",
		fixedFee: "Taxa de aluguel fixo",
		totalSoldValue: "Faturamento total",
	},
};
