export const abcSupplierReport = {
	title: "ABC Suppliers Report",
	label: "Suppliers ABC",
	description:
		"This report ranks suppliers into three categories (A, B, C) based on purchase volume. Category A suppliers are those that concentrate most of the spending on product purchases, while category C suppliers are those with the least spending.",
	export: "Download in Excel",
	contentTable: {
		emptyReports: "No reports available",
		totalizers: {
			totalValues: "Total product values",
			curveA: "Curve A",
			curveB: "Curve B",
			curveC: "Curve C",
			tooltips: {
				curveA:
					"Suppliers with high value that correspond to up to 80% of the purchase value",
				curveB:
					"Suppliers with intermediate value that correspond to up to 15% of the purchase value",
				curveC:
					"Suppliers with low value that correspond to up to 5% of the purchase value",
			},
		},
		columns: {
			name: "Supplier",
			total: "Total Cost",
			cumulateTotal: "Cumulative Total",
			percentage: "Individual Representation",
			cumulativePercentage: "Cumulative Representation",
			tier: "Classification",
		},
	},
};
