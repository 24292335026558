export const customerAndSupplier = {
	title: "Clientes e fornecedores",
	name: "Nome fantasia",
	namePlaceholder: "Digite aqui o nome fantasia",
	legalName: "Razão social",
	legalNamePlaceholder: "Digite aqui a razão social",
	cpfOrCnpj: "CPF/CNPJ",
	cpfOrCnpjPlaceholder: "Digite o CPF/CNPJ",
	inscricaoEstadual: "Inscrição Estadual",
	inscricaoEstadualPlaceholder: "000.0000-00",
	cep: "CEP",
	cepPlaceholder: "Digite o CEP",
	state: "Estado",
	statePlaceholder: "Digite o estado",
	city: "Cidade",
	cityPlaceholder: "Digite a cidade",
	street: "Endereço",
	streetPlaceholder: "Digite o endereço",
	streetNumber: "Número",
	streetNumberPlaceholder: "Digite o número",
	complemento: "Complemento",
	complementoPlaceholder: "Digite o complemento",
	neighborhood: "Bairro",
	neighborhoodPlaceholder: "Digite o bairro",
	bank: "Banco",
	bankPlaceholder: "Digite o banco",
	agency: "Agência",
	agencyPlaceholder: "0000",
	account: "Conta",
	accountPlaceholder: "Digite a conta",
	ownerName: "Nome do proprietário",
	ownerNamePlaceholder: "Digite o nome do proprietário",
	bankcpfOrCnpj: "CPF/CNPJ do forncedor",
	bankcpfOrCnpjPlaceholder: "Digite o CPF/CNPJ",
	phone: "Telefone fixo",
	cellPhone: "Telefone celular",
	email: "E-mail",
	placeholderPhone: "(00) 0000-0000",
	placeholderCellPhone: "(00) 0 0000-0000",
	placeholderEmail: "examplo@email.com",
	validateField: "Campo obrigatório",
	wishToAdd: "Deseja criá-lo?",
	popConfirm: "Você deseja mesmo apagar este item?",
	notInformed: "Não informado",
	address: "Endereço",
	actions: "Ações",
	type: "Tipo",
	addNewRegister: "Novo cadastro",
	personalData: "Dados pessoais",
	bankData: "Dados bancários",
	infoMessage:
		"O tipo de cadastro tem a finalidade de parametrizar os documentos financeiros utilizados por um cliente, um fornecedor ou ambos.",
	registerTypes: {
		client: "Cliente",
		supplier: "Fornecedor",
		customerAndSupplier: "Cliente e Fornecedor",
	},
	selectRegistrationType: "Selecione o tipo de cadastro",
	modalWarningCancel: {
		title: "Descartar alterações",
	},
	invalidAgency: "Agência inválida",
	invalidAccount: "Conta inválida",
	invalidZipCode: "CEP inválido",
	invalidEmail: "E-mail inválido",
	supplierDetails: "Detalhes do fornecedor",
	viewDetails: "Ver detalhes",
};
