import { autobind } from "core-decorators";
import * as React from "react";
import { IPromiseBasedObservable } from "mobx-utils";

interface FormProps extends React.HTMLProps<HTMLFormElement> {
	disableAutofocus?: boolean;
	onSubmit: (
		event: React.FormEvent<any>,
		promises?: IPromiseBasedObservable<any>[],
	) => void;
}

@autobind
class Form extends React.Component<FormProps, {}> {
	private form: HTMLFormElement | null = null;
	private firstInput?: HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement;
	private static count = 0;

	public componentDidMount() {
		Form.count++;

		if (this.props.disableAutofocus || Form.count !== 1) {
			return;
		}

		if (!this.firstInput && this.form) {
			const inputs = this.form.querySelectorAll("input, textarea, select");

			if (inputs && inputs[0]) {
				this.firstInput = inputs[0] as
					| HTMLInputElement
					| HTMLTextAreaElement
					| HTMLSelectElement;
			}
		}

		if (this.firstInput) {
			this.firstInput.focus();
		}
	}

	public componentWillUnmount() {
		Form.count--;
	}

	private onRef = (form: HTMLFormElement | null) => (this.form = form);

	public render(): JSX.Element {
		const { disableAutofocus, ...props } = this.props;
		const formProps: React.HTMLProps<HTMLFormElement> = props;

		return (
			<form ref={this.onRef} {...formProps}>
				{this.props.children}
			</form>
		);
	}
}

export default Form;
