import * as React from "react";
import { FullSpinner } from "@cubos/zig-components";

type Component<T> =
	| React.ComponentClass<T>
	| React.StatelessComponent<T>
	| React.FC<T>
	| React.NamedExoticComponent<T>;

interface AsyncComponentState<T> {
	Component: Component<T> | null;
}

export function asyncComponent<T>(
	getComponent: () => Promise<Component<T>>,
): React.ComponentClass<T> {
	return class AsyncComponent extends React.Component<T, AsyncComponentState<T>> {
		private static Component: Component<T> | null = null;
		public state = { Component: AsyncComponent.Component };

		public componentDidMount() {
			if (!this.state.Component) {
				getComponent().then(Component => {
					AsyncComponent.Component = Component;
					this.setState({ Component });
				});
			}
		}

		public render() {
			const { Component } = this.state;

			if (Component) {
				return <Component {...this.props} />;
			}

			return <FullSpinner />;
		}
	};
}
