export const whatsapp = {
	section: {
		header: {
			model: "Model",
			goBack: "Go back and select another model",
			editTitle: "Edit WhatsApp message",
		},
		tags: {
			approvedModel: "Approved model",
			modelInValidation: "Model in validation",
			modelRefused: "Model not approved by Whatsapp",
			modelRecieved: "Model received by Whatsapp",
		},
		form: {
			title: "Set message parameters",
			checkboxLabel: "Use customer name from bank",
			checkboxObservationLabel: "Use observation field from bank",
			previewTitle: "Message Preview",
		},
		footer: {
			btnUnderstandWhy:
				"Understand why you can only send WhatsApp messages from templates",
			btnRequestNewModel: "Request new model",
		},
		understandWhyModal: {
			title: "Understand why you can only send WhatsApp messages from templates",
			text:
				"Whatsapp and Facebook have clear usage policies. All communication content that passes through the platform needs to be reviewed and approved to ensure that it does not violate Whatsapp policies. To ensure that your policies are not violated, you will only be able to initiate conversations using this message template. Since facebook has the right to review and refuse the message templates at any time.",
			understandButton: "Understood",
		},
		emptyTemplateState: {
			title: "No template registered",
			text: "Request a new template to send WhatsApp messages to your customers",
		},
	},
	newTemplatePage: {
		header: {
			title: "Request new model for",
			subtitle: {
				text:
					"Requesting the new template may take a few days. Type below what you want to include in your new template.",
				link: "Remember to always follow WhatsApp content guidelines",
			},
		},
		removeFileButtonLabel: "Click here to attach an image",
		messageErrorUpload: "The attachment must be an image type.",
		invalideVariableMessage: "You must use one of the glossary variables",
		modelTitle: "Model title",
		modelTitleTooltipMessage: "Enter a title for your template",
		modelTitlePlaceholder: "Enter the title",
		imageLabel: "Image",
		imageTooltipMessage: "Attach an image to upload in your template",
		textareaLabel: "Message content",
		textareaTooltipMessage: "Write the content of the template you would like to create",
		textareaPlaceholder:
			"Write the template content here. Tip: to add variables and be able to change them in the future, follow the glossary on the side.",
		glossaryVariables: {
			date: "date",
			client: "client",
			productName: "product-name",
			productOriginalPrice: "product-original-price",
			productPromotionalPrice: "product-promotional-price",
			discount: "discount",
			observation: "observation",
		},
		glossary: {
			title: "Glossary of variables",
			description:
				"To reuse this template, enter the code below between square brackets as in the example: “[date]”",
			date: {
				name: "Period",
				description: "Day of the week or specific date",
			},
			client: {
				name: "Client name",
				description: "Client name",
			},
			productName: {
				name: "Specific product name",
				description: "Product's name",
			},
			discount: {
				name: "Discount percentage",
				description: "Discount percentage value",
			},
			originalProductPrice: {
				name: "Price of the product",
				description: "Original price of the specific product",
			},
			productPromotionalPrice: {
				name: "Product promotional price",
				description: "Specific product promotional price",
			},
			observation: {
				name: "Observation",
				description: "Observation",
			},
		},
		warning: {
			responseDeadline: "Response deadline",
			description:
				"WhatsApp can take up to 3 days to authorize the new model. During this period you can send other messages from the other models in the previous area.",
		},
		footer: {
			title: "Message preview",
			newModelButton: "Request new model",
		},
	},
	contentControlModal: {
		title: "Content control",
		paragraph1: {
			text:
				"Whatsapp and Facebook have clear usage policies. All communication content that passes through the platform needs to be reviewed and approved to ensure that it does not violate Whatsapp policies. The following are some prohibited uses taken from the documentation itself.",
			textLink: "WhatsApp Business Policy. To read the full documentation, click here.",
		},
		paragraph2: {
			text:
				"Considering the context in which ZigPay operates, we must make clear some of the policies contained in Whatsapp's commercial policy documentation that are likely to interfere with what you can disclose on the platform. We have listed a few below, but it is very important that you read the",
			textLink: "Complete commercial policy documentation.",
		},
		alcoholicBeverages: "Alcoholic beverages",
		paragraph3: {
			text: "Companies may not sell or promote the sale of alcoholic beverages",
			politicsBold: "Policy:",
			exampleBold: "Examples:",
		},
		paragraph4: {
			text:
				"Disclosures about books or DVDs about alcoholic beverages. Or Alcohol related items such as glasses, soft drinks and wine bottle holders.",
			areAllowedBold: "Are allowed:",
		},
		paragraph5: {
			text:
				"Disclosures about alcoholic beverages or kits for the production of alcoholic beverages.",
			areNotAllowedBold: "Not allowed:",
		},
		confirmation: {
			paragraph:
				"Facebook has policies on the type of content posted by WhatsApp. Do you confirm that you have read the",
			underlined:
				"policies and are aware of what can and cannot be disclosed by the platform?",
			label:
				"I confirm that I have read the WhatsApp Business policies and am aware of what may be disclosed.",
		},
	},
	actionButtons: {
		title: "Optional - Buttons",
		subTitle:
			"These are buttons that appear as clickable options right after your message",
		addButton: "Add another button",
		noneButton: "None",
		actionButton: "Actions",
		quickResponseButton: "Quick response",
		quickResponseAction: {
			title: "Quick response {{numbering}}",
			placeholderQuickResponse: "Enter quick answer here",
		},
		optionsAction: {
			label: {
				typeAction: "Type of action",
				makeCall: "Make a call",
				accessWebsite: "Access website",
				phoneNumber: "Phone number",
				buttonText: "Button text",
				country: "Country",
				websiteLink: "Website link",
			},
			placeholder: {
				callNow: "Call now",
				enterNumber: "Enter number here",
				accessWebsite: "Access our site",
				insertLink: "Insert the link here",
			},
		},
		validationFields: {
			requiredField: "Required field {{field}}",
		},

		formatValidation: {
			phoneNumber: "Invalid phone number",
			url: "Invalid website link",
		},
	},
};
