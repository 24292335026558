import i18n from "#i18n/index";

const t = i18n.t;

export function getDefaultErrorMessage(error: Error) {
	if (error.message) {
		return error.message;
	}

	return t("components:helpers.defaultErrorMessage");
}

export const errorTap = (fn: (err: any) => void) => (err: any) => {
	fn(err);
	throw err;
};
