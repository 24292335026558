export const fiscalBoard = {
	selectMonth: "Select a month",
	addPlace: "Add place",
	selectPlace: "Select a place",
	nfceIssued: "NFC-e issued",
	nfseIssued: "NFS-e issued",
	nfce: "NFC-e",
	nfse: "NFS-e",
	fiscalProfile: "Issuance by fiscal profile",
	column: {
		date: "Date",
		errorType: "Error type",
		type: "Type",
		value: "Value",
		total: "Total Issuable",
	},
	issuingErrors: "Issuing Errors",
};
