export const notEmitedProducts = {
	title: "Products not issued",
	activeTab: "not-emitted-products",
	export: "Export to Excel",
	sheet: {
		title: "Products not issued",
		filename: "not_issued_products",
		columns: {
			name: "Product",
			count: "Quantity",
			combo: "Combo",
			firstNotEmittedSellDate: "First day not issued",
			lastNotEmittedSellDate: "Last day not issued",
		},
	},
	columns: {
		name: "Product",
		count: "Quantity",
		combo: "Combo",
		firstNotEmittedSellDate: "First day not issued",
		lastNotEmittedSellDate: "Last day not issued",
	},
};
