export const totemActionsReport = {
	title: "Ações no Totem",
	description: "Lista de todas as ações ao longo do evento.",
	filters: {
		totem: {
			label: "Totem",
			placeholder: "Digite ou selecione o totem",
		},
		transaction: {
			label: "Tipo de transação",
			placeholder: "Digite ou selecione o tipo",
		},
		search: {
			label: "Buscar",
			placeholder: "Busca por ID",
		},
	},
	columns: {
		date: "DATA E HORA",
		transactionType: "TIPO DE TRANSAÇÃO",
		totem: "TOTEM",
		user: "CLIENTE",
		value: "VALOR",
		paymentMethod: "FORMA DE PAGAMENTO",
	},
	filename: "relatorio-acoes-no-totem",
};
