import { contentFiscalData } from "./content-fiscal-data";
import { contentProductDetail } from "./content-product-detail";
import { contentProductMountable } from "./content-product-mountable";
import { contentProportionalValue } from "./content-proportional-value";

export const contentsSection = {
	contentFiscalData,
	contentProductDetail,
	contentProductMountable,
	contentProportionalValue,
};
