import { Employee } from "#api/enterprise-generated";
import { autobind } from "core-decorators";
import * as React from "react";
import NotificationSystem from "react-notification-system";
import ModalManager from "#components/modal-manager";

import MainContainer from "#structure/main-container";
import MainHeader from "#structure/main-header";

import EventNavBar from "#components/nav-bars/event-nav-bar";
import OrganizationNavBar from "#components/nav-bars/organization-nav-bar";
import PlaceNavBar from "#components/nav-bars/place-nav-bar";

import s from "./main.scss";
import { inject, observer } from "mobx-react";
import PlaceStore from "#stores/place";
import { EventStore } from "#stores/event";
import { UiStore } from "#stores/ui";
import { AuthStore } from "#stores/auth";
import { OrganizationStore } from "#stores/organization";
import { SandwichMenuProvider } from "#structure/providers/sandwich-menu";
import { PendingContracts } from "#components/pending-contracts";

interface OwnProps {
	main: React.ReactType;
	params: GlobalTypes.RouteParams;
	location: {
		pathname: string;
	};
}

interface IMainProps extends OwnProps {
	user: Employee;
}

export const singletons = {
	notifier: (undefined as any) as NotificationSystem,
	modalManager: (undefined as any) as ModalManager,
};

interface InjectedProps {
	placeStore?: PlaceStore;
	organizationStore?: OrganizationStore;
	eventStore?: EventStore;
	authStore?: AuthStore;
	uiStore?: UiStore;
}

function createPageTitle(orgName?: string) {
	return orgName ? `${orgName} - Dashboard` : "Zig Dashboard";
}

@inject("placeStore", "eventStore", "uiStore", "authStore", "organizationStore")
@autobind
@observer
class Main extends React.Component<IMainProps & InjectedProps, {}> {
	public componentDidMount() {
		this.changeDocumentTitle();
		this.props.placeStore!.fetchPlaces();
	}

	public componentDidUpdate(prev: IMainProps & InjectedProps) {
		if (prev.location.pathname !== this.props.location.pathname) {
			this.changeDocumentTitle();
		}
	}

	public componentWillUnmount() {
		this.props.organizationStore?.clean();
		this.changeDocumentTitle();
	}

	private onNotificationSystemRef = (ref: NotificationSystem) => {
		singletons.notifier = ref;
	};

	private onModalManagerRef = (ref: ModalManager) => {
		singletons.modalManager = ref;
	};

	// private checkCurrentEmployeePermission = (permission: string) => {
	// 	const employeePermissions = (
	// 		this.props.authStore?.currentEmployee?.permissions ?? []
	// 	).map(({ slug }) => slug);
	// 	return employeePermissions.includes(permission);
	// };

	private changeDocumentTitle = () => {
		document.title = createPageTitle(this.props.organizationStore?.organization?.name);
	};

	public render(): JSX.Element {
		return (
			<SandwichMenuProvider>
				<div className={s.main}>
					{this.props.params.placeId ? (
						this.props.params.eventId ? (
							<EventNavBar
								placeId={this.props.params.placeId}
								eventId={this.props.params.eventId}
							/>
						) : (
							<PlaceNavBar placeId={this.props.params.placeId} />
						)
					) : (
						<OrganizationNavBar />
					)}

					<div className={s.wrapper}>
						<MainHeader routeParams={this.props.params} />

						<MainContainer>
							<NotificationSystem ref={this.onNotificationSystemRef} />

							<ModalManager ref={this.onModalManagerRef} />

							{this.props.params.placeId && (
								<PendingContracts placeId={this.props.params.placeId} />
							)}

							{React.cloneElement(this.props.main as any, {
								routeParams: this.props.params,
							})}
						</MainContainer>
					</div>
				</div>
			</SandwichMenuProvider>
		);
	}
}

export default Main;
