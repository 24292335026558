export const report = {
	title: "Reports",
	templateTitle: "General Report",
	search: "Search",
	tabs: {
		general: {
			title: "Overview",
			cards: {
				promoters: "Promoters",
				relationLists: "Lists",
				guests: "Guests",
				entranceTotal: "Total in input",
				consumptionTotal: "Total in consumption",
				total: "Grand total",
			},
			columns: {
				listName: "List",
				guestName: "Name",
				cpf: "CPF",
				promoter: "Promoter",
				entrance: "Input type",
				upgradeEntrance: "Entry upgrade",
				consumption: "Consumption",
				generalTotal: "Grand total",
				checkin: "Check in details",
				phone: "Phone",
				birthDate: "Date of birth",
			},
		},
		byList: {
			title: "By guest list",
			columns: {
				relationLists: "Lists",
				promoters: "Promoters",
				guests: "Guests",
				entranceTotal: "Total in input",
				consumptionTotal: "Total in consumption",
				total: "Grand total",
			},
		},
	},
	filters: {
		promoter: {
			title: "Filter by promoter",
			placeholder: "Add filter by promoters",
		},
		relation: {
			title: "Filter by guest list",
			placeholder: "Add filters by list",
		},
		period: "Period",
	},
	empty: {
		search: "We did not find results for your search.",
		filters: {
			title: "No results were found for this selection.",
			subtitle: "Select another option and try again",
		},
		noneList: {
			title: "Looks like you haven't created a list yet.",
			subtitle: "Reports will appear here when you have a registered list",
		},
	},
	file: {
		filename: "guest-list-report",
		titleByGuestList: "Report by guest list",
		titleGeneral: "General report",
		listName: "List Name",
		promoter: "Promoter",
		numberOfActiveGuests: "Check-in performed",
		numberOfGuests: "Total guest",
		entranceTotal: "Total of entrance",
		consumptionTotal: "Total in consumption",
		total: "Total General",
		name: "Name",
		document: "CPF",
		entranceType: "Entrance type",
		upgrade: "Entrance upgrade",
		checkinDetails: "Check-in details",
		phone: "Phone",
		birthDate: "Date of birth",
	},
};
