export const soldBaseProductReport = {
	tabTitle: "Saída geral de produtos",
	filename: "relatorio_de-saida_geral_de_produtos",
	emptyText: "Sem dados a serem exibidos.",
	table: {
		code: "SKU",
		product: "Produto",
		count: "Quantidade",
	},
};
