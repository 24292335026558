import { isMain } from "#configuration/config";
import { TabInfo } from "#components/tabs/tabs";
import { TypeTagLabel } from "#components/primitive/tag-label";
import i18n from "#i18n/index";
import { usePermission } from "#resources/hooks";
import { useModularization } from "#components/nav-bars/use-modularization";

const t = i18n.t;
export function placeTabs(
	placeId: string,
	hasCmv: boolean,
	hasGrabAndGo: boolean,
	hasBeerTap: boolean,
	hasMenuFeature: boolean,
	hasProductionRule: boolean,
): TabInfo[] {
	const { showMenuV2 } = useModularization();

	const { hasPermission } = usePermission();
	const hasMenuManagementPermission = hasPermission("menuManagement");
	const hasMenuManagement = hasMenuManagementPermission && hasMenuFeature;
	return [
		{
			key: "products",
			label: t("place:placeTabs.products"),
			link: `/place/${placeId}/products`,
		},
		{
			key: "combos",
			label: t("place:placeTabs.combos"),
			link: `/place/${placeId}/combos`,
		},
		{
			key: "supplies",
			label: t("place:placeTabs.supplies"),
			link: `/place/${placeId}/supplies`,
		},
		...(hasProductionRule
			? [
					{
						key: "production-control",
						label: t("place:storage.tabs.productionControl.label"),
						link: `/place/${placeId ?? "not-found"}/production-control`,
					},
			  ]
			: []),
		...(hasMenuManagement
			? [
					{
						key: "manage-menu",
						label: t("place:productMenu.menus"),
						link: `/place/${placeId}/manage-menu`,
					},
			  ]
			: []),
		...(hasMenuManagement
			? [
					{
						key: "personalization-settings",
						label: t("place:placeTabs.config"),
						link: `/place/${placeId}/personalization-settings`,
					},
			  ]
			: []),
		...(showMenuV2
			? []
			: [
					{
						key: "bars",
						label: t("place:placeTabs.bars"),
						link: `/place/${placeId}/bars`,
					},
			  ]),
		...(hasBeerTap
			? [
					{
						key: "beer",
						label: t("place:placeTabs.beer"),
						link: `/place/${placeId}/beer-dispenser`,
					},
			  ]
			: []),
		...(hasGrabAndGo
			? [
					{
						key: "gng",
						label: t("place:placeTabs.gng"),
						link: `/place/${placeId}/grab-and-go`,
					},
			  ]
			: []),
		...(hasCmv
			? [
					{
						key: "cmv",
						label: t("place:placeTabs.cmv"),
						link: `/place/${placeId}/cmv`,
					},
			  ]
			: []),
	];
}

export function promotionDiscountTabs(placeId: string, hasDiscount: boolean): TabInfo[] {
	const discount = {
		key: "discount-categories",
		label: t("components:helpers.tabs.discount"),
		link: `/place/${placeId}/discount-categories`,
	};

	const links = [
		{
			key: "promotions",
			label: t("place:promotions.title"),
			link: `/place/${placeId}/promotions`,
		},
		{
			key: "bonus-category",
			label: t("place:bonusCategories.title"),
			link: `/place/${placeId}/bonus-categories`,
			hide: isMain,
		},
	];

	if (hasDiscount) links.push(discount);

	return links;
}

export function coalaTabs(placeId: string): TabInfo[] {
	const t = i18n.t;
	return [
		{
			key: "menus",
			label: t("place:coala.tabs.menu"),
			link: `/place/${placeId}/coala-menus`,
		},
		{
			key: "products",
			label: t("place:coala.tabs.products"),
			link: `/place/${placeId}/coala-products`,
		},
		{
			key: "vendor-report",
			label: t("place:coala.tabs.vendorReport"),
			link: `/place/${placeId}/coala-vendor-report`,
		},
		{
			key: "product-report",
			label: t("place:coala.tabs.productReport"),
			link: `/place/${placeId}/coala-products-report`,
		},
		{
			key: "order-report",
			label: t("place:coala.tabs.orderReport"),
			link: `/place/${placeId}/coala-order-report`,
		},
		{
			key: "config",
			label: t("place:coala.tabs.config"),
			link: `/place/${placeId}/coala-config`,
		},
	];
}

export function employeeTabs(): TabInfo[] {
	return [
		{
			key: "employees",
			label: t("org:employees.tabs.employees.label"),
			link: `/employees`,
			dataQa: "employees",
		},
		{
			key: "roles",
			label: t("org:employees.tabs.roles.label"),
			link: `/employees-roles`,
			dataQa: "roles",
		},
	];
}

export function multivendorPlaceTabs(placeId: string): TabInfo[] {
	return [
		{
			key: "extract",
			label: t("components:helpers.tabs.extract"),
			link: `/place/${placeId}/multivendor-extract`,
		},
		{
			key: "closings",
			label: t("components:helpers.tabs.closings"),
			link: `/place/${placeId}/multivendor-closings`,
		},
		{
			key: "product-sold-by-vendor",
			label: t("components:helpers.tabs.productSoldByVendor"),
			link: `/place/${placeId}/multivendor-products-sold`,
		},
		{
			key: "vendors-revenue",
			label: t("components:helpers.tabs.vendorsRevenue"),
			link: `/place/${placeId}/multivendor-revenues`,
		},
		{
			key: "multivendor-debtors-report",
			label: t("components:helpers.tabs.debtorsReport"),
			link: `/place/${placeId}/multivendor-debtors-report`,
		},
	];
}
export function multivendorEventTabs(placeId: string, eventId: string): TabInfo[] {
	return [
		{
			key: "closings",
			label: t("event:multivendor.closings.title"),
			link: `/place/${placeId}/event/${eventId}/multivendor-closings`,
		},
		{
			key: "product-sold-by-vendor",
			label: t("event:multivendor.productsSold.title"),
			link: `/place/${placeId}/event/${eventId}/multivendor-products-sold`,
		},
		{
			key: "vendors-revenue",
			label: t("event:multivendor.revenues.title"),
			link: `/place/${placeId}/event/${eventId}/multivendor-revenues`,
		},
	];
}

export function fiscalTabs(
	placeId: string,
	hasDashboardFiscal = false,
	isZigEmployee?: boolean,
	isForeign = false,
): TabInfo[] {
	const links = [
		{
			key: "invoices",
			label: t("components:helpers.tabs.invoices"),
			link: `/place/${placeId}/invoices`,
		},
		{
			key: "missing-invoices",
			label: isForeign
				? t("components:helpers.tabs.missingInvoicesForeign")
				: t("components:helpers.tabs.missingInvoices"),
			link: `/place/${placeId}/missing-invoices`,
		},
		{
			key: "trasactions",
			label: isForeign
				? t("components:helpers.tabs.products")
				: t("components:helpers.tabs.trasactions"),
			link: `/place/${placeId}/trasactions`,
		},
	];

	if (!isForeign) {
		links.push({
			key: "not-emited-products",
			label: t("components:helpers.tabs.notEmitedProducts"),
			link: `/place/${placeId}/not-emited-products`,
		});
	}

	if (isForeign) {
		links.push({
			key: "recharges",
			label: t("components:helpers.tabs.recharges"),
			link: `/place/${placeId}/recharges`,
		});
	}

	if (isZigEmployee) {
		links.push({
			key: "search",
			label: t("components:helpers.tabs.search"),
			link: `/place/${placeId}/search`,
		});
	}

	if (hasDashboardFiscal) {
		links.push({
			key: "emited",
			label: t("components:helpers.tabs.emited"),
			link: `/place/${placeId}/emited`,
		});

		if (!isForeign) {
			links.push({
				key: "emission-errors",
				label: t("components:helpers.tabs.emissionErrors"),
				link: `/place/${placeId}/emission-errors`,
			});
		}

		if (!isMain) {
			links.push({
				key: "relatorio-fiscal",
				label: t("components:helpers.tabs.fiscalReport"),
				link: `/place/${placeId}/fiscal-report`,
			});
		}
	}

	return links;
}

export function EventReserves(placeId: string, eventId: string): TabInfo[] {
	const links = [
		{
			key: "reserve",
			label: t("event:booking.reserve.label"),
			link: `/place/${placeId}/event/${eventId}/reserve`,
		},
		{
			key: "reserve-report",
			label: t("event:booking.reserveReport.label"),
			link: `/place/${placeId}/event/${eventId}/reserveReport`,
		},
	];
	return links;
}

export function productTabs(): TabInfo[] {
	const links = [
		{
			key: "products",
			label: t("org:products.tabs.productsTab.label"),
			link: `/products`,
		},
		{
			key: "categories",
			label: t("org:products.tabs.categories.label"),
			link: `/categories`,
		},
		// todo hotfix product-feature
		// {
		// 	key: "product-feature-templates",
		// 	label: "Modelos de funcionalidade de produto",
		// 	link: "/product-feature-templates",
		// },
	];

	return links;
}

export function placeStorageTabs({ placeId }: { placeId: string }): TabInfo[] {
	const { hasPermission } = usePermission();

	const hasPermissionInventoryControl =
		hasPermission("manageStorageInventories") ||
		hasPermission("reviewStorageInventories");

	const routesWithInventoryControl = [
		{
			key: "inventory-control",
			label: t("place:storage.tabs.inventoryControl.label"),
			link: `/place/${placeId}/inventory-control`,
		},
	];

	return [
		{
			key: "storage",
			label: t("place:storage.tabs.storages.label"),
			link: `/place/${placeId}/storage-list`,
		},
		{
			key: "alertas-estoque",
			label: t("place:storage.tabs.storageAlerts.label"),
			link: `/place/${placeId}/storage-alerts`,
		},
		{
			key: "nfreport",
			label: t("place:storage.tabs.nfReport.label"),
			link: `/place/${placeId}/purchase-invoice`,
		},
		...(hasPermissionInventoryControl ? routesWithInventoryControl : []),
	];
}

export function placeStorageReports({
	placeId,
	hasCmv,
	hasFinancial,
}: {
	placeId: string;
	hasCmv: boolean;
	hasFinancial: boolean;
}): TabInfo[] {
	const routesWithCMV = [
		{
			key: "cmv-report",
			label: t("place:storage.tabs.cmvReport.label"),
			link: `/place/${placeId}/cmv-report`,
		},
		{
			key: "loss-report",
			label: t("place:storage.tabs.lossReport.label"),
			link: `/place/${placeId}/loss-report`,
		},
	];

	const routerWithFinancial = [
		{
			key: "abc-supplier-report",
			label: t("place:storage.tabs.abcReports.abcSupplierReport.label"),
			link: `/place/${placeId}/abc-supplier-report`,
		},
	];

	return [
		{
			key: "profit-margin-report",
			label: t("place:storage.tabs.profitMarginReport.label"),
			link: `/place/${placeId}/profit-margin-report`,
		},
		{
			key: "storage-history",
			label: t("place:storage.tabs.storageHistory.label"),
			link: `/place/${placeId}/storage-history`,
		},
		{
			key: "kardex",
			label: t("place:kardex.title"),
			link: `/place/${placeId}/kardex`,
		},
		{
			key: "abc-purchase-report",
			label: t("place:storage.tabs.abcReports.abcPurchaseReport.label"),
			link: `/place/${placeId}/abc-purchase-report`,
		},
		...(hasFinancial ? routerWithFinancial : []),
		{
			key: "stock-position-report",
			label: t("place:storage.tabs.stockPositionReport.label"),
			link: `/place/${placeId}/stock-position-report`,
		},
		...(hasCmv ? routesWithCMV : []),
		{
			key: "transfers",
			label: "Transferências",
			link: `/place/${placeId}/transfers`,
		},
	];
}

export function operationsReportEventTabs(
	placeId: string,
	eventId: string | null,
): TabInfo[] {
	const tabs: TabInfo[] = [
		{
			key: "operation-event-actions-report",
			label: t("event:operationReports.tabs.allEventActionsReport"),
			link: `/place/${placeId}/event/${eventId}/operation-event-actions-report`,
		},
		{
			key: "operation-totem-actions-report",
			label: t("event:operationReports.tabs.totemActionsReport"),
			link: `/place/${placeId}/event/${eventId}/operation-totem-actions-report`,
			hide: true,
		},
		{
			key: "operation-orange-screen-report",
			label: t("event:operationReports.tabs.orangeScreenReport"),
			link: `/place/${placeId}/event/${eventId}/operation-orange-screen-report`,
		},
	];

	return tabs;
}

export function operationsReportPlaceTabs(placeId: string): TabInfo[] {
	const tabs: TabInfo[] = [
		{
			key: "operation-place-transactions-report",
			label: t("place:transactionsReport.title"),
			link: `/place/${placeId}/operation-place-transactions-report`,
		},
	];

	return tabs;
}

export function guestListTabs(
	placeId: string,
	eventId: string | null,
	hasPromoterFeature: boolean,
): TabInfo[] {
	const guestListTabs = [
		{
			key: "guest-list",
			label: t("guestList.title", { ns: "place" }),
			link: eventId
				? `/place/${placeId}/event/${eventId}/guest-lists`
				: `/place/${placeId}/guest-lists`,
		},

		{
			key: "guest-list-report",
			label: t("guestList.report.title", { ns: "place" }),
			link: eventId
				? `/place/${placeId}/event/${eventId}/guest-lists-report`
				: `/place/${placeId}/guest-lists-report`,
		},
	];

	if (hasPromoterFeature && !eventId) {
		guestListTabs.push({
			key: "guest-list-promoter",
			label: t("guestList.promoter.title", { ns: "place" }),
			link: eventId
				? `/place/${placeId}/event/${eventId}/guest-lists-promoter`
				: `/place/${placeId}/guest-lists-promoter`,
		});
	}

	return guestListTabs;
}

export function whatsUpTabs(placeId: string): TabInfo[] {
	return [
		{
			key: "whats-up",
			label: t("whatsUp.title", { ns: "place" }),
			link: `/place/${placeId}/qual-boa`,
		},
	];
}

export function financialReportsByPlaceTabs(
	placeId: string,
	permissions: Record<
		| "seeFinanceReports"
		| "seeOpenedBillsReport"
		| "seeCashierReports"
		| "seeBonusReports"
		| "seeProductsSoldReport"
		| "seeRefundReports",
		boolean
	>,
	placeFeatures: Record<"hasRappi", boolean>,
): TabInfo[] {
	const {
		seeBonusReports,
		seeCashierReports,
		seeFinanceReports,
		seeOpenedBillsReport,
		seeProductsSoldReport,
		seeRefundReports,
	} = permissions;
	const { hasRappi } = placeFeatures;

	const tabs: TabInfo[] = [
		{
			key: "/FinancialReport",
			label: t("financialReport.menu.financialReport", { ns: "place" }),
			link: `/place/${placeId}/financial-report#/FinancialReport`,
			hide: !seeFinanceReports,
		},
		{
			key: "/salesTotemTransaction",
			label: t("financialReport.menu.salesTotemTransaction", { ns: "place" }),
			link: `/place/${placeId}/financial-report#/salesTotemTransaction`,
			hide: true,
		},
		{
			key: "/UsedPromotions",
			label: t("financialReport.menu.promotionsReports", { ns: "place" }),
			link: `/place/${placeId}/financial-report#/UsedPromotions`,
			hide: !seeFinanceReports,
		},
		{
			key: "/DebtorsReport",
			label: t("financialReport.menu.debtorsReport", { ns: "place" }),
			link: `/place/${placeId}/financial-report#/DebtorsReport`,
			hide: !seeOpenedBillsReport,
		},
		{
			key: "/devolutionCard",
			label: t("financialReport.menu.devolutionReport", { ns: "place" }),
			link: `/place/${placeId}/financial-report#/devolutionCard`,
			hide: !seeCashierReports,
		},
		{
			key: "/CloseCashier",
			label: t("financialReport.menu.cashierClose", { ns: "place" }),
			link: `/place/${placeId}/financial-report#/CloseCashier`,
			hide: !seeCashierReports,
		},
		{
			key: "/BonusReportForPlace",
			label: t("financialReport.menu.bonusReport", { ns: "place" }),
			link: `/place/${placeId}/financial-report#/BonusReportForPlace`,
			hide: !seeBonusReports,
			tagType: "new",
		},
		{
			key: "/DiscountsReportForPlace",
			label: t("financialReport.menu.discountsReportForPlace", { ns: "place" }),
			link: `/place/${placeId}/financial-report#/DiscountsReportForPlace`,
			hide: !seeBonusReports,
		},
		{
			key: "/RefundedProductsReport",
			label: t("financialReport.menu.refundedProductsReport", { ns: "place" }),
			link: `/place/${placeId}/financial-report#/RefundedProductsReport`,
			hide: !seeProductsSoldReport,
		},
		{
			key: "/seeRefundReports",
			label: t("financialReport.menu.seeRefundReports", { ns: "place" }),
			link: `/place/${placeId}/financial-report#/seeRefundReports`,
			hide: !seeRefundReports,
		},
		{
			key: "/BonusByProductAtPlace",
			label: t("financialReport.menu.bonusByProduct", { ns: "place" }),
			link: `/place/${placeId}/financial-report#/BonusByProductAtPlace`,
			hide: !seeBonusReports,
		},
		{
			key: "/OpenedBillPayment",
			label: t("financialReport.menu.openedBillPayment", { ns: "place" }),
			link: `/place/${placeId}/financial-report#/OpenedBillPayment`,
			hide: !seeFinanceReports,
		},
		{
			key: "/reserveReport",
			label: t("financialReport.menu.reserveReport", { ns: "place" }),
			link: `/place/${placeId}/financial-report#/reserveReport`,
			hide: !seeFinanceReports,
		},
		{
			key: "/RappiDiscountsReport",
			label: t("financialReport.menu.rappiDiscountsReport", { ns: "place" }),
			link: `/place/${placeId}/financial-report#/RappiDiscountsReport`,
			hide: !seeFinanceReports || !hasRappi,
		},
		{
			key: "/ConsumptionObligationReport",
			label: t("financialReport.menu.consumptionObligationReport", { ns: "place" }),
			link: `/place/${placeId}/financial-report#/ConsumptionObligationReport`,
			hide: !seeCashierReports,
		},
		{
			key: "/MinimumConsumptionReport",
			label: t("financialReport.menu.minimumConsumptionReport", { ns: "place" }),
			link: `/place/${placeId}/financial-report#/MinimumConsumptionReport`,
			hide: !seeCashierReports,
		},
		{
			key: "/openSalesReport",
			label: t("financialReport.menu.openSalesReport", { ns: "place" }),
			link: `/place/${placeId}/financial-report#/openSalesReport`,
			hide: !seeFinanceReports,
		},
		{
			key: "/zigAppTransactions",
			label: t("financialReport.menu.zigAppTransactions", { ns: "place" }),
			link: `/place/${placeId}/financial-report#/zigAppTransactions`,
			hide: !seeFinanceReports,
		},
		{
			key: "/cashierAdjustments",
			label: t("financialReport.menu.cashierAdjustmentsReport", { ns: "place" }),
			link: `/place/${placeId}/financial-report#/cashierAdjustments`,
			hide: !seeFinanceReports,
		},
		{
			key: "/transferReport",
			label: t("financialReport.menu.transactionTransfer", { ns: "place" }),
			link: `/place/${placeId}/financial-report#/transferReport`,
			hide: !seeFinanceReports,
		},
	];

	return tabs;
}

export function financialReportsByEventTabs(
	placeId: string,
	eventId: string,
	permissions?: Record<
		| "seeFinanceReports"
		| "seeOpenedBillsReport"
		| "seeCashierReports"
		| "seeBonusReports"
		| "seeRefundReports"
		| "hasRappi",
		boolean | undefined
	>,
) {
	const {
		seeBonusReports,
		seeCashierReports,
		seeFinanceReports,
		seeOpenedBillsReport,
		seeRefundReports,
		hasRappi,
	} = permissions || {};

	const tabs: TabInfo[] = [
		{
			key: "/FinancialReport",
			label: t("eventReports.tabs.resume", { ns: "event" }),
			link: `/place/${placeId}/event/${eventId}/reports#/FinancialReport`,
			hide: !seeFinanceReports,
		},
		{
			key: "/UsedPromotions",
			label: t("eventReports.tabs.promotion", { ns: "event" }),
			link: `/place/${placeId}/event/${eventId}/reports#/UsedPromotions`,
			hide: !seeFinanceReports,
		},
		{
			key: "/DebtorsReport",
			label: t("eventReports.tabs.debtors", { ns: "event" }),
			link: `/place/${placeId}/event/${eventId}/reports#/DebtorsReport`,
			hide: !seeOpenedBillsReport,
		},
		{
			key: "/devolutionCard",
			label: t("eventReports.tabs.devolution", { ns: "event" }),
			link: `/place/${placeId}/event/${eventId}/reports#/devolutionCard`,
			hide: !seeCashierReports,
		},
		{
			key: "/CloseCashier",
			label: t("eventReports.tabs.cashierClosings", { ns: "event" }),
			link: `/place/${placeId}/event/${eventId}/reports#/CloseCashier`,
			hide: !seeCashierReports,
		},
		{
			key: "/UserBonusReport",
			label: t("eventReports.tabs.userBonus", { ns: "event" }),
			link: `/place/${placeId}/event/${eventId}/reports#/UserBonusReport`,
			hide: !seeBonusReports,
			tagType: "new",
		},
		{
			key: "/DiscountsReport",
			label: t("eventReports.tabs.discounts", { ns: "event" }),
			link: `/place/${placeId}/event/${eventId}/reports#/DiscountsReport`,
			hide: !seeBonusReports,
		},
		{
			key: "/MinimumConsumptionReport",
			label: t("eventReports.tabs.minimumConsumption", { ns: "event" }),
			link: `/place/${placeId}/event/${eventId}/reports#/MinimumConsumptionReport`,
			hide: !seeBonusReports,
		},
		{
			key: "/ConsumptionObligationReport",
			label: t("eventReports.tabs.consumptionObligation", { ns: "event" }),
			link: `/place/${placeId}/event/${eventId}/reports#/ConsumptionObligationReport`,
			hide: !seeBonusReports,
		},
		{
			key: "/RefundedReport",
			label: t("eventReports.tabs.refunded", { ns: "event" }),
			link: `/place/${placeId}/event/${eventId}/reports#/RefundedReport`,
			hide: !seeRefundReports,
		},
		{
			key: "/ServiceRemovalReport",
			label: t("eventReports.tabs.servicesRemoval", { ns: "event" }),
			link: `/place/${placeId}/event/${eventId}/reports#/ServiceRemovalReport`,
			hide: !seeRefundReports,
		},
		{
			key: "/BonusByProductReport",
			label: t("eventReports.tabs.bonusByProduct", { ns: "event" }),
			link: `/place/${placeId}/event/${eventId}/reports#/BonusByProductReport`,
			hide: !seeFinanceReports,
		},
		{
			key: "/RechargeConsumption",
			label: t("eventReports.tabs.rechargeConsumption", { ns: "event" }),
			link: `/place/${placeId}/event/${eventId}/reports#/RechargeConsumption`,
			hide: !seeFinanceReports,
		},
		{
			key: "/RefundedRecharges",
			label: t("eventReports.tabs.refundedRecharges", { ns: "event" }),
			link: `/place/${placeId}/event/${eventId}/reports#/RefundedRecharges`,
			hide: !seeFinanceReports,
		},
		{
			key: "/IndividualLimitPostReport",
			label: t("eventReports.tabs.individualLimitPost", { ns: "event" }),
			link: `/place/${placeId}/event/${eventId}/reports#/IndividualLimitPostReport`,
			hide: !seeFinanceReports,
		},
		{
			key: "/RappiDiscountsReport",
			label: t("eventReports.tabs.rappiDiscounts", { ns: "event" }),
			link: `/place/${placeId}/event/${eventId}/reports#/RappiDiscountsReport`,
			hide: !seeFinanceReports || !hasRappi,
		},
		{
			key: "/OpenedBillPaymentReport",
			label: t("eventReports.tabs.openedBillPayment", { ns: "event" }),
			link: `/place/${placeId}/event/${eventId}/reports#/OpenedBillPaymentReport`,
			hide: !seeFinanceReports,
		},
		{
			key: "/openSalesReport",
			label: t("eventReports.tabs.openSales", { ns: "event" }),
			link: `/place/${placeId}/event/${eventId}/reports#/openSalesReport`,
			hide: !seeFinanceReports,
		},
		{
			key: "/zigAppTransactions",
			label: t("eventReports.tabs.zigAppReport", { ns: "event" }),
			link: `/place/${placeId}/event/${eventId}/reports#/zigAppTransactions`,
			hide: !seeFinanceReports,
		},
		{
			key: "/cashierAdjustments",
			label: t("eventReports.tabs.cashierAdjustments", { ns: "event" }),
			link: `/place/${placeId}/event/${eventId}/reports#/cashierAdjustments`,
			hide: !seeFinanceReports,
		},
		{
			key: "/afterClosingEventReport",
			label: t("eventReports.tabs.afterClosingEvent", { ns: "event" }),
			link: `/place/${placeId}/event/${eventId}/reports#/afterClosingEventReport`,
			hide: !seeFinanceReports,
		},
		{
			key: "/forciblySynchronizedBalance",
			label: t("eventReports.tabs.forciblySynchronizedBalances", { ns: "event" }),
			link: `/place/${placeId}/event/${eventId}/reports#/forciblySynchronizedBalance`,
			hide: !seeFinanceReports,
		},
		{
			key: "/transactionTransferReport",
			label: t("eventReports.tabs.transactionTransfer", { ns: "event" }),
			link: `/place/${placeId}/event/${eventId}/reports#/transactionTransferReport`,
			hide: !seeFinanceReports,
		},
	];

	return tabs;
}

export function barReportsByPlaceTabs(
	placeId: string,
	permissions: Record<
		"seeProductsSoldReport" | "seeSellsByWaiterReport" | "seeBarReports",
		boolean
	>,
	placeFeatures: Record<"burnPaperTickets", boolean>,
	hideByKeys?: string[],
): TabInfo[] {
	const { seeBarReports, seeProductsSoldReport, seeSellsByWaiterReport } = permissions;
	const { burnPaperTickets } = placeFeatures;

	let barReportsTabs: TabInfo[] = [
		{
			key: "SoldProducts",
			link: `/place/${placeId}/bar-reports#/SoldProducts`,
			label: t("barReports.soldProducts.tabTitle", { ns: "place" }),
			hide: !seeProductsSoldReport,
		},
		{
			key: "NewSoldProducts",
			link: `/place/${placeId}/bar-reports#/NewSoldProducts`,
			label: t("barReports.salesReport.tabTitle", { ns: "place" }),
			hide: !seeProductsSoldReport,
			tagType: "new",
		},
		{
			key: "ChipsBurned",
			link: `/place/${placeId}/bar-reports#/ChipsBurned`,
			label: t("barReports.chipsBurned.tabTitle", { ns: "place" }),
			hide: !burnPaperTickets,
		},
		{
			key: "ChipsBurnedByEmployee",
			link: `/place/${placeId}/bar-reports#/ChipsBurnedByEmployee`,
			label: t("barReports.chipsBurned.byEmployee.tabTitle", { ns: "place" }),
			hide: !burnPaperTickets,
		},
		{
			key: "WaiterReport",
			link: `/place/${placeId}/bar-reports#/WaiterReport`,
			label: t("barReports.waiterReport.tabTitle", { ns: "place" }),
			hide: !seeSellsByWaiterReport,
		},
		{
			key: "DeliveredProductsByBar",
			link: `/place/${placeId}/bar-reports#/DeliveredProductsByBar`,
			label: t("barReports.deliveredProductsByBar.tabTitle", { ns: "place" }),
			hide: !seeBarReports,
		},
		{
			key: "SoldProductReport",
			link: `/place/${placeId}/bar-reports#/SoldProductReport`,
			label: t("barReports.soldBaseProductReport.tabTitle", { ns: "place" }),
			hide: !seeProductsSoldReport,
		},
		{
			key: "tipReport",
			link: `/place/${placeId}/bar-reports#/tipReport`,
			label: t("barReports.tipReport.title", { ns: "place" }),
			hide: !seeBarReports,
		},
		{
			key: "productReturnsReport",
			link: `/place/${placeId}/bar-reports#/productReturnsReport`,
			label: t("barReports.productReturnsReport.title"),
			hide: !seeBarReports,
		},
	];

	if (hideByKeys?.length) {
		barReportsTabs = barReportsTabs.filter(({ key }) => !hideByKeys.includes(key));
	}

	return barReportsTabs;
}

export function barReportsByEventTabs(
	placeId: string,
	eventId: string,
	permissions: Record<
		| "seeProductsSoldReport"
		| "seeSellsByWaiterReport"
		| "seeBarReports"
		| "seeBarConsolidatedSellingsReport",
		boolean
	>,
	placeFeatures: Record<"burnPaperTickets" | "ficha", boolean>,
	hideByKeys?: string[],
): TabInfo[] {
	const {
		seeBarConsolidatedSellingsReport,
		seeBarReports,
		seeProductsSoldReport,
		seeSellsByWaiterReport,
	} = permissions;
	const { burnPaperTickets, ficha } = placeFeatures;

	let barReportsTabs: TabInfo[] = [
		{
			key: "SoldProducts",
			link: `/place/${placeId}/event/${eventId}/bar-reports#/SoldProducts`,
			label: t("barReports.tabs.soldProducts.label", { ns: "event" }),
			hide: !seeProductsSoldReport,
		},
		{
			key: "NewSoldProducts",
			link: `/place/${placeId}/event/${eventId}/bar-reports#/NewSoldProducts`,
			label: t("barReports.salesReport.tabTitle", { ns: "place" }),
			hide: !seeProductsSoldReport,
			tagType: "new",
		},
		{
			key: "ChipsBurned",
			link: `/place/${placeId}/event/${eventId}/bar-reports#/ChipsBurned`,
			label: t("barReports.tabs.chipsBurned.label", { ns: "event" }),
			hide: !burnPaperTickets,
		},
		{
			key: "ChipsBurnedByEmployee",
			link: `/place/${placeId}/event/${eventId}/bar-reports#/ChipsBurnedByEmployee`,
			label: t("barReports.tabs.chipsBurned.byEmployee.label", { ns: "event" }),
			hide: !burnPaperTickets,
		},
		{
			key: "WaiterReport",
			link: `/place/${placeId}/event/${eventId}/bar-reports#/WaiterReport`,
			label: t("barReports.tabs.waiterReport.label", { ns: "event" }),
			hide: !seeSellsByWaiterReport,
		},
		{
			key: "DeliveredProductsByBar",
			link: `/place/${placeId}/event/${eventId}/bar-reports#/DeliveredProductsByBar`,
			label: t("barReports.tabs.deliveredProductsByBar.label", { ns: "event" }),
			hide: !seeBarReports,
		},
		{
			key: "ConsolidatedBarReport",
			link: `/place/${placeId}/event/${eventId}/bar-reports#/ConsolidatedBarReport`,
			label: t("barReports.tabs.consolidatedBarReport.label", { ns: "event" }),
			hide: !seeBarConsolidatedSellingsReport,
		},
		{
			key: "SoldProductReport",
			link: `/place/${placeId}/event/${eventId}/bar-reports#/SoldProductReport`,
			label: t("barReports.tabs.soldProductReport.label", { ns: "event" }),
			hide: !seeProductsSoldReport,
		},
		{
			key: "PrintReport",
			link: `/place/${placeId}/event/${eventId}/bar-reports#/PrintReport`,
			label: t("barReports.tabs.reprintReport.title", { ns: "event" }),
			hide: !ficha,
		},
		{
			key: "tipReport",
			link: `/place/${placeId}/event/${eventId}/bar-reports#/tipReport`,
			label: t("eventReports.tabs.tipReport", { ns: "event" }),
			hide: !seeBarReports,
		},
		{
			key: "productReturnsReport",
			link: `/place/${placeId}/event/${eventId}/bar-reports#/productReturnsReport`,
			label: t("barReports.productReturnsReport.title"),
			hide: !seeBarReports,
		},
	];

	if (hideByKeys?.length) {
		barReportsTabs = barReportsTabs.filter(({ key }) => !hideByKeys.includes(key));
	}

	return barReportsTabs;
}

export function entranceReportsByPlaceTabs(
	placeId: string,
	seeFinanceReports: boolean,
): TabInfo[] {
	const entranceReportsTabs = [
		{
			key: "boxOfficeBilling",
			link: `/place/${placeId}/entrance-reports#/boxOfficeBilling`,
			label: t("entranceBilling.tabs.boxOfficeBilling.title"),
			hide: !seeFinanceReports,
		},
		{
			key: "EntranceReport",
			link: `/place/${placeId}/entrance-reports#/EntranceReport`,
			label: t("entranceBilling.tabs.entranceReport.tabLabel"),
			hide: !seeFinanceReports,
		},
	];

	return entranceReportsTabs;
}

export function tableReportsByPlaceTabs(placeId: string): TabInfo[] {
	const tabs = [
		{
			key: "general",
			label: t("tableReports.tabs.general.label", { ns: "event" }),
			link: `/place/${placeId}/table-reports#general`,
		},
		{
			key: "table-products-sold",
			label: t("tableReports.tabs.tableProductsSold.label", { ns: "event" }),
			link: `/place/${placeId}/table-reports#table-products-sold`,
		},
	];

	return tabs;
}

export function reserveTabs(placeId: string): TabInfo[] {
	const reserveTabs = [
		{
			key: "reserve-manager",
			label: t("place:reserve.tabs.reserveManager"),
			link: `/place/${placeId}/reserve/reserve-manager`,
		},

		{
			key: "general-config",
			label: t("place:reserve.tabs.generalReserveConfig"),
			link: `/place/${placeId}/reserve/general-config`,
		},
		{
			key: "calendar-management",
			label: t("place:reserve.tabs.calendarManagement"),
			link: `/place/${placeId}/reserve/calendar-management`,
		},
		{
			key: "queue-manager",
			label: t("place:reserve.tabs.queueManager"),
			link: `/place/${placeId}/reserve/queue-manager`,
		},
	];

	return reserveTabs;
}

export function giftbackTabs(): TabInfo[] {
	const giftbackTabs = [
		{
			key: "giftback-management",
			label: t("org:giftback.giftbacks.title"),
			link: `/giftback/management`,
		},
		{
			key: "giftback-list",
			label: t("org:giftback.giftbackList.title"),
			link: `/giftback/list`,
		},
		{
			key: "giftback-report",
			label: t("org:giftback.giftbackReport.title"),
			link: `/giftback/report`,
		},
	];

	return giftbackTabs;
}

//validar se existirá tabs adicionais para navegação
// export function chartOfAccounts(): TabInfo[] {
// 	const t = i18n.t;
// 	return [
// 		{
// 			key: "chart-of-accounts",
// 			label: "Plano de contas",
// 			link: `/chart-of-accounts`,
// 			dataQa: "chart-of-accounts",
// 		},
// 	];
// }

export function getTagType(
	tagType: "new" | "comingSoon",
): {
	type: TypeTagLabel;
	text: string;
} {
	switch (tagType) {
		case "new":
			return { type: "highlight", text: t("globalKeys:new") };

		case "comingSoon":
			return { type: "primary", text: t("globalKeys:comingSoon") };

		default:
			return { type: "primary", text: t("globalKeys:comingSoon") };
	}
}

export type TabTagType = "new" | "comingSoon" | null;
