export const conference = {
	title: "Conferência de evento",
	eventOpen: "Evento aberto",
	closeEventToConference: "Você deve fechar o evento para realizar a conferência",
	titleTypeOfConference: {
		resumed: "Quadro de resumo",
		integratedIncome: "Taxas sobre APP e máquinas integradas (crédito/débito)",
		notIntegratedIncome: "Taxas sobre máquinas POS não integradas (crédito/débito)",
		zigFees: "Taxas Zig",
		securityDeposit: "Depósito caução",
		otherCharges: "Outras cobranças",
		rechargeRefund: "Devolução de recarga",
	},
	columnsTable: {
		name: "Nome",
		grossValue: "Valor Bruto",
		tax: "Taxa",
		taxReceivedOrPayable: "A receber / A pagar Zig",
		receiptForecast: "Previsão de recebimento",
	},
	conference: "Conferência",
	processConference: "Processar conferência",
};
