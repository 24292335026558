import * as React from "react";

import { autobind } from "core-decorators";
import { inject, observer } from "mobx-react";
import { AuthStore } from "#stores/auth";

import MainLogo from "#components/logos/main-logo";
import LoginForm, { ILoginFields } from "#components/forms/login-form";

import { Organization } from "#api/enterprise-generated";

import s from "./login.scss";
import { observable } from "mobx";
import { WithTranslation, withTranslation } from "react-i18next";

interface Props extends WithTranslation<"noAuth"> {
	value: number;
	loginStep: string;
	isSendingCode: boolean;
	_isLoggingIn: boolean;
	loginData: {
		phone?: string;
	};
	authStore?: AuthStore;
}

interface State {
	organizations: Organization[];
}

@inject("authStore")
@autobind
@observer
class Login extends React.Component<Props, State> {
	@observable
	public isLogging: boolean = false;
	@observable
	public error: Error | null = null;

	public state: State = {
		organizations: [],
	};

	private async handleSubmit(values: ILoginFields) {
		this.isLogging = true;
		await this.props.authStore!.login(
			values.username!,
			values.password!,
			values.organization!,
		);

		this.isLogging = false;
	}

	public componentDidCatch(err: Error) {
		this.error = err;
	}

	public render() {
		const { t } = this.props;
		return (
			<div className={s.wholeWrapper}>
				<div className={s.loginBgWrapper} />

				<div className={s.login}>
					<div className={s.LoginFormWrapper}>
						<div className={s.subtitle}>{t("login.dashboard")}</div>

						<div className={s.loginContent}>
							<div className={s.loginContentForm}>
								<LoginForm
									isLoading={this.isLogging}
									organizations={this.state.organizations}
									onSubmit={this.handleSubmit}
									error={this.error || undefined}
								/>
							</div>
						</div>
					</div>
					<div className={s.logo}>
						<MainLogo />
					</div>
				</div>
			</div>
		);
	}
}

export default withTranslation("noAuth")(Login);
