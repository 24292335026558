export const storages = {
	label: " Storage",
	search: "Look for a storage",
	newStorage: "New storage",
	storageName: "Storage name",
	removeStorage: "Delete storage",
	editStorage: "Edit storage",
	createStorage: "Create storage",
	addNewStorage: "Add new storage",
	zeroBackoffice: "Clear all products in storage",
	deleteAllProducts: "Remove all products",
	modalTransfer: "Transfer products from {{storage}}",
	confirmMessage: "Are you sure you want to delete this storage?",
	transferResume: "Transfer report",
	storageList: {
		central: "General",
		default: "Place",
		emptyMessage: "No items to show",
		emptyArea: "No stocks to display",
	},
	storageCard: {
		productSearch: "Search for a product",
		addProduct: "Add product",
		edit: "Edit storage",
		transfer: "Transfer products",
		error: "Cannot {{action}} product with quantity 0",
		modal: {
			addProducts: "Add Products",
			removeProducts: "Remove Products",
			amount: "Enter the amount you want",
			add: "add and your unit cost price",
			count: "Quantity",
			costValue: "Cost Price",
			note: "Note",
		},
		modalTransfer: {
			title: "Transfer {{name}}",
			okText: "Transfer",
			storage: "Stock",
			quantity: "Quantity",
			error: {
				selectStorage: "Select a stock",
				selectCount: "Select an amount",
			},
		},
	},
	addProductModal: {
		title: "Add Products",
		manual: {
			label: "Manually",
			product: "Product",
			quantity: "The amount",
			costValue: "Cost price",
			addOtherProduct: "Add another product",
			error: {
				zeroCount: "Cannot add product with quantity 0",
				noneValidProduct: "No valid product selected",
			},
		},
		xml: {
			label: "Import by Invoice (XML)",
			upload: "Click or drag files to upload",
		},
		noteInfo: {
			error:
				"Some problem occurred with the note and it was not possible to create the Supplier",
			title: "Invoice Data",
			number: "Note number",
			series: "Series",
			operationType: "Nature of Operation",
			accessKey: "Access Key",
			unavailable: "Unavailable",
			supplierName: "Issuer",
			cpnj: "CNPJ",
			addSupplier: "Add issuer to your favorites",
			totalValue: "Total value",
			removeBill: "Remove Account Payable",
			addBill: "Add Account Payable",
			bill: "Account",
			provider: "Supplier",
		},
		form: {
			successNotification: "Imported Data",
			addedProducts: "Added Products",
			submit: "Finish import",
		},
		product: {
			label: "Product",
			code: "SKU",
			description: "Product Description",
			commercialQuantity: "Qty",
			commercialUnit: "Un",
			unitValue: "Unit price",
			equals: "equivalent to",
			cost: "Cost price per",
			skip: "Skip",
		},
	},
	transferProductModal: {
		transferFor: {
			label: "Transfer to",
			local: "Local Storage",
			central: "General Storage",
			placeSelect: {
				label: "Destination",
				placeholder: "Select a location",
			},
		},
		destinyStorage: {
			label: "Target storage",
			placeholder: "Select a storage",
		},
		product: {
			label: "Product",
			placeholder: "Select a product",
		},
		quantity: "The amount",
		table: {
			product: "Product",
			quantity: "The amount",
			unityCost: "Unit cost",
			totalCost: "Total cost",
			empty: "No selected products",
		},
		error: {
			addProduct: "Product cannot be added without quantity",
			selectProduct: "Negative quantity in storage. Product could not be selected",
		},
		printTransfer: {
			title: "Transfer Proof",
			text:
				"This document proves the transfer of products from the stock {{fromStorage}} to the stock {{toStorage}}. On the day {{date}} made by {{employee}}. The transferred products are in the following list :",
			quantity: "Quantity",
			withoutName: "Without Name",
			responsible: "Responsible for destination stock",
			successNotification: "Associated and transferred products",
			notFound: "Not Found",
			sheet: {
				filename: "stock-transfer",
				title: "Transfer from stock {{selectedStorage}} to {{toStorage}}",
				columns: {
					name: "Product",
					count: "Quantity",
					unit: "Unit",
					unitaryCost: "Unit Cost",
					totalCost: "Total Cost",
				},
			},
			infoText:
				"Transfer carried out successfully, see the summary of transferred products below",
			none: "None",
			print: "Print Transfer Document",
			download: "Download Excel from transfers",
			notTransnfer: "The following products could not be transferred",
			unassociated: "They are not associated with the target location",
			associate: "Click here to associate them",
		},
	},
	tableProducts: {
		columns: {
			name: "Product",
			category: "Category",
			count: "Quantity",
			actions: "Actions",
		},
		product: "Product",
		quantity: "Quality",
		cost: "Cost price",
		add: "Add product",
		total: "in total",
		productError: "Product not found",
		countError: "Cannot add product with quantity 0",
	},
	deleteProduct: {
		tooltip: "Delete product",
		title: "Are you sure you want to delete this product?",
	},
	error: "Error updating inventories",
	modalImportInvoice: {
		errors: {
			notKeyAccess: "Unable to fetch XML access key",
		},
		placeholders: {
			selectStorage: "Select stock",
		},
		labelInputs: {
			storage: "Storage",
			xmlKey: "XML key",
		},
		typeOptionsImport: {
			xmlFile: "Attach XML file",
			xmlKeyAccess: "Insert XML Access Key",
		},
		validationMessage: {
			emptySelectTypeImport: "Select an import option",
			emptyStorage: "Select a stock",
			emptyXmlKey: "Access Key field empty",
			emptyFileXml: "XML file not selected",
		},
	},
};
