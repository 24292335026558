export const addBillModalForm = {
	paymentMethods: {
		app: "Aplicativo",
		money: "Dinheiro",
		check: "Cheque",
		boleto: "Boleto bancário",
		creditCard: "Cartão de crédito",
		bankTransfer: "Transferência bancária",
		promissory: "Promissória",
		debitCard: "Cartão de débito",
		automaticDebit: "Débito automático",
	},
	description: "Descrição",
	descriptionPlaceholder: "Insira uma descrição",
	performDate: "Data de competência",
	dueDate: "Data de vencimento",
	account: "Conta",
	accountPlaceholder: "Selecione uma conta",
	value: "Valor",
	toPay: "Pagar",
	toReceive: "Receber",
	costCenter: "Centro de custo",
	costCenterPlaceholder: "Selecione um centro de custo",
	billType: "Tipo",
	billtypePlaceholder: "Selecione um tipo",
	supplier: "Fornecedor",
	supplierPlaceholder: "Selecione um fornecedor",
	paymentMethod: "Método de pagameto",
	paymentMethodPlaceholder: "Selecione um método de pagamento",
	payDate: "Data de pagamento",
	reconciled: "Reconciliado",
	status: "Pago",
	observation: "Observação",
	billAttachmentSize: "Arquivo muito grande (máx 20 MB)",
	loading: "Carregando...",
	attachment: "Anexo",
	deleteBill: "Tem certeza que deseja excluir esta conta?",
	validateField: "Campo obrigatório",
	invalidValue: "Valor inválido",
};
