import React from "react";
import { useTranslation } from "react-i18next";

type TableTotalPaginationProps = {
	total: number;
	range: [number, number];
};

export const TableTotalPagination = ({
	total,
	range: [initialItem, endItem],
}: TableTotalPaginationProps) => {
	const { t } = useTranslation("globalKeys");

	return (
		<span>
			<strong>
				{initialItem} - {endItem}
			</strong>{" "}
			{t("of")} {total}
		</span>
	);
};
