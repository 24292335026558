import classNames from "classnames";
import * as React from "react";
import s from "./textarea.scss";

export type ITextareaProps = React.TextareaHTMLAttributes<HTMLTextAreaElement>;

export interface ICustomTextAreaProps extends ITextareaProps {
	error?: string;
	helpText?: string;
}

// TODO forward ref nao necessaria pois é existe apenas o <textarea />
export const TextArea = React.forwardRef<HTMLTextAreaElement, ICustomTextAreaProps>(
	({ className, error, helpText, ...props }, ref) => (
		<div className={s.container}>
			<textarea
				className={classNames(s.textarea, error && s.error)}
				ref={ref}
				{...props}
			/>

			{(helpText || error) && (
				<span className={classNames(s.helpText, error && s.error)}>
					{error || helpText}
				</span>
			)}
		</div>
	),
);
