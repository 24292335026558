export const financial = {
	periodLabel: "({{since}} to {{until}})",
	filters: {
		income: "Revenue",
		average: "Average ticket",
		bonus: "Bonus",
	},
	methodFilters: {
		total: "Average",
		zigCredit: "Zigpay Credit",
		zigDebit: "Zigpay Debit",
		credit: "Credit",
		debit: "Debit",
		cash: "Money",
	},
	income: {
		label: "Compare with",
		labelChart: "Billed",
	},
	ticket: {
		average: "Average ticket",
		real: "Actual Ticket",
	},
	bonus: {
		label: "Bonus",
	},
	graphCards: {
		paymentMethod: {
			label: "Payment methods",
			subtitle: "Amount invoiced by payment method during the period {{periodLabel}}",
			zigCredit: "Zigpay Credit",
			zigDebit: "Zigpay Debit",
			credit: "Credit",
			debit: "Debit",
			cash: "Money",
			postEvent: "After the event",
			voucher: "Voucher",
			anticipated: "Anticipated",
			dashboard: "Dashboard",
			app: "Application",
			others: "Others",
			uniformed: "Uniformed",
		},
		billingsPerPeriod: {
			label: "Days with the highest income",
			subtitle: "Amount billed by day of the week during the period {{periodLabel}}",
		},
		averageTicket: {
			label: "Average ticket records",
			subtitle: "Amount billed per day during the period {{periodLabel}}",
		},
		bonusPerWeek: {
			label: "Days of the week with the most bonuses",
			subtitle: "Subsidized amount per day during the period {{periodLabel}}",
		},
		billingsPerWeekDay: {
			label: "Days of the week with the highest revenue",
			subtitle:
				"Average amount billed per day of the week during the period {{periodLabel}}",
		},
		averageTicketPerWeekDay: {
			label: "Days of the week with the highest average tickets",
			subtitle:
				"Average value of the Average Ticket per day of the week during the period {{periodLabel}}",
		},
		highestBonus: {
			label: "Bigger bonuses",
			subtitle: "Subsidized amount per day during the period {{periodLabel}}",
		},
		consumptionTime: {
			label: "Total sales by time",
			subtitle: "Period: {{periodLabel}}",
			filename: "total_sales_per_hour",
			formatPeriod: {
				nextPeriod: "{{currPeriod}} to {{nextPeriod}}",
				currPeriod: "at {{currPeriod}}",
			},
			tooltip: {
				dates: "Total consumed from {{since}} to {{until}}:",
				date: "Total consumed at {{date}}",
			},
			columns: {
				period: "Period",
				sunday: "Sunday",
				monday: "Monday",
				tuesday: "Tuesday",
				wednesday: "Wednesday",
				thursday: "Thursday",
				friday: "Friday",
				saturday: "Saturday",
				general: "General",
			},
		},
	},
	financialChartCards: {
		averageTicket: "Average Ticket",
		average: " Average",
		billing: "Billing",
		bonus: "Bonus",
	},
};
