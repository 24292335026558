import * as React from "react";
import ReactToPrint from "react-to-print";

import { Button, IButtonProps } from "#components/primitive";
import { Tooltip } from "antd";
import { useTranslation } from "react-i18next";

import IconPrintWhite from "#assets/svg/print.svg";
import IconPrintGray from "#assets/svg/print-gray.svg";

import s from "./print-button.scss";

export type Direction = "start" | "end";
type ButtonType = IButtonProps["variant"];

interface IProps {
	useRef: any;
	direction?: Direction;
	tooltip?: string;
	dataQa?: string;
	className?: string;
	onBeforePrint?: () => void;
	onAfterPrint?: () => void;
	onClick?: () => void;
	type?: ButtonType;
	children?: React.ReactNode;
}

export function PrintButton(props: IProps) {
	const { t } = useTranslation("place");

	const {
		useRef,
		onBeforePrint,
		onAfterPrint,
		onClick,
		type = "secondary",
		children,
	} = props;

	const getIconByTypeButton = () => {
		if (type === "primary" || type === "destructive") return <IconPrintWhite />;

		return <IconPrintGray />;
	};

	const tooltipText = props.tooltip ?? t("financialReport.print");
	const classByDirection =
		props.direction === "end" ? s.printEndDirection : s.printStartDirection;

	return (
		<div className={classByDirection}>
			<ReactToPrint
				onBeforeGetContent={onBeforePrint}
				onBeforePrint={onBeforePrint}
				onAfterPrint={onAfterPrint}
				trigger={() => (
					<Tooltip placement="bottomRight" title={tooltipText}>
						<Button
							// leadingIcon={getIconByTypeButton()}
							onClick={onClick}
							variant={type}
							className={s.printButton}
						>
							{getIconByTypeButton()}
							{children}
						</Button>
					</Tooltip>
				)}
				content={() => useRef?.current || useRef}
			/>
		</div>
	);
}
