export const sideTab = {
	delete: "Delete",
	promotionIs: {
		active: "Active",
		inactive: "Inactive",
	},
	popConfirm: {
		title: "Are you sure you want to delete the promotion?",
		deletePromotion: "Delete promotion",
	},
	modalConfirm: {
		title: "Disable promotion",
		text:
			"There are currently open events. When disabling this promotion, you will need to manually re-login on each device.",
		additionalText:
			"By deactivating the promotion, all customers who received it will not be able to use it until it is activated again. In addition, despite appearing on the POS promotions list, the brigade will not be able to grant it to any customer.",
		infoText: "Enter the full name of the promotion to confirm your action",
		errorMessage: "Incorrect promotion name",
		inactivePomotion: "Disable promotion",
		tooltipInactivePromotion:
			"You must enter the name of the promotion exactly as it is registered to complete the deactivation",
		placeholderInactivePromotion: "Enter the name of the promotion here",
	},
};
