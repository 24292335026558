import { DEV_TOKENS, PROD_TOKENS } from "./constants";

export type Envs = "prod" | "dev";

const extractCSSVariables = (cssText: string) => {
	const regex = /\$(.*?):\s*(rgb\([^)]+\));/g;
	const variables: Record<string, string> = {};
	let match;

	while ((match = regex.exec(cssText)) !== null) {
		variables[`--${match[1].trim().replace("$", "")}`] = match[2].trim();
	}

	return variables;
};

const setCSSVariables = (variables: Record<string, string>) => {
	Object.keys(variables).forEach(key => {
		document.documentElement.style.setProperty(key, variables[key]);
	});
};

export const applyTheme = (env: Envs) => {
	const TOKENS: { [key in Envs]: string } = {
		dev: DEV_TOKENS,
		prod: PROD_TOKENS,
	};

	const cssText = TOKENS[env];

	const variables = extractCSSVariables(cssText);
	setCSSVariables(variables);
};
