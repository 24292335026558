export const newEvent = {
	title: "New event",
	modalTitle: "Add event",
	form: {
		labelName: "Name:",
		labelBegin: "Start:",
		labelDescription: "Description:",
		eventPicture: "Event photo:",
		backgroundEventPicture: "Event cover photo:",
		editButton: "To edit",
		addButton: "Add",
		cancel: "Cancel",
		peopleCapacity: "Event capacity",
		peopleCapacityTooltip: "Number of people the event holds.",
	},
};
