export const storageAdd = {
	warningProductAlreadyOnList: "This product has already been added to the list!",
	title: "Add product to {{storageName}} stock",
	cancel: "Cancel",
	finish: "Finished",
	addProductForm: {
		form: {
			modalWarning: {
				title: "Product with zero cost",
				errorMessage:
					"The zero unit cost will affect the tracking of your COGS and impact the accuracy of some financial reports. Do you still want to proceed?",
				button: "No, fill in the cost",
			},
			label: {
				product: "Product",
				count: "Amount",
				cost: "Cost price",
			},
			validation: {
				missingField: "Mandatory field",
				invalidQuantity: "Invalid quantity",
			},
			submit: "Add",
		},
	},
	table: {
		columns: {
			product: "Product",
			count: "Amount",
			unitcost: "Unit cost",
			totalcost: "Total cost",
			action: "action",
		},
	},
};
