export const minimmConsummation = {
	titleTab: "Configuração de consumação mínima",
	titleColumnsTable: {
		place: "Place",
		fiscalProfile: "Perfil fiscal",
		fiscalGroup: "Grupo fiscal",
		ncm: "NCM",
		action: "Ação",
	},
	placeholders: {
		fiscalProfile: "Selecione um perfil fiscal",
		fiscalGroup: "Selecione um grupo fiscal",
		ncm: "Insira o código NCM",
	},
	errors: {
		requiredField: "Campo obrigatório",
		invalidFormatNcm: "O NCM deve conter 8 dígitos",
	},
	messageWarning:
		"Ao cancelar esta ação as modificações feitas não serão realizadas. Deseja continuar mesmo assim?",

	titleButtonOut: "Sair sem salvar",
};
