export const beerDispenser = {
	title: "Chopeira",
	searchPlaceholder: "Procurar",
	newBeerDispenser: "Nova chopeira",
	emptyText: "Nenhuma chopeira encontrada.",
	product: "Produto",
	ip: "IP",
	number: "Nº da torneira",
	error: {
		requiredField: "Campo obrigatório",
		invalidIp: "Formato de IP inválido",
	},
	modal: {
		addBeerDispenser: "Adicionar chopeira",
		editBeerDispenser: "Editar chopeira",
		name: "Nome",
		product: "Produto",
		productTooltip: "Somente produtos em mililitros/litros",
		number: "Número",
		dispenserIp: "IP da chopeira",
		deleteDispenser: "Excluir chopeira",
		deleteText: "	Você tem certeza de que deseja excluir a chopeira <b>{{chopName}}</b>?",
	},
};
