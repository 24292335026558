import {
	GuestReportResponse,
	ImportRelationResponse,
	MultipleRelationUser,
	NewRelation,
	NewRelationUser,
	Pagination,
	Relation,
	RelationDataOverview,
	RelationReport,
	RelationUser,
} from "#resources/api/enterprise-generated";
import { showErrorNotification, showSuccessNotification } from "#helpers/notifications";
import { RootStore } from ".";
import { action } from "mobx";
import enterprise from "#resources/api/enterprise-client";
import { fetchModel } from "#helpers/fetch-model";
import dayjs from "dayjs";
import i18n from "#i18n/index";

function middleDay(date: Date) {
	return dayjs(date).hour(12).toDate();
}

const t = i18n.t;

export class RelationStore {
	public rootStore: RootStore;

	constructor(rootStore: RootStore) {
		this.rootStore = rootStore;
	}

	public clean() {
		this.relations.reset();
		this.createRelation.reset();
		this.editRelation.reset();
		this.addRelationUser.reset();
		this.editRelationUser.reset();
		this.addMultipleRelationUser.reset();
		this.relationUsers.reset();
		this.getRelationReportByLists.reset();
		this.getRelationLists.reset();
		this.getPromoters.reset();
		this.getRelationReportOverview.reset();
		this.getRelationReportGuests.reset();
	}

	public relations = new fetchModel<{ placeId: string; eventId?: string }, Relation[]>({
		fnPromise: args => enterprise.getRelations(args),
		onError: err => showErrorNotification(err.message),
	});

	public createRelation = new fetchModel<{ relation: NewRelation }, Relation>({
		fnPromise: args => enterprise.createRelation(args),
		onError: err => showErrorNotification(err.message),
		onSuccess: relation => {
			if (this.relations.value === undefined) {
				this.relations.value = [relation];
			} else {
				this.relations.value.unshift(relation);
			}
		},
	});

	public editRelation = new fetchModel<
		{ relationId: string; relation: NewRelation },
		Relation
	>({
		fnPromise: args =>
			enterprise.editRelation({ id: args.relationId, relation: args.relation }),
		onError: err => showErrorNotification(err.message),
		onSuccess: relation => {
			if (this.relations.value === undefined) {
				this.relations.value = [relation];
			} else {
				this.relations.value = this.relations.value.map(relationValue => {
					if (relation.id === relationValue.id) {
						return relation;
					}

					return relationValue;
				});
			}
			showSuccessNotification(t("store:relationStore.editRelation"));
		},
	});

	public deleteRelation = async (relationId: string) => {
		try {
			await enterprise.deleteRelation({ id: relationId });
			if (this.relations.value !== undefined)
				this.relations.value = this.relations.value.filter(
					relation => relation.id !== relationId,
				);
			showSuccessNotification(t("store:relationStore.deleteRelation"));
		} catch (error) {
			if (error instanceof Error) {
				showErrorNotification(error.message);
			}
		}
	};

	public deleteMultipleRelation = async (relationIds: string[]) => {
		try {
			await enterprise.deleteMultipleRelation({ id: relationIds });
			if (this.relations.value !== undefined)
				this.relations.value = this.relations.value.filter(
					relation => !relationIds.includes(relation.id),
				);
			const notificationText =
				relationIds.length > 1
					? t("store:relationStore.deleteMultipleRelation_one")
					: t("store:relationStore.deleteMultipleRelation_other");
			showSuccessNotification(notificationText);
		} catch (error) {
			if (error instanceof Error) {
				showErrorNotification(error.message);
			}
		}
	};

	public relationUsers = new fetchModel<{ relationId: string }, RelationUser[]>({
		fnPromise: args => enterprise.getRelationUsers({ relationId: args.relationId }),
		onError: error => showErrorNotification(error.message),
	});

	public addRelationUser = new fetchModel<
		{ relationId: string; user: NewRelationUser },
		RelationUser[]
	>({
		fnPromise: args => enterprise.addRelationUser(args),
		onError: err => showErrorNotification(err.message),
		onSuccess: relationUsers => {
			this.relationUsers.value = relationUsers;
			showSuccessNotification(t("store:relationStore.addRelationUser"));
		},
	});

	public editRelationUser = new fetchModel<
		{ relationId: string; relationUserId: string; user: NewRelationUser },
		RelationUser[]
	>({
		fnPromise: args => enterprise.editRelationUser(args),
		onError: error => showErrorNotification(error.message),
		onSuccess: relationUsers => {
			showSuccessNotification(t("store:relationStore.editRelationUser"));
			this.relationUsers.value = relationUsers;
		},
	});

	public deleteRelationUser = async (relationId: string, relationUserId: string) => {
		try {
			await enterprise.deleteRelationUser({ relationId, relationUserId });
			showSuccessNotification(t("store:relationStore.deleteRelationUser"));
			return relationUserId;
		} catch (error) {
			if (error instanceof Error) {
				showErrorNotification(error.message);
			}
		}
	};

	public addMultipleRelationUser = new fetchModel<
		{ relationId: string; users: NewRelationUser[] },
		MultipleRelationUser
	>({
		fnPromise: args => enterprise.addMultipleRelationUser(args),
		onError: err => showErrorNotification(err.message),
	});

	@action
	public updateRelationUserCount = (relationId: string, userCount: number) => {
		if (this.relations.value)
			this.relations.value = this.relations.value.map(relation =>
				relation.id === relationId ? { ...relation, userCount } : relation,
			);
	};

	public activateOrDeactivateRelation = async (relationId: string, isActive: boolean) => {
		try {
			await enterprise.activeOrDeactiveRelation({ id: relationId, isActive });
			if (this.relations.value) {
				this.relations.value = this.relations.value.map(relation =>
					relation.id === relationId ? { ...relation, isActive } : relation,
				);
			}
			const notificationText = isActive
				? t("store:relationStore.activateOrDeactivateRelation_active")
				: t("store:relationStore.activateOrDeactivateRelation_deactive");
			showSuccessNotification(notificationText);
			return isActive;
		} catch (error) {
			if (error instanceof Error) {
				showErrorNotification(error.message);
			}
		}
	};

	public placeRelations = new fetchModel<{ placeId: string }, Relation[]>({
		fnPromise: args => enterprise.getRelations({ placeId: args.placeId, eventId: null }),
		onError: err => showErrorNotification(err.message),
	});

	public importRelation = new fetchModel<
		{ relationIds: string[]; placeId: string; eventId: string },
		ImportRelationResponse
	>({
		fnPromise: args => enterprise.importRelation(args),
		onError: error => showErrorNotification(error.message),
	});

	public getRelationLists = new fetchModel<
		{
			placeId: string;
			eventId: string | null;
			since: Date;
			until: Date;
		},
		Relation[]
	>({
		fnPromise: args =>
			enterprise.getRelationLists({
				...args,
				since: middleDay(args.since),
				until: middleDay(args.until),
			}),
		onError: err => showErrorNotification(err.message),
	});

	public getPromoters = new fetchModel<
		{
			placeId: string;
			eventId: string | null;
			since: Date;
			until: Date;
		},
		string[]
	>({
		fnPromise: args =>
			enterprise.getPromoters({
				...args,
				since: middleDay(args.since),
				until: middleDay(args.until),
			}),
		onError: err => showErrorNotification(err.message),
	});

	public getRelationReportByLists = new fetchModel<
		{
			placeId: string;
			eventId: string | null;
			promoters: string[];
			relationIds: string[];
			since: Date;
			until: Date;
		},
		RelationReport[]
	>({
		fnPromise: args =>
			enterprise.getRelationReportByLists({
				...args,
				since: middleDay(args.since),
				until: middleDay(args.until),
			}),
		onError: err => showErrorNotification(err.message),
	});

	public getRelationReportOverview = new fetchModel<
		{
			placeId: string;
			eventId: string | null;
			promoters: string[];
			relationIds: string[];
			since: Date;
			until: Date;
		},
		RelationDataOverview
	>({
		fnPromise: args =>
			enterprise.getRelationReportOverview({
				...args,
				since: middleDay(args.since),
				until: middleDay(args.until),
			}),
		onError: err => showErrorNotification(err.message),
	});

	public getRelationReportGuests = new fetchModel<
		{
			placeId: string;
			eventId: string | null;
			promoters: string[];
			relationIds: string[];
			since: Date;
			until: Date;
			pagination: Pagination;
		},
		GuestReportResponse
	>({
		fnPromise: args =>
			enterprise.getRelationReportGuests({
				...args,
				since: middleDay(args.since),
				until: middleDay(args.until),
			}),
		onError: err => showErrorNotification(err.message),
	});
}
