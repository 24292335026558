export const general = {
	label: "General report",
	eventDate: "Event date",
	filters: {
		table: "Table",
		employeeName: "Employee",
		payment: "Payment",
		tablePlaceholder: "Select a table",
		employeeNamePlaceholder: "All",
		status: "Status",
		paymentPlaceholder: "All",
		dateOrPeriod: "Data or period",
	},
	resume: {
		people: "People",
		totalRevenue: "Total revenue",
		openTables: "Tables in use",
		tablesUsed: "Mesas used",
		chargeback: "Chargeback",
		averageTicket: "Average ticket",
		averageLengthStay: "Average Length of Stay",
		hours: "hours and",
		minutes: "minutes",
		info: "This value already includes the service charge.",
	},
	table: {
		title: "Table consumption",
		allPayments: "See all payments",
		columns: {
			tableName: "TABLE",
			employeeName: "EMPLOYEE",
			numberOfSeats: "PEOPLE",
			openedAt: "OPENING",
			closedAt: "CLOSING",
			discount: "DISCOUNTS",
			refundValue: "REFUNDS",
			totalValue: "TOTAL",
			icon: "RECEIPT",
			permanence: "PERMANENCE",
			tip: "TIP",
			productsValue: "PRODUCTS VALUE",
			buyers: {
				name: "NAME",
				document: "DOCUMENT",
				paymentType: "PAYMENT",
				total: "TOTAL",
			},
		},
	},
	paymentTypes: {
		CreditCard: "Credit",
		DebitCard: "Debit",
		Pix: "Pix",
		Cash: "Cash",
	},
};
