/* eslint-disable max-len */
import { ConciliationTranslations } from "./conciliation-pt-br";

export const CONCILIATION_FR_FR: ConciliationTranslations = {
	pageTitle: "Conciliation via OFX",
	pageParagraph:
		"En téléchargeant le relevé de votre compte courant ou d'épargne au format OFX, disponible auprès des principales banques, et en l'important dans le tableau de bord, vous gagnez du temps dans la gestion de l'argent de votre entreprise.",
	importModal: {
		title: "Importation OFX",
		accountBankNotRegisteredTitle: "Compte bancaire non enregistré",
		triggerButton: "Importer un fichier OFX",
		cancelButton: "Annuler",
	},
	uploadOfxForm: {
		inputInstruction: "Cliquez ou faites glisser le fichier OFX",
		fileType: "Fichier OFX",
		description:
			"Exportez le fichier OFX de votre compte contenant uniquement les transactions afin de ne pas générer de mouvements indésirables",
		submitButton: "Importer",
		validation: {
			fileRequired: "Le fichier OFX est obligatoire",
			fileSize: "Le fichier OFX doit avoir une taille maximale de 5 Mo",
		},
	},
	submitOfxForm: {
		nameFile: "Nom du fichier :",
		periodImported: "Période importée :",
		periodImportedValue: "{{start}} à {{end}}",
		bankName: "Institution bancaire :",
		labelCurrency: "Solde final au {{date}}",
		labelCheckValue: "Je confirme le montant de {{value}} à la fin de ce relevé",
		labelAccounts: "Sélectionnez un compte :",
		placeholderAccount: "Cliquez pour sélectionner",
		accountRequired: "Sélectionnez un compte",
		submitButton: "Confirmer le montant",
		alerts: {
			accountNotCreated: {
				title: "Compte bancaire non trouvé",
				description:
					"Nous n'avons pas identifié ce compte bancaire parmi ceux enregistrés. <a>Cliquez ici</a> pour enregistrer le compte <b>ou sélectionnez un compte déjà enregistré ci-dessous</b> pour continuer l'importation de l'OFX.",
			},
			accountNotValidated: {
				title: "Ce compte n'a pas encore été validé.",
				description:
					"Ce compte bancaire a été enregistré sous le nom {{name}} mais ses données n'ont pas encore été validées. Nous utiliserons les données exportées dans cet OFX pour effectuer la validation automatique.",
			},
		},
	},
	feedbackOfxStep: {
		success: {
			title: "Importation réussie",
			itemsImported: "{{send}} transactions ont été importées",
			balance: "Solde final de {{balance}} au {{date}}",
		},
		error: {
			title: "Oups... Quelque chose n'a pas fonctionné comme prévu",
			description:
				"Nous n'avons pas pu effectuer l'importation de l'OFX. Veuillez réessayer plus tard.",
		},
		confirmButton: "Concilier les transactions",
	},
	redirectBankFlow: {
		description:
			"L'enregistrement du compte bancaire est une action nécessaire avant l'importation de l'OFX.",
		redirectButton: "Enregistrer un compte",
	},
	tabs: {
		pendingConciliations: "Conciliations en attente",
		history: "Historique",
		description: {
			pendingConciliations: "Détails des importations",
			history: "Historique des conciliations",
		},
		pendingTab: {
			emptyState: {
				title: "Aucune conciliation en attente pour le moment",
				description:
					"Importez un nouveau fichier OFX pour concilier de nouvelles transactions",
			},
		},
		historyTab: {
			emptyState: {
				title: "Aucune conciliation trouvée",
			},
		},
	},
	collapse: {
		branch: "Agence",
		account: "Compte",
		importedDate: "Période importée",
		importedDateDescription: "De {{since}} à {{until}}",
		conciliationDate: "Date de conciliation",
		actions: {
			undoConciliation: "Annuler la conciliation",
		},
		unConciliatedAmount: "{{amount}} non conciliées",
		item: {
			value: "Valeur",
			date: "Date",
			description: "Description",
			chartOfAccount: "Plan comptable",
			paymentMethod: "Mode de paiement",
			status: {
				conciliated: "Conciliée",
				refused: "Ignorée",
			},
			tooltip: {
				search: "Rechercher une transaction",
				addAccount: "Ajouter un compte",
				ignoreTransaction: "Ignorer la transaction",
			},
			accountType: {
				CheckingAccount: "Compte courant",
				SavingsAccount: "Compte d'épargne",
			},
			paymentTypes: {
				CREDIT: "Crédit",
				DEBIT: "Débit",
				INT: "INT",
				DIV: "DIV",
				FEE: "FEE",
				SRVCHG: "SRVCHG",
				DEP: "DEP",
				ATM: "ATM",
				POS: "POS",
				XFER: "XFER",
				CHECK: "Chèque",
				PAYMENT: "Paiement",
				CASH: "Espèces",
				DIRECTDEP: "Dépôt direct",
				DIRECTDEBIT: "Débit direct",
				REPEATPMT: "Paiement récurrent",
				OTHER: "Autres",
				CreditCard: "Carte de crédit",
				DebitCard: "Carte de débit",
				Pix: "Pix",
			},
		},
		cancel: "Annuler",
		complete: "Terminer la conciliation",
	},
	transactionSuccessNotification: "Transaction mise à jour avec succès !",
	cancelButton: "Annuler",
	confirmButton: "Confirmer",
	conciliateConciliation: {
		title: "Terminer la conciliation",
		description:
			"En terminant la conciliation, les transactions ignorées seront <b>supprimées définitivement</b> de vos conciliations. Voulez-vous continuer ?",
		error: "Il reste des transactions en attente !",
		success: "Conciliation réalisée avec succès !",
	},
	ignoreTransactionModal: {
		title: "Ignorer la transaction",
		description: "Êtes-vous sûr de vouloir ignorer la transaction <b>{{name}}</b> ?",
	},
	conciliationModal: {
		title: "Rechercher une transaction",
		contentTitle: "Informations sur l'importation",
		labelValue: "Valeur",
		labelPaymentDate: "Date de paiement",
		labelReceiptDate: "Date de réception",
		labelDescription: "Description",
		labelChartAccount: "Plan comptable",
		labelPaymentMethod: "Mode de paiement",
		buttonCancel: "Annuler",
		buttonConfirm: "Concilier",
		valuesAreDifferentAlert:
			"La valeur de la transaction est différente de la valeur sélectionnée et ne peut pas être conciliée",
		table: {
			emptyIsFetched: "Aucun compte trouvé",
			emptyCreatePaymentButton: "Créer un compte à payer",
			emptyCreateReceiptButton: "Créer un compte à recevoir",
			emptyInitial: {
				title: "Utilisez les recherches pour commencer",
				subtitle:
					"Recherchez et sélectionnez une transaction pour concilier la transaction ci-dessous.",
			},
			paymentDate: "Date",
			receiptDate: "Date",
			chartAccounts: "Plan comptable",
			paymentMethod: "Mode de paiement",
			value: "Valeur",
		},
	},
	undoIgnoreTransactionModal: {
		button: "Annuler l'exclusion",
		title: "Annuler l'exclusion",
		description:
			"Êtes-vous sûr de vouloir annuler l'exclusion de la transaction <b>{{name}}</b> ?",
	},
	undoConciliationTransactionModal: {
		button: "Annuler la conciliation",
		title: "Annuler la conciliation",
		description:
			"Êtes-vous sûr de vouloir annuler la conciliation de la transaction <b>{{name}}</b> ?",
	},
};
