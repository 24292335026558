import dayjs from "dayjs";
import { flowRight } from "lodash-es";

const datesFormat = {
	/**
	 * Date (in local format)
	 */
	LOCALE: "L",
	/**
	 * Day of week, month name, day of month, year, time (Thursday, September 4 1986 8:30 PM)
	 */
	LOCALE_FULL: "LLLL",
	/**
	 * Month name, day of month, year, time (September 4 1986 8:30 PM)
	 */
	LOCALE_FULL_2: "LLL",
	/**
	 * Month name, day of month, year (September 4 1986)
	 */
	LOCALE_FULL_3: "LL",
	/**
	 * Week day (Sunday Monday ... Friday Saturday)
	 */
	WEEK_DAY: "dddd",
	/**
	 * Month name, year (August 2022)
	 */
	MONTH_YEAR: "MMMM YYYY",
	/**
	 * Locale date (08/16/2018)
	 */
	DATE: "L",
	/**
	 * Locale date, hour (08/29/2022 10:05 AM)
	 */
	DATE_HOUR: "L LT",
	/**
	 * ISO_8601 (2022-08-29)
	 */
	ISO_8601: "YYYY-MM-DD",
	/**
	 * Locale hour (10:05 AM)
	 */
	HOUR: "LT",
	/**
	 * 24 hour (22:05)
	 */
	HOUR_24: "HH:mm",
} as const;

type DateFormat = keyof typeof datesFormat;

type SingularTime = "year" | "month" | "week" | "day" | "hour" | "minute" | "second";
type PluralTime = `${SingularTime}s`;

type TimeDuration = SingularTime | PluralTime;

interface CustomFormat {
	custom: string;
}

function formatDate<T = DateFormat>(
	date: Date,
	format?: T extends DateFormat ? T : CustomFormat,
): string {
	if (typeof format !== "object") {
		const defaultFormat = format ?? "LOCALE";
		return dayjs(date).format(datesFormat[defaultFormat as DateFormat]);
	}

	return dayjs(date).format(format.custom);
}

// Use it only for compose datetime, otherwise use datetime object

function add(amount: number, duration: TimeDuration) {
	return (date: Date) => dayjs(date).add(amount, duration).toDate();
}

function subtract(amount: number, duration: TimeDuration) {
	return (date: Date) => dayjs(date).subtract(amount, duration).toDate();
}

function startOf(duration: TimeDuration) {
	return (date: Date) => dayjs(date).startOf(duration).toDate();
}

function endOf(duration: TimeDuration) {
	return (date: Date) => dayjs(date).endOf(duration).toDate();
}

function isAfter(comparisonDate: Date) {
	return (date: Date) => dayjs(date).isAfter(comparisonDate);
}

function isBefore(comparisonDate: Date) {
	return (date: Date) => dayjs(date).isBefore(comparisonDate);
}

function isBetween(since: Date, until: Date) {
	return (date: Date) => dayjs(date).isBetween(since, until);
}

function isSame(comparisonDate: Date) {
	return (date: Date) => dayjs(date).isSame(comparisonDate);
}

function fromNow(withoutSuffix?: boolean) {
	return (date: Date) => dayjs(date).fromNow(withoutSuffix);
}

function diff(comparisonDate: Date, duration: TimeDuration) {
	return (date: Date) => dayjs(date).diff(comparisonDate, duration);
}

const datetime = (date = dayjs().toDate()) => ({
	now() {
		return date;
	},
	add(amount: number, duration: TimeDuration) {
		return add(amount, duration)(date);
	},
	subtract(amount: number, duration: TimeDuration) {
		return subtract(amount, duration)(date);
	},
	startOf(duration: TimeDuration) {
		return startOf(duration)(date);
	},
	endOf(duration: TimeDuration) {
		return endOf(duration)(date);
	},
	isAfter(comparisonDate: Date) {
		return isAfter(comparisonDate)(date);
	},
	isBefore(comparisonDate: Date) {
		return isBefore(comparisonDate)(date);
	},
	isBetween(since: Date, until: Date) {
		return isBetween(since, until)(date);
	},
	isSame(comparisonDate: Date) {
		return isSame(comparisonDate)(date);
	},
	fromNow(withoutSuffix?: true) {
		return fromNow(withoutSuffix)(date);
	},
	diff(comparisonDate: Date, duration: TimeDuration) {
		return diff(comparisonDate, duration)(date);
	},
	compose(...args: ((date: Date) => Date)[]): Date {
		return flowRight(...args)(date);
	},
});

export { datetime, subtract, startOf, endOf, add, formatDate };
