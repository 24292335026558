export const duplicateMountableProduct = {
	duplicateMountable: "Duplicar montável",
	createNewProduct: "Criar novo produto",
	createNewProductDescription: "Duplique o produto montável e edite um novo produto",
	importDuplicateInBatch: "Importar duplicata em lote",
	importDuplicateInBatchDescription:
		"Importe a etapa de montável para produtos já criados",
	importDuplicate: "Importar duplicata",
	selectProductsDisclaimer:
		"Selecione os produtos que deseja importar os dados do montável",
	linkToProducts: "Vincular a produtos",
	addProductsToMountable: "Adicione produtos para adicionar a parte montável",
	addProductsCount: "Adicionar {{countProducts}} produtos",
	duplicateConfirmation: "Confirmação de duplicata",
	selectedProducts:
		"Foi selecionado ({{countProducts}}) produto(s) para duplicar as etapas de montáveis.",
	finishStep: "Deseja concluir a adição das etapas?",
	finishStepNote:
		"OBS: se produtos montáveis foram selecionados terão suas etapas atuais substituídas.",
	backStage: "Voltar etapa",
	confirmDuplicate: "Confirmar duplicata",
	successMessage: "Foram adicionados etapas de montável em produtos da lista.",
};
