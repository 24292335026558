export const fiscalReport = {
	title: "Tax Report",
	activeTab: "fiscal-report",
	period: "Period",
	errorNotification: "Deadline {{date}}",
	sheet: {
		title: "Tax Report",
		filename: "fiscal_report",
		columns: {
			date: "Date",
			eventName: "Event",
			totalReceipt: "Issuable Billing",
			emitted: "Billing issued",
			emittedNfce: "Consumer Notes",
			emittedNfse: "Service Notes",
		},
	},
	fiscalProfile: "Fiscal Profile",
	columns: {
		date: "Date",
		eventName: "Event",
		totalReceipt: "Issuable Billing",
		emitted: "Billing issued",
		emittedNfce: "Consumer Notes",
		emittedNfse: "Service Notes",
		total: "Total",
	},
};
