import i18n from "#i18n/index";
import { message, notification } from "antd";

type MessageType = "info" | "success" | "error" | "warning" | "loading";

const t = i18n.t;

export function showSuccessNotification(description: string, duration: number = 5) {
	notification.open({
		message: t("components:notification.success"),
		description,
		type: "success",
		duration,
	});
}

export function showErrorNotification(description: string, duration: number = 5) {
	console.error("showErrorNotification: ", description);
	notification.open({
		message: t("components:notification.error"),
		description,
		type: "error",
		duration,
	});
}

export function showWarningNotification(description: string, duration: number = 5) {
	notification.open({
		message: t("components:notification.warning"),
		description,
		type: "warning",
		duration,
	});
}

export function showMessageOnTop({
	description,
	time,
	type,
}: {
	description: string;
	time?: number;
	type: MessageType;
}) {
	return message[type](description, time ? time / 1000 : 0);
}

export const hideMessageOnTop = () =>
	showMessageOnTop({
		description: "",
		time: 1000,
		type: "success",
	});
