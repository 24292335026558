export const fiscalGroupForm = {
	navigateBackGroupLabel: "Voltar para grupos fiscais",
	navigateBackCouvertLabel: "Voltar para couvert fiscais",
	titleSteps: {
		vat: "Imposto sobre valor agregado (IVA)",
		service: "Serviço",
		general: "Dados gerais",
	},
	steps: {
		general: {
			fields: {
				name: {
					label: "Nome",
					placeholder: "Nome do grupo",
				},
				type: {
					label: "Tipo",
				},
			},
			types: {
				invoice: "Fatura",
				vat: "Imposto sobre valor agregado (IVA)",
				vatFactura: "Imposto sobre valor agregado (IVA) - México",
				service: "Serviço",
			},
		},
		tip: {
			fiscalGroup: "Grupo fiscal",
			placeholder: "Selecionar grupo fiscal",
			cestPlaceholder: "Selecione o CEST",
		},
		vat: {
			fields: {
				taxRate: {
					label: "Aliquota (%)",
				},
				code: {
					label: "SKU",
					placeholder: "Selecionar SKU",
				},
				productType: {
					label: "Tipo de produto",
					placeholder: "Selecionar tipo de produto",
				},
				taxExemption: {
					label: "Isenção de imposto",
					placeholder: "Selecionar isenção de imposto",
				},
			},
			vatVendusTaxExemptionOptions: {
				M01: "Artigo 16.º, n.º 6 do CIVA ou similar",
				M02: "Artigo 6.º do decreto-Lei n.º 198/90, de 19 de Junho",
				M03: "Exigibilidade de caixa",
				M04: "Artigo 13.º do CIVA ou similar",
				M05: "Artigo 14.º do CIVA ou similar",
				M06: "Artigo 15.º do CIVA ou similar",
				M07: "Artigo 9.º do CIVA ou similar",
				M08: "IVA (autoliquidação)",
				M09: "IVA (não confere direito a dedução)",
				M10: "IVA (regime de isenção: artigo 53.º do CIVA)",
				M11: "Regime particular do tabaco",
				M12: "Regime da margem de lucro agências de viagens",
				M13: "Regime da margem de lucro bens em segunda mão",
				M14: "Regime da margem de lucro objetos de arte",
				M15: "Regime da margem de lucro objetos de coleção e antiguidades",
				M16: "Artigo 14.º do RITI ou similar",
				M99: "Não sujeito; não tributado ou similar",
			},
			vatVendusProductTypeOptions: {
				P: "Produto",
				S: "Serviço",
				O: "Outro (portes, adiantamentos, etc.)",
				I: "Imposto (excepto IVA e IS) ou Encargo Parafiscal",
				E: "Imposto especial de consumo (IABA, ISP e IT)",
			},
			vatVendusCodeOptions: {
				INT: "Taxa intermediária",
				ISE: "Isento",
				NOR: "Taxa normal",
				RED: "Taxa reduzida",
			},
		},
	},
};
