import {
	ClientsInTagTimeline,
	ConsumptionResume,
	ConsumptionTimeline,
	Employee,
	NewPublic,
	OpenTableTimeline,
	SelledProduct,
	TotalReceipt,
	VendorResume,
} from "#resources/api/enterprise-generated";
import { action, observable } from "mobx";
import { AsyncStore } from "#helpers/mobx/utils";
import enterprise from "#resources/api/enterprise-client";

export const INTERVAL_TIME = 1 * 60 * 1000;

type TLiveHooks =
	| "publicDetails"
	| "topClients"
	| "totalReceipt"
	| "topSellingProducts"
	| "consumptionTimeline"
	| "vendorsResume"
	| "rechargeTimeline"
	| "tableConsumptionTimeline"
	| "openTablesTimeline"
	| "clientsInTagTimeline"
	| "tableReceipt";

export class LiveStore extends AsyncStore<TLiveHooks> {
	@observable
	public publicDetails: NewPublic | null = null;
	// @observable
	// public topClients: TopSellingClient[] | null = null;
	@observable
	public vendorResume: VendorResume[] | null = null;
	@observable
	public totalReceipt: TotalReceipt | null = null;
	@observable
	public tableReceipt: number | null = null;
	@observable
	public topSellingProducts: SelledProduct[] | null = null;
	@observable
	public barConsumptionResume: ConsumptionResume | null = null;
	@observable
	public consumptionTimeline: ConsumptionTimeline[] | null = null;
	@observable
	public entrancesConsumptionResume: ConsumptionResume | null = null;
	@observable
	public rechargeTimeline: ConsumptionTimeline[] | null = null;
	@observable
	public tableConsumptionTimeline: ConsumptionTimeline[] | null = null;
	@observable
	public openTablesTimeline: OpenTableTimeline[] | null = null;
	@observable
	public clientsInTagTimeline: ClientsInTagTimeline[] | null = null;

	@observable
	private intervalId: null | number = null;
	@observable
	private listenedEventId: null | string = null;

	@action
	public async getPublicDetails(eventId: string) {
		await this.doAsyncAction(
			"publicDetails",
			async () => (this.publicDetails = await enterprise.getNewPublic({ eventId })),
		);
	}

	@action
	public async getTopSellingProducts(eventId: string) {
		await this.doAsyncAction(
			"topSellingProducts",
			async () =>
				(this.topSellingProducts = await enterprise.getSelledProducts({ eventId })),
		);
	}

	@action
	public async getTotalReceipt(eventId: string) {
		await this.doAsyncAction(
			"totalReceipt",
			async () => (this.totalReceipt = await enterprise.getTotalReceipt({ eventId })),
		);
	}

	@action
	public async getTableReceipt(eventId: string) {
		await this.doAsyncAction(
			"tableReceipt",
			async () => (this.tableReceipt = await enterprise.getTableReceipt({ eventId })),
		);
	}

	@action
	public async getConsumptionTimeline(eventId: string) {
		await this.doAsyncAction(
			"consumptionTimeline",
			async () =>
				(this.consumptionTimeline = await enterprise.getConsumptionTimeline({ eventId })),
		);
	}

	@action
	public async getRechargeTimeline(eventId: string) {
		await this.doAsyncAction(
			"rechargeTimeline",
			async () =>
				(this.rechargeTimeline = await enterprise.getRechargeTimeline({ eventId })),
		);
	}

	@action
	public async getTableConsumptionTimeline(eventId: string) {
		await this.doAsyncAction(
			"tableConsumptionTimeline",
			async () =>
				(this.tableConsumptionTimeline = await enterprise.getTableConsumptionTimeline({
					eventId,
				})),
		);
	}

	@action
	public async getOpenTablesTimeline(eventId: string) {
		await this.doAsyncAction(
			"openTablesTimeline",
			async () =>
				(this.openTablesTimeline = await enterprise.getOpenTablesTimeline({
					eventId,
				})),
		);
	}

	@action
	public async getClientsInTagTimeline(eventId: string) {
		await this.doAsyncAction(
			"clientsInTagTimeline",
			async () =>
				(this.clientsInTagTimeline = await enterprise.getClientsInTagTimeline({
					eventId,
				})),
		);
	}

	// @action
	// public async getTopClients(eventId: string) {
	// 	await this.doAsyncAction(
	// 		"topClients",
	// 		async () => (this.topClients = await enterprise.getTopClients({ eventId })),
	// 	);
	// }

	@action
	public async getVendorsResume(eventId: string) {
		await this.doAsyncAction(
			"vendorsResume",
			async () => (this.vendorResume = await enterprise.getVendorsResume({ eventId })),
		);
	}

	@action
	private update = async (user: Employee) => {
		if (this.listenedEventId) {
			const eventId = this.listenedEventId;
			await Promise.all([
				...(user.permissions.find(p => p.slug === "seePublic")
					? [this.getPublicDetails(eventId)]
					: []),
				...(user.permissions.find(p => p.slug === "seeFinanceReports")
					? [
							// this.getTopClients(eventId),
							this.getTotalReceipt(eventId),
							this.getTableReceipt(eventId),
							this.getConsumptionTimeline(eventId),
							this.getRechargeTimeline(eventId),
							this.getTableConsumptionTimeline(eventId),
							this.getVendorsResume(eventId),
							this.getOpenTablesTimeline(eventId),
							this.getClientsInTagTimeline(eventId),
					  ]
					: []),
				...(user.permissions.find(p => p.slug === "seeBarReports")
					? [this.getTopSellingProducts(eventId)]
					: []),
			]);
		} else if (this.intervalId !== null) {
			this.stopListening();
		} else {
			throw new Error(
				"Invalid LiveStore.update state: listenedEventId is null, but intervalId is not",
			);
		}
	};

	@action
	public startListening = (eventId: string, user: Employee) => {
		this.stopListening();

		this.listenedEventId = eventId;
		this.intervalId = window.setInterval(() => this.update(user), INTERVAL_TIME);
		this.update(user);
	};

	@action
	public stopListening = () => {
		if (this.intervalId !== null) {
			window.clearInterval(this.intervalId);
			this.intervalId = null;
		}

		this.listenedEventId = null;

		this.publicDetails = null;
		// this.topClients = null;
		this.totalReceipt = null;
		this.topSellingProducts = null;
		this.barConsumptionResume = null;
		this.consumptionTimeline = null;
		this.entrancesConsumptionResume = null;
		this.rechargeTimeline = null;
	};
}
