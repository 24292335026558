import { cashierAdjustmentsReport } from "#i18n/translations/en-US/components/reports/cashier-adjustments-report";

export const reports = {
	clientsReportTable: {
		filename: "consumption-per-customer",
		title: "Consumption per customer",
		name: "Name",
		phone: "Phone",
		zigCode: "ZigCode",
		cpf: "CPF",
		appTransactionsValue: "Paid by app",
		bonusTransactionsValue: "Paid with bonus",
		housePostPaidTransactionsValue: "Post paid in the house",
		housePrePaidTransactionsValue: "Prepaid in the house",
		total: "Total",
		hasApp: "Has app",
		noApp: "Don't have app",
		seeExtract: "See extract",
		editData: "Edit data",
		invoices: "Invoices",
		searchClient: "Search for a client here",
		customerConsumption: "Consumption per customer",
		empty: "No data to display.",
	},
	cmvReportTable: {
		mountableCaps: "MOUNTABLE",
		mountable: "Product mountable",
		name: "Name",
		section: "Section",
		count: "Quantity",
		productUnitCost: "Unit cost",
		total: "Total cost",
	},
	goTo: "go to",
	rappiDiscount: {
		products: "Products",
		date: "Date",
		value: "Discount value",
		employee: "Employee who applied the discount",
		emptyText: "No data to display.",
	},
	reserveReportTable: {
		title: "Package details",
		downloadTable: "Download Table",
		confirmedPeople: "{{value}} confirmed customers",
		ticketTooltip: "Average ticket from package customers",
		total: "Total billing",
		reserveValue: "billed in package value",
		totalProductsValue: "billed on products",
		tips: "tips",
		issuedInvoices: "Issuance of invoice",
		name: "Name",
		count: "Qty.",
		unitValue: "Unitary value",
		totalValue: "Total value",
		discount: "Discount",
		receivedValue: "Received amount",
		noPromotion: "Products out of promotion",
		discountTooltip: "Any discounts on the account are part of this value",
		productName: "Total",
		packageName: "Package name",
		date: "Package date",
		products: "Products",
		invitedPeople: "Guests",
		confirmedPeopleExcel: "Confirmed",
		value: "Vackage value",
		revenue: "Invoicing",
		issued: "Invoice issuance",
		titleExcel: "packages",
		filenameExcel: "packages",
		promotionName: "Promotion",
		productNameExcel: "Product",
		selledCount: "Quantity",
		titleExcelDetails: "package details ",
		filenameExcelDetails: "package_details_",
	},
	stockReportTable: {
		title: "Stock transfers",
		category: "Transfers from {{product}}",
		bar: "Bar",
		time: "Time",
		stocker: "Stocker",
		employeeBar: "Bar employee",
		quantity: "Quantity",
	},
	cashierAdjustmentsReport,
};
