export const storageAlerts = {
	label: "Alertas de estoque",
	search: "Pesquisar produtos",
	btnAddEmail: "Configurar recebimento de alerta",
	btnAddProductAlert: "Adicionar alerta para um produto",
	addEmailModal: {
		title: "Configurar recebimento de alertas por email",
		name: "Nome",
		email: "Email",
		emptyText: "Sem emails cadastrados",
	},
	addAlertModal: {
		title: "Adicionar novo alerta",
		storageAlert: {
			label: "Alerta",
			storageIn: "Estoque em",
			below: "abaixo de",
		},
		storageCritic: {
			label: "Crítico",
			storage: "Estoque",
			below: "abaixo de",
		},
		add: "Criar alerta",
		invalidText:
			"O valor de estoque em alerta não pode ser menor ou igual ao valor de estoque critico",
	},
	columns: {
		product: "Produto",
		storageQuantity: "Estoque atual",
		storageAlert: {
			label: "Alerta",
			storageIn: "Estoque em",
			from: "a partir de",
		},
		storageCritic: {
			label: "Crítico",
			storage: "Estoque",
			from: "a partir de",
		},
		actions: "Ações",
	},
	filters: {
		ok: "Ok",
		storageAlert: "Estado de alerta",
		storageCritic: "Estado crítico",
	},
};
