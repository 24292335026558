export const invoicecCardInfo = {
	title: "Invoice data",
	billNumber: "Bill number",
	noteSeries: "Note series",
	noteData: "Note data",
	CFOP: "CFOP",
	accesskey: "Access key",
	natureOfOperation: "Nature of operation",
	issuer: "Issuer",
	CNPJAndCPF: "CNPJ/CPF",
	amount: "Amount",
};
