import * as React from "react";
import { Modal } from "antd";
import { useTranslation } from "react-i18next";
import WarningIcon from "#assets/svg/warning-triangule.svg";
import { Button, Typography } from "#components/primitive";
import s from "../pending-contracts.scss";

interface IModalApproveConfirmationProps {
	visible: boolean;
	loading: boolean;
	onClose: () => void;
	onOk: () => void;
}

export function ModalApproveConfirmation({
	visible,
	loading,
	onClose,
	onOk,
}: IModalApproveConfirmationProps) {
	const { t } = useTranslation("components", {
		keyPrefix: "pendingContracts.modalApproveConfirmation",
	});
	const { t: tGlobalKeys } = useTranslation("globalKeys");
	return (
		<Modal
			title={
				<div className={s.header}>
					<WarningIcon />
					<Typography type="heading" variant="v5" className={s.title}>
						{t("header")}
					</Typography>
				</div>
			}
			open={visible}
			onCancel={onClose}
			maskClosable={false}
			className={s.modal}
			footer={false}
		>
			<>
				<Typography type="paragraph" variant="v1">
					{t("description")}
				</Typography>
				<Typography type="paragraph" variant="v2">
					{t("reminder")}
				</Typography>
				<div className={s.actions}>
					<Button variant="ghost" onClick={onClose} disabled={loading}>
						{tGlobalKeys("goBack")}
					</Button>
					<Button variant="primary" onClick={onOk} disabled={loading} loading={loading}>
						{t("sendCode")}
					</Button>
				</div>
			</>
		</Modal>
	);
}

export default ModalApproveConfirmation;
