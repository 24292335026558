import * as React from "react";
import { observer } from "mobx-react-lite";
import { useTranslation } from "react-i18next";
import { Link } from "react-router";
import {
	Menu,
	MenuDivider,
	MenuIconContainer,
	MenuItem,
	MenuTag,
	TagTypeStyle,
} from "#components/primitive";
import { useBreadcrumb } from "#structure/providers/breadcrumb";
import RootStore from "#stores/index";
import { useLanguage } from "#structure/providers/language";
import { useWindowSize } from "#resources/hooks";
import { ChangeLanguageModal } from "../change-language-modal";
import Icon, { IconName } from "../../icon";
import s from "./nav-bar.scss";
import ChangeLanguageIcon from "./icons/change-language.svg";
import LogoutIcon from "./icons/logout.svg";

export interface NavBarRoute {
	icon: string;
	label: string;
	url: string;
	tagType?: TagTypeStyle;
	readonly?: boolean;
}

interface INavBarProps {
	items: NavBarRoute[];
	type?: string;
	isOpenSandwichMenu: boolean;
	onChangeIsOpenSandwichMenu(isOpen: boolean): void;
}

export default observer(NavBar);

function LogoContainer({ children }: HTMLElement) {
	return <Link to={"/"}>{children}</Link>;
}

function NavBar({ isOpenSandwichMenu, items, onChangeIsOpenSandwichMenu }: INavBarProps) {
	const [openLanguageModal, setOpenLanguageModal] = React.useState(false);
	const { changeLanguage, languageInfo } = useLanguage();
	const { onChangeNavBarList, onChangeCurrentPage } = useBreadcrumb();
	const { isExtraSmallDevice } = useWindowSize();
	const { authStore } = React.useContext(RootStore);
	const { t } = useTranslation("nav", { keyPrefix: "navbar.default" });
	const { t: tGlobalKeys } = useTranslation("globalKeys");

	React.useEffect(() => {
		onChangeNavBarList(items);
	}, [items]);

	function onClick(item: NavBarRoute) {
		onChangeCurrentPage(item.label);

		if (onChangeIsOpenSandwichMenu && isExtraSmallDevice) {
			onChangeIsOpenSandwichMenu && onChangeIsOpenSandwichMenu(!isOpenSandwichMenu);
		}
	}

	const menuItems = (items || []).map((item, index) => {
		const Icon = require(`./icons/${item.icon}.svg`)?.default;

		const content = (
			<>
				{Icon && (
					<MenuIconContainer>
						<Icon />
					</MenuIconContainer>
				)}
				<div>
					{item.label}
					{item.tagType ? (
						<MenuTag type={item.tagType}>{tGlobalKeys(item.tagType)}</MenuTag>
					) : null}
				</div>
			</>
		);

		return (
			<MenuItem
				key={`navbar-item-${index}-${item.url}`}
				active={window.location.pathname === item.url}
				tagType={item.tagType}
			>
				{Boolean(item.readonly) ? (
					content
				) : (
					<Link className={s.item} to={item.url} onClick={() => onClick(item)}>
						{content}
					</Link>
				)}
			</MenuItem>
		);
	});

	menuItems.push(<MenuDivider key="divider" />);
	menuItems.push(
		<MenuItem key="change-language">
			<button className={s.item} onClick={() => setOpenLanguageModal(true)}>
				<MenuIconContainer className={s.changeLanguageIcon}>
					<ChangeLanguageIcon />
					{languageInfo?.icon && (
						<div className={s.languageFlag}>
							<Icon icon={languageInfo?.icon as IconName} />
						</div>
					)}
				</MenuIconContainer>
				<div>{t("changeLanguage")}</div>
			</button>
		</MenuItem>,
	);
	menuItems.push(
		<MenuItem key="logout">
			<button className={s.item} onClick={() => authStore.logout()}>
				<MenuIconContainer>
					<LogoutIcon />
				</MenuIconContainer>
				<div>{t("logout")}</div>
			</button>
		</MenuItem>,
	);

	return (
		<>
			<Menu LogoContainer={LogoContainer} isForceOpen={isOpenSandwichMenu}>
				{menuItems}
			</Menu>
			<ChangeLanguageModal
				changeLanguage={changeLanguage}
				isLanguageModalOpen={openLanguageModal}
				onChangeLanguageModalStatus={setOpenLanguageModal}
			/>
		</>
	);
}
