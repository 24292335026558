import {
	SalesDetailsOrderBy,
	SalesDetailsOrderByColumn,
} from "#resources/api/enterprise-generated";

export const DEBOUNCE_TIMEOUT = 500;

export const TOTAL_PER_PAGE = 10;

export const SORTABLE_FIELDS: SalesDetailsOrderByColumn[] = ["date", "isRefunded"];

export const DEFAULT_DATE_ORDER_BY: SalesDetailsOrderBy = {
	column: "date",
	order: "desc",
};

export const SALES_REPORTS_ALLOWED_EMAILS = [
	"karen.souza@zig.fun",
	"noemia.saionara@zig.fun",
	"beatriz.oliveira@zig.fun",
	"bruno.carvalho@zig.fun",
	"yuri.santos@zig.fun",
	"bianca.cruz@zig.fun",
	"caio.milleo@zig.fun",
	"vitor.rios@zig.fun",
	"vitor.cronemberger@zig.fun",
	"sergio.deusdedith@zig.fun",
];
