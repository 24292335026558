import React from "react";
import { Dropdown } from "antd";
import Icon, { IconName } from "#components/icon";
import { languageOptions, useLanguage } from "#structure/providers/language";
import { useTranslation } from "react-i18next";

import s from "./switch-languages.module.scss";

export const SwitchLanguages = () => {
	const { t } = useTranslation("nav");
	const { changeLanguage, languageInfo } = useLanguage();

	const OPTIONS = languageOptions.map(language => ({
		label: language.label,
		key: language.value,
		icon: <Icon icon={language.icon as IconName} />,
		onClick: () => changeLanguage(language.value, true),
	}));

	return (
		<Dropdown
			menu={{
				items: [
					{
						label: t("header.language"),
						disabled: true,
						key: "header",
					},
					...OPTIONS,
				],
			}}
			overlayClassName={s.wrapper}
			trigger={["click"]}
			placement="bottomRight"
		>
			<a role="combobox" className={s.container} onClick={e => e.preventDefault()}>
				<Icon icon={languageInfo.icon as IconName} />

				<Icon icon="chevron-down" />
			</a>
		</Dropdown>
	);
};
