import { EnterpriseApiSignature, EnterpriseEndpointKey } from "#resources/helpers/api";
import { SdkgenError } from "@sdkgen/browser-runtime";
import { useQuery, UseQueryOptions, UseQueryResult } from "@tanstack/react-query";
import { showErrorNotification } from "#resources/helpers/notifications";
import enterprise from "#resources/api/enterprise-client";

type ICreateQueryFactory = <T extends EnterpriseEndpointKey>(
	methodName: T,
	defaultOptions?: Omit<
		UseQueryOptions<EnterpriseApiSignature<T>["result"], SdkgenError>,
		"queryKey"
	>,
	getQueryKey?: (params: EnterpriseApiSignature<T>["args"]) => any[],
) => {
	useApiHook: <TData = EnterpriseApiSignature<T>["result"]>(
		params: EnterpriseApiSignature<T>["args"],
		options?: Omit<UseQueryOptions<TData, SdkgenError>, "queryKey">,
	) => UseQueryResult<TData, SdkgenError>;
	MethodSignature: EnterpriseApiSignature<T>;
	MethodResult: EnterpriseApiSignature<T>["result"];
	UseApiOptions: Omit<
		UseQueryOptions<EnterpriseApiSignature<T>["result"], SdkgenError>,
		"queryKey"
	>;
};

function generateQueryKey<K extends EnterpriseEndpointKey>(
	endpoint: K,
	params: EnterpriseApiSignature<K>["args"],
) {
	return [endpoint, params];
}

/**
 * Cria um hook de consulta utilizando React Query e SDKgen.
 *
 * @param methodName - O nome do método da API.
 * @param defaultOptions - Opções padrão para a consulta.
 * @param getQueryKey - Função opcional para gerar a queryKey personalizada.
 * @returns Um objeto contendo o hook de consulta e os tipos relacionados.
 */
export const createQueryFactory: ICreateQueryFactory = (
	methodName,
	defaultOptions,
	getQueryKey,
) => {
	type MethodSignature = EnterpriseApiSignature<typeof methodName>;
	type MethodResult = MethodSignature["result"];
	type UseApiOptions = Omit<UseQueryOptions<MethodResult, SdkgenError>, "queryKey">;

	const useApiHook = <TData = MethodResult>(
		params: MethodSignature["args"],
		options?: UseApiOptions,
	): UseQueryResult<TData, SdkgenError> => {
		return useQuery({
			throwOnError(error) {
				showErrorNotification(error.message);

				return false;
			},
			...defaultOptions,
			...options,
			queryKey: getQueryKey?.(params) || generateQueryKey(methodName, params),
			queryFn: () => (enterprise as any)[methodName](params),
		});
	};

	return {
		useApiHook,
		MethodSignature: {} as MethodSignature,
		MethodResult: {} as MethodResult,
		UseApiOptions: {} as UseApiOptions,
	};
};
