export const soldProducts = {
	label: "Total de produtos vendidos",
	sheetFileName: "total-produtos-vendidos",
	since: "De",
	until: "Até",
	downloadAllSales: "Baixar todas as vendas",
	loading: "Gerando planilha",
	filter: {
		label: "Filtrar por tipo de transação",
		placeholder: "Todas",
		all: "Todos",
		balcony: "Balcão",
		cashless: "Cashless",
		dashboard: "Dashboard",
		delivery: "Delivery",
		ficha: "Ficha",
		grabngo: "Grab n Go",
		onlineBill: "Comanda Online",
		zigQrCode: "QR Code Zig",
		onlineFicha: "Ficha Digital",
	},
	columns: {
		fiscalCode: "SKU",
		name: "Nome",
		mountable: "Montável",
		category: "Categoria",
		unitaryCount: "Quantidade Unit.",
		count: "Quantidade",
		variable: "Variável",
		devolution: "Devolução",
		variableTooltip:
			"O valor unitário de produtos com valor variável é a média dos valores vendidos",
		fracionalCount: {
			label: "Quantidade Frac.",
			grams: "gramas",
			kg: "Kilos",
			lt: "Litros",
		},
		unitaryValue: "Valor unitário",
		subtotal: "Subtotal",
		discount: "Descontos",
		totalValue: "Valor total",
		grossTotalValue: "Total bruto",
		tax_percentage: "Imposto %",
		tax_money: "Imposto {{currencySymbol}}",
		net_sales: "Total liquido",
		operationType: "Operação",
	},
	total: "TOTAL",
	totalItem: "Total",
	mountableModal: {
		name: "Nome",
		section: "Sessão Montável",
		count: "Quantidade",
	},
	mountables: {
		label: "Montáveis",
		fiscalCode: "SKU",
		product: "Produto",
		category: "Categoria Produto",
		section: "Etapa Montável",
		name: "Item Montável",
		count: "Quantidade",
	},
	zigCard: "Zig Card",
	zigTable: "Zig Mesa",
	selectDate: "Selecione a data",
	selectTime: "Selecione o horário",
};
