import React from "react";
import { Typography } from "../typography";
import { Button, Input, RadioButton, Select } from "#components/primitive";
import s from "./header.scss";
import { DatePicker } from "#components/date-picker";
import Icon from "#components/icon";
import { IDateVisualizationType, IHeaderProps } from "./utils";
import { useTranslation } from "react-i18next";

const { RangePicker } = DatePicker;

export function Header({
	title,
	filters,
	description,
	buttonActions,
	dateProps,
	rangePickerWrapper,
	hasButtonAndFilterSameLine,
}: IHeaderProps) {
	const { t } = useTranslation("components", { keyPrefix: "header" });
	const [
		dateVisualization,
		setDateVisualization,
	] = React.useState<IDateVisualizationType>(dateProps?.radioOptionStarter ?? "day");

	if (filters && filters.length > 4) {
		throw new Error(t("filterError"));
	}

	const RadioButtonsComponents = () => (
		<div className={s.radioButtons}>
			<RadioButton
				label={t("perDay")}
				checked={dateVisualization === "day"}
				value="day"
				onChange={onHandleRadioChange}
			/>
			<RadioButton
				label={t("byPeriod")}
				checked={dateVisualization === "period"}
				value="period"
				onChange={onHandleRadioChange}
			/>
		</div>
	);

	function RangePickerComponent() {
		return !dateProps?.hasDateVisualizationChange ? (
			<RangePicker className={s.rangePicker} size="large" {...dateProps?.rangePicker} />
		) : null;
	}

	function ButtonsComponent() {
		return buttonActions ? (
			<div className={s.buttons}>
				{buttonActions.extra}
				{buttonActions.primary && (
					<Button variant="primary" {...buttonActions.primary}>
						{buttonActions.primary.label}
					</Button>
				)}
			</div>
		) : null;
	}

	function DatePickerContainer() {
		return dateProps?.hasDateVisualizationChange ? (
			<>
				<RadioButtonsComponents />
				{dateVisualization === "day" ? (
					<DatePicker className={s.rangePicker} size="large" {...dateProps?.datePicker} />
				) : (
					<RangePickerComponent />
				)}
			</>
		) : null;
	}

	const onHandleRadioChange = (event: React.ChangeEvent<HTMLInputElement>) =>
		setDateVisualization(event.target.value as IDateVisualizationType);

	const DatePickerWrapper = rangePickerWrapper ?? React.Fragment;

	return (
		<div className={s.headerWrapper}>
			<div className={s.firstLine}>
				<div className={s.title}>
					<Typography type="heading" variant="v2">
						{title}
					</Typography>
					<Typography type="paragraph" variant="v3" className={s.description}>
						{description}
					</Typography>
				</div>
				<div className={s.actions}>
					<DatePickerContainer />
					{dateProps?.rangePicker && !filters && <RangePickerComponent />}
					{(title || description) && !hasButtonAndFilterSameLine && <ButtonsComponent />}
				</div>
			</div>
			<div className={s.secondLine}>
				<div className={s.filters}>
					{filters &&
						filters.map((field, index) => {
							const Wrapper = field.fieldWrapper ?? React.Fragment;

							switch (field.fieldType) {
								case "input":
									return (
										<Wrapper>
											<Input
												leadingIcon={<Icon icon="search-dark" />}
												size="large"
												key={`${field.fieldType}-${index}`}
												{...field.fieldProps}
											/>
										</Wrapper>
									);
								case "select":
									return (
										<Wrapper>
											<Select
												size="large"
												key={`${field.fieldType}-${index}`}
												{...field.fieldProps}
											>
												{field.fieldProps.options?.map(option => (
													<Select.Option key={option.value} {...option}>
														{option.label}
													</Select.Option>
												))}
											</Select>
										</Wrapper>
									);
							}
						})}
				</div>

				{dateProps?.rangePicker && filters && (
					<DatePickerWrapper>
						<RangePickerComponent />
					</DatePickerWrapper>
				)}
				{(!title && !description) || hasButtonAndFilterSameLine ? (
					<ButtonsComponent />
				) : null}
			</div>
		</div>
	);
}
