export const personalizationSettings = {
	personalizationSettings: "Configure e personalize seus cardápios",
	description:
		"Aqui você irá personalizar a parte visual e configurar informações a serem exibidas nos cardápios. A personalização é única e se aplicará a todos os cardápios que você tem.",
	yourActiveProducts: "Seus produtos ativos",
	onlinePostPayment: "Pague na mesa",
	delivery: "Delivery",
	dineIn: "Pedido na mesa",
	onlineTab: "Comanda online",
	viewMenu: "Cardápio digital",
	title: "Título",
	placeName: "Nome do local",
	logo: "Logo",
	coverImg: "Imagem de capa",
	assignedImgInYourMenu: "A imagem atribuída aparecerá no seu menu.",
	infoImage:
		"Caso você não adicione uma imagem, o header do seu produto será a logo e nome do local.",
	recommendations: "Recomendações",
	highQualityImg:
		"Recomendamos que seu logo e sua imagem de capa estejam com alta qualidade para melhor aplicação.",
	imgs: "Imagens",
	primaryColor: "Cor de destaque",
	colors: "Cores",
	contrastColor: "Cor de contraste",
	secondaryColor: "Cor secundária",
	saveCustomization: "Salvar personalização",
	serviceInformation: "Informações de atendimento",
	deliverySettings: "Configurações de delivery",
	pickUpAddress: "Endereço para retirada",
	generateQrCode: "Gerar QR Code",
	state: "Estado",
	select: "Selecione",
	city: "Cidade",
	enterOrSelect: "Digite ou selecione",
	neighborhood: "Bairro",
	neighborhoodName: "Digite o nome do bairro",
	address: "Endereço",
	street: "Nome da rua ou avenida",
	number: "Número",
	complement: "Complemento",
	typeOfFee: "Tipo de taxa",
	currentHold: "Espera atual",
	holdPlaceholder: "15 minutos",
	baseValue: "Valor base",
	valuePerkm: "Valor por Km",
	visible: "Deixar visível",
	averageTime: "Tempo médio de espera",
	status: "Status (aberto ou fechado)",
	copyOrTypeLink: "Cole ou digite aqui o link",
	parameter: "Parâmetro",
	searchParameter: "Buscar parâmetro",
	parameterValue: "Valor do parâmetro",
	value: "Valor",
	newQrCode: "Novo QR Code",
	importQrCode: "Importar QR Code",
	downloadQrCode: "Baixar QR Code",
	uniqueFee: "Única",
	basePlusKm: "Base + Variável por Km",
	perRatio: "Taxa por raio",
	addMoreFee: "Adicionar mais uma taxa",
	fromTo: "De {{beginning}} to {{end}}",
	time: "{{minutes}} minutos",
	chairNumber: "Número da cadeira",
	tableNumber: "Número da mesa",
	roomNumber: "Número do quarto",
	referencePoint: "Ponto de referência",
	savedSuccessfully: "Atualizações salvas com sucesso!",
	deliveryInfo: "Solicitar informações de entrega (opcional)",
	selectInfo: "Selecione a informação que você precisa",
	radiusUntil: "Raio até",
	complementExample: "Ex: Loja A",
	chair: "cadeira",
	table: "mesa",
	room: "quarto",
	reference: "referencia",
	menu: "Cardápio",
	selectProduct: "Selecione o produto",
	importSheet: "Importar planilha para gerar QR Codes",
	importQrCodes: "Importar QrCodes",
	somethingWentWrong: "Algo deu errado",
	couldNotImportSheet:
		"Não conseguimos importar sua planilha. Verifique se ela segue o modelo proposto ou peça ajuda ao nosso suporte.",
	importInstructions:
		"Após a importação, os QR Codes serão baixados automaticamente para seus	downloads.",
	configSlug: "Configure seu slug aqui",
	generateMultipleQrCode: "Gerar múltiplos QR code",
};
