export const lossReport = {
	label: "Perdas",
	title: "Relatório de Perdas",
	period: "Período",
	emptyText: "Nenhum dado foi encontrado",
	add: "Adicionar Perda",
	export: "Download em Excel",
	description:
		"O relatório de perdas agrupa todas as movimentações de estoque marcadas como perdas. Dessa forma, o administrador poderá ter uma visão geral dos produtos que foram perdidos.",
	periodInfo: "Exibindo relatório de {{since}} até {{until}}",
	columns: {
		sku: "SKU",
		product: "Produto",
		inventory: "Estoque",
		refund: "Perda de estorno",
		inventoryLoss: "Perda de inventário",
		manual: "Perda manual",
		subtotal: "Subtotal",
		production: "Perda de produção",
		total: "Perda total",
		totalLoss: "Valor total da perda",
	},
	sheet: {
		title: "Relatório de perda",
		fileName: "relatorio-perda",
		sku: "SKU",
		product: "Produto",
		inventory: "Estoque",
		refund: "Perda de estorno",
		inventoryLoss: "Perda de inventário",
		manual: "Perda manual",
		subtotal: "Subtotal",
		production: "Perda de produção",
		basicStorageUnit: "Unidade",
		total: "Perda total",
		totalLoss: "Valor total da perda",
	},
	addModal: {
		loading: "Carregando...",
		storageSelect: "Selecionar estoque",
		productSelect: "Selecionar produto",
		none: "Nenhum produto encontrado",
		lossQuantity: "Quantidade perdida",
		count: "Quantidade",
		date: "Data",
		other: "Adicionar outro produto",
	},
	validations: {
		validStorage: "Selecione um estoque válido",
		validProduct: "Selecione um produto válido",
		validQuantity: "Insira uma quantidade válida",
		validDate: "Selecione uma data válida",
	},
};
