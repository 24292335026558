export const productFeatureTemplates = {
	title: "Modelos de funcionalidade de produto",
	activeTab: "product-feature-templates",
	tooltip: "Crie modelos com campos personalizados para o seu produto",
	form: {
		isRequired: "Esse campo é obrigatório",
		templateName: {
			label: "Nome do modelo",
			placeholder: "Digite o nome do modelo",
		},
		templateObservation: {
			label: "Observação",
			placeholder: "Digite alguma observação (opcional)",
		},
		templateFieldsContainer: {
			label: "Campos do modelo",
			add: "Adicionar campo",
			field: "Campo {{number}}",
			type: {
				label: "Tipo do campo {{number}}",
				placeholder: "Selecione o tipo do campo",
				text: "Texto",
				number: "Numeral",
				bool: "Verdadeiro/Falso",
				enum: "Opções",
			},
			name: {
				label: "Nome do campo {{number}}",
				placeholder: "Digite o nome do campo {{number}}",
			},
			isRequired: "Campo {{number}} é obrigatório?",
			remove: "Remover campo",
			value: {
				label: "Opções do campo {{number}}",
				placeholder: "Valor {{count}} do campo {{number}}",
			},
		},
		editModel: "Editar modelo",
		createModel: "Criar modelo",
		addProduct: {
			isRequired: "Esse campo é obrigatório",
			fields: "Preencha pelo menos um campo",
			wrongFields: {
				label: "Preencha todos os dados",
				of: "do",
				from: "dos",
				field: "campo",
			},
		},
	},
	header: {
		add: "Adicionar modelo",
	},
	modal: {
		edit: "Editar",
		add: "Adicionar",
		title: "modelo de funcionalidade de produto",
	},
	templateList: {
		status: {
			title:
				"Deseja realmente desativar o modelo? Todas as funcionalidades que o usam serão desativadas também.",
		},
		actions: {
			title:
				"Deseja realmente remover o modelo? Todas as funcionalidades que o usam serão desativadas também.",
			edit: "Editar modelo",
			remove: "Remover modelo",
		},
	},
};
