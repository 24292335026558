export const tabTaxGroups = {
	addNewTaxGroup: "Add new group",
	emptyTaxGroupsMessage: "There are no product tax groups to show",
	infosTaxGroup: {
		aliquot: "Aliquot",
		cfop: "CFOP",
		tax: "Tax",
		type: "Type",
	},
	searchPlaceholder: "Search product tax groups",
	typeLabel: {
		invoice: "Invoice",
		service: "Service",
		vat: "Value Added Tax",
	},
};
