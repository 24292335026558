import * as React from "react";
import FilledButton from "#components/form-components-shared/buttons/filled-button";
import MainLogo from "#components/logos/main-logo";
import { isMain, isProductionBuild } from "#configuration/config";

import NotUsualState from "#components/not-usual-state";
import * as Sentry from "@sentry/react";
import s from "./style.scss";
import { withTranslation, WithTranslation } from "react-i18next";
import { SdkgenError } from "@sdkgen/browser-runtime";

type Props = WithTranslation<["components", "globalKeys"]>;

interface State {
	errored?: boolean;
	errorCode?: string;
}

class ErrorBoundary extends React.Component<Props, State> {
	public state: State = {};

	public componentDidCatch(error: Error, errorInfo: React.ErrorInfo) {
		let errorCode = undefined;
		if (isMain && isProductionBuild) {
			Sentry.captureException(error, {
				extra: {
					componentStack: errorInfo.componentStack,
					errorType: error instanceof SdkgenError ? error.type : undefined,
				},
			});
		}

		this.setState({ errored: true, errorCode });
	}

	private reloadPage = () => {
		window.location.reload();
	};

	private clearSiteData = () => {
		window.localStorage.clear();
		window.location.reload();
	};

	public render() {
		const { errorCode, errored } = this.state;
		const t = this.props.t;

		if (errored) {
			return (
				<div className={s.appError}>
					<Sentry.ErrorBoundary>
						<NotUsualState
							icon={<MainLogo />}
							title={t("components:errorBoundary.title")}
							subtitle={
								errorCode && (
									<>
										{t("components:errorBoundary.subtitle")}: {errorCode}
									</>
								)
							}
							description={`${t("components:errorBoundary.description")} ${
								errorCode ? t("components:errorBoundary.descriptionWithError") : ""
							}`}
							action={
								<div style={{ display: "flex" }}>
									<FilledButton onClick={this.reloadPage} style={{ margin: 5 }}>
										{t("globalKeys:reload")}
									</FilledButton>
									<FilledButton
										onClick={this.clearSiteData}
										style={{ margin: 5 }}
										theme="danger"
									>
										{t("globalKeys:leave")}
									</FilledButton>
								</div>
							}
						/>
					</Sentry.ErrorBoundary>
				</div>
			);
		}

		return this.props.children;
	}
}

export default withTranslation(["components", "globalKeys"])(ErrorBoundary);
