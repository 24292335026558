import {
	SimpleTableBody,
	SimpleTableEmptyState,
	SimpleTableFooter,
	SimpleTableHead,
	SimpleTableLabelDetail,
	SimpleTableLoader,
	SimpleTableRoot,
} from "./simple-table";

export const SimpleTable = {
	Root: SimpleTableRoot,
	Head: SimpleTableHead,
	Body: SimpleTableBody,
	EmptyState: SimpleTableEmptyState,
	Footer: SimpleTableFooter,
	LabelDetail: SimpleTableLabelDetail,
	Loader: SimpleTableLoader,
};
