export const withdrawRequestDrawer = {
	title: "Solicitar saque ou pagamento de boleto",
	titleWithdraw: "Solicitar saque",
	titleBankslip: "Pagamento de boleto",
	titlePendingBankslip: "Pagamento de boleto Zig",
	pendingBankslip: "Boleto pendente",
	warning:
		"Caso o valor solicitado ultrapasse o saldo disponível para saque, o mesmo será inteirado com o valor disponível para antecipação, no qual incidirá a taxa de antecipação.",
	withdrawOption: "Saque",
	anticipationOption: "Antecipação",
	bankslipPaymentOption: "Pagamento de boleto",
	bankSlid: {
		bankSlipValue: "Valor do boleto",
		bankSlipInfo: "Informações do boleto",
		dataReview: "Revisão dos dados",
		form: {
			liquidValue: "Valor líquido",
			liquidValuePlaceholder: "Digite aqui o valor líquido",
			beneficiary: "Beneficiário",
			barCode: "Número do código de barras",
			dueDate: "Vencimento",
			attachment: "Selecionar arquivo do boleto",
			valuesReview: "Revisão dos valores",
			bankSlipValue: "Valor do boleto",
			available: "Saldo disponível para saque",
			valueToBeAnticipated: "Valor líquido a antecipar",
			anticipationTax: "Taxa de antecipação",
			anticipationValue: "Valor bruto a antecipar",
			liquidAmmountRequested: "Valor líquido solicitado",
			thidPartyWithdrawFee: "Taxa de saque para terceiros",
			totalValue: "Valor bruto total",
			bankSlipInfo: "Informações do boleto",
			calculating: "Calculando...",
		},
		transactionDetails: {
			title: "Detalhamento da transação",
			paymentValue: "Valor do pagamento",
			available: "Valor de saque",
			valueToBeAnticipated: "Valor líquido de antecipação",
			taxValue: "Taxa de antecipação",
			totalValue: "Valor total",
			calculating: "Calculando...",
		},
	},
	withdraw: {
		withdrawValue: "Valor do saque",
		accountInfo: "Informações da conta",
		dataReview: "Revisão dos dados",
		warning:
			"Solicitações realizadas após às 10h30 (horário de Brasília) só serão atendidas no dia seguinte.",
		warningAccountChoice:
			"Será cobrado uma taxa de {{value}} para saque em contas de terceiros.",
		errorMessage: "Não foi possível concluir a solicitação.",
		successMessage: "Saque solicitado com sucesso!",
		liquidValue: "Valor líquido",
		liquidValuePlaceholder: "Digite aqui o valor líquido",
		reason: "Motivo da solicitação",
		reasonPlaceholder: "Digite aqui o motivo para o saque",
		account: "Conta",
		accountPlaceholder: "Digite ou selecione a conta",
	},
	titleTransfer: "Transferir saldo entre places",
	transferOption: "Transferência entre places",
	bankslipValue: "Valor do boleto",
	expireDate: "Vencimento",
	downloadBankslipPdf: "Baixar boleto em PDF",
};
