export const clientArea = {
	label: "Customer Area",
	places: "Places",
	org: "Organization",
	searchPanel: "Panel name",
	noPanels: "No panels available.",
	noPanelsForFilter: "No panel corresponding to applied filters",
	autoRefresh: "Automatic Update.",
	fullScreen: "Full Screen",
	autoUpdatesOn: "Automatic updates turned on",
	autoUpdatesOff: "Automatic updates turned off",
	rotateDevice: "Please rotate your device",
};
