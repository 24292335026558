export const contracts = {
	solicitation: {
		columns: {
			id: "Id do Ticket",
			requestBy: "Nome do solicitante",
			requestDate: "Data da solicitação",
			requestReason: "Motivo da solicitação",
			requestType: "Tipo de solicitação",
			returnDate: "Data de retorno",
			status: "Status",
		},
		filter: {
			periodTimeLabel: "Data da solicitação",
			statusLabel: "Status",
			statusPlaceholder: "Selecione o status da solicitação",
		},
		incident: "Incidente",
		status: {
			closed: "Finalizado",
			hold: "Aguardando",
			open: "Aberto",
			new: "Novo",
			pending: "Pendente",
			solved: "Resolvido",
		},
		title: "Solicitações",
	},
	history: {
		title: "Histórico de contratos",
		filename: "historico_de_contratos",
		status: {
			approved: "Aprovado",
			pendingApproval: "Aprovação pendente",
			pendingSign: "Assinatura pendente",
		},
		columns: {
			requestDate: "Data da solicitação",
			signatureDate: "Data da assinatura",
			contract: "Contrato",
			managerName: "Responsável comercial",
			signedBy: "Responsável pela assinatura",
			status: "Status",
		},
		filter: {
			periodTimeLabel: "Data da solicitação",
			statusLabel: "Status",
			statusPlaceholder: "Selecione o status da solicitação",
		},
		details: {
			title: "Solicitação feita no modelo",
			temporary: "temporário",
			permanent: "permanente",
			contractNotFound: "Contrato não encontrado",
			equipaments: {
				equipament: "Equipamento",
				quantity: "Quantidade",
				unitValue: "Valor unitário",
				totalValue: "Valor Total",
			},
			manager: {
				managerName: "Responsável ZIG",
				managerContact: "Canal de contato",
			},
			file: {
				type: "Tipo de solicitação",
				requestModel: "Modelo de solicitação",
				contractPeriod: "Tempo do contrato",
			},
		},
		actionsTable: {
			details: "Ver detalhes da solicitação",
			downloadDocuments: "Baixar documentos",
		},
	},
};
