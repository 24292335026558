export const stepperInventoryControl = {
	steps: {
		config: "Configuration",
		count: "Listing and counting",
		adjustLoss: "Adjustments and osses ",
		resumeInventory: "Inventory Summary",
		approval: "Approval",
	},
	stepsDescription: {
		count: "Save the inventory during the filling to not lose your progress",
		resumeInventory: "Review the counted items before sending",
	},
	fieldRequired: "Required field",
};
