export const chartOfAccounts = {
	titlePage: "Chart of Accounts",
	tableTitle: {
		revenues: "Revenues",
		costs: "Costs",
		expenses: "Expenses",
	},
	tableRows: {
		minimizeAll: "Minimize",
		expandAll: "Expand",
	},
	expandLevels: "Expand levels",
	categoryFixedInfo: "Fixed categories cannot be edited or deleted",
	viewCode: "View chart of accounts code",
	usageInfo:
		"The financial Chart of Accounts is the list of revenue and expense categories for a company. As a way to classify financial transactions, it helps organize and better understand the financial situation by looking at income and expenses.",

	titleModal: {
		edit: "Rename account",
		create: "Add account",
		exclude: "Delete chart of accounts",
		warning: "This chart of accounts has links",
	},
	titleActions: {
		edit: "Edit Button",
		create: "Create Button",
		exclude: "Delete Button",
	},
	modalForm: {
		field: {
			nameChart: { label: "Account name", placeholder: "Enter the account name" },
		},
		errors: {
			requiredField: "Required field",
			characterLimitExceeded: "70 character limit exceeded",
		},
		status: {
			successEdit: "Category edited successfully",
			successCreate: "Category created successfully",
			successExclude: "Category deleted successfully",
		},
		content: {
			create:
				"This account will be added at level {{level}}, code {{code}}, as a subcategory of {{typeCategory}} in the category {{name}}. How would you like to name it?",
			edit: "What name would you like to give to this account?",
		},
	},
	modalExcludeChart: {
		warningContent:
			"This chart of accounts <strong>cannot be deleted</strong> as there are transactions linked to it.",
		excludeContent:
			'By confirming the action, you will permanently delete the chart of accounts for "{{name}}". Do you want to continue?',
		buttonExclude: "Yes, I want to delete the plan",
	},
};
