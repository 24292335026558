export const accounts = {
	title: "Contas Salvas",
	saveNewAccountButton: "Salvar nova conta",
	filterInputPlaceholder: "Filtrar contas salvas",
	table: {
		holder: "Titular da conta",
		holderTooltip: "Razão social ou nome do proprietário da conta",
		name: "Identificação",
		pixKey: "Chave Pix",
		agency: "Agência",
		account: "Conta",
		accountType: "Tipo de conta",
		deletePopconfirm: 'Tem certeza que deseja remover a conta "{{name}}"?',
		withdraw: "Sacar",
		anticipate: "Antecipar",
	},
	drawerTitle: "Salvar conta",
	addNewAccount: "Adicionar nova conta",
	form: {
		holder: "Razão Social/Nome do proprietário",
		holderPlaceholder: "Digite aqui a razão social/nome do proprietário",
		name: "Identificação da conta",
		nameTooltip: "Titulo pelo qual você identificará a conta",
		namePlaceholder: "Digite aqui o nome da conta",
		bank: "Banco",
		bankPlaceholder: "Selecione aqui o banco ou digite para procurar",
		documentType: "Tipo de documento",
		cnpj: "CNPJ",
		cpf: "CPF",
		none: "Nenhum",
		documentNumber: "CPF/CNPJ",
		documentNumberPlaceholder: "Digite aqui o documento do titular da conta",
		agency: "Agência",
		agencyPlaceholder: "Digite aqui a agência (sem dígito)",
		agencyWithDigitPlaceholder: "Digite aqui a agência (com dígito)",
		agencyDigitPlaceholder: "Digite aqui o dígito da agência",
		agencyDigit: "Dígito",
		account: "Conta",
		accountDigit: "Dígito",
		accountPlaceholder: "Digite aqui a conta (sem dígito)",
		accountDigitPlaceholder: "Digite aqui o dígito da conta",
		accountType: "Tipo de conta",
		noneAccount: "Nenhuma",
		contaCorrente: "Corrente",
		contaPoupanca: "Poupança",
		contaSalario: "Salário",
		pixKey: "Chave PIX",
		pixKeyPlaceholder: "Digite aqui a chave PIX da conta",
		selectAccountPlaceholder: "Digite ou selecione a conta",
		accountDataType: "Dados para cadastrar",
		bankAccount: "Dados bancários",
		pix: "PIX",
		saveAccountFuture: "Salvar conta para transações futuras",
	},
	formErrors: {
		holder: "Digite o nome do titular da conta",
		name: "Digite o nome para identificar a conta",
		bank: "Selecione o banco ao qual a conta pertence",
		documentNumber: "Digite o documento do titular da conta (CPF/CNPJ)",
		documentNumberInvalid: "Documento inválido",
		agency: "Digite o número da agência",
		agencyInvalid: "Agência inválida",
		account: "Digite o número da conta",
		accountDigit: "Digite o dígito da conta",
		accountType: "Selecione o tipo da conta",
		pixKeyInvalid: "Chave PIX inválida",
		receiverDataWrapper: "Preencha os dados bancários ou chave PIX",
	},
	accountTypes: {
		checking: "Conta Corrente",
		savings: "Conta Poupança",
		salary: "Conta Salário",
	},
};
