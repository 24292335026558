import { fromPromise, IPromiseBasedObservable } from "#helpers/mobx-utils";
import {
	NewReserve,
	NewReserveTemplate,
	Reserve,
	ReserveReportDetails,
	ReserveReportRow,
	ReserveTemplate,
} from "#api/enterprise-generated";
import { action, observable } from "mobx";
import { showErrorNotification, showSuccessNotification } from "#helpers/notifications";
import { RootStore } from ".";
import enterprise from "#api/enterprise-client";
import { fetchModel } from "#helpers/fetch-model";
import dayjs from "dayjs";
import i18n from "#i18n/index";

function middleDay(date: Date) {
	return dayjs(date).hour(12).toDate();
}

const t = i18n.t;

export class ReserveStore {
	public rootStore: RootStore;

	constructor(rootStore: RootStore) {
		this.rootStore = rootStore;
	}

	@observable
	public addReservePromise: IPromiseBasedObservable<void> | null = null;

	@observable
	public reserves: Reserve[] = [];

	@action
	public getReserves = async () => {
		try {
			this.reserves = await enterprise.getReserves({
				eventId: this.rootStore.eventStore.event!.id,
			});
		} catch (error) {
			if (error instanceof Error) {
				showErrorNotification(error.message);
			}
		}
	};

	@action
	public getReserveDetail(reserveId: string) {
		return enterprise.getReserveDetail({ reserveId });
	}

	@action
	public addReserve = async (eventId: string, reserve: NewReserve) => {
		this.addReservePromise = fromPromise(
			enterprise
				.createReserve({ eventId, reserve })
				.then(_ => {
					showSuccessNotification(t("store:reserveStore.addReserve"));
					this.getReserves();
				})
				.catch(err => {
					if (err instanceof Error) {
						showErrorNotification(err.message);
					}
				}),
		);
		return this.addReservePromise;
	};

	@action
	public editReserve = async (reserveId: string, reserve: NewReserve) => {
		try {
			await enterprise.updateReserve({ reserveId, reserve });
			showSuccessNotification(t("store:reserveStore.editReserve"));
			await this.getReserves();
		} catch (error) {
			if (error instanceof Error) {
				showErrorNotification(error.message);
			}
		}
	};

	@action
	public deleteReserve = async (reserveId: string) => {
		this.addReservePromise = fromPromise(
			enterprise
				.deleteReserve({ reserveId })
				.then(() => {
					showSuccessNotification(t("store:reserveStore.deleteReserve"));
					this.getReserves();
				})
				.catch(err => {
					if (err instanceof Error) {
						showErrorNotification(err.message);
					}
				}),
		);
	};

	public reserveReportAtEvent = new fetchModel<{ eventId: string }, ReserveReportRow[]>({
		fnPromise: ({ eventId }) => enterprise.getReserveReportAtEvent({ eventId }),
		onError: err => showErrorNotification(err.message),
	});

	public reserveReportAtPlace = new fetchModel<
		{ placeId: string; since: Date; until: Date },
		ReserveReportRow[]
	>({
		fnPromise: ({ placeId, since, until }) =>
			enterprise.getReserveReportAtPlace({
				placeId,
				since: middleDay(since),
				until: middleDay(until),
			}),
		onError: err => showErrorNotification(err.message),
	});

	public reserveReportDetails = new fetchModel<
		{ reserveId: string },
		ReserveReportDetails
	>({
		fnPromise: ({ reserveId }) => enterprise.getReserveReportDetail({ reserveId }),
		onError: err => showErrorNotification(err.message),
	});

	public createReserveTemplate = new fetchModel<NewReserveTemplate, string>({
		fnPromise: props => enterprise.createReserveTemplate({ reserveTemplate: props }),
		onSuccess: () => {
			showSuccessNotification(t("store:reserveStore.createReserveTemplate"));
		},
		onError: err => showErrorNotification(err.message),
	});

	public getReserveTemplate = new fetchModel<{ placeId: string }, ReserveTemplate[]>({
		fnPromise: ({ placeId }) =>
			enterprise.getReserveTemplates({
				placeId,
				pagination: { currentPage: 1, perPage: 100 },
			}),
		onError: err => showErrorNotification(err.message),
	});

	public removeReserveTemplate = new fetchModel<{ reserveTemplateId: string }, void>({
		fnPromise: ({ reserveTemplateId }) =>
			enterprise.deleteReserveTemplate({ reserveTemplateId }),
		onError: err => showErrorNotification(err.message),
	});
}
