import React from "react";
import dayjs from "dayjs";
import { useTranslation } from "react-i18next";
import { Divider } from "antd";

import LevelIcon from "#assets/svg/levels.svg";
import { DashboardNotificationList } from "#resources/api/enterprise-generated";
import { TagLabel, Typography } from "#components/primitive";
import {
	CLASSIFICATION_LABEL,
	CLASSIFICATION_TAG_TYPE,
	LEVEL_LABEL,
} from "#components/notifications/types";

import s from "../../notifications-content.scss";

interface INotificationsContentInfoProps {
	notification: DashboardNotificationList;
}

export function NotificationsContentInfo({
	notification,
}: INotificationsContentInfoProps) {
	const { t } = useTranslation("components", {
		keyPrefix: "notifications",
	});
	const { t: tGlobayKeys } = useTranslation("globalKeys");

	const URL_REGEX = /(?:https?:\/\/)?(?:www\.)?[a-zA-Z0-9-]+\.[a-zA-Z]{2,}(?:\/\S*)?/g;

	function formatDateTime(inputDate: Date): string {
		const now = dayjs();
		const dateToFormat = dayjs(inputDate);

		if (now.isSame(dateToFormat, "day")) {
			return dateToFormat.format("HH:mm");
		} else if (now.subtract(1, "day").isSame(dateToFormat, "day")) {
			return tGlobayKeys("yesterday");
		} else {
			return dateToFormat.format("DD-MM");
		}
	}

	function formatDescriptionWithUrl(description: string) {
		const lines = description.split("\n");

		const formattedLines = lines.map((line, lineIndex) => {
			const words = line.split(" ");

			const formattedWords = words.map((word, wordIndex) => {
				const isLastWord = wordIndex === words.length - 1;
				const trimmedWord = isLastWord ? word.trim() : word;

				if (trimmedWord.match(URL_REGEX)) {
					const href = trimmedWord.startsWith("http")
						? trimmedWord
						: `http://${trimmedWord}`;
					return (
						<span key={wordIndex}>
							<a
								href={href}
								target="_blank"
								rel="noopener noreferrer"
								className={s.linkText}
							>
								{trimmedWord}
							</a>{" "}
						</span>
					);
				} else {
					return (
						<span key={wordIndex}>
							{trimmedWord}
							{!isLastWord && " "}
						</span>
					);
				}
			});

			return (
				<Typography
					key={lineIndex}
					type="paragraph"
					variant="v1"
					className={s.infoTextTitle}
				>
					{formattedWords}
				</Typography>
			);
		});

		return formattedLines;
	}

	return (
		<>
			<div className={s.infoContainer}>
				<div className={s.infoHeader}>
					<TagLabel
						type={CLASSIFICATION_TAG_TYPE[notification.classification]}
						className={s.tag}
					>
						{CLASSIFICATION_LABEL[notification.classification]}
					</TagLabel>
					<Typography type="paragraph" variant="v4" className={s.createdAt}>
						{formatDateTime(notification.createdAt)}
					</Typography>
				</div>
				<div className={s.infoText}>
					<Typography type="paragraph" variant="v2" className={s.infoTextTitle}>
						{notification.title}
					</Typography>
					<div className={s.descriptionContainer}>
						{formatDescriptionWithUrl(notification.description)}
					</div>
				</div>
				{notification.tags && (
					<div className={s.infoLevel}>
						<LevelIcon />
						<Typography type="paragraph" variant="v4" className={s.infoLevelText}>
							{t("levelTag", {
								tag: notification.tags
									.map(notification => LEVEL_LABEL[notification])
									.join(" / "),
							}).toUpperCase()}
						</Typography>
					</div>
				)}
			</div>
			<Divider />
		</>
	);
}
