export const editMenu = {
	menuActive: "Ativar cardápio",
	goBack: "Voltar para todos os cardápios",
	deleteConfirm: "Tem certeza que deseja apagar o menu?",
	okText: "Sim, eu quero!",
	warningNotification:
		"Menu editado, porem houve problema no cadastro do horario de funcionamento:",
	errorProductIdentify:
		"não foi possivel intentificar o produto corespondente de {{name}}, baixe uma nova planilha modelo e tenha cuidado com a coluna 'indentificador'",
	successNotification: "As informações de todos produtos foram editadas com sucesso",
	errorEditNotification: "Erro em ",
	updatedPrices: "Preços atualizados",
	search: "Pesquisar produtos",
	massEdit: "Editar produtos em massa",
	deleteProducts: "Remover produtos",
	deleteProductsConfirm:
		"Tem certeza que deseja remover todos os produtos selecionados do menu?",
	massDelete: "Remover produtos em massa",
	add: "Adicionar produto",
};
