export const entranceBilling = {
	tabs: {
		boxOfficeBilling: {
			title: "Faturamento de Bilheteria",
			tabLabel: "Faturamento",
		},
		entranceReport: {
			title: "Total de entradas vendidas",
			tabLabel: "Entradas vendidas",
			tabs: {
				total: "Total",
				clients: "Clientes por entrada",
			},
			columns: {
				name: "Nome",
				count: "Quantidade",
				unitValue: "Valor unitário",
				subtotal: "Subtotal",
				discountValue: "Descontos",
				totalValue: "Valor total",
			},
		},
	},
};
