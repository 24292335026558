export const employees = {
	filters: {
		sells: {
			label: "Vendas",
			shortName: "Vendas",
			quantity: "Quantidade de pedidos",
			total: "Valor total dos pedidos",
			average: "Média por funcionário",
			best: "Melhor perfomance",
			lower: "Menor perfomance",
		},
		financialOperations: {
			label: "Recebimentos financeiros",
			shortName: "Recebimentos",
			quantity: "Quantidade de operações",
			total: "Produtos vendidos",
			average: "Média por funcionário",
			best: "Melhor performance",
			lower: "Menor performance",
		},
		tips: {
			label: "Gorjetas",
			shortName: "Gorjetas",
			total: "Gorjeta total",
			percent: "% sobre a venda",
			average: "Média por funcionário",
			higher: "Maior valor",
			lower: "Menor valor",
		},
		refunds: {
			label: "Estornos",
			shortName: "Estornos",
			quantity: "Quantidade de estornos",
			total: "Valor total dos estornos",
		},
		sellRefunds: {
			label: "Quem causou o estorno",
			shortName: "Estorno",
		},
		checkins: {
			label: "Check-ins",
			shortName: "Check-ins",
			quantity: "Quantidade de Check-ins total",
			average: "Média por funcionário",
			higher: "Funcionário com mais Check-ins",
			lower: "Funcionário com menos Check-ins",
		},
	},
	userCard: {
		employeeName: "nome do funcionário",
		role: "Posição",
		ofSales: "das vendas",
	},
};
