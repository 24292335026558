export const technicalSheet = {
	title: "Technical Sheet",
	modalDelete: {
		title: "Delete technical sheet",
		text:
			"By confirming the action you permanently delete the technical sheet. Do you wish to continue?",
		okText: "Yes, I want to delete the technical sheet",
	},
	addInput: {
		columns: {
			inputName: "Input",
			count: "Count",
			unitCostValue: "Unit cost",
			totalCost: "Total cost",
			actions: "Actions",
		},
		warnings: {
			withoutInput: "Select an input",
			withoutQuantity: "Add a quantity",
			alreadyExist:
				"The input already exists in the list of inputs, please select a different input.",
		},
		inputName: "Input",
		count: "Count",
		loss: "Loss",
		value: "Cost price",
		price: "Price",
		add: "Add",
	},
	editInput: {
		inputName: "Input",
		count: "Count",
		loss: "Loss",
		value: "Cost price",
		price: "Price",
		delete: "Delete",
	},
	header: {
		addButton: "Add Input",
		editButton: "Technical Sheet Edit",
	},
	infoCard: {
		value: "Sale rice",
		totalCost: "Total cost",
		cmv: "CMV %",
		profitMargin: "Profit Margin",
	},
	table: {
		columns: {
			inputName: "Input",
			count: "Count",
			loss: "Loss",
			unitCostValue: "Unit cost",
			yieldPercentage: "Yield",
			unitCostWithYield: "Unit Cost With Yield",
			totalCost: "Total Cost",
			actions: "Actions",
		},
	},
};
