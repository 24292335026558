export const storages = {
	label: "Estoques",
	search: "Busque um estoque",
	newStorage: "Novo estoque",
	storageName: "Nome do estoque",
	removeStorage: "Excluir estoque",
	editStorage: "Editar estoque",
	createStorage: "Criar estoque",
	addNewStorage: "Adicionar novo estoque",
	zeroBackoffice: "Zerar todos os produtos em estoque",
	deleteAllProducts: "Remover todos os produtos",
	modalTransfer: "Transferir produtos de {{storage}}",
	confirmMessage: "Tem certeza que deseja apagar esse estoque?",
	transferResume: "Relatório de transferência",
	storageList: {
		central: "Geral",
		default: "Local",
		emptyMessage: "Sem itens para mostrar",
		emptyArea: "Sem estoques para exibir",
	},
	storageCard: {
		productSearch: "Busque por um produto",
		addProduct: "Adicionar produto",
		edit: "Editar estoque",
		transfer: "Transferir produtos",
		error: "Não é possível {{action}} produto com quantidade 0",
		modal: {
			addProducts: "Adicionar produtos",
			removeProducts: "Remover produtos",
			amount: "Digite a quantidade que você deseja",
			add: "adicionar e seu preço de custo unitário",
			count: "Quantidade",
			costValue: "Preço de custo",
			note: "Observação",
		},
		modalTransfer: {
			title: "Transferir {{name}}",
			okText: "Transferir",
			storage: "Estoque",
			quantity: "Quantidade",
			error: {
				selectStorage: "Selecione um estoque",
				selectCount: "Selecione uma quantidade",
			},
		},
	},
	addProductModal: {
		title: "Adicionar Produtos",
		manual: {
			label: "Manualmente",
			product: "Produto",
			quantity: "Quantidade",
			costValue: "Preço de custo",
			addOtherProduct: "Adicionar outro produto",
			error: {
				zeroCount: "Não é possível adicionar produto com quantidade 0",
				noneValidProduct: "Nenhum produto válido selecionado",
			},
		},
		xml: {
			label: "Importar XML",
			upload: "Clique ou arraste arquivos para fazer upload",
		},
		noteInfo: {
			error: "Algum problema com a nota ocorreu e não foi possível criar o Fornecedor",
			title: "Dados da Nota Fiscal",
			number: "Número da nota",
			series: "Série",
			operationType: "Natureza da Operação",
			accessKey: "Chave de acesso",
			unavailable: "Indisponível",
			supplierName: "Emitente",
			cpnj: "CNPJ",
			addSupplier: "Adicionar emitente aos seus favoritos",
			totalValue: "Valor total",
			removeBill: "Remover conta a pagar",
			addBill: "Adicionar conta a pagar",
			bill: "Conta",
			provider: "Fornecedor",
		},
		form: {
			successNotification: "Dados importados",
			addedProducts: "Produtos Adicionados",
			submit: "Finalizar importação",
		},
		product: {
			label: "Produto",
			code: "SKU",
			description: "Descrição do produto",
			commercialQuantity: "Qtd",
			commercialUnit: "Un",
			unitValue: "Preço unitário",
			equals: "equivale a",
			cost: "Preço de custo por",
			skip: "Pular",
		},
	},
	transferProductModal: {
		transferFor: {
			label: "Transferir para",
			local: "Estoque Local",
			central: "Estoque Geral",
			placeSelect: {
				label: "Local de destino",
				placeholder: "Selecione um local",
			},
		},
		destinyStorage: {
			label: "Estoque de destino",
			placeholder: "Selecione um estoque",
		},
		product: {
			label: "Produto",
			placeholder: "Selecione um produto",
		},
		quantity: "Quantidade",
		table: {
			product: "Produto",
			quantity: "Quantidade",
			unityCost: "Custo Unitário",
			totalCost: "Custo Total",
			empty: "Sem produtos selecionados",
		},
		error: {
			addProduct: "Produto não pode ser adicionado sem quantidade",
			selectProduct: "Quantidade em estoque negativa. Produto não pôde ser selecionado",
		},
		printTransfer: {
			title: "Comprovante de transferência",
			text:
				"Esse documento comprova a transferência de produtos do estoque {{fromStorage}} para o estoque {{toStorage}}. No dia {{date}} feita por {{employee}}. Os produtos transferidos estão na seguinte lista:",
			quantity: "Quantidade",
			withoutName: "Sem nome",
			responsible: "Responsável pelo estoque destino",
			successNotification: "Produtos associados e transferidos",
			notFound: "Não encontrado",
			sheet: {
				filename: "transferencia-estoque",
				title: "Transferência do estoque {{selectedStorage}} para {{toStorage}}",
				columns: {
					name: "Produto",
					count: "Quantidade",
					unit: "Unidade",
					unitaryCost: "Custo Unitário",
					totalCost: "Custo Total",
				},
			},
			infoText:
				"Transferência realizada com sucesso, veja abaixo o resumo dos produtos transferidos",
			none: "Nenhum",
			print: "Imprimir Doc. de transferências",
			download: "Baixar Excel de transferências",
			notTrasnfer: "Os seguintes produtos não puderam ser transferidos",
			unassociated: "Eles não estão associados ao local de destino",
			associate: "Clique aqui para associá-los",
		},
	},
	tableProducts: {
		columns: {
			name: "Produto",
			category: "Categoria",
			count: "Quantidade",
			actions: "Ações",
		},
		product: "Produto",
		quantity: "Quantidade",
		cost: "Preço de custo",
		add: "Adicionar produto",
		total: "no total",
		productError: "Produto não encontrado",
		countError: "Não é possível adicionar produto com quantidade 0",
	},
	deleteProduct: {
		tooltip: "Apagar produto",
		title: "Tem certeza que deseja apagar esse produto?",
	},
	error: "Erro ao atualizar estoques",
	modalImportInvoice: {
		errors: {
			notKeyAccess: "Não foi possível trazer a chave de acesso XML",
		},
		placeholders: {
			selectStorage: "Selecionar estoque",
		},
		labelInputs: {
			storage: "Estoque",
			xmlKey: "Chave XML",
		},
		typeOptionsImport: {
			xmlFile: "Anexar arquivo XML",
			xmlKeyAccess: "Inserir chave de acesso do XML",
		},
		validationMessage: {
			emptySelectTypeImport: "Selecione uma opção de importação",
			emptyStorage: "Selecione um estoque",
			emptyXmlKey: "Campo de Chave de Acesso vazio",
			emptyFileXml: "Arquivo XML não selecionado",
		},
	},
};
