export const totemActionsReport = {
	title: "Totem Actions",
	description: "List of all actions throughout the event.",
	filters: {
		totem: {
			label: "Totem",
			placeholder: "Enter or select the totem",
		},
		transaction: {
			label: "Transaction Type",
			placeholder: "Enter or select the type",
		},
		search: {
			label: "Search",
			placeholder: "Search by ID",
		},
	},
	columns: {
		date: "DATE AND TIME",
		transactionType: "TRANSACTION TYPE",
		totem: "TOTEM",
		user: "CUSTOMER",
		value: "VALUE",
		paymentMethod: "PAYMENT METHOD",
	},
	filename: "totem-actions-report",
};
