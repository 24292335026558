export const forms = {
	multiEntranceInputRelation: {
		removeEntrance: "Remover entrada",
		addEntrance: "Adicionar entrada",
	},
	placeComboProductInput: {
		filterProducts: "Filtrar produtos",
	},
	inputs: {
		searchPlaceholder: "Digite para pesquisar",
	},
	selects: {
		newFiscalModeOptions: {
			sat: "SAT-e",
			nfce: "NFC-e",
		},
		regimeTributarioOptions: {
			regimeNormal: "Regime Normal",
			simplesNacional: "Simples Nacional",
			simplesNacionalExcesso: "Simples Nacional Excesso",
		},
		states: {
			AC: "Acre",
			AL: "Alagoas",
			AM: "Amapá",
			BA: "Bahia",
			CE: "Ceará",
			DF: "Distrito Federal",
			ES: "Espírito Santo",
			GO: "Goiânia",
			MA: "Maranhão",
			MG: "Minas Gerais",
			MS: "Mato Grosso do Sul",
			MT: "Mato Grosso",
			PA: "Pará",
			PB: "Paraíba",
			PE: "Pernambuco",
			PI: "Piauí",
			PR: "Paraná",
			RJ: "Rio de Janeiro",
			RN: "Rio Grande do Norte",
			RO: "Rondônia",
			RR: "Roraima",
			RS: "Rio Grande do Sul",
			SC: "Santa Catarina",
			SE: "Sergipe",
			SP: "São Paulo",
			TO: "Tocantins",
		},
	},
	validations: {
		requiredField: "Esse campo é obrigatório",
		requiredFieldShort: "Campo obrigatório",
		invalidEmail: "Endereço de email inválido",
	},
	clientDetailsDialog: {
		editClientData: "Editar dados de cliente",
		name: "Nome",
		phone: "Telefone",
		email: "Email",
		birthDate: "Data de nascimento",
	},
	loginForm: {
		org: "Empresa",
		login: "Login",
		password: "Senha",
		submit: "Entrar",
	},
	paymentRequestForm: {
		alert:
			"Atenção! Solicitações realizadas após às 10h30m (horário de Brasília) só serão atendidas no dia seguinte.",
		alertAnticipation:
			"Atenção! Solicitações de Antecipação até às 18h00m (horário de Brasília) serão atendidas no dia útil seguinte. Após este período, as solicitações serão atendidas em dois dias úteis.",
		value: "Valor",
		reason: "Motivo",
		reasonPlaceholder: "Indique o motivo da transação",
		useAnotherBankAccount: "Utilizar conta bancária padrão?",
		bankData: "Dados bancários",
		bank: "Banco",
		bankPlaceholder: "Selecione um banco",
		document: "CPF ou CNPJ",
		documentPlaceholder: "Ex: 123456789",
		account: "Conta",
		accountPlaceholder: "Ex: 44440",
		agency: "Agência",
		agencyPlaceholder: "Ex: 5214",
		type: "Tipo",
		checking: "Conta corrente",
		savings: "Poupança",
		buttonSolicitation: "Realizar solicitação",
	},
};
