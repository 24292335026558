export const updateStatusMessage = {
	success: {
		approved: {
			title: "Inventory completed!",
			message: "Inventory completed",
		},
		in_review: {
			title: "Inventory sent for review!",
			message: "Inventory will be reviewed by the approver",
		},
	},
	error: {
		approved: {
			title: "Oops, something went wrong!",
			message: "Inventory not completed",
		},
		in_review: {
			title: "Oops, something went wrong!",
			message: "Inventory not sent for review",
		},
	},
	actions: {
		goHome: "Back to home page",
		backToResume: "Back to summary",
		createAnotherInventory: "Create another inventory",
	},
};
