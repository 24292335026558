import { closings } from "./closings";
import { extract } from "./extract";
import { debtorsReport } from "./debtors-report";

export const multivendor = {
	closings,
	extract,
	debtorsReport,
	productsSold: {
		title: "Produtos vendidos por vendor",
		activeTab: "product-sold-by-vendor",
		bonus: "Bônus por produto",
	},
	revenues: {
		title: "Faturamento por vendor",
		description:
			"Relatório de relação de faturamento e cada vendor cadastrado como parte do local.",
		period: "valores referentes aos períodos {{ since }} - {{ until }}",
		activeTab: "vendors-revenue",
		columns: {
			name: "Vendor",
			totalSoldValue: "Produtos vendidos",
			totalTipValue: "Serviço",
			subtotal: "Subtotal",
			rent: "Aluguel",
			revenue: "Receita",
			billing: "Faturamento",
			percentageFee: "Taxa",
			salesFee: "Taxa Sobre Vendas",
			salesFeeValue: "Repasse de Vendas",
			serviceFee: "Taxa Sobre Serviço",
			serviceFeeValue: "Repasse de Serviço",
			percentageFeeText: "Taxa em {{fee}}",
			adjustValue: "Ajustes",
			electricalValue: "Elétrica",
			hydraulicsValue: "Hidráulica",
			cleaningValue: "Limpeza",
			otherValue: "Outros",
			cashRetention: "Retenção de Dinheiro",
			cashPayment: "Pagamento em dinheiro",
			voucherPayment: "Pagamento em voucher",
			creditPayment: "Pagamento em credito",
			debitPayment: "Pagamento em debito",
			total: "Total",
			totalSoldValueInCredit: "Faturamento em Crédito",
			totalSoldValueInDebit: "Faturamento em Débito",
			totalSoldValueInPix: "Faturamento em Pix",
			creditFee: "Taxa sobre Crédito",
			debitFee: "Taxa sobre Débito",
			pixFee: "Taxa sobre Pix",
			totalSoldValueInCreditFeeValue: "Cobrança sobre Crédito",
			totalSoldValueInDebitFeeValue: "Cobrança sobre Débito",
			totalSoldValueInPixFeeValue: "Cobrança sobre Pix",
			totalSoldValueInOtherPaymentMethods: "Faturamento em outros métodos de pagamento",
			billingTooltip:
				"Essa coluna se refere a soma de valores faturados pelo vendor em produtos, serviço e ajustes.",
			revenueTooltip:
				"Essa coluna é o resultado do faturamento menos todas as taxas e ajustes cobrados do vendor.",
			voucherRetention: "Retenção de voucher",
			productsFee: "Repasse de Produtos com Taxa Especial",
		},
		sheet: {
			title: "Faturamento por vendor",
			filename: "faturamento_por_vendor_em_local",
			columns: {
				name: "Vendor",
				totalSoldValue: "Produtos vendidos",
				totalTipValue: "Serviço",
				subtotal: "Subtotal",
				rent: "Aluguel",
				revenue: "Receita",
				billing: "Faturamento",
				percentageFee: "Taxa",
				salesFee: "Taxa Sobre Vendas",
				salesFeeValue: "Repasse de Vendas",
				serviceFee: "Taxa Sobre Serviço",
				serviceFeeValue: "Repasse de Serviço",
				percentageFeeText: "Taxa em {{fee}}",
				adjustValue: "Ajustes",
				electricalValue: "Elétrica",
				hydraulicsValue: "Hidráulica",
				cleaningValue: "Limpeza",
				otherValue: "Outros",
				cashRetention: "Retenção de Dinheiro",
				totalSoldValueInCredit: "Faturamento em Crédito",
				totalSoldValueInDebit: "Faturamento em Débito",
				totalSoldValueInPix: "Faturamento em Pix",
				creditFee: "Taxa sobre Crédito",
				debitFee: "Taxa sobre Débito",
				pixFee: "Taxa sobre Pix",
				totalSoldValueInCreditFeeValue: "Cobrança sobre Crédito",
				totalSoldValueInDebitFeeValue: "Cobrança sobre Débito",
				totalSoldValueInPixFeeValue: "Cobrança sobre Pix",
				totalSoldValueInOtherPaymentMethods: "Faturamento em outros métodos de pagamento",
				voucherRetention: "Retenção de voucher",
				productsFee: "Repasse de Produtos com Taxa Especial",
			},
		},
		totalizers: {
			totalSold: "Faturamento total dos vendors",
			totalReceipt: "Receita total com vendors",
			highestVendor: "Maior vendor",
		},
		emptyState: {
			title: "Nenhuma informação encontrada",
			description: "Não conseguimos encontrar nenhum dado no período selecionado.",
			subDescription: "Verifique se o período é válido ou tente um novas datas.",
		},
	},
};
