import { NavBarRoute } from "#components/nav-bars-shared/nav-bar";
import * as React from "react";
import { browserHistory } from "react-router";

type NavItem = Pick<NavBarRoute, "url" | "label">;

interface IBreadCrumb {
	currentPage: string;
	onChangeCurrentPage(currentPage: string): void;
	onChangeNavBarList(navBarList: NavItem[]): void;
}

let lastPathname = window.location.pathname;

export const BreadCrumbContext = React.createContext({} as IBreadCrumb);

export function BreadCrumbContextProvider({ children }: { children: React.ReactNode }) {
	const [currentPage, setCurrentPage] = React.useState<string>("");
	const [navBarList, setNavBarList] = React.useState<NavItem[]>([]);

	const findExactPathOccurrence = (list: NavItem[]) => {
		const path = window.location.pathname.split("/");

		const occurrencesFound = list.filter(item =>
			item.url?.includes(path[path.length - 1]),
		);

		if (occurrencesFound.length > 1) {
			const foundItem = occurrencesFound.find(item => {
				const splitItemPath = item.url?.split("/");

				const lastCurrPath = path[path.length - 1];
				const lastItemPath = splitItemPath[splitItemPath.length - 1];

				return lastItemPath === lastCurrPath;
			});

			return foundItem || occurrencesFound.shift();
		}

		return occurrencesFound.shift();
	};

	const onRouteChange = React.useCallback(() => {
		if (navBarList.length) {
			const foundItem = findExactPathOccurrence(navBarList);

			if (!foundItem) {
				return;
			}

			setCurrentPage(foundItem.label);
			lastPathname = window.location.pathname;
		}
	}, [navBarList, setCurrentPage]);

	browserHistory.listen(({ pathname }) => {
		if (lastPathname !== pathname) {
			onRouteChange();
		}
	});

	React.useEffect(() => {
		onRouteChange();
	}, [navBarList]);

	function onChangeCurrentPage(currentPage: string) {
		setCurrentPage(currentPage);
	}

	function onChangeNavBarList(navBarList: NavBarRoute[]) {
		setNavBarList([...navBarList]);
	}

	return (
		<BreadCrumbContext.Provider
			value={{ currentPage, onChangeCurrentPage, onChangeNavBarList }}
		>
			{children}
		</BreadCrumbContext.Provider>
	);
}

export function useBreadcrumb() {
	return React.useContext(BreadCrumbContext);
}
