import * as React from "react";

import s from "./breadcrumbs.scss";

interface BreadcrumbsProps extends React.HTMLAttributes<HTMLDivElement> {
	crumbs: React.ReactNode[];
}

export function Breadcrumbs({ crumbs, ...props }: BreadcrumbsProps) {
	return (
		<div className={s.breadcrumbsContainer} {...props}>
			{crumbs.map((crumb, index) => (
				<span key={index} className={s.crumb}>
					{crumb}
				</span>
			))}
		</div>
	);
}
