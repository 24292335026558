export const multivendor = {
	closings: {
		title: "Fechamento por vendor",
		filename: "fechamento-de-vendors",
		columns: {
			vendorName: "Vendor",
			vendorValueToReceive: "Valor a receber",
			otherProductsFeeValue: "Taxa variável sobre produtos especiais",
			generalFeeValue: "Taxa variável geral",
			eventDate: "Data do evento",
			payDate: "Data de repasse",
			generalPercentageFee: "Taxa variável sobre faturamento",
			fixedFee: "Taxa de aluguel fixa",
			totalSoldValue: "Faturamento total",
		},
		emptyText: "Sem dados a serem exibidos.",
	},
	productsSold: {
		title: "Produtos vendidos por vendor",
	},
	revenues: {
		title: "Faturamento por vendor",
		description:
			"Relatório de relação de faturamento e cada vendor cadastrado como parte do evento.",
		filename: "faturamento_por_vendor",
		columns: {
			name: "Vendor",
			totalSoldValue: "Produtos vendidos",
			totalTipValue: "Serviço",
			subtotal: "Subtotal",
			rent: "Aluguel",
			revenue: "Receita",
		},
	},
};
