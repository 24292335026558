export const invoicecCardInfo = {
	title: "Dados da nota fiscal",
	billNumber: "Número da nota",
	noteSeries: "Série da nota",
	noteData: "Data da nota",
	CFOP: "CFOP",
	accesskey: "Chave de acesso",
	natureOfOperation: "Natureza da operação",
	issuer: "Emitente",
	CNPJAndCPF: "CNPJ/CPF",
	amount: "Valor total",
};
