export const rechargeManagement = {
	title: "Balance management",
	titleWithPeriod: "Recharges from the last {{period}} days",
	titleWithoutPeriod: "Recharges",
	editButton: "Edit selected balance",
	statusSelect: "Status",
	statusPlaceholder: "Filter by status",
	employeesSelect: "Employee who created",
	employeesPlaceholder: "Filter by employee",
	clientSearch: "Client document",
	clientPlaceholder: "Filter by client",
	period: "Period",
	editTitle_other: "Editing {{value}} balances",
	editTitle_one: "Editing balance",
	save: "Save changes",
	validPlace: "Places where top-ups are valid",
	validPlacePlaceholder:
		"Click to select locations. All previously valid locations will lose their validity if they are not in this selection, except the location where the recharge was originally carried out",
	expirationDate: "Expiration date",
	valid: "Valid for more {{value}} days",
	expireToday: "Expires today",
	expired: "Expired {{value}} days ago",
	required: "Required field",
	columns: {
		id: "ID",
		date: "Creation date",
		method: "payment method",
		author: "Employee who created",
		user: "Client",
		totalValue: "Value",
		expiresAt: "Expiration date",
		validPlaces: "valid in places",
		status: "Status",
		rechargeType: "Type",
		minimumConsumption: "Minimum consumption",
		bonus: "Bonus",
		recharge: "Recharge",
	},
	activeLabel: "Valid",
	expiredLabel: "Expired",
	refundedLabel: "Refunded",
	emptyTitle: "There were no balances",
	emptySubtitle: "There were no balances in the selected period",
	warningMessage:
		"This recharge will continue to be used <b>even if it appears as reversed in the history</b> until the customer is re-registered if it is already in their ZigTag.",
	hasBonusOrMinimumConsumptionPlaceIdsError:
		"Editing Place blocked for Bonus and Minimum Consumption",
	hasBonusOrMinimumConsumptionexpiresAtError:
		"For Minimum Consumption, edition only for expired dates",
	editAllButton: "Edit all balances",
	editAllMessage: "Are you sure you want to edit all balances?",
	editAllConfirm: "Yes, go to editing",
};
