import React from "react";
import { ModularizationOnboarding } from "./modularization";
import { BonusReportModal } from "./bonus-report";
import { OnboardingKeysType } from "./constants";

type OnboardingProps = {
	onboardingKey: OnboardingKeysType;
	onClose: () => void;
};

const ONBOARDING_COMPONENTS: {
	[key in OnboardingKeysType]: (props: { onClose: () => void }) => React.JSX.Element;
} = {
	modularization: ModularizationOnboarding,
	bonusReport: BonusReportModal,
};

export const Onboarding = ({ onClose, onboardingKey }: OnboardingProps) => {
	const OnboardingComponent = ONBOARDING_COMPONENTS[onboardingKey];

	return (
		<>
			<OnboardingComponent onClose={onClose} />
		</>
	);
};
