export const xlsxPicker = {
	sheetHumanizeErrorMessage: {
		verifyBaseSheet:
			", please check the sample spreadsheet template or use it as a base spreadsheet.",
		unidentifieldColumn: "Column names error",
		emptySheet: "This worksheet is empty",
		emptySheetRows: "This spreadsheet has empty lines, please remove the empty lines.",
		emptyHeaderCell: "Missing name in columns",
		sheetPoorlyFormatted:
			"Format spreadsheet for Calibri font, size 11, remove spaces, unnecessary line breaks and bold",
		default: "There was an error importing the sheet",
	},
	sheetNameExample: "(example)",
	sheetNameInvalid: "(invalid)",
	sheetDownload: "Download sample table",
	sheetXlsxPicker: "Choose a worksheet",
	sheetFeedback: {
		successMessage: "Valid worksheet!",
		errorMessage: "Invalid worksheet, download it again and make the necessary changes.",
	},
	columns: {
		name: "Name",
		category: "Category",
		menuCategory: "Menu category",
		description: "Description",
		image: "Image",
		hasAlcohol: "Contains alcohol?",
		price: "Price",
		variablePrice: "Variable price",
		kind: "Product type",
		fiscalCode: "SKU",
		ncm: "Fiscal - NCM",
		cest: "Fiscal - CEST",
		fiscalProductGroup: "Fiscal - Tax group",
		fiscalProfile: "Fiscal - Tax profile",
		id: "Product ID",
		products: "products",
		bars: "Bars",
		beerBrand: "Beer brand",
		beerStyle: "Beer style",
		beerAbv: "ABV - Alcohol by volume",
	},
	cell: {
		trueHasAlcohol: "TRUE",
		falseHasAlcohol: "FALSE",
	},
};
