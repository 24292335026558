import { soldProducts } from "./sold-products";
import { salesReport } from "./sales-report";
import { chipsBurned } from "./chips-burned";
import { waiterReport } from "./waiter-report";
import { deliveredProductsByBar } from "./delivered-products-by-bar";
import { soldBaseProductReport } from "./sold-base-product-report";
import { soldRankingReport } from "./sold-ranking-report";

export const barReports = {
	printDate: "Issued on",
	reportPeriod: "Report period",
	filter: "Filter by transaction type",
	filterPlaceholder: "Select a transaction type",
	filterOptions: {
		all: "All",
		balcony: "Balcony",
		cashless: "Cashless",
		dash: "Dashboard",
		delivery: "Delivery",
		ficha: "Ficha",
		grabngo: "Grab n Go",
		onlineBill: "Online Bill",
		zigQrcode: "Zig QR Code",
	},
	soldProducts,
	salesReport,
	chipsBurned,
	waiterReport,
	deliveredProductsByBar,
	soldBaseProductReport,
	soldRankingReport,
	tipReport: {
		title: "Tip Report",
		filename: "tip-report_{{date}}",
		subtitle: "List of amounts to be received by operators/waiters for service tips.",
		filterByPeriod: "Filter by period",
		filterByEmployee: "Filter by employee",
		filterByEmployeePlaceholder: "Select the employees",
		eventPeriod: "Event period",
		statusRunning: "(in progress)",
		columns: {
			name: "Name",
			total: "Total in tips",
			cashTotal: "Cash",
			debitTotal: "Debit",
			creditTotal: "Credit",
			appTotal: "App",
			pixTotal: "Pix",
			bonusTotal: "Bonus",
		},
	},
	productReturnsReport: {
		title: "Product return",
		product: "Product",
		quantity: "QTY",
		returnValue: "Return value",
		totalValue: "Total",
		noRecords: "No records",
		noReturnedProducts: "No returns in this period",
		noReturnsThisEvent: "No returns in this event",
		productReturnReport: "Product return report",
		totalReturnedProducts: "Total returned products",
		productsReturnedByEmployee: "Products returned by staff",
		employee: "Employee",
		summary: "Summary",
	},
	yes: "Yes",
	no: "No",
	noCard: "No card",
	grams: "grams",
	kilos: "Kilos",
	liter: "Liters",
	by: "by",
};
