import { TabInfo } from "#components/tabs/tabs";
import { Submenu } from "./navigation-with-permissions";

export const extractSubMenusFromTabs = (tabs: TabInfo[]): Submenu[] => {
	return tabs
		.filter(tab => !tab.hide)
		.map(tab => ({
			label: tab.label,
			url: tab.link,
		}));
};

export const extractAlternativeUrlsFromTabs = (
	tabs: TabInfo[],
	filterNotHiddenTabs?: boolean,
): string[] => {
	let tabsList = tabs;

	if (filterNotHiddenTabs) {
		tabsList = tabsList.filter(item => !item.hide);
	}

	return tabsList.map(tab => tab.link);
};
