import { RootStore } from "#stores/index";
import { fetchModel } from ".";

const isFetchModel = (value: any) =>
	typeof value === "object" && value?.constructor?.name === "fetchModel";

export const resetAllFetchModelsOnStore = (store: StoreInstance) => {
	Object.entries(store).forEach(([key, value]) => {
		if (isFetchModel(value))
			(store[key as keyof StoreInstance] as fetchModel<any, any>).reset();
	});
};

type StoreInstance =
	| RootStore["liveStore"]
	| RootStore["deviceStore"]
	| RootStore["routerStore"]
	| RootStore["authStore"]
	| RootStore["placeStore"]
	| RootStore["barStore"]
	| RootStore["itemStore"]
	| RootStore["comboStore"]
	| RootStore["promotionStore"]
	| RootStore["deliveryStore"]
	| RootStore["vendorStore"]
	| RootStore["cashierStore"]
	| RootStore["prechargeStore"]
	| RootStore["relationStore"]
	| RootStore["employeeStore"]
	| RootStore["eventStore"]
	| RootStore["organizationStore"]
	| RootStore["clientStore"]
	| RootStore["reserveStore"]
	| RootStore["imageStore"]
	| RootStore["backofficeStore"]
	| RootStore["planAccountStore"]
	| RootStore["CMVStore"]
	| RootStore["uiStore"]
	| RootStore["fiscalStore"]
	| RootStore["invoiceStore"]
	| RootStore["productFeatureStore"]
	| RootStore["messageStore"]
	| RootStore["extractStore"]
	| RootStore["metabaseStore"]
	| RootStore["whatsUpStore"]
	| RootStore["promoterStore"]
	| RootStore["zigTagStore"]
	| RootStore["chartOfAccountsStore"]
	| RootStore["passwordCardStore"]
	| RootStore["storageStore"]
	| RootStore["clientAreaStore"];
