import { contentsSection } from "./contents-section";

export const productDetail = {
	isActive: "Produto ativo",
	isInactive: "Produto inativo",
	buttonEditProduct: "Editar produto",
	variable: "Variável",
	modalDeleteProduct: {
		titleModal: "Confirmação necessária",
		confirmDeleteProduct: "Deseja deletar o produto: {{productName}}",
	},
	sectionsCards: {
		productDetail: "Detalhes do produto",
		fiscalData: "Dados fiscais",
		cardProductSoldBy: "Produto vendido por g/ml",
		cardProductMountable: "Produto montável",
		releaseValue: "Preço de solta",
	},
	duplicateProductMountable: "Duplicar produto montável",
	contentsSection,
};
