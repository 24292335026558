export const pos = {
	title: "Devices: PDVs and Event Kiosks",
	open: "{{active}} of {{total}} PDV´s opened",
	info:
		"It is necessary that the PDV is connected to the internet to synchronize the settings.",
	searchPlaceholder: "Search for device or employee",
	noName: "No name",
	error: "This operation is only allowed when there is no PDV selected",
	editConfig: {
		label: "Edit Settings",
		title: "Modify PDVs ({{count}})",
		configs: {
			label: "Settings",
			checkbox: "Try to pair bluetooth machine",
		},
		bluetooth: "Bluetooth",
		wifi: "Wi-fi",
		preferences: "Preferences",
		noName: "Preference without name",
		confirmMessage:
			"Are you sure you want to save changes? Both the wifi and mobile data of the device(s) are disabled, so it will not be possible to view the updated information of the device.",
		errorMessage:
			"One of these preferences 'Enable selling' and 'Enable group selling' needs to be active",
	},
	columns: {
		fingerPrint: "CODE",
		product: "PRODUCT",
		version: "VERSION",
		employee: "EMPLOYEE",
		activation: "ACTIVATION",
		lastUpdate: "LAST UPDATE",
		cleanedDataStatus: "CLEANED?",
		devicePlatform: "MODEL",
		hasFinishedActivity: {
			label: "OPERATION",
			finished: "Closed",
			open: "Open",
		},
		status: "STATUS",
		data: "DATE",
	},
	deviceConfigColumn: {
		noData: "no data",
		on: "on",
		off: "off",
		tryingToConnect: "Trying to connect to the bluetooth machine",
		connected: "connected",
		disconnect: "disconnected",
		bluetooth: "Bluetooth",
		bluetoothMachine: "Bluetooth machine",
		wifi: "Wifi",
		mobileData: "Mobile data",
		nfc: "NFC",
	},
	settings: "Settings",
	humanizedPreferencies: {
		dettachOnCheckout: "Automatically detach on checkout",
		hidePaymentModeVoucher: "Hide voucher payment mode",
		isGroupedSellEnabled: "Enable group selling",
		isKeyboardOpenEnabled: "Keyboard already open in search",
		isNormalSellEnabled: "Enable sell",
		isNumericKeyboardInObsEnabled: "Observation with numeric keyboard",
		isSearchByCodeEnabled: "Numeric search",
		isSellWithoutCardEnabled: "Enable selling without card",
		isStockTransfEnabled: "Transfer stock",
		isStorageTransferPrinterLanActive: "Enable LAN inventory transfer printer",
		printTwoTickets: "Print two sales tickets",
		printViaBtForWaiter: "Show button to print pending orders from waiters",
		registerWithoutCard: "Register without card",
		removeCreditDebitPaymentOption: "Remove debit and credit option",
		hideDettachOption: "Hide detach",
	},
};
