export const reports = {
	header: {
		revenue: "Faturamento",
		service: "Serviço",
		products: "Produtos",
		people: "Pessoas",
		ticket: "Ticket Médio",
		entrance: "Couvert/Entradas",
		day: "Dia",
		period: "Período",
	},
	addPlacesTitle: "Adicionar locais ao relatório",
	addPlacesPlaceholder: "Selecione local(is)",
	all: "Todos",
	emptyComponent: "Sem dados para exibição do relatório",
	emptyPlaces: "Adicione ao menos um local para exibição do relatório",
	tooltip: "Não inclui contas em aberto",
	revenue: {
		title: "Faturamento Total",
		paymentMethod: "Métodos de pagamento",
		periodChart: {
			perLocalTitle: "Faturamento total por local",
			duringPeriodSubtitle: "Durante o período",
		},
		revenueChart: {
			entranceService: "Serviço de entrada: {{entrance}}",
		},
		methodChart: {
			rechargeDevolutions: "Recargas devolvidas: {{value}}",
			adjustedMoney: "Dinheiro ajustado: {{value}}",
		},
	},
	services: {
		title: "Serviço total",
		subtitle: "Por place em porcentagem",
	},
	products: {
		title: "Total de venda por horário",
		period: "Período: ({{since}} até {{until}})",
		dayFilter: "Selecione o dia",
		empty: "Sem dados para exibição do relatório",
		totalConsumedPeriod: "Total consumido de {{since}} até {{until}}:",
		totalConsumedAt: "Total consumido às {{value}}",
		nextPeriod: "{{currPeriod}} até {{nextPeriod}}",
		currentPeriod: "às {{currPeriod}}",
		periodTitle: "Período",
		productConsumptionTab: "Produtos por hora",
		productsSold: {
			table: {
				count: "QUANTIDADE",
				productName: "NOME DO PRODUTO",
				place: "LOCAL",
				sku: "SKU",
				total: "Total",
				totalPlaceValue: "TOTAL DO LOCAL",
				totalValue: "VALOR TOTAL",
				unitValue: "VALOR UNITÁRIO",
			},
			totalizers: {
				totalCount: "Quantidade total de produtos",
				totalSold: "Faturamento total do periodo",
			},
		},
		productSoldTab: "Produtos vendidos por local",
	},
	downloadExcel: {
		title: "Relatório {{orgName}}",
		fileName: "faturamento-{{orgName}}",
		columns: {
			date: "Data",
			place: "Local",
			service: "Serviço",
			inboundService: "Serviço de entrada",
			totalBilling: "Faturamento total",
		},
	},
};
