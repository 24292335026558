export const stockPositionReport = {
	label: "Posição de Estoque",
	title: "Relatório de Posição de Estoque",
	filters: {
		period: "Período",
		storage: "Estoque",
		apply: "Aplicar filtros",
		clean: "Limpar filtros",
	},
	paragraph:
		"O relatório de Posição de Estoque permite uma visão de como o estoque estava em determinado período exibindo os produtos dos estoques selecionados, suas quantidades e custos.",
	selectStock: "Selecione o estoque",
	emptyMessage: {
		title: "Selecione estoque para começar",
		description:
			"Selecione o estoque e o dia desejado para visualizar o relatório de posição do estoque",
	},
	table: {
		product: "Produto",
		initialQuantity: "Quantidade inicial",
		finalQuantity: "Quantidade final",
		maxQuantity: "Quantidade máxima",
		minQuantity: "Quantidade mínima",
		replacement: "Reposição sugerida",
		averageCost: "Custo médio",
		totalCost: "Custo total",
		total: "Total",
	},
};
