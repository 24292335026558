export const consolidated = {
	excelButton: "Exportar para Excel",
	extract: "Extrato",
	filename: "extrato-zig",
	table: {
		section: "Seção",
		name: "Nome",
		totalValue: "Valor Bruto",
		fee: "Taxa",
		feeMin: "Taxa Mínima",
		feeMax: "Taxa Máxima",
		payValue: "A Receber/A Pagar Zig",
	},
	formatFee: "De {{minFee}} a {{maxFee}}",
};
