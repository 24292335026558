import { ImageUrl, User } from "#api/enterprise-generated";
import { ClientStore } from "#stores/client";
import { extendModel } from "#helpers/mobx/utils";

/**  @deprecated models should not ne used anymore */
export interface IGetClientQuery {
	cpf?: string;
	phone?: string;
}

/**  @deprecated models should not ne used anymore */
export type ExtendedClient = User & IGetClientQuery;

/**  @deprecated models should not ne used anymore */
export class ClientModel implements ExtendedClient {
	public clientStore: ClientStore;
	public id!: string;
	public name!: string;
	public avatar!: ImageUrl | null;

	public cpf!: string;
	public phone!: string | undefined;

	constructor(clientStore: ClientStore, client: User) {
		this.clientStore = clientStore;
		this.sync(client);
	}

	public sync = (client: User) => {
		extendModel(this, client);
	};
}
