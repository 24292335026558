import { IColumn } from "./types";
import React, { createContext, ReactNode, useContext, useMemo, useState } from "react";

interface ISimpleTableContext<T extends object = any> {
	columns: IColumn<T>[];
	totalizer?: T;
	dataSource: T[];
	isLoading: boolean;
	selectedItems: T[];
	hasCheckbox: boolean;
	columnsCount: number;
	toggleCheckbox(item: T): void;
	toggleAllCheckbox(item: T[]): void;
}

interface ISimpleTableProvider<T> {
	columns: IColumn<T>[];
	dataSource: T[];
	totalizer?: T;
	onCheckItems?(items: T[]): void;
	children: ReactNode;
	isLoading: boolean;
}

const SimpleTableContext = createContext<ISimpleTableContext<any>>(
	{} as ISimpleTableContext<any>,
);

export function SimpleTableProvider<T>({
	children,
	columns,
	dataSource,
	onCheckItems,
	isLoading,
	totalizer,
}: ISimpleTableProvider<T>) {
	const [selectedItems, setSelectedItems] = useState<T[]>([]);

	const hasCheckbox = Boolean(onCheckItems);
	const columnsCount = useMemo(
		() => (hasCheckbox ? columns.length + 1 : columns.length),
		[hasCheckbox, columns],
	);

	function toggleCheckbox(item: T) {
		if (selectedItems.includes(item)) {
			const items = selectedItems.filter(i => i !== item);
			setSelectedItems(items);
			onCheckItems && onCheckItems(items);
		} else {
			const items = [...selectedItems, item];
			setSelectedItems(items);
			onCheckItems && onCheckItems(items);
		}
	}

	function toggleAllCheckbox(items: T[]) {
		const toggledItems = selectedItems.length === dataSource.length ? [] : items;
		setSelectedItems(toggledItems);
		onCheckItems && onCheckItems(toggledItems);
	}

	return (
		<SimpleTableContext.Provider
			value={{
				columns,
				dataSource,
				isLoading,
				toggleCheckbox,
				toggleAllCheckbox,
				selectedItems,
				hasCheckbox,
				columnsCount,
				totalizer,
			}}
		>
			{children}
		</SimpleTableContext.Provider>
	);
}

export function useSimpleTable() {
	const context = useContext(SimpleTableContext);

	return context;
}
