import { taxTabs } from "./tax-tabs";
import { components } from "./components";

export const fiscalData = {
	components,
	taxTabs,
	titlePage: "Tax data",
	titlesTabs: {
		taxCouvert: "Couvert Tax Data",
		taxGroups: "Product Tax Groups",
		taxProfiles: "Tax profiles",
		minimumConsummation: "Minimum consummation",
		recharge: "Recharge",
	},
};
