import React from "react";
import { Dayjs } from "dayjs";
import dayJsGenerateConfig from "rc-picker/lib/generate/dayjs";
import generateCalendar, { CalendarProps } from "antd/lib/calendar/generateCalendar";

type CalendarAbsProps = CalendarProps<Dayjs>;

const CustomCalendar = generateCalendar<Dayjs>(dayJsGenerateConfig);

export function Calendar(props: CalendarAbsProps) {
	return <CustomCalendar {...props} />;
}
