import React from "react";
import { useTranslation } from "react-i18next";

import { Button, Typography } from "#components/primitive";
import EmptyStateIcon from "#resources/assets/svg/empty-state-notifications.svg";
import { useNotifications } from "#components/notifications/notifications.context";

import s from "../../notifications-content.scss";

interface INotificationsContentFooterProps {
	showMore: boolean;
}

export function NotificationsContentFooter({
	showMore,
}: INotificationsContentFooterProps) {
	const { t } = useTranslation("components", {
		keyPrefix: "notifications",
	});
	const { t: tGlobayKeys } = useTranslation("globalKeys");

	const {
		onShowMoreReadNotifications,
		onShowMoreUnreadNotifications,
		currentTab,
	} = useNotifications();

	return (
		<div className={s.footerContainer}>
			{showMore ? (
				<Button
					variant="text"
					onClick={
						currentTab === "read"
							? onShowMoreReadNotifications
							: onShowMoreUnreadNotifications
					}
					className={s.seeMoreButton}
				>
					{tGlobayKeys("seeMore")}
				</Button>
			) : (
				<div className={s.emptyStateContainer}>
					<div>
						<EmptyStateIcon />
					</div>
					<Typography type="paragraph" variant="v1">
						{currentTab === "unread"
							? t("emptyUnreadNotifications")
							: t("emptyNotifications")}
					</Typography>
				</div>
			)}
		</div>
	);
}
