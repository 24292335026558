export const finExManagerialReport = {
	title: "DRE gerencial",
	exportToExcel: "Exportar para excel",
	excelTitles: {
		incomeList: "Receitas",
		costsList: "Custos",
		expensesList: "Despesas ",
	},
	periodSelect: {
		fifteen: "Últimos 15 dias",
		thirty: "Últimos 30 dias",
		sixty: "Últimos 60 dias",
		selectPeriod: "Selecionar período",
	},
	description:
		"O DRE Gerencial auxilia na analise a saúde financeira e os resultados de lucro, ou prejuízo, de uma operação para equilibrar as despesas e aumentar o faturamento otimizando custos e gerando lucros.",
	periodDescription: "Exibindo valores consolidados de {{since}} até {{until}}",
	tabs: {
		summary: "Resumo",
		overview: "Visão Geral",
	},
	period: "Período",
	applyDate: "Aplicar data",
	table: {
		level: "Nível",
		income: "Receitas",
		costs: "Custos",
		expenses: "Despesas",
		total: "Total",
		contribution: "Margem de contribuição",
		noData: "Sem dados",
		summaries: {
			totalIncome: "Total receitas",
			totalCosts: "Total custos",
			totalExpenses: "Total despesas",
			totalPeriod: "Total do período",
		},
	},
};
