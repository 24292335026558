import i18n from "#i18n/index";
import enterprise from "#resources/api/enterprise-client";
import { ZigTag } from "#resources/api/enterprise-generated";
import { fetchModel } from "#resources/helpers/fetch-model";
import {
	showErrorNotification,
	showSuccessNotification,
} from "#resources/helpers/notifications";

export class ZigTagStore {
	public getZigTag = new fetchModel<
		{
			eventId: string;
			zigCode: string;
		},
		ZigTag
	>({
		fnPromise: args => enterprise.getZigTag(args),
		onError: error => showErrorNotification(error.message),
	});

	public blockZigDeviceIfPossible = new fetchModel<
		{
			id: string;
			eventId: string;
		},
		void
	>({
		fnPromise: args => enterprise.blockZigDeviceIfPossible(args),
		onError: error => showErrorNotification(error),
		onSuccess: () => showSuccessNotification(i18n.t("event:zigTags.block.success")),
	});
}
