export const passwordCardHeader = {
	main: {
		title: "Cartão de senhas",
		description:
			"Os cartões de senhas são usados em substituição às senhas digitadas e funcionam por aproximação, agilizando, assim, a operação. Dessa forma, apenas os que possuírem esses cartões estarão aptos a autorizar certas ações no PDV, oferecendo maior controle e segurança para procedimentos relevantes como sangria e estorno.",
		labelText: "Pesquisar",
		placeholder: "Pesquise pelo dono do cartão",
		statusFilter: "Exibir apenas cartões ativos",
	},
	stockHistory: {
		title: "Cartão de senhas - Histórico de ações",
		cardOwner: "Dono(a) do cartão: ",
	},
	blockButton: "Bloquear múltiplos cartões",
};
