import classNames from "classnames";
import CloseIcon from "#assets/svg/close.svg";
import * as React from "react";
import s from "./filter-pill.scss";

export interface IFilterPillProps {
	className?: string;
	label: string;
	onRemove?: () => void;
}

export function FilterPill({ className, label, onRemove }: IFilterPillProps) {
	return (
		<div className={classNames(s.container, className)}>
			{label}
			<div onClick={onRemove}>
				<CloseIcon />
			</div>
		</div>
	);
}
