export const anticipationDrawer = {
	title: "Solicitar Antecipação e Pagamento de Boleto",
	titleAnticipation: "Solicitar Antecipação",
	titleBilletPayment: "Solicitar Pagamento de Boleto",
	anticipationValue: "Valor da antecipação",
	accountData: "Informações da conta",
	summary: "Revisão dos dados",
	warningBanner:
		"Solicitações realizadas após às 10h30 (horário de Brasília) só serão atendidas no dia seguinte.",
	warningAccountChoice:
		"Será cobrado uma taxa de {{value}} para antecipação em contas de terceiros.",
	formStatusError: "Não foi possível concluir a solicitação.",
	formStatusSuccess: "Antecipação solicitada com sucesso!",
	form: {
		selectAccountPlaceholder: "Digite ou selecione a conta",
		liquidValue: "Valor líquido",
		liquidValuePlaceholder: "Digite aqui o valor líquido",
		totalValue: "Total a ser antecipado",
		totalValueTooltip: "O valor líquido mais a taxa de antecipação.",
		reason: "Motivo da solicitação",
		reasonPlaceholder: "Digite aqui o motivo para a antecipação",
		accountType: "Transferir para",
		accountTypes: {
			default: "Conta padrão",
			existing: "Conta cadastrada",
			new: "Nova conta",
			tooltip: "Aquela firmada em contrato",
		},
		shouldSaveForFutureRequests: "Salvar conta para transações futuras",
		bankData: "Dados bancários",
		name: "Identificador da conta",
		summaryTitle: "Revisão de valores",
		summaryReason: "Motivo",
		summaryValue: "Valor solicitado",
		summaryAnticipationFee: "Taxa de antecipação",
		summaryLiquidValue: "Valor líquido solicitado",
		summaryThirdPartyFee: "Taxa de antecipação para terceiros",
		summaryTotal: "Valor bruto a antecipar",
		withdrawAll: "Antecipar todo valor disponível",
	},
};
