export const rechargeManagement = {
	title: "Gestão de saldo",
	titleWithPeriod: "Recargas dos últimos {{period}} dias",
	titleWithoutPeriod: "Recargas",
	editButton: "Editar saldos selecionados",
	statusSelect: "Status",
	statusPlaceholder: "Filtre por status",
	employeesSelect: "Funcionário que criou",
	employeesPlaceholder: "Filtre por funcionário",
	clientSearch: "CPF do cliente",
	clientPlaceholder: "Filtre por cliente",
	period: "Período",
	editTitle_other: "Editando {{value}} saldos",
	editTitle_one: "Editando saldo",
	save: "Salvar alterações",
	validPlace: "Locais em que os saldos são válidos",
	validPlacePlaceholder:
		"Clique para selecionar locais. Todos os locais previamente válidos vão perder a validade caso não estejam nessa seleção, menos o local em que a recarga foi originalmente realizada",
	expirationDate: "Data de expiração",
	valid: "Válida por mais {{value}} dias",
	expireToday: "Expira hoje",
	expired: "Expirada há {{value}} dias",
	required: "Campo obrigatório",
	columns: {
		id: "ID",
		date: "Data de criação",
		method: "método de pagamento",
		author: "Funcionário que criou",
		user: "Cliente",
		totalValue: "Valor",
		expiresAt: "Data de expiração",
		validPlaces: "válido nos locais",
		status: "Status",
		rechargeType: "Tipo",
		minimumConsumption: "Consumação Antecipada",
		bonus: "Bônus",
		recharge: "Recarga",
	},
	activeLabel: "Válida",
	expiredLabel: "Expirada",
	refundedLabel: "Estornada",
	emptyTitle: "Não houveram saldos",
	emptySubtitle: "Não houveram saldos no período selecionado",
	warningMessage:
		"Essa recarga continuará podendo ser usada <b>mesmo que apareça como estornada no histórico</b> até o cliente ser recadastrado caso ela já esteja na sua ZigTag.",
	hasBonusOrMinimumConsumptionPlaceIdsError:
		"Edição de Locais bloqueado para Bonus e Consumação antecipada",
	hasBonusOrMinimumConsumptionexpiresAtError:
		"Para Consumação antecipada, edição apenas para datas expiradas",
	editAllButton: "Editar todos os saldos",
	editAllMessage: "Você tem certeza que deseja editar todos os saldos?",
	editAllConfirm: "Sim, ir para edição",
};
