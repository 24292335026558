export const invoices = {
	title: "Notas Fiscais",
	period: "Período",
	downloadXML: "Download do XML",
	downloadJSON: "Download do JSON",
	descriptionForeign:
		"Este relatório exibe as Notas Fiscais e Notas de Crédito emitidas, permitindo filtrar por período, perfil fiscal, funcionário, tipo de fatura (Nota Fiscal e Nota de Crédito) e tipo de item (Recarga ou Produto).",
	modelLabel: {
		nfce: "NFC-e",
		nfse: "NFS-e",
		sat: "SAT",
		ivaVendus: "IVA - Vendus",
		ivaFactura: "IVA - Factura",
	},
	sheet: {
		title: "Notas Fiscais",
		filename: "notas_fiscais",
		columns: {
			issuedAt: "Emitida em",
			mode: "Modo de emissão",
			fiscalProfile: "Perfil fiscal",
			isCanceled: "Cancelada",
			eventDate: "Data do evento",
			serie: "Série nota",
			number: "Nº nota",
			transaction: "Transação",
			recharge: "Recarga",
			employee: "Funcionário",
			value: "Valor nota",
			imgUrl: "URL Image",
			pdfUrl: "URL PDF",
			xml: "XML",
			json: "JSON",
		},
	},
	columns: {
		issuedAt: "Emitida em",
		mode: "Modo de emissão",
		fiscalProfile: "Perfil fiscal",
		isCanceled: "Cancelada",
		type: "Tipo",
		eventDate: "Data do evento",
		serie: "Série nota",
		number: "Nº nota",
		value: "Valor nota",
		transaction: "Transação",
		recharge: "Recarga",
		employee: "Funcionário",
		fiscalMode: "Modo fiscal",
		expirationDate: "Data de expiração",
		errorMsg: "Erro",
		status: {
			label: "Status",
			pending: "Pendente de criação",
			waiting: "Pendente de emissão",
		},
		tooltips: {
			nc: "Nota de Credito / Nota Cancelada",
			ft: "Fatura / Notas Fiscais",
		},
		actions: {
			title: "Ações",
			pdf: "PDF",
			image: "Imagem",
			xml: "XML",
			cancelledXml: "XML de cancelamento",
			json: "JSON",
			cancelledJson: "JSON de cancelamento",
			cancel: {
				okText: "Sim, cancelar nota fiscal",
				title: "Tem certeza que deseja cancelar a nota fiscal?",
			},
			cancelUnnavailable: {
				okText: "Ok",
				title: "Notas emitidas a mais de 30 minutos não podem ser canceladas",
			},
		},
	},
	missing: {
		title: "Notas Faltantes",
		titleForeign: "Notas Pendentes",
		period: "Período",
		fiscalProfile: "Perfil fiscal",
		fiscalProfileLabel: "Filtrar por perfil fiscal",
		employeeLabel: "Filtrar por funcionário",
		invoiceTypeLabel: "Filtrar tipo de documento",
		documentId: "Identificador",
		pendingAutoissuanceOrders: "Total bruto pendente",
		zigSystemEmployee: "ZIG (sistema)",
		creditInvoiceLabel: "Notas de Crédito",
		itemTypeLabel: "Filtrar tipo de item",
		recharges: "Recargas",
		products: "Produtos",
		invoiceLabel: "Notas Fiscais",
		selectFiscalProfile: "Selecione um perfil fiscal",
		download: "Download do XML",
		descriptionForeign:
			"Este relatório mostra todas as Notas pendentes, ou seja, aquelas que chegaram ao sistema, mas não foram emitidas por algum motivo. Permite visualizar a mensagem de erro associada a cada Nota Pendente.",
		descriptionForeignMEX:
			"Este relatório mostra todas as Notas pendentes, ou seja, 1) aquelas que foram recebidas pelo sistema mas não foram emitidas por algum motivo e 2) aquelas para as quais foi criada uma ordem de autofaturação e estão aguardando o cliente emitir. Permite visualizar a mensagem de erro associada a cada Nota pendente.",
		columns: {
			serie: "Série",
			number: "Número",
			employee: "Funcionário",
			date: "Data",
			actions: "Ações",
		},
	},
	downloadBackupModal: {
		title: "Download de arquivos XML",
		placeholder: "Selecione",
		error: "Erro: {{error}}",
		empty: "Nenhum backup disponível",
		content:
			"Para realizar o download desta quantidade de arquivos é necessário baixar o mês completo.",
		selectMonth: "Selecione abaixo o mês de referência para download.",
	},
	filters: {
		zigSystemEmployee: "ZIG (sistema)",
		fiscalProfileLabel: "Filtrar por Perfil Fiscal",
		employeeLabel: "Filtrar por Funcionario",
		invoiceType: "Filtrar por tipo de Fatura",
		invoiceItemType: "Filtrar por tipo de item",
		invoiceItemTypeProduct: "Produtos",
		invoiceItemTypeRecharge: "Recargas",
		invoiceTypeAll: "Todas as Faturas",
		invoiceTypeWithCreditInvoice: "Faturas com Notas de Credito",
		invoiceTypeWithoutCreditInvoice: "Faturas sem Notas de Credito",
	},
};
