import * as React from "react";
import * as ReactGA from "react-ga";

import { Modal } from "antd";

interface Props {
	name: string | null;
	className?: string;
	contentClassName?: string;
	width?: string;
	title: string | JSX.Element;
	onCloseDialog: () => any;
	actions?: string | JSX.Element;
	closeIfClickOutside?: boolean;
}

import s from "./dialog.scss";
export default class Dialog extends React.Component<Props, {}> {
	public componentDidMount() {
		if (this.props.name) ReactGA.modalview(this.props.name);
	}

	public render(): JSX.Element {
		return (
			<Modal
				title={this.props.title}
				width={this.props.width ? this.props.width : undefined}
				maskClosable={this.props.closeIfClickOutside}
				onCancel={this.props.onCloseDialog}
				className={`${s.dialog} ${this.props.className}`}
				open={true}
				footer={this.props.actions || null}
			>
				<div className={`${s.content} ${this.props.contentClassName}`}>
					{this.props.children}
				</div>
			</Modal>
		);
	}
}
