export const drawerProfile = {
	excludeTaxProfile: "Excluir perfil",
	infoDataCompany: {
		CNPJ: "CNPJ",
		cityCode: "Código do município",
		corporateReason: "Razão social",
		fantasyName: "Nome fantasia",
		municipalRegistration: "Inscrição municipal",
		stateRegistration: "Inscrição estadual",
		taxRegime: "Regime tributário",
		specialTaxRegime: "Regime especial de tributação",
	},
	infoDataIvaVendus: {
		apikey: "Chave API",
		cashierIds: "IDs dos caixas",
		country: "País",
		payments: {
			cash: "Dinheiro",
			credit: "Crédito",
			debit: "Débito",
			mbway: "Mbway",
			others: "Outros",
			paymentMethodId: "Forma de pagamento",
		},
	},
	infoDataNfce: {
		certificate: "Certificado",
		certificatePassword: "Senha do certificado",
		cscNfceHomologation: "CSC NFC-e homologação",
		cscNfceProduction: "CSC NFC-e produção",
		expirationDate: "Data de expiração",
		idNfceHomologation: "ID NFC-e homologação",
		idNfceProduction: "Id NFC-e produção",
	},
	infoDataSat: {
		serialNumberSat: "Número de série SAT",
	},
	infoDataTax: {
		mode: "Modo fiscal",
	},
	infoManifestation: {
		manifestation: "Manifestação",
	},
	infosGeneralData: {
		email: "E-mail",
		phone: "Telefone",
	},
	infosGeneralDataFiscalModeBR: {
		cep: "CEP",
		county: "Cidade",
		neighborhood: "Bairro",
		number: "Número",
		publicPlace: "Logradouro",
		state: "Estado",
	},
	infosTipConfig: {
		taxGroup: "Grupo Fiscal",
		registered: "Cadastrado",
	},
	titles: {
		companyData: "Dados da empresa",
		generalData: "Dados gerais",
		taxData: "Dados fiscais",
		tipConfig: "Configuração de gorjeta",
	},
};
