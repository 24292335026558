import * as React from "react";
import { Link, withRouter } from "react-router";
import { observer } from "mobx-react-lite";
import { formatDate } from "#resources/helpers/date-format";
import { useBreadcrumb } from "#structure/providers/breadcrumb";
import { useWindowSize } from "#resources/hooks";
import {
	Event as UserEvent,
	OrganizationWithFeatures as UserOrganization,
	Place as UserPlace,
} from "#api/enterprise-generated";
import { Breadcrumbs as PrimitiveBreadcrumbs } from "#components/primitive";

import s from "./bread-crumbs.scss";

interface IBreadCrumb {
	text: string;
	fullText: string;
	link: string;
	description?: string;
	hasThreeLvls?: boolean;
}

interface IBreadCrumbsProps {
	organization: UserOrganization | undefined;
	place: UserPlace | undefined;
	event: UserEvent | undefined;
	includeOrganizationOnUrl?: boolean;
}

function removeFirstLastSlashes(url: string) {
	return url.replace(/(^\/)|(\/$)/g, "");
}

export default withRouter(observer(BreadCrumbs));

function BreadCrumbs({
	event,
	organization,
	place,
	includeOrganizationOnUrl,
}: IBreadCrumbsProps) {
	const { currentPage } = useBreadcrumb();
	const { isExtraSmallDevice } = useWindowSize();

	const pathname = removeFirstLastSlashes(window.location.pathname);

	function genBreadCrumb({
		text,
		fullText,
		link,
		description = "",
		hasThreeLvls,
	}: IBreadCrumb): JSX.Element {
		const parsedLink = removeFirstLastSlashes(link);
		const href = pathname !== parsedLink ? link : "";

		return (
			<Link to={href} title={`${fullText} ${description ?? ""}`}>
				{hasThreeLvls && isExtraSmallDevice ? (
					<span>...</span>
				) : (
					<>
						<div>{text}</div>
						{description && <span>({description})</span>}
					</>
				)}
			</Link>
		);
	}

	function baseUrl(org: UserOrganization) {
		return includeOrganizationOnUrl ? `/organization/${org.id}/` : "/";
	}

	let organizationUrl = "";
	const crumbs = [];

	if (organization) {
		organizationUrl = baseUrl(organization);

		crumbs.push(
			genBreadCrumb({
				text: organization.name,
				fullText: organization.name,
				link: organizationUrl,
			}),
		);
	}

	if (organization && place) {
		crumbs.push(
			genBreadCrumb({
				text: place.name,
				fullText: place.name,
				link: `${organizationUrl}place/${place.id}`,
				hasThreeLvls: Boolean(event),
			}),
		);
	}

	if (organization && place && event) {
		crumbs.push(
			genBreadCrumb({
				text: event.name,
				fullText: event.name,
				link: `${organizationUrl}place/${place.id}/event/${event.id}`,
				description: formatDate(event.begin),
			}),
		);
	}

	if (currentPage) {
		crumbs.push(<span>{currentPage}</span>);
	}

	return (
		<div className={s.breadcrumbContainer}>
			<PrimitiveBreadcrumbs crumbs={crumbs} />
		</div>
	);
}
