export const fiscalCouvertForm = {
	fiscalCouvertForm: {
		editOfPlace: "Edit fiscal couvert of {{ placeName }}",
		fields: {
			code: {
				label: "SKU",
				placeholder: "Enter the SKU",
			},
			NCM: {
				label: "NCM",
				placeholder: "Enter the NCM",
			},
			CEST: {
				label: "CEST",
				placeholder: "Enter the CEST",
			},
			taxProfile: {
				label: "Tax Profile",
			},
			taxGroup: {
				label: "Tax Group",
			},
		},
	},
};
