export const general = {
	fieldsAddress: {
		cep: {
			label: "CEP",
			placeholder: "00000-000",
		},
		complement: {
			label: "Complemento",
			placeholder: "Inserir complemento",
		},
		county: {
			label: "Município",
			placeholder: "Inserir município",
		},
		neighborhood: {
			label: "Bairro",
			placeholder: "Inserir bairro",
		},
		number: {
			label: "Número",
			placeholder: "Inserir número",
		},
		publicplace: {
			label: "Logradouro",
			placeholder: "Inserir logradouro",
		},
		uf: {
			label: "UF",
			placeholder: "Selecionar estado",
		},
	},
	fieldsGeneral: {
		email: {
			label: "Email",
			placeholder: "Inserir email",
		},
		fiscalMode: {
			label: "Modo fiscal",
			placeholder: "Selecionar modo fiscal",
		},
		enableManifestation: {
			label: "Habilitar busca de NFs emitidas para este CNPJ para importação em estoque",
		},
		name: {
			label: "Nome",
			placeholder: "Nome do perfil",
		},
		phone: {
			label: "Telefone",
			placeholder: "Inserir telefone",
			error: "Telefone inválido: deve conter de 10 à 11 dígitos com DDD",
			errorIvaVendus: "Telefone inválido: deve conter 9 dígitos",
			errorIvaFactura: "Telefone inválido: deve conter 9 a 12 dígitos",
		},
	},
	optionsFiscalMode: {
		ivaFactura: "IVA - Factura",
		ivaVendus: "IVA - Vendus",
		nfce: "NFC-e",
		sat: "SAT",
	},
	optionsEnableManifestation: {
		allow: "Sim, permitir busca de notas fiscais emitidas para esse CNPJ",
		notAllow: "não permitir busca de notas fiscais",
	},
};
