export const coalaAddMenuModal = {
	editMenu: "Editar cardápio",
	addMenu: "Adicionar um novo cardápio",
	menuForm: {
		delivery: "Delivery",
		table: "Pedido na mesa",
	},
	menuInformation: "Informações do cardápio",
	name: "Nome do cardápio",
	type: "Tipo de cardápio",
	workingPeriods: "Horário de funcionamento do cardápio",
	save: "Salvar alterações",
	create: "Criar cardápio",
	day: "Dia",
	startTime: "Horário de inicio",
	endTime: "Horário de fim",
	start: "Começo",
	end: "Término",
	validateField: "Campo obrigatório",
};
