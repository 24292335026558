export const entranceBilling = {
	tabs: {
		boxOfficeBilling: {
			title: "Ticket Billing",
			tabLabel: "Billing",
		},
		entranceReport: {
			title: "Total Tickets Sold",
			tabLabel: "Tickets Sold",
			tabs: {
				total: "Total",
				clients: "Customers by Ticket",
			},
			columns: {
				name: "Name",
				count: "The amount",
				unitValue: "Unitary value",
				subtotal: "Subtotal",
				discountValue: "Discounts",
				totalValue: "Amount",
			},
		},
	},
};
