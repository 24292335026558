import { EnterpriseApiSignature } from "#resources/helpers/api";

export const PRODUCTS_KEYS = {
	getPlaceProducts: ({ placeId }: { placeId: string }) => [
		"getPlaceProducts",
		{ placeId },
	],
	getPlaceProductsByType: ({
		placeId,
		productType,
		order,
		orderBy,
		pagination,
		productName,
	}: EnterpriseApiSignature<"getPlaceProductsByType">["args"]) => [
		"getPlaceProductsByType",
		{ placeId, productType, order, orderBy, pagination, productName },
	],
} as const;
