import React, { useMemo } from "react";
import styles from "./grid.module.scss";
import { useMediaQuery } from "usehooks-ts";
import { BreakingPoints, GridTemplateAreas } from "./types";
import { BREAKING_POINT_QUERIES } from "./constants";
import { getFormattedGridTemplateAreasAndRows } from "./helpers";
import classNames from "classnames";

type GridProps = {
	children: React.ReactNode;
	areas: GridTemplateAreas;
	breakingPointsAreas?: { [key in BreakingPoints]?: GridTemplateAreas };
	className?: string;
};

export const Grid: React.FC<GridProps> = ({
	children,
	areas,
	breakingPointsAreas,
	className,
}) => {
	const smBp = useMediaQuery(BREAKING_POINT_QUERIES.sm);
	const mdBp = useMediaQuery(BREAKING_POINT_QUERIES.md);
	const lgBp = useMediaQuery(BREAKING_POINT_QUERIES.lg);
	const xlBp = useMediaQuery(BREAKING_POINT_QUERIES.xl);
	const xlBp2 = useMediaQuery(BREAKING_POINT_QUERIES["2xl"]);
	const xlBp3 = useMediaQuery(BREAKING_POINT_QUERIES["3xl"]);

	const { gridAreas, gridRows } = useMemo(() => {
		if (xlBp3 && breakingPointsAreas?.["3xl"]) {
			return getFormattedGridTemplateAreasAndRows(breakingPointsAreas?.["3xl"]);
		}

		if (xlBp2 && breakingPointsAreas?.["2xl"]) {
			return getFormattedGridTemplateAreasAndRows(breakingPointsAreas?.["2xl"]);
		}

		if (xlBp && breakingPointsAreas?.xl) {
			return getFormattedGridTemplateAreasAndRows(breakingPointsAreas?.xl);
		}

		if (lgBp && breakingPointsAreas?.lg) {
			return getFormattedGridTemplateAreasAndRows(breakingPointsAreas?.lg);
		}

		if (mdBp && breakingPointsAreas?.md) {
			return getFormattedGridTemplateAreasAndRows(breakingPointsAreas?.md);
		}

		if (smBp && breakingPointsAreas?.sm) {
			return getFormattedGridTemplateAreasAndRows(breakingPointsAreas?.sm);
		}

		return getFormattedGridTemplateAreasAndRows(areas);
	}, [smBp, mdBp, lgBp, xlBp, xlBp2, xlBp3, breakingPointsAreas, areas]);

	return (
		<div
			className={classNames(styles.grid, className)}
			style={{
				gridTemplateAreas: gridAreas,
				gridTemplateRows: gridRows,
			}}
		>
			{children}
		</div>
	);
};
