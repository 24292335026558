import { fetchModel } from "#resources/helpers/fetch-model";
import { showErrorNotification } from "#resources/helpers/notifications";
import enterprise from "#api/enterprise-client";

import { RootStore } from ".";
import {
	Cycle,
	EventSummary,
	HawkerCashierAdjustment,
	HawkerCashierClosing,
	HawkerCashierFinancialSummary,
	HawkerCashierHawkerClosing,
	HawkerCashierPayment,
	HawkerCashierReleasedProduct,
	HawkerPaymentMethod,
	HawkerReport,
	HawkerSaleType,
	HawkersSummary,
	Pagination,
	SalesPaginated,
	Sessions,
} from "#resources/api/enterprise-generated";

export class HawkersStore {
	public rootStore: RootStore;

	constructor(rootSore: RootStore) {
		this.rootStore = rootSore;
	}

	public clean() {
		this.getHawkerSales.reset();
		this.getHawkerPayments.reset();
		this.getHawkersRanking.reset();
		this.getHawkerSessions.reset();
		this.getHawkerCycles.reset();
		this.getHawkersSummary.reset();
		this.getHawkerReport.reset();
		this.getHawkerCashierFinancialSummaryForClosingReport.reset();
		this.getHawkerCashierHawkerClosingsForClosingReport.reset();
		this.getHawkerCashierAdjustmentsForClosingReport.reset();
		this.getHawkerCashierPaymentsForClosingReport.reset();
		this.getHawkerCashierReleasedProductsForClosingReport.reset();
		this.getHawkerCashiersClosingsForEvent.reset();
	}

	public getHawkerSales = new fetchModel<
		{
			eventId: string;
			type: HawkerSaleType;
			pagination: Pagination;
			hawkerIds?: string[] | null;
		},
		SalesPaginated
	>({
		fnPromise: args => enterprise.getHawkerSales(args),
		onError: err => showErrorNotification(err.message),
	});

	public getHawkerPayments = new fetchModel<
		{
			eventId: string;
			pagination: Pagination;
			hawkerIds?: string[] | null;
		},
		HawkerPaymentMethod
	>({
		fnPromise: args => enterprise.getHawkerPayments(args),
		onError: err => showErrorNotification(err.message),
	});

	public getHawkersRanking = new fetchModel<
		{
			eventId: string;
		},
		HawkersSummary[]
	>({
		fnPromise: args => enterprise.getHawkersRanking(args),
		onError: err => showErrorNotification(err.message),
	});

	public getHawkerSessions = new fetchModel<
		{
			eventId: string;
		},
		Sessions[]
	>({
		fnPromise: args => enterprise.getHawkerSessions(args),
		onError: err => showErrorNotification(err.message),
	});

	public getHawkerCycles = new fetchModel<
		{
			eventId: string;
			hawkerId: string;
			sessionId: string;
		},
		Cycle[]
	>({
		fnPromise: args => enterprise.getHawkerCycles(args),
		onError: err => showErrorNotification(err.message),
	});

	public getHawkersSummary = new fetchModel<
		{
			eventId: string;
		},
		EventSummary
	>({
		fnPromise: args => enterprise.getHawkersSummary(args),
		onError: err => showErrorNotification(err.message),
	});

	public getHawkerReport = new fetchModel<
		{
			eventId: string;
			hawkerId: string;
		},
		HawkerReport
	>({
		fnPromise: args => enterprise.getHawkerReport(args),
		onError: err => showErrorNotification(err.message),
	});

	public getHawkerCashiersClosingsForEvent = new fetchModel<
		{ eventId: string },
		HawkerCashierClosing[]
	>({
		fnPromise: args => enterprise.getHawkerCashiersClosingsForEvent(args),
		onError: error => showErrorNotification(error.message),
	});

	public getHawkerCashierFinancialSummaryForClosingReport = new fetchModel<
		{ id: string; eventId: string },
		HawkerCashierFinancialSummary
	>({
		fnPromise: args => enterprise.getHawkerCashierFinancialSummaryForClosingReport(args),
		onError: error => showErrorNotification(error.message),
	});

	public getHawkerCashierHawkerClosingsForClosingReport = new fetchModel<
		{ id: string; eventId: string },
		HawkerCashierHawkerClosing[]
	>({
		fnPromise: args => enterprise.getHawkerCashierHawkerClosingsForClosingReport(args),
		onError: error => showErrorNotification(error.message),
	});

	public getHawkerCashierAdjustmentsForClosingReport = new fetchModel<
		{ id: string; eventId: string },
		HawkerCashierAdjustment[]
	>({
		fnPromise: args => enterprise.getHawkerCashierAdjustmentsForClosingReport(args),
		onError: error => showErrorNotification(error.message),
	});

	public getHawkerCashierPaymentsForClosingReport = new fetchModel<
		{ id: string; eventId: string },
		HawkerCashierPayment[]
	>({
		fnPromise: args => enterprise.getHawkerCashierPaymentsForClosingReport(args),
		onError: error => showErrorNotification(error.message),
	});

	public getHawkerCashierReleasedProductsForClosingReport = new fetchModel<
		{ id: string; eventId: string },
		HawkerCashierReleasedProduct[]
	>({
		fnPromise: args => enterprise.getHawkerCashierReleasedProductsForClosingReport(args),
		onError: error => showErrorNotification(error.message),
	});
}
