export const stockTransferHistory = {
	titlePage: "Stock Transfers",
	description:
		"This report allows tracking movements between stocks, providing adequate visibility of transfers for stock keepers and managers.",
	actionButtons: {
		print: "Print",
		downloadExcel: "Download in Excel",
	},
	filterToPeriod: "Filter by period",
	column: {
		dateTransfer: "Transfer Date",
		employee: "Employee",
		outgoingStock: "Outgoing Stock",
		incomingStock: "Incoming Stock",
		inputAndProduct: "Input/Product",
		amount: "Amount",
	},
	employeePlaceholder: "Employee",
	storagePlaceholder: "Storage",
	searchBy: "Search by",
};
