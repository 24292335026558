import { importNewList } from "./import-new-list";
import { newList } from "./new-list";
import { report } from "./report";
import { infos } from "./infos";
import { promoter } from "./promoter";

export const guestList = {
	title: "Lista de Convidados",
	importList: "Importar Lista",
	createList: "Criar Lista",
	searchList: "Pesquisar lista",
	list_one: "lista",
	list_other: "listas",
	guestsNotImported: "Convidados não importados",
	addGuestError: {
		relationReachesLimit: "Lista já atingiu o limite de convidados",
		userAlreadyExists: "Convidado já cadastrado na lista",
		userWithoutDocument: "Convidado sem documento",
	},
	columns: {
		name: "Nome da Lista",
		description: "Descrição",
		userCount: "Convidados",
		guest: "Convidado",
		expiresAt: "Expiração",
		status: "Status",
		active: "ATIVO",
		inactive: "DESATIVADO",
	},
	deleteModal: {
		title: "Excluir Lista",
		message: "Tem certeza que deseja excluir esta(s) listas?",
		confirm: "Excluir Lista",
	},
	importNewList,
	newList,
	report,
	promoter,
	emptyList: {
		defaultMessage: "Parece que você não criou uma lista ainda",
		defaultText: "As listas aparecerão aqui quando você tiver uma lista cadastrada.",
	},
	infos,
	menuDrop: {
		disable: "Desativar lista",
		enable: "Ativar lista",
		download: "Download",
		print: "Imprimir",
		edit: "Editar",
		delete: "Deletar",
	},
	sheet: {
		filename: "lista_{{name}}",
		title: "Lista: {{name}}",
		columns: {
			name: "Nome",
			cpf: "CPF",
			phone: "Telefone",
			promoter: "Promoter",
		},
		example: "lista (exemplo)",
		error: "Planilha inválida",
	},
	list: {
		goBack: "Voltar para listas",
		import: {
			label: "Importar planilha de convidados",
			description:
				"Para importar uma lista de convidados basta fazer upload de uma planilha",
		},
		sheet: {
			title: "Lista de convidados",
			error:
				"Planilha inválida, baixe-a novamente e faça as alterações necessárias. Em caso de linhas vazias em vermelho, exclua e tente novamente.",
		},
		success: "Planilha importada com sucesso",
		warning:
			"Antes de importar a planilha, garanta que todas as linhas em branco sejam removidas",
		delete: {
			permanentlyList: "Deletar lista permanentemente",
			permanentlyLists: "Deletar listas permanentemente",
			list: "Deletar lista",
			lists: "Deletar listas",
			selectedList:
				"A lista selecionada será removida permanentemente e suas informações (convidados, check-in, ticket médio, entrada, consumo, promoters) serão removidas dos relatórios. Essa ação não poderá ser desfeita. Deseja continuar assim mesmo?",
			selectedLists:
				"As listas selecionadas serão removidas permanentemente e suas informações (convidados, check-in, ticket médio, entrada, consumo, promoters) serão removidas dos relatórios. Essa ação não poderá ser desfeita. Deseja continuar assim mesmo?",
		},
		print: {
			noEntrance: "Sem entrada associada a essa lista.",
			date: "Data da emissão",
			entrance: "Entrada associada",
			discount: "Tipo de desconto",
			description: "Descrição",
			expiresAt: "Expira às",
		},
	},
	utils: {
		expiresAt: "Expira em",
		hasExpired: "Expirou há",
		expires: "Expira",
		invalidName: "Nome inválido",
		insertLastName: "Insira um sobrenome",
	},
};
