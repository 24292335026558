export const promotionInfo = {
	title: "Promotion data",
	description:
		"Set all the necessary parameters to create a specialized promotion for your customers.",
	promotionName: {
		label: "Promotion name",
		placeholder: "Enter the name of the promotion",
	},
	promotionDescription: {
		label: "Promotion description",
		placeholder: "Enter the description of the promotion",
	},
	expiration: {
		label: "Expiration",
		info:
			"The promotion will be valid until the selected date. When the expiration date comes, the promotion will be inactive and you can change the expiration date.",
		notExpire: "Does not expire",
	},
	automaticMessage: {
		label: "Automated messages to customers",
		info:
			"All customers who win the promotion receive automatic messages from the selected channel. These shipments cost",
		sendMessage: "Send automatic messages",
	},
	whatsUp: {
		label: "View promotions on 'Whats Up'",
		info: "All enabled promotions will be displayed in 'Whats Up'.",
		showPromotions: "View promotions on 'Whats Up'",
	},
	places: "Promotion locations",
	errors: {
		requiredField: "This field is required",
		dateFormat: "Invalid date format",
		dayDiff: "At least one day difference required",
		selectPlace: "Please select at least one location",
	},
};
