export const config = {
	title: "Configurações de loja delivery",
	updatingData: "Atualizando dados",
	savedData: "Dados salvos",
	newImageSaved: "Nova imagem salva",
	form: {
		initialInfo: "informações iniciais",
		addressInfo: "informações de endereço",
		deliveryInfo: "informações de entrega",
		webpageInfo: "informações de visualização",
		paymentMethods: "métodos de pagamento",
		additionalFields: "campos adicionais",
		qrCodesGenerate: "Gerar QR Codes",
	},
	subForms: {
		additionalFields: {
			empty: "Não existem campos cadastrados",
			add: "Adicionar novo campo",
			label: "Legenda",
			slug: "Slug",
			required: "Obrigatório",
			type: {
				label: "Tipo",
				text: "Alfanumérico",
				number: "Número",
				date: "Data",
				select: {
					label: "Múltipla escolha",
					a: "Opção A",
					b: "Opção B",
					c: "Opção C",
				},
				remove: "Remover",
				options: {
					label: "Opções",
					placeholder: "Adicione uma nova escolha",
				},
			},
			save: "Salvar campos adicionais",
		},
		address: {
			formattedAddress: "Endereço",
			complement: "Complemento",
			postalCode: "CEP",
			streetName: "Nome da rua",
			streetNumber: "Número da rua",
			neighborhood: "Bairro",
			city: "Cidade",
			state: "Estado",
			country: "País",
			save: "Salvar informações de endereço",
		},
		delivery: {
			attendanceRadius: {
				label: "Raio de atendimento",
				sufix: "km",
			},
			deliveryFeePerKm: "Taxa de entrega por Km",
			fixedDeliveryFee: "Taxa mínima de entrega",
			avgWaitTime: {
				label: "Tempo médio de entrega",
				sufix: "Minutos",
			},
			save: "Salvar informações de entrega",
		},
		initialInfo: {
			name: "Nome",
			isManualMenu: "Ativação manual de cardápio",
			isOrganization: "Modo organização",
			save: "Salvar informações iniciais",
		},
		payments: {
			methods: {
				creditCard: "Cartão de crédito",
				debitCard: "Cartão de débito",
				cash: "Dinheiro",
				creditCardOnline: "Cartão de crédito online",
				others: "Outras formas de pagamento",
				voucherInPlace: "Voucher",
				mastercard: "Master-card",
				visa: "Visa",
				amex: "Amex",
				elo: "Elo",
				hipercard: "Hiper-card",
				diners: "Diners",
				ticket: "Ticket",
				alelo: "Alelo",
				vr: "VR",
				ben: "Ben",
				sodexo: "Sodexo",
				pix: "Pix",
			},
			save: "Salvar métodos de pagamento",
		},
		qrCodeGenerator: {
			columns: {
				url: "URL",
				params: "Parâmetro",
				value: "Valor do parâmetro",
			},
			warningNotification: "Preencha todos os campos.",
			urlRequired: "URL é obrigatório",
			paramsRequired: "Parâmetro é obrigatório",
			valueRequired: "Valor do parâmetro é obrigatório",
			new: "Novo QR code",
			download: "Baixar QR codes",
			import: "Importar QR codes",
			chooseSheet: "Escolha uma planilha",
			sheetFilename: "QR codes",
		},
		webPage: {
			image: "Imagem",
			slug: "Slug",
			primaryColor: "Cor Primária",
			secondaryColor: "Cor Secundária",
			save: "Salvar informações de visualização",
		},
	},
};
