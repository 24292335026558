import { IInputProps, Input } from "..";
import * as React from "react";

import ReactInputMask, { InputMaskProps } from "react-input-mask";

export type IMaskedInputProps = InputMaskProps & IInputProps;

export function MaskedInput(props: IMaskedInputProps) {
	return (
		<ReactInputMask {...props}>
			{/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */
			/* @ts-ignore */}
			{inputProps => (
				<Input
					{...inputProps}
					leadingIcon={props.leadingIcon}
					trailingIcon={props.trailingIcon}
				/>
			)}
		</ReactInputMask>
	);
}
