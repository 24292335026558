export const closings = {
	title: "Closing by vendor",
	download: "Download sheet",
	choosePeriod: "Choose period to export spreadsheet",
	sheet: {
		title: "Vendor Closing",
		filename: "vendors-closing",
		columns: {
			vendorName: "vendor",
			vendorValueToReceive: "Amount to receive",
			otherProductsFeeValue: "Variable Fee on Special Products",
			generalFeeValue: "General variable fee",
			eventDate: "Event Date",
			payDate: "Payment date",
			generalPercentageFee: "Variable fee on billing",
			fixedFee: "Fixed Rent Fee",
			totalSoldValue: "Total Billing",
		},
	},
	calendar: {
		totalSoldValue: "Total value",
		toReceive: "Receivable",
	},
	modal: {
		details: "Day details",
		vendorName: "Vendor",
		vendorValueToReceive: "Amount to receive",
		otherProductsFeeValue: "Variable Fee on Special Products",
		generalFeeValue: "General variable fee",
		eventDate: "Event Date",
		payDate: "Payment date",
		generalPercentageFee: "Variable fee on billing",
		fixedFee: "Fixed Rent Fee",
		totalSoldValue: "Total Billing",
	},
};
