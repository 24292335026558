export const fiscalCouvertForm = {
	editOfPlace: "Editar couvert fiscal de {{ placeName }}",
	fields: {
		code: {
			label: "SKU",
			placeholder: "Insira o SKU",
		},
		NCM: {
			label: "NCM",
			placeholder: "Insira o NCM",
		},
		CEST: {
			label: "CEST",
			placeholder: "Insira o CEST",
		},
		taxProfile: {
			label: "Perfil fiscal",
		},
		taxGroup: {
			label: "Grupo fiscal",
		},
	},
};
