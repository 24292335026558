export const storage = {
	zeroProducts: {
		noProproductIsSelected: "Nenhum produto selecionado",
	},
	removeProduct: {
		noProproductIsSelected: "Nenhum produto selecionado",
	},
	layout: {
		goBack: "Voltar",
	},
	indicators: {
		cost: {
			title: "Valor total em estoque",
		},
		turnover: {
			high: "Maior giro",
			low: "Menor giro",
		},
		zeroed: {
			title: "Produtos zerados",
			seeMore: "Ver Mais",
		},
		intervalNotice: "Valores referentes aos ultimos 90 dias",
		updatedAt: "Atualizado em {{date}}",
	},
	modalXML: {
		title: "Importar XML",
		warningMessage: {
			line1:
				"Selecione o estoque de destino, baixe a planilha exemplo, preencha e importe novamente.",
			line2:
				"Atenção! O valor dos produtos é informado em centavos. Ex: 123 equivale a {{currencySymbol}} 1,23",
		},
		form: {
			label: {
				storage: "Estoque",
				xml: "XML",
			},
			placeholder: {
				xml: "Escolha um arquivo .xml para importar",
			},
			validation: {
				missingField: "Campo obrigatório",
			},
			submit: "Importar Nota",
		},
	},
	modalManualChange: {
		title: {
			add: 'Adicionar produto "{{prodName}}"',
			subtract: 'Remover produto "{{prodName}}"',
		},
		okText: {
			add: "Adicionar produto",
			subtract: "Remover produto",
		},
		cancel: "Cancelar",
		message: {
			add: "Digite a quantidade de produto que você quer adicionar e o preço de custo",
			subtract:
				"Digite a quantidade que você deseja remover e uma observação caso necessário",
		},
		form: {
			hasProductionRuleWarning:
				"Este produto possui ficha técnica e seus itens serão reduzidos do estoque apenas quando estiverem zerados.",
			label: {
				quantity: "Quantidade",
				costPrice: "Preço de custo",
				obs: "Observação",
			},
			validation: {
				invalidQuantity: "Quantidade invalida",
				missingProductInfo: "Faltando informação de produto",
				missingActionInfo: "Faltando informação de ação",
			},
		},
	},
	header: {
		searchPlaceHolder: "Buscar por produto",
		importXML: "Importar XML",
		transferProducts: "Transferir produtos",
		addProducts: "Adicionar produtos",
	},
	table: {
		columns: {
			name: "produto",
			category: "categoria",
			quantity: "quantidade",
			action: "ação",
			lastCost: "último preço",
			avgCost: "preço médio",
			totalCost: "custo total",
		},
		actions: {
			zeroStorage: "Zerar estoque",
			removeProducts: "Remover produtos",
			label: "Ações em lote",
			message: "Selecione o check box para ações em lote",
		},
		confirmationModal: {
			warnignHiddenProductsInSearch:
				"Atenção, alguns produtos podem estar escondidos por causa da busca, limpe a busca para continuar",
			deleteSingle: {
				title: "Remover produto",
				message:
					"Ao confirmar a ação você remove permanentemente estes itens do estoque. Deseja continuar?",
				confirmMessage: "Sim, desejo remover",
			},
			deleteMultiple: {
				title: "Remover produto",
				message:
					"Ao confirmar a ação você remove permanentemente estes itens do estoque. Deseja continuar?",
				confirmMessage: "Sim, desejo remover",
			},
			zero: {
				title: "Zerar produtos",
				message:
					"Ao confirmar a ação você zera permanentemente todos os itens selecionados do estoque. Deseja continuar?",
				confirmMessage: "Sim, desejo zerar os produtos",
			},
		},
	},
};
