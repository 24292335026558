import * as React from "react";
import { Dayjs } from "dayjs";
import dayJsGenerateConfig from "rc-picker/lib/generate/dayjs";
import { RangeValue as RangeValueRcPicker } from "rc-picker/lib/interface";
import generatePicker, {
	PickerProps,
	RangePickerProps as RangePickerPropsUi,
} from "antd/lib/date-picker/generatePicker";

import "./date-picker.scss";

export type DatePickerAbsProps = PickerProps<Dayjs>;

export type RangeValue<DateType> = RangeValueRcPicker<DateType>;

const CustomDatePicker = generatePicker<Dayjs>(dayJsGenerateConfig);

export type RangePickerProps = RangePickerPropsUi<Dayjs>;

export function DatePicker({ ...props }: DatePickerAbsProps) {
	return <CustomDatePicker {...props} />;
}

function RangePicker({
	value,
	onChange,
	onCalendarChange: onCalendarChangeProp,
	...props
}: RangePickerProps) {
	const [isSelecting, setIsSelecting] = React.useState(false);

	return (
		<CustomDatePicker.RangePicker
			value={value}
			onCalendarChange={(period, formatString, info) => {
				onCalendarChangeProp && onCalendarChangeProp(period, formatString, info);

				if (!onChange) {
					return;
				}

				if (!isSelecting) {
					setIsSelecting(true);
				}

				const selectedAllDates = period?.every(date => date !== null);

				if (selectedAllDates && isSelecting) {
					onChange(period, formatString);
					setIsSelecting(false);
				}
			}}
			{...props}
		/>
	);
}

DatePicker.RangePicker = RangePicker;
DatePicker.TimePicker = CustomDatePicker.TimePicker;
DatePicker.WeekPicker = CustomDatePicker.WeekPicker;
DatePicker.MonthPicker = CustomDatePicker.MonthPicker;
DatePicker.QuarterPicker = CustomDatePicker.QuarterPicker;
DatePicker.YearPicker = CustomDatePicker.YearPicker;
