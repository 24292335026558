import { combo } from "./combo";
import { supplies } from "./supplies";
import { bars } from "./bars";
import { grabAndGo } from "./grab-and-go";
import { duplicateMountableProduct } from "./duplicate-mountable-products";
import { xlsxPicker } from "./xlsx-picker";
import { newAndEditProduct } from "./new-and-edit-product";
import { beerDispenser } from "./beer-dispenser";
import { cmv } from "./cmv";
import { personalizationSettings } from "./personalization-settings";
import { productArea } from "./product-area";
import { productDetail } from "./product-detail";
import { technicalSheet } from "./technical-sheet";

export const products = {
	productArea,
	productDetail,
	technicalSheet,
	duplicateMountableProduct,
	productsTab: {
		tabTitle: "Products",
		searchLabel: "Search",
		searchPlaceholder: "Search products...",
		productsByCategory: "Products by Category",
		sortByCategory: "Sort by category",
		sortByMenuCategory: "Sort by menu category",
		checkAllItems: "Select all products ({{counter}})",
		uncheckAllItems: "Deselect all products ({{counter}})",
		addProductButton: "Add Product",
		exportToExcelButton: "Export report",
		importProductsButton: "Import products",
		syncWithDelivery: "Sync with delivery",
		editProductsViaExcel: "Edit products via excel",
		buttonDownloadQrCodes: "Download QR codes",
		downloadQrCodeAppSell: "Download sales QR codes for the Zig App",
		editProducts: "Edit Products",
		deleteProducts: "Remove products",
		deleteSelectedProducts: "Delete selected",
		table: {
			activeBars: "Active Bars",
			name: "Product",
			fiscalData: "Tax Data",
			mountableDescription: "Mountable Product",
			hasProductionRule: "Datasheet",
			value: "Price",
			active: "Activated",
			actions: "Actions",
			edit: "To edit",
			duplicate: "Duplicate buildable product",
			delete: "Delete",
			yesOption: "Yes",
			noOption: "No",
			onDeleteProductMessage: "Do you want to delete the product {{productName}}?",
			variable: "Variable",
			fiscalCode: "SKU",
		},
		onDeleteMessage: {
			title_one: "Do you want to remove {{counter}} product?",
			title_other: "Do you want to remove {{counter}} products?",
			content: "Heads up! Products will be permanently removed.",
			okText: "Yes",
			cancelText: "No",
		},
		syncModal: {
			title: "Sync products",
			chooseYourMenusMultiSelect: "Choose your menus",
			chooseTheVariablesCheckbox: {
				title:
					"Choose the variables that will be synchronized for products already registered in the delivery:",
				all: "All",
				price: "Price",
				category: "Category",
				subcategory: "Subcategory",
				order: "Order",
				picture: "Photograph",
				name: "Name",
				description: "Description",
				mountableItems: "Buildable items",
			},
			activeSyncOptionCheckbox: "Activate synced products for delivery sale",
			alertMessage:
				"Products that are not in delivery will be created with the same information",
			successFeedback: "Success, all products synchronized!",
			warningFeedback: "Some products had sync issues.",
			warningFeedbackTable: {
				type: "Type",
				description: "Description",
			},
		},
		importModal: {
			import: "Import",
			downloadSheet: "Download spreadsheet",
			title: "Import spreadsheet devices",
			sheetFileName: "Products",
			buttonText: "Choose sheet",
			activateProduct: "Activate imported products",
			alertMessage:
				"Heads up! the value of the products is informed in cents, ex: 123 is equivalent to R$ 1.23",
			table: {
				columns: {
					product: "Product",
					error: "Error",
					description: "Description",
				},
				errors: {
					invalidTaxData: "Tax data",
					ncm: "NCM",
					cest: "CEST",
					duplicatedFiscalCode: "Duplicate SKU",
					productNotFound: "Product not found",
					categoryWithSubcategory: "Category",
					supercategoryNotFound: "Product type",
					name: "Name",
					category: "Category",
					hasAlcohol: "Contains alcohol",
					isHiddenFromApp: "Hide product",
					value: "Price",
					kind: "product type",
					beerAbv: "ABV - Alcohol content",
					other: "Unknown",
				},
				errorsDescription: {
					name: "Product name not informed",
					category: "Unspecified product category",
					hasAlcohol: "Field must be true or false",
					isHiddenFromApp: "Field must be true or false",
					value: "Product price is invalid",
					kind: "The product type is invalid",
					beerAbv: "ABV - Alcohol content must be a number",
					other: "Unknown error",
				},
			},
		},
		xlsxPicker,
		getTitleDialogActive: "Activate product",
		getTitleDialogDisable: "Disable product",
		getDescriptionDialogActive:
			"Do you want to activate this product in the mountable products it composes?",
		getDescriptionDialogDisable:
			"Do you want to disable this product in the mountable products it composes?",
		findFiscalGroupError: "Registered fiscal group not found",
		findFiscalProfileError: "Registered fiscal profile not found",
		productWithoutCategory: "Products without menu category",
		onDeleteSelectProductsError: "Oops! A problem has occurred, please try again",
		testEmission: {
			titleSuccess: "Testing Fiscal Data - Success",
			titleError: "Oops, we found some errors",
			titleTesting: "Testing Fiscal Data",
			updating: "Updating products...",
			testing: "Testing {{currentTest}}/{{length}}",
			loadingText: "Please wait, we are testing selected products",
			success: "Tax data successfully tested",
			successDescription: "No errors were found in the data presented",
		},
		activeAllQuestion: "Activate all?",
		disableAllQuestion: "Disable all?",
		activeAll: "Activate all",
		disableAll: "Disable all",
		testFiscalData: "Test fiscal data",
		limitOver: "Limit above {{limit}} selected",
		deleteSelect: "Delete Selected",
		manageProducts: "Product Management",
		managePlaceProducts: "Manage place products",
	},
	newAndEditProduct,
	editProducts: {
		title: "Edit Product",
		categoryChange: "Change category:",
		categoryNone: "None",
		typeChange: "Change type:",
		typeNone: " None",
		save: "To save",
		confirm: "Do you want to modify the following products?",
	},
	combo,
	supplies,
	bars,
	grabAndGo,
	beerDispenser,
	cmv,
	personalizationSettings,
	productFeatureForm: {
		validateMessage: "This field is required.",
		typeHere: "Type here...",
		selectHere: "Select here...",
		templatesOptionsPlaceholder: "Select template",
		org: "ORG",
		zig: "ZIG",
		observation: "Observation",
		fields: "Fields",
	},
};
