import React from "react";

import NotificationIcon from "#assets/svg/bell.svg";

import { NotificationsProvider, useNotifications } from "./notifications.context";
import { NotificationsContent } from "./notifications-content";

import s from "./notifications.scss";

export function Notifications() {
	return (
		<NotificationsProvider>
			<NotificationsComponent />
		</NotificationsProvider>
	);
}

function NotificationsComponent() {
	const {
		hasUnreadNotifications,
		showNotifications,
		onOpenNotifications,
		onCloseNotifications,
	} = useNotifications();

	React.useEffect(() => {
		if (hasUnreadNotifications) {
			onOpenNotifications();
		}
	}, [hasUnreadNotifications]);

	return (
		<div className={s.container}>
			<div
				className={s.iconContainer}
				onClick={showNotifications ? onCloseNotifications : onOpenNotifications}
			>
				<NotificationIcon />
				{hasUnreadNotifications && <div className={s.hasNotifications}></div>}
			</div>

			{showNotifications && <NotificationsContent />}
		</div>
	);
}
