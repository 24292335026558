import { TagLabel } from "#components/primitive";
import {
	ProductSoldAtPlace,
	ProductValuesHistory,
} from "#resources/api/enterprise-generated";
import { useCurrency } from "#resources/hooks";
import { Table } from "antd";
import { ColumnsType } from "antd/lib/table";
import React from "react";
import s from "./table-products-sold.module.scss";

import { sumField } from "../helpers/calc";
import { TableEmptyState } from "#components/table-empty-state";
import { useTranslation } from "react-i18next";
import { ExpandIcon } from "../components/expand-icon";

type TableProductsSoldProps = {
	dataSource: ProductSoldAtPlace[];
	barName: string;
};

export const TableProductsSold = ({ dataSource, barName }: TableProductsSoldProps) => {
	const { t } = useTranslation("place", {
		keyPrefix: "barReports.salesReport.totalSales.tableProductsSold",
	});
	const { formatCurrency } = useCurrency();

	const columns: ColumnsType<ProductSoldAtPlace> = [
		{
			key: "fiscalCode",
			dataIndex: "fiscalCode",
			title: t("sku"),
		},
		{
			key: "image",
			dataIndex: "image",
			title: "",
			render: (value: ProductSoldAtPlace["image"]) => {
				if (!value?.url) {
					return <div className={s.productImage} />;
				}

				return (
					<img src={value.url} alt={t("productImageAlt")} className={s.productImage} />
				);
			},
		},
		{ key: "name", dataIndex: "name", title: t("name") },
		{ key: "count", dataIndex: "count", title: t("unitQuantity"), align: "right" },
		{
			key: "unitValue",
			dataIndex: "unitValue",
			title: t("unitValue"),
			align: "right",
			render: (value: number, r) =>
				r.productValues && r.productValues?.length > 1 ? (
					<TagLabel type="info">
						{t("values", {
							length: r.productValues?.length,
						})}{" "}
					</TagLabel>
				) : (
					formatCurrency(value)
				),
		},
		{
			key: "subtotal",
			dataIndex: "subtotal",
			title: t("subtotal"),
			align: "right",
			render: value => formatCurrency(value),
		},
		{
			key: "discount",
			dataIndex: "discount",
			title: t("discounts"),
			align: "right",
			render: value => formatCurrency(value),
		},
		{
			key: "totalValue",
			dataIndex: "totalValue",
			title: t("totalValue"),
			align: "right",
			render: value => formatCurrency(value),
		},
	];

	const countAmount = sumField(dataSource, "count");
	const subtotalAmount = sumField(dataSource, "subtotal");
	const discountAmount = sumField(dataSource, "discount");
	const totalAmount = sumField(dataSource, "totalValue");

	return (
		<div className={s.container}>
			<Table
				rowKey="name"
				columns={columns}
				dataSource={dataSource}
				pagination={{}}
				scroll={{
					x: "100%",
				}}
				expandable={{
					expandIcon: ({ expanded, onExpand, record, expandable }) =>
						expandable ? (
							<ExpandIcon expanded={expanded} onExpand={e => onExpand(record, e)} />
						) : null,
					expandIconColumnIndex: 9,
					columnWidth: 53,
					rowExpandable: record =>
						Boolean(record.productValues && record.productValues?.length > 1),
					expandedRowRender: record => {
						const columns: ColumnsType<ProductValuesHistory> = [
							{
								key: "name",
								dataIndex: "name",
								title: t("name"),
								render: () => record.name,
							},
							{
								key: "category",
								dataIndex: "category",
								title: t("category"),
								render: () => "",
							},
							{
								key: "count",
								dataIndex: "count",
								title: t("unitQuantity"),
								align: "right",
							},
							{
								key: "unitValue",
								dataIndex: "unitValue",
								title: t("unitValue"),
								align: "right",
								render: (value: number) => formatCurrency(value),
							},
							{
								key: "subtotal",
								dataIndex: "subtotal",
								title: t("subtotal"),
								align: "right",
								render: value => formatCurrency(value),
							},
							{
								key: "discount",
								dataIndex: "discount",
								title: t("discounts"),
								align: "right",
								render: value => formatCurrency(value),
							},
							{
								key: "totalValue",
								dataIndex: "totalValue",
								title: t("totalValue"),
								align: "right",
								render: value => formatCurrency(value),
							},
						];

						return (
							<Table
								columns={columns}
								dataSource={record.productValues || []}
								pagination={false}
								size="small"
							/>
						);
					},
				}}
				locale={{
					emptyText: <TableEmptyState emptyType="noFilters" description={<></>} />,
				}}
				summary={() => (
					<Table.Summary fixed={"top"}>
						<Table.Summary.Row
							style={{ backgroundColor: "var(--color-success-lightest)" }}
						>
							<Table.Summary.Cell index={0} colSpan={3}>
								<b>
									{t("totalInBar", {
										barName,
									})}
								</b>
							</Table.Summary.Cell>
							<Table.Summary.Cell index={1} colSpan={1} align="right">
								<b>{countAmount}</b>
							</Table.Summary.Cell>
							<Table.Summary.Cell index={2} colSpan={2} align="right">
								<b>{formatCurrency(subtotalAmount)}</b>
							</Table.Summary.Cell>
							<Table.Summary.Cell index={3} colSpan={1} align="right">
								<b>{formatCurrency(discountAmount)}</b>
							</Table.Summary.Cell>
							<Table.Summary.Cell index={4} colSpan={1} align="right">
								<b>{formatCurrency(totalAmount)}</b>
							</Table.Summary.Cell>
							<Table.Summary.Cell index={5} colSpan={1} />
						</Table.Summary.Row>
					</Table.Summary>
				)}
				sticky
			/>
		</div>
	);
};
