export const emissionErrors = {
	title: "Erros de emissão",
	period: "Período",
	export: "Exportar Excel",
	drawer: {
		title: "Histórico de erros",
		message: "Mensagem",
		type: "Tipo de erro",
		profile: "Perfil fiscal",
		count: "Quantidade",
		date: "Primeiro dia não emitido",
		updatedAt: "Último dia não emitido",
		status: "Status",
		details: "Detalhes da última nota com erro",
		products: "Produtos",
		clients: "Clientes",
	},
	tabs: {
		invoices: "Notas não emitidas",
		products: "Produtos não emitidos",
	},
	table: {
		statusLabel: {
			pending: "Pendente",
			resolved: "Resolvido",
		},
		errorTypeLabel: {
			unknown: "Desconhecido",
		},
		columns: {
			errorMessage: "Mensagem",
			errorType: "Tipos de erro",
			fiscalProfileId: "Perfil fiscal",
			count: "Quantidade",
			createdAt: "Primeiro dia não emitido",
			updatedAt: "Último dia não emitido",
			status: "Status",
		},
	},
};
