export const products = {
	title: "Produtos",
	header: {
		search: "Buscar produtos",
		edit: "Editar produtos em massa",
		delete: "Apagar produtos",
		deleteConfirm: "Tem certeza que deseja apagar todos os produtos selecionados?",
		massDelete: "Apagar produtos em massa",
		add: "Adicionar produtos",
	},
	error:
		"não foi possivel intentificar o produto corespondente de {{name}}, baixe uma nova planilha modelo e tenha cuidado com a coluna 'indentificador'",
	sucess: "Todos produtos editados com sucesso",
	errorIn: "Erro em ",
	bottomText: "Todas as alterações feita aqui irão afetar todos os cardápios",
};
