export const emissionErrors = {
	title: "Emission Errors",
	period: "Period",
	export: "Export Excel",
	drawer: {
		title: "Error History",
		message: "Message",
		type: "Type of error",
		profile: "Tax profile",
		count: "Quantity",
		date: "First day not issued",
		updatedAt: "Last day not issued",
		status: "Status",
		details: "Details of the last note in error",
		products: "Products",
		clients: "Clients",
	},
	tabs: {
		invoices: "Notes issued",
		products: "Products not issued",
	},
	table: {
		statusLabel: {
			pending: "Pending",
			resolved: "Resolved",
		},
		errorTypeLabel: {
			unknown: "Unknown",
		},
		columns: {
			errorMessage: "Message",
			errorType: "Error types",
			fiscalProfileId: "Fiscal Profile",
			count: "Quantity",
			createdAt: "First day not issued",
			updatedAt: "Last day not issued",
			status: "Status",
		},
	},
};
