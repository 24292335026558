export const fiscalSettings = {
	form: {
		title: "Fiscal data for Couvert '{{name}}'",
		subtitle: "Update fiscal data for'{{name}}'",
		code: "SKU",
		ncm: "NCM",
		cest: "CEST",
		fiscalProfile: "Fiscal Profile",
		fiscalGroup: "Fiscal Group",
		optionalFields: "These fields are optional, but are necessary for issuing invoices",
		onlyNumbers: "Numbers only",
		cst: "CST",
		quantitySold: "Quantity sold",
		aliquota: "Aliquot",
		calculo: "Base of Calculation",
		invoice: "Invoice",
		service: "Service",
		vat: "Value-added tax",
		valueAdded: "Value-added",
		type: "Type",
		name: "Name",
		cfop: "CFOP",
		csosn: "CSOSN",
		origin: "Origin",
		reduceCalculo: "Reduction in the calculation base",
		servideCode: "Service Code",
		tributacao: "Code of taxation of the municipality",
		generalFields: "General Fields",
		icms: "ICMS",
		pis: "PIS",
		cofins: "COFINS",
		requiredField: "This field is required",
		createProfile: "Create fiscal profile",
		updateProfile: "Update fiscal profile",
		generalData: "General Data",
		companyData: "Company Data",
		cnpj: "CNPJ",
		inscricaoEstadual: "Registration State",
		fantasyName: "Fantasy Name",
		socialName: "Social Name",
		regimeTributario: "Tax regime",
		cityCode: "City Code",
		municipalRegistration: "Municipal Registration",
		address: "Address",
		zipCode: "ZIP",
		street: "Street",
		number: "Number",
		adjunct: "Add-on",
		neighborhood: "Neighborhood",
		city: "City",
		state: "State",
		contact: "Contact",
		email: "Email",
		phone: "Phone",
		fiscal: "Fiscal",
		fiscalMode: "Fiscal Mode",
		sat: "SAT-e",
		satNumber: "SAT Serial Number",
		nfce: "NFC-e",
		certificate: "Certificate",
		certificatePassword: "Certificate Number",
		expirationDate: "Expiration Date",
		cscHomologation: "CSC NFC-e Homologation",
		idHomologation: "NFC-e Homologation Id",
		cscProduction: "CSC NFC-e Production",
		idProduction: "NFC-e Production Id",
		zipCodeValidation: "CEP must have 8 numbers",
	},
	emptyData: "There is no data to display.",
	fiscalDataPlace: "Local Coverage Fiscal Data",
	unavailable: "Unavailable",
	fiscalGroupProduct: "Product fiscal groups",
	addNewGroup: "Add new group",
	addNewProfile: "Add new profile",
	delete: "Are you sure you want to delete?",
	expireIn: "Expires in",
	generalSettings: "Fiscal Settings",
	searchFiscalProfile: "Search fiscal profiles",
	searchFiscalGroup: "Search fiscal groups",
};
