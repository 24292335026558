import * as React from "react";
import { useTranslation } from "react-i18next";

import s from "./main-logo.scss";
import classNames from "classnames";

type IMainLogoProps = React.HTMLAttributes<HTMLDivElement>;

function MainLogo({ ...props }: IMainLogoProps) {
	const { t } = useTranslation("components");
	return <div className={classNames(s.mainLogo, props.className)}>{t("logo")}</div>;
}

export default MainLogo;
