export const booking = {
	reserve: {
		label: "Pacotes",
		search: "Pesquise por pacotes",
		create: "Criar pacote",
		empty: {
			title: "Nenhum pacote para esse evento",
			subtitle:
				"Crie seu primeiro pacote clicando no botão ¨Criar pacote¨ no topo dessa página",
		},
		reserveCard: {
			guests: "{{count}} convidados",
			promotions: "{{count}} promoções",
			none: "Nenhuma promoção selecionada",
			people: "{{count}} pessoas confirmadas",
		},
		reserveDetail: {
			title: "Pacote de",
			confirmed: "{{count}} confirmados",
			of: "de",
			guestsCount: "{{count}} convidados",
			delete: "Tem certeza que deseja apagar esse produto?",
			promotions: "Promoções do pacote",
			none: "Nenhuma promoção selecionada",
			guests: "Convidados",
			invoices: "Notas geradas",
			value: "Valor do pacote",
			issueInvoice: "Emitir nota fiscal",
			copyModal: {
				defaultReserveName: "Cópia de {{name}}",
				title: "Criar cópia do pacote",
				copy: "Copiar",
				text: "Deseja criar cópia de reserva ",
				placeholder: "Nome da cópia do pacote",
				errors: {
					inputs: "Os campos não foram preenchidos corretamente.",
					name: "O nome do pacote é igual ao nome original.",
					length: "O nome do pacote não atingiu a quantidade mínima de caracteres.",
				},
			},
			modal: {
				title: "Emitir nota fiscal para {{name}}",
				issueInvoice: "Emitir nota",
				fiscalProfile: "Perfil Fiscal",
				cpf: "CPF",
				cnpj: "CNPJ",
				none: "Nenhum",
				paymentMethod: "Método pagamento",
				fiscalGroup: "Grupo Fiscal",
				ncm: "NCM",
				cest: "CEST",
				value: "Valor",
				remove: "Remover",
				add: "Adicionar grupo fiscal",
				tip: "Gorjeta",
				removeTip: "Remover gorjeta",
				invoiceValue: "Valor da nota",
				invoiceAlert: "Notas com valor maior que R$ 10,000 serão divididas.",
				packageValue: "Valor do pacote",
				discount: "Descontos",
				total: "Total",
				chooseOptions: "Escolha uma das opções",
				addDiscount: "Adicionar descontos na nota",
				completeValue: "Completar o valor do pacote",
				optionalTip: {
					title: "Gorjeta (opcional)",
					add: "Adicionar gorjeta",
				},
				inputMultiplePaymentsManual: {
					label: "Método de pagamento",
					placeholder: "Selecione",
					value: "Valor",
					add: "Adicionar pagamento",
				},
				errors: {
					value: "Inclua algum produto com valor para utilizar essa funcionalidade",
					inputs: "Campos não foram preenchidos corretamente",
				},
			},
			tableMultiplePayment: {
				columns: {
					paymentMethod: "Método de pagamento",
					value: "Valor",
				},
				total: "Total adicionado",
			},
			peopleDidntArrive: {
				title: "Desconhecido",
				subtitle: "Sem informações",
			},
			peopleArrived: {
				title: "No evento",
				subtitle: "Já saiu",
			},
		},
		reserveModal: {
			inactive: " (Promoção inativa)",
			edit: "Editar pacote",
			create: "Criar",
			newTemplate:
				"Atenção! O modelo criado poderá ser utilizado por todos os eventos deste local.",
			template:
				"O pacote pode ser customizado, mas criará associação ao modelo para ser usado em relatórios e métricas.",
			models: "Modelos",
			clientInfo: "Informações do cliente",
			name: "Nome",
			estimatedPeopleCount: "Número de convidados",
			value: "Valor do pacote",
			expiresAt: "Expira em",
			promotion: "Promoção",
			promotionLabel: "Promoção {{number}}",
			updateModal: {
				promotionName: "Nome da promoção",
				description: "Descrição",
				productName: "Nome do produto",
				percentage: "Porcentagem",
			},
			modalTitle: {
				edit: "Editar {{name}}",
				newTemplate: "Criar modelo",
				template: "Criar a partir de modelo",
				create: "Criar pacote",
			},
			errors: {
				selectedTemplate: "O modelo não pôde ser encontrado",
				promotion: "Nenhuma promoção selecionada",
				inputs: "Preencha todos os campos obrigatórios para a categoria selecionada.",
			},
		},
		reserveOptions: {
			create: {
				name: "Crie pacote customizado",
				label: "Defina como será o pacote oferecido e se desejar inclua uma promoção.",
			},
			createWithTemplate: {
				name: "Crie a partir de modelo",
				label:
					"Crie um pacote para seus clientes com base nos modelos criados anteriormente.",
			},
			createTemplate: {
				name: "Crie novo modelo",
				label:
					"Crie um modelo customizado de pacote para utilizar futuramente em seus eventos.",
			},
			canBeUsedIn: "Poderá ser usado em",
			allEvents: "todos os eventos",
			fromThisPlace: "deste local",
			managePackages: "Gerenciar pacotes",
		},
		reserveTemplate: {
			loading: "Carregando ...",
			empty: "O local não possui nenhum modelo cadastrado.",
			remove: "Remover",
			value: "Valor do pacote",
			guests: "Convidados",
			promotions: "Promoções",
			modalConfirm: {
				title: "Tem certeza de que quer remover o modelo?",
				content: "Ele não poderá mais ser utilizado em nenhum evento deste local.",
			},
		},
	},
	reserveReport: {
		label: "Relatório de pacotes",
		subtitle: {
			none: "Nenhum pacote ativo durante o evento",
			one: "um pacote ativo durante o evento",
			default: "{{count}} pacotes ativos durante o evento",
		},
	},
};
