import { config } from "./config";
import { editMenu } from "./edit-menu";
import { menu } from "./menu";
import { orderReport } from "./order-report";
import { productReport } from "./product-report";
import { products } from "./products";
import { vendorReport } from "./vendor-report";
export const coala = {
	config,
	editMenu,
	menu,
	orderReport,
	productReport,
	products,
	vendorReport,
	tabs: {
		menu: "Menu",
		products: "Products",
		vendorReport: "Vendor Report",
		productReport: "Report by product",
		orderReport: "Order Report",
		config: "Settings",
	},
};
