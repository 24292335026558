export const finExManagerialReport = {
	title: "Management report",
	exportToExcel: "Export to excel",
	excelTitles: {
		incomeList: "Revenues",
		costsList: "Costs",
		expensesList: "Expenses",
	},
	periodSelect: {
		fifteen: "Last 15 days",
		thirty: "Last 30 days",
		sixty: "Last 60 days",
		selectPeriod: "Select period",
	},
	description:
		"The Management DRE helps to analyze the financial health and profit or loss results of an operation to balance expenses and increase revenue, optimizing costs and generating profits.",
	periodDescription: "Displaying consolidated values of {{since}} to {{until}}",
	tabs: {
		summary: "Summary",
		overview: "Overview",
	},
	period: "Period",
	applyDate: "Apply date",
	table: {
		level: "Level",
		income: "Income",
		costs: "Costs",
		expenses: "Expenses",
		description: "Description",
		total: "Total",
		contribution: "Contribution margin",
		noData: "No data",
		summaries: {
			totalIncome: "Total revenues",
			totalCosts: "Total costs",
			totalExpenses: "Total expenses",
			totalPeriod: "Total period",
		},
	},
};
