export const errorsValidations = {
	anticipation: {
		value: {
			noLiquidValue: "Digite o valor líquido para a antecipação",
			liquidValue:
				"Valor solicitado maior que valor total líquido disponível ({{totalAvailable}})",
			noTotalValue: "Digite o valor total a ser antecipado",
			totalValue:
				"Valor solicitado maior que valor total bruto disponível ({{totalAvailable}})",
			reason: "Digite um motivo para a antecipação",
		},
		accountData: {
			noAccountType: "Selecione um tipo de conta para a antecipação",
			accountHolder: "Digite o nome do titular da conta",
			accountTypeExisting: "Selecione uma das contas existentes",
			accountName: "Digite o nome para identificar a conta",
			accountBank: "Selecione o banco ao qual a conta pertence",
			accountDocument: "Digite o documento do titular da conta (CPF/CNPJ)",
			accountDocumentInvalid: "Documento inválido",
			accountAgency: "Digite o número da agência",
			accountAgencyInvalid: "Agência inválida",
			accountNumber: "Digite o número da conta",
			accountDigit: "Digite o dígito da conta",
			accountType: "Selecione o tipo da conta",
			accountPixKey: "Chave PIX inválida",
			accountTypeWrapper: "Preencha os dados bancários ou chave PIX",
		},
	},
	withdraw: {
		value: {
			noWithdrawValue: "Digite o valor para o saque",
			withdrawValue:
				"Valor solicitado maior que valor total bruto disponível ({{totalAvailable}})",
			anticipation: "Necessária antecipação",
			reason: "Digite um motivo para a solicitação",
			accountChoice: "Selecione um tipo de conta para a solicitação",
			existingAccount: "Selecione uma das contas existentes",
			accountName: "Digite o nome para identificar a conta",
			accountHolder: "Digite o nome do titular da conta",
			accountBank: "Selecione o banco ao qual a conta pertence",
			accountNumber: "Digite o número da conta",
			accountDigit: "Digite o dígito da conta",
			agencyNumber: "Digite o número da agência",
			agencyInvalid: "Agência inválida",
			documentNumber: "Digite o documento do titular da conta (CPF/CNPJ)",
			documentInvalid: "Documento inválido",
			accountType: "Selecione o tipo da conta",
			pixKeyInvalid: "Chave PIX inválida",
			receiverDataWrapper: "Preencha os dados bancários ou chave PIX",
		},
	},
	bankSlip: {
		basicInfoValue:
			"Valor solicitado maior que valor total bruto disponível ({{totalAvailable}})",
		anticipation: "Necessária antecipação",
		beneficiary: "Digite o beneficiário do boleto",
		barCode: "Digite o código de barras do boleto",
		invalidBarCode: "Código de barras inválido",
		attachment: "Anexe o arquivo do boleto",
		dueDate:
			"ATENÇÃO: O boleto só será pago se o saldo restante cobrir os custos com juros e multa.",
	},
};
