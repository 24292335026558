export const storageList = {
	layout: {
		title: "Storage list",
	},
	header: {
		searchPlaceHolder: "Search for storage",
		importXML: "import XML",
		createStorage: "Create storage",
	},
	indicators: {
		cost: {
			title: "Total Inventory Amount",
			footer: {
				localStorage: "Local storage",
				centralStorage: "Global storage",
			},
		},
		turnover: {
			high: "High turnover",
			low: "Low turnover",
		},
		zeroed: {
			title: "Zero count products",
			seeMore: "See More",
		},
		intervalNotice: "Values referring to the last 90 days",
		updatedAt: "Updated on {{date}}",
	},
	list: {
		local: "Local",
		central: "Global",
		rename: "Rename storage",
		delete: "Delete",
		valueInStorage: "Value in storage",
		productsInStorage: "Products in storage",
		productsInStorageCount: "{{count}} products",
		createdAt: "Created at {{date}}",
	},
	modalXml: {
		title: "Import XML",
		warningMessage: {
			line1:
				"Select the target storage, download the example spreadsheet, fill it in and import it again.",
			line2:
				"Attention! The value of the products is informed in cents. Ex: 123 equals {{currencySymbol}} 1.23",
		},
		form: {
			label: {
				storage: "Storage",
				xml: "XML",
			},
			placeholder: {
				xml: "Choose an .xml file to import",
			},
			validations: {
				missingFields: "Missing field",
			},
			cancel: "Cancel",
			submit: "Save",
		},
	},
	modalForm: {
		title: {
			createStorage: "Create storage",
			editarStorage: "Edit storage",
		},
		form: {
			label: {
				name: "Name",
				isCentral: "Type of storage",
			},
			options: {
				central: "Global",
				local: "Local",
			},
			placeholder: {
				name: "Storage name",
			},
		},
	},
	modalDelete: {
		title: "Delete Storage",
		warningMessage: 'Are you sure you want to remove the "{{storageName}}" inventory?',
		submit: "Delete",
	},
};
