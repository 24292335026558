export const employeesRole = {
	table: {
		roleName: "Role name",
		access: "Access",
		collaboratorsQuantity: "Number of collaborators in the role",
		none: "None",
	},
	masterOperationPDVAndDashboard: "Master - Operation (POS) and Dashboard",
	operationPDV: "Operation (POS)",
	dashboard: "Dashboard",
	roleNeedsASlug: "The role needs a slug",
	collaborators: "Collaborators",
	collaborator: "Collaborator",
	editRole: "Edit role",
	deleteRole: "Delete role",
	newRole: "New role",
	rolePermissionWarning:
		"This role has dash and POS permissions and therefore cannot be used in the edition/creation of employees",
	deleteRoleModal: {
		title: "Do you want to delete the role?",
		describe: "By deleting a role, all collaborators linked to it will lose the link.",
	},
	header: {
		roles: "Roles",
		describe:
			"Add, edit, and delete roles to be used in the dashboard or at the point of sale (POS)",
		searchRole: "Search role",
		searchRolePlaceholder: "Search by role name",
		closeSearch: "Close search",
		alertMessage:
			"Since the separation of employees into dash and pdv, it is no longer possible to create or use positions with permissions of both types.",
	},
	roleModal: {
		PDVRole: "Operation",
		PDVDescription: "Role for collaborators with access permission to <b>POS</b>",
		PDVFooterText: "Create POS collaborator",
		enterpriseRole: "Manager",
		enterpriseDescription:
			"Role for collaborators with access permission to the <b>Dashboard</b>",
		enterpriseFooterText: "Create dashboard collaborator",
		newManagerRole: "New Manager role",
		newOperationRole: "New operational role",
		operationPermission: "Operational permissions",
		managerPermission: "Manager permissions",
	},
	form: {
		roleNameDescription: "Select the type of role you want to create",
		roleNameRequired: "Role name is required",
		roleName: "Role name",
		roleNamePlaceholder: "Enter the name of the new role",
		roleNameError: "Invalid role name",
		importExistingRolePermissions: "(Optional) Import permissions from existing role",
		selectExistingRole: "Select an existing role",
		roleType: "Role type",
		operational: "POS",
		managerial: "Dashboard",
		master: "Master",
		saveEditions: "Save changes",
		createNewRole: "Create new role",
		discardChanges: "Discard changes",
		discardCreation: "Discard creation",
	},
};
