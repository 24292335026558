import { observable } from "mobx";

type RequestStatus = "notFetched" | "sucess" | "fetching" | "error";

type Value<ValueType, TransformedType> = TransformedType extends undefined
	? ValueType
	: TransformedType;

type TransformFunctionSignature<ValueType, TransformedType> = (
	value: ValueType,
) => TransformedType;

type FetchCallbackSignature<FetchArguments> = (args: FetchArguments) => void;

type ErrorCallbackSignature<FetchArguments> = (err: any, args: FetchArguments) => void;

type SuccessCallbackSignature<ValueType, TransformedType, FetchArguments> = (
	value: Value<ValueType, TransformedType>,
	args: FetchArguments,
) => void;

type ExternalFunctionSignature<FetchArguments, ValueType> = (
	args: FetchArguments,
) => Promise<ValueType>;

interface ConstructorArgument<FetchArguments, ValueType, TransformedType> {
	fnPromise: ExternalFunctionSignature<FetchArguments, ValueType>;
	onSuccess?: SuccessCallbackSignature<ValueType, TransformedType, FetchArguments>;
	onError?: ErrorCallbackSignature<FetchArguments>;
	onFetching?: FetchCallbackSignature<FetchArguments>;
	transform?: TransformFunctionSignature<ValueType, TransformedType>;
}

export type FetchModelType<FetchArguments, ValueType, TransformedType = undefined> = {
	value?: ValueType | undefined;
	loading: boolean;
	loadingForFirstTime: boolean;
	error: any | undefined;
	status: RequestStatus;
	lastRequestTimestamp: Date | undefined;
	fetch: (args: FetchArguments) => Promise<Value<ValueType, TransformedType>>;
	reset: () => void;
};

//documentation: https://git.cubos.io/zigpay/zig-dash/wikis/fetch-model
export class fetchModel<FetchArguments, ValueType, TransformedType = undefined> {
	private externalFn: ExternalFunctionSignature<FetchArguments, ValueType>;
	private successCallback?: SuccessCallbackSignature<
		ValueType,
		TransformedType,
		FetchArguments
	>;
	private errorCallback?: ErrorCallbackSignature<FetchArguments>;
	private fetchingCallback?: FetchCallbackSignature<FetchArguments>;
	private tranformFn?: TransformFunctionSignature<ValueType, TransformedType>;

	@observable
	public value?: Value<ValueType, TransformedType>;
	@observable
	public loading: boolean;
	@observable
	public loadingForFirstTime: boolean;
	@observable
	public error: any | undefined;
	@observable
	public status: RequestStatus;
	@observable
	public lastRequestTimestamp: Date | undefined;

	public fetch = (args: FetchArguments): Promise<Value<ValueType, TransformedType>> => {
		const promise = this.externalFn(args);
		this.loading = true;
		this.loadingForFirstTime = this.status === "notFetched" ? true : false;
		this.status = "fetching";
		this.lastRequestTimestamp = new Date();

		if (this.fetchingCallback) this.fetchingCallback(args);

		return new Promise((resolve, reject) => {
			promise
				.then(value => {
					this.status = "sucess";
					if (this.tranformFn) {
						//@ts-ignore
						this.value = this.tranformFn(value);
					} else {
						//@ts-ignore
						this.value = value;
					}
					if (this.successCallback)
						this.successCallback(this.value as Value<ValueType, TransformedType>, args);
					resolve(this.value as Value<ValueType, TransformedType>);
				})
				.catch(err => {
					this.status = "error";
					this.error = err;
					if (this.errorCallback) this.errorCallback(this.error, args);
					reject(this.error);
				})
				.finally(() => {
					this.loading = false;
					this.loadingForFirstTime = false;
				});
		});
	};

	public reset = () => {
		this.loading = false;
		this.loadingForFirstTime = false;
		this.value = undefined;
		this.error = undefined;
		this.status = "notFetched";
		this.lastRequestTimestamp = undefined;
	};

	public constructor(
		config: ConstructorArgument<FetchArguments, ValueType, TransformedType>,
	) {
		this.externalFn = config.fnPromise;
		this.successCallback = config.onSuccess;
		this.errorCallback = config.onError;
		this.fetchingCallback = config.onFetching;
		this.tranformFn = config.transform;

		this.loading = false;
		this.loadingForFirstTime = false;
		this.value = undefined;
		this.error = undefined;
		this.status = "notFetched";
		this.lastRequestTimestamp = undefined;
	}
}
