import { TransactionSource } from "#resources/api/enterprise-generated";
import { Select, SelectProps } from "#components/primitive";
import * as React from "react";
import { useTranslation } from "react-i18next";
import s from "./select-transaction-source.scss";
import i18n from "#i18n/index";

const t = i18n.t;

export interface ISelectTransactionSourceOption {
	label: string;
	value: TransactionSource;
}

export const sourceOptions: ISelectTransactionSourceOption[] = [
	{
		value: "balcony",
		label: t("event:barReports.tabs.soldProducts.filter.balcony"),
	},
	{
		value: "cashless",
		label: t("event:barReports.tabs.soldProducts.filter.cashless"),
	},
	{
		value: "dash",
		label: t("event:barReports.tabs.soldProducts.filter.dashboard"),
	},
	{
		value: "delivery",
		label: t("event:barReports.tabs.soldProducts.filter.delivery"),
	},
	{
		value: "ficha",
		label: t("event:barReports.tabs.soldProducts.filter.ficha"),
	},
	{
		value: "grabngo",
		label: t("event:barReports.tabs.soldProducts.filter.grabngo"),
	},
	{
		value: "onlineBill",
		label: t("event:barReports.tabs.soldProducts.filter.onlineBill"),
	},
	{
		value: "zigQrcode",
		label: t("event:barReports.tabs.soldProducts.filter.zigQrCode"),
	},
	{
		value: "onlineFicha",
		label: t("event:barReports.tabs.soldProducts.filter.onlineFicha"),
	},
];

export function SelectTransactionSource(props: SelectProps) {
	const { t } = useTranslation("event", {
		keyPrefix: "barReports.tabs.soldProducts.filter",
	});

	return (
		<Select
			className={s.transactionSourceSelect}
			mode="multiple"
			allowClear
			placeholder={t("placeholder")}
			size="large"
			{...props}
		>
			{sourceOptions.map(({ value, label }, index) => (
				<Select.Option key={`select-source-option-${value}-${index}`} value={value}>
					{label}
				</Select.Option>
			))}
		</Select>
	);
}
