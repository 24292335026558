import * as React from "react";

import s from "./skeleton.scss";
import classnames from "classnames";

interface IProps {
	className?: string;
	height?: number;
	width?: number;
}

const Skeleton: React.StatelessComponent<IProps> = ({ className, height, width }) => (
	<div
		role="status"
		aria-label="loading"
		style={{ height, width }}
		className={classnames(s.skeleton, className)}
	/>
);

export default Skeleton;
