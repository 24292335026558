import i18n from "#i18n/index";
import { IntlLanguages } from "../language";

const t = i18n.t;

export type SupportedLanguages = IntlLanguages;
export type SupportedCurrencies = "BRL" | "USD" | "MXN" | "EUR";

export interface ICurrencyOptions {
	language?: SupportedLanguages;
	currency?: SupportedCurrencies;
	centsBase?: boolean;
	withoutSymbol?: boolean;
}
export const DEFAULT_CURRENCY: SupportedCurrencies = "BRL";
export const CENTS_BASE = 100;
export const MIN_FRACTION_DIGITS = 2;

// dashboard default config
export const defaultOptions: ICurrencyOptions = {
	currency: "BRL",
	language: "pt-BR",
	centsBase: true,
	withoutSymbol: false,
};

export const CURRENCIES: Record<SupportedCurrencies, SupportedLanguages | undefined> = {
	BRL: "pt-BR",
	USD: "en-US",
	MXN: "es-MX",
	EUR: "de-DE",
};

export const CURRENCIES_NARROW_SYMBOLS: Record<SupportedCurrencies, string> = {
	BRL: "R$",
	USD: "$",
	MXN: "$",
	EUR: "€",
};

export const CURRENCIES_NAMES = (label: SupportedCurrencies) => {
	const currencyName: Record<SupportedCurrencies, string> = {
		BRL: t("globalKeys:currencies.real"),
		USD: t("globalKeys:currencies.dollar"),
		MXN: t("globalKeys:currencies.mexPeso"),
		EUR: t("globalKeys:currencies.euro"),
	};

	return currencyName[label];
};

export interface ResultCurrency<T = unknown> {
	values: Record<keyof T, string> | null;
	formatCurrency: (value: number) => string;
	currencyNarrowSymbol: string;
}
