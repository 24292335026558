export const cmv = {
	searchProduct: "Encontre um produto",
	searchPlaceholder: "Selecione um produto ou categoria",
	details: {
		salePrice: "Preço de Venda",
		cost: "Custo",
		realCost: "Custo Real",
		markup: "Markup",
		contributionMargin: "M. de Contrib.",
		productSimulator: "Simulador de CMV",
		editProductPrice: "Alterar Preço",
	},
	supply: {
		columns: {
			input: "Insumo",
			quantity: "Qnt",
			unitCost: "Custo Unitário",
			loss: "Perda",
			realCost: "Custo Real",
		},
	},
};
