import * as React from "react";
import classnames from "classnames";
import s from "./typography.scss";

export interface ITypographyProps extends React.HTMLProps<HTMLDivElement> {
	type: "display" | "heading" | "paragraph" | "caption";
	/**
	 * Variantes para tipografia:
	 * - `v1`: Display grande e negrito:
	```css	
	  font-size: $font-size-xl; //(40px) 
		font-weight: $font-weight-bold; //(700)
	```
	 * - `v2`: Display grande e regular:
	```css	
	  font-size: $font-size-xl; //(40px) 
		font-weight: $font-weight-regular; //(400)
	```
	 * - `v3`: Cabeçalho médio e negrito:
	```css	
	  font-size: $font-size-md; //(24px) 
		font-weight: $font-weight-bold; //(700)
	``` 
	 * - `v4`: Cabeçalho pequeno e regular:
	```css	
	  font-size: $font-size-sm; //(20px) 
		font-weight: $font-weight-regular; //(400)
	``` 
	 * - `v5`: Legenda ultra pequena e negrito:
	```css	
	  font-size: $font-size-xxxs; //(12px) 
		font-weight: $font-weight-bold; //(700)
	``` 
	 */
	variant: "v1" | "v2" | "v3" | "v4" | "v5";
	as?: "h1" | "h2" | "h3" | "h4" | "h5" | "h6" | "p" | "span" | "caption";
	className?: string;
	children?: React.ReactNode;
}

export function Typography({
	type,
	variant,
	as,
	className,
	children,
	...props
}: ITypographyProps) {
	function getClassNamesForTypeAndVariant(): string {
		return classnames(className, s.baseColor, s[`${type}--${variant}`]);
	}

	function getDefaultTagForType(): typeof as {
		switch (type) {
			case "display":
				return "h1";
			case "heading":
				return "h2";
			case "paragraph":
				return "p";
			case "caption":
				return "caption";
		}
	}

	function getTag(tag: typeof as): JSX.Element {
		switch (tag) {
			case "h1":
				return (
					<h1 className={getClassNamesForTypeAndVariant()} {...props}>
						{children}
					</h1>
				);
			case "h2":
				return (
					<h2 className={getClassNamesForTypeAndVariant()} {...props}>
						{children}
					</h2>
				);
			case "h3":
				return (
					<h3 className={getClassNamesForTypeAndVariant()} {...props}>
						{children}
					</h3>
				);
			case "h4":
				return (
					<h4 className={getClassNamesForTypeAndVariant()} {...props}>
						{children}
					</h4>
				);
			case "h5":
				return (
					<h5 className={getClassNamesForTypeAndVariant()} {...props}>
						{children}
					</h5>
				);
			case "h6":
				return (
					<h6 className={getClassNamesForTypeAndVariant()} {...props}>
						{children}
					</h6>
				);
			case "p":
				return (
					<p className={getClassNamesForTypeAndVariant()} {...props}>
						{children}
					</p>
				);
			case "span":
				return (
					<span className={getClassNamesForTypeAndVariant()} {...props}>
						{children}
					</span>
				);
			case "caption":
				return (
					<caption className={getClassNamesForTypeAndVariant()} {...props}>
						{children}
					</caption>
				);
			default:
				return (
					<p className={getClassNamesForTypeAndVariant()} {...props}>
						{children}
					</p>
				);
		}
	}

	return getTag(as ?? getDefaultTagForType());
}
