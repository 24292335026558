import { Menu } from "#components/primitive";
import React, { useCallback, useEffect, useState } from "react";

import { MenuIcon } from "../nav-bar/menu-icon";
import { MenuItem } from "#components/primitive/menu/v2/menu-item";
import { NavBarProps } from "./types";
import { useTranslation } from "react-i18next";
import { observer } from "mobx-react-lite";
import { useStores } from "#stores/index";
import { MenuDivider } from "#components/primitive/menu/v2";
import { Link } from "react-router";
import { useChangeLayout } from "../use-change-layout";
import { isMain } from "#configuration/config";
import { useBreadcrumb } from "#structure/providers/breadcrumb";
import { extractUrlListFromNavItems } from "./helpers";

import s from "./nav-bar.module.scss";

const _NavBar = ({
	isOpenSandwichMenu,
	items,
	runningEvents,
	onChangeIsOpenSandwichMenu,
	selectedMenu,
	onSelectMenu,
	showRunningEventsIdx,
}: NavBarProps) => {
	const { t } = useTranslation("nav", { keyPrefix: "navbar.default" });
	const { authStore } = useStores();
	const [openSubMenuIndex, setOpenSubMenuIndex] = useState<number | null>(null);
	const { onChangeNavBarList, onChangeCurrentPage } = useBreadcrumb();

	React.useEffect(() => {
		if (!items?.length) return;

		onChangeNavBarList(extractUrlListFromNavItems(items));
	}, [items]);

	const checkIfSubMenuShouldBeOpened = useCallback(() => {
		if (!selectedMenu) {
			return null;
		}

		const foundMenuItemWithSubItems = items.findIndex(item =>
			item.submenus?.some(
				subItem =>
					subItem.url === selectedMenu || subItem.alternativeUrls?.includes(selectedMenu),
			),
		);

		if (foundMenuItemWithSubItems > -1) {
			return foundMenuItemWithSubItems;
		}

		return null;
	}, [items, selectedMenu, isOpenSandwichMenu]);

	const { changeLayout } = useChangeLayout();

	useEffect(() => {
		if (!isOpenSandwichMenu) {
			const valueFound = checkIfSubMenuShouldBeOpened();
			setOpenSubMenuIndex(valueFound);
		}
	}, [isOpenSandwichMenu]);

	const menuItems = items.map((item, idx) => {
		return (
			<MenuItem
				{...item}
				key={`${item.url}-${item.label}`}
				icon={<MenuIcon icon={item.icon} className="menu-icon" width={24} height={24} />}
				onSelectMenu={menuUrl => {
					onSelectMenu?.(menuUrl);

					if (menuUrl) {
						onChangeCurrentPage(menuUrl);
					}
				}}
				selectedMenu={selectedMenu}
				showRunningEvents={idx === showRunningEventsIdx}
				isOpenSandwichMenu={isOpenSandwichMenu}
				runningEvents={runningEvents}
				subMenuItems={item.submenus}
				isSubMenuOpen={openSubMenuIndex === idx}
				onToggleSubMenu={forcedIndex => {
					const hasForcedIndex = typeof forcedIndex === "number";

					if (hasForcedIndex) {
						setOpenSubMenuIndex(forcedIndex);
						return;
					}

					setOpenSubMenuIndex(idx);
				}}
			/>
		);
	});

	return (
		<Menu
			title="Menu"
			onChangeIsOpenSandwichMenu={onChangeIsOpenSandwichMenu}
			isForceOpen={isOpenSandwichMenu}
			LogoContainer={({ children }) => <Link to="/">{children}</Link>}
			v2
		>
			{menuItems}
			<MenuDivider />

			{!isMain && (
				<MenuItem
					icon={<MenuIcon icon="settings" width={24} height={24} />}
					label={"Alterar layout"}
					onSelectMenu={changeLayout}
					selectedMenu={null}
					isOpenSandwichMenu={isOpenSandwichMenu}
					className={s.logoutButton}
				/>
			)}

			<MenuItem
				icon={<MenuIcon icon="logout" width={24} height={24} />}
				label={t("logout")}
				onSelectMenu={() => authStore.logout()}
				selectedMenu={selectedMenu}
				isOpenSandwichMenu={isOpenSandwichMenu}
				className={s.logoutButton}
			/>
		</Menu>
	);
};

export const NavBar = observer(_NavBar);
