export const minimmConsummation = {
	titleTab: "Minimum consumption setting",
	titleColumnsTable: {
		place: "Place",
		fiscalProfile: "Tax profile",
		fiscalGroup: "Tax group",
		ncm: "NCM",
		action: "Action",
	},
	placeholders: {
		fiscalProfile: "Select a tax profile",
		fiscalGroup: "Select a tax group",
		ncm: "Enter the NCM code",
	},
	errors: {
		requiredField: "Required field",
		invalidFormatNcm: "The NCM must contain 8 digits",
	},
	messageWarning:
		"When canceling this action, the modifications made will not be carried out. Do you want to continue anyway?",

	titleButtonOut: "Exit without saving",
};
