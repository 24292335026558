import * as React from "react";
import s from "./menu.scss";
import classnames from "classnames";
import LogoMinified from "./logo-minified.svg";
import Logo from "./logo.svg";
import MenuPatternImage from "./menu-background-pattern.svg";
import { IMenuIconContainerProps, IMenuProps, IMenuTagProps } from "./types";

export function MenuTag({ type, children }: IMenuTagProps) {
	return <span className={classnames(s.menuTag, s[type])}>{children}</span>;
}

export function MenuDivider() {
	return <hr className={s.divider} />;
}

export function MenuIconContainer({
	className,
	children,
	...props
}: IMenuIconContainerProps) {
	return (
		<div className={classnames("icon-container", className)} {...props}>
			{children}
		</div>
	);
}

export function Menu({
	LogoContainer = React.Fragment,
	// TODO: Update this prop to isOpen when migrate everything to v2
	isForceOpen,
	onChangeIsOpenSandwichMenu,
	children,
	title,
}: IMenuProps) {
	const handleCloseMenu = () => onChangeIsOpenSandwichMenu?.(false);

	return (
		<nav
			className={classnames(s.navContainer, {
				[s.forceOpen]: isForceOpen,
			})}
			onMouseOver={isForceOpen ? undefined : () => onChangeIsOpenSandwichMenu?.(true)}
		>
			<div
				className={s.overlay}
				onMouseOver={handleCloseMenu}
				onClick={handleCloseMenu}
			/>
			<div className={s.patternContainer}>
				<MenuPatternImage className={s.pattern} />
			</div>
			<LogoContainer>
				<div className={classnames(s.logoContainer)}>
					<div className={s.logoExpanded}>
						<Logo />
					</div>
					<div className={s.logoMinified}>
						<LogoMinified />
					</div>
				</div>
			</LogoContainer>

			<MenuDivider />

			{title && <small className={s.title}>{title}</small>}

			<ul className={s.navMenu}>{children}</ul>
		</nav>
	);
}
