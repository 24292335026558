export const storageTransfer = {
	warningNotifications: {
		productAlreadyOnList: "This product has already been added to the list!",
		originAndDestinyStorageAreTheSame:
			"The output stock cannot be the same as the destination stock!",
		notPossibleToTransferToMultipleStorages:
			"It is not possible to add products with different destination stocks to the list!",
	},
	title: "Transfer products from stock {{storageName}}",
	cancel: "Cancel",
	finish: "Finish",
	feedbackModal: {
		notFound: "Not found",
		sheet: {
			filename: "stock transfer",
			title: "Transfer from stock {{fromStorageName}} to {{toStorageName}}",
			columns: {
				product: "Product",
				quantity: "Quantity",
				ube: "Unit",
			},
		},
		close: "Close",
		cancel: "Cancel",
		importOnlyAssociated: "Import only associates",
		associateAndProducts: "Associate products and import",
		downloadExcell: "Download in excel",
		messages: {
			loading: {
				title: "Transferring Products",
				subtitle: "Please wait, we are transferring products",
			},
			error: {
				title: "Oops... Something didn't go as planned.",
				subtitle: "We were unable to transfer the products.\nPlease try again later.",
			},
			needPlaceActivation: {
				title: "Oops... Something didn't go as planned.",
				subtitle: "Do you want to save the transfer report?",
			},
			success: {
				title: "Successfully transferred",
				subtitle: "Do you want to save the transfer report?",
			},
		},
		wasntPossibleToTransferDueToNotAssociatedProducts:
			"Oops... The transfer could not be performed. Some products are not associated at the destination location. Please associate the following products to proceed:",
		title: "Transfer Report",
		transferPrint: {
			printReport: "Print report",
			pdf: {
				proofOfTransfer: "Proof of Transfer",
				message:
					'This document proves the transfer of products from Stock "{{fromStorage}}" to stock "{{toStorage}}" at {{transferDateString}} made by ZigPay. The transferred products are in the following list:',
				storageResponsible: "Responsible for the destination stock",
			},
		},
	},
	transferProductForm: {
		form: {
			validation: {
				maxStorageCountIs: "Maximum stock quantity is {{productMaxCountString}}",
				missingField: "Mandatory field",
				invalidQuantity: "Invalid quantity",
			},
			storageTypes: {
				local: "Local stock",
				central: "General Inventory",
			},
			placeholder: {
				product: "Search for product",
				storage: "Target stock",
			},
			label: {
				storageType: "Transfer to:",
				storage: "Target stock",
				product: "Search for product",
				quantity: "Quantity",
			},
			submit: "Add",
		},
	},
	table: {
		columns: {
			name: "product",
			toStorage: "Destination stock",
			count: "amount",
			unitcost: "unit cost",
			totalcost: "total cost",
			action: "action",
		},
	},
};
