import constate from "constate";
import { useState } from "react";
import { GroupBy, SalesReportProps } from "../types";
import { useDebounceValue } from "usehooks-ts";
import { PaymentMethod } from "#resources/api/enterprise-generated";
import {
	usePlaceProducts,
	useProductsSoldByBarReport,
} from "#resources/hooks/integrations/products/query";
import { useGetBars } from "#resources/hooks/integrations/bars/query";
import { useGetEmployeesV2 } from "#resources/hooks/integrations/team";
import { useExportAllTransactionProductsXlsxPlace } from "#resources/hooks/integrations/transactions/mutation";
import { DEBOUNCE_TIMEOUT } from "./constants";
import { PaymentMethodLabels } from "#resources/helpers/humanize";
import { useGTMEvent } from "#resources/hooks/metrics";
import { useTranslation } from "react-i18next";

import useFilters from "./use-filters";

type SalesReportTab = "all-sales" | "total-sales";

const useSalesReportHook = (props: SalesReportProps) => {
	const { sendSelectContent } = useGTMEvent();
	const { t } = useTranslation("place", {
		keyPrefix: "barReports.salesReport",
	});
	const { since: _since, until: _until, placeId, eventLevel, eventId } = props;

	const [selectedTab, setSelectedTab] = useState<SalesReportTab>("all-sales");

	const {
		rangeDate,
		setRangeDate,
		clientNameFilter,
		setClientNameFilter,
		employeesFilter,
		setEmployeesFilter,
		paymentMethodsFilter,
		setPaymentMethodsFilter,
		transactionsFilter,
		setTransactionsFilter,
		productsFilter,
		setProductsFilter,
		barsFilter,
		setBarsFilter,
		since,
		until,
	} = useFilters(props);

	const [_groupBy, setGroupBy] = useState<GroupBy>("bar");
	const [groupBy] = useDebounceValue(_groupBy, DEBOUNCE_TIMEOUT);

	const {
		mutateAsync: doExportAllTransactionProductsXlsxPlace,
		isPending: exportAllTransactionsIsLoading,
	} = useExportAllTransactionProductsXlsxPlace();

	const {
		data: dataSourceProductsByBarAtPlace = [],
		isLoading: isLoadingProductsSoldByBar,
	} = useProductsSoldByBarReport(
		{
			placeId,
			since,
			until,
			filters: {
				barIds: null,
				productIds: null,
				sources: null,
			},
		},
		{
			enabled: selectedTab === "total-sales" && groupBy === "bar",
			retry: false,
		},
	);

	const { data: placeProducts = [] } = usePlaceProducts(
		{ placeId },
		{ enabled: selectedTab === "total-sales" },
	);
	const { data: bars = [] } = useGetBars(
		{ placeId },
		{ enabled: selectedTab === "total-sales" },
	);
	const { data: employees = [] } = useGetEmployeesV2({});

	const productsOptions = placeProducts.map(({ id, name }) => ({
		value: id,
		label: name,
	}));

	const barOptions = bars.map(({ id, name }) => ({
		value: id,
		label: name,
	}));

	const employeesOptions = employees.map(({ id, name }) => ({
		value: id,
		label: name,
	}));

	const paymentMethodsOptions = Object.entries(
		PaymentMethodLabels,
	).map(([value, label]: [PaymentMethod, string]) => ({ value, label }));

	const exportAllTransactions = async () => {
		await doExportAllTransactionProductsXlsxPlace(
			{
				placeId,
				since,
				until,
			},
			{
				onSuccess(url) {
					window.open(url, "_blank");
				},
			},
		);
	};

	const exportAllSales = () => {
		exportAllTransactions();
	};

	const exportAmountInSales = () => {
		alert("Exportar: Total em vendas");
	};

	const handleSelectTab = (tab: SalesReportTab) => {
		const eventByTab: Record<SalesReportTab, { label: string; event_track: string }> = {
			"all-sales": {
				event_track: "clk_allproductsales_report",
				label: t("allSalesTab"),
			},
			"total-sales": {
				event_track: "clk_productsoldby_report",
				label: t("totalSalesTab"),
			},
		};

		sendSelectContent(eventByTab[tab]);
		setSelectedTab(tab);
	};

	return {
		/** General */
		eventLevel,
		selectedTab,
		setSelectedTab: handleSelectTab,
		placeId,
		since,
		until,
		eventId,

		/** Filters */
		rangeDate,
		setRangeDate,
		clientNameFilter,
		setClientNameFilter,
		employeesFilter,
		setEmployeesFilter,
		paymentMethodsFilter,
		setPaymentMethodsFilter,
		transactionsFilter,
		setTransactionsFilter,
		productsFilter,
		setProductsFilter,
		barsFilter,
		setBarsFilter,
		groupBy,
		setGroupBy,

		/** Actions */
		exportAllTransactions,
		exportAllTransactionsIsLoading,
		exportAllSales,
		exportAmountInSales,

		/** Options */
		productsOptions,
		barOptions,
		employeesOptions,
		paymentMethodsOptions,

		/** GroupedByBar */
		dataSourceProductsByBarAtPlace,
		isLoadingProductsSoldByBar,
	};
};

export const [
	SalesReportProvider,
	useSalesReport,
	useSalesOptions,
	useSalesFilters,
	useSalesActions,
	useSalesByBar,
] = constate(
	useSalesReportHook,
	/** General */
	value => value,
	/** Options */
	({ barOptions, productsOptions, employeesOptions, paymentMethodsOptions }) => ({
		barOptions,
		productsOptions,
		employeesOptions,
		paymentMethodsOptions,
	}),
	/** Filters */
	value => ({
		rangeDate: value.rangeDate,
		setRangeDate: value.setRangeDate,
		clientsFilter: value.clientNameFilter,
		setClientsFilter: value.setClientNameFilter,
		employeesFilter: value.employeesFilter,
		setEmployeesFilter: value.setEmployeesFilter,
		paymentMethodsFilter: value.paymentMethodsFilter,
		setPaymentMethodsFilter: value.setPaymentMethodsFilter,
		transactionsFilter: value.transactionsFilter,
		setTransactionsFilter: value.setTransactionsFilter,
		productsFilter: value.productsFilter,
		setProductsFilter: value.setProductsFilter,
		barsFilter: value.barsFilter,
		setBarsFilter: value.setBarsFilter,
		groupBy: value.groupBy,
		setGroupBy: value.setGroupBy,
	}),
	/** Actions */
	({ exportAllTransactions, exportAllSales, exportAmountInSales }) => ({
		exportAllTransactions,
		exportAllSales,
		exportAmountInSales,
	}),
	/** GroupedByBar */
	value => ({
		dataSource: value.dataSourceProductsByBarAtPlace,
		isLoading: value.isLoadingProductsSoldByBar,
	}),
);
