export const crudMessage = {
	create: {
		title: "Nova mensagem",
		subtitle: {
			sms:
				"Digite o conteúdo que será enviado para a lista de clientes selecionado. Sua mensagem vai impactar ",
			whatsapp:
				"Selecione um template com base no que deseja comunicar e depois altere os seus parâmetros.",
		},
		client: "pessoa",
		clients: "pessoas",
		show: "Clique aqui para ver dicas sobre o conteúdo",
		subtitleTemplateList:
			"Selecione um template com base no que deseja comunicar e depois altere os seus parâmetros.",
	},
	edit: {
		title: "Editar mensagem programada",
		subtitle: "Reescreva o conteúdo da mensagem ou altere a programação do envio.",
	},
	resume: "Dados do envio",
	content: "Conteúdo da mensagem",
	program: "Programar envio",
	sendNow: "Enviar agora",
	textArea: {
		content: "Conteúdo da mensagem",
		copy: "Copiar conteúdo da mensagem",
		sucessCopy: "Mensagem copiada para área de transferência",
		at: "às",
		placeholder: "Escreva o conteúdo da mensagem para o grupo selecionado...",
		character: "caracter restante",
		characters: "caracteres restantes",
		footer:
			"Dica: para adicionar variáveis e poder alterá-las no futuro, digite [VARIÁVEL] entre colchetes.",
	},
	resumeCard: {
		sms: "SMS",
		footerText: {
			summarizes: "Mensagens para clientes com optout não serão cobradas.",
			smsBalanceAddedValue:
				"O valor extra de {{value}} será acrescentado ao pagamento da cobrança mensal da Zig.",
			smsBalanceSubtractedValue:
				"O valor de {{value}} será abatido do pagamento da cobrança mensal da Zig.",
			noExtraCost:
				"Como não houve alteração na quantidade da lista de destinatários, não terá nenhum custo extra.",
			charactersLimit:
				"Quantidade de caracteres ultrapassou o limite. O custo do SMS foi alterado e o valor de {{value}} será adicionado ao pagamento da cobrança mensal da Zig.",
			addedValue:
				"O valor de {{value}} será adicionado ao pagamento da cobrança mensal da Zig.",
		},
		messageCost: "Custo do envio",
		amountMessageSent: "Total de destinatários",
		summarizes: "Resumo do envio",
		messageInProcess: {
			sms: "SMS em processamento",
			whatsapp: "Mensagem em processamento",
		},
		amountMessageReceived: {
			sms: "SMS recebidos",
			whatsapp: "mensagens recebidas",
		},
		amountMessageNotReceived: {
			notRecieved: {
				sms: "SMS não recebidos",
				whatsapp: "mensagens não recebidas",
			},
			optout: {
				sms: "SMS não recebidos por optout",
				whatsapp: "mensagens não recebidas por optout",
			},
			error: {
				sms: "SMS não recebidos por erro",
				whatsapp: "mensagens não recebidas por erro",
			},
		},
		resume: "Resumo do envio",
		rescheduledShippingCost: "Custo do envio reprogramado",
		postShipmentBalance: "Saldo pós envio",
	},
	modalMessage: {
		title: {
			messageSent: "Mensagem enviada",
			messageScheduled: "Mensagem programada",
			messageTips: "Dicas de mensagem",
		},
		messageScheduled: {
			clientsCount: "Sua mensagem será enviada para ",
			client: "cliente",
			clients: "clientes",
			of: "de",
			day: "dia",
			at: "às",
			cancelOrEdit:
				"Caso queira cancelar o envio ou alterar o conteúdo, volte para a lista de mensagens e edite as informações de envio.",
		},
		messageSent: {
			clientsCount: "Sua mensagem foi enviada para ",
			client: "cliente",
			clients: "clientes",
			of: "de",
		},
		messageTips: {
			tips: "Dicas do que você pode fazer",
			tipOne:
				"Uma mensagem bem escrita impacta seus clientes de maneira única! Como você tem {{characters}} caracteres, o ideal é que a sua mensagem seja sucinta, clara e direta.",
			tipTwo:
				"Você pode incluir links importantes para os seus clientes. Links para a sua landing page ou para o ticket do evento facilita o acesso do cliente à informações e compra de ingressos.",
			tipThree:
				"Vai rodar uma promoção interessante? Filtre o grupo de clientes que tem direito a promoção e envie SMS direcionado.",
			tipFour:
				"Lançamento de algum produto novo? Filtre os seus melhores clientes e envie todas as informações do seu produto.",
		},
		addBalance: "Adicionar Saldo",
		notBalance:
			"Você não possui saldo suficiente para enviar SMS para o grupo selecionado. Deseja adicionar o saldo restante?",
	},
	campaignSelect: {
		title: "Campanha",
		placeholder: "Selecionar campanha",
		createOrSearch: "Busque por campanha ou crie uma nova",
		text:
			"OPCIONAL: Atrele esse envio à uma campanha para facilitar a análise da performance de múltiplos envios juntos.",
	},
	campaignModal: {
		title: "Nome da campanha",
		tooltipMessage:
			"OPCIONAL: Atrele esse envio à uma campanha para facilitar a análise da performance de múltiplos envios juntos.",
		placeholder: "Insira o nome da campanha",
		confirmButton: "Criar campanha",
	},
	campaignsDetail: {
		columns: {
			clientName: "Nome do cliente",
			gender: "Gênero",
			cpf: "CPF",
			status: "Status",
			phone: "Telefone",
		},
		genders: {
			male: "Homem",
			female: "Mulher",
			other: "Outro",
			uninformed: "Não informado",
		},
		sent: "Enviado",
		canceled: "Cancelado",
		sentInDay: "Enviado no dia {{day}} para",
		clients: "clientes",
		client: "cliente",
		programedMessage:
			"Sua mensagem foi programada no dia {{day}} para ser enviada no dia {{date}}",
		canceledMessage: "A mensagem que seria enviada no dia {{day}} foi cancelada",
	},
	day: "Dia",
	hour: "Hora",
	footer: {
		reprogram: "Reprogramar envio de SMS",
		scheduleShipping: "Agendar envio de SMS",
		send: "Enviar SMS agora",
	},
	success: {
		createTemplate: "Novo template solicitado",
	},
	errors: {
		smsCost: "Custo do SMS precisa ser configurado",
		place: "Não foi possível identificar o place",
		campaign: "Não foi possível criar a campanha.",
		editMessage: {
			sendNow: "Dados atualizados. Mensagem será enviada em instantes.",
			sucess: "Dados atualizados.",
		},
	},
};
