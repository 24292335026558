export const pos = {
	title: "Vendas POS",
	sidebar: {
		title: "Total de {{month}}",
		day: "Dia",
		total: "Total",
		credit: "Crédito",
		debit: "Débito",
		clear: "Limpar",
		info: "Selecione uma ou mais datas para ver detalhes.",
	},
	methodTranslate: {
		credit: "Crédito",
		debit: "Débito",
	},
	selectDate: "Selecione um dia para visualizar transações",
	operations: {
		title: "Operações",
		search: "Pesquise por bandeira, dígitos ou valor",
		columns: {
			date: "Data e hora",
			brand: "Bandeira",
			method: "Método",
			lastDigits: "Dígitos",
			value: "Valor",
		},
	},
	others: "Outros",
};
