import React, { ReactNode } from "react";
import { useTranslation } from "react-i18next";
import classNames from "classnames";

import { EmptyType } from "./types";
import { ILLUSTRATIONS } from "./constants";

import s from "./table-empty-state.module.scss";

export type TableEmptyStateProps = {
	className?: string;
	emptyType: EmptyType;
	title?: string | ReactNode;
	description?: string | ReactNode;
};

export const TableEmptyState = ({
	className,
	emptyType,
	title,
	description,
}: TableEmptyStateProps) => {
	const { t } = useTranslation("components", { keyPrefix: "tableEmptyState" });
	const Illustration = ILLUSTRATIONS[emptyType];

	const defaultTitle = t(`${emptyType}.title` as const);
	const defaultDescription = t(`${emptyType}.description` as const);

	return (
		<div className={classNames(s.container, className)}>
			<Illustration width={120} height={120} />

			<div className={s.content}>
				<h4>{title ?? defaultTitle}</h4>
				<p>{description ?? defaultDescription}</p>
			</div>
		</div>
	);
};
