import * as React from "react";
import { Helmet } from "react-helmet";
import { observer } from "mobx-react-lite";

import RootStore from "#stores/index";
import { SMALL_DEVICE_SIZE } from "#resources/hooks";
import { GlobalWarnings } from "#components/global-warnings";
import { GlobalWarningsProvider } from "#components/global-warnings/hooks/use-global-warnings";

import s from "./main-container.scss";

interface IMainContainerProps {
	children: React.ReactNode;
}

export default observer(MainContainer);

function MainContainer({ children }: IMainContainerProps) {
	const { uiStore } = React.useContext(RootStore);

	return (
		<GlobalWarningsProvider>
			{window.innerWidth <= SMALL_DEVICE_SIZE && (
				<Helmet>
					<meta name="viewport" content="width=1080" />
				</Helmet>
			)}

			<main className={s.main}>
				<GlobalWarnings />
				{uiStore.topLoading && <div className={s.loading} />}
				<div className={s.wrapper} id="content-wrapper">
					{children}
				</div>
			</main>
		</GlobalWarningsProvider>
	);
}
