import * as React from "react";
import "antd/dist/antd.less";
require("../../antd-theme.less");

import { ConfigProvider } from "antd";
import pt_BR from "antd/lib/locale-provider/pt_BR";
import en_US from "antd/lib/locale-provider/en_US";
import fr_FR from "antd/lib/locale-provider/fr_FR";
import { useLanguage } from "./language";
import { DashLanguages } from "#i18n/utils";
import { Locale } from "antd/lib/locale-provider";

const antdLanguageMap: Record<DashLanguages, Locale> = {
	"pt-BR": pt_BR,
	"en-US": en_US,
	// keep pt-br untl spanish strings
	"es-MX": pt_BR,
	"es-ES": pt_BR,
	"fr-FR": fr_FR,
};

export function AntDesignThemeProvider(props: React.PropsWithChildren<{}>) {
	const { language } = useLanguage();
	return (
		<ConfigProvider locale={antdLanguageMap[language]}>{props.children}</ConfigProvider>
	);
}
