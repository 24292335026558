export const soldRankingReport = {
	tabTitle: "Vendas por produtos",
	pageTitle: "Vendas por produtos",
	pageDescription: "Relatório comparativo entre operadores e sua vendas.",
	exportReport: "Exportar relatório",
	groupedView: "Visualização agrupada",
	selectPeriod: "Selecione o período",
	searchCategory: "Categoria",
	searchCategoryPlaceholder: "Buscar categoria",
	searchProduct: "Produto",
	searchProductPlaceholder: "Buscar produto",
	filename: "relatorio_de_vendas",
	table: {
		columns: {
			position: "Posição",
			product: "Produto",
			count: "Quantidade",
			totalSold: "Total",
			price: "Preço",
			discount: "Desconto",
		},
		withoutInfo: "Sem informações para exibir",
	},
	productSoldTable: {
		columns: {
			waiter: "Garçom",
			count: "Quantidade",
			price: "Preço",
			discount: "Desconto",
			total: "Total",
		},
		withoutInfo: "Sem informações para exibir",
	},
	totalizers: {
		highestSeller: "Maior operador",
		totalSellerSold: "Total do operador",
	},
};
