export const updateStatusMessage = {
	success: {
		approved: {
			title: "Inventário concluído!",
			message: "Inventário finalizado",
		},
		in_review: {
			title: "Inventário enviado para revisão!",
			message: "O inventário será revisado por um responsável",
		},
	},
	error: {
		approved: {
			title: "Ops, algo deu errado!",
			message: "Inventário não finalizado",
		},
		in_review: {
			title: "Ops, algo deu errado!",
			message: "Inventário não enviado para revisão",
		},
	},
	actions: {
		goHome: "Voltar para página inicial",
		backToResume: "Voltar para o resumo",
		createAnotherInventory: "Criar outro inventário",
	},
};
