import { infoCard } from "./info-card";
import { drawers } from "./drawers";
import { modalPlaces } from "./modal-places";
import { modalDelete } from "./modal-delete";

export const components = {
	drawers,
	infoCard,
	modalPlaces,
	modalDelete,
};
