import * as ReactGA from "react-ga";
import { EventInvoiceIssuingHooks, issueEventInvoicesDebouced } from "#helpers/fiscal";
import { fromPromise, IPromiseBasedObservable } from "#helpers/mobx-utils";
import {
	BarEmittedValue,
	EmployeeEmittedValue,
	EmployeeWithIssuedInvoices,
	FiscalProfileEmittedValue,
	GenericValueForReport,
	Invoice,
	InvoiceFiscalProductGroup,
	InvoicesV2Filter,
	InvoicesV2PaginationResponse,
	IssueResult,
	NifData,
	ObligationPaymentsForInvoice,
	Pagination,
	PendingInvoicesFilter,
	PendingInvoicesPaginatedResponse,
	PeriodTime,
	RechargeWithInvoicePaginatedResponse,
	SearchInvoice,
	SelectableInvoiceRequest,
	TransactionProductWithInvoice,
	UnusedInvoice,
} from "#resources/api/enterprise-generated";
import { action, observable } from "mobx";
import {
	hideMessageOnTop,
	showErrorNotification,
	showMessageOnTop,
	showSuccessNotification,
	showWarningNotification,
} from "#helpers/notifications";
import { InvoiceModel } from "#models/invoice";
import { RootStore } from ".";
import { autobind } from "core-decorators";
import enterprise from "#resources/api/enterprise-client";
import { fetchModel } from "#helpers/fetch-model";
import dayjs from "dayjs";
import i18n from "#i18n/index";

const t = i18n.t;

function notifyOfIssuing(invoiceCount: IssueResult) {
	if (invoiceCount.failedInvoiceCount === 0) {
		if (invoiceCount.issuedInvoices.length === 0) {
			showSuccessNotification(t("store:invoiceStore.notifyOfIssuing_zero"));
		} else {
			showSuccessNotification(
				t("store:invoiceStore.notifyOfIssuing_other", {
					invoiceCount: invoiceCount.issuedInvoices.length,
				}),
			);
		}
	} else if (invoiceCount.issuedInvoices.length !== 0) {
		showWarningNotification(
			t("store:invoiceStore.notifyOfIssuingWarning_other", {
				issuedInvoices: invoiceCount.issuedInvoices.length,
				errors: invoiceCount.errors.join("\n"),
			}),
		);
	} else {
		showErrorNotification(
			t("store:invoiceStore.notifyOfIssuingWarning_zero", {
				errors: invoiceCount.errors.join("\n"),
			}),
		);
	}
}

function middleDay(date: Date) {
	return dayjs(date).hour(12).toDate();
}

@autobind
export class InvoiceStore {
	@observable
	public hasPendingInvoices: boolean = false;
	@observable
	public fetchInvoicesPromise: IPromiseBasedObservable<void> | null = null;
	@observable
	public sendInvoicesByEmailPromise: IPromiseBasedObservable<void> | null = null;
	@observable
	public issueInvoicesForUserPromise: IPromiseBasedObservable<IssueResult | null> | null = null;
	@observable
	public hasPendingInvoicesPromise: IPromiseBasedObservable<void> | null = null;

	@observable
	public invoices?: InvoiceModel[] = undefined;

	public rootStore: RootStore | null = null;

	constructor(rootStore: RootStore) {
		this.rootStore = rootStore;
	}

	public clean = () => {
		this.getFiscalInvoicesv2.reset();
		this.getFiscalInvoicesv2ToXml.reset();
		this.issueInvoicesOfTransactionsWithConsumptionObligation.reset();
		this.issueInvoicesWithSelectedProducts.reset();
		this.issueInvoicesOfTransactions.reset();
		this.getUnusedInvoices.reset();
		this.getZippedUnusedInvoices.reset();
		this.getAllTransactionsWithInvoices.reset();
		this.getEmittedValue.reset();
		this.getEmittedValueReport.reset();
		this.getEmittedValueByFiscalProfile.reset();
		this.findInvoices.reset();
		this.cancelInvoice.reset();
		this.getNifData.reset();
		this.getAllRechargesWithInvoicesByEvents.reset();
		this.getRechargesInvoicesEmittedValue.reset();
		this.getRechargesInvoicesEmittedNetValue.reset();
	};

	public getEmployeesFromInvoicesByDateRange = new fetchModel<
		{ placeId: string; period: { since: Date; until: Date } },
		EmployeeWithIssuedInvoices[]
	>({
		fnPromise: args => enterprise.getEmployeesFromInvoicesByDateRange(args),
		onError: err => showErrorNotification(err.message),
	});

	@action
	public fetchInvoices = async (eventId: string) => {
		this.invoices = [];
		this.fetchInvoicesPromise = fromPromise(
			enterprise.getInvoices({ eventId }).then(invoices => {
				this.resolveAndReplace(...invoices);
			}),
		);
	};

	@action
	public getInvoice = async (invoiceId: string) => {
		try {
			await enterprise.getInvoice({ id: invoiceId }).then(invoice => invoice);
		} catch (error) {
			console.error(error);
			if (error instanceof Error) {
				showErrorNotification(error.message);
			}
			return null;
		}
	};

	@action
	public fetchFiscalInvoices = (
		placeId: string,
		fiscalProfileId: string,
		since: Date,
		until: Date,
	) => {
		this.invoices = undefined;
		this.fetchInvoicesPromise = fromPromise(
			enterprise
				.getFiscalInvoices({ placeId, fiscalProfileId, since, until })
				.then(invoices => {
					this.resolveAndReplace(...invoices);
				}),
		);
	};

	public getFiscalInvoicesv2 = new fetchModel<
		{
			placeId: string;
			since: Date;
			until: Date;
			pagination: Pagination;
			filterOptions: InvoicesV2Filter;
		},
		InvoicesV2PaginationResponse
	>({
		fnPromise: args => enterprise.getFiscalInvoicesV2(args),
		onError: err => showErrorNotification(err.message),
	});

	public getEmittedValueByBar = new fetchModel<
		{
			placeId: string;
			since: Date;
			until: Date;
		},
		BarEmittedValue[]
	>({
		fnPromise: args =>
			enterprise.getEmittedValueByBar({
				placeId: args.placeId,
				date: {
					since: args.since,
					until: args.until,
				},
			}),
		onError: err => showErrorNotification(err.message),
	});

	public getFiscalInvoicesv2ToXml = new fetchModel<
		{ placeId: string; since: Date; until: Date; pagination: Pagination },
		InvoicesV2PaginationResponse
	>({
		fnPromise: args => enterprise.getFiscalInvoicesV2(args),
		onError: err => showErrorNotification(err.message),
	});

	public getZippedInvoices = new fetchModel<
		{
			placeId: string;
			fiscalProfileId: string;
			since: Date;
			until: Date;
			sendToEmail?: string | null;
		},
		string | null
	>({
		fnPromise: args => enterprise.getZippedInvoices(args),
		onError: err => {
			if (err instanceof Error) {
				if (
					!err.stack?.includes("LimitExceeded") ||
					!err.stack?.includes("PeriodOutOfRange")
				) {
					return showErrorNotification(err.message);
				}
			}
		},
	});

	@action
	public clearInvoices() {
		this.invoices = [];
	}

	@action
	public fetchInvoicesForUser = async (eventId: string, userId: string) => {
		this.resolveAndReplace(
			...(await enterprise.getInvoicesFromUser({ eventId, userId })),
		);
		return this.invoices;
	};

	@action
	public fetchInvoicesForUserAtPlace = async (
		placeId: string,
		userId: string,
		since: Date,
		until: Date,
	) => {
		try {
			this.invoices = undefined;
			const result = await enterprise.getInvoicesFromUserAtPlace({
				placeId,
				userId,
				since,
				until,
			});
			this.resolveAndReplace(...result);
			return this.invoices;
		} catch (err) {
			if (err instanceof Error) {
				showErrorNotification(err.message);
			}
		}
	};

	@action
	public issueInvoicesForUser = async ({
		eventId,
		userId,
		email,
		preventEmail,
	}: {
		eventId: string;
		userId: string;
		email?: string;
		preventEmail?: boolean;
	}) => {
		this.issueInvoicesForUserPromise = fromPromise(
			enterprise
				.issueInvoicesForUser({ eventId, userId })
				.then(async results => {
					notifyOfIssuing(results);

					if (!preventEmail && results.issuedInvoices.length > 0) {
						await enterprise.sendInvoicesByEmail({
							invoiceIds: results.issuedInvoices.map(inv => inv.id),
							email: email || null,
						});
					}

					await this.fetchInvoicesForUser(eventId, userId);

					ReactGA.event({
						category: "User",
						action: "issue user invoices",
					});

					return results;
				})
				.catch(err => {
					if (err instanceof Error) {
						showErrorNotification(err.message);
					}
					return null;
				}),
		);

		return this.issueInvoicesForUserPromise;
	};

	@action
	public issueAllEventInvoices = async (
		eventId: string,
		{ setProgress, notifyError }: EventInvoiceIssuingHooks = {},
	) => {
		try {
			const res = await issueEventInvoicesDebouced({
				eventId,
				setProgress,
				notifyError,
			});

			showSuccessNotification(t("store:invoiceStore.issueAllEventInvoices"));

			ReactGA.event({
				category: "User",
				action: "issue all event invoices",
			});

			return res;
		} catch (error) {
			if (error instanceof Error) {
				showErrorNotification(error.message);
			}
			throw error;
		}
	};

	public sendInvoicesByEmail = (invoiceIds: string[], email: string | null) => {
		this.sendInvoicesByEmailPromise = fromPromise(
			enterprise
				.sendInvoicesByEmail({ invoiceIds, email })
				.then(() => {
					showSuccessNotification(t("store:invoiceStore.sendInvoicesByEmail"));
				})
				.catch(err => {
					if (err instanceof Error) {
						showErrorNotification(err.message);
					}
				}),
		);
	};

	@action
	public resolveAndReplace = (...invoices: Invoice[]) => {
		const invoiceModels: InvoiceModel[] = [];

		for (const invoice of invoices) {
			const invoiceModel = (this.invoices || []).find(inv => inv.id === invoice.id);

			if (!invoiceModel) {
				invoiceModels.push(new InvoiceModel(this, invoice));
			} else {
				invoiceModel.sync(invoice);
				invoiceModels.push(invoiceModel);
			}
		}

		this.invoices = invoiceModels;
	};

	@action
	public resolveAndAppend = (...invoices: Invoice[]) => {
		const invoiceModels: InvoiceModel[] = [];

		for (const invoice of invoices) {
			const invoiceModel = (this.invoices || []).find(inv => inv.id === invoice.id);

			if (!invoiceModel) {
				invoiceModels.push(new InvoiceModel(this, invoice));
				this.invoices = invoiceModels;
			} else {
				invoiceModel.sync(invoice);
			}
		}
	};

	public issueInvoicesOfTransactionsWithConsumptionObligation = new fetchModel<
		{
			eventId: string;
			transactionIds: string[];
			cpf?: string | null;
			cnpj?: string | null;
			nif?: string | null;
			obligations: ObligationPaymentsForInvoice[];
		},
		IssueResult
	>({
		fnPromise: args =>
			enterprise.issueInvoicesOfTransactionsWithConsumptionObligation(args),
		onSuccess: result => {
			result.errors.forEach(errorMessage => {
				showErrorNotification(errorMessage);
				hideMessageOnTop();
			});

			if (result.errors.length === 0) {
				showMessageOnTop({
					description: t("store:invoiceStore.issueInvoicesOfTransactions"),
					time: 5000,
					type: "success",
				});
			}
		},
		onError: err => {
			if (err instanceof Error) {
				showErrorNotification(err.message);
			}
			hideMessageOnTop();
		},
	});

	public issueInvoicesWithSelectedProducts = new fetchModel<
		{
			eventId: string;
			selectableInvoiceRequest: SelectableInvoiceRequest;
		},
		IssueResult
	>({
		fnPromise: args => enterprise.issueInvoicesWithSelectedProducts(args),
		onSuccess: result => {
			result.errors.forEach(errorMessage => {
				showErrorNotification(errorMessage);
				hideMessageOnTop();
			});

			if (result.errors.length === 0) {
				showMessageOnTop({
					description: t("store:invoiceStore.issueInvoicesOfTransactions"),
					time: 5000,
					type: "success",
				});
			}
		},
		onError: err => {
			if (err instanceof Error) {
				showErrorNotification(err.message);
			}
			hideMessageOnTop();
		},
	});

	public issueInvoicesOfTransactions = new fetchModel<
		{
			eventId: string;
			transactionIds: string[];
			cpf: string | null;
			cnpj: string | null;
			userId?: string;
		},
		IssueResult
	>({
		fnPromise: ({ userId, transactionIds, eventId, cpf, cnpj }) =>
			userId
				? enterprise.issueInvoicesForUser({ eventId, userId })
				: enterprise.issueInvoicesOfTransactions({ eventId, transactionIds, cpf, cnpj }),
		onSuccess: result => {
			result.errors.forEach(errorMessage => {
				showErrorNotification(errorMessage);
				hideMessageOnTop();
			});

			if (result.errors.length === 0) {
				showMessageOnTop({
					description: t("store:invoiceStore.issueInvoicesOfTransactions"),
					time: 5000,
					type: "success",
				});
			}
		},
		onError: err => {
			if (err instanceof Error) {
				showErrorNotification(err.message);
			}
			hideMessageOnTop();
		},
	});

	public getUnusedInvoices = new fetchModel<
		{
			fiscalProfileId: string;
			placeId: string;
			since: Date;
			until: Date;
		},
		UnusedInvoice[]
	>({
		fnPromise: args => enterprise.getUnusedInvoices(args),
		onError: err => showErrorNotification(err.message),
	});

	public getPendingInvoices = new fetchModel<
		{
			timePeriod: PeriodTime;
			placeId: string;
			pagination: Pagination;
			filterOptions?: PendingInvoicesFilter | null;
		},
		PendingInvoicesPaginatedResponse
	>({
		fnPromise: args => enterprise.getPendingInvoicesInternational(args),
		onError: err => showErrorNotification(err.message),
	});

	public getTotalPendingAutoissuanceOrders = new fetchModel<
		{
			timePeriod: PeriodTime;
			placeId: string;
			filterOptions?: PendingInvoicesFilter | null;
		},
		number
	>({
		fnPromise: args => enterprise.getTotalPendingAutoissuanceOrders(args),
		onError: err => showErrorNotification(err.message),
	});

	public getZippedUnusedInvoices = new fetchModel<
		{
			fiscalProfileId: string;
			since: Date;
			until: Date;
		},
		string
	>({
		fnPromise: args => enterprise.getZippedUnusedInvoices(args),
		onError: err => showErrorNotification(err.message),
	});

	public getAllTransactionsWithInvoices = new fetchModel<
		{ placeId: string; since: Date; until: Date; isIssued: boolean | null },
		TransactionProductWithInvoice[]
	>({
		fnPromise: args =>
			enterprise.getAllTransactionsWithInvoices({
				placeId: args.placeId,
				since: middleDay(args.since),
				until: middleDay(args.until),
				isIssued: args.isIssued,
			}),
		onError: err => showErrorNotification(err.message),
	});

	public getAllRechargesWithInvoicesByEvents = new fetchModel<
		{
			eventIds: string[];
			pagination: Pagination;
			filterOptions: { isIssued: boolean | null };
		},
		RechargeWithInvoicePaginatedResponse
	>({
		fnPromise: args => enterprise.getAllRechargesWithInvoicesByEvents(args),
		onError: err => showErrorNotification(err.message),
	});

	public getRechargesInvoicesEmittedValue = new fetchModel<
		{ eventIds: string[] },
		number
	>({
		fnPromise: args => enterprise.getRechargesInvoicesEmittedValue(args),
		onError: err => showErrorNotification(err.message),
	});

	public getRechargesInvoicesEmittedNetValue = new fetchModel<
		{ eventIds: string[] },
		number
	>({
		fnPromise: args => enterprise.getRechargesInvoicesEmittedNetValue(args),
		onError: err => showErrorNotification(err.message),
	});

	public getRechargesInvoicesXlsx = new fetchModel<{ eventIds: string[] }, string>({
		fnPromise: args => enterprise.getRechargesInvoicesXlsx(args),
		onError: err => showErrorNotification(err.message),
	});

	public getEmittedValue = new fetchModel<
		{ placeId: string; since: Date; until: Date },
		number
	>({
		fnPromise: args =>
			enterprise.getEmittedValue({
				placeId: args.placeId,
				since: middleDay(args.since),
				until: middleDay(args.until),
			}),
		onError: err => showErrorNotification(err.message),
	});

	public getIvaNetEmittedValue = new fetchModel<
		{ placeId: string; since: Date; until: Date },
		number
	>({
		fnPromise: args =>
			enterprise.getIvaVendusEmittedNetValueForTransactions({
				placeId: args.placeId,
				since: middleDay(args.since),
				until: middleDay(args.until),
			}),
		onError: err => showErrorNotification(err.message),
	});

	public getTotalTransactionsByEvents = new fetchModel<
		{ placeId: string; since: Date; until: Date },
		number
	>({
		fnPromise: args =>
			enterprise.getTotalTransactionsByEvents({
				placeId: args.placeId,
				date: {
					since: middleDay(args.since),
					until: middleDay(args.until),
				},
			}),
		onError: err => showErrorNotification(err.message),
	});

	public getEmittedValueReport = new fetchModel<
		{ placeId: string; since: Date; until: Date },
		GenericValueForReport[]
	>({
		fnPromise: args => enterprise.getEmittedValueReport(args),
		onError: err => showErrorNotification(err.message),
	});

	public getEmittedValueByEmployee = new fetchModel<
		{ placeId: string; since: Date; until: Date },
		EmployeeEmittedValue[]
	>({
		fnPromise: args =>
			enterprise.getEmittedValueByEmployee({
				placeId: args.placeId,
				dateFilter: {
					since: middleDay(args.since),
					until: middleDay(args.until),
				},
			}),
		onError: err => showErrorNotification(err.message),
	});

	public getEmittedValueByFiscalProfile = new fetchModel<
		{ placeId: string; since: Date; until: Date },
		FiscalProfileEmittedValue[]
	>({
		fnPromise: args => enterprise.getEmittedValueByFiscalProfile(args),
		onError: err => showErrorNotification(err.message),
	});

	public findInvoices = new fetchModel<
		{ placeId: string; since: Date; until: Date; num: number },
		SearchInvoice[]
	>({
		fnPromise: args => enterprise.findInvoices(args),
		onError: err => showErrorNotification(err.message),
	});

	public cancelInvoice = new fetchModel<{ invoiceId: string }, void>({
		fnPromise: args => enterprise.cancelInvoice(args),
		onSuccess: () => {
			if (this.rootStore?.placeStore.isCurrentPlaceInternational) {
				return showSuccessNotification(t("store:invoiceStore.cancelInvoiceNC"));
			}
			showSuccessNotification(t("store:invoiceStore.cancelInvoice"));
		},
		onError: err => showErrorNotification(err.message),
	});

	public getReportInvoiceFiscalProductGroups = new fetchModel<
		{ placeId: string; since: Date; until: Date },
		InvoiceFiscalProductGroup[]
	>({
		fnPromise: args => enterprise.getReportInvoiceFiscalProductGroups(args),
		onError: err => showErrorNotification(err.message),
	});

	public getNifData = new fetchModel<{ nif: string }, NifData | null>({
		fnPromise: args => enterprise.getNifData(args),
		onError: err => showErrorNotification(err.message),
	});
}
