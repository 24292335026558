export const tagGroups = {
	title: "Grupo de Tags",
	total: "Total",
	exportToExcel: "Exportar para excel",
	importConsumption: "Importar consumo",
	blockCards: {
		label: "Bloquear Tags Selecionadas",
		confirmationMessage:
			"Você está prestes a bloquear as tags selecionadas. Tem certeza de que deseja prosseguir?",
		confirmationButton: "Sim, bloquear tags",
	},
	unblockCards: {
		label: "Ativar Tags Selecionadas",
		confirmationMessage:
			"Você está prestes a ativar as tags selecionadas. Tem certeza de que deseja prosseguir?",
		confirmationButton: "Sim, ativar tags",
	},
	filter: {
		zigCode: {
			label: "Filtrar por Zig Code",
			placeholder: "Zig Code",
		},
		group: {
			label: "Filtrar por grupo",
			placeholder: "Nome do Grupo",
		},
		serial: {
			label: "Filtrar por Serial",
			from: "De:",
			to: "Até:",
			placeholderFrom: "Ex: 1001",
			placeholderTo: "Ex: 2001",
		},
		status: {
			label: "Filtrar por Status",
			options: {
				all: "Todos",
				blocked: "Bloqueados",
				inUse: "Em uso",
				used: "Anexado",
				inactive: "Inativo",
			},
		},
		userType: {
			label: "Por Tipo de Usuário",
			options: {
				all: "Todos",
				anonymous: "Anônimo",
				nonAnonymous: "Não Anônimo",
			},
		},
	},
	table: {
		export: {
			fileName: "grupo-de-cartoes-do-evento-{{eventName}}_{{eventDate}}",
			titleName: "Grupo de Cartões do evento {{eventName}}",
		},
		selectedItems: "Itens selecionados:",
		columns: {
			zigCode: "Zig Code",
			serialNumber: "Serial",
			status: {
				label: "Status",
				blocked: "Bloqueado",
				inUse: "Em uso",
				used: "Anexado",
				inactive: "Inativo",
			},
			user: {
				label: "Usuário",
				anonymous: "Anônimo",
			},
			zigDeviceGroup: "Grupo de Cartão",
			totalValue: "Saldo",
			actions: {
				label: "Ações",
				block: "Bloquear",
				blockCard: "Bloquear Cartão",
				unblock: "Desbloquear",
				unblockCard: "Desbloquear Cartão",
				addConsumption: "Adicionar consumação",
			},
		},
	},
	addConsumption: {
		title: "Adicionar consumação",
		confirmButton: "Confirmar",
		fields: {
			rechargeType: {
				label: "Tipo de Recarga",
			},
			balanceValue: {
				label: "Valor de Recarga",
				required: "O valor de recarga deve ser maior que zero",
			},
			reason: {
				label: "Motivo",
				required: "Informe o motivo",
			},
		},
	},
	topUpCancelation: {
		confirmation: {
			title: "Confirmar Cancelamento de Recargas Pendentes",
			description:
				"Você está prestes a cancelar todas as recargas pendentes associadas a este cartão. Confirma o cancelamento?",
			confirmButton: "Confirmar Cancelamento",
			cancelButton: "Voltar",
		},
		confirmationByBatch: {
			title: "Confirmar Cancelamento em Lote de Recargas Pendentes",
			description:
				"Você está prestes a cancelar todas as recargas pendentes associadas aos cartões selecionados. Confirmar o cancelamento em lote?",
			confirmButton: "Confirmar Cancelamento",
			cancelButton: "Voltar",
		},
		cancelationButton: "Cancelar recargas pendentes",
	},
};
