import flatten from "tree-flatten";

import {
	BackofficeBill,
	BackofficeBillPlanCategory,
	BackofficeNewBill,
	BasicStorageUnit,
} from "#api/enterprise-generated";
import i18n from "#i18n/index";
import { rootStore } from "#stores/index";

const t = i18n.t;

type IPlanOfAccounts = Omit<BackofficeBillPlanCategory, "children"> & {
	children: any;
};

const onlyNumbersRegex = /[^\d]/g;
const onlyNumbersAndLetters = /[^A-Za-z0-9]/g;
const normalizeRegex = /[^a-zA-Z\d]/gi;

export function onlyNumbers(value: string) {
	return value.replace(onlyNumbersRegex, "");
}
export function onlyLetersAndNumber(value: string) {
	return value.replace(onlyNumbersAndLetters, "");
}

export function normalize(value: string) {
	return value.replace(normalizeRegex, "");
}

export function onlyValidNumber(v: any): number | undefined {
	return typeof v === "number" && !isNaN(v) ? v : undefined;
}

export function moneyToAllowNegativeNumber(brl: string) {
	return Number(brl.replace(/[^0-9,-]/gi, ""));
}

export function phoneFormatter(phoneRaw: string) {
	const phoneWithoutSymbols = phoneRaw.replace(/\D/g, "");
	let phone = "";
	let prefix = "";

	if (phoneWithoutSymbols.length > 8) {
		phone = phoneWithoutSymbols.substr(phoneWithoutSymbols.length - 9);
		phone = `${phone.substr(0, 5)}-${phone.substr(5)}`;

		prefix = phoneWithoutSymbols.substr(0, phoneWithoutSymbols.length - 9);
		prefix = `${prefix.substr(0, prefix.length - 2)} (${prefix.substr(
			prefix.length - 2,
		)})`;

		if (phoneRaw.trim()[0] === "+") prefix = `+${prefix}`;

		return `${prefix} ${phone}`.trim();
	} else if (phoneWithoutSymbols.length === 8) {
		phone = phoneWithoutSymbols;
		return `${phone.substr(0, 4)}-${phone.substr(4)}`;
	} else return phoneWithoutSymbols;
}

export function cpfFormatter(cpfRaw: string) {
	if (cpfRaw.trim().length !== 11) {
		console.warn("CPF out of default size.");
		return t("globalKeys:invalid");
	} else {
		return cpfRaw.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/g, "$1.$2.$3-$4");
	}
}

export function currencyFormatter(moneyRaw: number) {
	const lng = localStorage.getItem("lng") ?? i18n.language;
	const { organizationStore } = rootStore;
	const { currencyOrg } = organizationStore;

	const formater = new Intl.NumberFormat(lng, {
		style: "currency",
		currency: currencyOrg ? `${currencyOrg}` : "BRL",
		currencyDisplay: "narrowSymbol",
	});

	const value = formater.format(Math.abs(moneyRaw || 0) / 100);

	const prefix = moneyRaw < 0 ? "-" : "";
	return `${prefix} ${value}`;
}

export function currencyReverter(formattedNumber: string) {
	return Number(formattedNumber.replace(/[^\d-]/g, ""));
}

export function billToNewBill(bill: BackofficeBill): BackofficeNewBill {
	const { id, supplier, account, attachment, ...newBill } = bill;

	return {
		...newBill,
		costCenter: bill.costCenter ? bill.costCenter.name : null,
		billPlanCategories: [],
		billType: bill.billType
			? {
					idOrName: bill.billType.name,
					category: bill.billType.category.name,
			  }
			: null,
	};
}

export function billPlanTreeToArr(tree: IPlanOfAccounts[]) {
	const treeFormated: IPlanOfAccounts[] = [];

	tree.forEach(i => {
		treeFormated.push(...flatten(i, "children"));
	});

	return treeFormated;
}

export function truncatePhrase(prhase: string, prhaseLength: number) {
	return prhase.length > prhaseLength ? `${prhase.slice(0, prhaseLength)}...` : prhase;
}

export function calculateProductCost(productCost: number, unit: BasicStorageUnit) {
	if (unit === "kilogram" || unit === "liter") return productCost / 1000;
	else return productCost;
}

export function removeAccents(str: string) {
	return str.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
}
