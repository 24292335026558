import { MessageTemplateStatus } from "#resources/api/enterprise-generated";

export const modalMyModels = {
	title: "My models",
	columns: {
		model: "Model",
		status: "Status",
	},
	status: {
		approved: "Approved",
		pending: "Pending",
		received: "Recieved",
		rejected: "Rejected",
	} as Record<MessageTemplateStatus, string>,
	emptyState: {
		title: "No template registered",
		text: "Request a new template to send WhatsApp messages to your customers",
	},
	requestNewModel: "request new model",
};
