export const onboarding = {
	modularization: {
		title: "Introduzindo <strong>Novo Menu Zig</strong>",
		content:
			"<strong>Agora você pode...</strong> visualizar seu histórico financeiro de forma detalhada e personalizada, com filtros avançados e relatórios que facilitam o acompanhamento das suas transações.",
		primaryActionLabel: "Saiba mais",
		secondaryActionLabel: "Pular",
	},
	bonusReport: {
		title: "Acompanhe os bônus concedidos e utilizados",
		content:
			"Agora você pode acompanhar os bônus concedidos e utilizados a partir de relatórios detalhados com o total distribuído, nome do cliente, colaborador, motivo e valor. Veja agora o novo relatório acessando a aba de Bônus nos relatórios financeiros do seu local.",
		primaryActionLabel: "Fechar",
	},
};
