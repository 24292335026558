import { formatter } from "./formatter";

export const promotion = {
	defaultName: "Cópia de {{promotion}}",
	errors: {
		nameLength: "O nome da promoção não atingiu a quantidade mínima de caracteres.",
		sameName: "O nome da cópia é igual ao nome original.",
		notFilledCorrectly: "Os campos não foram preenchidos corretamente.",
	},
	copyModal: {
		okText: "Copiar",
		title: "Criar cópia de promoção",
		message: "Deseja criar cópia da promoção",
		placeholder: "Nome da cópia de promoção",
	},
	peopleTable: {
		example: "planilha exemplo",
		error: "Formato inválido",
		import: "Importar planilha",
		download: "Baixar planilha exemplo",
	},
	categoryModal: {
		title: "Adicionar novo produto",
		label: "Produto ou categoria",
		placeholder: "Busque por um produto ou categoria",
		noneProduct: "Nenhum produto encontrado",
	},
	creationStep: {
		formatter,
		error: "Não foi possível redirecionar página.",
		steps: {
			promotionInfo: "Dados da promoção",
			goals: "Metas",
			awards: "Prêmios",
			usageConditions: "Condições de uso",
			resume: "Resumo",
		},
		stepsPages: {
			goals: {
				label: "Recorrência de ganhos",
				placeholder: "Selecione uma recorrência máxima de ganhos",
				error: "Condição TODOS OS CLIENTES precisa ser removida",
				new: "Nova meta",
				add: "Adicionar meta",
				conditions: {
					birthday: {
						month: "Aniversariante do mês",
						week: "Aniversariante da semana",
						day: "Aniversariante do dia",
					},
					checkin: {
						label: "Horário de chegada",
					},
					gender: {
						male: "Homens",
						female: "Mulheres",
						others: "Outros",
					},
					minimumRecharge: {
						label: "Valor mínimo da recarga",
					},
				},
			},
			usageConditions: {
				conditions: {
					expiration: {
						label: "Dias corridos:",
						suffix: "Dias",
					},
					period: {
						beginTime: "De:",
						endTime: "Até:",
						error: "Hora no formato inválido para a conversão",
					},
					week: {
						infoText:
							"Como os eventos podem começar num dia da semana e terminar no outro, a promoção é válida no dia que o evento é iniciado. Então, o cliente pode usar a promoção no dia seguinte, caso o evento tenha sido iniciado no dia anterior.",
						validPromotion: "Promoção válida ",
					},
					modal: {
						new: "Nova condição de uso",
						add: "Adicionar condição de uso",
					},
				},
			},
		},
		stepsWrapper: {
			title: "Título não encontrado",
			description: "Descrição não encontrada",
			save: "Salvar alterações",
		},
	},
	table: {
		goals: {
			label: "Lista de metas da promoção",
			empty: "Nenhuma meta",
			new: "Nova meta",
		},
		usageConditions: {
			label: "Lista de condições de uso da promoção",
			empty: "Nenhuma condição de uso",
			new: "Nova condição de uso",
		},
	},
};
