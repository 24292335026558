import * as React from "react";
import Header, { ICustomHeaderProps } from "#components/nav/header";
import { observer } from "mobx-react-lite";
import RootStore from "#stores/index";

interface IMainHeader {
	routeParams: GlobalTypes.RouteParams;
	customHeader?: ICustomHeaderProps;
}

function MainHeader({ routeParams, customHeader }: IMainHeader) {
	const { organizationStore, placeStore, eventStore } = React.useContext(RootStore);

	const organization = organizationStore.organization || undefined;
	const place = routeParams.placeId ? placeStore.place || undefined : undefined;
	const event = routeParams.eventId ? eventStore.event || undefined : undefined;

	return (
		<Header
			organization={organization}
			place={place}
			event={event}
			customHeader={customHeader}
		/>
	);
}

export default observer(MainHeader);
