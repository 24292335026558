export const prechargeReports = {
	title: "Relatórios de Pré Carga",
	filterByDate: "Filtrar por data",
	cards: {
		retrievedRecharge: "Cargas Resgatadas",
		consumedValue: "Cargas Utilizadas",
		avgRechargedValue: "Ticket Médio das cargas dadas",
		avgRetrievedValueByDay: "Média de resgate por dia",
		gender: {
			label: "Divisão de Gênero",
			male: {
				title: "{{percent}}% de Homens",
				acronym: "H",
			},
			female: {
				title: "{{percent}}% de Mulheres",
				acronym: "M",
			},
			other: {
				title: "{{percent}}% de Outros",
				acronym: "O",
			},
		},
	},
	table: {
		exportToExcel: {
			title: "{{placeName}}_{{date}}",
			filename: "{{placeName}}_{{date}}",
		},
		columns: {
			date: "Data",
			maleQuantity: "Total de Cargas para Homens",
			femaleQuantity: "Total de Cargas para Mulheres",
			otherQuantity: "Total de Cargas para Outros",
			topUpQuantity: "Qtd Total de Cargas",
			redeemedQuantity: "Qtd Total de Resgates",
			accumulatedValue: "Valor Acumulado",
			bonusValue: "Valor Bonus",
			redeemedValue: "Valor Resgatado",
			usedValue: "Valor Utilizado",
		},
	},
};
