export const abcPurchaseReport = {
	title: "ABC Purchases Report",
	label: "Purchases ABC",
	description:
		"The ABC curve method in inventory management will primarily reveal the importance of each item to the company - i.e., which are the most expensive goods that need attention in cost control and purchasing processes.",
	export: "Download in Excel",
	filterByPlaceholder: "All curves",
	contentTable: {
		emptyReports: "No reports available",
		totalizers: {
			totalValues: "Total product values",
			curveA: "Curve A",
			curveB: "Curve B",
			curveC: "Curve C",
			tooltips: {
				curveA:
					"Products with high value that correspond to up to 80% of the purchase value",
				curveB:
					"Products with intermediate value that correspond to up to 15% of the purchase value",
				curveC:
					"Products with low value that correspond to up to 5% of the purchase value",
			},
		},
		columns: {
			name: "Product or Input",
			quantity: "Quantity Purchased",
			unitMeasurement: "Unit of Measurement",
			unitaryValue: "Unit Cost",
			total: "Total Cost",
			cumulateTotal: "Cumulative Total",
			percentage: "Individual Representation",
			cumulativePercentage: "Cumulative Representation",
			tier: "Classification",
		},
	},
};
