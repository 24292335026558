import * as React from "react";
import { useTranslation } from "react-i18next";
import { Modal } from "antd";
import { languageOptions } from "#structure/providers/language";
import { DashLanguages } from "#i18n/utils";
import GlobalSvg from "#resources/assets/global.svg";
import ArrowRight from "#resources/assets/svg/arrow-right.svg";

import s from "./change-language-modal.scss";
import Icon, { IconName } from "#components/icon";

interface IChangeLanguageModal {
	isLanguageModalOpen: boolean;
	onChangeLanguageModalStatus: (isOpen: boolean) => void;
	changeLanguage: (language: DashLanguages, forceReload?: boolean) => void;
}

export function ChangeLanguageModal({
	isLanguageModalOpen,
	onChangeLanguageModalStatus,
	changeLanguage,
}: IChangeLanguageModal) {
	const { t } = useTranslation("nav", { keyPrefix: "navbar" });

	return (
		<Modal
			title={t("default.changeSystemLanguage")}
			className={s.languageModal}
			open={isLanguageModalOpen}
			onCancel={() => onChangeLanguageModalStatus(false)}
			footer={null}
		>
			<div className={s.divLanguage}>
				<ul className={s.optionList}>
					{languageOptions.map(({ value, label, icon }) => (
						<li key={value} className={s.languageOption}>
							<button
								className={s.optionButton}
								onClick={() => {
									changeLanguage(value, true);
									onChangeLanguageModalStatus(false);
								}}
							>
								<div className={s.iconContainer}>
									<GlobalSvg />
									{icon && (
										<div className={s.languageFlag}>
											<Icon icon={icon as IconName} />
										</div>
									)}
								</div>
								{label}
								<ArrowRight />
							</button>
						</li>
					))}
				</ul>
			</div>
		</Modal>
	);
}
