export const financialReport = {
	print: "Imprimir relatório",
	letterhead: 'Emitido por "{{name}} às {{momentLT}} em {{momentL}}"',
	menu: {
		financialReport: "Fechamento",
		promotionsReports: "Promoções utilizadas",
		salesTotemTransaction: "Vendas por transações Totem",
		debtorsReport: "Contas em aberto",
		devolutionReport: "Devolução de cartão por caixa",
		cashierClose: "Fechamento de caixa",
		bonusReport: "Bônus",
		discountsReportForPlace: "Descontos em conta",
		refundedProductsReport: "Produtos estornados",
		seeRefundReports: "Serviços removidos",
		bonusByProduct: "Bônus por produto",
		openedBillPayment: "Pagamento de contas em aberto",
		reserveReport: "Relatório de pacotes",
		rappiDiscountsReport: "Descontos Rappi",
		consumptionObligationReport: "Consumação mínima",
		minimumConsumptionReport: "Consumação antecipada",
		openSalesReport: "Vendas avulsas",
		cashierAdjustmentsReport: "Sangrias e Fundos de Troco",
		zigAppTransactions: "Vendas por transações do Zig.app",
		transactionTransfer: "Transferências de contas",
	},
	tabs: {
		financialReport: {
			title: "Financeiro de {{placeName}}",
			seeAllPaymentsButton: "Ver todos os pagamentos",
			analyticPayments: {
				title: "Relatório Analítico",
				columns: {
					date: "Data",
					author: "Funcionário",
					client: "Cliente",
					method: "Método",
					value: "Valor",
				},
				searchName: "Buscar por cliente ou funcionário...",
				searchMethod: "Selecione um método...",
				bonus: "Bônus por produto",
				filename: "relatorio-analitico",
			},
			cmv: {
				title: "CMV",
				name: "Nome",
				value: "Valor",
				valuesName: "Custo total de mercadoria vendida",
			},
			goTo: "ir para",
			filename: "relatorio_fechamento",
			columnSection: {
				value: "valor",
				receipt: "receita",
				percentage: "percentual",
				quantity: "quantidade",
			},
		},
		salesTotemTransaction: {
			columns: {
				date: "Data",
				author: "Funcionário",
				client: "Cliente",
				method: "forma de pagamento",
				operator: "Operador",
				totem: "Totem",
				value: "Valor da venda",
				details: "Detalhes",
			},
			details: {
				title: "Detalhes da transação",
				refunded: "Estornado",
				products: "Produtos",
				transactionId: "Id da transação",
				zigId: "Id da zig",
				close: "Fechar",
			},
			totalizer: {
				totalClients: "Total de clientes",
				paymentWithBalance: "Pagamento com saldo",
				totalSales: "Total em vendas",
				searchByIdOrCpf: "Busque por ID ou CPF",
				paymentMethod: "Forma de pagamento",
				totemLabel: "Totem",
				enterOrSelectTotem: "Digite ou selecione o totem",
			},
			paymentMethodDetails: {
				CreditCard: "Cartão de Crédito",
				DebitCard: "Cartão de Débito",
				Cash: "Dinheiro",
				PostEvent: "Post Evento",
				ZigPosCredit: "ZigPos Crédito",
				ZigPosDebit: "ZigPos Débito",
				Voucher: "Voucher",
				Bonus: "Bônus",
				Pix: "Pix",
				IntegratedFoodVoucher: "Voucher de Alimentação Integrado",
				ZigPosCreditInInstallments: "ZigPos Crédito em Parcelas",
				Anticipated: "Antecipado",
				Ame: "Ame",
				MBWay: "MBWay",
			},
		},
		promotionsReports: {
			title: "Promoções utilizadas de {{placeName}}",
			promotionReportLabel: "Promoções utilizadas",
			promotionReportFileName: "promocoes-utilizadas",
			userPromotionReportLabel: "Utilizadas por pessoas",
			userPromotionReportFileName: "promocoes-utilizadas-por-pessoa",
			totalDiscount: "Total de desconto",
			totalUses: "Total de uso",
			columns: {
				userName: "Nome",
				userDocument: "CPF",
				productName: "Produto",
				promotionName: "Promoção",
				productCategory: "Categoria",
				uses: "Quantidade de usos",
				totalDiscount: "Desconto total",
			},
		},
		debtorsReport: {
			title: "Contas em aberto",
			goTo: "ir para",
			clientInfo: "Informações de Cliente",
			filename: "contas-aberto",
			clientTotal_one: "Total ({{debtors}} cliente)",
			clientTotal_other: "Total ({{debtors}} clientes)",
			modalTitleName: "Pagamento de {{userName}}",
			modalTitleDebts: "Pagamento de {{debts}} contas",
			columns: {
				username: "Nome",
				document: "CPF",
				phone: "Telefone",
				date: "Data",
				value: "Aberto em conta",
				tipValue: "Aberto em serviço",
				totalOpen: "Total em aberto",
				paidValue: "Pago após evento",
				stillOpen: "Ainda em aberto",
				filters: {
					paid: "Já pago",
					unpaid: "Não pago",
				},
				others: {
					allBillsPaid: "Todas contas pagas",
					payAllBills: "Pagar todas contas",
					paid: "Já pago",
					paymentOptions: "Opções de pagamento",
					pixCharge: "Gerar cobrança via PIX",
				},
				status: {
					title: "Status de cobrança",
					paid: "Pago",
					unpaid: "A pagar",
				},
			},

			search: "Buscar cliente...",
			paymentLink: {
				sendCharge: "Cobrar",
				generatedLink: "Link gerado",
				charged: "Cobrado",
				noReturn: "Sem retorno",
				cancel: "Cancelar",
				discharge: "Dar baixa",
				chooseShippingMethod: "Escolha o método de envio",
				finalPaymentLink: "Copie o link para controle ou para enviar em outro momento",
				error: "O número fornecido pelo cliente não possui Whatsapp.",
				details: "Detalhes {{userName}}",
				openValues: "Valores em aberto",
				openConsumption: "Consumo em aberto",
				totalDebit: "Total em aberto",
				tip: "serviço",
				mail: "E-mail",
				phone: "Telefone",
				consumedProducts: "Produtos consumidos",
				viewBillingDetails: "Ver detalhes",
				copyError: "Erro ao copiar link",
				copySuccess: "Copiado com sucesso",
				status: {
					created: "Link gerado",
					notCharged: "Não cobrado",
					expired: "Sem resposta",
					partiallyCreated: "Parcialmente cobrado",
				},
				whatsappMessage:
					"Olá {{userName}}, esperamos que esteja tudo bem. Gostaríamos de informar que você possui uma conta pendente no {{placeName}}." +
					" Para sua comodidade, disponibilizamos um link de pagamento seguro onde você pode efetuar o pagamento de forma rápida e fácil: " +
					"{{link}} . Caso já tenha efetuado o pagamento, por favor, desconsidere esta mensagem. Caso contrário, agradecemos antecipadamente pela sua cooperação em resolver essa pendência.",
				emailError: "Erro ao enviar o email",
				emailSuccess: "Email enviado com sucesso",
				paidBillMessage: "Não é possível cobrar uma conta paga",
				resendBill: "Reenviar",
				statusTooltip: {
					created: "Link de cobrança gerado para este cliente.",
					notCharged: "Nenhum link foi gerado para cobrar este cliente",
					partiallyCreated:
						"Existe um link de cobrança gerado para esse cliente, porém ele possui outra conta em aberto no período que não foi cobrada no link anterior",
					expired:
						"O link de pagamento foi gerado a mais de 7 dias e não foi pago até o momento",
				},
				selectService: "Deseja incluir a taxa de serviço no valor em aberto?",
				selectServiceText:
					"Ao escolher incluir a taxa de serviço, o valor da taxa será adicionada ao valor em aberto da conta.",
				dontIncludeService: "Não incluir ",
				includeService: "Incluir taxa de serviço",
				seeMore: "Ver mais",
				copyLink: "Copiar link da cobrança",
				copyValidLink: "Certifique-se de que este é um link válido",
				retrieveLink: "Resgatar link de cobrança",
				moreOptions: "Mais opções",
			},
		},
		devolutionReport: {
			title: "Devolução de cartão por caixa",
			filename: "devolucao-de-cartao-por-caixa",
			sheetTitle: "Devolução de cartão {{sheetName}}",
			filenameCard: "devolucao-de-cartao",
			total: "Total",
			totalCategory: "Total ({{category}})",
			totalGeral: "Total geral",
			cashier: "Por caixas",
			cashDevolution: "Devolução em dinheiro",
			devolution: "Devolução em {{method}}",
			devolutions: "{{value}} devoluções",
			columns: {
				employee: "Funcionário",
				quantity: "Quantidade",
				value: "Valor",
				type: "Tipo",
			},
		},
		cashierClose: {
			title: "Fechamento de caixa",
			resume: "Resumo",
			cashiers: "Caixas",
			search: "Buscar caixa",
			searchBars: "Buscar bares",
			excelExport: "Exportar para excel",
			changeFund: "Fundo de troco",
			modality: "Modalidade",
			billed: "Faturado",
			closed: "Fechado",
			difference: "Diferença",
			methods: {
				cash: "Dinheiro",
				debit: "Débito",
				credit: "Crédito",
				voucher: "Voucher",
				zigDebit: "Zig Débito",
				zigCredit: "Zig Crédito",
				postEvent: "Após o evento",
				dashboard: "Dashboard",
				anticipated: "Antecipado",
				deliveryOnline: "Delivery Online",
				ame: "Ame",
				rappi: "Rappi",
				uber: "Uber",
				ifood: "Ifood",
				pix: "Pix",
				bonus: "Bonus",
				installment: "Parcelamento",
				foodVoucher: "Vale-refeição",
			},
			hawkers: "Ambulantes",
			hawkerCashier: "Caixa de Ambulantes",
		},
		bonusReport: {
			title: "Bônus",
			description: "Veja todos os relatórios de concessão e uso de bônus",
			filenameBonus: "bonus-concedido",
			filenameAnticipated: "consumacao-antecipada",
			filenameComsumption: "consumacao",
			total: "Total de {{category}}",
			tabs: {
				given: "Bônus Concedido",
				used: "Bônus Utilizado",
			},
			usedBonus: "Bonus Utilizados",
			columns: {
				client: "Cliente",
				date: "Data",
				dateTime: "Data e Hora",
				reason: "Motivo",
				receivedValue: "Valor recebido",
				spentValue: "Valor gasto no período",
				spentValueInOtherEvents: "Valor gasto em outro período",
				spentValueInBonus: "Valor do Pagamento em Bônus",
				givenBy: "Dado por",
				total: "Total",
			},
		},
		discountsReportForPlace: {
			title: "Descontos utilizados de {{placeName}}",
			discounts: "Descontos",
			filename: "descontos",
			columns: {
				employee: "Funcionário",
				date: "Data",
				clients: "Clientes",
				justify: "Justificativa",
				category: "Categoria",
				products: "Produtos",
				percentage: "Porcentagem",
				discount: "Desconto",
				total: "Total",
			},
			filter: "Filtrar por categoria",
			selectCategory: "Escolher a categoria",
		},
		refundedProductsReport: {
			title: "Produtos estornados",
			titleRecharges: "Recargas estornadas",
			filenameRecharges: "recargas-estornadas",
			columns: {
				date: "Data",
				client: "Cliente",
				author: "Recarga feita por",
				refundedBy: "Estornado por",
				totalValue: "Valor total",
				usedValue: "Valor utilizado",
			},
		},
		seeRefundReports: {
			title: "Remoção de serviços por caixa",
			printButton: "Imprimir relatório",
			exportButton: "Exportar relatório",
		},
		bonusByProduct: {
			title: "Bônus por produto",
			filename: "bonus_por_produto",
			columns: {
				product: "Produto",
				category: "Categoria",
				value: "Bônus recebido no período",
				bonusSpentFromOtherEvents: "Bônus recebido em outro período",
				total: "Total",
				totalCategory: "Total ({{category}})",
			},
			cards: {
				totalProductsValue: "Total de bônus em Produtos",
				totalTipValue: "Total de bônus em Serviço",
				totalValue: "Total",
				receivedInPeriod: "Recebido no período",
				receivedAtAnotherPeriod: "Recebido em outro período",
			},
		},
		openedBillPayment: {
			title: "Pagamentos de contas em aberto",
			tooltip: "Informações do cliente",
			columns: {
				date: "Data de pagamento",
				client: "Cliente",
				document: "CPF",
				openedEventDate: "Data da conta em aberto",
				employee: "Pago para",
				method: "Método",
				value: "Valor",
				total: "Total",
				observation: "Observação",
				tipValue: "Valor de gorjeta",
			},
			mixed: "Misto",
			emptyText: "Sem dados a serem exibidos.",
		},
		reserveReport: {
			title: "Pacotes",
			withoutPackage: "Nenhum pacote ativo durante o período",
			onePackage: "Um pacote ativo durante o período",
			manyPackages: "{{quantity}} pacotes ativos durante o período",
			columns: {
				name: "Nome do pacote",
				products: "Produtos",
				invitedPeople: "Convidados",
				confirmedPeople: "Confirmados",
				value: "Valor do pacote",
				revenue: "Faturamento total",
				issued: "Emissão de NF",
			},
			moreDetails: "Mais detalhes",
			total: "Total",
			totalCategory: "Total ({{category}})",
		},
		consumptionObligationReport: {
			title: "Consumação Mínima",
			filename: "consumaçao-minima",
			filenameObligation: "obrigacao-de-consumo",
			columns: {
				date: "Data",
				client: "Cliente",
				employee: "Dado por",
				value: "Valor",
				userProductsValueAtEvent: "Utilizado em produtos",
				paidValue: "Sobra de consumação",
				total: "Total",
			},
		},
		minimumConsumptionReport: {
			title: "Consumação antecipada",
		},
		openSalesReport: {
			title: "Relatório de vendas avulsas",
			searchPlaceholder: "Pesquisar por nome do funcionário",
			employeeSales: "Vendas avulsas de {{employeeName}}",
			columns: {
				employee: "Funcionário",
				total: "Total",
				date: "Data",
				isRefunded: "Foi estornada",
				refundedBy: "Estornada por",
				refundedAt: "Estornada em",
				value: "Valor",
				method: "Método",
				yes: "sim",
				no: "no",
			},
			seeAllSales: "Ver todas as vendas",
			totalSales: "Total de vendas avulsas",
		},
		rappiDiscountReport: {
			title: "Descontos do Rappi",
			sheet: {
				filename: "relatorio_de_descontos_do_rappi",
				title: "Descontos do Rappi",
				columns: {
					products: "Produtos",
					date: "Data",
					value: "Valor do desconto",
					employee: "Funcionário que aplicou o desconto",
				},
			},
		},
		zigAppReport: {
			title: "Vendas por transações do Zig.app",
			searchByClientName: "Busque por nome do cliente",
			paymentMethod: "Forma de pagamento",
			searchByIdOrCpf: "Busque por ID ou CPF",
			totemLabel: "Totem",
			enterOrSelectTotem: "Digite ou selecione o totem",
			balance: "Saldo",
			creditCard: "Cartão de crédito",
			refunded: "Estornado",
			creditCardPayment: "Pagamento cartão de crédito",
			paymentWithBalance: "Pagamento com saldo",
			averageTicket: "Ticket Médio",
			totalSales: "Total em vendas",
			totalClients: "Total de clientes",
			date: "Data",
			client: "Cliente",
			clients: "clientes",
			operator: "Operador (PDV)",
			details: "Detalhes",
			totalPeriod: "Total período",
			transactionDetail: "Detalhes da transação",
			produtcs: "Produtos",
			zigId: "ID da zig",
			transactionId: "ID da transação",
			copyTextSuccess: "Id copiado com sucesso!",
			total: "Total pago com Zig.app",
			service: "Serviço",
			close: "Fechar",
			justification: "Justificativa",
			refundTransaction: "Estornar transação",
			cancelTransaction: "Cancelar transação",
			optional: "Opcional",
			charactersLimit: "Até 150 caracteres",
			inputCancelationPlaceholder: "Escreva o motivo do estorno",
			cancelAlertMessage:
				"Ao cancelar, os produtos irão voltar para o estoque e a ação NÃO poderá ser desfeita. Deseja continuar mesmo assim?",
			serviceCharge: "Taxa de serviço",
			status: "Status",
			selectRefundProductsList: "Produtos:",
			shouldGoBackToStock: "Os produtos devem voltar para o estoque?",
			inputJustificationPlaceholder: "Escreva o motivo do estorno",
			refundAdlertMessage:
				"Ao estornar, os produtos NÃO VOLTARÃO para o estoque e a ação NÃO poderá ser desfeita. Deseja continuar mesmo assim?",
		},
		salesTotetemTransaction: {
			title: "Vendas por transações do Totem",
			subtitle:
				"Acompanhe as transações feitas pelos seus clientes usando um totem do evento.",
		},
		transactionTransferReport: {
			title: "Transferências de contas",
			fileName: "transferencias_de_contas",
			columns: {
				originCardId: "ID de origem",
				receiverCardId: "ID do cartão que recebeu",
				originDocument: "CPF de origem",
				receiverDocument: "CPF do cartão que recebeu",
				productValue: "Valor do produto",
				productDiscountValue: "Desconto",
				productName: "Nome do produto",
				employeeName: "Operador",
				date: "Horário",
			},
		},
	},
	getSubTitle: "De {{since}} até {{until}}",
};
