export const contentProductMountable = {
	labelStep: "Etapa",
	labelItems: "ITENS",
	labelTitleInfo: "INFORMAÇÕES",
	addNewItem: "Adicionar outro item",
	fields: {
		labelRequiredField: "Campo obrigatório",
		title: "Título",
		pricing: "Precificação",
		max: {
			title: "Máximo",
			errorMessage: {
				notNullable: "O valor máximo não pode ser igual à zero",
				cannotLessMinimum: "Valor máximo não pode ser menor que valor mínimo",
			},
		},
		min: {
			title: "Mínimo",
			errorMessage: {
				cannotGreaterMaximum: "Valor mínimo não pode ser maior que valor máximo",
			},
		},
		item: "Item",
		SelectableMaxQuantity: "Quantidade máx selecionável",
		AmountSlaughtered: {
			title: "Quantidade abatida",
			errorMessage: {
				notNullable: "Quantidade abatida deve ser maior que zero",
			},
		},
		price: "Valor",
	},
	modalToggleStatusItem: {
		activeItem: "Ativar item montável",
		deactiveItem: "Desativar item montável",
		warningMessage: {
			active: "Deseja ativar esse item nos demais produtos montáveis que ele compõe?",
			deactive:
				"Deseja desativar esse item nos demais produtos montáveis que ele compõe?",
		},
	},
	modalWarningMontable: {
		excludeStep: "Excluir etapa",
		excludeItem: "Excluir item",
		warningMessage: {
			exludeLastStep: "Não é possível excluir todas as etapas de um produto montável.",
			excludeLastItem: "Não é possível excluir todos os itens de um produto montável.",
		},
	},
};
