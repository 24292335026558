export const whatsapp = {
	section: {
		header: {
			model: "Modelo",
			goBack: "Voltar e selecionar outro modelo",
			editTitle: "Editar mensagem WhatsApp",
		},
		tags: {
			approvedModel: "Modelo aprovado",
			modelInValidation: "Modelo em validação",
			modelRefused: "Modelo não aprovado pelo Whatsapp",
			modelRecieved: "Modelo recebido pelo Whatsapp",
		},
		form: {
			title: "Defina os parâmetros da mensagem",
			checkboxLabel: "Utilizar nome do cliente a partir do banco",
			checkboxObservationLabel: "Utilizar campo observação a partir do banco",
			previewTitle: "Prévia da mensagem",
		},
		footer: {
			btnUnderstandWhy:
				"Entenda porque você só pode enviar mensagens de WhatsApp a partir de modelos",
			btnRequestNewModel: "Solicitar novo modelo",
		},
		understandWhyModal: {
			title:
				"Entenda porque você só pode enviar mensagens de WhatsApp a partir de modelos",
			text:
				"O Whatsapp e o Facebook possuem políticas claras de uso. Todos os conteúdos de comunicação que passam pela plataforma precisam ser revisados e aprovados para garantir que eles não violem as políticas do Whatsapp. Para garantir que as suas políticas não sejam violadas, você só poderá iniciar conversas utilizando esse modelo de mensagem. Sendo que o facebook tem o direito de revisar e recusar os modelos de mensagem a qualquer momento.",
			understandButton: "Entendi",
		},
		emptyTemplateState: {
			title: "Nenhum template cadastrado",
			text:
				"Solicite um novo template para enviar o mensagens de WhatsApp para seus clientes",
		},
	},
	newTemplatePage: {
		header: {
			title: "Solicitar novo modelo para",
			subtitle: {
				text:
					"A solicitação do novo modelo pode demorar alguns dias. Digite abaixo o que	deseja incluir no seu novo modelo.",
				link: "Lembre-se de sempre seguir as diretrizes de conteúdo do WhatsApp",
			},
		},
		removeFileButtonLabel: "Clique aqui para anexar uma imagem",
		messageErrorUpload: "O anexo deve ser do tipo imagem.",
		invalideVariableMessage: "Você deve utilizar uma das variáreis do glossário",
		modelTitle: "Título do modelo",
		modelTitleTooltipMessage: "Informe um título para seu template",
		modelTitlePlaceholder: "Digite o título",
		imageLabel: "Imagem",
		imageTooltipMessage: "Anexe uma imagem para enviar no seu template",
		textareaLabel: "Conteúdo da mensagem",
		textareaTooltipMessage: "Escreva o conteúdo do modelo que gostaria de criar",
		textareaPlaceholder:
			"Escreva o conteúdo do modelo aqui. Dica: para adicionar variáveis e poder alterá-las no futuro, siga o glossário ao lado.",
		glossaryVariables: {
			date: "data",
			client: "cliente",
			productName: "produto-nome",
			productOriginalPrice: "produto-preco-original",
			productPromotionalPrice: "produto-preco-promocional",
			discount: "promocao-desconto",
			observation: "observacao",
		},
		glossary: {
			title: "Glossário de variáveis",
			description:
				"Para poder reaproveitar esse modelo, digite o código abaixo entre colchetes como no exemplo: “[{{variable}}]”",
			date: {
				name: "Período",
				description: "Dia da semana ou data específica",
			},
			client: {
				name: "Nome do cliente",
				description: "Nome do cliente",
			},
			productName: {
				name: "Nome do produto específico",
				description: "Nome do produto",
			},
			discount: {
				name: "Porcentagem de desconto",
				description: "Valor percentual do desconto",
			},
			originalProductPrice: {
				name: "Preço do produto",
				description: "Preço original do produto específico",
			},
			productPromotionalPrice: {
				name: "Preço promocional do produto",
				description: "Preço promocional do produto específico",
			},
			observation: {
				name: "Observação",
				description: "Observação",
			},
		},
		warning: {
			responseDeadline: "Prazo de resposta",
			description:
				"O WhatsApp pode demorar até 3 dias para autorizar o novo modelo. Durante esse período você pode enviar outras mensagens a partir dos outros modelos da área anterior.",
		},
		footer: {
			title: "Prévia da mensagem",
			newModelButton: "Solicitar novo modelo",
		},
	},
	contentControlModal: {
		title: "Controle de conteúdo",
		paragraph1: {
			text:
				"O Whatsapp e o Facebook possui políticas claras de uso. Todos os conteúdos de comunicação que passam pela plataforma precisam ser revisados e aprovados para garantir que eles não violem as políticas do Whatsapp. A seguir, temos alguns usos proibidos retirados da própria documentação da",
			textLink:
				"Política do WhatsApp Business. Para ler a documentação completa, clique aqui",
		},
		paragraph2: {
			text:
				"Considerando o contexto em que a ZigPay atua, devemos deixar claras algumas das políticas contidas na documentação de política comercial do Whatsapp que provavelmente interfere no que você pode divulgar na plataforma. Listamos algumas abaixo, mas é muito importante que você leia a",
			textLink: "documentação completa de política comercial.",
		},
		alcoholicBeverages: "Bebidas alcoólicas",
		paragraph3: {
			text: "as empresas não podem vender ou promover a venda de bebidas alcoólicas",
			politicsBold: "Política:",
			exampleBold: "Exemplos:",
		},
		paragraph4: {
			text:
				"divulgações sobre livros ou DVDs sobre bebidas alcoólicas. Ou Itens relacionados a bebidas alcoolicas como taças, refrigerantes e suportes para garrafas de vinho.",
			areAllowedBold: "São permitidas",
		},
		paragraph5: {
			text:
				"divulgações sobre bebidas alcoólicas ou kits para produção de bebidas alcoólicas.",
			areNotAllowedBold: "Não são permitidas",
		},
		confirmation: {
			paragraph:
				"O Facebook possui políticas sobre o tipo de conteúdo divulgado pelo WhatsApp. Você confirma que leu as ",
			underlined:
				"políticas e está ciente do que pode e não pode ser divulgado pela plataforma?",
			label:
				"Confirmo que li as políticas do WhatsApp Business e estou ciente do que pode ser divulgado.",
		},
	},
	actionButtons: {
		title: "Botões - Opcional",
		subTitle: "São botões que aparecem como opções clicáveis logo após a sua mensagem",
		addbutton: "Adicionar outro botão",
		noneButton: "Nenhum",
		actionButton: "Ações",
		quickResponseButton: "Resposta rápida",
		quickResponseAction: {
			title: "Resposta rápida {{numbering}}",
			placeholderQuickResponse: "Digite aqui a resposta rápida",
		},
		optionsAction: {
			label: {
				typeAction: "Tipo de ação",
				makeCall: "Fazer ligação",
				accessWebsite: "Acessar site",
				phoneNumber: "Número de telefone",
				buttonText: "Texto do botão",
				country: "País",
				websiteLink: "Link do site",
			},
			placeholder: {
				callNow: "Ligar agora",
				enterNumber: "Insira o número aqui",
				accessWebsite: "Acesse nosso site",
				insertLink: "Insira o link aqui",
			},
		},
		validationFields: {
			requiredField: "Campo obrigatório {{field}}",
		},

		formatValidation: {
			phoneNumber: "Número de telefone inválido",
			url: "Link inválido",
		},
	},
};
