import * as React from "react";
// import Tippy, { TippyProps } from "@tippyjs/react";
// import "tippy.js/dist/tippy.css";
import { Tooltip as AntdTooltip } from "antd";
import { TooltipProps as AntdTooltipProps } from "antd/lib/tooltip";

export interface ITooltipProps extends Omit<AntdTooltipProps, "children"> {
	/**
	 * @deprecated utilizar content
	 */
	message?: React.ReactNode;
	content?: React.ReactNode;
	children: React.ReactElement | string;
}

export function Tooltip({
	message,
	content,
	children,
	placement = "top",
	...props
}: ITooltipProps) {
	return (
		<AntdTooltip title={message ?? content} placement={placement} {...props}>
			{typeof children === "string" ? <span>{children}</span> : children}
		</AntdTooltip>
	);
}

// export function Tooltip({ message, children, ...props }: ITooltipProps) {
// 	return (
// 		<Tippy content={message} placement="auto" {...props}>
// 			{typeof children === "string" ? <span>{children}</span> : children}
// 		</Tippy>
// 	);
// }
