import { homeInventoryControl } from "./home-inventory-control";
import { cardInventoryControl } from "./card-inventory-control";
import { stepperInventoryControl } from "./stepper-inventory-control";
import { stepsInventoryControl } from "./steps-inventory-control";
import { wrapperInventoryControl } from "./wrapper-inventory-control";
import { updateStatusMessage } from "./update-status-message";

export const inventoryControl = {
	title: "Inventário",
	emptyState: {
		title: "Você ainda não possui inventários",
		infoEmpty: "Clique em adicionar criar inventário para começar",
		addNewInventory: "Novo inventário",
	},
	homeInventoryControl,
	cardInventoryControl,
	stepperInventoryControl,
	stepsInventoryControl,
	wrapperInventoryControl,
	updateStatusMessage,
};
