import TagManager from "react-gtm-module";
import { useStores } from "#stores";

type GTMContextLevel = "event" | "place" | "org";

interface GAInternalData {
	place_name?: string;
	event_name?: string;
	org_name?: string;
	org_id?: string;
	role?: string;
	user_id?: string;
	context_level?: GTMContextLevel;
}

type GTMEventType = "page_view" | "select_content" | "view_content";

export type GTMEventSelectContentData = {
	label: string;
};

export type SendGTMEventParams = {
	event: GTMEventType;
	event_track: string;
	extra_data_json?: object | null;
};

export interface UseGTMEventProps {
	sendGTMEvent: (params: SendGTMEventParams) => void;
	sendSelectContent: (
		params: Omit<SendGTMEventParams, "event"> & GTMEventSelectContentData,
	) => void;
}

const sanitizeObject = (data: any) => {
	const objectKeys = Object.keys(data);

	for (let i = 0; i < objectKeys.length; i++) {
		const key = objectKeys[i];

		if (!data[key]) {
			delete data[key];
		}
	}

	return data;
};

export const useGTMEvent = (): UseGTMEventProps => {
	const { placeStore, eventStore, organizationStore, authStore } = useStores();

	const pushEvent = (eventName: string, eventData: any) => {
		TagManager.dataLayer({
			dataLayer: {
				event: eventName,
				...sanitizeObject(eventData),
			},
		});
	};

	const getContext = (): GTMContextLevel | undefined => {
		if (!location) {
			return;
		}

		const hasPlacePath = location?.pathname?.startsWith?.("/place/");
		const hasEventPath = location?.pathname?.includes?.("/event/");

		if (hasEventPath) return "event";

		if (hasPlacePath) return "place";

		return "org";
	};

	const sendGTMEvent = ({
		event,
		event_track,
		extra_data_json,
		...rest
	}: SendGTMEventParams) => {
		const place_name = placeStore.place?.name;
		const event_name = eventStore.event?.name;
		const org_name = organizationStore.organization?.name;
		const org_id = organizationStore.organization?.id;
		const user_id = authStore.currentEmployee?.id;
		const role = authStore.currentEmployee?.role?.slug;
		const context_level = getContext();

		const commonData: GAInternalData = {
			place_name,
			event_name,
			org_name,
			org_id,
			user_id,
			role,
			context_level,
		};

		pushEvent(event, {
			...commonData,
			event_track,
			extra_data_json: extra_data_json ? JSON.stringify(extra_data_json) : null,
			...rest,
		});
	};

	const sendSelectContent = (
		params: Omit<SendGTMEventParams, "event"> & GTMEventSelectContentData,
	) => {
		sendGTMEvent({ ...params, event: "select_content" });
	};

	return { sendGTMEvent, sendSelectContent };
};
