import classNames from "classnames";
import { Checkbox } from "@mds/react-components";
import React from "react";

import {
	ISimpleTableBody,
	ISimpleTableEmptyState,
	ISimpleTableFooter,
	ISimpleTableLabelDetail,
	ISimpleTableRoot,
} from "./types";
import { SimpleTableProvider, useSimpleTable } from "./context";

import s from "./simples-table.scss";
import Skeleton from "#components/skeleton";
import { Tooltip } from "../tooltip";

export function SimpleTableHead() {
	const {
		columns,
		dataSource,
		hasCheckbox,
		toggleAllCheckbox,
		selectedItems,
	} = useSimpleTable();

	return (
		<thead>
			<tr>
				{hasCheckbox && (
					<th style={{ width: "10%" }}>
						<Checkbox
							checked={dataSource.length === selectedItems.length}
							onClick={() => toggleAllCheckbox(dataSource)}
							id="all"
							children=""
						/>
					</th>
				)}
				{columns.map(({ dataKey, ...column }) => (
					<th {...column} key={String(dataKey)}>
						{column.name}
					</th>
				))}
			</tr>
		</thead>
	);
}

export function SimpleTableLoader() {
	const { columns } = useSimpleTable();

	const skeletonArray = Array.from(Array(5).keys());

	return (
		<tbody>
			{skeletonArray.map((_, index) => (
				<tr key={index} className={s.skeletonLine}>
					{columns.map((_, colIndex) => (
						<td key={colIndex}>
							<Skeleton height={15} />
						</td>
					))}
				</tr>
			))}
		</tbody>
	);
}

export function SimpleTableBody({ children }: ISimpleTableBody) {
	const {
		columns,
		dataSource,
		hasCheckbox,
		selectedItems,
		toggleCheckbox,
		isLoading,
		totalizer,
	} = useSimpleTable();

	if (isLoading) {
		return <SimpleTableLoader />;
	}

	return (
		<tbody>
			{dataSource.map((data, index) => (
				<>
					{totalizer && index === 0 && (
						<tr className={s.totalizer}>
							{columns.map(column => (
								<td key={String(column.dataKey)} {...column}>
									{totalizer[column.dataKey]}
								</td>
							))}
						</tr>
					)}

					<tr key={index}>
						{hasCheckbox && (
							<td>
								<Checkbox
									id={data}
									checked={selectedItems.includes(data)}
									onChange={() => toggleCheckbox(data)}
									children=""
								/>
							</td>
						)}

						{columns.map(({ dataKey, ...column }, colIndex) => {
							const content = data[dataKey];

							return (
								<td {...column} key={colIndex}>
									{column.useTooltip ? (
										<Tooltip content={content}>
											<div className={s.tooltipContent}>{content}</div>
										</Tooltip>
									) : (
										content
									)}
								</td>
							);
						})}
					</tr>
				</>
			))}
			{children}
		</tbody>
	);
}

export function SimpleTableEmptyState({
	title,
	description,
	className,
	children,
	...props
}: ISimpleTableEmptyState) {
	const { columnsCount, dataSource, isLoading } = useSimpleTable();

	if (dataSource.length || isLoading) {
		return null;
	}

	return (
		<tr>
			<td colSpan={columnsCount}>
				<div {...props} className={classNames(s.emptyState, className)}>
					<h3>{title}</h3>
					<p>{description}</p>
					{children}
				</div>
			</td>
		</tr>
	);
}

export function SimpleTableFooter({ children }: ISimpleTableFooter) {
	const { columnsCount } = useSimpleTable();

	return (
		<tfoot>
			<tr className={classNames({ [s.footerSpacing]: !Boolean(children) })}>
				<td colSpan={columnsCount}>
					<div className={s.footer}>{children}</div>
				</td>
			</tr>
		</tfoot>
	);
}

export function SimpleTableRoot<T>({
	children,
	columns,
	dataSource,
	onCheckItems,
	isLoading = false,
	totalizer,
}: ISimpleTableRoot<T>) {
	return (
		<SimpleTableProvider
			columns={columns}
			dataSource={dataSource}
			onCheckItems={onCheckItems}
			isLoading={isLoading}
			totalizer={totalizer}
		>
			<table className={s.table}>{children}</table>
		</SimpleTableProvider>
	);
}

export function SimpleTableLabelDetail({ title, description }: ISimpleTableLabelDetail) {
	return (
		<>
			<h2 className={s.title}>{title}</h2>
			<span className={s.description}>{description}</span>
		</>
	);
}
