import ErrorIcon from "#assets/svg/error-icon.svg";
import * as React from "react";
import s from "./form-status.scss";
import SuccessIcon from "#assets/svg/success-icon.svg";

import { Button, IButtonProps } from "..";
import { useTranslation } from "react-i18next";

export type FormStatusStatuses = "error" | "success";

export interface IFormStatusProps {
	description?: string;
	onCancel?: () => void;
	onCancelButtonProps?: IButtonProps;
	onCancelText?: string;
	onOk?: () => void;
	onOkButtonProps?: IButtonProps;
	onOkText?: string;
	status: FormStatusStatuses;
	title?: string;
}

export function FormStatus({
	description,
	onCancel,
	onCancelButtonProps,
	onCancelText,
	onOk,
	onOkButtonProps,
	onOkText,
	status,
	title,
}: IFormStatusProps) {
	const { t } = useTranslation("components", { keyPrefix: "formStatus" });
	const { t: tGlobalKeys } = useTranslation("globalKeys");
	return (
		<div className={s.container}>
			{status === "error" ? (
				<>
					<ErrorIcon />
					<div>
						<h6 className={s.title}>{title ?? t("error.title")}</h6>
						<p className={s.description}>{description ?? t("error.description")}</p>
					</div>
				</>
			) : (
				<>
					<SuccessIcon />
					<div>
						<h6 className={s.title}>{title ?? t("success")}</h6>
						{description && <p className={s.description}>{description}</p>}
					</div>
				</>
			)}
			<div className={s.footer}>
				{onCancel && (
					<Button variant="ghost" onClick={onCancel} {...onCancelButtonProps}>
						{onCancelText ?? tGlobalKeys("cancel")}
					</Button>
				)}
				{onOk && (
					<Button variant="primary" onClick={onOk} {...onOkButtonProps}>
						{onOkText ?? tGlobalKeys("conclude")}
					</Button>
				)}
			</div>
		</div>
	);
}
