import { EVENT_KEYS } from "../event";

export const ORG_KEYS = {
	getPlaces: ["getPlaces"],
	getActiveEventsBySimplifiedPlace: (placeId: string, monthYear: string) => [
		...EVENT_KEYS.getEvents,
		placeId,
		monthYear,
	],
} as const;
