export const tabTaxProfiles = {
	addNewTaxProfile: "Adicionar novo perfil",
	emptyTaxProfilesMessage: "Não existem perfis fiscais a serem mostrados",
	infosTaxProfile: {
		cnpj: "CNPJ",
		corporateName: "Razão Social",
		fantasyName: "Nome Fantasia",
		stateRegistration: "Inscrição Estadual",
		taxRegime: "Regime Tributário",
		phone: "Telefone",
		email: "E-mail",
	},
	optionsTaxation: {
		normalRegime: "Regime normal",
		simpleNational: "Simples nacional",
		simpleNationalExcess: "Simples nacional excesso",
	},
	searchPlaceholder: "Pesquisar perfil fiscal",
};
