export const wrapperInventoryControl = {
	titleWrapper: "Create new inventory",
	titleButtons: {
		sendInventory: "Send inventory",
		approveInventory: "Approve inventory",
		retellInventory: "retell",
		downloadInExcel: "Download in excel",
	},
	placeholders: {
		keywordSearch: "Search by category, code or product",
	},
	headerInfos: {
		name: "Name",
		inventoryTitle: "Inventory title",
		createAt: "Creation date",
		lastEdtion: "Last edition",
		detailsInventory: "Inventory details",
		stockist: "Stockist",
		totalProducts: "Total products",
		categoriesSelected: "Selected categories",
		storageSelected: "Storage selected",
		storageAndCategoriesSelected: "Storage and categories",
		status: "Status",
	},
	seeMoreDetails: "See more details",
	drawerInfos: {
		seeDetails: "See details",
	},
};
