import enterprise from "#resources/api/enterprise-client";
import {
	showErrorNotification,
	showSuccessNotification,
} from "#resources/helpers/notifications";
import { SdkgenError } from "@sdkgen/browser-runtime";
import { useMutation } from "@tanstack/react-query";
import i18n from "#i18n/index";
import { EnterpriseApiSignature } from "#resources/helpers/api";

const t = i18n.t;

type CreateEventSignature = EnterpriseApiSignature<"createEvent">;

export const useCreateEvent = () => {
	return useMutation<
		CreateEventSignature["result"],
		SdkgenError,
		CreateEventSignature["args"]
	>({
		mutationFn: args => enterprise.createEvent(args),
		onSuccess() {
			showSuccessNotification(t("store:eventStore.createEvent"));
		},
		onError(error) {
			showErrorNotification(error.message);
		},
	});
};
