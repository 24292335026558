export const precharge = {
	title: "Precharge operations",
	searchPlaceholder: "Search by precharge operation name",
	createNewPrecharge: "Create precharge operation",
	noSearchResults: "The search found no results",
	empty: "There are no precharge operations registered",
	card: {
		noTermsOfService: "No terms of service",
		noEnd: "No end",
	},
	addNewPrecharge: {
		prechargeOperation: "Precharge operations",
		siteConfig: "Site settings",
		eventName: "Event name",
		termsOfService: "Terms of Service",
		ticketUrl: "Ticket url",
		slug: "Slug",
		aliases: "Aliases",
		siteAppearance: "Site appearance",
		cover: "Cover",
		logo: "Logo",
		mainImage: "Main image",
		primaryColor: "Primary color",
		darkMode: "Dark Mode",
		operationConfig: "Operation settings",
		beginAt: "Starts at",
		endsAt: "Ends in",
		childFriendly: "Suitable for children",
		preSetedValues: "Pre-selectable values",
		bonus: "Bonus",
		minimumValue: "Minimum value",
		rechargeConfig: "Recharge settings",
		expirationDate: "Expiration date of recharges",
		validRechargDate: "Refills are valid from",
		rechargeDuration: "Recharge duration",
	},
	sideTab: {
		edit: "Edit precharge operation",
		withoutTerms: "No terms of service",
		preChargeOperation: "Precharge operation",
		slug: "SLUG",
		beginAt: "Starts at",
		endsAt: "Ends in",
		expiresAt: "Expiration date of recharges",
		undefined: "Undefined",
		validRecharges: "Recharges are valid from",
		darkMode: "Dark Mode",
		childFriendly: "Suitable for children",
		primaryColor: "Primary color",
		durability: "Recharge duration",
		remove: {
			title: "Are you sure you want to delete this precharge operation?",
			button: "Delete Precharge Operation",
		},
	},
	prechargeStore: {
		createPrechargeOperationSuccess: "Precharge operation created successfully",
		updatePrechargeOperationSuccess: "Precharge operation updated successfully",
	},
	homePage: {
		deleteTitle: "Delete Precharge site",
		deleteSubtitle: "Are you sure you want to delete this Precharge site?",
		title: "Precharge",
		description:
			"Create a site so that customers can precharge before the event starts, avoiding queues and consequently reducing the volume of operations at the event.",
		createButton: "Create Precharge site",
		searchInputPlaceholder: "Search for a site",
		willExpire: "Expires in",
		hasExpired: "Expired in",
		copyLink: "Copy link to site",
		active: "Active",
		inactive: "Inactive",
		expired: "Expired",
		copied: "Link copied.",
		report: "Report",
	},
	formPage: {
		title: "New precharge site",
		description:
			"Fill in the data and promote the website to your customers. Allow users to pre-charge before the event starts, avoiding queues and reducing the volume of operations at the event.",
		nextStep: "Next step",
		discard: "Discard creation",
		logoDescription: "The image must be in .jpeg or .png, with 880px by 880px",
		siteSettingsTitle: "Site Settings",
		rechargeSettingsTitle: "Precharge Settings",
		tipsTitle: "Event Tips",
		eventData: {
			title: "Event information",
			logo: "Event logo",
			eventName: "Event name",
			eventNamePlaceholder: "Enter event name",
			email: "Contact email",
		},
		customization: {
			title: "Website customization",
			slug: "Website URL",
			slugPlaceholder: "Type event name",
			color: "Color of the details",
			rechargesPeriod: "Allow precharges within period of",
			endsAt: "Take the site down on",
			buffer: "Page featured photo",
			mainPageInfoTitle: "Homepage title",
			mainPageInfoTitleDescription:
				'Suggestion: Precharges? "{{name}}" has! Plan ahead and avoid queues',
			mainPageInfoTitlePlaceholder: "Type here the title you want to place on the page",
			mainPageInfoSubtitle: "Supplementary text to the title",
			colorPlaceholder: "Select color",
		},
		rechargeSettings: {
			title: "Precharge settings",
			rechargesLimit: "Precharge limit rules",
			rechargesLimitDescription:
				"Set the minimum value per precharge and the minimum value per customer (sum of all precharges advanced during the operation period).",
			minimumValue: "Minimum precharge value",
			maximumValuePerUser: "Maximum precharge value per limit",
			suggestion: "Value suggestion precharge",
			suggestionDescription:
				"On the pre-charge website, the customer can select one of the values ​​already suggested or enter the value they want, respecting the minimum and maximum limits established above. Suggest 4 values ​​to be displayed on the website. If you wish, you can also gift your customers to earn bonuses based on the suggested amounts.",
			preSetedValue: "Suggested amount",
			bonus: "Free bonus",
			bonusDescription:
				"If you wish, you can also gift your customers to earn bonuses based on the suggested amounts.",
			paymentMethods: "Payment methods",
			paymentMethodsDescription:
				"Choose the payment methods to be accepted. At least one of them must be selected.",
			bonusTable: {
				rechargeValue: "Recharge Amount",
				bonus: "Bonus",
			},
			addValue: "Add suggested amount to the website",
			suggestedValue: "Suggested amounts added",
			removeValue: "Click on the X to remove",
			noBonus: "No bonus",
			minimumValueDescription: "The minimum amount your customer can load is",
			maximumValueDescription: "The sum of all the amounts of Pre-charge cannot exceed",
			cardTitle: "Pre-charge Suggestion",
			cardBonus: "Free Bonus",
			addButton: "Add Suggested Value",
			drawerTitle: "Add Suggestion",
			drawerOkButton: "Save Suggestion",
			radioYes: "Yes. I want to offer bonuses to my customers",
			radioNo: "No. I do not want to offer bonuses to my customers",
			bonusExample:
				"By loading <span>{{preSetedValue}}</span> your customer will get <span>{{bonus}}</span> as a free bonus.",
		},
		tips: {
			title: "Event Tips",
			selectTips: "Website Tips Section",
			yesOpt: "Yes. Add tips section on website.",
			noOpt: "No. Do not add tips section on site.",
			mainQuestion:
				"Would you like to insert a section with tips for customers to have the best possible experience at your event?",
			importantInfoQuestion:
				"Define important information about your event below, assigning them a title and text that complements it.",
			tipTitle: "Title of the tip",
			tipTitlePlaceholder: "Ex.: Can I carry water in my backpack?",
			tipDescription: "Informative text of the tip",
			tipDescriptionPlaceholder: "Ex.: You can carry water in transparent bottles",
			addMoreTip: "Add more tips",
			deleteTip: "Delete tip",
		},
		validations: {
			required: "Required field",
			requiredOption: "Select an option",
			invalidSlugRules: "one or more rules were not met",
			maxValuPerUserLowerThanMinValue:
				"Maximum value per user must be greater than minimum value",
			presetedValueLowerThanMinValue:
				"Precharge value must be greater than minimum value",
			presetedValueHigherThanMaxValuePerUser:
				"Precharge value must be less than maximum value per user",
			presetedValueAlreadyExists: "Precharge value already exists",
			isTipsEnabled: "Please add a tip",
		},
	},
	report: {
		precharge: "Precharge",
		edit: "Edit site",
		showSite: "View site",
		costumersTitle: "{{value}} people",
		costumersDescription: "Precharged",
		ticketsDescription: "Average ticket",
		collectedDescription: "Amount collected",
		redeemedDescription: "Amount redeemed",
		maleTitle: "{{value}} men",
		femaleTitle: "{{value}} women",
		otherTitle: "{{value}} others",
		paymentMethod: "Payment method",
		value: "Value",
		prechargePerDay: "Precharges per day",
		date: "Date",
		dateHour: "Date/Time",
		name: "Name",
		document: "Document",
		email: "Email",
		bonus: "Bonus",
		cardSymmary: "Card used",
		cardBrand: "Card Brand",
		filter: {
			name: "Customer name",
			document: "Document",
			email: "Email",
			acquirerTransactionId: "Acquirer transaction",
			paymentMethod: "Payment method",
			status: "Status",
		},
		creditCard: "Credit Card",
		pix: "PIX",
		billing: "Billing",
		status: {
			pendingReview: "Pending",
			refunded: "Refunded",
			refused: "Refused",
			success: "Approved",
		},
		errors: {
			hasWhitespace: "Cannot contain spaces",
			hasUpperCase: "Cannot contain capital letters",
			hasSpecialCharacter: "Cannot contain special characters",
		},
	},
};
