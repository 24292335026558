export const productCard = {
	infoProduct: `Skipped "{{nameProduct}}" product`,
	restoreproduct: "Click here to restore",
	code: "SKU:",
	quantities: "Qty:",
	unit: "Unit:",
	unitPrice: "Paid by unit:",
	unitPriceTooltip: "Value per unit in invoice",
	equivalentProduct: "Equivalent product",
	productNotFound: "Product not found",
	wishToCreate: "Do you wish to create it?",
	equivalentUnit: "1 {{product}} equals",
	costPricePerUnit: "Unit price",
	costPricePerUnitTooltip: "Value per unit in the Zig system",
	costTypeOptions: {
		total: "in total",
		unit: "per unit",
		milliliter: "per milliliter",
		gram: "per gram",
		kilogram: "per kilogram",
		liter: "per litre",
	},
};
