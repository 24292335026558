export const beerDispenser = {
	title: "Draft Beer",
	searchPlaceholder: "Search",
	newBeerDispenser: "New draft beer",
	emptyText: "No beer found.",
	product: "Product",
	ip: "IP",
	number: "Beer tap number",
	error: {
		requiredField: "Required field",
		invalidIp: "Invalid IP format",
	},
	modal: {
		addBeerDispenser: "Add draft beer",
		editBeerDispenser: "Edit draft beer",
		name: "Name",
		product: "Product",
		productTooltip: "Only products in milliliters/liters",
		number: "Number",
		dispenserIp: "Draft beer IP",
		deleteDispenser: "Delete draft beer",
		deleteText: "Are you sure you want to delete the draft beer <b>{{chopName}}</b>?",
	},
};
