import React from "react";
import classNames from "classnames";

import s from "./running-events-badge.module.scss";

type RunningEventsBadgeProps = {
	count: number;
	hideLabel?: boolean;
	hideCounter?: boolean;
	className?: string;
};

export const RunningEventsBadge = ({
	count,
	className,
	hideCounter,
	hideLabel,
}: RunningEventsBadgeProps) => {
	return (
		<div
			className={classNames(
				s.runningEvents,
				{
					[s.onlyPulsingDot]: hideCounter && hideLabel,
				},
				className,
			)}
		>
			<div className={s.pulsingDot} />

			{!hideCounter && <small>{String(count).padStart(2, "0")}</small>}

			{!hideLabel && <small>Abertos</small>}
		</div>
	);
};
