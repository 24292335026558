import React from "react";
import { Cell, Grid } from "#components/grid-structure";
import { Select } from "#components/primitive";

import { SelectTransactionSource } from "#components/select-transaction-source";
import { TransactionSource } from "#resources/api/enterprise-generated";
import { TableProductsSold } from "../table-products-sold";
import { useSalesReport } from "../context";
import { GroupBy } from "../types";
import { LoadingOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";

import s from "./table-total-sales.module.scss";

export const TableTotalSales = () => {
	const { t } = useTranslation("place", {
		keyPrefix: "barReports.salesReport.totalSales",
	});
	const {
		transactionsFilter,
		setTransactionsFilter,
		productsFilter,
		setProductsFilter,
		setBarsFilter,
		groupBy,
		setGroupBy,
		dataSourceProductsByBarAtPlace,
		isLoadingProductsSoldByBar,
		productsOptions,
		barOptions,
	} = useSalesReport();

	return (
		<div className={s.container}>
			<div className={s.filters}>
				<Grid className={s.grid} areas={[["group", "transaction", "product", "bar"]]}>
					<Cell area="group">
						<Select<GroupBy>
							size="large"
							defaultValue={groupBy}
							placeholder={t("groupPlaceholder")}
							onChange={val => setGroupBy(val)}
						>
							<Select.Option value="bar">{t("groupByBar")}</Select.Option>
							<Select.Option value="category">{t("groupByCategory")}</Select.Option>
							<Select.Option value="no-group">{t("noGroup")}</Select.Option>
						</Select>
					</Cell>

					<Cell area="transaction">
						<SelectTransactionSource
							defaultValue={transactionsFilter}
							onChange={(values: TransactionSource[]) => setTransactionsFilter(values)}
							placeholder={t("transactionPlaceholder")}
							maxTagCount="responsive"
						/>
					</Cell>

					<Cell area="product">
						<Select<string[]>
							defaultValue={productsFilter}
							size="large"
							placeholder={t("productPlaceholder")}
							onChange={values => setProductsFilter(values)}
							mode="multiple"
							optionFilterProp="children"
							maxTagCount="responsive"
							allowClear
						>
							{productsOptions.map(({ label, value }) => (
								<Select.Option key={value} value={value}>
									{label}
								</Select.Option>
							))}
						</Select>
					</Cell>

					<Cell area="bar">
						<Select<string[]>
							defaultValue={[]}
							size="large"
							placeholder={t("barPlaceholder")}
							onChange={values => setBarsFilter(values)}
							mode="multiple"
							optionFilterProp="children"
							maxTagCount="responsive"
							allowClear
						>
							{barOptions.map(({ label, value }) => (
								<Select.Option key={value} value={value}>
									{label}
								</Select.Option>
							))}
						</Select>
					</Cell>
				</Grid>
			</div>

			{groupBy === "bar" ? (
				<div className={s.barTables}>
					{isLoadingProductsSoldByBar && <LoadingOutlined />}

					{dataSourceProductsByBarAtPlace.map(bar => {
						return (
							<div className={s.tableBarWrapper} key={bar.barId}>
								<strong>{bar.barName}</strong>

								<TableProductsSold
									dataSource={bar.products}
									barName={bar.barName ?? ""}
								/>
							</div>
						);
					})}
				</div>
			) : (
				<strong>Em Construção</strong>
			)}
		</div>
	);
};
