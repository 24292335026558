type AsyncFunction<T> = () => Promise<T>;

export async function parallelLimit<T>(tasks: Array<AsyncFunction<T>>, limit: number) {
	const taskQueue = [...tasks];
	const results: T[] = [];

	const runners = Array.from(new Array(limit)).map(async () => {
		while (taskQueue.length > 0) {
			results.push(await taskQueue.shift()!());
		}
	});

	await Promise.all(runners);

	return results;
}
