export const contentProductMountable = {
	labelStep: "Step",
	labelItems: "ITENS",
	labelTitleInfo: "INFORMATIONS",
	addNewItem: "Add another item",
	fields: {
		labelRequiredField: "Required field",
		title: "Title",
		pricing: "Pricing",
		max: {
			title: "Maximum",
			errorMessage: {
				notNullable: "The maximum value cannot be zero",
				cannotLessMinimum: "Maximum value cannot be less than minimum value",
			},
		},
		min: {
			title: "Minimum",
			errorMessage: {
				cannotGreaterMaximum: "Minimum value cannot be greater than maximum value",
			},
		},
		item: "Item",
		SelectableMaxQuantity: "Selectable max quantity",
		AmountSlaughtered: {
			title: "Amount slaughtered",
			errorMessage: {
				notNullable: "Amount slaughtered must be greater than zero",
			},
		},
		price: "Value",
	},
	modalToggleStatusItem: {
		activeItem: "Activate mountable item",
		deactiveItem: "Deactivate mountable item",
		warningMessage: {
			active:
				"Do you want to activate this item in other mountable products it belongs to?",
			deactive:
				"Do you want to deactivate this item in other mountable products it belongs to?",
		},
	},
	modalWarningMontable: {
		excludeStep: "Exclude step",
		excludeItem: "Exclude item",
		warningMessage: {
			exludeLastStep: "It is not possible to exclude all steps from a mountable product.",
			excludeLastItem:
				"It is not possible to exclude all items from a mountable product.",
		},
	},
};
