export const withdrawRequestDrawer = {
	title: "Request a withdrawal or payment of a ticket",
	titleWithdraw: "Request withdrawal",
	titleBankslip: "Payment of ticket",
	titlePendingBankslip: "Zig bankslip payment",
	pendingBankslip: "Pending bankslip",
	warning:
		"If the requested amount exceeds the balance available for withdrawal, it will be informed with the amount available for advance payment, which will be subject to the advance fee.",
	withdrawOption: "Withdraw",
	bankslipPaymentOption: "Bankslip Payment",

	bankSlid: {
		bankSlipValue: "Bank slip value",
		bankSlipInfo: "Bank slip information",
		dataReview: "Data review",
		form: {
			liquidValue: "Net value",
			liquidValuePlaceholder: "Enter the net amount here",
			beneficiary: "Beneficiary",
			barCode: "Barcode number",
			dueDate: "Due date",
			attachment: "Select bank slip file",
			valuesReview: "Review of values",
			bankSlipValue: "Bank slip value",
			available: "Balance available for withdrawal",
			valueToBeAnticipated: "Net amount to be anticipated",
			anticipationTax: "Anticipation fee",
			anticipationValue: "Total amount to be anticipated",
			liquidAmmountRequested: "Net amount requested",
			thidPartyWithdrawFee: "Third party withdraw fee",
			totalValue: "Total value",
			bankSlipInfo: "Bank sllip information",
			calculating: "Calculating...",
		},
		transactionDetails: {
			title: "Transaction detail",
			paymentValue: "Payment amount",
			available: "Withdrawal value",
			valueToBeAnticipated: "Net amount of anticipation",
			taxValue: "Anticipation fee",
			totalValue: "Total value",
			calculating: "Calculating...",
		},
	},
	withdraw: {
		withdrawValue: "Withdrawal Value",
		accountInfo: "Account information",
		dataReview: "Data review",
		warning:
			"Requests made after 10:30 am (Brasília time) will only be dealt with the following day.",
		warningAccountChoice:
			"A fee of {{value}} will be charged for withdrawals to third-party accounts.",
		errorMessage: "The request could not be completed.",
		successMessage: "Withdrawal Requested Successfully!",
		liquidValue: "Net value",
		liquidValuePlaceholder: "Enter the net amount here",
		reason: "Reason for request",
		reasonPlaceholder: "Enter the reason for the withdrawal here",
		account: "Account",
		accountPlaceholder: "Enter or select account",
	},
	titleTransfer: "Transfer balance between places",
	transferOption: "Transfer between places",
	bankslipValue: "Bankslip value",
	expireDate: "Expiration date",
	downloadBankslipPdf: "Download bankslip in PDF",
};
