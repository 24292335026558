export const kardex = {
	title: "Ficha Kardex",
	label: "Estoque",
	storage: "Estoque",
	product: "Produto",
	seeProduct: "Ver produto",
	period: "Período",
	applyFilters: "Aplicar filtros",
	at: "até",
	destiny: "Destino",
	origin: "Origem",
	dateFilterBy: {
		label: "Filtrar período por",
		options: {
			events: "Eventos entre o período",
			interval: "Todos entre o período",
		},
	},
	transferTypeTranslate: {
		input: "Entrada",
		transferOut: "Transferência (saída)",
		transferIn: "Transferência (entrada)",
		lost: "Perda",
		sell: "Venda",
		refund: "Perda vinda de estorno",
		manual: "Ajuste Manual",
		productionIn: "Entrada de produção",
		productionOut: "Saída de produção",
		invoice: "Entrada nota fiscal",
		inventory: "Inventário",
		productionLost: "Perda de produção",
		cardExchange: "Troca por cartão",
	},
	header: {
		date: "Data",
		employeeName: "Funcionário",
		obs: "Observação",
		initial: "Saldo inicial",
		noValue: "Valor não informado",
		type: "Transação",
		count: "Quantidade",
		final: "Saldo final",
		unitCostValue: "Custo unit.",
		selectStock: "Selecione o estoque",
		selectProduct: "Selecione o produto",
		applyFilters: "Aplicar filtros",
		description:
			"A ficha kardex é uma representação do estoque e movimentações de um item. É semelhante à tela de movimentações, apresentando o histórico de entradas e saídas, a quantidade movimentada, o saldo e valores a cada movimentação.",
	},
	nameNotFound: "Nome não encontrado",
	download: "Exportar para Excel",
	emptyState: {
		title: "Selecione estoque para começar",
		description:
			"Selecione o estoque, produto e o período para visualizar a Ficha Kardex do produto",
	},
};
