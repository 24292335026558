export const fiscalGroupForm = {
	navigateBackGroupLabel: "Back to tax groups",
	navigateBackCouvertLabel: "Back to tax couvert",
	titleSteps: {
		vat: "Value Added Tax",
		service: "Service",
		general: "General data",
	},
	steps: {
		general: {
			fields: {
				name: {
					label: "Name",
					placeholder: "Group name",
				},
				type: {
					label: "Type",
				},
			},
			types: {
				invoice: "Invoice",
				vat: "Value Added Tax (VAT)",
				vatFactura: "Value Added Tax (VAT) - Mexico",
				service: "Service",
			},
		},
		tip: {
			fiscalGroup: "Fiscal Group",
			placeholder: "Select fiscal group",
			cestPlaceholder: "Select the CEST",
		},
		vat: {
			fields: {
				taxRate: {
					label: "Tax Rate (%)",
				},
				code: {
					label: "SKU",
					placeholder: "Select SKU",
				},
				productType: {
					label: "Product Type",
					placeholder: "Select Product Type",
				},
				taxExemption: {
					label: "Tax Exemption",
					placeholder: "Select Tax Exemption",
				},
			},
			vatVendusTaxExemptionOptions: {
				M01: "Article 16, paragraph 6 of CIVA or similar",
				M02: "Article 6 of Decree-Law no. 198/90, of June 19",
				M03: "Cash basis accounting",
				M04: "Article 13 of CIVA or similar",
				M05: "Article 14 of CIVA or similar",
				M06: "Article 15 of CIVA or similar",
				M07: "Article 9 of CIVA or similar",
				M08: "VAT (self-assessment)",
				M09: "VAT (no right to deduction)",
				M10: "VAT (exemption regime: Article 53 of CIVA)",
				M11: "Special tobacco regime",
				M12: "Margin scheme for travel agencies",
				M13: "Margin scheme for second-hand goods",
				M14: "Margin scheme for works of art",
				M15: "Margin scheme for collector's items and antiques",
				M16: "Article 14 of RITI or similar",
				M99: "Not subject; not taxed or similar",
			},
			vatVendusProductTypeOptions: {
				P: "Product",
				S: "Service",
				O: "Other (freight, advances, etc.)",
				I: "Tax (except VAT and IS) or Parafiscal Charge",
				E: "Special Tax on Consumption (IABA, ISP, and IT)",
			},
			vatVendusCodeOptions: {
				INT: "Intermediate rate",
				ISE: "Exempt",
				NOR: "Normal rate",
				RED: "Reduced rate",
			},
		},
	},
};
