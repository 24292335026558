export const editMenu = {
	menuActive: "Activate menu",
	goBack: "Back to all menus",
	deleteConfirm: "Are you sure you want to delete the menu?",
	okText: "Yes, I want it!",
	warningNotification:
		"Menu edited, but there was a problem registering the opening hours:",
	errorProductIdentify:
		"unable to intentify the corresponding product of {{name}}, download a new template sheet and be careful with the 'identifier' column",
	successNotification: "All product information has been edited successfully",
	errorEditNotification: "Error in ",
	updatedPrices: "Updated Prices",
	search: "Search products",
	massEdit: "Edit products in bulk",
	deleteProducts: "Remove Products",
	deleteProductsConfirm:
		"Are you sure you want to remove all selected products from the menu?",
	massDelete: "Remove products in bulk",
	add: "Add Product",
};
