import { productsTab } from "./products-tab";
import { categories } from "./categories";

export const products = {
	tabs: {
		productsTab,
		categories,
		generalStock: "Estoque Geral",
	},
	functions: {
		modal: {
			sheet: {
				inventory: "inventario",
				columns: {
					id: "Id do produto",
					name: "Nome",
					category: "Categoria",
					type: "Tipo do produto",
					hasAlcohol: "Contém álcool?",
					fiscalCode: "SKU",
					fiscalNcm: "Fiscal - NCM",
				},
			},
		},
	},
	emptyList: "Nenhum produto encontrado",
};
