export const lossReport = {
	label: "Losses",
	title: "Loss Report",
	period: "Period",
	emptyText: "No data was found",
	export: "Download to Excel",
	add: "Add loss",
	description:
		"The loss report aggregates all stock movements marked as losses. This way, the administrator can have an overview of the products that have been lost.",
	periodInfo: "Displaying report from {{since}} to {{until}}",
	columns: {
		sku: "SKU",
		product: "Product",
		inventory: "Inventory",
		refund: "Chargeback loss",
		inventoryLoss: "Inventory loss",
		manual: "Manual loss",
		subtotal: "Subtotal",
		production: "Production loss",
		total: "Total loss",
		totalLoss: "Total loss value",
	},
	sheet: {
		title: "Loss Report",
		fileName: "loss-report",
		sku: "SKU",
		product: "Product",
		inventory: "Inventory",
		refund: "Chargeback loss",
		inventoryLoss: "Inventory loss",
		manual: "Manual loss",
		subtotal: "Subtotal",
		production: "Production loss",
		basicStorageUnit: "Unit",
		total: "Total loss",
		totalLoss: "Total loss value",
	},
	addModal: {
		loading: "Loading...",
		storageSelect: "Select stock",
		productSelect: "Select product",
		none: "No products found",
		lossQuantity: "Lost amount",
		count: "Quantity",
		date: "Date",
		other: "Add another product",
	},
	validations: {
		validStorage: "Select a valid storage",
		validProduct: "Select a valid product",
		validQuantity: "Insert a valid quantity",
		validDate: "Select a valid date",
	},
};
