import classNames from "classnames";
import { FilterPill } from "../filter-pill";
import * as React from "react";
import s from "./file-upload.scss";

import { DropEvent, FileRejection, useDropzone } from "react-dropzone";
import { useTranslation } from "react-i18next";

export interface IFileUploadProps {
	accept?: string[];
	className?: string;
	onChange?: (files: File[]) => void;
	initialFiles?: File[];
}

export function FileUpload({
	accept,
	className,
	onChange,
	initialFiles,
}: IFileUploadProps) {
	const [files, setFiles] = React.useState<File[]>(initialFiles ?? []);
	const onDrop = React.useCallback(
		(acceptedFiles: File[], _fileRejections: FileRejection[], _event: DropEvent) => {
			setFiles(acceptedFiles);
			onChange?.(acceptedFiles);
		},
		[],
	);
	const { getInputProps, getRootProps, isDragAccept, isDragReject } = useDropzone({
		onDrop,
		accept,
	});

	const { t } = useTranslation("components");

	function onRemoveFile(file: File) {
		setFiles(files.filter(f => f !== file));
	}

	return (
		<div
			className={classNames(s.container, className, {
				[s.accept]: isDragAccept,
				[s.reject]: isDragReject,
			})}
			{...getRootProps()}
		>
			<input {...getInputProps()} />
			{/* eslint-disable-next-line @typescript-eslint/no-magic-numbers */}
			{files.length === 0 ? (
				<span>{t("fileUpload")}</span>
			) : (
				files.map((file, index) => (
					<FilterPill
						key={`file-${file.name}-${index}`}
						label={file.name}
						onRemove={() => onRemoveFile(file)}
					/>
				))
			)}
		</div>
	);
}
