import {
	useGetAllPlaceSales,
	useGetAllPlaceSalesInEvent,
} from "#resources/hooks/integrations/sales/query";
import { useEffect, useState } from "react";
import { useSalesReport } from "#pages/place/bar-reports/sales-report/context";
import {
	Pagination,
	PaginationResponse,
	SalesDetailsOrderBy,
} from "#resources/api/enterprise-generated";
import {
	DEBOUNCE_TIMEOUT,
	DEFAULT_DATE_ORDER_BY,
	TOTAL_PER_PAGE,
} from "#pages/place/bar-reports/sales-report/context/constants";
import { useDebounceValue } from "#resources/hooks";

export const useGetAllPlaceSalesByLevel = () => {
	const {
		clientNameFilter: _clientNameFilter,
		employeesFilter: _employeesFilter,
		paymentMethodsFilter: _paymentMethodsFilter,
		placeId,
		since,
		until,
		selectedTab,
		eventId,
		eventLevel,
	} = useSalesReport();

	const [orderBy, setOrderBy] = useState<SalesDetailsOrderBy | null>(
		DEFAULT_DATE_ORDER_BY,
	);

	const [debouncedOrderBy] = useDebounceValue(orderBy, DEBOUNCE_TIMEOUT);

	const employeesFilter = _employeesFilter.length > 0 ? _employeesFilter : null;
	const clientNameFilter = _clientNameFilter ? _clientNameFilter : null;
	const paymentMethodsFilter =
		_paymentMethodsFilter.length > 0 ? _paymentMethodsFilter : null;

	const [pagination, setPagination] = useState<Pagination>({
		currentPage: 1,
		perPage: TOTAL_PER_PAGE,
	});

	const [paginationResponse, setPaginationResponse] = useState<PaginationResponse>({
		currentPage: 1,
		perPage: TOTAL_PER_PAGE,
		lastPage: 1,
		total: 0,
	});

	const enabledPlaceRequests = Boolean(!eventLevel && selectedTab === "all-sales");
	const enabledEventRequests = Boolean(eventLevel && selectedTab === "all-sales");

	const {
		data: getAllPlaceSalesData,
		isFetching: getAllPlaceSalesIsFetching,
	} = useGetAllPlaceSales(
		{
			placeId,
			pagination,
			period: { since, until },
			filters: {
				employeeIds: employeesFilter,
				paymentMethods: paymentMethodsFilter,
				customerName: clientNameFilter,
				orderBy: debouncedOrderBy ? [debouncedOrderBy] : null,
			},
		},
		{
			enabled: enabledPlaceRequests,
			placeholderData: previousData => previousData,
		},
	);

	const {
		data: getAllPlaceSalesInEventData,
		isFetching: getAllPlaceSalesInEventDataIsFetching,
	} = useGetAllPlaceSalesInEvent(
		{
			eventId: eventId || "",
			pagination,
			placeId,
			filters: {
				employeeIds: employeesFilter,
				paymentMethods: paymentMethodsFilter,
				customerName: clientNameFilter,
				orderBy: debouncedOrderBy ? [debouncedOrderBy] : null,
			},
		},
		{
			enabled: enabledEventRequests,
			placeholderData: previousData => previousData,
		},
	);

	const data = eventLevel ? getAllPlaceSalesInEventData : getAllPlaceSalesData;
	const isFetching = eventLevel
		? getAllPlaceSalesInEventDataIsFetching
		: getAllPlaceSalesIsFetching;

	useEffect(() => {
		setPagination(prev => ({ ...prev, currentPage: 1 }));
	}, [clientNameFilter, employeesFilter, paymentMethodsFilter]);

	useEffect(() => {
		if (!data?.pagination) return;

		setPaginationResponse(prev => ({
			...prev,
			total: data.pagination.total,
			perPage: data.pagination.perPage,
			lastPage: data.pagination.lastPage,
		}));
	}, [data?.pagination]);

	return {
		data,
		isFetching,
		pagination: {
			...paginationResponse,
			currentPage: pagination.currentPage,
		},
		setPagination,
		orderBy,
		setOrderBy,
	};
};
