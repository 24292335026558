import { newEvent } from "./new-event";

export const events = {
	title: "Events in {{place}}",
	newEvent,
	lastEventMonth: "Last month of event",
	eventsNumber: "Events",
	totalClients: "total customers",
	averageTicket: "average ticket",
	sales: "sales",
	receipts: "receipts",
	card: {
		pdv: "PDV's open",
		syncAfterClosed: "Sync after closed",
		lastSyncAfterClosedAt: "Last sync at:",
		averageTicket: "Average ticket",
		income: "Invoicing",
		clients: "Customers",
		status: {
			created: "Closed",
			finished: "Finished",
			running: "Open",
			canceled: "Canceled",
		},
	},
	timeline: {
		label: "other upcoming events, modify the period to see more",
	},
	tooltip: {
		calculation: "Calculation memorial: Total amount paid - Discounts made",
		incomeSummaryCalc: "Amount received in the events held throughout this month",
		minimumConsumption: "Minimum Consumption",
		productSold: "Products Sold",
		sales: "Sales",
		service: "Service",
		ticket: "Entrance",
	},
};
