export const whatsUp = {
	title: "Qual é a boa?",
	description: `Preencha os dados e compartilhe seu evento com seus clientes através do aplicativo
	ZIGPAY. Permita que os usuários tenham acesso ao horário de funcionamento, redes
	sociais, fotos e links externos.`,
	publish: "Publicar",
	edit: "Editar informações",
	placePublishObs:
		"Ao publicar você estará concordando em exibir as informações do seu local no aplicativo ZIG e ser responsável pelo conteúdo publicado.",
	eventPublishObs:
		"Ao publicar você estará concordando em exibir as informações do seu evento no aplicativo ZIG e ser responsável pelo conteúdo publicado.",
	active: {
		titlePlace: "Exibir casa no aplicativo do usuário",
		titleEvent: "Exibir evento no aplicativo do usuário",
		description:
			"Não será cobrada nenhuma taxa extra para exibir sua casa no aplicativo ZIG",
		unfilledFields: "Não foi possível ativar. Campos obrigatório não preenchidos.",
	},
	inactive: {
		placeTitle:
			"Seu local não está sendo exibido no aplicativo. É necessário habilitar a exibição.",
		eventTitle:
			"Seu evento não está sendo exibido no aplicativo. É necessário habilitar a exibição.",
	},
	profileSection: {
		title: "Perfil",
		seePreview: "Ver prévia do aplicativo",
		description:
			"Você pode subir imagens de até 500kb nos formatos .png, .jpg ou .jpeg de acordo com as dimensões mencionadas abaixo:",
		brandImage: "Imagem da marca",
		brandImageObs: "Imagem quadrada ou 500x500 pixels",
		frontCoverImage: "Imagem da capa",
		galleryImage: "Imagem para galeria (opcional)",
		horizontalImageObs: "Imagem horizontal ou 1280x780 pixels",
	},
	aboutSection: {
		titlePlace: "Sobre o local",
		titleEvent: "Sobre o evento",
		descriptionPlace:
			"Nessa etapa você deverá preencher informações sobre o local. Tente preencher todas as informações de forma clara e simples.",
		descriptionEvent:
			"Nessa etapa você deverá preencher informações sobre o evento. Tente preencher todas as informações de forma clara e simples.",
		eventTagLimit:
			"Você pode selecionar até 6 tags. As tags ajudarão os usuários a saberem mais sobre os diferenciais do seu evento.",
		placeTagLimit:
			"Você pode selecionar até 6 tags. As tags ajudarão os usuários a saberem mais sobre os diferenciais do seu local.",
		placeName: "Nome do local",
		eventName: "Nome do evento",
		placeCategory: "Categoria do Local",
		eventCategory: "Categoria do evento",
		descriptionInput: "Descrição (opcional)",
		placeDescriptionPlaceHolder: "Escreva uma breve descrição sobre o local",
		eventDescriptionPlaceHolder: "Escreva uma breve descrição sobre o evento",
		tags: "Tags",
		musicTags: "Estilo musical",
		musicTagLimit:
			"Utilize as tags de estilo para ajudar na exibição do seu local/evento para usuários com interesse nos mesmos estilos.",
		musicTagPlaceholder: "Selecione até 3 estilos musicais",
		tagsPlaceholder: "Selecione até 6 tags",
		barAndRestaurant: "Bar e restaurante",
		bar: "Bar",
		pub: "Pub",
		restaurant: "Restaurante",
		nightclub: "Balada",
		show: "Show",
		event: "Evento",
		festival: "Festival",
		beachClub: "Beach club",
		arena: "Arena",
		bistro: "Bistrô",
	},
	linkSection: {
		title: "Links externos (opcional)",
		site: "Site",
		reservation: "Reservas",
		facebook: "Facebook",
		instagram: "Instagram",
		ticket: "Ingressos",
		buttonHighlight: "Botão destaque (opcional)",
		pasteHere: "Cole aqui",
	},
	addressSection: {
		title: "Informações de endereço",
		titleOptional: "Informações de endereço (opcional)",
		zigLocation: "Local Zig",
		zigLocationTooltip:
			"É o polo da Zig onde seu local foi cadastrado. Caso deseje alterar, entre em contato com nosso time",
		zipCode: "CEP",
		zipCodeLink: "Não sei meu CEP",
		city: "Cidade",
		state: "Estado",
		neighborhood: "Bairro",
		address: "Endereço",
		number: "Número",
		complement: "Complemento (opcional)",
		addressPlaceholder: "Nome da rua ou avenida",
		complementPlaceholder: "Complemento de endereço",
	},
	operationSection: {
		placeTitle: "Horário de funcionamento",
		eventTitle: "Datas do evento",
		placeDescription:
			"Informe o dia e horário de funcionamento da sua casa. Caso sua casa não funcione em algum dia da semana, basta não adiciona-lo na lista abaixo.",
		eventDescription: "Informe o dia e horário de funcionamento do seu evento.",
		day: "Dia",
		add: "Adicionar",
		date: "Data",
		time: "Hora (opcional)",
		until: "até",
		monday: "Segunda-feira",
		tuesday: "Terça-feira",
		wednesday: "Quarta-feira",
		thursday: "Quinta-feira",
		friday: "Sexta-feira",
		saturday: "Sábado",
		sunday: "Domingo",
	},
	appearsInAppModal: {
		title: "Não compartilhar local no aplicativo",
		description:
			"Ao desativar, seu local não será exibido no aplicativo do usuário. Deseja continuar assim mesmo?",
	},
	cancelEditModal: {
		title: "Cancelar edição",
		description:
			"Ao cancelar a edição, as modificações não serão salvas. Deseja continuar assim mesmo?",
	},
	successModal: {
		eventTitle: "As informações foram salvas com sucesso!",
		placeTitle: "As informações foram salvas com sucesso!",
		description: `Certifique-se que a função "Exibir casa no aplicativo do usuário" esteja ativa para que seu local seja exibido no Zig.app.`,
	},
	formValidations: {
		name: "Nome é um campo obrigatório",
		nameMaxLength: "Limite máximo de caracteres é {{maxLength}}",
		locationCategory: "Categoria do local é um campo obrigatório",
		description: "Limite de 160 caracteres atingido",
		tags: "Você só pode selecionar até 6 tags",
		musicTags: "Você só pode selecionar até {{numberTags}} tags",
		link: "Insira um link válido",
		linkHighlightType: "Botão de destaque é um campo obrigatório",
		zigLocation: "Local Zig é um campo obrigatório",
		postalCode: "CEP é um campo obrigatório",
		state: "Estado é um campo obrigatório",
		city: "Cidade é um campo obrigatório",
		neighborhood: "Bairro é um campo obrigatório",
		streetName: "Endereço é um campo obrigatório",
		streetNumber: "Número é um campo obrigatório",
		image: "Imagem é obrigatória",
		imageSize: "Suba imagens até 500kb",
		date: "Data é um campo obrigatório",
		generalError: "*Informações obrigatórias não preenchidas",
	},
};
