import * as React from "react";
import { Icon as LegacyIcon } from "@ant-design/compatible";
import classnames from "classnames";

interface IProps {
	icon: string | JSX.Element;
	className?: string;
	description?: React.ReactNode;
	title?: React.ReactNode;
	subtitle?: React.ReactNode;
	action?: JSX.Element;
}
import s from "./index.scss";

export default class NotUsualState extends React.Component<IProps, {}> {
	public render() {
		const icon =
			typeof this.props.icon === "string" ? (
				<LegacyIcon type={this.props.icon} className={s.icon} />
			) : (
				this.props.icon
			);

		return (
			<div className={classnames(s.notUsualState, this.props.className)}>
				{icon}
				{this.props.title && <p className={s.title}>{this.props.title}</p>}
				{this.props.subtitle && <p className={s.subtitle}>{this.props.subtitle}</p>}
				<p>{this.props.description}</p>
				{this.props.action}
			</div>
		);
	}
}
