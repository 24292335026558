export const forms = {
	multiEntranceInputRelation: {
		removeEntrance: "Remove entry",
		addEntrance: "Add entry",
	},
	placeComboProductInput: {
		filterProducts: "Filter products",
	},
	inputs: {
		searchPlaceholder: "Type to search",
	},
	selects: {
		newFiscalModeOptions: {
			sat: "SAT-e",
			nfce: "NFC-e",
		},
		regimeTributarioOptions: {
			regimeNormal: "Regime Normal",
			simplesNacional: "Simples Nacional",
			simplesNacionalExcesso: "Simples Nacional Excesso",
		},
		states: {
			AC: "Acre",
			AL: "Alagoas",
			AM: "Amapá",
			BA: "Bahia",
			CE: "Ceará",
			DF: "Distrito Federal",
			ES: "Espírito Santo",
			GO: "Goiânia",
			MA: "Maranhão",
			MG: "Minas Gerais",
			MS: "Mato Grosso do Sul",
			MT: "Mato Grosso",
			PA: "Pará",
			PB: "Paraíba",
			PE: "Pernambuco",
			PI: "Piauí",
			PR: "Paraná",
			RJ: "Rio de Janeiro",
			RN: "Rio Grande do Norte",
			RO: "Rondônia",
			RR: "Roraima",
			RS: "Rio Grande do Sul",
			SC: "Santa Catarina",
			SE: "Sergipe",
			SP: "São Paulo",
			TO: "Tocantins",
		},
	},
	validations: {
		requiredField: "This field is required",
		requiredFieldShort: "Required field",
		invalidEmail: "Invalid email address",
	},
	clientDetailsDialog: {
		editClientData: "Edit customer data",
		name: "Name",
		phone: "Phone",
		email: "Email",
		birthDate: "Birth date",
	},
	loginForm: {
		org: "Organization",
		login: "Login",
		password: "Password",
		submit: "Enter",
	},
	paymentRequestForm: {
		alert:
			"Heads up! Requests made after 10:30 am (Brasília time) will only be processed the following day.",
		alertAnticipation:
			"Heads up! Anticipation requests until 18:00h (Brasília time) will be dealt with on the following business day. After this period, requests will be processed within two business days.",
		value: "Value",
		reason: "Reason",
		reasonPlaceholder: "Indicate the reason for the transaction",
		useAnotherBankAccount: "Use standard bank account?",
		bankData: "Bank data",
		bank: "Bank",
		bankPlaceholder: "Select a bank",
		document: "CPF or CNPJ",
		documentPlaceholder: "Ex: 123456789",
		account: "Account",
		accountPlaceholder: "Ex: 44440",
		agency: "Agency",
		agencyPlaceholder: "Ex: 5214",
		type: "Type",
		checking: "Checking account",
		savings: "Savings account",
		buttonSolicitation: "Make request",
	},
};
