export const packages = {
	title: "Packages",
	header: {
		title: "Add balance",
		subtitle: "Add balance to send messages to your customers",
	},
	modalPackage: {
		title: "Buy package",
		label: "Do you want to buy the Package",
		for: "by",
		addBalance: "and add {{amount}} balance sms?",
		footer: {
			sellPackage: "Buy SMS package",
		},
	},
	packageCard: {
		tagLabel: "Tag Label",
		sms: "SMS",
		forSMS: "by SMS",
		sell: "Buy package",
	},
	xlsx: {
		title: "SMS Campaign History",
		filename: "sms-campaigns",
		message: "Message",
		place: "Place",
		date: "Date and time",
		status: "Status",
		impactedCustomers: "Impacted customers",
	},
};
