import {
	ImageUrl,
	OrganizationFeatures,
	OrganizationWithFeatures,
} from "#api/enterprise-generated";
import { OrganizationStore } from "#stores/organization";
import { extendModel } from "#helpers/mobx/utils";

/**  @deprecated models should not ne used anymore */
export class OrganizationModel implements OrganizationWithFeatures {
	public organizationStore: OrganizationStore;

	public id!: string;
	public name!: string;
	public description!: string | null;
	public icon!: ImageUrl | null;
	public username!: string;
	public reportMails!: string[];
	public currency!: string | null;
	public currencySymbol!: string | null;
	public countryISO3!: string | null;
	public features!: OrganizationFeatures[];

	constructor(
		organizationStore: OrganizationStore,
		organization: OrganizationWithFeatures,
	) {
		this.organizationStore = organizationStore;
		this.sync(organization);
	}

	public sync = (organization: OrganizationWithFeatures) => {
		extendModel(this, organization);
	};
}
