export const cmv = {
	searchProduct: "Find a product",
	searchPlaceholder: "Select a product or category",
	details: {
		salePrice: "Sale Price",
		cost: "Cost",
		realCost: "Real Cost",
		markup: "Markup",
		contributionMargin: "Contribution Margin",
		productSimulator: "CMV Simulator",
		editProductPrice: "Change Price",
	},
	supply: {
		columns: {
			input: "Input",
			quantity: "Qty",
			unitCost: "Unit Cost",
			loss: "Loss",
			realCost: "Real Cost",
		},
	},
};
