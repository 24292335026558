import { steps } from "./steps";
import { components } from "./components";

export const fiscalProfileForm = {
	components,
	steps,
	titleSteps: {
		company: "Company data",
		fiscal: "Tax data",
		general: "General data",
		tip: "Tip Settings",
	},
	navigateBackLabel: "Back to tax profiles",
};
