import * as React from "react";
import classnames from "classnames";

import s from "./tag-label.scss";

export type TypeTagLabel =
	| "primary"
	| "secondary"
	| "warning"
	| "success"
	| "error"
	| "info"
	| "highlight";

interface TagLabelProps extends React.HTMLAttributes<HTMLDivElement> {
	children: React.ReactNode;
	type: TypeTagLabel;
}

export function TagLabel({ children, type, className, ...props }: TagLabelProps) {
	return (
		<div className={classnames(s.tagLabel, s[type], className)} {...props}>
			{children}
		</div>
	);
}
