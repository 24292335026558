export const locationNotes = {
	title: "Location Notes",
	filename: "location-notes",
	table: {
		serialNumber: "Number",
		issueDate: "Issue date",
		period: "Period",
		status: "Status",
		value: "Value",
		generatedAs: "Generated as",
		pdf: "PDF",
		noAttachments: "No attachments",
		attachments: "Attachments",
	},
	status: {
		created: "Created",
		generated: "Generated",
		cancelled: "Cancelled",
	},
	generated: {
		automatic: "Automatic",
		manually: "Manually",
	},
	emptyText: "There is nothing to show for the selected period",
};
