export const stockPositionReport = {
	label: "Stock Position",
	title: "Stock Position Report",
	filters: {
		period: "Period",
		storage: "Storage",
		apply: "Apply filters",
		clean: "Clear filters",
	},
	paragraph:
		"The Stock Position report provides an overview of how the stock was at a certain period, displaying the products from the selected stocks, their quantities, and costs.",
	selectStock: "Select the stock",
	emptyMessage: {
		title: "Select stock to start",
		description: "Select the stock and desired day to view the stock position report",
	},
	table: {
		product: "Product",
		initialQuantity: "Initial quantity",
		finalQuantity: "Final quantity",
		maxQuantity: "Maximum quantity",
		minQuantity: "Minimum quantity",
		replacement: "Suggested replacement",
		averageCost: "Average cost",
		totalCost: "Total cost",
		total: "Total",
	},
};
