import { drawerProfile } from "./drawer-profile";

export const drawers = {
	drawerProfile,
	editData: "Edit information",
	viewlinkedplaces: "View linked places",
	drawerCouvert: {
		code: "SKU",
		couvertGeneralData: "Couvert General Data",
		taxGroup: "Tax Group",
		taxProfile: "Tax Profile",
	},
	drawerGroup: {
		excludeTaxGroup: "Delete group",
		titles: {
			generalData: "General data",
			vat: "VAT",
			service: "Service",
		},
		fiscalProductGroupType: {
			vat: "Value Added Tax (VAT)",
			vatFactura: "Value Added Tax (VAT) - Mexico",
			service: "Service",
			invoice: "Invoice",
		},
		fields: {
			taxRate: {
				label: "Tax Rate (%)",
			},
			code: {
				label: "SKU",
			},
			productType: {
				label: "Product Type",
			},
			taxExemption: {
				label: "Tax Exemption",
			},
		},
	},
};
