export const financial = {
	title: "Extrato diário",
	filename: "extrato-diario",
	details: "Ver detalhes",
	noDataToShow: "Não há nada a mostrar para o período selecionado",
	table: {
		datetime: "Data",
		in: "Entrada",
		out: "Saída",
		balance: "Saldo",
		withdrew: "Resgatado",
		total: "Total",
	},
	summaryTitle: "Resumo para {{date}}",
	event: "Eventos",
	noResume: "Não há nenhum fechamento no período",
	modality: "Modalidade",
	payvalue: "A Pagar/A Receber",
	receive: "A receber",
	pay: "A pagar",
};
