export const packages = {
	title: "Pacotes",
	header: {
		title: "Adicionar saldo",
		subtitle: "Adicione saldo para enviar mensagens para os seus clientes",
	},
	modalPackage: {
		title: "Comprar pacote",
		label: "Deseja comprar o Pacote",
		for: "por",
		addBalance: "e adicionar {{amount}} sms de saldo?",
		footer: {
			sellPackage: "Comprar pacote de SMS",
		},
	},
	packageCard: {
		tagLabel: "Tag Label",
		sms: "SMS",
		forSMS: "por SMS",
		sell: "Comprar pacote",
	},
	xlsx: {
		title: "Histórico de Campanhas de SMS",
		filename: "campanhas-sms",
		message: "Mensagem",
		place: "Casa",
		date: "Data e hora",
		status: "Status",
		impactedCustomers: "Clientes impactados",
	},
};
