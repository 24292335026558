import { useWindowSize as useWindowSizeHookTs } from "usehooks-ts";

// measured in pixels
export const SMALL_DEVICE_SIZE = 600;
export const MEDIUM_DEVICE_SIZE = 768;
export const LARGE_DEVICE_SIZE = 992;
export const EXTRA_LARGE_DEVICE_SIZE = 1200;

export function useWindowSize() {
	const { width, height } = useWindowSizeHookTs();

	return {
		width,
		height,
		isExtraSmallDevice: width < SMALL_DEVICE_SIZE,
		isSmallDevice: width >= SMALL_DEVICE_SIZE && width < MEDIUM_DEVICE_SIZE,
		isMediumDevice: width >= MEDIUM_DEVICE_SIZE && width < LARGE_DEVICE_SIZE,
		isLargeDevice: width >= LARGE_DEVICE_SIZE && width < EXTRA_LARGE_DEVICE_SIZE,
		isExtraLargeDevice: width >= EXTRA_LARGE_DEVICE_SIZE,
	};
}
