export const newList = {
	title: "New guest list",
	steps: {
		first: {
			title: "List data",
			description: "What list is this?",
		},
		second: {
			title: "Customers",
			description: "Who are your customers?",
		},
		last: {
			title: "Ready list",
			description: "Download and print",
		},
	},
	create: "Create List",
	view: "View created list",
	print: "Print",
	downloadList: "Download list",
	forms: {
		firstStep: {
			title: "New list",
			name: {
				label: "List name",
				placeholder: "Enter your list name here",
				errorName: "Enter the name of the list",
				errorLength: "List name must be less than 100 characters",
			},
			expiresAtDate: {
				label: "List expiration",
				at: "at",
				error: "Expired date and time",
			},
			addGuestTimeLimit: "Guest insertion deadline",
			colorSelect: "Assign a color",
			couvertPercentage: "Discount on cover charge",
			entranceIds: {
				label: "Associated entry",
				placeholder: "Select the associated entry type",
			},
			description: {
				label: "Description",
				placeholder: "Add a note to your list if you want",
				error: "List description must be less than 280 characters",
				leftCharacters: "{{count}} characters left",
			},
			canBeInvitedByLink: {
				label: "Share List via Link",
				error: "For a list to be shareable, a guest limit is required",
			},
			isDocumentRequired: {
				label: "CPF required",
			},
			hasAutomaticEntranceCharge: {
				label: "Charge entrance automatically",
			},
			guestsLimitByPromoter: {
				label: "Limit of guests per promoter",
				placeholder: "Enter the limit number",
			},
			guestsLimit: {
				label: "Limit of guests",
				placeholder: "Enter the limit number",
			},
		},
		secondStep: {
			successImport: "Spreadsheet successfully imported",
			label:
				"You can manually add guests or import a spreadsheet. If you wish, you can go ahead and add or import guests at a later time.",
			title: "Customer {{count}}",
			cpf: {
				label: "CPF",
				error: "Invalid CPF",
				duplicate: "Duplicate CPF",
			},
			name: {
				label: "Name and surname",
				placeholder: "Enter guest name",
				error: "Invalid name",
			},
			phone: "Telephone",
			promoter: {
				label: "Promoter's name",
				placeholder: "Name of the person responsible for the list",
			},
			addNewClient: "Add guest",
		},
		lastStep: {
			title: "List created successfully",
			cardTitle: "Download/print",
		},
	},
	list: "List",
	goBackToLists: "Back to Lists",
};
