export const flow = {
	title: "Extrato-Fluxo de Caixa",
	filename: "extrato-fluxo-de-caixa",
	emptyText: "Não há nada a mostrar para o período selecionado",
	table: {
		date: "Data de liquidação",
		competency: "Data de transação",
		title: "Descrição",
		value: "Valor",
		balance: "Saldo",
	},
};
