import { orangeScreenReport } from "./orange-screen-report";
import { allEventActionsReport } from "./all-event-actions-report";
import { totemActionsReport } from "./totem-actions-report";

export const operationReports = {
	tabs: {
		orangeScreenReport: "Orange Screen Report",
		allEventActionsReport: "Event Actions",
		totemActionsReport: "Totem Actions",
	},
	orangeScreenReport,
	allEventActionsReport,
	totemActionsReport,
};
