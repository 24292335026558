export const entranceBilling = {
	tabs: {
		pageTabs: {
			boxOffice: "Faturamento",
			soldEntrances: "Entradas vendidas",
			employeeReport: "Entradas por funcionário",
		},
		boxOfficeBilling: {
			label: "Faturamento de bilheteria",
			value: "valor",
			revenue: "receita",
			percentage: "percentual",
			quantity: "quantidade",
			filename: "faturamento_bilheteria",
		},
		soldEntrances: {
			label: "Total de entradas vendidas",
			tabs: {
				total: "Total",
				clients: "Clientes por entrada",
			},
			columns: {
				name: "Nome",
				count: "Unidades vendidas",
				averageValue: "Preço médio",
				totalValue: "Total",
			},
			total: "Receita Total",
			sheet: {
				title: "Entradas vendidas",
				fileName: "entradas_vendidas",
				name: "Nome",
				average: "Valor médio",
				count: "Quantidade",
				totalValue: "Preço",
			},
			filter: "Filtrar por hora",
			since: "De",
			until: "Até",
			clientsReport: {
				title: "Listagem de clientes por entrada vendida",
				filterLabel: "Filtrar por entrada vendida",
				filterPlaceholder: "Adicione filtros por entrada",
				clients: "Clientes",
				entranceValue: "Valor da entrada",
				consumpion: "Consumo",
				total: "Total",
				name: "Nome",
				document: "Documento",
				entrance: "Entrada",
				checkin: "Check-in",
				checkout: "Checkout",
				fileName: "clientes-por-entrada-vendida_{{date}}",
			},
		},
		entranceReport: {
			label: "Entradas vendidas por funcionários",
			filename: "entradas-vendidas-por-funcionario",
			columns: {
				name: "Nome",
				count: "Quantidade",
				unitValue: "Valor unitário",
				subtotal: "Subtotal",
				discountValue: "Descontos",
				totalValue: "Valor total",
				entrance: "Entrada",
			},
			sheet: {
				title: "Entradas",
				fileName: "entradas",
				name: "Nome",
				count: "Quantidade",
				unitValue: "Valor unitário",
				subtotal: "Subtotal",
				discountValue: "Descontos",
				totalValue: "Valor total",
			},
			total: "Total",
			totalCategory: "Total ({{category}})",
		},
	},
};
