export const newEvent = {
	title: "Novo evento",
	modalTitle: "Adicionar evento",
	form: {
		labelName: "Nome:",
		labelBegin: "Início:",
		labelDescription: "Descrição:",
		eventPicture: "Foto do evento:",
		backgroundEventPicture: "Foto de capa do evento:",
		editButton: "Editar",
		addButton: "Adicionar",
		cancel: "Cancelar",
		peopleCapacity: "Capacidade do evento",
		peopleCapacityTooltip: "Quantidade de pessoas que o evento comporta.",
	},
};
