import * as React from "react";

import Icon from "#components/icon";

import classnames from "classnames";
import s from "./wizard.scss";

export type WizardData = Omit<IWizardItem, "children" | "isCurrent" | "hideTitles">;

export interface IWizardItem {
	title?: string;
	itemKey: string;
	children: React.ReactNode;
	isValid?: boolean;
	isCurrent?: boolean;
	isLast?: boolean;
	isHidden?: boolean;
	hideTitles?: boolean;
	onStepClick?: (step: string) => void;
}

export interface IWizardProps {
	spread?: boolean;
	currentStep: string;
	onStepClick?: (step: string) => void;
	data: WizardData[];
	className?: string;
	hideTitles?: boolean;
}

const NUMBER_SUM_ONE = 1;

export function Wizard({
	currentStep,
	data,
	onStepClick,
	spread,
	className,
	hideTitles,
}: IWizardProps) {
	const dataWithoutHiddenItems = data.filter(({ isHidden }) => !isHidden);

	return (
		<div
			className={classnames(
				s.wizard,
				spread && s.spread,
				onStepClick && s.clicky,
				className,
			)}
		>
			<ul className={classnames(hideTitles && s.stepsWithoutTitle)}>
				{data.map(({ itemKey, ...props }, index) => {
					const isLast = index + NUMBER_SUM_ONE === dataWithoutHiddenItems.length;

					return (
						<Item
							isCurrent={currentStep === itemKey}
							isLast={isLast}
							key={itemKey}
							itemKey={itemKey}
							onStepClick={onStepClick}
							hideTitles={hideTitles}
							{...props}
						>
							{index + NUMBER_SUM_ONE}
						</Item>
					);
				})}
			</ul>
		</div>
	);
}

const Item = ({
	children,
	isValid,
	isCurrent,
	isHidden,
	isLast,
	title,
	onStepClick,
	itemKey,
	hideTitles,
}: IWizardItem) => {
	if (isHidden) return <></>;

	const onClick = onStepClick ? () => onStepClick(itemKey) : undefined;

	return (
		<li
			onClick={onClick}
			className={classnames({
				[s.previous]: isValid,
				[s.current]: isCurrent,
			})}
		>
			<div className={s.item}>
				<div className={s.tag}>{isValid ? <Icon icon="check-small" /> : children}</div>
				{!hideTitles && <span>{title}</span>}
				{!isLast ? <Icon icon="chevron-right" /> : null}
			</div>
		</li>
	);
};
