export const paymentMethodReport = {
	titleBar: "Método de pagamento por Bar",
	titleEmployee: "Método de pagamento por Funcionário",
	info:
		"Os valores desse relatório podem ter sido estimados e por isso é possível existir pequenas diferenças",
	sheet: {
		titleBar: "Método de pagamento por Bar",
		filenameBar: "metodo-pagamento-bar",
		titleEmployee: "Método de pagamento por Funcionário",
		filenameEmployee: "metodo-pagamento-funcionario",
	},
	columns: {
		App: "App",
		Cash: "Dinheiro",
		DebitCard: "Débito",
		CreditCard: "Crédito",
		Voucher: "Voucher",
		ZigPosDebit: "Débito Zig",
		ZigPosCredit: "Crédito Zig",
		PostEvent: "Pós Evento",
		Dashboard: "Dashboard",
		Anticipated: "Antecipado",
		DeliveryOnline: "Delivery",
		Consumption: "Consumação",
		Ame: "Ame",
		Rappi: "Rappi",
		Uber: "Uber",
		Ifood: "Ifood",
		Bonus: "Bônus",
		Pix: "Pix",
		Opened: "Em Aberto",
		Other: "Outros",
		ZigPosCreditInInstallments: "Crédito Zig em Parcelas",
		IntegratedFoodVoucher: "Vale-refeição",
		name: "Nome",
		total: "Total",
	},
};
