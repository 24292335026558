import { orangeScreenReport } from "./orange-screen-report";
import { allEventActionsReport } from "./all-event-actions-report";
import { totemActionsReport } from "./totem-actions-report";

export const operationReports = {
	tabs: {
		orangeScreenReport: "Relatório de Tela Laranja",
		allEventActionsReport: "Ações do Evento",
		totemActionsReport: "Ações no Totem",
	},
	orangeScreenReport,
	allEventActionsReport,
	totemActionsReport,
};
