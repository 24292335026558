// Activate polyfills
require("babel-polyfill");
import "#styles/print.scss";

/* Application setup */
import * as React from "react";
import { render } from "react-dom";

import { ZigComponentsThemeProvider } from "#structure/providers/zig-components";
import { AntDesignThemeProvider } from "#structure/providers/antd";
import { MobxProvider } from "#structure/providers/mobx";
import { App } from "./app";
import "./i18n/index";

import { LanguageProvider } from "./structure/providers/language";
import { BreadCrumbContextProvider } from "#structure/providers/breadcrumb";
import { setupAppConfiguration } from "#configuration/config";
import { ReactQueryProvider } from "#structure/providers/react-query";

const container = document.getElementById("app")!;

if (module.hot) {
	// https://github.com/webpack-contrib/webpack-hot-middleware/issues/390
	// @ts-ignore
	module.hot.accept();
}
function renderApp() {
	return render(
		<ReactQueryProvider>
			<LanguageProvider>
				<AntDesignThemeProvider>
					<ZigComponentsThemeProvider>
						<MobxProvider>
							<BreadCrumbContextProvider>
								<App />
							</BreadCrumbContextProvider>
						</MobxProvider>
					</ZigComponentsThemeProvider>
				</AntDesignThemeProvider>
			</LanguageProvider>
		</ReactQueryProvider>,
		container,
	);
}

setupAppConfiguration().then(renderApp);
