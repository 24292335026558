import { autobind } from "core-decorators";
import * as React from "react";
import CurrencyInput, { ICurrencyInputProps } from "react-currency-input";
import { ObjectOmit, ObjectOptional } from "typelevel-ts";

import s from "./plain-input.scss";
import { FieldProps } from "formik";
import moize from "moize";
import { inject, observer } from "mobx-react";
import { OrganizationStore } from "#stores/organization";

type ValueType = string | number | undefined | null;

type PlainInputArtificialType = "currency";
type PlainInputNativeType =
	| "text"
	| "email"
	| "range"
	| "search"
	| "tel"
	| "url"
	| "password"
	| "number";
export type PlainInputType = PlainInputArtificialType | PlainInputNativeType;

export interface PlainInputProps
	extends ObjectOmit<
		React.HTMLProps<HTMLInputElement>,
		"onChange" | "onBlur" | "ref" | "value"
	> {
	value: ValueType;
	onChange: (s: ValueType) => void;
	removePrefix?: boolean;
	type?: PlainInputType;
	children?: never;

	onBlur?(ev?: React.FocusEvent<HTMLInputElement>): void;
	onFocus?(ev: React.FocusEvent<HTMLInputElement>): void;

	organizationStore?: OrganizationStore;
}

interface State {
	rawValue?: string | number;
}

@inject("organizationStore")
@observer
@autobind
export class PlainInput extends React.Component<PlainInputProps, State> {
	public state: State = {};

	private onChangeInput: React.HTMLProps<HTMLInputElement>["onChange"] = (
		ev: React.ChangeEvent<HTMLInputElement>,
	) => {
		this.setState({ rawValue: ev.target.value });
		this.props.onChange(ev.target.value);
	};

	private onChangeCurrency: ICurrencyInputProps["onChangeEvent"] = (_, __, num) => {
		this.setState({ rawValue: num });
		this.props.onChange(num);
	};

	public shouldComponentUpdate(nextProps: PlainInputProps, nextState: State) {
		return Number(nextState.rawValue || null) !== nextProps.value || !nextProps.readOnly;
	}

	public render() {
		const {
			value,
			onChange,
			type,
			className,
			organizationStore,
			...inputProps
		} = this.props;

		const classnames =
			s.input + ` ${className || ""}` + ` ${type === "number" ? s.noSpinner : ""}`;

		return (
			<span className={s.inputWrapper}>
				{this.props.type === "currency" ? (
					<CurrencyInput
						{...inputProps}
						className={classnames}
						value={this.props.value || ""}
						onChangeEvent={this.onChangeCurrency}
						prefix={
							this.props.removePrefix ? "" : organizationStore?.currencySymbolOrg ?? ""
						}
						precision={2}
						decimalSeparator=","
						thousandSeparator="."
					/>
				) : (
					<input
						{...inputProps}
						className={classnames}
						value={
							this.props.value === null || this.props.value === undefined
								? ""
								: this.props.value
						}
						type={type}
						onChange={this.onChangeInput}
					/>
				)}
			</span>
		);
	}
}

export default PlainInput;

export const formikPlainInput = moize({ serialize: true })(
	(inputProps: ObjectOptional<PlainInputProps, "value" | "onChange"> = {}) => ({
		field,
		form: { setFieldValue },
	}: FieldProps) => (
		<>
			<PlainInput
				autoFocus
				{...field}
				onChange={value => setFieldValue(field.name, value)}
				{...inputProps}
			/>
		</>
	),
);
