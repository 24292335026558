import { useStores } from "#stores";

export const useRollout = ({ allowedEmails }: { allowedEmails: string[] }) => {
	const { authStore } = useStores();

	const isAllowedUser =
		authStore.currentEmployee?.username &&
		allowedEmails.some(email => authStore.currentEmployee?.username.includes(email));

	return Boolean(isAllowedUser);
};
