export const conference = {
	title: "Event Conference",
	eventOpen: "Open event",
	closeEventToConference: "You must close the event to hold the conference",
	titleTypeOfConference: {
		resumed: "Summary Table",
		integratedIncome: "Fees on integrated APP and machines (credit/debit)",
		notIntegratedIncome: "Fees on non-integrated POS machines (credit/debit)",
		zigFees: "Zig Fees",
		securityDeposit: "Security Deposit",
		otherCharges: "Other Charges",
		rechargeRefund: "Recharge Refund",
	},
	columnsTable: {
		name: "Name",
		grossValue: "Gross Value",
		tax: "Tax",
		taxReceivedOrPayable: "Zig Receivable / Payable",
		receiptForecast: "Receipt forecast",
	},
	conference: "Conference",
	processConference: "Process conference",
};
