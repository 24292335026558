export const reserve = {
	title: "Reservation and Waiting line",
	tabs: {
		reserveManager: "Reserve Manager",
		queueManager: "Queue Manager",
		generalReserveConfig: "General Settings",
		calendarManagement: "Calendar Management",
		subTabs: {
			reserveConfig: "General Reserve Settings",
			reserveDaysConfig: "Booking Days Settings",
			queueConfig: "Queue Settings",
			siteConfig: "Site Settings",
		},
	},
	cardReserve: {
		contact: "Contact",
		phone: "Cell phone",
		email: "E-mail",
		tableTo: "Table for",
		people: "people",
		actionButtons: {
			accommodate: "Accommodate",
			cancel: "Cancel",
			noShow: "No-show",
		},
		note: "Note",
	},
	statusDescription: {
		accommodated: "Accommodated",
		canceled: "Canceled",
		"no-show": "No-show",
		reserved: "Reserved",
	},
	collapseShiftList: {
		successNotification: "Your changes have been saved.",
		labels: {
			reserved: "Reserved",
			people: "People",
			noShow: "No-show",
			canceled: "Canceled",
		},
		dialogsConfirmation: {
			cancel: {
				title: "Cancel",
				subtitle:
					"Do you want to cancel the reservation? Once confirmed, this action cannot be undone.",
				actionText: "Cancel booking",
			},
			noShow: {
				title: "No-show",
				subtitle:
					"Do you want to charge the no-show fee? Once confirmed, this action cannot be undone.",
				actionText: "Charge for no-show",
			},
			seatDown: {
				title: "Accommodate",
				subtitle:
					"Do you want to accommodate the customer? Once confirmed, this action cannot be undone.",
				actionText: "Confirm accommodation",
			},
		},
	},
	tableReserveField: {
		tableFor: "Table for",
		people: "People",
		minOfPeople: "Min of People",
		deleteConfirmation: {
			title: "Delete tables",
			subtitle: "Tables will be permanently deleted. Do you want to continue anyway?",
			actionText: "Delete tables",
		},
	},
	config: {
		selectFee: {
			person: "By person",
			table: "By table",
		},
		selectTime: {
			minutes: "Minutes",
			hours: "Hours",
			days: "Days",
		},
		shiftSelect: {
			to: "to",
		},
		bookingDays: {
			addTables: "Add tables",
		},
		seatArea: {
			title: "Seating area",
			name: "Seating area name",
			noShowFee: "No-show fee",
			noShowTooltip: "Amount to be charged for bookings that didn't show up.",
			advancedFee: "Advanced fee",
			advancedTooltip: "Amount will be charged during booking.",
			description: "Short description (up to 45 characters)",
			delete: "Delete seating area",
			selectSeatArea: "Select seating areas and define tables",
		},
	},
	placeConfig: {
		title: "Place information",
		postalCode: "ZIP code",
		street: "Adress",
		streetPlaceholder: "Street name",
		neighborhood: "Neighborhood",
		state: "State",
		statePlaceholder: "State",
		city: "City",
		number: "Number",
		complement: "Complement (optional)",
		complementPlaceholder: "Adress complement",
		phone: "Phone",
		businessHours: "Business hours",
		businessHoursPlaceholder: "Ex: Monday to friday, from 5PM to 10PM",
	},
	bookingForm: {
		bookableTime: "Bookable time",
		startTime: "Start",
		to: "to",
		endTime: "End",
		confirmDelete: {
			title: "Delete time",
			subtitle:
				"This time slot will be permanently deleted. Do you want to continue anyway?",
			actionText: "Delete time",
		},
		addTime: "New bookable time",
		deleteTime: "Delete time",
		submit: "Save changes",
	},
	siteConfig: {
		title: "Customization",
		description:
			"You can upload images of up to 500kb in .png, .jpg or .jpeg formats according to the dimensions mentioned below:",
		brandImage: "Brand image",
		brandImageObs: "Square image or 500x500 pixels",
		coverImage: "Cover image",
		galleryImage: "Image for gallery (optional)",
		horizontalImageObs: "Horizontal image or 1280x780 pixels",
	},
	reserveConfigPage: {
		reserveConfig: "General Reserve Settings",
		reserveConfigDescrip:
			"Set business hours, time between reservations, minimum advance time to reserve, tolerance limit, cancelation and add your place's seating areas.",
		configCards: {
			businessHours: {
				title: "Business hours",
				description:
					"Set your place's business hours. Reservation times will respect business hours.",
			},
			toleranceTime: {
				title: "Tolerance limit",
				description:
					"How long will the person be expected to arrive after the reservation time?",
			},
			cancelationTime: {
				title: "Cancelation",
				description: "What is the time limit for customers to cancel a reservation?",
			},
			timeBetweenReservation: {
				title: "Time interval between reservations",
				description:
					"What will be the time interval between one reservation and another?",
			},
			minimumAdvanceTime: {
				title: "Minimum advance time to reserve",
				description: "How far in advance can a reservation be made?",
			},
		},
		intervals: {
			morning: "Morning",
			afternoon: "Afternoon",
			night: "Night",
		},
		seatAreaConfig: "Seating area settings",
		seatAreaConfigDescrip:
			"Add, describe, and set fees for diferent seating areas of your place.",
		confirmationModal: {
			title: "Delete seating area",
			subtitle:
				"This seating area will be permanently deleted. Do you want to continue anyway?",
			actionText: "Delete seating area",
		},
		cancelModal: {
			title: "Discard changes",
			subtitle:
				"Your changes will be deleted permanently. Do you want to continue anyway?",
			actionText: "Discard changes",
		},
		addSeatArea: "New seating area",
		requiredFields: "Required fields",
		cancel: "Cancel",
		cancelSucess: "Your changes were discarded.",
		save: "Save changes",
		sucess: "Your changes have been saved.",
		error: "Your changes were not saved, try again.",
	},
	generalConfig: {
		config: "General settings",
		makeAdjustments:
			"Make adjustments and configure times and information to share in the reservation link.",
	},
	shiftDefinition: {
		setShift: "Shift settings",
		configureShift:
			"Configure your establishment's shifts. You will assign them to days of the week in the calendar settings.",
		addShift: "Add shift",
	},
	intervalReservation: {
		interval: "Interval between reservations",
		setInterval:
			"Set time intervals for making a reservation, delay limits, and scheduling period.",
		availableReservation: "Availability for reservation",
		reservationWindow: "Interval window in the calendar",
		limit: "Delay time limit",
		limitInfo:
			"The delay limit does not trigger automatic cancellation, only the indication in the reservation list.",
	},
	storeDescriptionSession: {
		storeDescription: "Store description",
		describeStore: "Describe your store to share information on your reservation site.",
		productLink: "Product link",
		acceptTerms:
			"I agree to share the information on this page with customers who view the reservation site.",
		dayAndTime: "Days and hours of operation",
		addDayAndTime: "Add days and hours",
		rules: "Rules and conditions",
		rulesPlaceholder:
			"Describe here the rules, conditions, and guidelines of your reservation and cancellation policy.",
		additionalInfo: "Additional information",
		infoPlaceholdeR: "If you wish, describe more information about your establishment.",
		shareMenuReservation: "Share link to the menu on the reservation site?",
	},
	saveSettings: "Save settings",
	saveChanges: "Save changes",
	deleteShift: "Delete shift",
	shiftName: "Shift name",
	shiftNameExemple: "E.g.: Morning, lunch, evening, etc.",
	start: "Start",
	end: "End",
	daysOfTheWeek: "Days of the week",
	selectAvailableDays:
		"You need to select the days when this shift will be available for booking.",
	calendarManagementDescription:
		"Set the number of seats you make available for each of your shifts. Edit whenever needed.",
	createReservation: "Create reservation",
	modalConfigurations: {
		exclude: {
			title: "Are you sure you want to delete?",
			text: "By deleting these places, you will lose the settings previously made.",
			confirmButton: "Yes, I want to delete",
		},
		discard: {
			title: "Do you want to discard the changes?",
			text: "By exiting without saving, you will not keep the changes made now.",
			confirmButton: "Yes, discard",
		},
		cancel: {
			title: "Cancel reservation",
			text:
				"Are you sure you want to cancel this reservation? The deletion will be permanent. Remember to contact the customer to inform them of the cancellation.",
			confirmButton: "Yes, cancel reservation",
		},
		accommodate: {
			title: "Accommodate",
			text:
				"Have the people at this table arrived? Confirm if everything is okay to accommodate this reservation. This action cannot be undone.",
			confirmButton: "Yes, accommodate",
		},
	},
	availableSeatsAmount: "Available seats amount",
	addMoreSeats: "Add more seats",
	selectShift: "Select shift",
	tables: "Tables",
	seats: "Seats",
	replicateForAllWeek: "Replicate for all days of the week.",
	editOrDeleteLater: "You can delete/edit each day later if you prefer.",
};
