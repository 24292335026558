import { productsTab } from "./products-tab";
import { categories } from "./categories";

export const products = {
	tabs: {
		productsTab,
		categories,
		generalStock: "General stock",
	},
	functions: {
		modal: {
			sheet: {
				inventory: "inventory",
				columns: {
					id: "Product Id",
					name: "Name",
					category: "Category",
					type: "Product type",
					hasAlcohol: "Does it contain alcohol?",
					fiscalCode: "SKU",
					fiscalNcm: "Fiscal - NCM",
				},
			},
		},
	},
	emptyList: "No products found",
};
