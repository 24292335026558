export const cashierAdjustmentsReport = {
	title: "Sangrias e Fundos de Troco",
	filters: {
		byAuthor: {
			label: "Filtrar por Funcionário",
			placeholder: "Selecione um funcionário",
		},
		byCashier: {
			label: "Filtrar por Caixa",
			placeholder: "Selecione um caixa",
		},
		byType: {
			label: "Filtrar por Tipo",
			placeholder: "Selecione um tipo",
		},
	},
	table: {
		columns: {
			author: "Funcionário",
			cashier: "Caixa",
			timestamp: "Data/Hora",
			type: "Tipo",
			obs: "Observação",
			value: "Valor",
		},
	},
	adjustmentTypes: {
		bleeding: "Sangria",
		pettyCash: "Fundo de Troco",
	},
	cashierAdjustmentForm: {
		inputLabels: {
			value: "Valor",
			obs: "Observação",
		},
		errors: {
			emptyValue: "Digite um valor",
		},
	},
	cashierAdjustmentModal: {
		title: "Lançar {{adjustmentType}}",
		notAllowedText: "Essa ação não pode ser realizada quando o evento já foi encerrado.",
	},
	excelFileName: "ajustes-de-caixa-{{timestamp}}",
	successfullExportMsg: "Planilha exportada com sucesso!",
	exchangeTotal: "Total em fundo de troco",
	cashWithdrawal: "Total em sangria",
};
