import React from "react";
import { useTranslation } from "react-i18next";

import { Typography } from "#components/primitive";
import EmptyStateIcon from "#resources/assets/svg/empty-state-notifications.svg";

import s from "../../notifications-content.scss";

export function NotificationsContentEmptyState() {
	const { t } = useTranslation("components", {
		keyPrefix: "notifications",
	});

	return (
		<div className={s.emptyStateContainer}>
			<div className={s.emptyStateIcon}>
				<EmptyStateIcon />
			</div>
			<Typography type="heading" variant="v2">
				{t("noNews")}
			</Typography>
			<Typography type="paragraph" variant="v1">
				{t("emptyUnreadNotifications")}
			</Typography>
		</div>
	);
}
