import { fetchModel } from "#resources/helpers/fetch-model";
import enterprise from "#resources/api/enterprise-client";
import { showErrorNotification } from "#resources/helpers/notifications";
import { PrechargeOperation } from "#resources/api/enterprise-generated";
import i18n from "#i18n/index";

const t = i18n.t;
export class PrechargeStore {
	public resetAll = () => {
		this.getPrechargeOperationsForPlace.reset();
	};

	public getPrechargeOperationsForPlace = new fetchModel<
		Parameters<typeof enterprise.getPrechargeOperationsForPlace>["0"],
		PrechargeOperation[]
	>({
		fnPromise: args => enterprise.getPrechargeOperationsForPlace(args),
		onError: err => showErrorNotification(err.message),
	});

	public createPrechargeOperation = new fetchModel<
		Parameters<typeof enterprise.createPrechargeOperation>["0"],
		PrechargeOperation
	>({
		fnPromise: args => enterprise.createPrechargeOperation(args),
		onError: err => showErrorNotification(err.message),
		onSuccess: () => t("place:precharge.prechargeStore.createPrechargeOperationSuccess"),
	});

	public updatePrechargeOperationData = new fetchModel<
		Parameters<typeof enterprise.updatePrechargeOperationData>["0"],
		PrechargeOperation
	>({
		fnPromise: args => enterprise.updatePrechargeOperationData(args),
		onError: err => showErrorNotification(err.message),
		onSuccess: () => t("place:precharge.prechargeStore.updatePrechargeOperationSuccess"),
	});

	public updatePrechargeOperationLogo = new fetchModel<
		Parameters<typeof enterprise.updatePrechargeOperationLogo>["0"],
		PrechargeOperation
	>({
		fnPromise: args => enterprise.updatePrechargeOperationLogo(args),
		onError: err => showErrorNotification(err.message),
	});

	public updatePrechargeOperationMainImage = new fetchModel<
		Parameters<typeof enterprise.updatePrechargeOperationMainImage>["0"],
		PrechargeOperation
	>({
		fnPromise: args => enterprise.updatePrechargeOperationMainImage(args),
		onError: err => showErrorNotification(err.message),
	});

	public deletePrechargeOperation = new fetchModel<
		Parameters<typeof enterprise.deletePrecharge>["0"],
		void
	>({
		fnPromise: args => enterprise.deletePrecharge(args),
		onError: err => showErrorNotification(err.message),
		onSuccess: () => t("place:precharge.prechargeStore.deletePrechargeOperationSuccess"),
	});
}
