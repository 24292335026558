exports = module.exports = require("../../../node_modules/.pnpm/css-loader@0.28.11/node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._152WU-Jbod4RkZFnAHkCOW{border-radius:5px;position:relative;overflow:hidden;min-width:20px;min-height:20px;background:#dcdcdc}._152WU-Jbod4RkZFnAHkCOW::after{content:\"\";position:absolute;top:0;left:0;width:100%;height:100%;background:linear-gradient(90deg, GAINSBORO, #eeeeee, GAINSBORO);animation:_3do_hdsPWkJVNT3ukCa7dV 1s ease-in-out infinite}@keyframes _3do_hdsPWkJVNT3ukCa7dV{0%{transform:translate3d(-100%, 0, 0)}100%{transform:translate3d(100%, 0, 0)}}", ""]);

// exports
exports.locals = {
	"skeleton": "_152WU-Jbod4RkZFnAHkCOW",
	"progress": "_3do_hdsPWkJVNT3ukCa7dV"
};