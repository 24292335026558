export const recharges = {
	title: "Recargas",
	activeTab: "recharges",
	export: "Exportar Excel",
	transactionedValue: "Valor Transacionado",
	value: "Valor Bruto Emitido",
	netValue: "Valor Líquido Emitido",
	periodFilter: "Filtrar por período",
	eventsFilter: "Filtrar por eventos",
	filterIssuedLabel: "Filtrar por status",
	eventsSelected: "eventos selecionados",
	eventsFilterPlaceholder: "Selecione o(s) evento(s)",
	descriptionForeign:
		"Este relatório mostra todas as recargas e exibe informações da Nota Fiscal associada a elas, se existirem. Permite filtrar por período, eventos e status.",
	columns: {
		totalValue: "Valor da recarga",
		usedValue: "Valor consumido",
		date: "Data",
		eventName: "Evento",
		eventDate: "Data evento",
		cnpj: "CNPJ Emitente",
		cpf: "CPF Cliente",
		type: "Tipo",
		issuerNIF: "NIF Lojista",
		recipientNIF: "NIF Cliente",
		invoiceSeries: "Série nota",
		invoiceNumber: "Nº nota",
		invoiceValue: "Valor nota",
		invoiceNetValue: "Valor líquido da nota",
		invoiceDate: "Data Emissão",
	},
};
