export const drawerProfile = {
	excludeTaxProfile: "Exclude profile",
	infoDataCompany: {
		CNPJ: "CNPJ",
		cityCode: "City code",
		corporateReason: "Corporate reason",
		fantasyName: "Fantasy name",
		municipalRegistration: "Municipal registration",
		stateRegistration: "State registration",
		taxRegime: "Tax regime",
	},
	infoDataIvaVendus: {
		apikey: "API key",
		cashierIds: "Cashier IDs",
		country: "Country",
		payments: {
			cash: "Cash",
			credit: "Credit",
			debit: "Debit",
			mbway: "Mbway",
			others: "Others",
			paymentMethodId: "Form of payment",
		},
	},
	infoDataNfce: {
		certificate: "Certificate",
		certificatePassword: "Certificate password",
		cscNfceHomologation: "CSC NFC-e homologation",
		cscNfceProduction: "CSC NFC-e production",
		expirationDate: "Expiration date",
		idNfceHomologation: "ID NFC-e homologation",
		idNfceProduction: "Id NFC-e production",
	},
	infoDataSat: {
		serialNumberSat: "Serial number SAT",
	},
	infoDataTax: {
		mode: "Tax mode",
	},
	infoManifestation: {
		manifestation: "Manifestation",
	},
	infosGeneralData: {
		email: "Email",
		phone: "Phone",
	},
	infosGeneralDataFiscalModeBR: {
		cep: "Postal Code",
		county: "City",
		neighborhood: "Neighborhood",
		number: "Number",
		publicPlace: "Street",
		state: "State",
	},
	infosTipConfig: {
		taxGroup: "Fiscal Group",
		registered: "Registered",
	},
	titles: {
		companyData: "Company Data",
		generalData: "General data",
		taxData: "Tax Data",
		tipConfig: "Tip Settings",
	},
};
