import enterprise from "#resources/api/enterprise-client";

import { SdkgenError } from "@sdkgen/browser-runtime";
import { useMutation } from "@tanstack/react-query";

import { EnterpriseApiSignature } from "#resources/helpers/api";

type SetReadNotificationsSignature = EnterpriseApiSignature<"setReadDashboardNotificationsForEmployee">;

export const useSetReadNotifications = () => {
	return useMutation<
		SetReadNotificationsSignature["result"],
		SdkgenError,
		SetReadNotificationsSignature["args"]
	>({
		mutationFn: args => enterprise.setReadDashboardNotificationsForEmployee(args),
	});
};
