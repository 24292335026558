export const nfReport = {
	label: "Notas fiscais de compra",
	columns: {
		name: "Número da nota",
		date: "Data de importação",
		emission: "Data de emissão",
		value: "Valor",
	},
	productColumns: {
		name: "Produtos",
		quantity: "Quantidade",
		unit: "Unidade",
		unitValue: "Valor unitário",
		total: "Valor total",
	},
	notAssociatedProduct: "(Produto não associado)",
	empty: "Sem produtos",
};
