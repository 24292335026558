export const reserve = {
	title: "Reserva e Fila de Espera",
	tabs: {
		reserveManager: "Gestão de Reservas",
		queueManager: "Gestão Fila de Espera",
		generalReserveConfig: "Configurações Gerais",
		calendarManagement: "Gestão de Calendário",
		subTabs: {
			reserveConfig: "Configurações Gerais de Reserva",
			reserveDaysConfig: "Configurações dos dias de Reserva",
			queueConfig: "Configurações Fila de Espera",
			siteConfig: "Configurações do Site",
		},
	},
	cardReserve: {
		contact: "Contato",
		phone: "Celular",
		email: "E-mail",
		tableTo: "Mesa para",
		people: "pessoas",
		actionButtons: {
			accommodate: "Acomodar",
			cancel: "Cancelar",
			noShow: "No-show",
		},
		note: "Observação",
	},
	statusDescription: {
		accommodated: "Acomodado",
		canceled: "Cancelado",
		"no-show": "No-show",
		reserved: "Reservado",
	},
	collapseShiftList: {
		successNotification: "Suas alterações foram salvas.",
		labels: {
			reserved: "Reservas",
			people: "Pessoas",
			noShow: "No-show",
			canceled: "Canceladas",
		},
		dialogsConfirmation: {
			cancel: {
				title: "Cancelar",
				subtitle:
					"Deseja cancelar a reserva? Após a confirmação essa ação não poderá ser desfeita.",
				actionText: "Cancelar reserva",
			},
			noShow: {
				title: "No-show",
				subtitle:
					"Deseja fazer a cobrança da taxa de no-show? Após a confirmação essa ação não poderá ser desfeita.",
				actionText: "Fazer cobrança de no-show",
			},
			seatDown: {
				title: "Acomodar",
				subtitle:
					"Deseja acomodar o cliente? Após a confirmação essa ação não poderá ser desfeita.",
				actionText: "Confirmar acomodação",
			},
		},
	},
	tableReserveField: {
		tableFor: "Mesas para",
		people: "Pessoas",
		minOfPeople: "Mínimo de pessoas",
		deleteConfirmation: {
			title: "Excluir mesas",
			subtitle: "As mesas serão excluídas permanentemente. Deseja continuar assim mesmo?",
			actionText: "Excluir mesas",
		},
	},
	config: {
		selectFee: {
			person: "Por pessoa",
			table: "Por mesa",
		},
		selectTime: {
			minutes: "Minutos",
			hours: "Horas",
			days: "Dias",
		},
		shiftSelect: {
			to: "às",
		},
		bookingDays: {
			addTables: "Adicionar mesas",
		},
		seatArea: {
			title: "Ambiente",
			name: "Nome do ambiente",
			noShowFee: "Taxa de no-show",
			noShowTooltip: "Valor a ser cobrado de reservas que não compareceram.",
			advancedFee: "Cobrança antecipada",
			advancedTooltip: "Valor será cobrado durante a reserva.",
			description: "Breve descrição (até 45 caracteres)",
			delete: "Excluir ambiente",
			selectSeatArea: "Selecione os ambientes e defina as mesas",
		},
	},
	placeConfig: {
		title: "Informações do estabelecimento",
		postalCode: "CEP",
		street: "Endereço",
		streetPlaceholder: "Nome da rua ou avenida",
		neighborhood: "Bairro",
		state: "Estado",
		statePlaceholder: "UF",
		city: "Cidade",
		number: "Número",
		complement: "Complemento (opcional)",
		complementPlaceholder: "Complemento do endereço",
		phone: "Telefone",
		businessHours: "Horário de funcionamento",
		businessHoursPlaceholder: "Ex: De segunda à quinta das 17h às 22h",
	},
	bookingForm: {
		bookableTime: "Horário reservável",
		startTime: "Início",
		to: "até",
		endTime: "Fim",
		confirmDelete: {
			title: "Excluir horário",
			subtitle:
				"Este horário reservável será excluído permanentemente. Deseja continuar assim mesmo?",
			actionText: "Excluir horário",
		},
		addTime: "Novo horário reservável",
		deleteTime: "Excluir horário",
		submit: "Salvar alterações",
	},
	siteConfig: {
		title: "Personalização",
		description:
			"Você pode subir imagens de até 500kb nos formatos .png, .jpg ou .jpeg de acordo com as dimensões mencionadas abaixo:",
		brandImage: "Imagem da marca",
		brandImageObs: "Imagem quadrada ou 500x500 pixels",
		coverImage: "Imagem da capa",
		galleryImage: "Imagem para galeria (opcional)",
		horizontalImageObs: "Imagem horizontal ou 1280x780 pixels",
	},
	reserveConfigPage: {
		reserveConfig: "Configurações Gerais de Reserva",
		reserveConfigDescrip:
			"Configure o horário de funcionamento, intervalo entre as reservas, tempo limite de reserva, tolerância, cancelamento e adicione seus ambientes.",
		configCards: {
			businessHours: {
				title: "Horário de funcionamento",
				description:
					"Defina o horário de funcionamento do seu estabelecimento. Os horários das reservas irão respeitar o horário de funcionamento.",
			},
			toleranceTime: {
				title: "Limite de tolerância",
				description:
					"Até quanto tempo será aguardada a chegada da pessoa após o horário da reserva? ",
			},
			cancelationTime: {
				title: "Cancelamento",
				description:
					"Qual é o limite de tempo para o cliente solicitar o cancelamento da reserva?",
			},
			timeBetweenReservation: {
				title: "Intervalo entre as reservas",
				description: "Qual será o tempo do intervalo entre uma reserva e outra?",
			},
			minimumAdvanceTime: {
				title: "Tempo limite para reservar",
				description: "Até quanto tempo antes do horário poderá ser feita a reserva? ",
			},
		},
		intervals: {
			morning: "Manhã",
			afternoon: "Tarde",
			night: "Noite",
		},
		seatAreaConfig: "Configuração de ambientes",
		seatAreaConfigDescrip:
			"Adicione, descreva e defina as taxas dos ambientes do seu estabelecimento.",
		confirmationModal: {
			title: "Excluir ambiente",
			subtitle:
				"Este ambiente será excluído permanentemente. Deseja continuar assim mesmo?",
			actionText: "Excluir ambiente",
		},
		cancelModal: {
			title: "Descartar alterações",
			subtitle:
				"Suas alterações serão excluídas permanentemente. Deseja continuar assim mesmo?",
			actionText: "Descartar alterações",
		},
		addSeatArea: "Novo ambiente",
		requiredFields: "Campos com preenchimento obrigatório",
		cancel: "Cancelar",
		cancelSucess: "Suas alterações foram descartadas.",
		save: "Salvar alterações",
		sucess: "Suas alterações foram salvas.",
		error: "Suas alterações não foram salvas, tente novamente.",
	},
	generalConfig: {
		config: "Configurações gerais",
		makeAdjustments:
			"Faça os ajustes e configurações de horários e informações para comparilhar no link de reservas.",
	},
	shiftDefinition: {
		setShift: "Definição de turnos",
		configureShift:
			"Configure os turnos do seu estabelecimento. Você irá atribuí-los a dias da semana na configuração de calendário.",
		addShift: "Adicionar turno",
	},
	intervalReservation: {
		interval: "Intervalo entre reservas",
		setInterval:
			"Defina os intervalos de tempo para fazer uma reserva, limites de atraso e período de agendamento.",
		availableReservation: "Disponibilidade para reserva",
		reservationWindow: "Janela de intervalo no calendário",
		limit: "Tempo limite para atraso",
		limitInfo:
			"	O limite de atraso não gera cancelamento automático, apenas a sinalização na lista de reservas.",
	},
	storeDescriptionSession: {
		storeDescription: "Descrição da loja",
		describeStore:
			"Descreva sua loja para compartilhar as informações em seu site de reservas.",
		productLink: "Link do produto",
		acceptTerms:
			"Aceito compartilhar as informações dessa página com os clientes que visualizarem o site de reserva.",
		dayAndTime: "Dias e horários de funcionamento",
		addDayAndTime: "Adicionar dias e horários",
		rules: "Regras e condições",
		rulesPlaceholder:
			"Descreva aqui as regras, condições e orientações da sua política de reserva e cancelamento.",
		additionalInfo: "Informações adicionais",
		infoPlaceholdeR: "Se desejar, descreva mais informações sobre seu estabelecimento.",
		shareMenuReservation: "Compartilhar link para o cardápio no site de reservas?",
	},
	saveSettings: "Salvar configurações",
	saveChanges: "Salvar alterações",
	deleteShift: "Excluir turno",
	shiftName: "Nome do turno",
	shiftNameExemple: "Ex: Manhã, almoço, noturno, etc...",
	start: "Início",
	end: "Final",
	daysOfTheWeek: "Dias da semana",
	selectAvailableDays:
		"	É necessário selecionar os dias em que esse turno estará disponível para reserva.",
	calendarManagementDescription:
		"Defina a quantidade de lugares você disponibiliza para cada um de seus turnos. Edite sempre que precisar.",
	createReservation: "Criar reserva",
	modalConfigurations: {
		exclude: {
			title: "Tem certeza que deseja excluir?",
			text: "Ao excluir esses lugares, você perderá as definições feitas previamente.",
			confirmButton: "Sim,  quero excluir",
		},
		discard: {
			title: "Você quer descartar as alterações?",
			text: "Ao sair sem salvar, você não irá manter as alterações feitas agora.",
			confirmButton: "Sim,  quer descartar",
		},
		cancel: {
			title: "Cancelar reserva",
			text:
				"Tem certeza que deseja cancelar essa reserva? A exclusão será permanente.Lembre-se de entrar em contato com o cliente para informar o cancelamento.",
			confirmButton: "Sim, cancelar reserva",
		},
		accommodate: {
			title: "Acomodar",
			text:
				"As pessoas dessa mesa já chegaram? Confirme se está tudo certo para acomodar essa reserva. Essa ação não poderá ser desfeita.",
			confirmButton: "Sim,  acomodar",
		},
	},
	availableSeatsAmount: "Quantidade de lugares disponíveis",
	addMoreSeats: "Adicionar mais lugares",
	selectShift: "Seleção de turno",
	tables: "Mesas",
	seats: "Lugares",
	replicateForAllWeek: "Replicar para todos os dias da semana.",
	editOrDeleteLater: "Você pode excluir/editar de cada um dos dias depois, se preferir.",
};
