export const extract = {
	title: "Vendor Extract",
	placeAvaliable: "your available balance",
	vendorOwned: "due to vendors",
	anticipationBalance: "Your balance available for anticipation",
	toBeAnticipatedToVendors: "Available for anticipation from vendors",
	period: "Period",
	requiredField: "Required field",
	tabs: {
		withdraws: {
			title: "Financial requests",
			export: "Export to Excel",
			columns: {
				vendorName: "Vendor",
				totalAvailable: "Balance",
				totalRequestedValue: "Withdrawal Requests",
				type: {
					label: "Type",
					Withdraw: "Withdrawal",
					Anticipation: "Anticipation",
				},
			},
			sheet: {
				title: "Vendor Extract",
				filename: "vendor-extract",
				columns: {
					vendorName: "Vendor",
					requestDate: "Date",
					availableValue: "Balance",
					withdrawValue: "Withdrawal Request",
					anticipationValue: "Anticipation Request",
				},
			},
		},
		history: {
			title: "History",
			export: "Export to Excel",
			columns: {
				requestDate: "Date",
				vendorName: "Vendor",
				status: "Status",
				paymentMethod: "Payment Method",
				attachments: "Attachments",
				attachment: "Attachment",
				withdrawValue: "Value",
				anticipationValue: "Anticipation value",
				proRataFee: "Pro rata fee",
				type: "Type",
			},
			labels: {
				paid: "Paid",
				processing: "Processing",
				pending: "Pending",
				refused: "Refused",
			},
			sheet: {
				title: "Vendor Extract",
				filename: "vendor-extract",
				columns: {
					requestDate: "Date",
					vendorName: "Vendor",
					status: "Status",
					paymentMethod: "Payment Method",
					withdrawValue: "Value",
				},
			},
		},
		adjustments: {
			title: "Settings",
			export: "Export to Excel",
			add: "Add Adjustment",
			deleteAdjustmentSuccess: "Adjustment Deleted",
			sheet: {
				title: "Vendor Extract",
				filename: "vendor-extract",
				columns: {
					originalPayDate: "Eligibility Date",
					date: "Settlement Date",
					vendorName: "Vendor Name",
					title: "Name",
					obs: "Note",
					value: "Value",
				},
			},
			columns: {
				originalPayDate: "Eligibility Date",
				date: "Settlement Date",
				vendorName: "Vendor Name",
				title: "Name",
				obs: "Note",
				value: "Value",
			},
			import: {
				vendorId: "Vendor ID",
				title: "Adjustment name",
				value: "Value (in cents)",
				originalPayDate: "Eligibility Date",
				date: "Settlement Date",
				type: "Adjustment type",
				category: "Category",
				obs: "Observation",
				importButton: "Import adjusts",
				vendorName: "Vendor name",
				successNotification: "Adjusts imported successfully.",
				vendors: "Vendors",
				electricalValue: "Electrical",
				hydraulicsValue: "Hydraulics",
				cleaningValue: "Cleaning",
				otherValue: "Other",
				vendorIdTextError: "Error: Enter Vendor Id",
				vendorIdIsValidError: "Error: Enter a valid Vendor Id",
				titleTextError: "Error: Enter a name for the Adjust",
				titleIsValidError: "Error: Adjust name longer than 255 characters",
				valueZeroError: "Error: Enter an adjustment value other than 0",
				categoryError: "Error: Please enter a valid adjustment category",
				obsError: "Error: Adjustment obs with more than 255 characters",
				valueCentsError:
					"Attention! The value is given in cents, ex: 123 is equivalent to R$ 1.23",
			},
			total: "Total",
			modal: {
				deleteAdjustments: "Delete adjustments",
				wantToDelete: "Are you sure you want to delete this adjustment?",
				cantBeUndone: "This action cannot be undone",
				cancel: "Cancel",
			},
		},
	},
	adjustments: {
		add: "Add new setting",
		vendor: {
			label: "Vendor to launch adjustment",
			placeholder: "Select vendor to launch tweaks",
		},
		title: {
			label: "Adjustment name",
			placeholder: "Enter the name of the adjustment",
		},
		value: "Value",
		originalPayDate: "Eligibility Date",
		date: "Settlement Date",
		type: {
			label: "Adjustment Type",
			vendorShouldPay: "Paying to vendor",
			vendorShouldReceive: "Receivable from vendor",
		},
		obs: "Note",
		electricalValue: "Electrical",
		hydraulicsValue: "Hydraulics",
		cleaningValue: "Cleaning",
		otherValue: "Other",
		category: "Category",
		categoryPlaceholder: "Select a category",
	},

	withdraw: {
		title: "Withdrawal requests for {{name}}",
		requests: {
			title: "Requests",
			label: "Do you want to confirm the following withdrawal requests?",
			reject: "Reject selected",
			approve: "Approve selected",
			requestDate: "Requested on",
			availableValue: "balance",
			anticipationValue: "Anticipation Value",
			prorata: "Prorata Value",
			totalValue: "Total Value",
			type: {
				label: "Type",
				withdraw: "Withdraw",
				anticipation: "Anticipation",
			},
		},
		transference: {
			title: "Transfer",
			warning: "ATTENTION",
			message:
				"Requests made after 10:30 am (Brasília time) will only be answered the next day.",
			typeMethod: {
				label: "Transfer with",
				zigAvailable: "Use Zig Pay balance",
				other: "Other",
			},
			paymentMethod: {
				label: "Payment method",
				placeholder: "Enter payment method",
			},
			attachments: "Attachments / Vouchers",
			totalValue: "Payment amount",
		},
	},
};
