export const stepperInventoryControl = {
	steps: {
		config: "Configuração",
		count: "Listagem e contagem",
		adjustLoss: "Ajustes e perdas",
		resumeInventory: "Resumo do inventário",
		approval: "Aprovação",
	},
	stepsDescription: {
		count: "Salve o inventário durante o preenchimento para não perder o seu avanço",
		resumeInventory: "Revise os itens contados antes de enviar",
	},
	fieldRequired: "Campo obrigatório",
};
