import { isDev } from "#configuration/config";
import { useEffect, useState } from "react";
import { applyTheme, Envs } from "./helpers";

export const useChangeLayout = () => {
	const [layoutEnv, setLayoutEnv] = useState<Envs>("dev");

	const applyProdTheme = () => applyTheme("prod");
	const applyDevTheme = () => applyTheme("dev");

	useEffect(() => {
		if (!isDev) return;

		if (layoutEnv === "dev") {
			applyDevTheme();
		} else {
			applyProdTheme();
		}
	}, [layoutEnv]);

	const changeLayout = () => {
		setLayoutEnv(prev => (prev === "dev" ? "prod" : "dev"));
	};

	return {
		changeLayout,
	};
};
