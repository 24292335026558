export const irregularCmvProducts = {
	label: "Without Unit Cost",
	layout: {
		goBack: "Go back",
	},
	table: {
		columns: {
			productId: "Product ID",
			name: "Product",
			storage: "Storage",
			storageId: "Storage ID",
			fiscalCode: "SKU",
			lastCost: "Last Unit Cost",
			unitCost: "Unit Cost",
			edit: "Edit",
		},
		actions: {
			edit: "Edit unit cost",
		},
	},
	batchActions: {
		title: "Batch actions",
		actions: {
			useLastCost: "Use last cost",
			editCost: "Edit cost",
			editViaSpreadsheet: "Edit via spreadsheet",
		},
		caption: "Select products for batch actions",
		useLastCost: {
			title: "Use last cost",
			description: "The following products will be affected:",
			selectedProductsWithoutCost: "There are products without last cost selected",
		},
	},
	updating: {
		title: "Updating costs",
		subtitle: "Wait, we are updating the unit costs",
	},
	editForm: {
		title: "Edit cost",
		inputs: {
			product: "Product",
			storage: "Storage",
			lastCost: "Last Unit Cost",
			unitCost: "Unit Cost",
			save: "Save",
			cancel: "Cancel",
		},
		errors: {
			unitCostValueBiggerThanZero: "The unit cost must be greater than 0",
		},
	},
	spreadsheetExportModal: {
		title: "Edit cost",
		fileName: "produtos-cmv-irregular-{{timesamp}}",
		exportingSuccess: "Spreadsheet exported successfully!",
		exportButton: "Click here to download the spreadsheet",
		currencyWarning:
			"Attention! the value of the products is informed in cents, ex: 123 is equivalent to R$ 1.23",
		importButton: "Import spreadsheet",
		closeButton: "Close",
		successTitle: "Update successfully performed",
		successSubtitle: "Products have been updated",
		errorTitle: "Oops... Something didn't go as planned.",
		errorSubtitle: "We were unable to update the products. Please try again later.",
	},
};
