export const employees = {
	tabs: {
		employees: {
			label: "Equipe",
			search: "Pesquisar",
			filter: "Filtrar por locais",
			addModal: "Adicionar colaborador",
			importModal: {
				label: "Importar colaboradores",
				sheetFileName: "Colaboradores",
				buttonText: "Escolha uma planilha",
				role: "Cargo",
				places: "Locais e Bares",
				permissions: "Permissões",
				columns: {
					name: "Nome",
					password: "Senha",
					username: "Nome do usuário",
					fichasAmount: "Quantidade de vias em fichas",
					zigTag: "ZigTag",
				},
				selectPlace: "Ao menos um local deve ser selecionado",
				requiredField: "Esse campo é obrigatório",
				custom: "Personalizado",
				errorNotification: "Nenhuma planilha selecionada",
				passwordRequirements: `Funcionários que tenham a permissão "Acesso ao Dashboard" devem seguir as seguintes regras para senha: mínimo de 6 caracteres, pelo menos uma letra.`,
			},
			multipleEmployeeModalError: {
				success: "Criado com sucesso",
				successMessage: "Os funcionários de login {{logins}} foram criados",
				error: "Falhou",
				errorMessage:
					"Não foi possível criar os funcionários de login {{logins}}. Login já existente",
			},
			editButton: "Editar colaboradores",
			exportButton: "Exportar colaboradores",
			deleteButton: "Remover colaboradores",
			edit: {
				label: "Editar colaborador",
				data: {
					label: "Dados do colaborador",
					name: "Nome do colaborador",
					login: "Login",
					password: "Senha",
					zigCard: "Zig Card",
					employeeNumber: {
						label: "Adicionar mais de um colaborador",
						tooltip: "Apenas para mais de um colaborador dentro do mesmo cargo",
						start: "De",
						end: "Até",
					},
					photo: "Foto",
				},
				extra: {
					label: "Informações extras",
					fichasAmount: {
						label: "Quantidade de vias em ficha",
						placeholder: "Quant. de vias",
					},
					type: "Tipo de colaborador",
				},
				places: {
					label: "Locais",
					selectedPlace: "Locais",
					selectedBar: {
						label: "Bares",
						placeholder: "Nenhum",
					},
					mainBar: "Bar principal / ponto de venda",
				},
				role: {
					label: "Cargos e permissões",
					employeeRole: {
						label: "Cargo",
						placeholder: "Escolha um cargo",
						none: "Nenhum",
						custom: "Personalizado",
					},
					permissions: "Permissões",
				},
				seeProducts: {
					label: "Ver produtos dos bares",
					blockedBar: {
						label: "Bloquear bares",
						placeholder: "Nenhum",
					},
				},
			},
			editMultiple: {
				label: "Editar colaborador ({{count}})",
				rename: "Renomear",
				number: "Numerar a partir de",
				role: "Mudar cargo",
				places: "Locais",
				bar: "Bares",
				fichasAmount: "Quantidade de vias em ficha",
				info: "Campos não preenchidos terão seus valores iniciais mantidos",
				confirm: "Deseja modificar os seguintes colaboradores?",
				none: "Nenhum",
				mainBar: "Bar principal",
			},
			employeeDelete: {
				text:
					"Você realmente deseja deletar {{name}} ({{role}})'? Essa ação não pode ser desfeita",
				customized: "Cargo customizado",
			},
			deleteModal: {
				title: "Deseja remover {{count}} colaborador(es)?",
				content: "Atenção! Os colaboradores serão removidos permanentemente.",
			},
			checkAll: "Selecionar colaboradores ({{counter}})",
			uncheckAll: "Desmarcar todos colaboradores ({{counter}})",
			columns: {
				name: "Colaborador",
				username: "Login",
				role: "Cargo",
				places: {
					label: "Locais",
					none: "Nenhum local selecionado",
				},
				bar: {
					label: "Bar",
					none: "Nenhum bar selecionado",
				},
				zigtag: "ZigTag",
				custom: "Personalizado",
			},
			createEmployee: {
				fakeBarBlocklistPermission: "Restringir visualização de produtos do cardápio",
				noSelectedPlace: "Selecione um local para habilitar essa permissão",
				onlyOneSelect: "Para habilitar essa permissão, selecione apenas um local",
				validation: {
					name: "Insira um nome válido",
					login: "Insira um login válido",
					role: "Selecione um cargo",
					password: "Insira uma senha válida",
					same: "Os valores preenchidos estão iguais",
					twoValues: "Os dois valores devem ser preenchidos",
					negative: "Valores não podem ser negativos",
					initialValueSmaller: "Valor inicial deve ser menor que o final",
					selectedPlaces: "Pelo menos um local é necessário",
				},
				menuVisualization: {
					title: "Visualização do cardápio",
					label: "Tipos",
					placeholder: "Selecione o tipo de visualização",
					type: {
						grid: "Padrão",
						smallGrid: "Foto pequena",
					},
				},
			},
			importSuccess: "Colaboradores importados com sucesso",
			deleteWarning: "Ops! Um problema ocorreu, tente novamente",
			addEmployeeZigtagError: "Erro ao adicionar colaborador com a Zigtag",
		},
		roles: {
			label: "Cargos",
			search: "Pesquise por cargo",
			create: "Criar cargo",
			edit: "Editar cargo",
			removeRole: "Tem certeza que deseja remover o cargo?",
			roleError: "Cargo não encontrado, tente novamente",
			isNameInvalid: "Os campos não foram preenchidos corretamentes",
			columns: {
				role: "Cargo",
				employees: "Colaboradores",
				options: "Opções",
				none: "Nenhum",
			},
			modal: {
				roleName: {
					label: "Nome do cargo",
					error: "Nome inválido",
					placeholder: "Ex: Garçom",
				},
				importPermissions: {
					label: "Importar permissões",
					placeholder: "Ex: Caixa",
				},
				permissions: "Permissões",
				placesBar: {
					place: "Locais",
					chooseLocal: "Escolha um local",
					bar: "Bares",
					chooseBar: "Escolha um bar",
					none: "Nenhum",
					blocked: "Bloquear bares",
					chooseMinimum: "Escolha no mínimo um bar",
				},
			},
		},
	},
};
