import { ApiClient } from "./enterprise-generated";
import { getEnterpriseApiEndpoint } from "#configuration/api-endpoints";
import i18n from "../../i18n";

const enterprise = new ApiClient(getEnterpriseApiEndpoint());

const tz = Intl.DateTimeFormat().resolvedOptions().timeZone;
const lng = localStorage.getItem("lng") ?? i18n.language;

enterprise.extra.set("tz", tz);
enterprise.extra.set("lng", lng);

export default enterprise;
