import * as React from "react";
import { Select as SelectAntd } from "antd";
import {
	BaseOptionType,
	DefaultOptionType,
	OptionProps as OptionPropsAntd,
	SelectValue as SelectedValueAntd,
	SelectProps as SelectPropsAntd,
} from "antd/lib/select";
import classNames from "classnames";

import s from "./select.scss";

export type SelectProps<
	T = SelectedValueAntd,
	O extends DefaultOptionType | BaseOptionType = DefaultOptionType
> = SelectPropsAntd<T, O>;
export type OptionProps = OptionPropsAntd;
export type SelectedValue = SelectedValueAntd;

export interface CustomSelectProps<
	T = SelectedValue,
	O extends DefaultOptionType | BaseOptionType = DefaultOptionType
> extends SelectPropsAntd<T, O> {
	children?: React.ReactNode;
	name?: string;
}

export interface ICustomSelectFooterProps {
	isVisible?: boolean;
	message: string;
}

function CustomSelect<T, O extends DefaultOptionType | BaseOptionType>({
	children,
	className,
	...props
}: CustomSelectProps<T, O>) {
	return (
		<SelectAntd<T, O> className={classNames(s.customSelect, className)} {...props}>
			{children}
		</SelectAntd>
	);
}

function CustomSelectFooter({ isVisible = true, message }: ICustomSelectFooterProps) {
	if (!isVisible) {
		return null;
	}

	return <div className={s.customFooter}>{message}</div>;
}

Select.Option = SelectAntd.Option;
Select.OptGroup = SelectAntd.OptGroup;
Select.CustomFooter = CustomSelectFooter;

export function Select<
	T = SelectedValueAntd,
	O extends DefaultOptionType | BaseOptionType = DefaultOptionType
>(props: CustomSelectProps<T, O>) {
	return <CustomSelect<T, O> {...props} />;
}
