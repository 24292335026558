import { observer } from "mobx-react-lite";
import * as React from "react";

interface ISandwichMenuContext {
	isOpenSandwichMenu: boolean;
	onChangeIsOpenSandwichMenu(isOpen: boolean | ((isOpen: boolean) => void)): void;
	currentSelectedMenu: null | string;
}

export const SandwichMenuContext = React.createContext({} as ISandwichMenuContext);

interface ISandwichMenuProviderProps {
	children: React.ReactNode;
}

function _SandwichMenuProvider({ children }: ISandwichMenuProviderProps) {
	const [isOpenSandwichMenu, setIsOpenSandwichMenu] = React.useState(false);

	const currentSelectedMenu = !window.location.pathname
		? null
		: window.location.pathname + (window.location.hash ?? "");

	return (
		<SandwichMenuContext.Provider
			value={{
				isOpenSandwichMenu: isOpenSandwichMenu,
				onChangeIsOpenSandwichMenu: setIsOpenSandwichMenu,
				currentSelectedMenu,
			}}
		>
			{children}
		</SandwichMenuContext.Provider>
	);
}

export const SandwichMenuProvider = observer(_SandwichMenuProvider);

export function useSandwichMenu() {
	return React.useContext(SandwichMenuContext);
}
