export const pos = {
	title: "POS sales",
	sidebar: {
		title: "Total of {{month}}",
		day: "Day",
		total: "Total",
		credit: "Credit",
		debit: "Debit",
		clear: "Clean",
		info: "Select one or more dates to see details.",
	},
	methodTranslate: {
		credit: "Credit",
		debit: "Debit",
	},
	selectDate: "Select a day to view transactions",
	operations: {
		title: "Operations",
		search: "Search by flag, digits or value",
		columns: {
			date: "Date and time",
			brand: "Flag",
			method: "Method",
			lastDigits: "Digits",
			value: "Value",
		},
	},
	others: "Others",
};
