export const withdrawConfirmation = {
	modal: {
		title: "Withraw request confirmation",
		message:
			"To confirm the withdraw request, enter the code sent to the confirmation method configured in the place.",
		codeInput: { label: "Code", placeholder: "Type here the confirmation code" },
		resendCodeBtnLabel: "I didn't receive my code",
	},
	form: {
		errors: {
			onlyNumbers: "The code must contain only numbers",
			minLength: "The code must have at least 6 digits",
		},
		submit: {
			success: "Request was successfully approved",
			error: "Invalid or expired code",
		},
	},
	resendConfirmationSuccessfully: "Code was resent successfully",
	notification:
		"Your request was processed successfully, but needs to be confirmed using the code sent to the confirmation contact configured in the place settings.",
};
