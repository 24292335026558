import { isValidElement, ReactNode } from "react";

export function extractTextFromReactNode(node: ReactNode): string {
	if (typeof node === "string" || typeof node === "number") {
		return node.toString();
	}

	if (Array.isArray(node)) {
		return node.map(extractTextFromReactNode).join("");
	}

	if (isValidElement(node) && node.props.children) {
		return extractTextFromReactNode(node.props.children);
	}

	return "";
}
