import * as React from "react";
import RootStore from "#stores/index";
import {
	EnterprisePermissions,
	ResultPermissions,
} from "#resources/helpers/permissions/utils";

type PermissionHookArgs = ResultPermissions & {
	permissionCheckMode?: "some" | "every";
};

export const usePermission = (args?: PermissionHookArgs) => {
	const { authStore, routerStore } = React.useContext(RootStore);
	const [isValid, setIsValid] = React.useState(false);

	const memoizedArgs = React.useMemo(() => args, []);

	const { permissions, redirectOnError, permissionCheckMode }: PermissionHookArgs = {
		permissions: [],
		redirectOnError: false,
		permissionCheckMode: "every",
		...(memoizedArgs ?? {}),
	};

	React.useEffect(() => {
		const permissionCheck =
			permissionCheckMode === "some"
				? permissions.some(verifyPermission)
				: permissions.every(verifyPermission);

		if (!permissionCheck) {
			args?.onError?.(permissions);
			if (redirectOnError) routerStore.push("/");
		}
		setIsValid(permissionCheck);
	}, [memoizedArgs, permissions]);

	function verifyPermission(permissionName: EnterprisePermissions): boolean {
		if (!authStore.currentEmployee) return false;

		const foundPermission = authStore.currentEmployee.permissions.find(
			permission => permission.slug === permissionName,
		);

		return Boolean(foundPermission);
	}

	function hasPermissionGrouped<T extends EnterprisePermissions[]>(
		permissionName: T,
	): { [K in T[number]]: boolean } {
		const obj = permissionName.reduce((prev, curr) => {
			return {
				...prev,
				[curr]: verifyPermission(curr),
			};
		}, {} as { [K in T[number]]: boolean });

		return obj;
	}

	return {
		hasPermission: verifyPermission,
		hasPermissionGrouped,
		isValid,
	};
};
