export const sellChip = {
	title: "Chips sold at the event",
	addCollaborator: "Add contributor",
	placeholder_one: "Select the contributor",
	placeholder_other: "Select collaborators",
	noChipsFound: "No chips found",
	oneChipFound: "A chip found",
	chipsFound: "{{num}} chips found",
	searchPlaceholder: "Search by customer or product",
	columns: {
		date: "Date",
		employee: "Employee",
		clients: "Customers",
		products: "Products",
		value: "Value",
		refunded: "Refunded",
		actionButton: "Refund chip",
	},
	modal: {
		title: "Refund {{client}} chip",
		okText: "Refund",
		product: "Product",
		products: "Products",
		reason: "Reason",
		reasonRequired: "Reason required.",
		reasonPlaceholder: "Enter the reason for the refund",
		shouldReturnToStorage: "Should it return to storage?",
		reasonError: "This field cannot be empty",
		returnToStorage: "Return product to storage",
	},
};
