import { abcPurchaseReport } from "./abc-purchase-report";
import { abcSupplierReport } from "./abc-supplier-report";

export const abcReports = {
	abcPurchaseReport,
	abcSupplierReport,
	header: {
		filter: "Filter by period",
		searchPlaceholderProducts: "Search by product or input",
		searchPlaceholderSuppliers: "Search by supplier",
	},
};
