import { useInfiniteQuery } from "@tanstack/react-query";
import { NOTIFICATIONS_KEYS } from "./keys";
import enterprise from "#resources/api/enterprise-client";

const PAGE_SIZE = 10;

export const useListUnreadNotifications = () => {
	return useInfiniteQuery({
		queryKey: NOTIFICATIONS_KEYS.listUnreadNotifications,
		queryFn: ({ pageParam = 1 }) =>
			enterprise.listUnreadDashboardNotificationsForEmployee({
				pagination: {
					currentPage: pageParam as number,
					perPage: PAGE_SIZE,
				},
			}),
		getNextPageParam: lastPage => {
			const { currentPage, lastPage: lastPageNumber } = lastPage.pagination;
			const nextPage = currentPage + 1;
			return nextPage <= lastPageNumber ? nextPage : undefined;
		},
		initialPageParam: 1,
		staleTime: 0,
	});
};

export const useListReadNotifications = () => {
	return useInfiniteQuery({
		queryKey: NOTIFICATIONS_KEYS.listReadNotifications,
		queryFn: ({ pageParam = 1 }) =>
			enterprise.listReadDashboardNotificationsForEmployee({
				pagination: {
					currentPage: pageParam as number,
					perPage: PAGE_SIZE,
				},
			}),
		getNextPageParam: lastPage => {
			const { currentPage, lastPage: lastPageNumber } = lastPage.pagination;
			const nextPage = currentPage + 1;
			return nextPage <= lastPageNumber ? nextPage : undefined;
		},
		initialPageParam: 1,
		enabled: false,
		staleTime: 0,
	});
};
