export const infos = {
	goBack: "Back to lists",
	import: "Import spreadsheet",
	addGuest: "Add guest",
	edit: "Edit information",
	download: "Download list",
	print: "Print out",
	search: "Search for guest or promoter",
	entrances: {
		label: "Associated entry",
		empty: "No associated entry",
		error: "No entry associated with this list",
	},
	couvert: "Couvert discount",
	expires: "Expiration",
	description: {
		label: "Description",
		empty: "No description added",
	},
	guest: "Guest",
	guests: "Guests",
	editInfos: "Edit list information",
	editGuest: "Edit guest",
	emptyList: {
		defaultMessage: "Looks like you haven't registered any guests",
		text: "The guest list will appear here when you have registered guests.",
		importList: "Import list",
	},
	editModal: {
		title: "Edit list",
		new: "New list",
		name: {
			label: "List name",
			placeholder:
				"Specify your list so that it quickly identifies (list name - event - date - location)",
		},
		expiresAt: "List expiration",
		color: "Assign a color",
		couvert: "Couvert discount",
		entrance: {
			label: "Associated entry",
			placeholder: "Type or select the entry type",
		},
		description: {
			label: "Description",
			placeholder: "Add a note to your list if you want",
			limit: "{{limit}} characters left",
		},
		errors: {
			emptyName: "Enter the name of the list",
			length: "List name must be less than 100 characters",
			expires: "Expired date and time",
			description: "List description must be less than 280 characters",
		},
		save: "Save edit",
	},
	addGuestModal: {
		name: {
			label: "First name and last name",
			placeholder: "Customer's full name",
			error: "Invalid name",
		},
		cpf: {
			label: "CPF",
			error: "Invalid CPF",
		},
		phone: "Phone",
		promoter: {
			label: "Promoter",
			placeholder: "Enter the promoter's name",
		},
	},
	columns: {
		name: "Name",
		cpf: "CPF",
		phone: "Phone",
		promoter: "Promoter",
		actions: "Actions",
		emptyText: "No data to display.",
	},
};
