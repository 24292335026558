/* eslint-disable max-len */
import { ConciliationTranslations } from "./conciliation-pt-br";

export const CONCILIATION_EN_US: ConciliationTranslations = {
	pageTitle: "OFX Conciliation",
	pageParagraph:
		"By downloading the statement of your checking or savings account in OFX file, available from major banks, and importing it into the dashboard, you save time managing your business's money.",
	importModal: {
		title: "OFX Import",
		accountBankNotRegisteredTitle: "Bank account not registered",
		triggerButton: "Import OFX file",
		cancelButton: "Cancel",
	},
	uploadOfxForm: {
		inputInstruction: "Click or drag the OFX file",
		fileType: "OFX File",
		description:
			"Export the OFX file from your account containing only the transactions so that no incorrect transactions are generated",
		submitButton: "Import",
		validation: {
			fileRequired: "The OFX file is required",
			fileSize: "The OFX file must be a maximum of 5 MB",
		},
	},
	submitOfxForm: {
		nameFile: "File name:",
		periodImported: "Imported period:",
		periodImportedValue: "{{start}} to {{end}}",
		bankName: "Bank institution:",
		labelCurrency: "Final balance on {{date}}",
		labelCheckValue: "I confirm the value of {{value}} at the end of this statement",
		labelAccounts: "Select an account:",
		placeholderAccount: "Click to select",
		accountRequired: "Select an account",
		submitButton: "Confirm value",
		alerts: {
			accountNotCreated: {
				title: "Bank account not found",
				description:
					"We did not identify this bank account among the registered ones. <a>Click here</a> to register <b>or select an already registered account below</b> to proceed with the OFX import.",
			},
			accountNotValidated: {
				title: "This account has not been validated yet.",
				description:
					"This bank account was registered with the name {{name}} but its data has not been validated yet. We will use the data exported in this OFX to perform automatic validation.",
			},
		},
	},
	feedbackOfxStep: {
		success: {
			title: "Import successful",
			itemsImported: "{{send}} transactions were imported",
			balance: "Final balance of {{balance}} on {{date}}",
		},
		error: {
			title: "Oops... Something didn't go as planned",
			description: "We couldn't complete the OFX import. Please try again later.",
		},
		confirmButton: "Concile transactions",
	},
	redirectBankFlow: {
		description:
			"Registering the bank account is a necessary action before importing the OFX.",
		redirectButton: "Register account",
	},
	tabs: {
		pendingConciliations: "Pending Conciliations",
		history: "History",
		description: {
			pendingConciliations: "Import details",
			history: "Conciliation history",
		},
		pendingTab: {
			emptyState: {
				title: "There are no pending conciliations at the moment",
				description: "Import a new OFX file to concile new transactions",
			},
		},
		historyTab: {
			emptyState: {
				title: "No conciliation found",
			},
		},
	},
	collapse: {
		branch: "Branch",
		account: "Account",
		importedDate: "Imported period",
		importedDateDescription: "From {{since}} to {{until}}",
		conciliationDate: "Conciliation date",
		actions: {
			undoConciliation: "Undo conciliation",
		},
		unConciliatedAmount: "{{amount}} not conciled",
		item: {
			value: "Value",
			date: "Date",
			description: "Description",
			chartOfAccount: "Chart of accounts",
			paymentMethod: "Payment method",
			status: {
				conciliated: "Conciled",
				refused: "Ignored",
			},
			tooltip: {
				search: "Search transaction",
				addAccount: "Add account",
				ignoreTransaction: "Ignore transaction",
			},
			accountType: {
				CheckingAccount: "Checking account",
				SavingsAccount: "Savings account",
			},
			paymentTypes: {
				CREDIT: "Credit",
				DEBIT: "Debit",
				INT: "INT",
				DIV: "DIV",
				FEE: "FEE",
				SRVCHG: "SRVCHG",
				DEP: "DEP",
				ATM: "ATM",
				POS: "POS",
				XFER: "XFER",
				CHECK: "Check",
				PAYMENT: "PAYMENT",
				CASH: "Cash",
				DIRECTDEP: "DIRECTDEP",
				DIRECTDEBIT: "Direct Debit",
				REPEATPMT: "REPEATPMT",
				OTHER: "Others",
				CreditCard: "Credit Card",
				DebitCard: "Debit Card",
				Pix: "Pix",
			},
		},
		cancel: "Cancel",
		complete: "Complete conciliation",
	},
	transactionSuccessNotification: "Transaction updated successfully!",
	cancelButton: "Cancel",
	confirmButton: "Confirm",
	conciliateConciliation: {
		title: "Complete conciliation",
		description:
			"By completing the conciliation, the ignored transactions will be <b>permanently deleted</b> from your conciliations. Do you want to continue?",
		error: "There are still pending transactions!",
		success: "Conciliation successfully completed!",
	},
	ignoreTransactionModal: {
		title: "Ignore Transaction",
		description: "Are you sure you want to ignore the transaction <b>{{name}}</b>?",
	},
	conciliationModal: {
		title: "Search transaction",
		contentTitle: "Import information",
		labelValue: "Value",
		labelPaymentDate: "Payment date",
		labelReceiptDate: "Receipt date",
		labelDescription: "Description",
		labelChartAccount: "Chart of accounts",
		labelPaymentMethod: "Payment method",
		buttonCancel: "Cancel",
		buttonConfirm: "Concile",
		valuesAreDifferentAlert:
			"The transaction value is different from the selected value and cannot be conciled",
		table: {
			emptyIsFetched: "No account found",
			emptyCreatePaymentButton: "Create payment",
			emptyCreateReceiptButton: "Create receipt",
			emptyInitial: {
				title: "Use the searches to start",
				subtitle: "Search and select a transaction to concile the transaction below.",
			},
			paymentDate: "Date",
			receiptDate: "Date",
			chartAccounts: "Chart of accounts",
			paymentMethod: "Payment method",
			value: "Value",
		},
	},
	undoIgnoreTransactionModal: {
		button: "Undo deletion",
		title: "Undo deletion",
		description:
			"Are you sure you want to undo the deletion of the transaction <b>{{name}}</b>?",
	},
	undoConciliationTransactionModal: {
		button: "Undo conciliation",
		title: "Undo conciliation",
		description:
			"Are you sure you want to undo the conciliation of the transaction <b>{{name}}</b>?",
	},
};
