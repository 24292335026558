import enterprise from "#api/enterprise-client";
import { showErrorNotification } from "#helpers/notifications";

import { RootStore } from ".";
import { INTERVAL_TIME } from "./live";
import {
	GetTableReportResponse,
	TableProductReport,
} from "#resources/api/enterprise-generated";
import { fetchModel } from "#resources/helpers/fetch-model";

export class TableStore {
	public rootStore: RootStore;
	private intervalId: null | number = null;
	private listenedEventId: null | string = null;

	constructor(rootStore: RootStore) {
		this.rootStore = rootStore;
	}

	public clean() {
		this.getTableReport.reset();
		this.getLiveTableReport.reset();
		this.getTableProductReport.reset();
	}

	private async update() {
		if (this.listenedEventId) {
			const eventId = this.listenedEventId;
			await this.getLiveTableReport.fetch({ eventId });
		} else if (this.intervalId !== null) {
			this.stopListening();
		} else {
			throw new Error(
				"Invalid TableStore.update state: listenedEventId is null, but intervalId is not",
			);
		}
	}

	public startListening(eventId: string) {
		this.stopListening();

		this.listenedEventId = eventId;
		this.intervalId = window.setInterval(() => this.update(), INTERVAL_TIME);

		this.update();
	}

	public stopListening() {
		if (this.intervalId !== null) {
			window.clearInterval(this.intervalId);
			this.intervalId = null;
		}

		this.listenedEventId = null;
		this.clean();
	}

	public getTableReport = new fetchModel<
		{ eventId?: string; placeId?: string; since?: Date; until?: Date },
		GetTableReportResponse
	>({
		fnPromise: args => enterprise.getTableReport(args),
		onError: err => showErrorNotification(err.message),
	});

	public getLiveTableReport = new fetchModel<
		{ eventId?: string; placeId?: string; since?: Date; until?: Date },
		GetTableReportResponse
	>({
		fnPromise: args => enterprise.getTableReport(args),
		onError: err => showErrorNotification(err.message),
	});

	public getTableProductReport = new fetchModel<
		{ eventId?: string; placeId?: string; since?: Date; until?: Date },
		TableProductReport[]
	>({
		fnPromise: args => enterprise.getTableProductReport(args),
		onError: err => showErrorNotification(err.message),
	});
}
