export const contentFiscalData = {
	fieldName: {
		ncm: "NCM",
		cest: "CEST",
		fiscalProfile: "Tax profile",
		fiscalGroup: "Tax group",
		fiscalCode: "SKU",
	},
	placeholder: {
		ncm: "Insert NCM",
		cestInput: "Insert CEST",
		cestSelect: "Select CEST",
		fiscalProfile: "Select tax profile",
		fiscalGroup: "Select tax group",
		fiscalCode: "Insert SKU",
	},
	infoMessageMyTapp:
		"It is not possible to deactivate this function while “Sync with My Tapp” is active.",
	requiredField: "Required field",
	validationMessage: {
		ncm: "NCM must contain 8 digits",
		cest: "The CEST must contain 7 digits",
		fiscalCode: "If not filled in, the code will be generated automatically.",
		patternFiscalCode: "Only numbers, letters, period and hyphen",
	},
	infoMessageManualcest: "Add manual CEST?",
};
