export const paymentMethodReport = {
	titleBar: "Bar payment method",
	titleEmployee: "Employee payment method",
	info:
		"The values in this report may have been estimated and therefore there may be small differences",
	sheet: {
		titleBar: "Bar payment method",
		filenameBar: "bar-payment-method",
		titleEmployee: "Employee payment method",
		filenameEmployee: "employee-payment-method",
	},
	columns: {
		App: "App",
		Cash: "Cash",
		DebitCard: "Debit",
		CreditCard: "Credit",
		Voucher: "Voucher",
		ZigPosDebit: "Zig Debit",
		ZigPosCredit: "Zig Credit",
		PostEvent: "Post Event",
		Dashboard: "Dashboard",
		Anticipated: "Anticipated",
		DeliveryOnline: "Online Delivery",
		Consumption: "Consumption",
		Ame: "Ame",
		Rappi: "Rappi",
		Uber: "Uber",
		Ifood: "Ifood",
		Bonus: "Bonus",
		Pix: "Pix",
		Opened: "Opened",
		Other: "Other",
		ZigPosCreditInInstallments: "Zig Credit in Installments",
		IntegratedFoodVoucher: "Food Voucher",
		name: "Name",
		total: "Total",
	},
};
