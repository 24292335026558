import enterprise from "#resources/api/enterprise-client";
import {
	Pagination,
	RechargeFilters,
	RechargeRequestFilters,
	RechargesPaginated,
} from "#resources/api/enterprise-generated";
import { fetchModel } from "#resources/helpers/fetch-model";
import { showErrorNotification } from "#resources/helpers/notifications";
import { RootStore } from ".";

export class RechargesStore {
	public rootStore: RootStore;

	constructor(rootStore: RootStore) {
		this.rootStore = rootStore;
	}

	public clean() {
		this.getAllRecharges.reset();
	}

	public getAllRecharges = new fetchModel<
		{
			filters: RechargeRequestFilters;
			pagination: Pagination;
		},
		RechargesPaginated
	>({
		fnPromise: args => enterprise.getAllRecharges(args),
		onError: error => showErrorNotification(error.message),
	});

	public getAllRechargeIds = new fetchModel<
		{
			filters: RechargeRequestFilters;
		},
		string[]
	>({
		fnPromise: args => enterprise.getAllRechargeIds(args),
		onError: error => showErrorNotification(error.message),
	});

	public alterRechargeExpirations = new fetchModel<
		{
			rechargeIds: string[];
			expiresAt: Date;
		},
		void
	>({
		fnPromise: args => enterprise.alterRechargeExpirations(args),
		onError: error => showErrorNotification(error.message),
	});

	public addRechargesToPlace = new fetchModel<
		{
			rechargeIds: string[];
			placeIds: string[];
		},
		void
	>({
		fnPromise: args => enterprise.addRechargesToPlace(args),
		onError: error => showErrorNotification(error),
	});

	public getAllRechargeFilters = new fetchModel<
		{
			eventId?: string | null;
			placeId?: string | null;
		},
		RechargeFilters
	>({
		fnPromise: args => enterprise.getAllRechargeFilters(args),
		onError: error => showErrorNotification(error),
	});
}
