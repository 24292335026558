import { isMain } from "./config";

export function getEnterpriseApiEndpoint() {
	const url = process.env.ZIG_API_URL;

	if (!url) throw new Error("env ZIG_API_URL not found");

	return url;
}

export function getDeliveryStoreApiEndpoint() {
	if (isMain) {
		return {
			auth: "https://api-auth.delivery.zigcore.com.br",
			client: "https://api-client.delivery.zigcore.com.br",
			order: "https://api-order.delivery.zigcore.com.br",
			partner: "https://api-partner.delivery.zigcore.com.br",
			store: "https://api-store.delivery.zigcore.com.br",
			reservation: "https://api-reservation.delivery.zigcore.com.br/",
		};
	}

	return {
		auth: "https://dev-api-auth.delivery.zigpay.dev",
		client: "https://dev-api-client.delivery.zigpay.dev",
		order: "https://dev-api-order.delivery.zigpay.dev",
		partner: "https://dev-api-partner.delivery.zigpay.dev",
		store: "https://dev-api-store.delivery.zigpay.dev",
		reservation: "https://dev-api-reservation.delivery.zigpay.dev/",
	};
}
