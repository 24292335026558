export const storage = {
	zeroProducts: {
		noProproductIsSelected: "No product selected",
	},
	removeProduct: {
		noProproductIsSelected: "No product selected",
	},
	layout: {
		goBack: "Back",
	},
	indicators: {
		cost: {
			title: "Total Inventory Amount",
		},
		turnover: {
			high: "High turnover",
			low: "Low turnover",
		},
		zeroed: {
			title: "Zero count products",
			seeMore: "See More",
		},
		intervalNotice: "Values referring to the last 90 days",
		updatedAt: "Updated on {{date}}",
	},
	modalXML: {
		title: "Import XML",
		warningMessage: {
			line1:
				"Select the target stock, download the example spreadsheet, fill it in and import it again.",
			line2:
				"Attention! The value of the products is informed in cents. Ex: 123 equals {{currencySymbol}} 1.23",
		},
		form: {
			label: {
				storage: "Stock",
				xml: "XML",
			},
			placeholder: {
				xml: "Choose an .xml file to import",
			},
			validation: {
				missingField: "Required field",
			},
			submit: "Import XML",
		},
	},
	modalManualChange: {
		title: {
			add: 'Add product "{{prodName}}"',
			subtract: 'Remove product "{{prodName}}"',
		},
		okText: {
			add: "Add Product",
			subtract: "Remove Product",
		},
		cancel: "Cancel",
		message: {
			add: "Type the quantity of product you want to add and the cost price",
			subtract: "Enter the amount you want to remove and a note if necessary",
		},
		form: {
			hasProductionRuleWarning:
				"This product has a technical sheet and its items will be reduced from stock only when they are with no stock",
			label: {
				quantity: "Quantity",
				costPrice: "Cost price",
				obs: "Observation",
			},
			validation: {
				invalidQuantity: "Invalid quantity",
				missingProductInfo: "Missing product information",
				missingActionInfo: "Missing action info",
			},
		},
	},
	header: {
		searchPlaceHolder: "Search by product",
		importXML: "Import XML",
		transferProducts: "Transfer products",
		addProducts: "Add products",
	},
	table: {
		columns: {
			name: "product",
			category: "category",
			quantity: "quantity",
			action: "action",
			lastCost: "Last price",
			avgCost: "Average price",
			totalCost: "Total cost",
		},
		actions: {
			zeroStorage: "Zero stock",
			removeProducts: "Remove products",
			label: "Batch actions",
			message: "Select the check box for batch actions",
		},
		confirmationModal: {
			warnignHiddenProductsInSearch:
				"Warning, some products may be hidden because of the search, clear the search to continue",
			deleteSingle: {
				title: "Remove Product",
				message:
					"By confirming the action you will permanently remove these items from the Inventory. Do you want to continue?",
				confirmMessage: "Yes, I want to remove",
			},
			deleteMultiple: {
				title: "Remove Product",
				message:
					"By confirming the action you will permanently remove these items from the Inventory. Do you want to continue?",
				confirmMessage: "Yes, I want to remove",
			},
			zero: {
				title: "Zero products",
				message:
					"By confirming the action you permanently reset all selected items from the Inventory. Do you want to continue?",
				confirmMessage: "Yes, I want to reset the products",
			},
		},
	},
};
