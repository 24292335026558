import * as React from "react";
import RootStore from "#stores/index";

export function useTabUrl({ initialKey }: { initialKey: string }) {
	const urlTab = document.location.hash.split("#")[1] ?? "";
	const [currentTab, setCurrentTab] = React.useState(urlTab || initialKey);

	const { routerStore } = React.useContext(RootStore);

	const onChangeTab = (tab: string) => {
		routerStore.push(`${window.location.pathname}#${tab}`);
		setCurrentTab(tab);
	};

	React.useEffect(() => {
		const hash = routerStore.location.hash.replace("#", "");

		if (hash) {
			onChangeTab(hash);
		}
	}, [routerStore.location.hash]);

	return {
		defaultActiveKey: urlTab || initialKey,
		onChangeTab,
		currentTab,
	};
}
