export function jobExecutor<Iterable, PromiseReturn extends any>(
	iterableInformation: Iterable[],
	iterableToPromise: (iterable: Iterable) => Promise<PromiseReturn>,
	onSuccessHook?: (iterable: Iterable, promiseReturn: PromiseReturn) => void,
	onErrorHook?: (err: any, iterable: Iterable) => void,
	maxParallelLimit: number = 5,
) {
	return new Promise<void>((resolve, _reject) => {
		let promisesFinished = 0;
		let backlog = [...iterableInformation];

		const prefill = () => {
			for (let i = 0; i < maxParallelLimit; i++) {
				addJobToQueue();
			}
		};

		const addJobToQueue = () => {
			if (promisesFinished === iterableInformation.length) {
				finish();
				return;
			}
			if (backlog.length === 0) return;

			const backlogFirst = backlog.shift();
			try {
				const jobPromise = iterableToPromise(backlogFirst!);
				jobPromise
					.then(promiseRet => {
						if (onSuccessHook) onSuccessHook(backlogFirst!, promiseRet);
						promisesFinished++;
						addJobToQueue();
					})
					.catch(err => {
						if (onErrorHook) onErrorHook(err, backlogFirst!);
						promisesFinished++;
						addJobToQueue();
					});
			} catch (err) {
				if (onErrorHook) onErrorHook(err, backlogFirst!);
				promisesFinished++;
				addJobToQueue();
			}
		};

		const finish = () => {
			resolve();
		};

		prefill();
	});
}
