import { TypeTagLabel } from "#components/primitive";
import i18n from "#i18n/index";
import {
	DashboardNotificationClassificationEnum,
	DashboardNotificationPaginated,
	DashboardNotificationTagsEnum,
	Pagination,
} from "#resources/api/enterprise-generated";
import { InfiniteData } from "@tanstack/react-query";

const t = i18n.t;

export type NotificationsTabs = "read" | "unread";

export interface NotificationsContextData {
	unreadNotifications?: InfiniteData<DashboardNotificationPaginated>;
	readNotifications?: InfiniteData<DashboardNotificationPaginated>;
	showNotifications: boolean;
	currentTab: NotificationsTabs;
	hasUnreadNotifications: boolean;
	onChangeTabs: (tab: NotificationsTabs) => void;
	onCloseNotifications: () => void;
	onOpenNotifications: () => void;
	onShowMoreReadNotifications: () => void;
	onShowMoreUnreadNotifications: () => void;
}

export const CLASSIFICATION_LABEL: Record<
	DashboardNotificationClassificationEnum,
	string
> = {
	alert: t("components:notifications.classification.alert").toUpperCase(),
	soon: t("globalKeys:comingSoon").toUpperCase(),
	release: t("components:notifications.classification.release").toUpperCase(),
	warning: t("components:notifications.classification.warning").toUpperCase(),
};

export const CLASSIFICATION_TAG_TYPE: Record<
	DashboardNotificationClassificationEnum,
	TypeTagLabel
> = {
	alert: "error",
	soon: "info",
	release: "primary",
	warning: "warning",
};

export const LEVEL_LABEL: Record<DashboardNotificationTagsEnum, string> = {
	app: t("components:notifications.level.app").toUpperCase(),
	organization: t("components:notifications.level.organization").toUpperCase(),
	place: t("components:notifications.level.place").toUpperCase(),
	event: t("components:notifications.level.event").toUpperCase(),
	pdv: t("components:notifications.level.pdv").toUpperCase(),
};

export const DEFAULT_PAGINATION: Pagination = {
	currentPage: 1,
	perPage: 10,
};
