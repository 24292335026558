import classNames from "classnames";
import * as React from "react";
import s from "./card-header.scss";

export interface ICardHeaderProps extends React.HTMLAttributes<HTMLDivElement> {
	className?: string;
	title?: string;
}

export function CardHeader({ children, className, title, ...props }: ICardHeaderProps) {
	return (
		<header className={classNames(s.header, className)} {...props}>
			{title && <h4>{title}</h4>}

			{children}
		</header>
	);
}
