export const storageList = {
	layout: {
		title: "Lista de estoques",
	},
	header: {
		searchPlaceHolder: "Buscar por estoque",
		importXML: "Importar XML",
		createStorage: "Criar Estoque",
	},
	indicators: {
		cost: {
			title: "Valor total em estoque",
			footer: {
				localStorage: "Estoque local",
				centralStorage: "Estoque Central",
			},
		},
		turnover: {
			high: "Maior giro",
			low: "Menor giro",
		},
		zeroed: {
			title: "Produtos zerados",
			seeMore: "Ver Mais",
		},
		intervalNotice: "Valores referentes aos ultimos 90 dias",
		updatedAt: "Atualizado em {{date}}",
	},
	list: {
		local: "Local",
		central: "Geral",
		rename: "Renomear estoque",
		delete: "Excluir",
		valueInStorage: "Valor em estoque",
		productsInStorage: "Produtos em estoque",
		productsInStorageCount: "{{count}} produtos",
		createdAt: "Criado em {{date}}",
	},
	modalXml: {
		title: "Importar XML",
		warningMessage: {
			line1:
				"Selecione o estoque de destino, baixe a planilha exemplo, preencha e importe novamente.",
			line2:
				"Atenção! O valor dos produtos é informado em centavos. Ex: 123 equivale a {{currencySymbol}} 1,23",
		},
		form: {
			label: {
				storage: "Estoque",
				xml: "XML",
			},
			placeholder: {
				xml: "Escolha um arquivo .xml para importar",
			},
			validation: {
				missingField: "Campo obrigatório",
			},
		},
	},
	modalForm: {
		title: {
			createStorage: "Criar Estoque",
			editarStorage: "Editar Estoque",
		},
		form: {
			label: {
				name: "Nome",
				isCentral: "Tipo de estoque",
			},
			options: {
				central: "Central",
				local: "Local",
			},
			placeholder: {
				name: "Nome do estoque",
			},
			cancel: "Cancelar",
			submit: "Salvar",
		},
	},
	modalDelete: {
		title: "Remover Estoque",
		warningMessage: 'Tem certeza que deseja remover o estoque "{{storageName}}" ?',
		submit: "Remover",
	},
};
