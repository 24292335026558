export const pixTypeInput = {
	pixKeyLabel: "Chave Pix",
	pixTypeLabel: "Tipo de Chave PIX",
	pixTypePlaceholder: "Selecione o tipo de Chave PIX",
	pixKeyType: {
		random: "Aleatória",
		phone: "Telefone",
		copyPaste: "Copia e Cola",
	},
};
