import { taxTabs } from "./tax-tabs";
import { components } from "./components";

export const fiscalData = {
	components,
	taxTabs,
	titlePage: "Dados Fiscais",
	titlesTabs: {
		taxCouvert: "Dados fiscais de couvert",
		taxGroups: "Grupos fiscais de produto",
		taxProfiles: "Perfis fiscais",
		minimumConsummation: "Consumação mínima",
		recharge: "Recarga",
	},
};
