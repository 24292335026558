export const transferForm = {
	loadingTargetPlaceData: "Carregando dados do place de destino...",
	reviewLabels: {
		balanceAfterTransferAtSourcePlace: "Saldo após transferência no place de origem",
		balanceAfterTransferAtTargetPlace: "Saldo após transferência no place de destino",
	},
	submitFailedMessage: "Não foi possível transferir o valor",
	submitSucceededMessage: "Valor transferido com sucesso",
	transferTotalValueBtn: "Transferir todo saldo disponível",
	balanceAvailable: "Saldo disponível",
	enoughBalanceMessage: "Saldo disponível para transferência",
	selectPlaceLabel: "Selecione o place de destino",
	selectPlacePlaceholder: "Selecione o place que receberá a transferência",
	wizardLabels: {
		target: "Destino",
		value: "Valor da transferência",
		review: "Revisão dos dados",
	},
};
