import { crudMessage } from "./crud-message";
import { modalImportClients } from "./modal-import-clients";
import { modalMyModels } from "./modal-my-models";
export const message = {
	header: {
		isCreate: {
			title: "New message",
			subtitle:
				"Select a house in your organization to list the recipient customer group.",
		},
		isEdit: {
			title: "Edit Scheduled Message",
			subtitle: "Change the box to select another recipient group.",
		},
	},
	places: "Houses",
	emptyWhatsappList:
		"No location in the organization has numbers registered on WhatsApp. Contact Zig's customer service team and request the registration of a number to be able to send messages via WhatsApp to your customers.",
	import: "Import excel sheets",
	myModels: "My models",
	columnsTableClients: {
		name: "Customer Name",
		cpf: "CPF",
		ticket: "Average ticket",
		attendance: "Visits",
	},
	modalSaveGroup: {
		title: "Save group",
		text:
			"Save the filters applied to create a group of customers and make it easier to send SMS in the future.",
		filtersList: "Applied filters",
		groupName: {
			title: "Group name",
			placeholder: "Select a name for the new customer group",
		},
	},
	footer: {
		save: "Save change and edit message",
		forward: "Forward",
		confirmWithoutSaveGroup: "Forward without saving group",
		confirmWithSaveGroup: "Save group and go forward",
	},
	infos: {
		title: "Customer Information Responsibility",
		text:
			"By importing the customer's contact list, for the purpose of sending SMS, you confirm that they have accepted the receipt of messages, based on the General Data Protection Law (LGPD).",
		agree: "Understood",
	},
	sheet: {
		title: "Customer List (example)",
		columns: {
			name: "Name",
			phone: "Phone",
			cpf: "CPF",
			observation: "Observation",
		},
	},
	crudMessage,
	modalImportClients,
	modalMyModels,
};
