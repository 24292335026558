export const eventReports = {
	tabs: {
		resume: "Fechamento",
		promotion: "Promoções utilizadas",
		debtors: "Contas em aberto",
		devolution: "Devolução de cartão por caixa",
		cashierClosings: "Fechamento de caixa",
		userBonus: "Bônus",
		discounts: "Descontos em conta",
		minimumConsumption: "Consumação antecipada",
		consumptionObligation: "Consumação Mínima",
		refunded: "Produtos estornados",
		servicesRemoval: "Serviços removidos",
		bonusByProduct: "Bônus por produto",
		rechargeConsumption: "Devolução de recargas",
		refundedRecharges: "Recargas estornadas",
		individualLimitPost: "Alteração de limite individual de pós",
		rappiDiscounts: "Descontos Rappi",
		openedBillPayment: "Pagamentos de contas em aberto",
		openSales: "Vendas avulsas",
		afterClosingEvent: "Ações sincronizadas após o evento",
		cashierAdjustments: "Sangrias e Fundos de Troco",
		forciblySynchronizedBalances: "Saldos Sincronizados Forçadamente",
		zigAppReport: "Vendas por transações do Zig.app",
		tipReport: "Relatório de Gorjeta",
		transactionTransfer: "Transferências de contas",
	},
	resumeReport: {
		title: "Fechamento",
		finished: {
			end: "Fechado: {{end}}h",
			not: "O evento não foi fechado",
		},
		reportIn: "Faturamento do evento '{{eventName}}'",
		fileName: "faturamento-{{eventName}}",
	},
	debtorsReport: {
		title: "Contas em aberto",
		modal: {
			confirm: "Confirmar",
			yes: "Sim",
			no: "No",
			paymentMethodError: "Selecione um método de pagamento",
			pay: "Pagar",
			placeScope: "local",
			eventScope: "evento",
			paymentConfirm_part1: "Esse pagamento ",
			paymentConfirm_part2: "irá pagar TODA a dívida",
			paymentConfirm_part3: "do cliente no {{scope}}, tem certeza que deseja fazer isso?",
			paymentMethod: "Método de pagamento",
			postEvent: "Marcar como pago",
			bonus: "Pagar com bônus",
			cash: "Dinheiro",
			creditCard: "Crédito",
			debitCard: "Débito",
			voucher: "Voucher",
			multiplePaymentConfirm_part1: "Esse pagamento ",
			multiplePaymentConfirm_part2: "irá pagar {{value}}",
			multiplePaymentConfirm_part3:
				"do cliente no {{scope}}, tem certeza que deseja fazer isso?",
			multiplePaymentError: "Atenção! Adicione um método de pagamento",
			column: {
				bonus: "Bônus",
				value: "Valor",
			},
			openConsumption: "Consumo em aberto",
			total: "Total",
			service: "Serviço ({{tip}}%)",
			paymentConsumption: "Pagando em consumo",
			paymentService: "Pagando em serviço",
			submitError: "Só é possível pagar a mais até 25% do valor total em aberto.",
			select: "Selecione",
			addPayment: "Adicionar pagamento",
			reason: "Justificativa",
			reasonPlaceholder: "Adicione a justificativa do bônus",
			observation: "Observação",
			observationPlaceholder: "Insira uma observação",
			pixCharge: "Cobrança via PIX",
			sendCharge: "Enviar cobrança",
			openValues: "Valores em aberto",
			totalDebit: "Total em aberto",
			pixValue: "Valor do pix",
			email: "Email",
			emailPlaceholder: "Email do cliente",
			fieldRequired: "Campo obrigatório",
			emailError: "Email inválido",
			phoneError: "Telefone inválido",
			discharge: "Dar baixa",
			openService: "Serviço em aberto",
		},
		alertTitleOpenBill: "Não é possível enviar cobranças no relatório do evento",
		alertMessageOpenBill:
			"Para enviar cobranças, acesse o menu [Locais] > escolha o local > menu [Relatórios Financeiros] > aba 'Contas em aberto' e utilize o link na coluna 'Cobrar'.",
	},
	cashierClosings: {
		card: {
			total: "TOTAL",
			closed: "Fechado",
			open: "Em aberto",
			received: "Faturado",
			difference: "Diferença",
			casher_one: "{{numberOfCashiers}} Caixa",
			cashier_other: "{{numberOfCashiers}} Caixas",
			notClosed_one: "({{numberOfOpenCashiers}} não fechado)",
			notClosed_other: "({{numberOfOpenCashiers}} não fechados)",
			modality: "Modalidade",
			noFlag: "Sem Bandeira",
			cash: "Dinheiro",
			cardDevolution: "Devoluções de cartão",
			productDevolution: "Devoluções de produto",
			bleeding: "Sangria",
			changeFund: "Fundo de troco",
			rechargeDevolutions: "Devoluções de recarga",
			xlsx: {
				modality: "Modalidade",
				received: "Faturado",
				closed: "Fechado",
				difference: "Diferença",
				closingResume: "Resumo do Fechamento",
				cashier: "Caixa",
				closingCashier: "Fechamento por Caixa",
				filenameEvent: "fechamento-de-caixa-{{eventDate}}",
				filename: "fechamento-de-caixa-em-local",
			},
		},
		header: {
			cashierHistory: "Ver histórico do caixa",
			transactionalHistory: "Ver histórico transacional",
			addBleed: "Fazer sangria",
			addChange: "Adicionar fundo de troco",
			reopenCashier: "Tem certeza que quer reabrir o caixa?",
			reopenCashierButton: "Reabrir caixa",
			closeCashier: "Tem certeza que deseja fechar o caixa {{cashierName}}?",
			closeCashierButton: "Fechar caixa",
			historyTitle: "Histórico do caixa - {{cashierName}}",
			obs: "Observação",
			addObs: "Adicionar observação",
		},
		modal: {
			cashiers: "Caixas",
			value: "Valor",
			changeFund: "Fundo de troco",
			history: "Histórico",
			historyTitle: "Histórico de {{name}}",
			cashierPosts: "Transações pós-pagas",
			cashierRecharges: "Recargas",
			addFlag: "Adicionar bandeira",
			saveValues: "Salvando valores",
			column: {
				client: "Cliente",
				clients: "Cliente(s)",
				zigCode: "ZigTag",
				date: "Data",
				method: "Método",
				valueRecharged: "Valor recarregado",
				valueComsumed: "Valor consumido",
				total: "Total",
				payments: "Pagamentos",
				author: "Funcionario",
				obs: "Observação",
			},
		},
		valueGreaterThanZero: "O valor precisa ser maior que 0",
		value: "Valor",
		cashier: "Caixas",
		noCard: "Sem cartão",
		noCashierSelected: "Nenhum caixa selecionado",
		all: "Todos",
		total: "Total",
		info: {
			closingCashier: "Fechamento de caixa",
			cashierReceipt: "Receita por caixa",
			changeFund: "Fundo de troco",
			changes: "Troco",
			cashier_one: "caixa",
			cashier_other: "caixas",
			notClosed: "não fechado",
			billed: "Faturado",
			bleeds: "Sangrado",
			devolutions: "Devolvido",
			closed: "Fechado",
			negative: "Diferença",
			searchCashier: "Buscar caixa por nome",
			cashierNotClosed: "Caixa não fechado",
			createBleed: "Fazer Sangria",
			createChange: "Adicionar fundo de troco",
			bleeding: "Sangria",
			cardDevolution: "Devoluções de cartão",
			rechargeDevolutions: "Devoluções de recarga",
			addNewModality: "Adicionar nova modalidade",
			closeCashier: "Fechar caixa",
			cancel: "Cancelar alterações",
			save: "Salvar fechamento",
			modality: "Modalidade",
			totalClosed: "Total Fechado",
			totalBilled: "Total Faturado",
		},
		paymentMethod: {
			debit: "Débito",
			cash: "Dinheiro",
			credit: "Crédito",
			zigDebit: "Débito Zig",
			zigCredit: "Crédito Zig",
			postEvent: "Pago após evento",
			voucher: "Voucher",
			dashboard: "Dashboard",
			anticipated: "Antecipado",
			subtotal: "Subtotal",
			devolution: "Devoluções",
			total: "Total",
			ame: "Ame",
			deliveryOnline: "Delivery Online",
			rappi: "Rappi",
			ifood: "Ifood",
			uber: "Uber",
			pix: "Pix",
			bonus: "Bonus",
			installment: "Parcelamento",
			foodVoucher: "Vale-refeição",
		},
		transactionsTitle: "Histórico de {{name}}",
		postPaid: "Transações pós-pagas",
		recharges: "Recargas",
		balanceTitleDay: "Receita por caixa no dia {{day}}",
		balanceTitle: "Receita por caixa",
		filenameBalance: "receita_por_caixa",
	},
	minimumConsumption: {
		title: "Consumação antecipada",
	},
	refunded: {
		title: "Produtos estornados/cancelados",
		filename: "produtos-estornados",
		zigTable: "Zig Mesa",
		zigTag: "Zig Card",
		columns: {
			date: "Data",
			name: "Nome do Produto",
			category: "Categoria",
			type: "Tipo",
			canceled: "Cancelado",
			refunded: "Estornado",
			refundedBy: "Estornado Por",
			soldBy: "Vendido Por",
			refundObs: "Observação",
			reason: "Motivo",
			clients: "Clientes",
			operationType: "Operação",
			count: "Quantidade",
			value: "Valor",
			totalRefunded: "Total ({{total}} estornos)",
			totalValue: "Valor Total",
			totalCount: "Quantidade Total",
			moreRefundedBuyer_other: "e mais {{buyers}} pessoas",
			moreRefundedBuyer_one: "e mais {{buyers}} pessoa",
		},
	},
	servicesRemoval: {
		title: "Remoção de serviços por caixa",
		titlePrint: "Serviços Removidos",
		goTo: "ir para",
		clientInfo: "Informações de Cliente",
		columns: {
			date: "Data",
			total: "Total Removido",
			name: "Cliente",
			value: "Valor",
			reason: "Motivo",
			employee: "Funcionário",
		},
	},
	rechargeConsumption: {
		title: "Devolução de recargas",
		filename: "devolucao-de-recargas",
		detailDialog: "Ver recargas devolvidas pelo caixa",
		rechargesRefundedBy: "Recargas devolvidas por {{author}}",
		columns: {
			author: "Caixa",
			ame: "Ame",
			anticipated: "Antecipado",
			cash: "Dinheiro",
			credit: "Crédito",
			dashboard: "Dashboard",
			debit: "Débito",
			deliveryOnline: "Delivery Online",
			ifood: "Ifood",
			pix: "PIX",
			rappi: "Rappi",
			uber: "Uber",
			voucher: "Voucher",
			total: "Total",
			client: "Cliente",
			method: "Método",
			value: "Valor",
		},
	},
	individualLimitPost: {
		title: "Informações de clientes",
		titleTab: "Alteração de limite individual de pôs",
		searchPlaceholder: "Busque por usuário, id ou nome",
		filename: "relatorio_de_alteracao_de_limite_individual_de_pos",
		columns: {
			username: "Usuário",
			client: "Cliente",
			employeeName: "Funcionário",
			date: "Horário",
			limit: "Aumento de limite",
			newLimite: "Novo limite",
			emptyText: "Sem dados a serem exibidos.",
		},
	},
	rappiDiscounts: {
		title: "Descontos do Rappi",
		rappiDiscounts: "Descontos Rappi",
		filename: "relatorio_de_descontos_do_rappi",
		columns: {
			products: "Produtos",
			date: "Data",
			value: "Valor do desconto",
			employee: "Funcionário que aplicou o desconto",
		},
	},
	openedBillPayment: {
		title: "Pagamentos de contas em aberto",
		filename: "relatorio_de_pagamentos_de_contas_em_aberto_em_evento",
		columns: {
			client: "Cliente",
			document: "CPF",
			openedEventDate: "Data da conta em aberto",
			employeeName: "Pago para",
			method: "Método",
			tipValue: "Valor de gorjeta",
			value: "Valor",
		},
	},
	afterClosingEvent: {
		title: "Ações sincronizadas após o evento",
		description: "Relatório com ações ou transações após o fechamento do evento.",
		searchLabel: "Pesquisar por nome do funcionário",
		searchPlaceHolder: "Digite o nome do funcionário",
		filterTransactionLabel: "Filtrar por tipo de transação",
		filterOriginLabel: "Visualizar",
		offlineActionLabel: "Após fechamento",
		historyMachineLabel: "Histórico da maq.",
		historyMachineAfterClosingLabel: "Histórico após fechamento",
		optionAll: "Todos os saldos",
		optionOfflineAction: "Saldos sincronizados após fechamento de evento",
		optionHistoryMachine: "Saldos do histórico da maquininha",
		optionHistoryMachineAfterClosing:
			"Saldos do histórico da maquininha sincronizados após o fechamento do evento",
		columns: {
			transactionType: "Tipo de transação",
			employee: "Funcionário",
			customerName: "Nome do cliente",
			date: "Data/Hora da sinc",
			value: "valor",
			origin: "Origem",
		},
	},
	forciblySynchronizedBalance: {
		title: "Saldos Sincronizados Forçadamente",
		table: {
			columns: {
				date: "Data/Hora",
				user: "Cliente",
				employee: "Funcionário",
				reason: "Justificativa",
				balance: "Saldo Sincronizado",
			},
		},
		successfulExportMsg: "Planilha exportada com sucesso!",
	},
	zigAppReport: {
		title: "Vendas por transações do Zig.app",
	},
	salesTotetemTransaction: {
		title: "Vendas por transações do Totem",
		subtitle:
			"Acompanhe as transações feitas pelos seus clientes usando um totem do evento.",
	},
};
