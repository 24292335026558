import * as React from "react";
import s from "./style.scss";
import { formatDate } from "#resources/helpers/date-format";
import { rootStore } from "#stores/index";
import { useTranslation } from "react-i18next";
import ZigLogo from "./zig-full-logo.svg";
import classNames from "classnames";

interface Props {
	since?: Date;
	until?: Date;
	notHideContent?: boolean;
	title?: string;
	extraPrintInfo?: JSX.Element;
	className?: string;
}

export const ReportPrintTemplate = React.forwardRef<
	HTMLDivElement,
	React.PropsWithChildren<Props>
>((props, ref) => {
	const { t: tPlace } = useTranslation("place");
	const { t: tComponents } = useTranslation("components");
	const employeeName =
		rootStore.authStore.getCurrentAdmin.value?.name ??
		rootStore.authStore.currentEmployee?.name;

	return (
		<div
			ref={ref}
			className={classNames(
				s.printContent,
				{ [s.notHide]: props.notHideContent },
				props.className,
			)}
		>
			<header className={s.header}>
				<div className={s.logo}>
					<ZigLogo />
				</div>

				<div className={s.headerContentText}>
					{props.title ? <h1 className={s.printTitle}>{props.title}</h1> : null}
					<div className={s.printDate} style={{ paddingTop: 8 }}>
						<span>{tPlace("barReports.printDate")}:</span>
						<span>{formatDate(new Date())}</span>
					</div>
					{props.since && props.until && (
						<span className={s.period}>
							{tPlace("barReports.reportPeriod")}:
							<span className={s.period}>
								{" " + formatDate(props.since, "DATE_HOUR")}
								{" a " + formatDate(props.until, "DATE_HOUR")}
							</span>
						</span>
					)}
					{props.since && !props.until && (
						<span className={s.period}>
							{tComponents("reportPrintTemplate.eventDate")}:{" "}
							<span className={s.period}>{formatDate(props.since, "DATE")}</span>
						</span>
					)}
					<div className={s.extraInfo}>
						{props.extraPrintInfo && (
							<span className={s.extraInfo}>{props.extraPrintInfo}</span>
						)}
						{employeeName && (
							<span className={s.extraInfo}>
								{tComponents("reportPrintTemplate.emissionInfo")} {employeeName}
							</span>
						)}
					</div>
				</div>
			</header>
			{props.children}
		</div>
	);
});
