export const soldRankingReport = {
	tabTitle: "Sales by products",
	pageTitle: "Sales by products",
	pageDescription: "Report between operators and their sales.",
	exportReport: "Export report",
	groupedView: "Grouped view",
	selectPeriod: "Select the period",
	searchCategory: "Category",
	searchCategoryPlaceholder: "Search category",
	searchProduct: "Product",
	searchProductPlaceholder: "Search product",
	filename: "sales_ranking",
	table: {
		columns: {
			position: "Position",
			product: "Product",
			count: "Quantity",
			totalSold: "Total",
			price: "Price",
			discount: "Discount",
		},
		withoutInfo: "No information to display",
	},
	productSoldTable: {
		columns: {
			waiter: "Waiter",
			count: "Quantity",
			price: "Price",
			discount: "Discount",
			total: "Total",
		},
		withoutInfo: "No information to display",
	},
	totalizers: {
		highestSeller: "Highest Seller",
		totalSellerSold: "Total seller sold",
	},
};
