export const waiterReport = {
	tabTitle: "Sales Ranking",
	title: "Sales",
	description: "Reports regarding bar sales, operators and product rankings",
	filter: "Filter by transaction type",
	transactionSell: "Sales per transaction",
	productSell: "Sales by product",
	waiterSell: "Sales per waiter",
	filename: "sales-per-waiter",
	filterWaiterLabel: "Employee's name",
	filterWaiterPlaceholder: "Select the employees",
	deletedEmployee: "Deleted employee",
	table: {
		name: "Name",
		soldValue: "Value sold",
		tip: "Value in Service",
		total: "Total",
	},
	sellerTransactionTable: {
		date: "Date",
		clients: "Customers",
		zigCode: "Card number",
		products: "Products",
		refunded: "Reversed",
		transactionValue: "Transaction value",
		tip: "Tip value",
		filename: "sales-per-transaction",
	},
	sellerProductTable: {
		name: "Name",
		category: "Category",
		count: "Quantity",
		value: "Value",
		discount: "Discount",
		total: "Total",
		filename: "sales-per-product",
	},
};
