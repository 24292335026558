export const notEmitedProducts = {
	title: "Produtos não emitidos",
	activeTab: "not-emited-products",
	export: "Exportar Excel",
	sheet: {
		title: "Produtos não emitidos",
		filename: "produtos_nao_emitidos",
		columns: {
			name: "Produto",
			count: "Quantidade",
			combo: "Combo",
			firstNotEmittedSellDate: "Primeiro dia não emitido",
			lastNotEmittedSellDate: "Último dia não emitido",
		},
	},
	columns: {
		name: "Produto",
		count: "Quantidade",
		combo: "Combo",
		firstNotEmittedSellDate: "Primeiro dia não emitido",
		lastNotEmittedSellDate: "Último dia não emitido",
	},
};
