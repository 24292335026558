export const accounts = {
	title: "Saved Accounts",
	saveNewAccountButton: "Save new account",
	filterInputPlaceholder: "Filter saved accounts",
	table: {
		holder: "Owner of the account",
		holderTooltip: "Corporate name or account owner's name",
		name: "Identification",
		pixKey: "Pix key",
		agency: "Agency",
		account: "Account",
		accountType: "Account Type",
		deletePopconfirm: 'Are you sure you want to remove the account "{{name}}"?',
		withdraw: "Withdraw",
		anticipate: "Anticipate",
	},
	drawerTitle: "Save account",
	addNewAccount: "Add new account",
	form: {
		holder: "Company Name/Owner's Name",
		holderPlaceholder: "Enter the name of the company/owner here",
		name: "Account identification",
		nameTooltip: "Title by which you will identify the account",
		namePlaceholder: "Enter account name here",
		bank: "Bank",
		bankPlaceholder: "Select your bank here or type to search",
		documentType: "Document Type",
		cnpj: "CNPJ",
		cpf: "CPF",
		none: "None",
		documentNumber: "CPF/CNPJ",
		documentNumberPlaceholder: "Enter the account holder document here",
		agency: "Agency",
		agencyPlaceholder: "Enter the agency here (without digit)",
		agencyWithDigitPlaceholder: "Enter the agency here (with digit)",
		agencyDigitPlaceholder: "Enter the agency digit here",
		agencyDigit: "Digit",
		account: "Account",
		accountDigit: "Digit",
		accountPlaceholder: "Enter the account here (without digit)",
		accountDigitPlaceholder: "Enter the account digit here",
		accountType: "Account Type",
		noneAccount: "None",
		contaCorrente: "Checking Account",
		contaPoupanca: "Savings Account",
		contaSalario: "Salary account",
		pixKey: "PIX key",
		pixKeyPlaceholder: "Enter your account PIX key here",
		selectAccountPlaceholder: "Enter or select account",
		accountDataType: "Data to register",
		bankAccount: "Bank data",
		pix: "PIX",
		saveAccountFuture: "Save account for future transactions",
	},
	formErrors: {
		holder: "Enter the name of the account holder",
		name: "Enter the name to identify the account",
		bank: "Select the bank to which the account belongs",
		documentNumber: "Enter the account holder's document (CPF/CNPJ)",
		documentNumberInvalid: "Invalid document",
		agency: "Digite o número da agência",
		agencyInvalid: "Invalid agency",
		account: "Enter account number",
		accountDigit: "Enter the account digit",
		accountType: "Select account type",
		pixKeyInvalid: "Invalid PIX key",
		receiverDataWrapper: "Fill in your bank details or PIX key",
	},
	accountTypes: {
		checking: "Checking account",
		savings: "Savings account",
		salary: "Salary account",
	},
};
