export const orderReport = {
	title: "Order Report",
	sheet: {
		filename: "vendor-delivery-report",
		title: "Sales Delivery",
		columns: {
			chanel: "Platform",
			reference: "Order number",
			date: "Date and time",
			note: "OBS",
			status: "Status",
			paymentMethods: "Payment Methods",
			customerName: "Customer",
			customerPhone: "Customer Phone",
			vendor: "Vendor",
			value: "Value",
			deliveryFee: "Delivery Fee",
			tip: "Tip",
			discount: "Discount",
			subtotal: "Subtotal",
		},
	},
	columns: {
		chanel: "Platform",
		reference: "Order number",
		date: "Date and time",
		note: "OBS",
		status: "Status",
		paymentMethods: "Payment Methods",
		customerName: "Customer",
		customerPhone: "Customer Phone",
		vendor: "Vendor",
		value: "Value",
		deliveryFee: "Delivery Fee",
		tip: "Tip",
		discount: "Discount",
		subtotal: "SubTotal",
	},
	vendorLabels: {
		coala: "Whitelabel",
		ifood: "Ifood",
		rappi: "Rappi",
		ubereats: "Uber Eats",
	},
	methodsLabel: {
		creditCard: "Cartão de crédito",
		debitCard: "Cartão de débito",
		cash: "Dinheiro",
		creditCardOnline: "Cartão de cŕedito online",
		voucherInPlace: "Voucher",
		mastercard: "Master-card",
		visa: "Visa",
		amex: "Amex",
		elo: "Elo",
		hipercard: "Hiper-card",
		diners: "Diners",
		ticket: "Ticket",
		alelo: "Alelo",
		vr: "VR",
		ben: "Ben",
		sodexo: "Sodexo",
	},
	statusLabel: {
		ready: "Pronto",
		canceled: "Cancelado",
		concluded: "Concluído",
		confirmed: "Confirmado",
		dispatched: "Despachado",
		integrated: "Integrado",
		placed: "Criado",
		pendingPayment: "Pagamento pendente",
		pickupAreAssigned: "Cliente no local",
		scheduled: "Agendado",
	},
	channelLabel: {
		marketplace: "Marketplace",
		phone: "Telefone",
		whatsapp: "Whatsapp",
		whitelabel: "Whitelabel",
	},
};
