export const tabTaxCouvert = {
	emptyTaxCouvertMessage: "There are no couvert tax data to show",
	infosTaxCouvert: {
		cest: "CEST",
		cfop: "CFOP",
		code: "SKU",
		group: "Group",
		ncm: "NCM",
		profile: "Profile",
	},
	searchPlaceholder: "Search couvert tax data",
};
