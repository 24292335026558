export const whatsUp = {
	title: "What's Up?",
	description:
		"Fill in the data and share your event with your customers through the ZIGPAY application. Allow users access to opening hours, social media, photos and external links.",
	publish: "Publish",
	edit: "Edit information",
	placePublishObs:
		"By posting you are agreeing to display your location information in the ZIG app and to be responsible for the posted content.",
	eventPublishObs:
		"By publishing you are agreeing to display your event information on the ZIG application and to be responsible for the published content.",
	active: {
		titlePlace: "Display home in user app",
		titleEvent: "Display event in user application",
		description: "No extra fee will be charged to display your home on the ZIG app",
		unfilledFields: "Could not activate. Mandatory fields not filled in.",
	},
	inactive: {
		placeTitle:
			"Your location is not showing in the app. It is necessary to enable the display.",
		eventTitle:
			"Your event is not showing in the app. It is necessary to enable the display.",
	},
	profileSection: {
		title: "Profile",
		seePreview: "View app preview",
		description:
			"You can upload images of up to 500kb in .png, .jpg or .jpeg formats according to the dimensions mentioned below:",
		brandImage: "Brand image",
		brandImageObs: "Square image or 500x500 pixels",
		frontCoverImage: "Cover image",
		galleryImage: "Image for gallery (optional)",
		horizontalImageObs: "Horizontal image or 1280x780 pixels",
	},
	aboutSection: {
		titlePlace: "About the place",
		titleEvent: "About the event",
		descriptionPlace:
			"At this stage, you must fill in information about the location. Try to fill in all the information clearly and simply.",
		descriptionEvent:
			"In this step, you must fill in information about the event. Try to fill in all the information clearly and simply.",
		eventTagLimit:
			"You can select up to 6 tags. Tags will help users learn more about your event's differentiators.",
		placeTagLimit:
			" You can select up to 6 tags. Tags will help users learn more about your location's differentiators.",
		placeName: "Place name",
		eventName: "Event name",
		placeCategory: "Location Category",
		eventCategory: "Event category",
		descriptionInput: "Description (optional)",
		placeDescriptionPlaceHolder: "Write a brief description about the location",
		eventDescriptionPlaceHolder: "Write a brief description about the event",
		musicTags: "Music style",
		musicTagLimit:
			"Use style tags to help get your location/event in front of users with an interest in the same styles.",
		musicTagPlaceholder: "Select up to 3 music style",
		tags: "Tags",
		tagsPlaceholder: "Select up to 6 tags",
		barAndRestaurant: "Bar and restaurant",
		bar: "Pub",
		pub: "Pub",
		restaurant: "Restaurant",
		nightclub: "Ballad",
		show: "Show",
		event: "Event",
		festival: "Festival",
		beachClub: "Beach club",
		arena: "Arena",
		bistro: "Bistro",
	},
	linkSection: {
		title: "External links (optional)",
		site: "Site",
		reservation: "Reservations",
		facebook: " Facebook",
		instagram: "Instagram",
		ticket: "Tickets",
		buttonHighlight: "Highlight button (optional)",
		pasteHere: "Paste here",
	},
	addressSection: {
		title: "Address information",
		titleOptional: "Address information (optional)",
		zigLocation: "Zig location",
		zigLocationTooltip:
			"It is the Zig hub where your location was registered. If you want to change, contact our team",
		zipCode: "Zip code",
		zipCodeLink: "I do not know my CEP",
		city: "City",
		state: "State",
		neighborhood: "Neighborhood",
		address: "Address",
		number: "Number",
		complement: "Add-on (optional)",
		addressPlaceholder: "Street or avenue name",
		complementPlaceholder: "Address add-on",
	},
	operationSection: {
		placeTitle: "Opening hours",
		eventTitle: "Event dates",
		placeDescription:
			"Inform the day and hours of operation of your house. If your house doesn't work on some day of the week, just don't add it to the list below.",
		eventDescription: "Enter the day and hours of your event.",
		day: "Day",
		add: "Add",
		date: "Date",
		time: "Time (optional)",
		until: "Up until",
		monday: "Monday",
		tuesday: "Tuesday",
		wednesday: "Wednesday",
		thursday: "Thursday",
		friday: "Friday",
		saturday: "Saturday",
		sunday: "Sunday",
	},
	appearsInAppModal: {
		title: "Do not share location in app",
		description:
			"When disabling, your location will not be displayed in the user's app. Do you want to continue anyway?",
	},
	cancelEditModal: {
		title: "Cancel edit",
		description:
			"When you cancel editing, the changes will not be saved. Do you want to continue anyway?",
	},
	successModal: {
		eventTitle: "The information has been saved successfully!",
		placeTitle: "The information has been saved successfully!",
		description: `Make sure the "Display home in user app" function is enabled so that your location is displayed in Zig.app.`,
	},
	formValidations: {
		name: "Name is a required field",
		nameMaxLength: "Maximum character limit is {{maxLength}}",
		locationCategory: "Location Category is a required field",
		description: "Limit of 160 characters reached",
		tags: "You can only select up to {{numberTags}} tags",
		link: "Please enter a valid link",
		linkHighlightType: "Highlight button is a required field",
		zigLocation: "Location Zig is a required field",
		postalCode: "Zip code is a required field",
		state: "State is a required field",
		city: "City is a required field",
		neighborhood: "Neighborhood is a required field",
		streetName: "Address is a required field",
		streetNumber: "Number is a required field",
		image: "Image is mandatory",
		imageSize: "Upload images up to 500kb",
		date: "Date is a required field",
		generalError: "*Mandatory information not filled in",
	},
};
