import enterprise from "#resources/api/enterprise-client";
import { fetchModel } from "#resources/helpers/fetch-model";
import { RootStore } from ".";

import { showErrorNotification } from "#helpers/notifications";
import { BarModel, barWithPlaceId } from "#models/bar";
import {
	Bar,
	BarOrEmployeeFinance,
	BarReport,
	TransactionSource,
} from "#resources/api/enterprise-generated";
import { action, observable } from "mobx";

export class BarStore {
	@observable
	public consolidatedBarSellingReports = new fetchModel<
		{
			eventId: string;
			sources?: TransactionSource[] | null;
		},
		BarReport[]
	>({
		fnPromise: args => enterprise.getConsolidatedBarSellingReport(args),
	});

	@observable
	public bars: BarModel[] = [];

	public rootStore: RootStore;

	constructor(rootStore: RootStore) {
		this.rootStore = rootStore;
	}

	public clean() {
		this.bars = [];
	}

	public resolve = (...incomingBars: barWithPlaceId[]) => {
		for (const incomingBar of incomingBars) {
			const bar = this.bars.find(t => t.id === incomingBar.id);
			if (!bar) {
				this.bars = this.bars.concat(new BarModel(this, incomingBar));
			} else {
				bar.sync(incomingBar);
			}
		}

		return this.bars;
	};

	@action
	public getConsolidatedBarSellingReport = (
		eventId: string,
		sources?: TransactionSource[] | null,
	) => {
		this.consolidatedBarSellingReports.fetch({ eventId, sources });
	};

	public getBars = new fetchModel<
		{
			placeId: string;
			includeDeleted?: boolean | null;
		},
		Bar[]
	>({
		fnPromise: args => enterprise.getBars(args),
		onError: err => showErrorNotification(err.message),
	});

	public getEstimatedFinanceDetailsByBarAtEvent = new fetchModel<
		{
			eventId: string;
		},
		BarOrEmployeeFinance[]
	>({
		fnPromise: args => enterprise.getEstimatedFinanceDetailsByBarAtEvent(args),
		onError: err => showErrorNotification(err.message),
	});
}
