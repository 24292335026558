export const recharges = {
	title: "Recharges",
	activeTab: "recharges",
	export: "Export to Excel",
	transactionedValue: "Transactioned Value",
	value: "Issued Value",
	netValue: "Issued Net Value",
	periodFilter: "Filter by period",
	eventsFilter: "Filter by events",
	filterIssuedLabel: "Filtrer par statut",
	eventsSelected: "events selected",
	eventsFilterPlaceholder: "Select the events",
	descriptionForeign:
		"This report shows all recharges and displays information from the associated Invoice, if any. It allows filtering by period, events, and status.",
	columns: {
		totalValue: "Recharge value",
		usedValue: "Used value",
		date: "Date",
		eventName: "Event Name",
		eventDate: "Event Date",
		cnpj: "Issuer CNPJ",
		cpf: "Customer CPF",
		type: "Type",
		issuerNIF: "Merchant's NIF",
		recipientNIF: "Customer's NIF",
		invoiceSeries: "Invoice Series",
		invoiceNumber: "Invoice Number",
		invoiceValue: "Invoice Value",
		invoiceNetValue: "Invoice Net Value",
		invoiceDate: "Invoice Date",
	},
};
