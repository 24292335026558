import {
	DashboardNotificationList,
	DashboardNotificationPaginated,
} from "#resources/api/enterprise-generated";
import { InfiniteData } from "@tanstack/react-query";
import { ONBOARDING_KEYS, OnboardingKeysType } from "./constants";

const getNotificationList = (
	unreadNotifications: InfiniteData<DashboardNotificationPaginated, unknown> | undefined,
) => {
	return (
		unreadNotifications?.pages.reduce((prev, curr) => [...prev, ...curr.data], []) || []
	);
};

const findOnboardingKey = (notificationList: DashboardNotificationList[]) => {
	return Object.entries(ONBOARDING_KEYS).find(([_, title]) => {
		const notificationFound = notificationList.find(notification => {
			return notification.title.toLocaleLowerCase() === title.toLocaleLowerCase();
		});

		return notificationFound && notificationFound.classification === "release";
	}) as [OnboardingKeysType, string] | undefined;
};

export const extractOnboardingKey = (
	unreadNotifications: InfiniteData<DashboardNotificationPaginated, unknown> | undefined,
) => {
	const notificationList = getNotificationList(unreadNotifications);

	if (!notificationList.length) {
		return null;
	}

	const found = findOnboardingKey(notificationList);

	if (!found) {
		return null;
	}

	const [foundKey] = found;

	return foundKey;
};
