export const transactions = {
	title: "Transactions",
	titleForeign: "Products",
	activeTab: "transactions",
	valueTransactioned: "Transactioned value",
	value: "Gross value issued",
	netValue: "Net value issued",
	period: "Period",
	export: "Export Excel",
	filterIssued: "Issued",
	filterNotIssued: "Not issued",
	filterIssuedLabel: "Filter by status",
	descriptionForeign:
		"In this report, it's possible to have an overview of the issued fiscal billing considering each transacted product, with the option to filter by period and status. The gross issued amount includes the Value Added Tax (VAT), while the net issued amount is calculated after deducting the VAT",
	columns: {
		transactionId: "Transaction Id",
		productName: "Product",
		count: "Quant.",
		value: "Unit value",
		total: "Total value",
		netValue: "Net Value",
		discountValue: "Discount",
		date: "Date",
		isCombo: "Combo",
		eventName: "Event",
		eventDate: "Event Date",
		cnpj: "Issuing CNPJ",
		cpf: "Customer CPF",
		issuerNIF: "Retailer's NIF",
		recipientNIF: "Customer's NIF",
		type: "Type",
		invoiceSeries: "Note series",
		invoiceNumber: "No.",
		invoiceValue: "Note value",
		invoiceNetValue: "Note net Value",
		invoiceDate: "Issuance Date",
	},
	sheet: {
		title: "Transactions",
		titleForeign: "Products",
		filename: "transactions",
		filenameForeign: "products",
		columns: {
			transactionId: "Transaction Id",
			productName: "Product",
			count: "Quantity",
			value: "Unit value",
			netValue: "Net Value",
			total: "Total value",
			discountValue: "Discount",
			date: "Date",
			isCombo: "It's Combo",
			eventName: "Event",
			eventDate: "Event Date",
			cnpj: "Issuing CNPJ",
			cpf: "Customer CPF",
			type: "Type",
			issuerNIF: "Retailer's NIF",
			recipientNIF: "Customer's NIF",
			invoiceSeries: "Note series",
			invoiceNumber: "No.",
			invoiceValue: "Note value",
			invoiceNetValue: "Note net Value",
			invoiceDate: "Issuance Date",
		},
	},
};
