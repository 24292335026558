import {
	useGetAllPlaceSalesSummary,
	useGetAllPlaceSalesSummaryInEvent,
} from "#resources/hooks/integrations/sales/query";
import { useSalesReport } from "#pages/place/bar-reports/sales-report/context";

export const useGetAllPlaceSalesSummaryByLevel = ({
	manageByFilters,
	manageBySelectedTab,
}: {
	manageBySelectedTab?: boolean;
	manageByFilters?: boolean;
}) => {
	const {
		since,
		until,
		placeId,
		eventId,
		eventLevel,
		selectedTab,
		employeesFilter: _employeesFilter,
		paymentMethodsFilter: _paymentMethodsFilter,
		clientNameFilter: _clientNameFilter,
	} = useSalesReport();

	const employeesFilter = _employeesFilter.length > 0 ? _employeesFilter : null;
	const clientNameFilter = _clientNameFilter ? _clientNameFilter : null;
	const paymentMethodsFilter =
		_paymentMethodsFilter.length > 0 ? _paymentMethodsFilter : null;

	const enabledPlaceRequests = manageBySelectedTab
		? Boolean(!eventLevel && selectedTab === "all-sales")
		: !eventLevel;
	const enabledEventRequests = manageBySelectedTab
		? Boolean(eventLevel && selectedTab === "all-sales")
		: Boolean(eventLevel);

	const filters = manageByFilters
		? {
				employeeIds: employeesFilter,
				paymentMethods: paymentMethodsFilter,
				customerName: clientNameFilter,
		  }
		: {
				customerName: null,
				employeeIds: null,
				paymentMethods: null,
		  };

	const {
		data: getAllPlaceSalesSummaryData,
		isFetching: getAllPlaceSalesSummaryIsFetching,
	} = useGetAllPlaceSalesSummary(
		{
			placeId,
			period: {
				since,
				until,
			},
			filters,
		},
		{
			placeholderData: (previousData, _previousQuery) => previousData,
			enabled: enabledPlaceRequests,
		},
	);

	const {
		data: getAllPlaceSalesSummaryInEventData,
		isFetching: getAllPlaceSalesSummaryInEventIsFetching,
	} = useGetAllPlaceSalesSummaryInEvent(
		{
			placeId,
			eventId: eventId || "",
			filters,
		},
		{
			placeholderData: (previousData, _previousQuery) => previousData,
			enabled: enabledEventRequests,
		},
	);

	const data = eventLevel
		? getAllPlaceSalesSummaryInEventData
		: getAllPlaceSalesSummaryData;
	const isFetching = eventLevel
		? getAllPlaceSalesSummaryInEventIsFetching
		: getAllPlaceSalesSummaryIsFetching;

	return { data, isFetching };
};
