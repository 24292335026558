export const contentProductDetail = {
	fieldName: {
		image: "Image",
		name: "Name",
		productType: "Product type",
		unitOfMeasurement: "Unit of measurement",
		category: "Category",
		menuCategory: "Menu category",
		price: "Price",
		description: "Description",
		drinkStyle: "Drink style",
		alcoholContent: "ABV - Alcohol content",
		hasAlcohol: "Contains alcohol",
		hiddenZigApp: "Do not display product on ZigApp",
		sincMyTapp: "Sync with MyTapp",
		productReturn: "Returnable Product",
		brand: "Brand",
		variableValue: "Variable value",
		stockableProduct: "Stockable product",
	},
	requiredField: "Required field",
	editImage: "Click on image to edit",
};
