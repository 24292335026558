export const orangeScreenReport = {
	title: "Orange Screen Report",
	subtitle: "List with all occurrences during the event period.",
	filters: {
		employee: {
			label: "Employee Name",
			placeholder: "Type the employee name",
		},
		deviceFingerprint: {
			label: "PDV ID",
			placeholder: "Type the PDV ID",
		},
	},
	columns: {
		date: "Date/time of occurrence",
		employee: "Employee",
		deviceId: "PDV ID",
		zigDeviceUid: "Card ID",
	},
	filename: "orange-screen-report",
};
