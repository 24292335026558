import Icon from "#components/icon";
import { Button, IButtonProps } from "#components/primitive";
import * as React from "react";
import s from "./button-dashed.module.scss";
import classNames from "classnames";

interface IButtonDashedProps extends IButtonProps {
	text?: string;
	onClick?: () => void;
}
export function ButtonDashed({ text, className, ...props }: IButtonDashedProps) {
	return (
		<Button
			variant="text"
			leadingIcon={<Icon icon="add-circle" />}
			className={classNames(s.dashedButton, className)}
			{...props}
		>
			{text}
		</Button>
	);
}
