export const reports = {
	header: {
		revenue: "Income",
		service: "Service",
		products: "Products",
		people: "People",
		ticket: "Average ticket",
		entrance: "Couvert/Entrance",
		day: "Day",
		period: "Period",
	},
	addPlacesTitle: "Add places to the report",
	addPlacesPlaceholder: "Select place(s)",
	all: "All",
	emptyComponent: "No data to display the report",
	emptyPlaces: "Add at least one place to view the report",
	tooltip: "Does not include open accounts",
	revenue: {
		title: "Total income",
		paymentMethod: "Payment methods",
		periodChart: {
			perLocalTitle: "Total income by place",
			duringPeriodSubtitle: "During the period",
		},
		revenueChart: {
			entranceService: "Entrance service: {{entrance}}",
		},
		methodChart: {
			rechargeDevolutions: "Recharges returned: {{value}}",
			adjustedMoney: "Adjusted money: {{value}}",
		},
	},
	services: {
		title: "Total service",
		subtitle: "By place in percentage",
	},
	products: {
		title: "Total sales by time",
		period: "Period: ({{since}} to {{until}})",
		dayFilter: "Select the day",
		empty: "No data to display the report",
		totalConsumedPeriod: "Total consumed from {{since}} to {{until}}:",
		totalConsumedAt: "Total consumed at {{value}}",
		nextPeriod: "{{currPeriod}} to {{nextPeriod}}",
		currentPeriod: "at {{currPeriod}}",
		periodTitle: "Period",
		productConsumptionTab: "Products per hour",
		productsSold: {
			table: {
				count: "QUANTITY",
				productName: "PRODUCT NAME",
				place: "PLACE",
				sku: "SKU",
				total: "Total",
				totalPlaceValue: "TOTAL PLACE",
				totalValue: "TOTAL VALUE",
				unitValue: "UNIT VALUE",
			},
			totalizers: {
				totalCount: "Total quantity of products",
				totalSold: "Total billing for the period",
			},
		},
		productSoldTab: "Products sold by place",
	},
	downloadExcel: {
		title: "Report {{orgName}}",
		fileName: "billing-{{orgName}}",
		columns: {
			date: "Date",
			place: "Place",
			service: "Service",
			inboundService: "Inbound service",
			totalBilling: "Total billing",
		},
	},
};
