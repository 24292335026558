export const contentFiscalData = {
	fieldName: {
		ncm: "NCM",
		cest: "CEST",
		fiscalProfile: "Perfil fiscal",
		fiscalGroup: "Grupo fiscal",
		fiscalCode: "SKU",
	},
	placeholder: {
		ncm: "Insira o NCM",
		cestInput: "CEST",
		cestSelect: "Selecione o CEST",
		fiscalProfile: "Selecione o perfil fiscal",
		fiscalGroup: "Selecione o grupo fiscal",
		fiscalCode: "Inserir o SKU",
	},
	infoMessageMyTapp:
		"Não é possível desativar essa função enquanto “Sincronizar com My Tapp” estiver ativa.",
	requiredField: "Campo obrigatório",
	validationMessage: {
		ncm: "NCM deve conter 8 dígitos",
		cest: "O CEST deve conter 7 dígitos",
		fiscalCode: "Caso não seja preenchido, o código será gerado automaticamente",
		patternFiscalCode: "Apenas números, letras, ponto e hífen",
	},
	infoMessageManualcest: "Adicionar CEST manual?",
};
