export const productFeatureTemplates = {
	title: "Product Functionality Templates",
	activeTab: "product-feature-templates",
	tooltip: "Create templates with custom fields for your product",
	form: {
		isRequired: "This field is required",
		templateName: {
			label: "Model Name",
			placeholder: "Enter the template name",
		},
		templateObservation: {
			label: "Note",
			placeholder: "Enter some remark (optional)",
		},
		templateFieldsContainer: {
			label: "Model Fields",
			add: "Add field",
			field: "Field {{number}}",
			type: {
				label: "Field type {{number}}",
				placeholder: "Select field type",
				text: "Text",
				number: "Number",
				bool: "True/False",
				enum: "Options",
			},
			name: {
				label: "Field name {{number}}",
				placeholder: "Enter the field name {{number}}",
			},
			isRequired: "Is field {{number}} required?",
			remove: "Remove field",
			value: {
				label: "Options for field {{number}}",
				placeholder: "Value {{count}} of field {{number}}",
			},
		},
		editModel: "Edit Model",
		createModel: "Create model",
		addProduct: {
			isRequired: "This field is required",
			fields: "Fill in at least one field",
			wrongFields: {
				label: "Fill in all data",
				of: "of",
				from: "from",
				field: "field",
			},
		},
	},
	header: {
		add: "Add Template",
	},
	modal: {
		edit: "Edit",
		add: "Add",
		title: "product functionality model",
	},
	templateList: {
		status: {
			title:
				"Do you really want to disable the template? All functionality that uses it will be disabled as well.",
		},
		actions: {
			title:
				"Do you really want to remove the template? All functionality that uses it will be disabled as well.",
			edit: "Edit Template",
			remove: "Remove Template",
		},
	},
};
