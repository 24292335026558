import { crudMessage } from "./crud-message";
import { modalImportClients } from "./modal-import-clients";
import { modalMyModels } from "./modal-my-models";

export const message = {
	header: {
		isCreate: {
			title: "Nova mensagem",
			subtitle:
				"Selecione uma casa da sua organização para listar o grupo de clientes destinatário.",
		},
		isEdit: {
			title: "Editar mensagem programada",
			subtitle: "Altere a casa para selecionar outro grupo de destinatários.",
		},
	},
	places: "Casas",
	emptyWhatsappList:
		"Nenhum local da organização tem números cadastrados no WhatsApp. Entre em	contato com o time de atendimento da Zig e solicite o cadastro de um número	para poder enviar mensagens por WhatsApp para os seus clientes.",
	import: "Importar planilhas do excel",
	myModels: "Meus modelos",
	columnsTableClients: {
		name: "Nome do Cliente",
		cpf: "CPF",
		ticket: "Ticket médio",
		presences: "Visitas",
	},
	modalSaveGroup: {
		title: "Salvar grupo",
		text:
			"Salve os filtros aplicados para criar um grupo de clientes e facilitar o envio de SMS no futuro.",
		filtersList: "Filtros aplicados",
		groupName: {
			title: "Nome do grupo",
			placeholder: "Selecione um nome para o novo grupo de clientes",
		},
	},
	footer: {
		save: "Salvar alteração e editar mensagem",
		foward: "Avançar",
		confirmWithoutSaveGroup: "Avançar sem salvar grupo",
		confirmWithSaveGroup: "Salvar grupo e avançar",
	},
	infos: {
		title: "Responsabilidade de informações de clientes",
		text:
			"Ao importar a lista de contatos dos clientes, com a finalidade de envio de SMS, você confirma que os mesmos aceitaram o recebimento das mensagens, com base na Lei Geral de Proteção de Dados (LGPD).",
		agree: "Entendido",
	},
	sheet: {
		title: "Lista de Clientes (exemplo)",
		columns: {
			name: "Nome",
			phone: "Telefone",
			cpf: "CPF",
			observation: "Observação",
		},
	},
	crudMessage,
	modalImportClients,
	modalMyModels,
};
