import { FiX } from "react-icons/fi";
import { WarningFilled } from "@ant-design/icons";
import { observer } from "mobx-react-lite";
import * as React from "react";
import RootStore from "#stores/index";
import s from "./global-warnings.scss";
import { useGlobalWarnings } from "./hooks/use-global-warnings";
import { useTranslation } from "react-i18next";
import { datetime } from "#resources/helpers/date-format";

const vendorWithdrawWarningId = "place-has-vendor-withdraws";

export const GlobalWarnings = observer(GlobalWarningsComponent);

function GlobalWarningsComponent() {
	const { addWarning, removeWarning, warnings } = useGlobalWarnings();
	const { placeStore, fiscalStore, extractStore } = React.useContext(RootStore);
	const { t } = useTranslation("components", { keyPrefix: "globalWarnings" });

	React.useEffect(() => {
		if (placeStore.place) {
			extractStore.getOpenVendorWithdrawRequests
				.fetch({ placeId: placeStore.place.id! })
				.then(value => {
					if (value.length !== 0)
						addWarning({
							id: vendorWithdrawWarningId,
							message: `${
								value.length === 1
									? t("requests_one", { value: value.length })
									: t("requests_other", { value: value.length })
							}`,
						});
				});
		} else {
			removeWarning(vendorWithdrawWarningId);
		}
	}, [placeStore.place]);

	React.useEffect(() => {
		if (
			extractStore.getOpenVendorWithdrawRequests.value !== undefined &&
			extractStore.getOpenVendorWithdrawRequests.value.length === 0
		) {
			removeWarning(vendorWithdrawWarningId);
		}
	}, [extractStore.getOpenVendorWithdrawRequests.value]);

	React.useEffect(() => {
		if (placeStore.place && !placeStore.place.isApproved) {
			addWarning({
				id: "place-not-approved",
				message: t("isApproved"),
			});
		}

		placeStore.newGetPlaces.fetch({}).then(val => {
			const placesBlocked = val
				.filter(place => place.status === "blocked")
				.map(place => place.name);

			if (placesBlocked.length) {
				const message =
					placesBlocked.length === 1
						? t("blockedPlace", { placeName: placesBlocked.join("") })
						: t("blockedPlaces", { placeName: placesBlocked.join(", ") });

				addWarning({
					id: "place-blocked",
					message,
				});
			}
		});

		fiscalStore.getFiscalProfiles.fetch({}).then(val => {
			const certificatesAboutToExpire = val
				.map(profile => {
					if (profile.nfce?.certificadoValidoAte) {
						if (
							datetime(profile.nfce.certificadoValidoAte).isAfter(datetime().now()) &&
							datetime(profile.nfce.certificadoValidoAte).isBefore(
								datetime().add(30, "day"),
							)
						) {
							return profile.name;
						}
					}
				})
				.filter(profile => profile);
			const expiredCertificates = val
				.map(profile => {
					if (profile.nfce?.certificadoValidoAte) {
						if (datetime(profile.nfce.certificadoValidoAte).isBefore(datetime().now())) {
							return profile.name;
						}
					}
				})
				.filter(profile => profile);

			if (certificatesAboutToExpire.length > 0) {
				addWarning({
					id: "certificates-about-to-expire",
					message: t("certificatesAboutToExpire", {
						value: certificatesAboutToExpire.join(", "),
					}),
				});
			}

			if (expiredCertificates.length > 0) {
				addWarning({
					id: "expired-certificates",
					message: t("expiredCertificates", {
						value: expiredCertificates.join(", "),
					}),
				});
			}
		});
	}, []);

	return (
		<div className={s.container}>
			{warnings.map(({ id, message }) => (
				<div key={`warning-${id}`} className={s.topMessage}>
					<WarningFilled className={s.icon} />
					{message}
					<i className={s.closeIcon}>
						<FiX onClick={() => removeWarning(id)} />
					</i>
				</div>
			))}
		</div>
	);
}
