/* eslint-disable max-len */
import { ConciliationTranslations } from "./conciliation-pt-br";

export const CONCILIATION_ES_MX: ConciliationTranslations = {
	pageTitle: "Conciliación vía OFX",
	pageParagraph:
		"Al descargar el extracto de su cuenta corriente o de ahorros en archivo OFX, disponible en los principales bancos, e importarlo en el dashboard, ahorra tiempo en la gestión del dinero de su negocio.",
	importModal: {
		title: "Importación OFX",
		accountBankNotRegisteredTitle: "Cuenta bancaria no registrada",
		triggerButton: "Importar archivo OFX",
		cancelButton: "Cancelar",
	},
	uploadOfxForm: {
		inputInstruction: "Haga clic o arrastre el archivo OFX",
		fileType: "Archivo OFX",
		description:
			"Exporte el archivo OFX de su cuenta que contenga solo los lanzamientos para que no se generen movimientos indebidos",
		submitButton: "Importar",
		validation: {
			fileRequired: "El archivo OFX es obligatorio",
			fileSize: "El archivo OFX debe tener un máximo de 5 MB",
		},
	},
	submitOfxForm: {
		nameFile: "Nombre del archivo:",
		periodImported: "Período importado:",
		periodImportedValue: "{{start}} hasta {{end}}",
		bankName: "Institución bancaria:",
		labelCurrency: "Saldo final en {{date}}",
		labelCheckValue: "Confirmo el valor de {{value}} al final de este extracto",
		labelAccounts: "Seleccione una cuenta:",
		placeholderAccount: "Haga clic para seleccionar",
		accountRequired: "Seleccione una cuenta",
		submitButton: "Confirmar valor",
		alerts: {
			accountNotCreated: {
				title: "Cuenta bancaria no encontrada",
				description:
					"No identificamos esta cuenta bancaria entre las registradas. <a>Haga clic aquí</a> para registrarla <b>o seleccione una cuenta ya registrada abajo</b> para continuar con la importación del OFX.",
			},
			accountNotValidated: {
				title: "Esta cuenta aún no ha sido validada.",
				description:
					"Esta cuenta bancaria fue registrada con el nombre {{name}} pero aún no ha tenido sus datos validados. Utilizaremos los datos exportados en este OFX para realizar la validación automática.",
			},
		},
	},
	feedbackOfxStep: {
		success: {
			title: "Importación realizada con éxito",
			itemsImported: "{{send}} lanzamientos fueron importados",
			balance: "Saldo final de {{balance}} en {{date}}",
		},
		error: {
			title: "Ops... Algo no salió como planeado",
			description:
				"No pudimos realizar la importación del OFX. Intente nuevamente más tarde.",
		},
		confirmButton: "Conciliar movimientos",
	},
	redirectBankFlow: {
		description:
			"El registro de la cuenta bancaria es una acción necesaria antes de la importación del OFX.",
		redirectButton: "Registrar cuenta",
	},
	tabs: {
		pendingConciliations: "Conciliaciones pendientes",
		history: "Historial",
		description: {
			pendingConciliations: "Detalles de las importaciones",
			history: "Historial de conciliaciones",
		},
		pendingTab: {
			emptyState: {
				title: "No hay conciliaciones pendientes en este momento",
				description: "Importe un nuevo archivo OFX para conciliar nuevos lanzamientos",
			},
		},
		historyTab: {
			emptyState: {
				title: "No se encontró ninguna conciliación",
			},
		},
	},
	collapse: {
		branch: "Agencia",
		account: "Cuenta",
		importedDate: "Período importado",
		importedDateDescription: "De {{since}} hasta {{until}}",
		conciliationDate: "Fecha de conciliación",
		actions: {
			undoConciliation: "Deshacer conciliación",
		},
		unConciliatedAmount: "{{amount}} no conciliadas",
		item: {
			value: "Valor",
			date: "Fecha",
			description: "Descripción",
			chartOfAccount: "Plan de cuentas",
			paymentMethod: "Forma de pago",
			status: {
				conciliated: "Conciliada",
				refused: "Ignorada",
			},
			tooltip: {
				search: "Buscar lanzamiento",
				addAccount: "Agregar cuenta",
				ignoreTransaction: "Ignorar transacción",
			},
			accountType: {
				CheckingAccount: "Cuenta corriente",
				SavingsAccount: "Cuenta de ahorros",
			},
			paymentTypes: {
				CREDIT: "Crédito",
				DEBIT: "Débito",
				INT: "INT",
				DIV: "DIV",
				FEE: "FEE",
				SRVCHG: "SRVCHG",
				DEP: "DEP",
				ATM: "ATM",
				POS: "POS",
				XFER: "XFER",
				CHECK: "Cheque",
				PAYMENT: "PAGO",
				CASH: "Efectivo",
				DIRECTDEP: "DIRECTDEP",
				DIRECTDEBIT: "Débito Directo",
				REPEATPMT: "REPEATPMT",
				OTHER: "Otros",
				CreditCard: "Tarjeta de Crédito",
				DebitCard: "Tarjeta de Débito",
				Pix: "Pix",
			},
		},
		cancel: "Cancelar",
		complete: "Concluir conciliación",
	},
	transactionSuccessNotification: "¡Transacción actualizada con éxito!",
	cancelButton: "Cancelar",
	confirmButton: "Confirmar",
	conciliateConciliation: {
		title: "Concluir conciliación",
		description:
			"Al concluir la conciliación, los lanzamientos ignorados serán <b>eliminados permanentemente</b> de sus conciliaciones. ¿Desea continuar?",
		error: "¡Aún tiene transacciones pendientes!",
		success: "¡Conciliación realizada con éxito!",
	},
	ignoreTransactionModal: {
		title: "Ignorar Transacción",
		description: "¿Está seguro de que desea ignorar la transacción <b>{{name}}</b>?",
	},
	conciliationModal: {
		title: "Buscar lanzamiento",
		contentTitle: "Información de la importación",
		labelValue: "Valor",
		labelPaymentDate: "Fecha de pago",
		labelReceiptDate: "Fecha de recibo",
		labelDescription: "Descripción",
		labelChartAccount: "Plan de cuentas",
		labelPaymentMethod: "Forma de pago",
		buttonCancel: "Cancelar",
		buttonConfirm: "Conciliar",
		valuesAreDifferentAlert:
			"El valor de la transacción es diferente del valor seleccionado y no puede ser conciliado",
		table: {
			emptyIsFetched: "No se encontró ninguna cuenta",
			emptyCreatePaymentButton: "Crear cuenta por pagar",
			emptyCreateReceiptButton: "Crear cuenta por cobrar",
			emptyInitial: {
				title: "Use las búsquedas para comenzar",
				subtitle:
					"Busque y seleccione un lanzamiento para realizar la conciliación de la transacción a continuación.",
			},
			paymentDate: "Fecha",
			receiptDate: "Fecha",
			chartAccounts: "Plan de cuentas",
			paymentMethod: "Método de pago",
			value: "Valor",
		},
	},
	undoIgnoreTransactionModal: {
		button: "Deshacer exclusión",
		title: "Deshacer exclusión",
		description:
			"¿Está seguro de que desea deshacer la exclusión de la transacción <b>{{name}}</b>?",
	},
	undoConciliationTransactionModal: {
		button: "Deshacer conciliación",
		title: "Deshacer conciliación",
		description:
			"¿Está seguro de que desea deshacer la conciliación de la transacción <b>{{name}}</b>?",
	},
};
