import { homeInventoryControl } from "./home-inventory-control";
import { cardInventoryControl } from "./card-inventory-control";
import { stepperInventoryControl } from "./stepper-inventory-control";
import { stepsInventoryControl } from "./steps-inventory-control";
import { wrapperInventoryControl } from "./wrapper-inventory-control";
import { updateStatusMessage } from "./update-status-message";

export const inventoryControl = {
	title: "Inventory",
	emptyState: {
		title: "You don't have inventory yet",
		infoEmpty: "Click add create inventory to get started",
		addNewInventory: "New inventory",
	},
	homeInventoryControl,
	cardInventoryControl,
	stepperInventoryControl,
	stepsInventoryControl,
	wrapperInventoryControl,
	updateStatusMessage,
};
