import i18n from "#i18n/index";
import { showSuccessNotification } from "#resources/helpers/notifications";
import { createMutationFactory } from "../mutation-factory";

const t = i18n.t;

/**
 * Create UNIQUE employee
 */
export const {
	useApiHook: useAddEmployee,
	MethodSignature: AddEmployeeMethodSignature,
} = createMutationFactory("addEmployee");

/**
 * Create MULTIPLE employees
 */
export const {
	useApiHook: useAddMultipleEmployees,
	MethodSignature: AddMultipleEmployeesMethodSignature,
} = createMutationFactory("addMultipleEmployees");

/**
 * Add ZIGTAG to WAITER
 */
export const {
	useApiHook: useAddZigTagToWaiter,
	MethodSignature: AddZigTagToWaiterMethodSignature,
} = createMutationFactory("addZigTagToWaiter");

/**
 * Edit UNIQUE employee
 */
export const {
	useApiHook: useEditEmployee,
	MethodSignature: EditEmployeeMethodSignature,
} = createMutationFactory("editEmployee");

/**
 * Edit password by UNIQUE employee
 */
export const { useApiHook: useEditEmployeePassword } = createMutationFactory(
	"editEmployeePassword",
);

/**
 * Edit MULTIPLE employees
 */
export const {
	useApiHook: useEditMultipleEmployees,
	MethodSignature: EditMultipleEmployeesMethodSignature,
} = createMutationFactory("editMultipleEmployees");

/**
 * Remove employee
 */
export const { useApiHook: useRemoveEmployee } = createMutationFactory("removeEmployee");

/**
 * Remove MULTIPLE employees
 */
export const { useApiHook: useRemoveMultipleEmployees } = createMutationFactory(
	"removeMultipleEmployees",
	{
		onSuccess: () => showSuccessNotification(t("team:removeMultipleEmployees.success")),
	},
);
/**
 * Change place and bars by employee
 */
export const {
	useApiHook: useChangePlaceAndBarsEmployee,
	MethodSignature: IChangePlaceAndBarsEmployeeMethodSignature,
} = createMutationFactory("editEmployeesPlacesAndBars");
