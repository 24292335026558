export const soldProducts = {
	label: "Total products sold",
	sheetFileName: "total-products-sold",
	since: "Since",
	until: "Until",
	downloadAllSales: "Download all sales",
	loading: "Generating spreadsheet",
	filter: {
		label: "Filter by transaction type",
		placeholder: "All",
		all: "All",
		balcony: "Balcony",
		cashless: "Cashless",
		dashboard: "Dashboard",
		delivery: "Delivery",
		ficha: "Ticket",
		grabngo: "Grab n Go",
		onlineBill: "Online Bill",
		zigQrCode: "Zig QR Code",
		onlineFicha: "Online Ticket",
	},
	columns: {
		fiscalCode: "SKU",
		name: "Name",
		mountable: "Mountable",
		category: "Category",
		unitaryCount: "Unit Quantity",
		count: "Quantity",
		variable: "Variable",
		devolution: "Devolution",
		variableTooltip:
			"The unit value of products with variable value is the average of the values sold",
		fracionalCount: {
			label: "Fractional Quantity",
			grams: "grams",
			kg: "Kilos",
			lt: "Liters",
		},
		unitaryValue: "Unit value",
		subtotal: "Subtotal",
		discount: "Discount",
		totalValue: "Total value",
		grossTotalValue: "Gross total",
		tax_percentage: "Tax %",
		tax_money: "Tax {{currencySymbol}}",
		net_sales: "Net sales",
		operationType: "Operation type",
	},
	total: "TOTAL",
	totalItem: "Total",
	mountableModal: {
		name: "Name",
		section: "Mountable Session",
		count: "Quantity",
	},
	mountables: {
		label: "Mountable",
		fiscalCode: "SKU",
		product: "Product",
		category: "Product Category",
		section: "Mountable Step",
		name: "Mountable Item",
		count: "Quantity",
	},
	zigCard: "Zig Card",
	zigTable: "Zig Table",
	selectDate: "Select date",
	selectTime: "Select time",
};
