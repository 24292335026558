exports = module.exports = require("../../../node_modules/.pnpm/css-loader@0.28.11/node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._1B9SbRdtXWGJnjCkJ-1_9W{height:100%;width:100%;display:flex;flex-direction:column;justify-content:center;align-items:center;color:#4c4c4c;flex:1}._1B9SbRdtXWGJnjCkJ-1_9W ._2ua1HS5uLCFIDXN4hhlKkc{font-size:4rem}._1B9SbRdtXWGJnjCkJ-1_9W .DSWebPuNFexpjLtDdX4aV{font-size:1.25rem;font-weight:bold}._1B9SbRdtXWGJnjCkJ-1_9W ._1trM8voEbXLtuOOpso0KBa{font-size:1.125rem;font-weight:bolder}", ""]);

// exports
exports.locals = {
	"notUsualState": "_1B9SbRdtXWGJnjCkJ-1_9W",
	"icon": "_2ua1HS5uLCFIDXN4hhlKkc",
	"title": "DSWebPuNFexpjLtDdX4aV",
	"subtitle": "_1trM8voEbXLtuOOpso0KBa"
};