export const wrapperInventoryControl = {
	titleWrapper: "Criar novo inventário",
	titleButtons: {
		sendInventory: "Enviar inventário",
		approveInventory: "Aprovar inventário",
		retellInventory: "Recontar",
		downloadInExcel: "Baixar em excel",
	},
	placeholders: {
		keywordSearch: "Buscar por categoria, código ou produto",
	},
	headerInfos: {
		name: "Nome",
		inventoryTitle: "Título do inventário",
		createAt: "Data de criação",
		lastEdtion: "Última edição",
		detailsInventory: "Detalhes do inventário",
		stockist: "Estoquista",
		totalProducts: "Total de produtos",
		categoriesSelected: "Categorias selecionadas",
		storageSelected: "Estoque selecionado",
		storageAndCategoriesSelected: "Estoque e categorias",
		status: "Status",
	},
	seeMoreDetails: "Ver mais detalhes",
	drawerInfos: {
		seeDetails: "Ver detalhes",
	},
};
