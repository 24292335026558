export const company = {
	fields: {
		CNPJ: {
			label: "CNPJ",
			placeholder: "Inserir CNPJ",
		},
		cityCode: {
			label: "Código do município",
			placeholder: "Inserir código do município",
		},
		corporateReason: {
			label: "Razão social",
			placeholder: "Inserir razão social",
		},
		fantasyName: {
			label: "Nome fantasia",
			placeholder: "Inserir nome fantasia",
		},
		municipalRegistration: {
			label: "Inscrição municipal",
			placeholder: "Inserir inscrição municipal",
		},
		stateRegistration: {
			label: "Inscrição estadual",
			placeholder: "Inserir inscrição estadual",
		},
		taxRegime: {
			label: "Regime tributário",
			placeholder: "Escolha um regime tributário",
		},
		specialTaxRegime: {
			label: "Regime especial de tributação",
			placeholder: "Escolha um regime tributário especial",
		},
	},
	optionsTaxation: {
		normalRegime: "Regime normal",
		simpleNational: "Simples nacional",
		simpleNationalExcess: "Simples nacional excesso",
	},
	optionsSpecialTaxRegime: {
		cooperativa: "Cooperativa",
		estimativa: "Estimativa",
		meEppSimplesNacional: "ME EPP - Simples Nacional",
		meiSimplesNacional: "MEI - Simples Nacional",
		microempresaMunicipal: "Microempresa municipal",
		sociedadeDeProfissionais: "Sociedade de profissionais",
	},
};
