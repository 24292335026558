import { Event, OrganizationWithFeatures, Place } from "#api/enterprise-generated";
import * as React from "react";
import BreadCrumbs from "../../nav/bread-crumbs";
import SandwichMenu from "#resources/assets/svg/sandwich-menu.svg";
import { useSandwichMenu } from "#structure/providers/sandwich-menu";
import classNames from "classnames";
import { Notifications } from "#components/notifications";
import { SwitchLanguages } from "#components/switch-languages";
import { useModularization } from "#components/nav-bars/use-modularization";

const s = require("./header.scss");

export interface ICustomHeaderProps {
	environment?: string;
	message: {
		color?: string;
		text: string;
	};
	imageUrl?: string;
}

interface ComponentProps {
	place: Place | undefined;
	event: Event | undefined;
	organization: OrganizationWithFeatures | undefined;
	shouldIncludeOrganizationOnURL?: boolean;
	customHeader?: ICustomHeaderProps;
}

type Props = ComponentProps;

function CustomHeader(props: ICustomHeaderProps) {
	const { message, imageUrl, environment } = props;

	return (
		<>
			<div
				className={s.customText}
				style={{
					color: message.color || "#fff",
				}}
			>
				<span>{message.text}</span>
				{environment && <span className={s.environment}>{environment}</span>}
			</div>

			<img alt="custom-header-img" src={imageUrl} className={s.customImage} />
		</>
	);
}

function Header(props: Props) {
	const {
		event,
		organization,
		place,
		customHeader,
		shouldIncludeOrganizationOnURL,
	} = props;

	const { isOpenSandwichMenu, onChangeIsOpenSandwichMenu } = useSandwichMenu();

	const { showMenuV2: showSwitchLanguages } = useModularization();

	return (
		<header className={s.header}>
			<button
				className={classNames({
					[s.sandwichButton]: true,
					[s.isOpenSandwichMenu]: isOpenSandwichMenu,
				})}
				onClick={() =>
					onChangeIsOpenSandwichMenu(oldIsOpenSandwichMenu => !oldIsOpenSandwichMenu)
				}
			>
				<SandwichMenu />
			</button>

			<div className={s.breadcrumbs}>
				<BreadCrumbs
					organization={organization}
					place={place}
					event={event}
					includeOrganizationOnUrl={shouldIncludeOrganizationOnURL}
				/>
			</div>
			<div className={s.customArea}>
				{customHeader && <CustomHeader {...customHeader} />}
			</div>
			{showSwitchLanguages && (
				<div className={s.switchLanguages}>
					<SwitchLanguages />
				</div>
			)}
			<div className={s.notifications}>
				<Notifications />
			</div>
		</header>
	);
}

export default Header;
