export const productArea = {
	titles: {
		productArea: "Product area",
		editProduct: "Edit product",
		addInput: "Add Input",
		editTechnicalSheet: "Technical Sheet edit",
	},
	tabs: {
		productDetail: "Product Details",
		technicalSheet: "Technical Sheet",
	},
};
