export const combo = {
	placeholder: "Filtrar combos",
	addComboButton: "Adicionar combo",
	activateMultipleCombosButton: "Ativar múltiplos combos",
	disableMultipleCombosButton: "Desativar múltiplos combos",
	activatedMultipleCombos: "Combos ativados",
	activatedSingleCombo: "Combo ativado",
	deactivatedMultipleCombos: "Combos desativados",
	deactivatedSingleCombo: "Combo desativado",
	table: {
		name: "Combo",
		warning:
			"Atenção! Para disponibilizar esse combo para venda, configure o seu preço ao lado",
		fiscalInfo:
			"Este combo não tem dados fiscais. Por isso, serão considerados os dados fiscais de seus produtos",
		category: "Categoria",
		description: "Descrição",
		products: "Produtos",
		price: "Preço",
		active: "Ativo",
	},
	addComboModal: {
		errors: {
			obrigatoryField: "Esse campo é obrigatório",
			invalidValue: "Valor inválido",
			products: "A combo deve estar associada a pelo menos um produto",
		},
		name: "Nome",
		category: "Categoria",
		price: "Preço",
		description: "Descrição",
		picture: "Foto:",
		products: "Produtos",
		includeFiscal: "Incluir dados fiscais?",
		include: "Incluir",
		fiscalData: "Dados fiscais",
		fiscalFields: {
			code: "SKU",
			ncm: "NCM",
			cest: "CEST",
			fiscalGroup: "Grupo fiscal",
			fiscalProfile: "Perfil fiscal",
		},
		edit: "Editar",
		editCombo: "Editar combo",
		add: "Adicionar",
		addCombo: "Adicionar combo",
		cancel: "Cancelar",
	},
	deleteCombo: 'Deseja deletar o produto "{{name}}"?',
	columns: {
		name: "Combo",
		category: "Categoria",
		description: "Descrição",
		products: "Produtos",
		price: "Preço",
		active: "Ativo",
	},
};
