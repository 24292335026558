export const stepsInventoryControl = {
	config: {
		labels: {
			titleInventory: "Give a title to the inventory",
			exampleTitleInventory: "e.g. 'Beverage Inventory'",
			stockistName: "Stockist Name",
			selectStock: "Select the stock to create the inventory",
			selectCategory: "Select the categories",
		},
		inventoryPlaceholder: "Enter the inventory title",
		stockPlaceholder: "Click to select",
		categoryPlaceholder: "Click to select categories",
	},
	count: {
		labels: {
			count: "Count",
			unit: "Unit",
			price: "Cost Price",
			amount: "Total Value",
			deleteCategory: "Delete category",
			deleteSelectedProducts: "Delete selected",
			inventoryItems: "Inventory items",
			deleteItems: "Delete items",
			modalDescription:
				"By confirming the action, you will permanently delete {{amount}} {{itemLabel}} from the inventory. Do you wish to continue?",
			modalDeleteCategoryMessage:
				"Upon confirming the action, the category {{category}} will be permanently deleted. Do you want to continue?",
			modalDeleteProductMessage:
				"Upon confirming the action, the product {{product}} will be permanently deleted. Do you want to continue?",
			deleteSelectedItems: "Delete selected items",
			searchProduct: "Search product",
			item: {
				single: "item",
				plural: "items",
			},
		},
		filter: {
			byProduct: "Organize by product",
			byCategory: "Organize by category",
		},
		notification: {
			success: "Items deleted successfully.",
			error: "An error occurred while deleting the items. Please try again later.",
		},
	},
	resume: {
		tableColumns: {
			category: "Category",
			quantity: "Quantity",
			difference: "Difference",
			system: "System",
			product: "Product",
			systemQuantity: "System quantity",
			count: "Count",
			countDifference: "Difference in count",
			monetaryDifference: "Diferença in {{currencyNarrowSymbol}}",
			unit: "Unit",
			action: {
				label: "Action",
				adjust: "Adjust",
				loss: "Loss",
			},
		},
	},
	adjustLoss: {
		tableColumns: {
			category: "Category",
			quantity: "Quantity",
			difference: "Difference",
			system: "System",
			product: "Product",
			systemQuantity: "System quantity",
			count: "Count",
			countDifference: "Difference in count",
			monetaryDifference: "Diferença in {{currencyNarrowSymbol}}",
			unit: "Unit",
			action: {
				label: "Action",
				adjust: "Adjust",
				loss: "Loss",
			},
		},
	},
	approval: {
		exportExcel: "Export in Excel",
		inconsistencyInventory: {
			units: "Units",
			sumOfPositiveDifferences: "Sum of positive differences",
			sumOfNegativeDifferences: "Sum of negative differences",
			inconsistenciesByQuantity: "Top 5 Inconsistencies by quantity",
			inconsistenciesByDiffences: "Top 5 Inconsistencies by value",
			checkedProducts: "Verified Products",
		},
		inputPlaceholder: "Search product",
		select: {
			all: "Show all items",
			onlyNegativeDifference: "Only products with negative difference",
			onlyPositiveDifference: "Only products with positive difference",
			onlyWithoutDifference: "Only products without difference",
		},
		tableColumns: {
			category: "Category",
			quantity: "Quantity",
			difference: "Difference",
			system: "System",
			product: "Product",
			systemQuantity: "System quantity",
			count: "Count",
			countDifference: "Difference in count",
			monetaryDifference: "Diferença in {{currencyNarrowSymbol}}",
			unit: "Unit",
			action: {
				label: "Action",
				adjust: "Adjust",
				loss: "Loss",
			},
		},
		searchProduct: "Search product",
	},
};
