export const precharge = {
	title: "Operações de pré-carga",
	searchPlaceholder: "Pesquise por nome da operação de pré-carga",
	createNewPrecharge: "Criar operação de pré-carga",
	noSearchResults: "A busca não encontrou resultados",
	empty: "Não existem operações de pré-carga cadastradas",
	card: {
		noTermsOfService: "Sem termos de serviço",
		noEnd: "Sem término",
	},
	addNewPrecharge: {
		prechargeOperation: "operação de pré-carga",
		siteConfig: "Configurações do site",
		eventName: "Nome do evento",
		termsOfService: "Termos de serviços",
		ticketUrl: "Ticket url",
		slug: "Slug",
		aliases: "Alias",
		siteAppearance: "Aparência do site",
		cover: "Cover",
		logo: "Logo",
		mainImage: "Imagem principal",
		primaryColor: "Cor primária",
		darkMode: "Dark Mode",
		operationConfig: "Configurações da operação",
		beginAt: "Começa em",
		endsAt: "Termina em",
		childFriendly: "Adequado para crianças",
		preSetedValues: "Valores pré-selecionáveis",
		bonus: "Bonus",
		minimumValue: "Valor mínimo",
		rechargeConfig: "Configurações da recarga",
		expirationDate: "Data de expiração das recargas",
		validRechargDate: "Regargas são válidas apartir de",
		rechargeDuration: "Duração de recarga",
	},
	sideTab: {
		edit: "Editar operação de pré-carga",
		withoutTerms: "Sem termos de serviço",
		preChargeOperation: "Operação de pré-carga",
		slug: "SLUG",
		beginAt: "Começa em",
		endsAt: "Termina em",
		expiresAt: "Data de expiração das recargas",
		undefined: "Não definido",
		validRecharges: "Regargas são válidas apartir de",
		darkMode: "Dark Mode",
		childFriendly: "Adequado para crianças",
		primaryColor: "Cor primária",
		durability: "Duração de recarga",
		remove: {
			title: "Tem certeza que quer apagar essa operação de pré-carga?",
			button: "Apagar Operação de pré-carga",
		},
	},
	prechargeStore: {
		createPrechargeOperationSuccess: "Operação de pré-carga criada com sucesso",
		updatePrechargeOperationSuccess: "Operação de pré-carga atualizada com sucesso",
		deletePrechargeOperationSuccess: "Operação de pré-carga apagada com sucesso",
	},
	homePage: {
		deleteTitle: "Deletar site de Pré-Carga",
		deleteSubtitle: "Tem certeza que deseja deletar este site de Pré-Carga?",
		title: "Pré-Carga",
		description:
			"Crie um site para que os clientes realizem pré-carga antes do evento começar, evitando filas e consequentemente diminuindo o volume de operação no evento.",
		createButton: "Criar site de Pré-Carga",
		searchInputPlaceholder: "Busque um site",
		willExpire: "Expira em",
		hasExpired: "Expirou em",
		copyLink: "Copiar link para o site",
		active: "Ativo",
		inactive: "Inativo",
		expired: "Expirado",
		copied: "Link copiado.",
		report: "Relatório",
	},
	formPage: {
		title: "Novo site de Pré-Carga",
		description:
			"Preencha os dados e divulgue o site para seus clientes. Permita que os usuários realizem a pré-carga antes do evento começar, evitando filas e diminuindo o volume de operação no evento.",
		nextStep: "Próxima etapa",
		discard: "Descartar criação",
		logoDescription: "A imagem deve ser em .jpeg ou .png, com 880px por 880px",
		siteSettingsTitle: "Configurações de site",
		rechargeSettingsTitle: "Configurações de pré-carga",
		tipsTitle: "Dicas para o evento",
		eventData: {
			title: "Informações do evento",
			logo: "Logo do evento",
			eventName: "Nome do evento",
			eventNamePlaceholder: "Digite o nome do evento",
			email: "E-mail para contato",
		},
		customization: {
			title: "Customização do site",
			slug: "URL do site",
			slugPlaceholder: "Digite o nome do evento",
			color: "Cor dos detalhes",
			rechargesPeriod: "Período de Pré-Cargas",
			endsAt: "Prazo do site",
			buffer: "Foto de destaque da página",
			mainPageInfoTitle: "Título da página",
			mainPageInfoTitleDescription:
				'Sugestão: Pré-carga? O(A) "{{name}}" tem! Se programe e evite filas',
			mainPageInfoTitlePlaceholder: "Digite aqui o título que deseja colocar na página",
			mainPageInfoSubtitle: "Texto complementar ao título",
			colorPlaceholder: "Selecionar cor",
			slugDescription:
				"Personalize o link para seu site de pré-carga. Não pode conter espaços, letras maiúsculas e caracteres especiais (!@#$%ˆ&*(){}[]<>).",
		},
		rechargeSettings: {
			title: "Configurações de pré-carga",
			rechargesLimit: "Regras de limite de pré-carga",
			rechargesLimitDescription:
				"Estabeleça o valor mínimo por pré-carga e o valor mínimo por cliente (soma de todas as pré-cargas antecipadas no período da operação).",
			minimumValue: "Valor mínimo de pré-carga",
			maximumValuePerUser: "Valor máximo de pré-carga por limite",
			suggestion: "Sugestão de valor de recarga",
			suggestionDescription:
				"No site de pré-carga, o cliente poderá selecionar um dos valores já sugeridos ou digitar o valor que desejar, respeitando os limites mínimo e máximo estabelecidos acima. Sugira 4 valores para serem exibidos no site. Case deseje, você também pode presentear seus clientes para ganhar bônus à partir das sugestões de valor.",
			preSetedValue: "Valor sugerido",
			bonus: "Bônus gratuito",
			bonusDescription:
				"Case deseje, você também pode presentear seus clientes para ganhar bônus à partir das sugestões de valor.",
			paymentMethods: "Formas de pagamento",
			paymentMethodsDescription:
				"Escolha as formas de pagamento à serem aceitas. Pelo menos uma delas deve ser selecionada",
			bonusTable: {
				rechargeValue: "Valor da Recarga",
				bonus: "Bônus",
			},
			addValue: "Adicionar valor sugerido ao site",
			suggestedValue: "Valores sugeridos adicionados",
			removeValue: "clique no X para remover",
			noBonus: "Sem bonus",
			minimumValueDescription: "O valor mínimo que o seu cliente poderá carregar é de",
			maximumValueDescription:
				"A soma de todos os valores de Pré-Carga não pode passar de",
			cardTitle: "Sugestão de Pré-Carga",
			cardBonus: "Bônus gratuito",
			addButton: "Adicionar valor sugerido",
			drawerTitle: "Adicionar sugestão",
			drawerOkButton: "Salvar sugestão",
			radioYes: "Sim. Desejo oferecer bônus para os meus clientes",
			radioNo: "Não. Não quero oferecer bônus para os meus clientes",
			bonusExample:
				"Ao carregar <span>{{preSetedValue}}</span> o seu cliente vai ter <span>{{bonus}}</span> de bônus gratuito.",
		},
		tips: {
			title: "Dicas para o evento",
			selectTips: "Seção de dicas do site",
			yesOpt: "Sim. Adicionar seção de dicas no site.",
			noOpt: "Não. Não adicionar seção de dicas no site.",
			mainQuestion:
				"Deseja inserir uma seção na qual constará dicas para os clientes terem a melhor experiência possível no o seu evento?",
			importantInfoQuestion:
				"Defina abaixo informações importantes sobre o seu evento, atribuindo-lhes um título e um texto que o complemente.",
			tipTitle: "Título da dica",
			tipTitlePlaceholder: "Ex.: Posso levar água na mochila?",
			tipDescription: "Texto informativo da dica",
			tipDescriptionPlaceholder: "Ex.: Pode levar água em garrafinhas transparentes",
			addMoreTip: "Adicionar mais dicas",
			deleteTip: "Apagar dica",
			addButton: "Adicionar dica",
			drawerOkButton: "Salvar dica",
		},
		validations: {
			required: "Campo obrigatório",
			requiredOption: "Selecione uma opção",
			invalidSlugRules: "uma ou mais regras não foram atendidas",
			maxValuPerUserLowerThanMinValue:
				"Valor máximo por usuário deve ser maior que o valor mínimo",
			presetedValueLowerThanMinValue:
				"Valor da precarga deve ser maior que o valor mínimo",
			presetedValueHigherThanMaxValuePerUser:
				"Valor da precarga deve ser menor que o valor máximo por usuário",
			presetedValueAlreadyExists: "Valor da precarga já existe",
			isTipsEnabled: "Por favor, adicione uma dica",
		},
	},
	report: {
		precharge: "Pré-carga",
		edit: "Editar site",
		showSite: "Visualizar site",
		costumersTitle: "{{value}} pessoas",
		costumersDescription: "Fizeram pré-carga",
		ticketsDescription: "Ticket médio",
		collectedDescription: "Valor arrecadado",
		redeemedDescription: "Valor resgatado",
		maleTitle: "{{value}} homens",
		femaleTitle: "{{value}} mulheres",
		otherTitle: "{{value}} outros",
		paymentMethod: "Modo de pagamento",
		value: "Valor",
		prechargePerDay: "Pré-cargas por dia",
		date: "Data",
		dateHour: "Data/Hora",
		name: "Nome",
		document: "Documento",
		email: "Email",
		bonus: "Bônus",
		cardSymmary: "Cartão utilizado",
		cardBrand: "Bandeira do cartão",
		filter: {
			name: "Nome do cliente",
			document: "Documento",
			email: "Email",
			acquirerTransactionId: "Transação adquirente",
			paymentMethod: "Método de pagamento",
			status: "Status",
		},
		creditCard: "Cartão de Crédito",
		pix: "PIX",
		billing: "Faturamento",
		status: {
			pendingReview: "Pendente",
			refunded: "Reembolsado",
			refused: "Recusado",
			success: "Aprovado",
		},
		errors: {
			hasWhitespace: "Não pode conter espaços",
			hasUpperCase: "Não pode conter letras maiúsculas",
			hasSpecialCharacter: "Não pode conter caracteres especiais",
		},
	},
};
